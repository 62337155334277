/*
 * UserProfile form
 * Edit your profile
 */

import * as React from 'react';

import { Button, Col } from 'react-bootstrap';
import { AbstractControl, FieldConfig, FormGenerator, FormGroup, Observable } from 'react-reactive-form';
import { FormUtil, userBaseConfigControls } from '../common/FormUtil';

import { TFunction } from 'i18next';
import { forEach } from 'lodash';
import { WithTranslation } from 'react-i18next';
import { toastr } from 'react-redux-toastr';
import { constants } from '../../constants/constants';
import { langOption } from '../../i18n';
import { Ioption, Iuser } from '../../models';
import { userLanguageEnum } from '../../models-enums';

interface IstateChanges extends Observable<any> {
    next: () => void;
}

interface AbstractControlEdited extends AbstractControl {
    stateChanges: IstateChanges;
}

const buildFieldConfig = (facilityOptions: any[], user: Iuser, codes: any) => {
    const languageOptions = FormUtil.convertEnumToOptions(userLanguageEnum);
    const standardCodes: Ioption[] = codes;
    const userCodes = standardCodes.filter(code => user.userStandards.includes(code.value));

    // Field config to configure form
    const fieldConfig = {
        controls: {
            ...userBaseConfigControls,
            language: {
                render: FormUtil.Select,
                meta: {
                    options: languageOptions,
                    label: 'common:language',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    isMulti: false,
                    name: 'language'
                },
                options: {
                    validators: FormUtil.validators.requiredWithTrim
                }
            },
            standards: {
                render: FormUtil.Select,
                meta: {
                    options: standardCodes,
                    label: 'common:standards',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    isMulti: true,
                    name: 'standards'
                },
                formState: {
                    value: userCodes,
                    disabled: false
                },
                options: {
                    validators: [FormUtil.validators.requiredWithTrim]
                }
            }
        }
    };

    return fieldConfig as FieldConfig;
};

interface Iprops extends React.Props<UserProfileForm> {
    colorButton: string;
    handleSubmit: any;
    loading: boolean;
    t: TFunction;
    toggleModal: () => void;
    user: Iuser;
    standardCodes: Ioption[];
}

class UserProfileForm extends React.Component<Iprops & WithTranslation, { userValuesUpdated: boolean }> {
    private formGroup: FormGroup | any;
    private fieldConfig: FieldConfig;
    constructor(props: Iprops & WithTranslation) {
        super(props);
        this.fieldConfig = FormUtil.translateForm(
            buildFieldConfig([], this.props.user, this.props.standardCodes),
            this.props.t
        );
        this.state = {
            userValuesUpdated: false
        };
    }

    componentDidMount() {
        this.fieldConfig = FormUtil.translateForm(
            buildFieldConfig([], this.props.user, this.props.standardCodes),
            this.props.t
        );
    }

    handleSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (this.formGroup.status === 'INVALID') {
            this.formGroup.markAsSubmitted();
            toastr.error('Please check invalid inputs', '', constants.toastrError);
            return;
        }

        const lang = langOption(this.formGroup.value.language.value);

        this.props.i18n
            .changeLanguage(lang)
            .then(() => {
                toastr.success(
                    'Success',
                    `Language changed to ${this.formGroup.value.language.label}.`,
                    constants.toastrSuccess
                );
            })
            .catch(() => {
                toastr.error(
                    'Error',
                    `Failed to update language to ${this.formGroup.value.language.label}.`,
                    constants.toastrError
                );
            });

        this.props.handleSubmit({
            ...this.props.user,
            ...this.formGroup.value,
            language: this.formGroup.value.language.value,
            email: this.props.user.email, // have to add back the email because disabling the input removes it
            userStandards: this.formGroup.value.standards.map((standard: Ioption) => standard.value)
        });
    };

    setForm = (form: AbstractControl) => {
        this.formGroup = form;
        this.formGroup.meta = {
            loading: this.props.loading
        };
    };

    componentDidUpdate(): void {
        if (!this.state.userValuesUpdated) {
            this.setState({ userValuesUpdated: true }, () => {
                forEach(this.props.user, (value, key) => {
                    this.formGroup.patchValue({ [key]: value });
                });
                let userLang = {
                    value: 0,
                    label: 'English'
                };

                const { language } = this.props.user;
                const emailControl = this.formGroup.get('email') as AbstractControlEdited;
                emailControl.disable();

                if (language) {
                    userLang = {
                        value: language,
                        label: userLanguageEnum[language]
                    };
                }

                FormUtil.patchControl(this.formGroup, 'language', userLang);
            });
        }
    }

    render() {
        const { t } = this.props;

        return (
            <div className={this.props.colorButton}>
                <form onSubmit={this.handleSubmit} className="clearfix beacon-form user-form">
                    <FormGenerator onMount={this.setForm} fieldConfig={this.fieldConfig} />
                    <Col xs={12} className="form-buttons text-right">
                        <Button
                            bsStyle="link"
                            type="button"
                            className="pull-left left-side"
                            onClick={this.props.toggleModal}
                        >
                            {t('cancel')}
                        </Button>
                        <Button bsStyle={this.props.colorButton} type="submit" disabled={this.props.loading}>
                            {t('save')}
                        </Button>
                    </Col>
                </form>
            </div>
        );
    }
}
export default UserProfileForm;
