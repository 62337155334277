/*
 * The Edit Job Connector
 */

import ViewJobForm from './ViewJobForm';
import { FormUtil } from '../common/FormUtil';
import { RouteComponentProps } from 'react-router';
import { TFunction } from 'i18next';
import { connect } from 'react-redux';
import { initialJob } from '../../reducers/initialState';
import { reopenJob, openJob } from '../../actions/manageJobActions';
import {
    ensureLatestProducts,
    getInventoryForJob,
    setTableFilter,
    toggleModalSignaturePad
} from '../../actions/manageInventoryActions';
import { toggleViewJobModal } from '../../actions/manageJobActions';
import { IinitialState } from '../../reducers';
import { selectIsLoading, selectOfflineStatusForJob } from '../../reducers/commonReducers';
import { getJobComments } from '../../actions/manageJobCommentsActions';
import {
    getJobSignatures,
    getSAPWorkOrders,
    toggleIsCollectingSignatures,
    toggleJobSignatureModal
} from '../../actions/workOrderActions';
import { getMeasurementPointListResultsForJobsHelper } from '../../actions/workerActions';
import { downloadJobV2, setIsDownloadingJob } from '../../actions/commonActions';

interface Iprops extends RouteComponentProps<{}> {
    colorButton: any;
    t: TFunction;
}
const mapStateToProps = (state: IinitialState, ownProps: Iprops) => {
    const selectedJob = state.manageJob.selectedJob || initialJob;
    return {
        user: state.user,
        jobManage: state.manageJob,
        isJobSignatureModalOpen: state.workOrder.showJobSignatureModal,
        isCollectingSignatures: state.workOrder.isCollectingSignatures,
        isSignatureModalOpen: state.manageInventory.showSignatureModal,
        loading: selectIsLoading(state),
        // customerOptions: FormUtil.convertToOptions(state.customers),
        facilityOptions: FormUtil.convertToOptions(state.facilities),
        fseOptions: FormUtil.convertToOptions(state.manageJob.fseUsers),
        showViewJobModal: state.manageJob.showViewJobModal,
        selectedJob,
        isJobDownloaded: selectOfflineStatusForJob(state, { job: selectedJob }),
        facility: state.facilities.facilitiesByID[selectedJob.facilityID]
        // customer: state.customers[selectedJob.customerID]
    };
};

export const ViewJobContainer = connect(mapStateToProps, {
    getJobComments,
    toggleViewJobModal,
    reopenJob,
    getJobSignatures,
    openJob,
    toggleJobSignatureModal,
    toggleIsCollectingSignatures,
    toggleModalSignaturePad,
    getSAPWorkOrders,
    getInventoryForJob,
    getMeasurementPointListResultsForJobsHelper,
    setIsDownloadingJob,
    setTableFilter,
    ensureLatestProducts,
    downloadJobV2
})(ViewJobForm);
