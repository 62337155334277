import { AxiosPromise, AxiosRequestConfig } from "axios";
import { Iproduct, IsimpleMeasurementPointResult } from "../models";

declare function postMessage(message: any): void;
declare function importScripts(...urls: string[]): void;
declare function axios(url: string, config?: AxiosRequestConfig): AxiosPromise;
declare const localforage: LocalForage;


export default () => {
  const root = process.env.REACT_APP_SERVER_DOMAIN;
  const host = process.env.REACT_APP_HOST_DOMAIN;
  self.addEventListener("message", e => { // eslint-disable-line no-restricted-globals
    if (!e) return;
    
    importScripts(`${host}/axios.min.js`);
    importScripts(`${host}/localforage.min.js`);

    switch (e.data.action) {
      case 'getAllProducts':{
        let startTime: number;
        let lastLoaded = 0;
        let packetCount = 0;

        const axiosOptions: AxiosRequestConfig = {
          headers: {
            Authorization: `Bearer ${e.data.token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          method: 'post',
          data: {
            search: '',
            mainCategoryID: null,
            subcategoryID: null,
            brandID: null,
            powerID: null,
            productTypeID: null,
            systemSizeID: null,
            isApproved: undefined,
            isFinalProduct: undefined,
            standards: [],
            updateDate: e.data.updateDate,
            UseCache: e.data.updateDate ? false : true // if we are asking for delta, don't use redis
          },
          timeout: 600_000, // 10 minutes
          onDownloadProgress: (progressEvent) => {
            const currentTime = new Date().getTime();
            if (!startTime) {
              startTime = currentTime;
            }
            const timeElapsedInSeconds = (currentTime - startTime) / 1000;
            const loadedBits = (progressEvent.loaded * 8) - lastLoaded;
            const speedInBps = loadedBits / timeElapsedInSeconds;
            const speedInMbps = speedInBps / 1_000_000;

            if(packetCount % 10 === 0){
              postMessage({action: 'downloadProgress', data: speedInMbps.toFixed(2)});
            }
            
            packetCount++;
            lastLoaded = progressEvent.loaded;
          }
        };
        const URL = `${root}/inventory/products?pagingType=None`;
      
        axios(URL, axiosOptions).then(resp => {

          const newProducts: { [key: string]: Iproduct } = resp.data.result ? resp.data.result : resp.data;

          // Did we recieve any new products?
          if(newProducts && Object.keys(newProducts).length > 0){

            // Get the existing products already in localforage (browser IndexedDB storage)
            localforage.getItem<{[key: string]: Iproduct}>('med-gas-mobile_all-products').then((products) => {
              let productsToSave: { [key: string]: Iproduct } = {};

              if(products !== null){
                for(let key in products){
                  if(products[key] && products[key].id){
                    productsToSave[key] = products[key];
                  }
                }
              }

              for(let key in newProducts){
                if(newProducts[key] && newProducts[key].id){
                  productsToSave[key] = newProducts[key];
                }
              }

              localforage.setItem('med-gas-mobile_all-products', productsToSave).then(()=>{
                postMessage({action: 'getAllProductsSucess'});
              }).catch((error) =>{
                if(e.data.showError && e.data.showError === true){
                  postMessage({action: 'getAllProductsFailed', error});
               }
              })
            }).catch(error => {
                console.error("Error getting all products: ", error);
                postMessage({action: 'getAllProductsFailed', error});
              });
          } else {
            // No new products, just return
            postMessage({action: 'getAllProductsSucess'});
          }
        });

        break;
      }
      case 'GetPagedMeasurementPointListResultsForJob': { // Confirm if this is still going to be used or not
        const axiosOptions: AxiosRequestConfig = {
            headers: {
                Authorization: `Bearer ${e.data.token}`,
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'post',
            // data: [e.data.jobId],
            timeout: 1000 * 60 * 5
        };

        if (e.data.jobId && e.data.page && e.data.batchAmount) {
            const URL = `${root}/MeasurementPoint/GetPagedMeasurementPointListResultsForJob?JobID=${e.data.jobId}&page=${e.data.page}&petPage=${e.data.batchAmount}`;
            axios(URL, axiosOptions)
                .then(resp => {
                    postMessage({
                        action: 'getResultsSuccess',
                        results: resp.data,
                        facilityID: e.data.facilityID,
                    });
                })
                .catch(error => {
                    postMessage({ action: 'getResultsFailed', error });
                });
        }

        break;
      }
      case 'getSimpleJobMeasurementPointResults':{
        const axiosOptions: AxiosRequestConfig = {
          headers: {
            Authorization: `Bearer ${e.data.token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          method: 'get',
          params: {},
          timeout: 1000 * 60 * 5 
        };

        let allPromises: Array<Promise<any>> = [];
        let allResults: IsimpleMeasurementPointResult[] = [];
        if (e.data.jobIDs){
          e.data.jobIDs.forEach((jobID: string) => {
            const URL = `${root}/MeasurementPoint/GetSimpleMeasurementPointListResults?JobID=${jobID}`;
            allPromises.push(axios(URL, axiosOptions).then(resp => {
              allResults = allResults.concat(resp.data)
            }))
          })
          return Promise.all(allPromises).then((status)=>{
            postMessage({action: 'getSimpleResultsSuccess', results: allResults, jobIDs: e.data.jobIDs})
          }).catch(error => {
            postMessage({action: 'getSimpleResultsFailed', error})
          })
        }
        break;
      }
      default:
        break;
    }
    });
  };
  