import * as LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-chained-backend';
import LocalStorageBackend, { I18NextLocalStorageBackend } from 'i18next-localstorage-backend';
import I18NextLocizeBackend from 'i18next-locize-backend';
import { forEach } from 'lodash';

import { userLanguageEnum } from './models-enums';

const ns = [
    'auth',
    'common',
    'securityF',
    'nsJob',
    'manageJobComments',
    'manageLocation',
    'manageInventory',
    'measurementPoints',
    'facility',
    'mobile',
    'manageMeasurementPointLists',
    'tiles',
    'toastMessage',
    'user',
    'manageWorkOrder',
    'nsPart'
];

export const languageLable: { [key: string]: string } = {
    English: 'en-US',
    French: 'fr',
    German: 'de',
    Spanish: 'es',
    Thai: 'th-TH',
    Chinese: 'zh'
};

export const langOption = (value: number): string => {
    const langName = userLanguageEnum[value];
    const langKey = languageLable[langName];

    return langKey;
};

i18next.on('onInitialized', lng => {
    moment.locale(lng);
});

const instance = i18next
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        preload: ['en-US'],
        lng: 'en-US',
        ns,
        fallbackLng: 'en-US',
        defaultNS: 'translation',

        debug: false,

        interpolation: {
            escapeValue: false, // not needed for react!!
            format: (value, format, lng) => {
                if (format === 'number') {
                    if (!value) {
                        return value;
                    }
                    return new Intl.NumberFormat(lng).format(parseFloat(value));
                }
                return value;
            }
        },

        react: {
            wait: true,
            useSuspense: true
        },
        detection: {
            caches: process.env.NODE_ENV === 'development' ? [] : ['localStorage']
        },
        backend: {
            backends: [LocalStorageBackend, I18NextLocizeBackend],
            backendOptions: [
                {
                    // expirationTime: 30, // for testing cache
                    expirationTime: 24 * 60 * 60 * 1000,
                    defaultVersion: `${process.env.REACT_APP_LOCIZE_VERSION}`
                } as I18NextLocalStorageBackend.BackendOptions,
                {
                    projectId: `${process.env.REACT_APP_LOCIZE_PROJECT_ID}`,
                    apiKey: `${process.env.REACT_APP_LOCIZE_API_KEY}`,
                    version: `${process.env.REACT_APP_LOCIZE_VERSION}`,
                    referenceLng: 'en-US'
                } as I18NextLocizeBackend.BackendOptions
            ]
        }
    });
// while developing load translations locally from json files
if (process.env.NODE_ENV === 'development') {
    forEach(ns, key => {
        i18next.addResourceBundle('en-US', key, require(`./translations/en-US/${key}.json`));
    });
}
export default instance;
