/*
 * Edit Facility Modal
 * responsible for editing a facility
 * this modal is rendered inside the UserQueueForm component because we pass the selectedCustomer
 */

import { connect } from 'react-redux';

import { addFacility, getProductInfo } from '../../actions/commonActions';
import AddFacilityForm from './AddFacilityForm';
import { setAddFacilityFormValues, updateAddFacilityFormValues } from '../../actions/facilitiesActions';

import { toggleAddFacilityModal } from '../../actions/manageJobActions';
import { withTranslation, WithTranslation } from 'react-i18next';
import { WithCommonModal } from '../common/WithCommonModal';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';
import { getIsMobile } from '../../reducers/configReducer';

interface Iprops {
    colorButton: any;
    modalClass?: string;
    secondModal?: boolean;
    disabled?: boolean;
}

const defaultProps = {
    className: 'add-faciity'
};

const mapStateToProps = (state: IinitialState, ownProps: Iprops & WithTranslation) => {
    let title = ownProps.t('facility:addFacility');

    const { productInfo } = state;

    return {
        ...defaultProps,
        user: state.user,
        loading: selectIsLoading(state),
        show: state.manageJob.showAddFacilityModal,
        formValues: state.facilities.addFacilityFormValues,
        productInfo,
        title,
        isMobile: getIsMobile(state)
    };
};

export default withTranslation('facility')(
    connect(mapStateToProps, {
        toggleModal: toggleAddFacilityModal,
        handleSubmit: addFacility,
        updateFormValue: updateAddFacilityFormValues,
        setFormValues: setAddFacilityFormValues,
        onHide: toggleAddFacilityModal,
        getProductInfo
    })(WithCommonModal(AddFacilityForm))
);
