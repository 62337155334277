import moment from 'moment';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import { keyBy, pickBy, filter } from 'lodash';

import * as types from '../actions/actionTypes';
import { IinitialState } from '.';
import { ImeasurementPointAnswer, ImeasurementPointResult } from '../models';
import { getSelectedJobID } from './commonSelectors';
import { initialMeasurementPointResultAnswer, initialMeasurmentPointResult } from './initialState';

const cleanResult = (result: ImeasurementPointResult): ImeasurementPointResult => {
    let cleanedAnswers: ImeasurementPointAnswer[] = [];
    if (result.measurementPointAnswers && result.measurementPointAnswers.length) {
        cleanedAnswers = result.measurementPointAnswers.map(answer => {
            return {
                ...initialMeasurementPointResultAnswer,
                ...pickBy(answer, (property, key) => property !== null)
            };
        });
    }
    return {
        ...initialMeasurmentPointResult,
        ...pickBy(result, (property, key) => property !== null),
        measurementPointAnswers: cleanedAnswers
    };
};
/*
 * SELECTORS
 */

export const selectMostRecentTemporaryResult = (
    state: { [key: string]: ImeasurementPointResult },
    installBaseID: string,
    jobID: string,
    temporary: boolean
) => {
    const filteredResults = filter(state, { installBaseID, jobID, temporary });
    if (filteredResults.length === 0) {
        return initialMeasurmentPointResult;
    }
    return filteredResults.reduce((previous, current) => {
        if (moment.utc(previous.updateDate).isAfter(moment.utc(current.updateDate))) {
            return previous;
        } else {
            return current;
        }
    }, initialMeasurmentPointResult);
};

/*
 * selectMostRecentResult
 * find the most recent result for this install and MPL
 */
export const selectMostRecentResult = (
    state: { [key: string]: ImeasurementPointResult },
    installBaseID: string,
    MPListID: string
) => {
    const filteredResults = filter(state, {
        installBaseID,
        measurementPointListID: MPListID
    });
    if (filteredResults.length === 0) {
        return initialMeasurmentPointResult;
    }
    return filteredResults.reduce((previous, current) => {
        if (moment.utc(previous.updateDate).isAfter(moment.utc(current.updateDate))) {
            return previous;
        } else {
            return current;
        }
    }, initialMeasurmentPointResult);
};

export const selectMeasurementPointListResultsByInstallBaseID = (state: IinitialState, installBaseID: string) => {
    return filter(
        state.measurementPointResults.measurementPointResultsByID,
        result => result.installBaseID === installBaseID && result.isDeleted === false && result.temporary !== true
    );
};

const selectMPListIDProp = (state: IinitialState, props: { MPListID: string }) => props.MPListID;
const selectMPLRbyID = (state: IinitialState) => state.measurementPointResults.measurementPointResultsByID;
const getHistoricalResultID = (state: IinitialState) => state.measurementPointResults.historicalResultID;

export const selectHistoricalResultID = createSelector(
    [getHistoricalResultID],
    historicalResultID => historicalResultID
);

export const selectMPLRsForJobAndMPL = createSelector(
    [selectMPLRbyID, getSelectedJobID, selectMPListIDProp],
    (measurementPointResultsByID, jobID, MPListID) => {
        return filter(measurementPointResultsByID, {
            jobID,
            measurementPointListID: MPListID,
            isDeleted: false
        });
    }
);

/*
 * REDUCERS
 */

const measurementPointResultsByIDReducer = (
    state: { [key: string]: ImeasurementPointResult } = {},
    action: any
): { [key: string]: ImeasurementPointResult } => {
    switch (action.type) {
        case types.ADD_MEASUREMENT_POINT_RESULT:
            return { ...state, [action.result.id]: cleanResult(action.result) };
        case types.UPDATE_MEASUREMENT_POINT_RESULT:
            return { ...state, [action.result.id]: action.result };
        case types.UPDATE_MEASUREMENT_POINT_RESULT_STATUS:
            return { ...state, [action.result.id]: cleanResult(action.result) };
        case types.UPDATE_MEASUREMENT_POINT_RESULT_BULK: {
            return { ...state, ...keyBy(action.results, 'id') };
        }
        case types.SET_MEASUREMENT_POINT_RESULT_HISTORY: {
            const newResults = keyBy(
                action.payload.data.map((res: ImeasurementPointResult) => cleanResult(res)),
                'id'
            );
            return { ...state, ...newResults };
        }
        case types.GET_MEASUREMENT_POINT_FACILITY_RESULTS_SUCCESS: {
            let results = action.results;
            if (action.payload && action.payload.data && action.payload.data.length > 0) {
                results = action.payload.data;
            }

            const newResults = keyBy(
                results.map((res: ImeasurementPointResult) => cleanResult(res)),
                'id'
            );

            return { ...state, ...newResults };
        }
        case types.USER_LOGOUT_SUCCESS:
            return {};
        default:
            return state;
    }
};

const selectedResult = (
    state: ImeasurementPointResult = initialMeasurmentPointResult,
    action: any
): ImeasurementPointResult => {
    switch (action.type) {
        case types.ADD_MEASUREMENT_POINT_RESULT:
            return action.result;
        case types.UPDATE_MEASUREMENT_POINT_RESULT:
            if (state.id === action.result.id) {
                return { ...state, ...cleanResult(action.result) };
            }
            return cleanResult(action.result);
        case types.USER_LOGOUT_SUCCESS:
            return initialMeasurmentPointResult;
        default:
            return state;
    }
};

/*
 * enable viewing of historical results
 */
const historicalResultID = (state = '', action: any): string => {
    switch (action.type) {
        case types.SET_HISTORICAL_RESULT_ID:
            return action.resultID;
        case types.CLEAR_HISTORICAL_RESULT_ID:
            return '';
        case types.USER_LOGOUT_SUCCESS:
            return '';
        default:
            return state;
    }
};

const historicalMPToCopy = (state = initialMeasurmentPointResult, action: any): any => {
    switch (action.type) {
        case types.SET_HISTORICAL_MP_RESULT:
            return action.payload;
        case types.CLEAR_HISTORICAL_MP_RESULT:
            return initialMeasurmentPointResult;
        case types.USER_LOGOUT_SUCCESS:
            return initialMeasurmentPointResult;
        default:
            return state;
    }
};

const measurementPointResultsReducer = combineReducers({
    measurementPointResultsByID: measurementPointResultsByIDReducer,
    historicalResultID,
    selectedResult,
    historicalMPToCopy
});

export default measurementPointResultsReducer;
