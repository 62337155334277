import React, { ReactNode } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    line-height: 1.42857143;
    color: #333333;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-size: 1.2rem;
    box-sizing: border-box;
    width: max-content;
    background-color: white;
    box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    overflow: hidden;
    pointer-events: all;
    opacity: 1;

    animation-fill-mode: forwards;
    animation-duration: 0.5s;
    animation-name: bounceIn;
`;

const ConfirmModal = ({ children }: { children: ReactNode }) => {
    return <Wrapper>{children}</Wrapper>;
};

export default ConfirmModal;
