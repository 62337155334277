import mixpanel from "mixpanel-browser";

import isLocalhost from "./isLocalHost";

if (!isLocalhost) {
  mixpanel.init(`${process.env.REACT_APP_MIXPANEL_TOKEN}`, {ignore_dnt: true});
}

const production = process.env.NODE_ENV === 'production';

interface IPeopleProps {
  $email: string;
  USER_ID: string;
}

const actions = {
  identify: (id: string) => {
    if (production && !isLocalhost) {
      mixpanel.identify(id);
    }
  },
  alias: (id: string) => {
    if (production && !isLocalhost) {
      mixpanel.alias(id);
    }
  },
  track: (name: string, props?: any) => {
    if (production && !isLocalhost) {
      mixpanel.track(name, props);
    }
  },
  people: {
    set: (props: IPeopleProps) => {
      if (production && !isLocalhost) {
        mixpanel.people.set(props);
      }
    },
  },
};

export default actions;
