import * as types from './actionTypes';

import { AxiosRequestConfig, AxiosResponse } from 'axios';
import {
    Ijob,
    IjobPopulated,
    IjobWorkOrder,
    ItableFiltersParams,
    ThunkResult,
    IinstallBasePopulated,
    IjobHour,
    IuserJob,
    IjobSignature,
    WorkOrderSource,
    IWorkOrder
} from '../models';
import { omit, unionBy, forEach } from 'lodash';
import { getIsWorkOrderTypeJob } from '../reducers/manageJobReducer';
import { initialJob } from '../reducers/initialState';
import {
    AppSyncItemStatus,
    jobSourceEnum,
    jobStatusEnum,
    jobTypesIdEnum,
    measurementPointResultStatusTypesEnum,
    SignatureTypeEnum,
    workOrderStatusEnum
} from '../models-enums';

import API from '../constants/apiEndpoints';
import { TFunction } from 'i18next';
import { beginAjaxCall, endAjaxCall } from './ajaxStatusActions';
import { appSyncItemNames, constants } from '../constants/constants';
import moment from 'moment';
import { msalFetch } from '../components/auth/Auth-Utils';
import { toastr } from 'react-redux-toastr';
import { selectJobWorkOrdersForJobID } from '../reducers/commonSelectors';
import { getSelectedWorkOrders } from '../reducers/manageInventoryReducer';
import { getSAPWorkOrders, populateWOWithFacilityID, unlinkSAPWorkOrder } from './workOrderActions';
import { IinitialState } from '../reducers';

const uuidv4 = require('uuid/v4');

export function updateJob(job: Ijob): ThunkResult<any> {
    return (dispatch, getState) => {
        const cleanedJob = omit(job, ['jobWorkOrders', 'userJobs', 'facility']);
        const originalJob = getState().manageJob.jobsByID[job.id];
        const axiosOptions: AxiosRequestConfig = {
            method: 'put',
            data: { job: cleanedJob },
            url: `${API.PUT.job}/${job.id}`
        };

        /*
            handle completing a job and updating a job differently
            we want the selected job in redux reset if the job was completed.
            this will prevent the user from viewing a completed job
            ManageInventory checks for a selectedJob and if there is not one it routes the user back to the Jobs screen
            */
        if (job.status === jobStatusEnum.completed) {
            toastr.success('Success', 'This job has been completed.', constants.toastrSuccess);
            dispatch({
                type: types.SET_SELECTED_JOB,
                job: initialJob
            });
        }

        dispatch({
            type: types.JOB_UPDATE,
            job,
            meta: {
                offline: {
                    effect: { axiosOptions, message: 'update job' },
                    rollback: {
                        type: types.JOB_UPDATE,
                        job: originalJob
                    }
                }
            }
        });
        return Promise.resolve();
    };
}

export const updateJobFse = (
    job: {
        id: string;
        facilityID: string;
        assignedUserID: string;
        jobTypeID: string;
        startDate: string;
        endDate: string;
        status: string;
        isDeleted: boolean;
        jobNumber: string;
        jobHours: IjobHour[];
        fseNotes: string;
        source: number;
        createDate: string | undefined;
    },
    users: string[] | null,
    validateParts: boolean
): ThunkResult<any> => {
    return (dispatch, getstate) => {
        const axiosOptions: AxiosRequestConfig = {
            method: 'put',
            data: { job, users, validateParts },
            url: `${API.PUT.job}/${job.id}`
        };

        dispatch({
            type: types.UPDATE_JOB,
            job,
            meta: {
                offline: {
                    effect: { axiosOptions, message: 'update job' },
                    rollback: {
                        type: types.JOB_UPDATE_FSE_SUCCESS,
                        payload: { job }
                    },
                    commit: { type: types.JOB_UPDATE_FSE_SUCCESS, payload: { job } }
                }
            }
        });

        dispatch({ type: types.TOGGLE_MODAL_ADD_FSE });

        dispatch({
            type: types.SET_SELECTED_JOB,
            job
        });
    };
};

/*
 *   Performs all logic for when a job is closed successfully, can be called from completeJobAPI and completeJobNoValidationAPI
 */
function handleJobCompleteSuccess(job: Ijob, selectedJobSignatures: IjobSignature[]): ThunkResult<any> {
    return (dispatch, getState) => {
        toastr.success('Success', 'This job has been completed.', constants.toastrSuccess);
        dispatch({
            type: types.SET_SELECTED_JOB,
            job: initialJob
        });

        job = { ...job, status: jobStatusEnum.completed };

        if (selectedJobSignatures.length > 0) {
            const removeSigs = selectedJobSignatures
                .filter(signature => signature.jobID === job.id)
                .map(signature => signature.id);
            const updatedSigs = selectedJobSignatures.filter(signature => !removeSigs.includes(signature.id));

            if (updatedSigs.length > 0) {
                dispatch({
                    type: types.UPDATE_JOB_SIGNATURE,
                    signatures: updatedSigs as IjobSignature[]
                });
            }
        }

        dispatch({
            type: types.JOB_UPDATE,
            job
        });

        // Attempting to call hideCommissioningModal() in commonReducers for a weird bug where the show value doesn't get set to false
        if (job.jobTypeID === jobTypesIdEnum.commissioning) {
            dispatch({
                type: types.TOGGLE_MODAL_JOB_CLOSING_WITH_SIGNATURE
            });
        }

        dispatch({
            type: types.CLEAR_CART
        });

        return Promise.resolve();
    };
}

export const getNextJobNumber = (showError: boolean = true): ThunkResult<any> => {
    return (dispatch, getState) => {
        dispatch({
            type: types.UPDATE_INITIAL_APP_SYNC_ITEM_STATUS,
            name: appSyncItemNames.NextJobNumber,
            status: AppSyncItemStatus.inProgress
        });

        const url = API.job.getNextAutoNumber;
        const axiosOptions: AxiosRequestConfig = {
            method: 'get'
        };

        return msalFetch(url, axiosOptions)
            .then(data => {
                if (!data.data) {
                    dispatch({
                        type: types.UPDATE_INITIAL_APP_SYNC_ITEM_STATUS,
                        name: appSyncItemNames.NextJobNumber,
                        status: AppSyncItemStatus.failed
                    });
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.GET_NEXT_JOB_NUMBER_SUCCESS,
                        payload: data.data
                    });

                    dispatch({ type: types.REMOVE_INITIAL_APP_SYNC_ITEM, name: appSyncItemNames.NextJobNumber });

                    return data;
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.UPDATE_INITIAL_APP_SYNC_ITEM_STATUS,
                    name: appSyncItemNames.NextJobNumber,
                    status: AppSyncItemStatus.failed
                });
                dispatch({
                    type: types.GET_NEXT_JOB_NUMBER_FAILED,
                    error,
                    axiosOptions
                });
                if (showError) {
                    constants.handleError(error, 'getNextJobNumber');
                }
                console.error(error);
            });
    };
};

/*
 *   This guy is only called when closing a job fails because there were invalid parts and
 *   the user wants to close the job anyways without validating parts
 */
function completeJobNoValidationAPI(job: Ijob): ThunkResult<any> {
    return (dispatch, getState) => {
        const cleanedJob = omit(job, ['jobWorkOrders', 'userJobs', 'facility']);
        const { jobSignaturesByID } = getState().manageJob;
        const selectedJobSignatures = Object.values(jobSignaturesByID);
        const url = `${API.POST.job.closeJob}/${job.id}`;
        const axiosOptions: AxiosRequestConfig = {
            method: 'post',
            // validation is now done server side
            data: { job: cleanedJob, validateParts: false },
            url
        };

        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (!data.data) {
                    throw new Error('Failed to complete job');
                } else {
                    dispatch(handleJobCompleteSuccess(job, selectedJobSignatures));
                    dispatch(endAjaxCall());
                }
            })
            .catch((error: any) => {
                constants.handleError(error, 'Failed to complete job');
                throw error;
            });
    };
}

export function completeJobAPI(job: Ijob, validate?: boolean): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const cleanedJob = omit(job, ['jobWorkOrders', 'userJobs', 'facility']);
        const { jobSignaturesByID } = getState().manageJob;
        const selectedJobSignatures = Object.values(jobSignaturesByID);
        const url = `${API.POST.job.closeJob}/${job.id}`;
        if (validate === undefined || validate === null) {
            validate = job.source === jobSourceEnum.BPCS;
        }
        const axiosOptions: AxiosRequestConfig = {
            method: 'post',
            // validation is now done server side
            data: { job: cleanedJob, validateParts: validate },
            url
        };

        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (!data.data) {
                    throw new Error('Failed to complete job');
                } else {
                    // If status code is 246 then part validation failed, ask if they want to complete without validating
                    if (data.status === 246) {
                        const toastrConfirmOptions = {
                            onOk: () => {
                                dispatch(completeJobNoValidationAPI(job));
                            },
                            onCancel: () => null,
                            okText: 'OK',
                            cancelText: 'Cancel'
                        };
                        toastr.confirm(data.data, toastrConfirmOptions);
                    } else {
                        dispatch(handleJobCompleteSuccess(job, selectedJobSignatures));
                        dispatch(endAjaxCall());
                    }
                }
            })
            .catch((error: any) => {
                constants.handleError(error, 'Failed to complete job');
                throw error;
            });
    };
}

export function searchFacilities(
    queryName?: string,
    queryPostalCode?: string,
    queryAddress?: string,
    countryID: string | string[] = ''
): ThunkResult<any> {
    return dispatch => {
        dispatch(beginAjaxCall());
        let name = queryName ? queryName : '';
        let address = queryAddress ? queryAddress : '';
        let postalCode = queryPostalCode ? queryPostalCode : '';
        let params: {
            name: string;
            address: string;
            postalCode: string;
        } = {
            name,
            address,
            postalCode
        };

        let options: AxiosRequestConfig;
        let url: string;
        if (countryID) {
            url = API.GET.facility.searchByCountries;
            options = {
                method: 'put',
                params,
                data: Array.isArray(countryID) ? countryID : [countryID]
            };
        } else {
            options = {
                method: 'get',
                params
            };
            url = API.GET.facility.search;
        }
        return msalFetch(url, options)
            .then((data: AxiosResponse<any>) => {
                if (!data.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.GET_FACILITIES_SEARCH_SUCCESS,
                        facilities: data.data.result
                    });
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.GET_FACILITIES_SEARCH_FAILED,
                    error,
                    options
                });
                constants.handleError(error, 'get facilities');
                console.error('[searchFacilities]:', error);
            });
    };
}

export function openJob(jobId: string): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch({
            type: types.UPDATE_INITIAL_APP_SYNC_ITEM_STATUS,
            name: appSyncItemNames.OpenJob,
            status: AppSyncItemStatus.inProgress
        });

        const url = `${API.job.openJob}?jobID=${jobId}`;
        const axiosOptions: AxiosRequestConfig = {
            method: 'get'
        };

        return msalFetch(url, axiosOptions).then((data: AxiosResponse<any>) => {
            if (!data.data) {
                dispatch({
                    type: types.UPDATE_INITIAL_APP_SYNC_ITEM_STATUS,
                    name: appSyncItemNames.OpenJob,
                    status: AppSyncItemStatus.failed
                });

                throw new Error('Error opening job');
            } else {
                dispatch({ type: types.REMOVE_INITIAL_APP_SYNC_ITEM, name: appSyncItemNames.OpenJob });

                dispatch({
                    type: types.JOB_GET_ASSIGNED_SUCCESS,
                    payload: data
                });
            }
        });
    };
}

export function getAssignedJobs(showError: boolean = true, assumeOnline: boolean = false): ThunkResult<any> {
    return (dispatch, getState) => {
        let search = '';
        let includeCompleted = false;

        // If we are calling this from initialAppSync, we don't ever want to return prematurely
        if (!assumeOnline) {
            if (!getState().offline.online) {
                return Promise.resolve(true);
            }
            let { showCompleted, search = '' } = getState().manageJob.tableFilters;
            let includeCompleted = false;
            if (showCompleted && showCompleted.value === true) {
                includeCompleted = true;
                if (!search || (search && search.length < 2)) {
                    return Promise.resolve();
                }
            }
        }

        dispatch({
            type: types.UPDATE_INITIAL_APP_SYNC_ITEM_STATUS,
            name: appSyncItemNames.Jobs,
            status: AppSyncItemStatus.inProgress
        });
        const axiosOptions: AxiosRequestConfig = {
            method: 'get',
            params: { pagingType: 'none', includeCompleted, search }
        };

        const url = API.job.getassigned;

        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (!data.data) {
                    dispatch({
                        type: types.UPDATE_INITIAL_APP_SYNC_ITEM_STATUS,
                        name: appSyncItemNames.Jobs,
                        status: AppSyncItemStatus.failed
                    });
                    throw new Error('Error getting jobs');
                } else {
                    const jobs = data.data.result;

                    dispatch({
                        type: types.JOB_GET_ASSIGNED_SUCCESS,
                        jobs,
                        includeCompleted,
                        excludeJobHours: true
                    });

                    dispatch({ type: types.REMOVE_INITIAL_APP_SYNC_ITEM, name: appSyncItemNames.Jobs });
                }
            })
            .catch((error: any) => {
                dispatch({ type: types.JOB_GET_ASSIGNED_FAILED });
                dispatch({
                    type: types.UPDATE_INITIAL_APP_SYNC_ITEM_STATUS,
                    name: appSyncItemNames.Jobs,
                    status: AppSyncItemStatus.failed
                });
                if (showError) {
                    constants.handleError(error, 'get jobs');
                }
                throw error;
            });
    };
}

/*
 *   Same as getFSEUsers but isn't using Redux offline
 */
export function getFSEUsersOnline(showError: boolean = true): ThunkResult<any> {
    return (dispatch, getState) => {
        const url = API.GET.user.getfseusers;
        const axiosOptions: AxiosRequestConfig = {
            method: 'get',
            params: {}
        };

        dispatch({
            type: types.UPDATE_INITIAL_APP_SYNC_ITEM_STATUS,
            name: appSyncItemNames.FSEUsers,
            status: AppSyncItemStatus.inProgress
        });

        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (!data.data) {
                    dispatch({
                        type: types.UPDATE_INITIAL_APP_SYNC_ITEM_STATUS,
                        name: appSyncItemNames.FSEUsers,
                        status: AppSyncItemStatus.failed
                    });

                    throw new Error('Error getting fse users');
                } else {
                    dispatch({
                        type: types.GET_FSE_SUCCESS,
                        updateDate: moment().unix(),
                        payload: { data: data.data } // conforming to the structure of the reducer
                    });

                    dispatch({ type: types.REMOVE_INITIAL_APP_SYNC_ITEM, name: appSyncItemNames.FSEUsers });
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.UPDATE_INITIAL_APP_SYNC_ITEM_STATUS,
                    name: appSyncItemNames.FSEUsers,
                    status: AppSyncItemStatus.failed
                });
                if (showError) {
                    constants.handleError(error, 'get fse users');
                }
                throw error;
            });
    };
}

export function getFSEUsers(showError: boolean = true): ThunkResult<any> {
    return (dispatch, getState) => {
        const url = API.GET.user.getfseusers;
        const axiosOptions: AxiosRequestConfig = {
            method: 'get',
            params: {},
            url
        };

        dispatch({
            type: types.GET_FSE_USERS,
            meta: {
                offline: {
                    effect: { axiosOptions, message: 'Get FSE users' },
                    commit: { type: types.GET_FSE_SUCCESS, updateDate: moment().unix() }
                }
            }
        });

        return Promise.resolve();
    };
}
/*
* verifyJobComplete
* verify all the results pass if this is an inspection
* verify all the work orders are closed if this is a repair or maintenance
most of the heavy lifting for this is done in makeSelectInstallBasesPopulated() located in manageInventoryReduxer.tsx
*/
const verifyJobComplete = (selectedJob: Ijob, installBasesPopulated: IinstallBasePopulated[]): boolean => {
    let validJob = true;
    if (selectedJob.jobTypeID === jobTypesIdEnum.inspection) {
        if (installBasesPopulated) {
            installBasesPopulated.forEach(install => {
                if (
                    install.measurementPointListResultStatus ===
                    measurementPointResultStatusTypesEnum.resultStatusNotTested
                ) {
                    validJob = false;
                }
            });
        } else {
            validJob = false;
        }
    } else if (
        selectedJob.jobTypeID === jobTypesIdEnum.maintenance ||
        selectedJob.jobTypeID === jobTypesIdEnum.repair
    ) {
        installBasesPopulated.forEach(install => {
            if (
                install.workOrderResultStatus !== workOrderStatusEnum.complete &&
                install.workOrderResultStatus !== workOrderStatusEnum.notApplicable
            ) {
                validJob = false;
            }
        });
    }

    return validJob;
};

/*
 * completeJobHelper
 * check if we should skip validation or validate that the job is complete.
 * if this is a workOrder type job or a AGS type, then require signatures
 * when requiring signatures, open the signature modal and return.  The signature modal will add the signatures and then
 * call completeJobHelper again with the signature
 */

const completeJobHelper = (
    t: TFunction,
    skipValidation: boolean,
    installBasesPopulated: IinstallBasePopulated[],
    hasSignature?: boolean,
    validateParts = false,
    countryID: string | null = null
): ThunkResult<any> => {
    return (dispatch, getState) => {
        const { selectedJob, jobSignaturesByID } = getState().manageJob;
        const { isJobClosingWithSignature } = getState().workOrder;
        const { jobTypeID } = selectedJob;
        const isCommissioning = jobTypeID === jobTypesIdEnum.commissioning;
        const isVerification = jobTypeID === jobTypesIdEnum.verification;
        const selectedJobSignatures = Object.values(jobSignaturesByID).filter(({ jobID }) => jobID === selectedJob.id);
        const verificationSignatures = selectedJobSignatures.filter(({ type }) => type === SignatureTypeEnum.Hospital);

        if (isVerification && verificationSignatures.length === 0) {
            toastr.error(
                t('toastMessage:noHospitalSignature'),
                t('toastMessage:hospitalRepMustSignOnCommentsScreen'),
                constants.toastrError
            );

            if (isJobClosingWithSignature) {
                dispatch({ type: types.TOGGLE_MODAL_JOB_CLOSING_WITH_SIGNATURE });
            }

            return;
        }

        if (isVerification && selectedJobSignatures.length === 0) {
            if (isJobClosingWithSignature) {
                dispatch({ type: types.TOGGLE_MODAL_JOB_CLOSING_WITH_SIGNATURE });
            }
        }

        if (skipValidation || verifyJobComplete(selectedJob, installBasesPopulated)) {
            const isWorkOrderTypeJob = getIsWorkOrderTypeJob(getState());
            const isAgs = jobTypeID === jobTypesIdEnum.agsRebalancing;
            const isAudit = jobTypeID === jobTypesIdEnum.audit;
            const isInspection = jobTypeID === jobTypesIdEnum.inspection;

            if ((isWorkOrderTypeJob || isAgs || isVerification || isAudit || isInspection) && !hasSignature) {
                if (!isJobClosingWithSignature) dispatch({ type: types.TOGGLE_MODAL_JOB_CLOSING_WITH_SIGNATURE });

                dispatch({
                    type: types.TOGGLE_MODAL_SIGNATURE_PAD
                });
                return;
            }

            if (isCommissioning && countryID === constants.ukCountryID) {
                dispatch({
                    type: types.TOGGLE_MODAL_JOB_SIGNATURE
                });
                return;
            } else if (isCommissioning) {
                dispatch({
                    type: types.TOGGLE_MODAL_SIGNATURE_PAD
                });
                return;
            }

            // note that in ManageInventory.tsx componentDidUpdate it listens for a job status changing to "completed"
            // then shows the user a success message and routes them back to the jobs view
            dispatch(completeJobWithFinalCheck());
            return;
        } else {
            dispatch(completeJobHelper(t, true, installBasesPopulated));
        }
    };
};

/**
 * Complete the job after checking if the user has confirmed parts (if it is a BPCS job) and has a signature
 * @param t
 * @param installBasesPopulated
 * @param hasSignature
 * @param hasConfirmedParts
 * @param countryID
 * @returns
 */
export function completeJob(
    t: TFunction,
    installBasesPopulated: IinstallBasePopulated[],
    hasConfirmedParts = false,
    countryID: string | null = null
): ThunkResult<any> {
    return (dispatch, getState) => {
        const {
            manageJob: { jobSignaturesByID, selectedJob },
            offline
        } = getState();

        const hasSignature = Object.values(jobSignaturesByID).filter(sig => sig.jobID === selectedJob.id)?.length > 0;

        if (!offline.online) {
            toastr.warning(
                t('toastMessage:offline'),
                t('toastMessage:completeJobOnlineWarning'),
                constants.toastrWarning
            );
            return;
        }
        const { source } = selectedJob;
        // validateParts now only happens when closing on job via a similarly named param
        const shouldValidate = source === jobSourceEnum.BPCS && hasConfirmedParts === false;

        dispatch(completeJobHelper(t, !!hasSignature, installBasesPopulated, hasSignature, shouldValidate, countryID));
    };
}

export function reopenJob(job: Ijob): ThunkResult<any> {
    return (dispatch, getState) => {
        return dispatch(updateJob({ ...job, status: jobStatusEnum.reopened }));
    };
}

export function receiveUpdatedJobs(jobs: IjobPopulated[]): ThunkResult<any> {
    return (dispatch, getState) => {
        jobs.forEach(job => {
            const { user } = getState();
            // check to see if the job has been unasigned from the user and if the userJob has been deleted
            let isAssigned = false;
            if (job.userJobs && job.userJobs.length) {
                forEach(job.userJobs, userJ => {
                    if (userJ.userID === user.id && userJ.isDeleted === false) {
                        isAssigned = true;
                    }
                });
            }
            if (job.assignedUserID === user.id) {
                isAssigned = true;
            }
            dispatch({
                type: types.JOB_UPDATE,
                job: { ...job, isDeleted: isAssigned === false } // if it is not assigned, lets mark it as deleted
            });
        });
    };
}

export const saveHour = (jobHour: IjobHour, jobID: string): ThunkResult<any> => (dispatch, getState) => {
    const originalJob = getState().manageJob.jobsByID[jobID];
    // const jobHoursForServer = filter(jobHours, { isDeleted: false });
    const axiosOptions: AxiosRequestConfig = {
        method: 'post',
        data: [jobHour],
        url: API.job.saveHours
    };
    dispatch({
        type: types.JOB_SAVE_HOUR,
        jobHour,
        jobID,
        meta: {
            offline: {
                effect: { axiosOptions, message: 'save job hours' },
                rollback: {
                    type: types.JOB_UPDATE,
                    job: originalJob
                }
            }
        }
    });
};
export const deleteJobHour = (jobHour: IjobHour): ThunkResult<any> => (dispatch, getState) => {
    const originalJob = getState().manageJob.jobsByID[jobHour.jobID];
    const jobHours = unionBy([{ ...jobHour, isDeleted: true }], originalJob.jobHours, 'id');

    const axiosOptions: AxiosRequestConfig = {
        method: 'post',
        data: jobHours,
        url: API.job.saveHours
    };
    dispatch({
        type: types.JOB_SAVE_HOUR,
        jobHours,
        jobID: jobHour.jobID,
        meta: {
            offline: {
                effect: { axiosOptions, message: 'delete job hour' },
                rollback: {
                    type: types.JOB_UPDATE,
                    job: originalJob
                }
            }
        }
    });
};

// Same as saveJob, except we're not going through redux offline
export function saveJobOnline(job: Ijob, t: TFunction, userJobs: IuserJob[], id = uuidv4()): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());

        dispatch({
            type: types.TOGGLE_MODAL_EDIT_JOB
        });

        if (getState().manageJob.showSearchFacilityModal) {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            dispatch(toggleSearchFacilityModal());
        }

        const newJob = {
            ...omit(job, ['assignedUser', 'jobHours', 'userJobs']),
            id,
            createDate: moment.utc().toISOString()
        };

        const axiosOptions: AxiosRequestConfig = {
            url: API.POST.job.create,
            method: 'post',
            data: { job: newJob, users: userJobs }
        };

        return msalFetch(axiosOptions.url!, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                dispatch({
                    type: types.JOB_ADD_SUCCESS,
                    job: newJob
                });

                toastr.success(t('toastMessage:success'), t('toastMessage:savedJob'), constants.toastrError);

                // Get next job number now that the current one has been used
                dispatch(getNextJobNumber());
            })
            .catch((error: any) => {
                constants.handleError(error, 'Failed to create job', 'error', true);
                console.error('[createJob]:', error);
            });
    };
}

export function deleteJob(jobID: string, t: TFunction): ThunkResult<any> {
    return (dispatch, getState) => {
        const toastrConfirmOptions = {
            onOk: () => {
                dispatch(beginAjaxCall());
                dispatch({ type: types.TOGGLE_MODAL_EDIT_JOB });

                const axiosOptions: AxiosRequestConfig = {
                    method: 'delete'
                };

                const url = `${API.job.single}/${jobID}`;
                return msalFetch(url, axiosOptions)
                    .then((data: AxiosResponse<any>) => {
                        dispatch({
                            type: types.JOB_DELETE_SUCCESS,
                            payload: jobID
                        });
                    })
                    .catch((error: any) => {
                        dispatch({
                            type: types.JOB_DELETE_FAILED,
                            error,
                            axiosOptions
                        });
                        constants.handleError(error, 'delete job');
                        console.error('[deleteJob]:', error);
                    });
            },
            onCancel: () => null,
            okText: t('jobDeleteOk'),
            cancelText: t('common:cancel')
        };
        toastr.confirm(t('jobDeleteConfirm'), toastrConfirmOptions);
    };
}

export const bulkAddJobWorkOrdersHelper = (newJobWorkOrders: IjobWorkOrder[], jobID: string): ThunkResult<any> => {
    return (dispatch, getState) => {
        const originalJobWorkOrders = selectJobWorkOrdersForJobID(getState(), {
            jobID
        });
        const jobWorkOrders = unionBy(newJobWorkOrders, originalJobWorkOrders, 'workOrderID');
        const jobWorkOrdersRollback = unionBy(
            newJobWorkOrders.map(item => ({ ...item, isDeleted: true })),
            originalJobWorkOrders,
            'workOrderID'
        );
        const cleanedJobWorkOrders = jobWorkOrders.map(item => omit(item, 'workOrder'));
        const axiosOptions: AxiosRequestConfig = {
            url: API.job.addWorkOrders,
            method: 'post',
            data: { id: jobID, jobWorkOrders: cleanedJobWorkOrders }
        };
        dispatch({
            type: types.JOB_UPDATE_WORKORDERS,
            jobWorkOrders,
            meta: {
                offline: {
                    effect: {
                        axiosOptions,
                        message: 'add workOrder'
                    },
                    rollback: {
                        type: types.JOB_UPDATE_WORKORDERS,
                        jobWorkOrders: jobWorkOrdersRollback
                    }
                }
            }
        });
        document.dispatchEvent(new CustomEvent('addedWorkOrdersToJob'));
    };
};

export const deleteSAPJobWorkOrder = (jobWorkOrderId: string): ThunkResult<any> => {
    return (dispatch, getState) => {
        const workOrderID = getState().manageJob.jobWorkOrdersByID[jobWorkOrderId].workOrderID;
        const workOrder = getState().workOrder.unlinkedSapWorkOrdersByID[workOrderID];
        dispatch(beginAjaxCall());
        const axiosOptions: AxiosRequestConfig = {
            url: `${API.DELETE.job.deleteJobWorkOrder}?jobWorkOrderId=${jobWorkOrderId}`,
            method: 'delete'
        };
        dispatch({
            type: types.DELETE_JOB_WORKORDERS,
            jobWorkOrderId,
            meta: {
                offline: {
                    effect: {
                        axiosOptions,
                        message: 'deleteJobWorkOrder'
                    }
                }
            }
        });
        dispatch(endAjaxCall());
        if (workOrder === undefined) {
            dispatch(getSAPWorkOrders());
        } else if (workOrder !== undefined && workOrder.id !== undefined) {
            dispatch(unlinkSAPWorkOrder(workOrder?.id, workOrder));
        }
    };
};

export const deleteJobWorkOrderNoAPI = (jobWorkOrderId: string) => ({
    type: types.DELETE_JOB_WORKORDERS,
    jobWorkOrderId
});

const getWorkOrdersFromJobWorkOrder = (jobWorkOrders: IjobWorkOrder[], state: IinitialState): IWorkOrder[] =>
    jobWorkOrders
        .map(jwo => (jwo.workOrder ? jwo.workOrder : state.workOrder.workOrdersByID[jwo.workOrderID]))
        .filter(wo => wo !== undefined);

/*
    unlinkWorkOrders is called from Job Hours modal, and from InventoryActionButton
*/
export function unlinkWorkOrders(t: TFunction, jobWorkOrders: IjobWorkOrder[] = []): ThunkResult<any> {
    return (dispatch, getState) => {
        const selectedWorkOrders = jobWorkOrders.length
            ? getWorkOrdersFromJobWorkOrder(jobWorkOrders, getState())
            : getSelectedWorkOrders(getState());

        const data = selectedWorkOrders.map(wo => ({ ...wo, facility: undefined }));
        const axiosOptions: AxiosRequestConfig = {
            method: 'put',
            data
        };
        const url = `${API.workOrder.unlink}`;
        const unlinkWOPromise = msalFetch(url, axiosOptions);
        let deleteInstallPromise = Promise.resolve({});

        dispatch(beginAjaxCall());
        return Promise.all([unlinkWOPromise, deleteInstallPromise])
            .then((resp: any) => {
                jobWorkOrders = jobWorkOrders.length ? jobWorkOrders : selectJobWorkOrdersForJobID(getState(), {});
                const jobWorkOrderIDs = jobWorkOrders
                    .filter(jwo => selectedWorkOrders.map(wo => wo?.id).includes(jwo.workOrderID))
                    .map(jwo => jwo.id);
                (selectedWorkOrders as IWorkOrder[])
                    .filter(wo => wo?.source === WorkOrderSource.SAP)
                    .map(wo => populateWOWithFacilityID(wo, getState().manageInventory.installBasesByID))
                    .forEach(wo => dispatch(unlinkSAPWorkOrder(wo.id, wo)));
                jobWorkOrderIDs.forEach(jwoID => dispatch(deleteJobWorkOrderNoAPI(jwoID)));
                toastr.success(t('toastMessage:success'), t('toastMessage:workOrderUnlinked'), constants.toastrSuccess);
            })
            .catch((error: any) => {
                console.error('error unlinking work order', error);
                toastr.error(t('toastMessage:error'), t('toastMessage:workOrderUnlinkedError'), constants.toastrError);
            })
            .finally(() => {
                dispatch(endAjaxCall());
            });
    };
}

// Same as addWorkOrdersToJob, except we're not going through redux offline
export const addWorkOrdersToJobOnline = (
    selection: string[],
    jobID: string,
    t: TFunction,
    SAP = false
): ThunkResult<any> => {
    return (dispatch, getState) => {
        if (!jobID) {
            toastr.error(t('toastMessage:selectJob'), constants.toastrError);
            return;
        }
        let newJobWorkOrders: IjobWorkOrder[] = [];
        const originalJobWorkOrders = selectJobWorkOrdersForJobID(getState(), {
            jobID
        });

        // when the selection comes from a multiselect table, it has "select-{workOrderID}"
        // when selection comes from doing a repair on a single asset it has "{workOrderID}"
        const workOrderIDs = selection.map(item => {
            return item.includes('select') ? item.split('select-')[1] : item;
        });

        newJobWorkOrders = workOrderIDs.map(item => {
            const woState = getState().workOrder;
            const workOrder = woState.workOrdersByID[item] || woState.unlinkedSapWorkOrdersByID[item];
            return {
                jobID,
                workOrderID: item,
                isDeleted: false,
                id: uuidv4(),
                workOrder
            };
        });

        const jobWorkOrders = unionBy(newJobWorkOrders, originalJobWorkOrders, 'workOrderID');
        const cleanedJobWorkOrders = jobWorkOrders.map(item => omit(item, 'workOrder'));
        const axiosOptions: AxiosRequestConfig = {
            url: API.job.addWorkOrders,
            method: 'post',
            data: { id: jobID, jobWorkOrders: cleanedJobWorkOrders }
        };

        return msalFetch(axiosOptions.url!, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                dispatch({
                    type: types.JOB_UPDATE_WORKORDERS_SUCCESS,
                    key: uuidv4(),
                    payload: data
                });

                toastr.success(t('toastMessage:success'), t('toastMessage:addedWorkOrderToJob'), constants.toastrError);

                if (SAP) {
                    // This is needed because the SAP WO is now linked to the job
                    workOrderIDs.map(woID => {
                        dispatch({
                            type: types.DELETE_UNLINKED_SAP_WORKORER,
                            isDeleted: true,
                            workOrderID: woID
                        });
                        return null;
                    });
                }
            })
            .catch((error: any) => {
                constants.handleError(error, 'Failed to add work orders to job', 'error', true);
                console.error('[addWorkOrders]:', error);
            });
    };
};

export const addWorkOrdersToJob = (selection: string[], jobID: string, t: TFunction, SAP = false): ThunkResult<any> => {
    return (dispatch, getState) => {
        if (!jobID) {
            toastr.error(t('toastMessage:selectJob'), constants.toastrError);
            return;
        }
        let newJobWorkOrders: IjobWorkOrder[] = [];
        const originalJobWorkOrders = selectJobWorkOrdersForJobID(getState(), {
            jobID
        });

        // when the selection comes from a multiselect table, it has "select-{workOrderID}"
        // when selection comes from doing a repair on a single asset it has "{workOrderID}"
        const workOrderIDs = selection.map(item => {
            return item.includes('select') ? item.split('select-')[1] : item;
        });

        newJobWorkOrders = workOrderIDs.map(item => {
            const woState = getState().workOrder;
            const workOrder = woState.workOrdersByID[item] || woState.unlinkedSapWorkOrdersByID[item];
            return {
                jobID,
                workOrderID: item,
                isDeleted: false,
                id: uuidv4(),
                workOrder
            };
        });

        const jobWorkOrders = unionBy(newJobWorkOrders, originalJobWorkOrders, 'workOrderID');

        const workOrdersRollback = newJobWorkOrders.map(wo => ({
            ...wo,
            isDeleted: true
        }));

        const jobWorkOrdersRollback = unionBy(workOrdersRollback, originalJobWorkOrders, 'workOrderID');
        const cleanedJobWorkOrders = jobWorkOrders.map(item => omit(item, 'workOrder'));
        const axiosOptions: AxiosRequestConfig = {
            url: API.job.addWorkOrders,
            method: 'post',
            data: { id: jobID, jobWorkOrders: cleanedJobWorkOrders }
        };
        dispatch({
            type: types.JOB_UPDATE_WORKORDERS,
            jobWorkOrders,
            meta: {
                offline: {
                    effect: {
                        axiosOptions,
                        message: 'add workOrder'
                    },
                    commit: {
                        type: types.JOB_UPDATE_WORKORDERS_SUCCESS,
                        key: uuidv4() // We need to generate a new key to force a re-render to fetch the new data
                    },
                    rollback: {
                        type: types.JOB_UPDATE_WORKORDERS,
                        jobWorkOrders: jobWorkOrdersRollback
                    }
                }
            }
        });

        if (SAP) {
            // This is needed because the SAP WO is now linked to the job
            workOrderIDs.map(woID => {
                dispatch({
                    type: types.DELETE_UNLINKED_SAP_WORKORER,
                    isDeleted: true,
                    workOrderID: woID
                });
                return null;
            });
        }

        toastr.success(t('toastMessage:success'), t('toastMessage:addedWorkOrderToJob'), constants.toastrError);
    };
};

export const setJobCoverLetter = (job: Ijob, coverLetter: string) => ({
    type: types.SET_JOB_COVER_LETTER,
    payload: {
        job,
        jobID: job.id,
        coverLetter
    }
});

export const setDefaultFacilityID = (id: string) => ({
    type: types.SET_DEFAULT_FACILITY_ID,
    payload: id
});

export const addOpenedInspectionJob = (inspectionJobID: string) => ({
    type: types.ADD_OPENED_INSPECTION_JOB,
    payload: inspectionJobID
});
export const addOpenedMaintenanceJob = (maintenanceJobID: string) => ({
    type: types.ADD_OPENED_MAINTENANCE_JOB,
    payload: maintenanceJobID
});

export const setTempJobHours = (jobHours: { [key: string]: IjobHour }) => ({
    type: types.SET_TEMP_JOB_HOURS,
    jobHours
});

export const setSelectedJobHourID = (id: string) => ({
    type: types.SET_SELECTED_JOB_HOUR_ID,
    id
});

export const updateJobFormValue = (formValues: { [key: string]: any }) => ({
    type: types.UPDATE_FORM_VALUES_MANAGE_JOB,
    formValues
});

export const setJobFormValues = (formValues: { [key: string]: any }) => ({
    type: types.SET_FORM_VALUES_MANAGE_JOB,
    formValues
});

export const setHoursFormValues = (formValues: { [key: string]: any }) => ({
    type: types.SET_FORM_VALUES_HOURS,
    formValues
});

export const updateHoursFormValue = (formValues: { [key: string]: any }) => ({
    type: types.UPDATE_FORM_VALUES_HOURS,
    formValues
});

export const emptySearchedFacilities = () => ({
    type: types.EMPTY_SEARCHED_FACILITIES
});

export const toggleEditJobModal = () => ({
    type: types.TOGGLE_MODAL_EDIT_JOB
});
export const toggleViewJobModal = () => ({
    type: types.TOGGLE_MODAL_VIEW_JOB
});
export const toggleRiskAssessmentModal = () => ({
    type: types.TOGGLE_MODAL_RISK_ASSESSMENT
});
export const toggleAddFacilityModal = () => ({
    type: types.TOGGLE_MODAL_ADD_FACILITY
});
export const toggleSearchFacilityModal = () => ({
    type: types.TOGGLE_MODAL_SEARCH_FACILITY
});
export const setTableFilter = (filters: ItableFiltersParams) => ({
    type: types.SET_TABLE_FILTER_MANAGE_JOB,
    filters
});

export const selectJobIDForWorkOrder = (id: string) => ({
    type: types.SET_SELECTED_JOB_ID,
    id
});

export const setSelectedJob = (job: Ijob) => ({
    type: types.SET_SELECTED_JOB,
    job
});
export const completeJobWithFinalCheck = () => ({
    type: types.SET_JOB_FINAL_CHECK_MODAL,
    payload: true
});
export const setJobFinalCheckMoal = (modalOn: boolean) => ({
    type: types.SET_JOB_FINAL_CHECK_MODAL,
    payload: modalOn
});

export function updateJobFinalCheckModal(modalOn: boolean): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(setJobFinalCheckMoal(modalOn));
    };
}

export function getDefaultReports(showError: boolean = true): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());

        const axiosOptions: AxiosRequestConfig = {
            method: 'get'
        };
        const url = API.GET.report.defaults;

        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (!data.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.REPORT_MANAGE_GET_DEFAULT_SUCCESS,
                        reports: data.data
                    });
                    return data;
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.REPORT_MANAGE_GET_DEFAULT_FAILED,
                    error,
                    axiosOptions
                });
                if (showError) {
                    constants.handleError(error, 'get default reports');
                }
                console.error(error);
            });
    };
}
