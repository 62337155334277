import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';
import { Col, Row } from 'react-bootstrap';
import { SimpleMeasurementPointListContainer, simpleQueryParamsEnum } from './SimpleMeasurementPointListContainer';
import { Banner } from '../../common/Banner';
import { measurementPointListTypeEnum } from '../../../models-enums';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getIsMobile } from '../../../reducers/configReducer';

const SimpleMeasurementPointList = (props: RouteComponentProps & WithTranslation) => {
    const isMobile = useSelector(getIsMobile);
    const queryParams = queryString.parse(props.location.search) as typeof simpleQueryParamsEnum;

    const measurementPointListType = parseInt(queryParams.type);
    return (
        <Row>
            <Banner
                title={props.t(measurementPointListTypeEnum[measurementPointListType])}
                img={require('../../../images/beaconManageInventoryHeader.jpg')}
                color="blue"
            />
            <Col xs={12} className="mp-right-column">
                <SimpleMeasurementPointListContainer isMobile={isMobile} {...props} />
            </Col>
        </Row>
    );
};

export const SimpleMeasurementPointListLayout = withTranslation('manageMeasurementPointLists')(
    SimpleMeasurementPointList
);
