import { ClientAuthError, ClientConfigurationError, InteractionRequiredAuthError } from '@azure/msal-browser';
import Axios, { AxiosRequestConfig } from 'axios';
import msalInstance from './MsalInstance';

export const MSAL_SCOPES = {
    MMG: `https://${process.env.REACT_APP_MSAL_TENANT_NAME}.onmicrosoft.com/${process.env.REACT_APP_MSAL_APP_ID_URI}/read`,
    OPENID: 'openid'
};

export const loginRequest = {
    scopes: [MSAL_SCOPES.MMG]
};

export const MSAL_LOGOUT_URL = `https://login.microsoftonline.com/common/oauth2/logout`;

export const forgotPasswordRequest = {
    authority: `https://${process.env.REACT_APP_MSAL_TENANT_NAME}.b2clogin.com/${process.env.REACT_APP_MSAL_TENANT_NAME}.onmicrosoft.com/${process.env.REACT_APP_MSAL_FORGET_PASSWORD_POLICY}`,
    scopes: [`https://${process.env.REACT_APP_MSAL_TENANT_NAME}.onmicrosoft.com/mmg-api/read`]
};

export const msalRequest = {
    scopes: [MSAL_SCOPES.MMG],
    extraQueryParameters: {}
};

// Simple debug method for msal debugging, easily clean up console when you don't want to see 100 msal messages
export const debugMSAL = (error: any) => {
    let showLogs = false;

    if (showLogs) {
        console.error('[debugMSAL]: ', error);
    }
};

const delay = (t: number) => new Promise(resolve => setTimeout(resolve, t));

export async function acquireToken() {
    if (msalInstance === undefined) {
        console.error('[acquireToken]: msalInstance is not defined');
        document.dispatchEvent(new CustomEvent('startUserLogoutSessionOnly'));
        return;
    }

    if (msalInstance.msalApp === undefined) {
        console.error('[acquireToken]: msalApp is not defined');
        document.dispatchEvent(new CustomEvent('startUserLogoutSessionOnly'));
        return;
    }

    return await msalInstance.msalApp.acquireTokenSilent(loginRequest).catch(async error => {
        // due to consent or interaction required ONLY
        console.error('[acquireToken]:', error);
        debugMSAL(`AcquireTokenSilent: ${error}`);
        const noAccountError = error?.errorCode?.indexOf('no_account_error') > -1;

        if (noAccountError) {
            const msalAccounts = msalInstance.msalApp.getAllAccounts();
            if (msalAccounts.length > 0) {
                msalInstance.msalApp.setActiveAccount(msalAccounts[0]);
            } else {
                console.error('[acquireToken]: we have reached an unrecoverable error with msal');
                // even redirecting the user to the logout url does not seem to resolve it.
                // Steps to replicate, login as username password.  then when asked to re-login login with Azure
                // window.location.replace(`https://login.microsoftonline.com/common/oauth2/logout`);
                document.dispatchEvent(new CustomEvent('startUserLogoutSessionOnly'));
                return;
            }
        }

        if (
            error instanceof ClientAuthError &&
            error.errorCode &&
            error.errorCode.trim() === 'endpoints_resolution_error'
        ) {
            // User does not have a network connection, they're probably working offline, so don't stop them or log them out
            console.log('ClientAuthError: endpoints_resolution_error');
        } else if (error instanceof ClientConfigurationError) {
            console.error('[acquireToken]: ClientConfigurationError', error);
            document.dispatchEvent(new CustomEvent('forceUserLogout'));
        } else if (error instanceof InteractionRequiredAuthError || noAccountError) {
            console.error('[acquireToken]: error requires interaction', noAccountError);

            return await msalInstance.msalApp.acquireTokenRedirect(loginRequest).catch(redirectError => {
                // if aquireTokenSilent, acquireTokenPop, and aquireTokenRedirect all fail, just log out
                console.error(`AcquireTokenRedirect: ${redirectError}`);
                document.dispatchEvent(new CustomEvent('startUserLogoutSessionOnly'));
            });
        } else {
            // If there is ANY error from MSAL when getting a token, just logout and prevent any weird user experince.
            // Right now this would be because of the app registration migration
            debugMSAL(error);
            document.dispatchEvent(new CustomEvent('startUserLogoutSessionOnly'));
        }
    });
}

/*
 * check the token before every API call.
 * goal is to redirect to login when needed and avoid displaying errors unless it fails to redirect for some reason.
 * If it is invalid it will try again.  If it is still invalid, redirect to the login page
 * if it is currently redirecting to the login page (logininprogress()) then it will wait
 *
 */

export const msalFetch = (url: string, options: AxiosRequestConfig, isRetry?: boolean): Promise<any> => {
    // If msalApp is initialized then delayTime is 0, otherwise it's currently at 250 to wait for the constructor in App to finish running.
    let delayTime = msalInstance.msalApp === undefined ? 250 : 0;

    return delay(delayTime).then(() => {
        return acquireToken()
            .then(authResponse => {
                if (!authResponse || (authResponse && !authResponse.accessToken)) {
                    console.error('[msalFetch]:', authResponse);
                    throw new Error('failed to retrieve token.  Likely because 3rd party cookies are disabled');
                }
                const headers = {
                    ...options,
                    headers: {
                        Authorization: `Bearer ${authResponse.accessToken}`
                    }
                };
                return Axios(url, headers);
            })
            .catch(error => {
                console.error(`[msalFetch (${url})]:`, ' ERROR: ' + error, ' OPTIONS: ' + JSON.stringify(options));
                debugMSAL(`msalFetch: ${error}`);
                return delay(2000).then(() => {
                    // wait for the redirect before returning the error to the calling function.
                    throw error;
                });
                // Don't allow a weird user experience, log the error and log the user out
                // TODO need to add something to catch the specific case when we want to log the user out.
                // document.dispatchEvent(
                //     new CustomEvent('startUserLogoutSessionOnly')
                // );
            });
    });
};
