import React from 'react';
import styled from 'styled-components';
import { AbstractControl, FieldConfig, FormGenerator } from 'react-reactive-form';
import { TFunction } from 'i18next';

import RequestPartContainer from '../../parts/RequestPart/RequestPartContainer';
import TabSelector, { TabSelectorEntity } from './TabSelector';
import { IWorkOrder } from '../../../models';
import { PartsControl } from '../../parts/PartsControl';
import { PhotosControl } from '../../photos/PhotosControl';
import { PointListButtonBarContainer } from '../PointListButtonBarContainer';
import { WorkOrderLayout } from '../../workOrder/WorkOrderLayout';

export enum TabType {
    Default = 0,
    Photos = 'photos',
    Parts = 'parts',
    Repairs = 'repairs',
    More = 'more'
}

interface IProps {
    installID: string;
    handleCannotComplete: () => void;
    shouldHidePhotoTab: boolean;
    shouldHidePartsTab: boolean;
    isFailing: boolean;
    selectedWorkOrders: IWorkOrder[];
    formControls: { [key: string]: FieldConfig };
    historicalWorkOrderID: string;
    t: any;
    history: any;
    getWorkOrderTitle: (workOrder: IWorkOrder, t: TFunction) => string;
    handleSubmit: () => void;
    setForm: (form: AbstractControl, id: string | number) => void;
    checklistMode: boolean;
    handleActiveTabSelect: (tabKey: TabType | string | number | any) => void;
    activeTab: TabType | number | string;
    additionalTabs: TabSelectorEntity[];
}

interface TabItem {
    name: string;
    Component: any;
    type: TabType;
    onClick?: () => void;
}

const TabSection = styled.div`
    height: 41px;
    display: flex;
    flex-direction: row;
    padding: 0px 0px 0px 16px;
    background: #f1f1f1;
`;

const Tab = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: ${(props: any) => (props.isActive ? '800' : '400')};
    font-size: 14px;
    height: 41px;
    color: ${(props: any) => (props.isActive ? '#013564' : '#007BC3')};
    border-radius: 4px 4px 0px 0px;
    user-select: none;
    background-color: ${(props: any) => (props.isActive ? '#fff' : null)};

    & > span {
        height: 41px;
        border-bottom: ${(props: any) => (props.isActive ? '1px solid #007BC3' : null)};
        padding: 12px;
    }

    &:hover {
        background-color: #fff;
        cursor: pointer;
    }
`;

const MainSection = styled.div`
    background-color: #fff;
    overflow-y: auto;
    overflow-x: hidden;

    .beacon-form {
        .col-xs-8 {
            padding: 0;
        }
    }

    .beacon-select,
    .ReactTable {
        max-width: calc(100vw - 32px);
        width: 100%;
    }

    .form-buttons {
        display: flex;
        flex-wrap: wrap;

        > * {
            margin: 0;
        }

        > div {
            padding: 0;
        }

        .col-xs-8 {
            width: 100%;
        }

        .col-xs-2 {
            width: auto;
        }

        .btn {
            height: 43px;
        }
    }
`;

const SectionWrap = styled.div`
    background-color: #fff;
    padding-top: 16px;
`;

export default (props: IProps) => {
    const { handleActiveTabSelect, activeTab, additionalTabs } = props;
    const [isMoreTabsActivated, setIsMoreTabsActivated] = React.useState<boolean>(true);

    const tabs = React.useMemo(() => {
        const tabsList: TabItem[] = [];

        if (!props.shouldHidePhotoTab) {
            tabsList.push({
                name: 'Photos',
                Component: Tab,
                type: TabType.Photos
            });
        }

        if (!props.shouldHidePartsTab) {
            tabsList.push({
                name: 'Parts',
                Component: Tab,
                type: TabType.Parts
            });
        }

        if (additionalTabs.length > 0) {
            tabsList.push({
                name: 'More »',
                Component: Tab,
                type: TabType.More,
                onClick: () => {
                    setIsMoreTabsActivated(true);
                }
            });
        }

        return tabsList;
    }, [props.installID, additionalTabs]);

    return (
        <>
            <TabSection>
                {!isMoreTabsActivated &&
                    tabs.map((tab, index) => {
                        return (
                            <tab.Component
                                key={index}
                                isActive={activeTab === tab.type}
                                onClick={() => {
                                    if (tab.onClick) {
                                        tab.onClick();
                                        return;
                                    }
                                    handleActiveTabSelect(tab.type);
                                }}
                            >
                                <span>{tab.name}</span>
                            </tab.Component>
                        );
                    })}
                {isMoreTabsActivated && (
                    <>
                        <Tab onClick={() => setIsMoreTabsActivated(false)}>
                            <span className="mp-tab-back-btn">«</span>
                        </Tab>
                        <TabSelector
                            setActiveTab={handleActiveTabSelect}
                            activeTab={activeTab}
                            tabs={additionalTabs}
                            t={props.t}
                            getWorkOrderTitle={props.getWorkOrderTitle}
                        />
                    </>
                )}
            </TabSection>
            <SectionWrap>
                <MainSection>
                    {activeTab === TabType.Photos && <PhotosControl installID={props.installID} />}
                    {activeTab === TabType.Parts && (
                        <PartsControl installID={props.installID} history={props.history} />
                    )}
                    {activeTab === 'failing' && (
                        <RequestPartContainer history={props.history} installID={props.installID} />
                    )}

                    {additionalTabs.map((tab: TabSelectorEntity) => {
                        if (tab?.isMeasurementPoint) {
                            return (
                                <div
                                    className="mp-container measurement-point-list-form"
                                    style={{
                                        display: tab.id === activeTab ? undefined : 'none',
                                        paddingLeft: '16px',
                                        paddingRight: '16px'
                                    }}
                                >
                                    <FormGenerator
                                        fieldConfig={props.formControls[tab.id]}
                                        onMount={form => props.setForm(form, tab.id)}
                                    />
                                </div>
                            );
                        }

                        if (tab?.isWorkOrder) {
                            const selectedWorkOrder: IWorkOrder | undefined = props.selectedWorkOrders.find(
                                (workOrder: IWorkOrder) => workOrder.id === tab.id
                            );

                            return (
                                <div
                                    style={{
                                        display: selectedWorkOrder?.id === activeTab ? undefined : 'none',
                                        paddingLeft: '1rem',
                                        paddingRight: '1rem'
                                    }}
                                >
                                    <WorkOrderLayout
                                        showChecklist={props.checklistMode}
                                        selectedWorkOrder={selectedWorkOrder}
                                        t={props.t}
                                    />
                                </div>
                            );
                        }
                    })}
                </MainSection>
            </SectionWrap>
            <PointListButtonBarContainer
                handleCannotComplete={props.handleCannotComplete}
                installID={props.installID}
                handleSubmit={props.handleSubmit}
            />
        </>
    );
};
