import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import CommonModal from '../common/CommonModal';
import { IinitialState } from '../../reducers';
import { TFunction } from 'i18next';
import { Button, Col, DropdownButton, MenuItem, Row } from 'react-bootstrap';
import {
    addOrUpdateFacilityContact,
    deleteFacilityContact,
    toggleModalEditFacilityContacts
} from '../../actions/manageInventoryActions';
import { AbstractControl, FormGenerator, Validators } from 'react-reactive-form';
import { FormUtil } from '../common/FormUtil';
import { IfacilityContact, Ioption, Iuser } from '../../models';
import ReactTable, { CellInfo } from 'react-table';
import { TableUtil } from '../common/TableUtil';
import { constants } from '../../constants/constants';
import { toastr } from 'react-redux-toastr';
import { msalFetch } from '../auth/Auth-Utils';
import API from '../../constants/apiEndpoints';

interface Iprops {
    t: TFunction;
    addOrUpdateFacilityContact: typeof addOrUpdateFacilityContact;
    deleteFacilityContact: typeof deleteFacilityContact;
}

const EditFacilityContactsModal = ({ t, addOrUpdateFacilityContact, deleteFacilityContact }: Iprops) => {
    const dispatch = useDispatch();
    const { show, selectedFacility, tableData, initialEmailOptions, offline } = useSelector((state: IinitialState) => {
        const { selectedJob } = state.manageJob;
        const selectedFacility = state.facilities.facilitiesByID[selectedJob?.facilityID];
        const tableData = state.contactsByFacility[selectedFacility?.id] || [];
        const initialEmailOptions = tableData.map(fc => ({ value: fc.email, label: fc.email }));

        return {
            show: state.manageInventory.showModalEditFacilityContacts,
            selectedFacility,
            tableData,
            initialEmailOptions,
            offline: state.offline
        };
    });

    const defaultContact = {
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        title: '',
        phone: '',
        mobile: '',
        enableNotifications: false,
        smartlinkNotifications: false,
        isDeleted: false,
        contactFacilities: [],
        source: 0 /*MMG*/
    };

    const [form, setForm] = useState<AbstractControl>();
    const [emailOptions, setEmailOptions] = useState<Ioption[]>(initialEmailOptions);
    const [selectedContact, setSelectedContact] = useState<IfacilityContact | undefined>(defaultContact);
    const [onlineUserList, setOnlineUserList] = useState<IfacilityContact[]>();

    const currentTile = useMemo(() => constants.getTileByURL(window.location.pathname), []);

    const handleDelete = (facilityContact: IfacilityContact, facilityID: string) => {
        const emailIndex = emailOptions.findIndex(email => email.value === facilityContact.email);

        emailOptions.splice(emailIndex, 1);
        setEmailOptions([...emailOptions]);

        deleteFacilityContact(facilityContact, facilityID);
        setSelectedContact(defaultContact);
    };

    const onSelect = (eventKey: number, facilityContact: CellInfo) => {
        switch (eventKey) {
            case 1:
                setSelectedContact(facilityContact.original);
                break;
            case 2:
                setSelectedContact(facilityContact.original);
                toastr.confirm(t('common:deleteConfirm'), {
                    onOk: () => handleDelete(facilityContact.original, selectedFacility?.id),
                    onCancel: () => console.log('Delete Canceled'),
                    okText: t('manageInventory:deleteContact'),
                    cancelText: t('common:cancel')
                });
                break;
            default:
                return;
        }
    };

    const columns = useMemo(
        () =>
            TableUtil.translateHeaders(
                [
                    {
                        Header: 'First',
                        accessor: 'firstName',
                        width: 120
                    },
                    {
                        Header: 'Last',
                        accessor: 'lastName',
                        width: 120
                    },
                    {
                        Header: 'Source',
                        accessor: 'source',
                        width: 120,
                        Cell: row => (row.value === 0 ? 'MMG' : row.value === 1 ? 'SAP' : '')
                    },
                    {
                        Header: 'actions',
                        id: 'actions',
                        minWidth: 20,
                        style: { overflow: 'visible' },
                        Cell: row => (
                            <DropdownButton
                                pullRight={true}
                                bsStyle="primary"
                                bsSize="xsmall"
                                title={t('common:actions')}
                                id="contact-action-button"
                                onSelect={(eventKey: any) => onSelect(eventKey, row)}
                            >
                                <MenuItem eventKey={1}>{t('common:edit')}</MenuItem>
                                <MenuItem eventKey={2} disabled={row.original.source === 1 /*SAP*/}>
                                    {t('common:delete')}
                                </MenuItem>
                            </DropdownButton>
                        )
                    }
                ],
                t
            ),
        []
    );

    const handleCreateEmail = (value: string) => {
        const emailRegex = new RegExp(
            /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,8}|[0-9]{1,3})(\]?)$/
        );

        const email = { value, label: value };
        const foundContact = tableData.find(td => td.email === value);

        if (foundContact) setSelectedContact(foundContact);
        else if (value.match(emailRegex)) {
            setEmailOptions(prev => [...prev, email]);
            setSelectedContact({ ...defaultContact, email: value });
        } else toastr.warning(t('toastMessage:warning'), t('toastMessage:validEmail'));
    };

    const loadEmailOptions = async (search: string): Promise<Ioption[]> => {
        if (offline.online && search?.length && search.length > 2) {
            const result = await msalFetch(API.contact.findUserOrContactByEmail, {
                method: 'get',
                params: { search }
            });
            const contacts =
                (result.data?.contacts as IfacilityContact[]).filter(
                    (contact, index, self) =>
                        self.findIndex(innerContact => innerContact.email === contact.email) === index
                ) || [];
            const usersAsContacts: IfacilityContact[] = result.data?.users
                ?.filter(
                    (user: Iuser) =>
                        !result.data?.contacts?.find((contact: IfacilityContact) => contact.userID === user.id) &&
                        !contacts.find(contact => contact.email === user.email)
                )
                .map((user: Iuser) => ({
                    ...defaultContact,
                    firstName: user.first,
                    lastName: user.last,
                    email: user.email,
                    phone: user.phone || ''
                }));

            const fullList = [...(usersAsContacts || []), ...contacts];
            setOnlineUserList(fullList);
            const returnList = fullList.map(({ email }: IfacilityContact) => ({ label: email, value: email }));
            return returnList as Ioption[];
        } else return emailOptions;
    };

    const fieldConfig = useMemo(() => {
        const sapContact = selectedContact?.source === 1; /* SAP */
        const formControls = {
            email: {
                render: FormUtil.AsyncCreatableSelect,
                meta: {
                    label: t('common:email'),
                    colWidth: 12,
                    rows: 2,
                    name: 'email',
                    required: true,
                    isMulti: false,
                    handleCreate: handleCreateEmail,
                    isClearable: true,
                    loadOptions: (value: string) => loadEmailOptions(value)
                },
                options: {
                    validators: [FormUtil.validators.requiredWithTrim]
                },
                formState: {
                    value: {
                        value: selectedContact?.email || '',
                        label: selectedContact?.email || ''
                    },
                    disabled: sapContact
                }
            },
            firstName: {
                render: FormUtil.TextInput,
                meta: {
                    label: t('common:firstName'),
                    colWidth: 6,
                    rows: 2,
                    name: 'firstName',
                    initialContent: '',
                    required: true
                },
                options: {
                    validators: [
                        Validators.maxLength(250),
                        Validators.minLength(2),
                        FormUtil.validators.requiredWithTrim
                    ]
                },
                formState: {
                    value: selectedContact?.firstName || '',
                    disabled: sapContact
                }
            },
            lastName: {
                render: FormUtil.TextInput,
                meta: {
                    label: t('common:lastName'),
                    colWidth: 6,
                    rows: 2,
                    name: 'lastName',
                    initialContent: '',
                    required: true
                },
                options: {
                    validators: [
                        Validators.maxLength(250),
                        Validators.minLength(2),
                        FormUtil.validators.requiredWithTrim
                    ]
                },
                formState: {
                    value: selectedContact?.lastName || '',
                    disabled: sapContact
                }
            },
            title: {
                render: FormUtil.TextInput,
                meta: {
                    label: t('common:title'),
                    colWidth: 12,
                    rows: 2,
                    name: 'title',
                    initialContent: '',
                    required: true
                },
                options: {
                    validators: [Validators.maxLength(250), FormUtil.validators.requiredWithTrim]
                },
                formState: {
                    value: selectedContact?.title || '',
                    disabled: sapContact
                }
            },
            phone: {
                render: FormUtil.TextInput,
                meta: {
                    label: t('common:phone'),
                    colWidth: 6,
                    rows: 2,
                    name: 'phone',
                    initialContent: '',
                    required: false
                },
                options: {
                    validators: [Validators.maxLength(22), Validators.minLength(7)]
                },
                formState: {
                    value: selectedContact?.phone || '',
                    disabled: sapContact
                }
            },
            mobile: {
                render: FormUtil.TextInput,
                meta: {
                    label: t('common:mobile'),
                    colWidth: 6,
                    rows: 2,
                    name: 'mobile',
                    initialContent: '',
                    required: false
                },
                options: {
                    validators: [Validators.maxLength(22), Validators.minLength(7)]
                },
                formState: {
                    value: selectedContact?.mobile || '',
                    disabled: sapContact
                }
            },
            enableNotifications: {
                render: FormUtil.Toggle,
                meta: {
                    label: t('manageInventory:enableNotifications'),
                    colWidth: 12,
                    rows: 2,
                    name: 'enableNotifications',
                    initialContent: false,
                    required: true
                },
                formState: {
                    value: selectedContact?.enableNotifications || false,
                    disabled: false
                }
            },
            smartlinkNotifications: {
                render: FormUtil.Toggle,
                meta: {
                    label: t('manageInventory:smartlinkNotifications'),
                    colWidth: 12,
                    rows: 2,
                    name: 'smartlinkNotifications',
                    initialContent: false
                },
                formState: {
                    value: selectedContact?.smartlinkNotifications || false,
                    disabled: false
                }
            }
        };

        return FormUtil.translateForm({ controls: { ...formControls } }, t);
    }, [emailOptions, selectedContact]);

    const onSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!form || form.status === 'INVALID') {
            form?.markAsSubmitted();
            toastr.error(t('toastMessage:invalidFormSubmission'), '', constants.toastrError);
        } else {
            const newContactFacility = {
                facilityID: selectedFacility?.id,
                contactID: selectedContact?.id
            };
            const tableRow = tableData.find(datum => datum.id === selectedContact?.id);
            const isAdd = !tableRow;

            const body = {
                ...form.value,
                email: form.value.email.value,
                contactFacilities: selectedContact?.contactFacilities
                    ? [...selectedContact.contactFacilities, newContactFacility]
                    : [newContactFacility],
                id: selectedContact?.id || null,
                source: tableRow?.source || defaultContact.source
            };

            addOrUpdateFacilityContact(body, selectedFacility.id, isAdd);
            setSelectedContact(defaultContact);
        }
    };

    const onHide = () => dispatch(toggleModalEditFacilityContacts());

    useEffect(() => {
        const onValueChange = (values: any) => {
            const { email } = values;

            const selectedFacilityContact = offline.online
                ? onlineUserList?.find(user => user.email === email?.value)
                : tableData.find(td => td.email === email?.value);

            if (selectedFacilityContact) setSelectedContact(selectedFacilityContact);
        };

        form?.valueChanges.unsubscribe(onValueChange);
        form?.valueChanges.subscribe(onValueChange);
    }, [form, onlineUserList]);

    return (
        <CommonModal className="" title={t('facility:contacts')} show={show} onHide={onHide}>
            <Row>
                <form onSubmit={onSubmit} className="clearfix beacon-form">
                    <Col xs={12}>
                        <FormGenerator onMount={(form: AbstractControl) => setForm(form)} fieldConfig={fieldConfig} />
                    </Col>
                    <Col>
                        {selectedContact?.id && (
                            <Button
                                bsStyle="primary"
                                type="button"
                                className="pull-left"
                                onClick={() => setSelectedContact(defaultContact)}
                                style={{ marginLeft: '20px' }}
                            >
                                {t('common:cancel')}
                            </Button>
                        )}
                        <Button bsStyle="primary" type="submit" className="pull-right" style={{ marginRight: '20px' }}>
                            {t(
                                tableData.find((contact: IfacilityContact) => contact.id === selectedContact?.id)
                                    ? 'common:update'
                                    : 'common:add'
                            )}
                        </Button>
                    </Col>
                </form>
            </Row>
            <Row>
                <div style={{ marginTop: '20px' }}>
                    <ReactTable
                        data={[...tableData]}
                        columns={columns}
                        pageSize={10}
                        showPageSizeOptions={false}
                        showPagination={false}
                        className={`beacon-table contacts -highlight ${currentTile.color}`}
                    />
                </div>
            </Row>
            <Col xs={12} className="form-buttons text-right">
                <Button bsStyle="default" type="button" className="pull-left" onClick={onHide}>
                    {t('common:close')}
                </Button>
            </Col>
        </CommonModal>
    );
};

export default connect(() => ({}), {
    addOrUpdateFacilityContact,
    deleteFacilityContact
})(EditFacilityContactsModal);
