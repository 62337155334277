import * as React from 'react';

import { Col, DropdownButton, MenuItem } from 'react-bootstrap';

import { TFunction } from 'i18next';
import { RouteComponentProps } from 'react-router';

interface Iprops extends RouteComponentProps<any> {
    t: TFunction;
    colorButton: string;
}

export const JobCommentActionButton = (props: Iprops) => {
    const { t, history, colorButton } = props;

    const handleOnSelect = (eventKey: any) => {
        switch (eventKey) {
            case 5:
                history.push('/devices');
                break;
            default:
                break;
        }
    };

    return (
        <Col className="inventory-action-button">
            <DropdownButton
                pullRight
                bsStyle={colorButton}
                title={t('manageInventory:actions')}
                id="job-comment-action-button"
                onSelect={handleOnSelect}
            >
                <MenuItem eventKey={5}>{t('mobile:assetsView')}</MenuItem>
            </DropdownButton>
        </Col>
    );
};
