import * as React from 'react';
import { useState } from 'react';

import { AbstractControl, FieldConfig, FormGenerator, FormGroup } from 'react-reactive-form';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TFunction } from 'i18next';
import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';

import { FormUtil } from '../FormUtil';
import { IinitialState } from '../../../reducers';
import { setTableFilter } from '../../../actions/manageJobActions';
import moment from 'moment';

interface Iprops {
    fieldConfig: FieldConfig;
    t: TFunction;
    show: boolean;
    closeFilterJobModal: any;
    bgColor: string;
    brColor: string;
    primaryColor: string;
    setTableFilter: typeof setTableFilter;
}

interface Istate {
    fieldConfig: FieldConfig;
}

const FilterTableModal: React.FC<Iprops & WithTranslation> = props => {
    let searchForm: FormGroup | any;
    const [show, setShow] = useState(false);
    const [state, setState] = React.useState<Istate>({
        fieldConfig: { controls: {} }
    });

    const handleClose = () => {
        setShow(false);
        props.closeFilterJobModal();
    };

    const applyFilter = () => {
        setShow(false);
        props.closeFilterJobModal();
        let filter: any = {
            showCompleted: {
                value: searchForm.value.showCompleted.value,
                label: searchForm.value.showCompleted.label
            }
        };

        //Job Type
        if (searchForm.value.jobType) {
            filter = {
                ...filter,
                jobType: {
                    value: searchForm.value.jobType.value,
                    label: searchForm.value.jobType.label
                }
            };
        } else {
            filter = { ...filter, jobType: null };
        }

        //Start Date
        if (searchForm.value.startDate) {
            const tempStartDate = searchForm.value.startDate;
            const startDateMoment = moment.isMoment(tempStartDate) ? tempStartDate : moment(tempStartDate);
            filter = { ...filter, startDate: startDateMoment.toISOString() };
        } else {
            filter = { ...filter, startDate: null };
        }

        //End Date
        if (searchForm.value.endDate) {
            const tempEndDate = searchForm.value.endDate;
            const endDateMoment = moment.isMoment(tempEndDate) ? tempEndDate : moment(tempEndDate);
            filter = { ...filter, endDate: endDateMoment.toISOString() };
        } else {
            filter = { ...filter, endDate: null };
        }

        props.setTableFilter(filter);
    };

    React.useEffect(() => {
        setShow(props.show);

        if (props.show) {
            const fieldConfig = props.fieldConfig;
            const controls = fieldConfig.controls as { [key: string]: any };

            // Check if there is a StartDate or EndDate filter, if so, convert to moment object and set the formState value
            Object.keys(controls).forEach((key: string) => {
                if (key === 'startDate') {
                    const startDate = controls[key].meta.defaultValue;
                    if (startDate !== '') {
                        const startDateMoment = moment(startDate).format('DD-MMM-YY');
                        controls[key].formState = { value: startDateMoment, disabled: false };
                    }
                } else if (key === 'endDate') {
                    const endDate = controls[key].meta.defaultValue;
                    if (endDate !== '') {
                        const endDateMoment = moment(endDate).format('DD-MMM-YY');
                        controls[key].formState = { value: endDateMoment, disabled: false };
                    }
                }
            });

            setState({ fieldConfig: FormUtil.translateForm(fieldConfig, props.t) });
        }
    }, [props.show]);

    const setForm = (form: AbstractControl) => {
        searchForm = form;
        searchForm.meta = {};
    };

    return (
        <div className="filter-table-modal-mobile">
            <Modal className="filter-table-modal-mobile" show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>{props.t('filterSearch')}</Modal.Title>
                    <Button onClick={handleClose}>
                        <FontAwesomeIcon icon={['far', 'times']} size="lg" style={{ color: props.primaryColor }} />
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <form className="filter-job-form-mobile">
                        <FormGenerator onMount={setForm} fieldConfig={state.fieldConfig} />
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={applyFilter}
                        style={{
                            backgroundColor: props.bgColor,
                            borderColor: props.brColor
                        }}
                    >
                        {props.t('applyFilters')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state: IinitialState, ownProps: Iprops) => {
    return {};
};

export default withTranslation('nsJob')(connect(mapStateToProps, {})(FilterTableModal));
