/*
 * Part Table
 */

import * as React from 'react';

import { Itile, IinstallBasePart, IinstallBasePartPopulated, Ijob } from '../../models';
import ReactTable, { FinalState, RowInfo, Column } from 'react-table';
import { toastr } from 'react-redux-toastr';

import { TFunction } from 'i18next';
import { TableUtil } from '../common/TableUtil';
import { constants } from '../../constants/constants';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    clearSelectedInstallBasePartID,
    deleteInstallBasePart,
    setSelectedInstallBasePartID
} from '../../actions/partsActions';
import { jobStatusEnum } from '../../models-enums';

interface Iprops {
    tableData: IinstallBasePartPopulated[] | IinstallBasePart[];
    t: TFunction;
    loading: boolean;
    isUS: boolean;
    currentTile: Itile;
    selectedInstallBasePartID: string;
    deleteInstallBasePart: typeof deleteInstallBasePart;
    setSelectedInstallBasePartID: typeof setSelectedInstallBasePartID;
    clearSelectedInstallBasePartID: typeof clearSelectedInstallBasePartID;
    disabled: boolean;
    selectedJob: Ijob;
}
interface Istate {
    selectedRow: any;
    columns: any[];
}
interface RowInfoEdited extends RowInfo {
    original: IinstallBasePart;
}

export class PartTable extends React.PureComponent<Iprops, Istate> {
    static defaultProps = {
        currentTile: constants.getTileByURL(window.location.pathname)
    };
    constructor(props: Iprops) {
        super(props);
        this.state = {
            selectedRow: {},
            columns: this.columns(this.props.t)
        };
    }
    componentDidUpdate(prevProps: Iprops) {
        if (
            this.props.selectedInstallBasePartID !== prevProps.selectedInstallBasePartID &&
            this.props.selectedInstallBasePartID.length === 0
        ) {
            this.setState({ selectedRow: {} });
        }
    }
    handleDelete = (item: IinstallBasePart) => {
        if (this.props.disabled) {
            return;
        }

        const toastrConfirmOptions = {
            onOk: () => {
                this.props.deleteInstallBasePart(item.id);
            },
            onCancel: () => null,
            okText: this.props.t('deletePartConfirmButton'),
            cancelText: this.props.t('common:cancel')
        };
        toastr.confirm(this.props.t('deletePartConfirmTitle'), toastrConfirmOptions);
    };

    columns = (t: TFunction) =>
        TableUtil.translateHeaders(
            [
                {
                    Header: 'numberHeader',
                    id: 'number',
                    accessor: (item: IinstallBasePartPopulated) => {
                        return item.part?.number;
                    },
                    minWidth: 80
                },
                {
                    Header: 'descriptionHeader',
                    id: 'description',
                    accessor: (item: IinstallBasePartPopulated) => {
                        if (item.partStockID) {
                            return `${item.part.description} - [boot stock]`;
                        } else {
                            return item.part.description;
                        }
                    },
                    minWidth: 160
                },
                {
                    Header: 'quantityHeader',
                    id: 'quantity',
                    accessor: (item: IinstallBasePartPopulated) => {
                        // each installBasePart should only have 1 quantity defined at a time
                        let count = 0;
                        if (item.jobPartQuantity) {
                            count++;
                        }
                        if (item.directPartQuantity) {
                            count++;
                        }
                        if (item.partStockQuantity) {
                            count++;
                        }
                        if (item.workOrderPartQuantity) {
                            count++;
                        }
                        if (count > 1) {
                            console.error('[columns -> accessor]: installBasePart has multiple quanitities.', item);
                        }
                        return (
                            item.jobPartQuantity ||
                            item.partStockQuantity ||
                            item.workOrderPartQuantity ||
                            item.directPartQuantity
                        );
                    },
                    width: 100
                },
                {
                    Header: '',
                    id: 'delete',
                    minWidth: 20,
                    Cell: row => {
                        // You cannot delete a work order part if the job has been closed/reopened
                        if (this.props.selectedJob.status !== jobStatusEnum.new && row.original.workOrderPartID) {
                            return null;
                        }

                        return (
                            <Button
                                bsStyle="link"
                                style={{
                                    float: 'right',
                                    color: constants.colors.red,
                                    display: this.props.disabled ? 'none' : 'block'
                                }}
                            >
                                <FontAwesomeIcon icon={['far', 'times']} />
                            </Button>
                        );
                    }
                }
            ],
            t
        );
    /*
     * (reusable)
     * Handle user clicking on a product row
     * set the selected product to state and open the modal
     */
    getTrProps = (state: FinalState, rowInfo: RowInfoEdited | undefined) => {
        if (rowInfo) {
            return {
                style: {
                    background:
                        rowInfo.index === this.state.selectedRow
                            ? constants.colors[`${this.props.currentTile.color}Tr` as keyof typeof constants.colors]
                            : ''
                }
            };
        } else {
            return {};
        }
    };
    getTdProps = (
        fState: FinalState,
        rowInfo: RowInfoEdited | undefined,
        column: Column | undefined,
        instance: any
    ) => {
        if (rowInfo && column && column.id && column.id === 'delete') {
            return {
                onClick: () => {
                    // You cannot delete a work order part if the job has been closed/reopened
                    if (this.props.selectedJob.status !== jobStatusEnum.new && rowInfo.original.workOrderPartID) {
                        return;
                    }
                    this.handleDelete(rowInfo.original);
                }
            };
        } else if (rowInfo) {
            return {
                onClick: () => {
                    // You cannot delete a work order part if the job has been closed/reopened
                    if (this.props.selectedJob.status !== jobStatusEnum.new && rowInfo.original.workOrderPartID) {
                        return;
                    }
                    this.setState({
                        selectedRow: rowInfo.index
                    });
                    this.props.setSelectedInstallBasePartID(rowInfo.original.id);
                }
            };
        } else {
            return {};
        }
    };

    render() {
        const { tableData, t } = this.props;
        const tableClassName = `beacon-table -highlight ${this.props.currentTile.color}`;
        return (
            <div style={{ marginTop: '20px' }}>
                <ReactTable
                    data={tableData}
                    pageSize={tableData.length || 3}
                    columns={this.state.columns}
                    getTrProps={this.getTrProps}
                    getTdProps={this.getTdProps}
                    showPageSizeOptions={false}
                    showPagination={false}
                    className={tableClassName}
                    sortable={false}
                    multiSort={false}
                    noDataText={t('addPartTableNoDataText')}
                    resizable={false}

                    // loading={loading}
                />
            </div>
        );
    }
}
