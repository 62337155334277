/*
 * Work Order Container
 */

import { WithTranslation, withTranslation } from 'react-i18next';
import { IWorkOrder } from '../../models';
import { IinitialState } from '../../reducers';
import WorkOrderForm from './WorkOrderForm';
import { connect } from 'react-redux';
import { getCurrentWorkOrder, selectWorkOrdersByInstallBaseID } from '../../reducers/workOrderReducer';
import {
    addWorkOrder,
    saveWorkOrderClosingNotes,
    saveWorkOrder,
    toggleEditPartModalForm,
    updateWorkOrder
} from '../../actions/workOrderActions';
import { selectIsLoading } from '../../reducers/commonReducers';
import { getAssignedJobs } from '../../actions/manageJobActions';

interface Iprops {
    selectedWorkOrder: IWorkOrder;
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops & WithTranslation) => {
    const selectedInstall = state.manageInventory.installBasesByID[ownProps.selectedWorkOrder.installBaseID];
    const selectedProduct = state.manageInventory.productsByID[selectedInstall.productID];
    const selectedWorkOrders = selectWorkOrdersByInstallBaseID(state, selectedInstall.id);
    const currentWorkOrder = getCurrentWorkOrder(state);
    return {
        loading: selectIsLoading(state),
        selectedWorkOrders,
        selectedProduct,
        currentWorkOrder,
        standardOptions: state.productInfo.standardOptions,
        selectedInstallBaseID: selectedInstall.id,
        disabled: true,
        selectedWorkOrder: ownProps.selectedWorkOrder
    };
};

export const WorkOrderContainer = withTranslation('manageWorkOrder')(
    connect(mapStateToProps, {
        getJobs: getAssignedJobs,
        addWorkOrder,
        saveWorkOrderClosingNotes,
        saveWorkOrder,
        updateWorkOrder,
        toggleEditPartModalForm
    })(WorkOrderForm)
);
