import { Ibuilding, Ifloor, Ilocation, Iroom } from '../models';
import { createTableFiltersWithName, createShowModalWithNamedType } from './commonReducers';
import * as types from '../actions/actionTypes';
import { combineReducers } from 'redux';
import { IinitialState } from '.';
import { createSelector } from 'reselect';

const getShowEditLocationModal = (state: IinitialState) => state.manageLocation.showEditLocationModal;

export const selectLocationModals = createSelector([getShowEditLocationModal], editLocationModal => editLocationModal);

// This reducer manages the array of currently visible locations in the table
function visibleLocations(
    state: Array<Ibuilding | Ifloor | Ilocation | Iroom> = [],
    action: any
): Array<Ibuilding | Ifloor | Ilocation | Iroom> {
    switch (action.type) {
        case types.SET_VISIBLE_LOCATIONS:
            return action.locations || [];
        case types.USER_LOGOUT_SUCCESS:
            return [];
        default:
            return state;
    }
}

const locationManage = combineReducers({
    visibleLocations,
    showEditLocationModal: createShowModalWithNamedType('EDIT_LOCATION'),
    tableFilters: createTableFiltersWithName('MANAGE_LOCATION')
});

export default locationManage;
