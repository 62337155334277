/*
 * TwoPaneLayout verifies that the user has access to this feature, then either shows the component or lets them know they do not have access
 */

import 'react-table/react-table.css';

import * as React from 'react';

import { Col, Grid, Row } from 'react-bootstrap';
import { Iuser } from '../../models';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import ManageInventory from '../manageInventory/ManageInventory';
import ManageJob from '../manageJob/ManageJob';
import ManageJobComment from '../manageJobComment/ManageJobComment';
import ManageLocation from '../manageLocation/ManageLocation';
import { MeasurementPointListLayout } from '../measurementPointList/MeasurementPointListLayout';
import { checkSyncStatus, setLocationPathname } from '../../actions/commonActions';
import { connect } from 'react-redux';
import { constants } from '../../constants/constants';
import { IinitialState } from '../../reducers';
import moment from 'moment';
import { debounce } from 'lodash';
import { selectIsLoading } from '../../reducers/commonReducers';
import { SimpleMeasurementPointListLayout } from '../measurementPointList/SimpleMeasurementPoint/SimpleMeasurementPointListLayout';
import Mixpanel from '../../helpers/Mixpanel';
import ManageWorkOrder from '../manageWorkOrder/ManageWorkOrder';
import { SuggestedPartsLayout } from '../parts/SuggestedParts';
import { QuotePartCartLayout } from '../parts/QuotePartCartLayout';
import UnassignedSAPWorkOrderContainer from '../workOrder/SAP/Unassigned';
interface Iprops extends RouteComponentProps<any> {
    user: Iuser;
    checkSyncStatus: () => void;
    setLocationPathname: typeof setLocationPathname;
    loading: boolean;
    lastSync: number;
}

class TwoPaneLayout extends React.PureComponent<Iprops, {}> {
    debouncedCheckSyncStatus = debounce(this.props.checkSyncStatus, 1000);

    tile = constants.getTileByURL(this.props.location.pathname);
    hasAccess = constants.hasSecurityFunction(this.props.user, this.tile.securityFunction);

    componentDidMount() {
        this.props.setLocationPathname(this.props.location?.pathname ?? '');
    }

    componentDidUpdate(prevProps: Iprops) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.props.setLocationPathname(this.props.location?.pathname ?? '');
        }
    }

    render() {
        Mixpanel.track(`Viewed ${this.tile.title}`, {
            hasAccess: this.hasAccess
        });
        Mixpanel.identify(this.props.user.id);
        Mixpanel.people.set({
            $email: this.props.user.email,
            USER_ID: this.props.user.id
        });

        if (!this.hasAccess) {
            return (
                <Grid className="two-pane-layout modal-container" id="two-pane-layout" fluid={true}>
                    <Row>
                        <Col className="col-fluid">
                            <div style={{ padding: '20px' }}>
                                {' '}
                                <h4>No access to {this.tile.title}. Please contact support and request access.</h4>{' '}
                            </div>
                        </Col>
                    </Row>
                </Grid>
            );
        }

        /*
         * CheckSyncStatus if the user has access as this is not the initialSync
         */
        // if (
        //     this.props.lastSync &&
        //     this.props.loading === false &&
        //     moment().diff(moment(this.props.lastSync, 'X'), 's') > constants.secondsBetweenLatestUpdate
        // ) {
        //     this.debouncedCheckSyncStatus();
        // }

        return (
            <Grid className="two-pane-layout modal-container" id="two-pane-layout" fluid={true}>
                <Row>
                    <Col className="col-fluid" data-path={this.props.location.pathname}>
                        <Switch>
                            <Route exact path="/devices" component={ManageInventory} />
                            <Route exact path="/devices/:installID" component={MeasurementPointListLayout} />
                            <Route exact path="/suggested_parts/:installID" component={SuggestedPartsLayout} />
                            <Route exact path="/jobs" component={ManageJob} />
                            <Route exact path="/job_comments" component={ManageJobComment} />
                            <Route exact path="/maintenance" component={ManageWorkOrder} />
                            {/* Differes from /maintenance, but follows same idea. This route is for repair adding assets to repair job*/}
                            <Route exact path="/repair" component={ManageInventory} />
                            {/* Differes from /repair, but follows same idea. This route is for commission adding assets to comissioning job*/}
                            <Route exact path="/commission" component={ManageInventory} />
                            <Route exact path="/sap" component={UnassignedSAPWorkOrderContainer} />
                            <Route
                                exact
                                path="/unassigned-sap-workorders"
                                component={UnassignedSAPWorkOrderContainer}
                            />
                            <Route exact path="/quote_part_cart" component={QuotePartCartLayout} />
                            <Route exact path="/warranty" component={ManageInventory} />
                            <Route exact path="/locations" component={ManageLocation} />
                            <Route exact path="/simple_list" component={SimpleMeasurementPointListLayout} />
                        </Switch>
                    </Col>
                </Row>
            </Grid>
        );
    }
}

const mapStateToProps = (state: IinitialState, ownProps: any) => {
    return {
        user: state.user,
        lastSync: state.syncStatus.lastSync,
        loading: selectIsLoading(state)
    };
};

export default connect(mapStateToProps, { checkSyncStatus, setLocationPathname })(TwoPaneLayout);
