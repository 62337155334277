import * as React from 'react';

import { TableUtil } from '../../common/TableUtil';
import ReactTable, { RowInfo } from 'react-table';
import { TFunction } from 'i18next';
import { IshoppingCart, IsuggestedPartPopulated } from '../../../models';
import { FC } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { deleteFromCart } from '../../../actions/shoppingCartActions';
import { deleteQuoteItemFromQuoteCart, saveQuoteCart } from '../../../actions/partsActions';

const buildColumns = (t: TFunction, handleDelete: (row: string) => void) => {
    return TableUtil.translateHeaders(
        [
            {
                Header: 'Part',
                id: 'part',
                accessor: ({ part }: IsuggestedPartPopulated) => `${part.number} - ${part.description}`,
                minWidth: 600
            },
            {
                Header: 'Quantity',
                accessor: 'quantity',
                minWidth: 150
            },
            {
                Header: '',
                accessor: 'id',
                Cell: row => (
                    <Button bsStyle="link" style={{ fontSize: '1.6em' }} onClick={() => handleDelete(row.original.id)}>
                        <FontAwesomeIcon icon={['far', 'times']} />
                    </Button>
                ),
                width: 100
            }
        ],
        t
    );
};

interface IdispatchProps {
    tableData: IsuggestedPartPopulated[];
    deleteFromCartCB: typeof deleteFromCart;
    saveQuoteCartCB: typeof saveQuoteCart;
    deleteQuoteItemFromQuoteCartCB: typeof deleteQuoteItemFromQuoteCart;
    selectedCart: IshoppingCart;
    t: TFunction;
    disabled: boolean;
}
interface RowInfoSuggestedPart extends RowInfo {
    original: IsuggestedPartPopulated;
}

export const RequestPartTable: FC<IdispatchProps> = ({
    tableData,
    t,
    deleteFromCartCB,
    saveQuoteCartCB,
    deleteQuoteItemFromQuoteCartCB,
    selectedCart,
    disabled
}) => {
    const handleDelete = (rowId: string) => {
        if (disabled) {
            return;
        }
        deleteFromCartCB(rowId, 'QUOTE_PARTS');
        deleteQuoteItemFromQuoteCartCB(rowId);
        saveQuoteCartCB('', Object.values(selectedCart.itemsByID));
    };
    return (
        <Row style={{ marginTop: '24px' }}>
            <Col xs={12} style={{ padding: ' 0 32px' }}>
                <ReactTable
                    data={tableData}
                    columns={buildColumns(t, handleDelete)}
                    pageSize={tableData.length}
                    className={`beacon-table -highlight blue`}
                    sortable={false}
                    noDataText={t('common:noDataText')}
                    resizable={false}
                    showPagination={false}
                />
            </Col>
        </Row>
    );
};
