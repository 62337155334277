/*
 * Signature Modal
 */

import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import CommissioningDataSignaturePad from './CommissioningDataSignaturePad';
import { IinstallBasePopulated, Ijob } from '../../models';
import { IinitialState } from '../../reducers';
import { WithCommonModal } from '../common/WithCommonModal';
import { jobSourceEnum, jobTypesIdEnum } from '../../models-enums';
import { selectIsLoading } from '../../reducers/commonReducers';
import {
    submitJobSignature,
    toggleJobSignatureModal,
    toggleIsJobClosingWithSignature
} from '../../actions/workOrderActions';
import { completeJobWithFinalCheck } from '../../actions/manageJobActions';

interface Iprops {
    colorButton: string;
    selectedJob: Ijob;
    installBasesPopulated: IinstallBasePopulated[];
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops & WithTranslation) => {
    const job = state.manageJob.jobsByID[ownProps.selectedJob.id];

    let shouldShow = true;
    // If this isn't a commissioning job, don't show, if it is, check this value to see if it has been asked to opened
    if (job.jobTypeID === jobTypesIdEnum.commissioning) {
        shouldShow = state.workOrder.showJobSignatureModal;
    } else {
        shouldShow = false;
    }

    const validateParts = job.source === jobSourceEnum.BPCS;
    const selectedJobSignatures = Object.values(state.manageJob.jobSignaturesByID).filter(
        signature => signature.jobID === ownProps.selectedJob.id
    );
    const { isJobClosingWithSignature } = state.workOrder;

    return {
        className: 'signature-modal',
        show: shouldShow,
        title: ownProps.t('commissioningDataSignaturePadModalTitle'),
        selectedJob: job,
        validateParts,
        user: state.user,
        selectedJobSignatures,
        isJobClosingWithSignature,
        installBasesPopulated: ownProps.installBasesPopulated,
        loading: selectIsLoading(state)
    };
};

export default withTranslation('manageWorkOrder')(
    connect(mapStateToProps, {
        submitSignature: submitJobSignature,
        toggleModal: toggleJobSignatureModal,
        toggleIsJobClosingWithSignature,
        completeJobWithFinalCheck,
        onHide: toggleJobSignatureModal
    })(WithCommonModal(CommissioningDataSignaturePad))
);
