// when saving the editPartForm - persist the form values to workOrderFormValues.parts
import { WithTranslation, withTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import {
    clearSelectedInstallBasePartID,
    saveInstallBasePart,
    setPartFormValues,
    setSelectedInstallBasePartID,
    updatePartFormValue
} from '../../actions/partsActions';
import { IinitialState } from '../../reducers';
import { selectIsLoading } from '../../reducers/commonReducers';
import PartForm from './PartForm';

import { keyBy, map } from 'lodash';
import { constants } from '../../constants/constants';
import { Ihistory, Ioption, IworkOrderPart } from '../../models';
import { jobStatusEnum } from '../../models-enums';
import { getIsMobile } from '../../reducers/configReducer';
import { initialInstallBasePartPopulated } from '../../reducers/initialState';
import { makeSelectInstallBasesPopulatedTest } from '../../reducers/manageInventoryReducer';
import {
    selectAllInstallBasePartsForInstallBaseAndJob,
    selectAllParts,
    selectInstallBasePartsPopulated,
    selectJobPartsForSelectedJob,
    selectPartOptions,
    selectPartStocksForUser
} from '../../reducers/partsReducer';

interface Iprops {
    colorButton?: string;
    disabled?: boolean;
    installID: string;
    history: Ihistory;
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops & WithTranslation) => {
    const { selectedInstallBasePartID } = state.parts;
    const installBasePartsPopulated = selectInstallBasePartsPopulated(state);
    const selectedInstallBasePartPopulated =
        installBasePartsPopulated[selectedInstallBasePartID] || initialInstallBasePartPopulated;

    const workOrderID = state.workOrder.selectedWorkOrderID;
    let partOptions = selectPartOptions(state);

    const installBaseParts = selectAllParts(state);
    const selectedJobID = state.manageJob.selectedJob.id;
    const tableParts = selectAllInstallBasePartsForInstallBaseAndJob(state, {
        installBaseID: ownProps.installID
    });

    // changed selector to get enough information to determine virtual install bases
    const installBases = makeSelectInstallBasesPopulatedTest(state);

    // Need to grab all WorkOrderParts for the current Work Order, then tack those onto the partOptions array (could probably rework this into the selector)
    let woParts: IworkOrderPart[] = [];
    let woPartsForVirtualInstallBases: IworkOrderPart[] = [];

    const selectedJob = state.manageJob.selectedJob;

    // Let's handle Work order parts now, work order parts can only be used if the Job is new, if it's been closed and repopened, you cannot
    // TODO move this logic to the selectPartOptions selector?
    if (selectedJob && selectedJob.status === jobStatusEnum.new) {
        const jobWorkOrders = Object.values(state.manageJob.jobWorkOrdersByID).filter(x => x.jobID === selectedJobID);

        // Check if this job has more than 1 work order
        const otherJobWorkOrders = jobWorkOrders.filter(x => x.workOrderID !== workOrderID);

        if (otherJobWorkOrders && otherJobWorkOrders.length > 0) {
            // Ok now check if the other work orders have any virtual install bases
            otherJobWorkOrders.forEach(jwo => {
                // Grab the work order from the JobWorkOrder
                const workOrder = state.workOrder.workOrdersByID[jwo.workOrderID];

                if (workOrder) {
                    // Now grab the Install base and check if it's virtual
                    const ib = installBases.find(x => x.id === workOrder.installBaseID);
                    const ibIsVirtual = ib?.product?.subcategory?.id === constants.virtualProductSubcategoryID;

                    if (ibIsVirtual && workOrder.parts && workOrder.parts.length > 0) {
                        woPartsForVirtualInstallBases = [...woPartsForVirtualInstallBases, ...workOrder.parts];
                    } else if (ibIsVirtual) {
                        // If the Work Order doesn't have work order parts attached to it, double check the workOrderPartsByID
                        // This seems to be necessary if you're logged in, and recieve a new SAP WO with parts, all the pieces do not connect without doing a sync, trying to prevent the need for that
                        const woPartsForWorkOrder = Object.values(state.parts.workOrderPartsByID).filter(
                            x => x.workOrderID === workOrder.id
                        );
                        if (woPartsForWorkOrder && woPartsForWorkOrder.length > 0) {
                            woPartsForVirtualInstallBases = [...woPartsForVirtualInstallBases, ...woPartsForWorkOrder];
                        }
                    }
                }
            });
        }

        map(state.parts.workOrderPartsByID, woPart => {
            const wo = state.workOrder.workOrdersByID[woPart.workOrderID];
            if (wo) {
                if (woPart.workOrderID === workOrderID) {
                    woParts.push(woPart);
                }
            }
        });

        jobWorkOrders.forEach(jwo => {
            if (jwo.workOrder?.installBaseID === ownProps.installID && jwo.workOrder?.parts?.length) {
                jwo.workOrder.parts.forEach(woPart => {
                    const part = state.parts.partsByID[woPart.partID];

                    if (part) {
                        const partOption = {
                            label: `(${woPart.estimated}) ${part.number} - ${part.description} - [work order part]: ${jwo.workOrder?.number}`,
                            value: part.id
                        };
                        partOptions = [...partOptions, partOption];
                    }
                });
            }
        });

        // Did we find any virtual install bases with work order parts?
        if (woPartsForVirtualInstallBases && woPartsForVirtualInstallBases.length > 0) {
            woParts = [...woParts, ...woPartsForVirtualInstallBases];

            // Add the work order parts to the partOptions array
            woPartsForVirtualInstallBases.forEach(woPart => {
                const part = state.parts.partsByID[woPart.partID];
                const workOrder = jobWorkOrders.find(jwo => jwo.workOrderID === woPart.workOrderID)?.workOrder;
                if (part && workOrder) {
                    const workOrder = jobWorkOrders.find(jwo => jwo.workOrderID === woPart.workOrderID)?.workOrder;
                    const partOption: Ioption = {
                        label: `(${woPart.estimated}) ${part.number} - ${part.description} - [work order part]: ${workOrder?.number}`,
                        value: part.id
                    };
                    partOptions = [...partOptions, partOption];
                }
            });
        }
    }

    partOptions
        .sort((a, b) => b.label.localeCompare(a.label))
        .sort(a => (a.label.includes('[work order part]') ? -1 : 0));

    const woPartsByID = keyBy(woParts, 'id');
    const isUserUSA = constants.usCountryID === state.user.countryID;
    const { historicalWorkOrderID } = state.workOrder;
    const { historicalResultID } = state.measurementPointResults;
    const disabled = historicalWorkOrderID.length > 0 || historicalResultID.length > 0;
    const selectedParts = selectAllInstallBasePartsForInstallBaseAndJob(state, {
        installBaseID: ownProps.installID
    });

    return {
        partOptions,
        formValues: state.parts.partFormValues,
        installBaseID: ownProps.installID,
        loading: selectIsLoading(state),
        selectedInstallBasePartPopulated,
        selectedJobID,
        partStocks: selectPartStocksForUser(state),
        jobParts: selectJobPartsForSelectedJob(state),
        workOrderParts: woPartsByID,
        standardOptions: state.productInfo.standardOptions,
        tableParts,
        user: state.user,
        workOrderID,
        isUserUSA,
        disabled,
        installBaseParts,
        isMobile: getIsMobile(state),
        selectedParts
    };
};

export default withTranslation('manageWorkOrder')(
    connect(mapStateToProps, {
        clearSelectedInstallBasePartID,
        setFormValues: setPartFormValues,
        setSelectedInstallBasePartID,
        updateFormValue: updatePartFormValue,
        saveInstallBasePart
    })(PartForm)
);
