import React from 'react';
import { TFunction } from 'i18next';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import CommonMobileModal from '../../common/CommonMobileModal';
import NewPhotoForm from '../NewPhotoForm';
import { addNewPhoto } from '../../../actions/photosActions';

const iconCamera = require('../../../images/icons/icon-camera.svg');

interface IProps {
    getShowCameraComponent: (handleClose: () => void) => JSX.Element | null;
    getAttachImageComponent: () => JSX.Element | null;
    showAddNewPhotoModal: boolean;
    onAddNewModalHide: () => void;
    t: TFunction;
    addNewPhoto: typeof addNewPhoto;
    images: { id: string; url: string }[];
    installBaseID: string;
    showCamera: boolean;
    handleCameraOpen: () => void;
    handleCameraClose: () => void;
    showHistoricalImagesModal: () => void;
}

const ButtonSection = styled.div`
    display: flex;
    gap: 24px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    line-height: 0px !important;
    flex-wrap: wrap;
`;

const MobileButton = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8.67px;
    height: 36px;
`;

export default (props: IProps) => {
    return (
        <div className="take-photo-control">
            {!props.showAddNewPhotoModal && (
                <ButtonSection>
                    <MobileButton bsStyle="primary" onClick={props.handleCameraOpen}>
                        <img src={iconCamera} />
                        <span>Add New Image</span>
                    </MobileButton>
                    {props.getAttachImageComponent()}
                    <MobileButton bsStyle="primary" onClick={props.showHistoricalImagesModal}>
                        {props.t('historicalImagesButton')}
                    </MobileButton>
                </ButtonSection>
            )}
            {props.showCamera && props.getShowCameraComponent(props.handleCameraClose)}
            <CommonMobileModal
                show={props.showAddNewPhotoModal}
                onHide={() => props.onAddNewModalHide()}
                className=""
                title="Add Image"
            >
                <NewPhotoForm
                    images={props.images}
                    t={props.t}
                    loading={false}
                    installBaseID={props.installBaseID}
                    addNewPhoto={props.addNewPhoto}
                    toggleModal={props.onAddNewModalHide}
                />
            </CommonMobileModal>
        </div>
    );
};
