/*
 * Edit Facility Form
 * Add and Edit facilities
 *
 */

import React, { useEffect, useMemo } from 'react';
import { Validators, FormGenerator, FormGroup, GroupProps, FormArray } from 'react-reactive-form';
import { Col, Button } from 'react-bootstrap';
import { constants, postalAndStateRequiredCountries } from '../../constants/constants';
import { toastr } from 'react-redux-toastr';
import { WithTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FormUtil } from '../common/FormUtil';
import { IproductInfo, Iuser, IfacilityWithoutBuildings, Icountry } from '../../models';

import { IaddFacilityFormValues } from '../../modelsForms';

// add the bootstrap form-control class to the react-select select component

const MobileButtonGroup = styled.div`
    position: fixed;
    bottom: 0;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #f5f5f5;
    width: 100vw;
    gap: 24px;

    & > button {
        flex: 1;
    }
`;

const getIsRequiredFromSelectedCountry = (selectedCountry?: Icountry) => {
    if (!selectedCountry) {
        return {
            state: false,
            postalCode: false
        };
    }

    return postalAndStateRequiredCountries[selectedCountry.value];
};

interface Iprops {
    handleSubmit: (facility: Partial<IfacilityWithoutBuildings>) => void;
    toggleModal: () => void;
    loading: boolean;
    colorButton: string;
    updateFormValue: (formValue: { [key: string]: any }) => void;
    setFormValues: (formValues: IaddFacilityFormValues) => void;
    formValues: IaddFacilityFormValues;
    getProductInfo: () => void;
    productInfo: IproductInfo;
    user: Iuser;
    isMobile: boolean;
}

const AddFacilityForm = ({
    productInfo,
    isMobile,
    formValues,
    user,
    t,
    updateFormValue,
    handleSubmit,
    toggleModal,
    colorButton,
    getProductInfo,
    loading
}: Iprops & WithTranslation) => {
    const [formGroup, setFormGroup] = React.useState<FormGroup | any>();

    const onValueChanges = (value: any, key: string) => {
        if (key === 'countryID') {
            updateFormValue({ state: undefined });
        }
        updateFormValue({ [key]: value });
    };

    useEffect(() => {
        if (productInfo.standardOptions.length === 0) {
            getProductInfo();
        }
    }, [productInfo.standardOptions.length]);

    useEffect(() => {
        if (!formGroup) {
            return;
        }

        for (const key in formGroup.controls) {
            formGroup.get(key).valueChanges.subscribe((value: any) => {
                onValueChanges(value, key);
            });
        }
    }, [formGroup]);

    const fieldConfig = useMemo(() => {
        const disabled = false;
        const countryOptions = constants.countries;
        const countryIDToUse = formValues.countryID ? formValues.countryID : constants.countries[user.countryID];
        const isStateRequired = getIsRequiredFromSelectedCountry(countryIDToUse)
            ? getIsRequiredFromSelectedCountry(countryIDToUse).state
            : false;
        const isPostalCodeRequired = getIsRequiredFromSelectedCountry(countryIDToUse)
            ? getIsRequiredFromSelectedCountry(countryIDToUse).postalCode
            : false;

        const fieldConfigControls = {
            name: {
                options: {
                    validators: [FormUtil.validators.requiredWithTrim]
                },
                render: FormUtil.TextInput,
                meta: {
                    label: 'facilityForm.nameLabel',
                    colWidth: 12,
                    name: 'fac-name'
                },
                formState: { value: formValues.name, disabled }
            },
            name2: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'facilityForm.contactName2',
                    colWidth: 12,
                    name: 'fac-name2',
                    required: false
                },
                formState: { value: formValues.name2, disabled }
            },
            standardID: {
                render: FormUtil.Select,
                meta: {
                    options: productInfo.standardOptions,
                    label: 'standard',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    isMulti: false,
                    name: 'standard',
                    required: true
                },
                options: {
                    validators: [FormUtil.validators.requiredWithTrim]
                },
                formState: {
                    value: formValues.standardID,
                    disabled
                }
            },
            address: {
                options: {
                    validators: [FormUtil.validators.requiredWithTrim]
                },
                render: FormUtil.TextInput,
                meta: {
                    label: 'user:address',
                    colWidth: isMobile ? 12 : 6,
                    type: 'text',
                    name: 'address'
                },
                formState: { value: formValues.address, disabled }
            },
            address2: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'user:address2',
                    colWidth: isMobile ? 12 : 6,
                    type: 'text',
                    name: 'address2',
                    required: false
                },
                formState: { value: formValues.address2, disabled }
            },
            city: {
                options: {
                    validators: [FormUtil.validators.requiredWithTrim]
                },
                render: FormUtil.TextInput,
                meta: {
                    label: 'user:city',
                    colWidth: isMobile ? 12 : 5,
                    type: 'text',
                    name: 'city'
                },
                formState: { disabled, value: formValues.city }
            },
            state: {
                options: {
                    validators: isStateRequired === true ? [FormUtil.validators.requiredWithTrim] : null
                },
                render: FormUtil.TextInput,
                meta: {
                    label: 'user:state',
                    colWidth: isMobile ? 12 : 3,
                    type: 'text',
                    name: 'state',
                    required: isStateRequired
                },
                formState: {
                    value: formValues.state,
                    disabled: false
                }
            },
            postalCode: {
                options: {
                    validators:
                        isPostalCodeRequired === true
                            ? [
                                  FormUtil.validators.requiredWithTrim,
                                  Validators.pattern(/^[a-zA-Z0-9][a-zA-Z0-9\- ]{0,10}[a-zA-Z0-9]$/)
                              ]
                            : null
                },
                render: FormUtil.TextInput,
                meta: {
                    label: 'user:zip',
                    colWidth: isMobile ? 12 : 4,
                    type: 'tel',
                    name: 'postal-code',
                    required: isPostalCodeRequired
                },
                formState: {
                    value: formValues.postalCode,
                    disabled: false
                }
            },
            type: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'facilityForm.facilityType',
                    colWidth: 12,
                    type: 'text',
                    name: 'type',
                    required: false
                },
                formState: { value: formValues.type, disabled }
            },
            countryID: {
                options: {
                    validators: FormUtil.validators.requiredWithTrim
                },
                render: FormUtil.Select,
                meta: {
                    options: countryOptions,
                    label: 'user:country',
                    colWidth: 12,
                    placeholder: 'manageUserQueue:countrySearchPlaceholder',
                    name: 'country'
                },
                formState: {
                    value: formValues.countryID ? formValues.countryID : constants.countries[user.countryID],
                    disabled
                }
            },
            sapFacilityNumber: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'facilityForm.sapFacilityNumber',
                    colWidth: 12,
                    type: 'text',
                    name: 'sapFacilityNumber',
                    required: false
                },
                formState: { disabled, value: formValues.sapFacilityNumber }
            },
            comments: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'common:comments',
                    colWidth: 12,
                    componentClass: 'textarea',
                    rows: 5,
                    name: 'comments',
                    initialContent: formValues.comments,
                    required: false
                },
                options: {
                    validators: [Validators.maxLength(1000)]
                },
                formState: { value: formValues.comments, disabled }
            }
        } as { [key: string]: GroupProps };

        return FormUtil.translateForm(
            {
                controls: { ...fieldConfigControls }
            },
            t
        );
    }, [user.countryID, formValues.countryID, isMobile]);

    const formValuesToItem = () => {
        const fv = FormUtil.getValues(formGroup.value);

        return { ...fv };
    };

    const handleFormSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (formGroup.status === 'INVALID') {
            formGroup.markAsSubmitted();
            toastr.error(t('toastMessage:invalidFormSubmission'), '', constants.toastrError);
            return;
        }

        handleSubmit(formValuesToItem());
    };

    const setForm = (form: FormGroup | FormArray) => {
        const fg = form;

        fg.meta = {
            loading
        };

        setFormGroup(fg);
    };
    const ButtonGroup = isMobile ? MobileButtonGroup : Col;

    return (
        <form onSubmit={handleFormSubmit} className="clearfix beacon-form facility-form">
            <FormGenerator onMount={setForm} fieldConfig={fieldConfig} />
            <ButtonGroup xs={12} className={isMobile ? undefined : 'form-buttons text-right'}>
                <Button
                    bsStyle="default"
                    type="button"
                    className="pull-left"
                    onClick={() => {
                        toggleModal();
                    }}
                >
                    {t('cancel')}
                </Button>

                <Button bsStyle={colorButton} type="submit" disabled={loading}>
                    {t('save')}
                </Button>
            </ButtonGroup>
        </form>
    );
};

export default AddFacilityForm;
