/*
 * Work Order Close Form
 */

import { Col, Button } from 'react-bootstrap';
import { FormGenerator, AbstractControl, FieldConfig, FormGroup } from 'react-reactive-form';

import * as React from 'react';
import { toastr } from 'react-redux-toastr';

import { constants } from '../../constants/constants';
import { FormUtil } from '../common/FormUtil';
import { IWorkOrder, IinstallBasePopulated } from '../../models';
import { workOrderStatusEnum } from '../../models-enums';
import { TFunction } from 'i18next';
import { passSelected } from '../../actions/measurementPointResultsActions';

interface Iprops {
    toggleModal: () => void;
    loading: boolean;
    selectedWorkOrder: IWorkOrder;
    colorButton: string;
    t: TFunction;
    selection: string[];
    passSelected: typeof passSelected;
    installBasesPopulated: IinstallBasePopulated[];
}

class WorkOrderCloseForm extends React.Component<Iprops, {}> {
    private form: FormGroup | any;
    public fieldConfig: FieldConfig;

    constructor(props: Iprops) {
        super(props);
        this.fieldConfig = FormUtil.translateForm(this.buildFieldConfig(), this.props.t);
    }

    buildFieldConfig = () => {
        const fieldConfigControls = {
            closingNotes: {
                options: {
                    validators: [FormUtil.validators.requiredWithTrim]
                },
                render: FormUtil.TextInput,
                meta: {
                    label: 'closingNote',
                    componentClass: 'textarea',
                    colWidth: 12,
                    rows: 10,
                    type: 'input',
                    autoFocus: true,
                    name: 'closing-note'
                }
            }
        };

        return {
            controls: { ...fieldConfigControls }
        };
    };

    handleSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (this.form.status === 'INVALID') {
            this.form.markAsSubmitted();
            toastr.error('Please check invalid inputs', '', constants.toastrError);
            return;
        }
        const { closingNotes } = this.form.value;
        this.props.passSelected(this.props.t, closingNotes);
        this.props.toggleModal();
    };

    setForm = (form: AbstractControl) => {
        this.form = form;
        this.form.meta = {
            loading: this.props.loading
        };
    };

    render() {
        const { t } = this.props;
        const { status } = this.props.selectedWorkOrder;
        const formClassName = `beacon-form location-form ${this.props.colorButton}`;

        let button;
        if (status !== workOrderStatusEnum.complete) {
            button = (
                <Button bsStyle={this.props.colorButton} type="submit" disabled={this.props.loading}>
                    {t('common:save')}
                </Button>
            );
        }

        return (
            <div>
                <form onSubmit={this.handleSubmit} className={formClassName}>
                    <FormGenerator onMount={this.setForm} fieldConfig={this.fieldConfig} />
                    <Col xs={12} className="form-buttons text-right">
                        <Button bsStyle="default" type="button" className="pull-left" onClick={this.props.toggleModal}>
                            {t('common:cancel')}
                        </Button>
                        {button}
                    </Col>
                </form>
            </div>
        );
    }
}
export default WorkOrderCloseForm;
