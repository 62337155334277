import { IinitialState } from '../../reducers';
import initialState from '../../reducers/initialState';

/*
 * Migration 1 runs when upgrading from 0 to 1
 */

const typedMigrations = {
    199: (state: IinitialState): IinitialState => {
        console.info('[redux-persist]: running migration 196', state.appVersion);
        const { manageJob, syncStatus, workOrder, manageInventory } = initialState;
        return {
            ...state,
            manageJob,
            syncStatus,
            workOrder,
            manageInventory
        };
    },
    200: (state: IinitialState): IinitialState => {
        console.info('[redux-persist]: running migration 200', state.appVersion);
        const { parts } = initialState;
        return {
            ...state,
            parts
        };
    },
    211: (state: IinitialState): IinitialState => {
        console.info('[redux-persist]: running migration 211', state.appVersion);
        const { parts, syncStatus } = initialState;
        return {
            ...state,
            parts,
            syncStatus
        };
    },
    223: (state: IinitialState): IinitialState => {
        console.info('[redux-persist]: running migration 223', state.appVersion);
        const { measurementPointResults, syncStatus } = initialState;
        return {
            ...state,
            measurementPointResults,
            syncStatus
        };
    },
    226: (state: IinitialState): IinitialState => {
        console.info('[redux-persist]: running migration 226', state.appVersion);
        const { measurementPointResults, syncStatus, offlineStatusByFacilityID, offlineStatusByJobID } = initialState;
        return {
            ...state,
            measurementPointResults,
            syncStatus,
            offlineStatusByFacilityID,
            offlineStatusByJobID
        };
    },
    227: (state: IinitialState): IinitialState => {
        console.info('[redux-persist]: running migration 227', state.appVersion);
        const {
            measurementPointResults,
            syncStatus,
            offlineStatusByFacilityID,
            offlineStatusByJobID,
            manageJob
        } = initialState;
        return {
            ...state,
            measurementPointResults,
            syncStatus,
            offlineStatusByFacilityID,
            offlineStatusByJobID,
            manageJob
        };
    },
    228: (state: IinitialState): IinitialState => {
        console.info('[redux-persist]: running migration 228', state.appVersion);
        const { syncStatus, manageJob } = initialState;
        return {
            ...state,
            syncStatus,
            manageJob
        };
    },
    230: (state: IinitialState): IinitialState => {
        console.info('[redux-persist]: running migration 230', state.appVersion);
        const { cart, manageJob, syncStatus, parts } = initialState;
        return {
            ...state,
            selectedCart: cart,
            manageJob,
            syncStatus,
            parts
        };
    },
    231: (state: IinitialState): IinitialState => {
        console.info('[redux-persist]: running migration 231', state.appVersion);
        const { manageJob, syncStatus } = initialState;
        return {
            ...state,
            manageJob,
            syncStatus
        };
    },
    232: (state: IinitialState): IinitialState => {
        console.info('[redux-persist]: running migration 232', state.appVersion);
        const { parts, syncStatus } = initialState;
        return {
            ...state,
            parts,
            syncStatus
        };
    },
    233: (state: IinitialState): IinitialState => {
        console.info('[redux-persist]: running migration 233', state.appVersion);
        const { workOrder } = initialState;
        return {
            ...state,
            workOrder
        };
    }
} as { [key: number]: (state: IinitialState) => IinitialState };

const untypedMigrations = typedMigrations as any;
export const rootMigrations = untypedMigrations;
