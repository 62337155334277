/*
 * Inventory Action Button Container connets the Inventory Action Button to redux
 */

import {
    bulkUpdateInstalls,
    setEnableInstallBatchMode,
    startBulkUpdateInstallProduct,
    toggleEditInstallModal,
    toggleSearchNewProductsModal,
    toggleEditJobDefaultsModal,
    toggleCommissioningDataFormModal,
    toggleModalSignaturePad,
    getReportPreview,
    toggleModalAddFSE,
    toggleModalEditFacilityContacts
} from '../../actions/manageInventoryActions';
import { completeJob, toggleEditJobModal, unlinkWorkOrders } from '../../actions/manageJobActions';

import { passSelected, startBulkCannotCompleteSelected } from '../../actions/measurementPointResultsActions';
import {
    toggleJobSignatureModal,
    toggleAddHoursModal,
    toggleWorkOrderCloseModal,
    toggleConfirmSelectJobModal,
    toggleIsJobClosingWithSignature,
    toggleIsCollectingSignatures
} from '../../actions/workOrderActions';

import { IinitialState } from '../../reducers';
import { IinstallBasePopulated } from '../../models';
import { InventoryActionButton } from './InventoryActionButton';
import { RouteComponentProps } from 'react-router';
import { TFunction } from 'i18next';
import { connect } from 'react-redux';
import { jobStatusEnum } from '../../models-enums';
import { jobTypesIdEnum } from '../../models-enums';
import { selectIsLoading } from '../../reducers/commonReducers';
import { countriesWithJobDefaults } from '../../constants/constants';

interface Iprops extends RouteComponentProps<any> {
    t: TFunction;
    colorButton: string;
    installBasesPopulated: IinstallBasePopulated[];
    resetSelection: () => void;
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops) => {
    const { user } = state;
    const { selectedJob } = state.manageJob;
    const showCompleteJobButton =
        selectedJob.assignedUserID === state.user.id && selectedJob.status !== jobStatusEnum.completed;
    const { jobTypeID } = selectedJob;
    const isAuditJob = jobTypeID === jobTypesIdEnum.audit;
    const isCommissioningJob = jobTypeID === jobTypesIdEnum.commissioning;
    const isMaintenanceJob = jobTypeID === jobTypesIdEnum.maintenance;
    const isRepairJob = jobTypeID === jobTypesIdEnum.repair;
    const isVerificationJob = jobTypeID === jobTypesIdEnum.verification;
    const isWarrantyJob = jobTypeID === jobTypesIdEnum.warrantyBM;
    const isAgsJob = jobTypeID === jobTypesIdEnum.agsRebalancing;
    const isAnnualJob = jobTypeID === jobTypesIdEnum.inspection;
    const isServicePlanJob = jobTypeID === jobTypesIdEnum.servicePlan;

    const jobFacility = state.facilities.facilitiesByID[selectedJob.facilityID];

    const showEditJobDefaultsButton =
        jobTypeID === jobTypesIdEnum.inspection &&
        jobFacility &&
        countriesWithJobDefaults.includes(jobFacility.countryID);

    const workOrders = state.workOrder.workOrdersByID;
    const installBaseParts = state.parts.installBasePartsByID;

    return {
        loading: selectIsLoading(state),
        showCompleteJobButton,
        showEditJobDefaultsButton,
        selection: state.manageInventory.selection,
        isAuditJob,
        isCommissioningJob,
        isMaintenanceJob,
        isRepairJob,
        isVerificationJob,
        isWarrantyJob,
        isServicePlanJob,
        isAgsJob,
        isAnnualJob,
        selectedJob,
        online: state.offline.online,
        workOrders,
        installBaseParts,
        user
    };
};

export const InventoryActionButtonContainer = connect(mapStateToProps, {
    toggleEditJobModal,
    toggleSearchNewProductsModal,
    passSelected,
    startBulkCannotCompleteSelected,
    completeJob,
    toggleEditInstallModal,
    setEnableInstallBatchMode,
    startBulkUpdateInstallProduct,
    bulkUpdateInstalls,
    toggleWorkOrderCloseModal,
    toggleAddHoursModal,
    toggleConfirmSelectJobModal,
    toggleEditJobDefaultsModal,
    toggleJobSignatureModal,
    toggleModalSignaturePad,
    toggleIsJobClosingWithSignature,
    toggleIsCollectingSignatures,
    toggleCommissioningDataFormModal,
    getReportPreview,
    unlinkWorkOrders,
    toggleModalAddFSE,
    toggleModalEditFacilityContacts
})(InventoryActionButton);
