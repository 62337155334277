/*
 * Preventative Maintenance Table
 */

import * as React from 'react';

import { IWorkOrder, IjobWorkOrder, ItableFiltersReducer, Itile, Iuser, Ijob } from '../../models';
import ReactTable, { FinalState, RowInfo, SortingRule } from 'react-table';
import { find } from 'lodash';
import selectTableHOC, { SelectTableAdditionalProps } from 'react-table/lib/hoc/selectTable';
import { setSelectedWorkOrderID, setTableFilter, updateWorkOrderSelection } from '../../actions/workOrderActions';

import { TFunction } from 'i18next';
import { TableUtil } from '../common/TableUtil';
import { constants } from '../../constants/constants';
import ConfirmSelectJobModal from '../workOrder/ConfirmSelectJobModal';
import { emptyTile } from '../../reducers/initialState';
import { RouteComponentProps } from 'react-router-dom';
import { WithTranslation } from 'react-i18next';
import { workOrderTypesEnum } from '../../models-enums';

const SelectTable = selectTableHOC(ReactTable);
const columns = (t: TFunction, jobsByID: { [key: string]: Ijob }) =>
    TableUtil.translateHeaders(
        [
            {
                Header: 'Activity',
                accessor: 'activityDescription',
                width: 220
            },
            {
                Header: 'manageWorkOrder:productName',
                accessor: 'productName',
                minWidth: 180
            },
            {
                Header: 'Location',
                accessor: 'installBaseLocation',
                minWidth: 160
            }
        ],
        t
    );

interface Iprops extends RouteComponentProps<any> {
    tableData: IWorkOrder[];
    t: TFunction;
    setSelectedWorkOrderID: typeof setSelectedWorkOrderID;
    loading: boolean;
    tableFilters: ItableFiltersReducer;
    setTableFilter: typeof setTableFilter;
    user: Iuser;
    selection: string[];
    updateWorkOrderSelection: typeof updateWorkOrderSelection;
    jobWorkOrdersByID: { [key: string]: IjobWorkOrder };
    jobsByID: { [key: string]: Ijob };
    selectedJob: Ijob;
    preventativeMaintenanceEnabled?: boolean;
}
interface Istate {
    selectedRow: any;
    columns: any[];
    selectAll: boolean;
    currentTile: Itile;
}
interface RowInfoWorkOrder extends RowInfo {
    original: IWorkOrder;
}

export class PreventativeMaintenanceTableWithSelect extends React.PureComponent<
    Iprops & SelectTableAdditionalProps & WithTranslation & RouteComponentProps,
    Istate
> {
    private checkboxTable: any;
    constructor(props: Iprops & SelectTableAdditionalProps & WithTranslation) {
        super(props);
        this.state = {
            selectedRow: {},
            columns: columns(this.props.t, this.props.jobsByID),
            currentTile: emptyTile,

            selectAll: false
        };
    }
    componentDidMount() {
        document.addEventListener('addedWorkOrdersToJob', this.handleAddedWorkOrders, false);
        this.props.updateWorkOrderSelection([]);
        this.setState({
            currentTile: constants.getTileByURL(this.props.location.pathname)
        });
    }
    componentWillUnmount() {
        document.removeEventListener('addedWorkOrdersToJob', this.handleAddedWorkOrders, false);
    }

    handleAddedWorkOrders = () => {
        this.props.updateWorkOrderSelection([]);
    };

    /*
     * (reusable)
     * Handle user clicking on a product row
     * set the selected product to state and open the modal
     */
    /**
     * Toggle a single checkbox for select table
     */
    toggleSelection = (key: string, shift: boolean, row: any) => {
        // start off with the existing state
        let selection = [...this.props.selection];
        const keyIndex = selection.indexOf(key);

        // check to see if the key exists
        if (keyIndex >= 0) {
            // it does exist so we will remove it using destructing
            selection = [...selection.slice(0, keyIndex), ...selection.slice(keyIndex + 1)];
        } else {
            // it does not exist so add it
            selection.push(key);
        }
        // update the state
        this.props.updateWorkOrderSelection(selection);
    };

    /**
     * Toggle all checkboxes for select table
     */
    toggleAll = () => {
        const { keyField = 'id' } = this.props;
        const selectAll = !this.state.selectAll;
        const selection: string[] = [];

        if (selectAll && keyField !== undefined) {
            // we need to get at the internals of ReactTable
            const wrappedInstance = this.checkboxTable.getWrappedInstance();
            // the 'sortedData' property contains the currently accessible records based on the filter and sort
            const currentRecords: any[] = wrappedInstance.getResolvedState().sortedData;
            // we just push all the IDs onto the selection array
            currentRecords.forEach(item => {
                selection.push(`select-${item._original[keyField]}`);
            });
        }
        this.setState({ selectAll });
        this.props.updateWorkOrderSelection(selection);
    };

    /**
     * Whether or not a row is selected for select table
     */
    isSelected = (key: string) => {
        return this.props.selection.includes(`select-${key}`);
    };
    getTrProps = (state: FinalState, rowInfo: RowInfoWorkOrder | undefined) => {
        // const { selection } = this.props;
        if (rowInfo) {
            const hasJobWorkOrder = find(this.props.jobWorkOrdersByID, {
                workOrderID: rowInfo.original.id
            });
            return {
                style: {
                    background:
                        rowInfo.index === this.state.selectedRow
                            ? constants.colors[`${this.state.currentTile.color}Tr` as keyof typeof constants.colors]
                            : '',
                    color: hasJobWorkOrder ? constants.colors.blue : 'inherit'
                },
                onClick: () => {
                    this.props.setSelectedWorkOrderID(rowInfo.original.id);
                    this.setState({
                        selectedRow: {
                            [rowInfo.viewIndex || 0]: !this.state.selectedRow[rowInfo.viewIndex || 0]
                        }
                    });
                }
            };
        } else {
            return {};
        }
    };
    onSortedChanged = (newSorted: SortingRule[], column: any, shiftKey: boolean) => {
        this.props.setTableFilter({ sorted: newSorted, page: 0 });
        this.setState({ selectedRow: {} });
    };

    onPageChange = (page: number) => {
        this.props.setTableFilter({ page });
    };
    onPageSizeChange = (rows: number) => {
        this.props.setTableFilter({ rows, page: 0 });
    };

    render() {
        const { tableData, t, loading } = this.props;
        const colorButton = constants.colors[`${this.state.currentTile.color}Button` as keyof typeof constants.colors];

        const noDataText = this.props.preventativeMaintenanceEnabled
            ? t('manageWorkOrder:noDataFacilityTasks')
            : t('manageWorkOrder:noDataFacilityTasksNotActive');
        let tableClassName = `beacon-table -highlight ${this.state.currentTile.color}`;

        if (this.props.selection.length) {
            tableClassName = `${tableClassName} with-action-button`;
        }
        return (
            <div className="preventative-maintenance">
                <SelectTable
                    data={tableData}
                    onSortedChange={this.onSortedChanged}
                    columns={this.state.columns}
                    getTrProps={this.getTrProps}
                    defaultPageSize={this.props.tableFilters.rows || constants.tablePageSizeDefault}
                    onPageSizeChange={this.onPageSizeChange}
                    showPageSizeOptions={true}
                    pageSizeOptions={constants.tablePageSizeOptions}
                    className={tableClassName}
                    previousText={t('common:previous')}
                    nextText={t('common:next')}
                    onPageChange={this.onPageChange}
                    sortable={false}
                    multiSort={false}
                    noDataText={noDataText}
                    resizable={false}
                    loading={loading}
                    toggleSelection={this.toggleSelection}
                    selectAll={this.state.selectAll}
                    selectType="checkbox"
                    toggleAll={this.toggleAll}
                    isSelected={this.isSelected}
                    ref={r => (this.checkboxTable = r)}
                    keyField="id"
                />

                <ConfirmSelectJobModal
                    colorButton={colorButton}
                    facilityID={this.props.selectedJob.facilityID}
                    workOrderMode={workOrderTypesEnum.pmp}
                    history={this.props.history}
                    location={this.props.location}
                    match={this.props.match}
                    forSAP={false}
                />
            </div>
        );
    }
}
