import React, { Component } from 'react';

class RiskAssessmentList extends Component {
    render() {
        return (
            <div>
                <p style={{ margin: '10px 18px', fontWeight: 600 }}>
                    Consider the following list as a minimum when carry out this assessment:
                </p>
                <ul style={{ paddingLeft: '32px' }}>
                    <li>Have you completed all of the customer site safety requirements?</li>
                    <li>Outdoor conditions: (Vehicle to Building). Is there a slipping hazard? (Rain/Ice)</li>
                    <li>Is there a danger of stumbling while walking around worksite?</li>
                    <li>Is there a Fall risk (compressor on platform/no safety railing ect.) Ladder use!</li>
                    <li>Is the lighting adequate?</li>
                    <li>Is there a danger that there are parts that are still under pressure or live?</li>
                    <li>Is there electrocution risk? (Is all power disconnected/locked-tested?)</li>
                    <li>Is equipment protected against unexpected return to on position? (lockout/tagout)</li>
                    <li>Is there a risk of touching hot parts (burn hazard)?</li>
                    <li>Do I have the right tool and is it in good condition?</li>
                    <li>If using a calibrated tool, is calibration current?</li>
                    <li>Is there a danger of flying dust?</li>
                    <li>
                        Does my personal protection offer sufficient protection? (safety shoes/hardhat/safety glasses)
                    </li>
                    <li>Is there fire exposure?</li>
                    <li>Do I have permission and permit for hot work from the customer and my Supervisor?</li>
                    <li>Is there a fire extinguisher in the workplace?</li>
                    <li>Did or can I remove combustible materials oil/filter etc.?</li>
                    <li>Is both my BM Office and the customer informed of my work location?</li>
                    <li>Do I know where I shoud go incase of an emergency alarm (escape)?</li>
                    <li>Am I dressed correctly for circumstances? (weather/temperature/environment)</li>
                    <li>Am I current on all Training especially for equip/task attempting to perform?</li>
                    <li>Do I have all customer Permissions? (LOTO/Hot-work/Confined Space/etc.)</li>
                    <li>
                        Determine all measures necessary to eliminate risks or reduce risk exposure to an acceeptable
                        level.
                    </li>
                    <li>Execute necessary measures identified in STEP 2. If needed request assistance.</li>
                    <li>Have all necessary mitigating measures been implemented?</li>
                    <li>Has the customer been informed?&nbsp;</li>
                </ul>
            </div>
        );
    }
}

export default RiskAssessmentList;
