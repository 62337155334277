import { toastr } from 'react-redux-toastr';
import { TFunction } from 'i18next';
import { IshoppingCartItem, ThunkResult } from '../models';
import { constants } from '../constants/constants';
import { CLEAR_CART } from './actionTypes';

export function addToCart(item: IshoppingCartItem, cartName: string, t: TFunction): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch({
            type: `ADD_TO_CART_${cartName}`,
            item
        });
        toastr.success(t('toastMessage:success'), t('toastMessage:addedToCart'), constants.toastrSuccess);
    };
}
export function clearCart(): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch({
            type: CLEAR_CART
        });
    };
}

export const decreaseFromCart = (itemID: string, cartName: string) => ({
    type: `DECREASE_FROM_CART_${cartName}`,
    itemID
});
export const updateQuantityCart = (quantity: number, itemID: string, cartName: string) => ({
    type: `UPDATE_QUANTITY_CART_${cartName}`,
    itemID,
    quantity
});
export const saveMessageInput = (message: string, cartName: string) => ({
    type: `UPDATE_MESSAGE_CART_${cartName}`,
    message
});
export const deleteFromCart = (itemID: string, cartName: string) => ({
    type: `DELETE_FROM_CART_${cartName}`,
    itemID
});
