import * as React from 'react';
import * as localForage from 'localforage';
import { FormControl, Button, ControlLabel } from 'react-bootstrap';
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toastr } from 'react-redux-toastr';
import { TFunction } from 'i18next';
import { Itile } from '../../models';
import CommonModal from '../common/CommonModal';
import { constants } from '../../constants/constants';
import { addNewPhoto } from '../../actions/photosActions';
import NewPhotoForm from './NewPhotoForm';
import 'react-html5-camera-photo/build/css/index.css';
import MobileTakePhotoControl from './Mobile/TakePhotoControl';
import HistoricalImagesModal from './HistoricalImagesModal';

const uuidv4 = require('uuid/v4');
const iconCamera = require('../../images/icons/icon-camera.svg');
const iconPhoto = require('../../images/icons/icon-photo.svg');
const maxPhotoSize = 31457280;
const maxWidth: number = 1200;

interface Iprops {
    installBaseID: string;
    loading: boolean;
    t: TFunction;
    currentTile: Itile;
    addNewPhoto: typeof addNewPhoto;
    isMobile: boolean;
}

interface Istate {
    images: { url: string; id: string }[];
    showAddNewPhotoModal: boolean;
    showCamera: boolean;
    showHistoricalImagesModal: boolean;
}

interface ImageData {
    id: string;
    url: string;
}

const resizeImage = async (file: Blob, maxWidth: number): Promise<Blob> => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = () => {
            const scaleFactor = maxWidth / img.width;
            const targetWidth = img.width * scaleFactor;
            const targetHeight = img.height * scaleFactor;
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = targetWidth;
            canvas.height = targetHeight;
            ctx?.drawImage(img, 0, 0, targetWidth, targetHeight);
            canvas.toBlob(blob => {
                if (blob) {
                    resolve(blob);
                } else {
                    reject(new Error('Error creating blob from canvas'));
                }
            }, file.type);
        };
        img.onerror = () => {
            reject(new Error('Error loading image'));
        };
    });
};

class TakePhotoControl extends React.PureComponent<Iprops, Istate> {
    static defaultProps = {
        currentTile: constants.getTileByURL(window.location.pathname)
    };

    constructor(props: Iprops) {
        super(props);
        this.state = {
            images: [],
            showAddNewPhotoModal: false,
            showCamera: false,
            showHistoricalImagesModal: false
        };
    }

    getFileUrl = (key: string) => {
        return localForage.getItem(key).then(item => {
            return window.URL.createObjectURL(item);
        });
    };

    handleTakePhoto = (dataUri: string): void => {
        const base64Length = dataUri.length - (dataUri.indexOf(',') + 1);
        const padding =
            dataUri.charAt(dataUri.length - 2) === '=' ? 2 : dataUri.charAt(dataUri.length - 1) === '=' ? 1 : 0;
        const fileSize = base64Length * 0.75 - padding;

        if (fileSize > maxPhotoSize) {
            toastr.error(this.props.t('toastMessage:invalidFormSubmission'), '', constants.toastrError);
        } else {
            const id = uuidv4();

            fetch(dataUri)
                .then(result => result.blob())
                .then(data => resizeImage(data, maxWidth))
                .then(resizedBlob => {
                    const resizedFile = new File([resizedBlob], id, { type: resizedBlob.type });
                    return localForage.setItem(id, resizedFile);
                })
                .then(value => {
                    this.setState({
                        images: [{ id, url: window.URL.createObjectURL(value) }],
                        showCamera: false,
                        showAddNewPhotoModal: true
                    });
                })
                .catch(err => console.error('[handleTakePhoto]:', err));
        }
    };

    handleCameraError = (error: any): void => {
        this.setState({ showCamera: false });
        const toastrConfirmOptions = {
            okText: this.props.t('common:ok'),
            disableCancel: true
        };
        toastr.confirm(error.message, toastrConfirmOptions);
    };

    // Handle file change function
    handleFileChange = async (fileList: FileList) => {
        const images: ImageData[] = [];

        for (let i = 0; i < fileList.length; i++) {
            const id = uuidv4();
            const originalFile = fileList[i];
            try {
                const resizedBlob = await resizeImage(originalFile, maxWidth);
                const resizedFile = new File([resizedBlob], originalFile.name, { type: resizedBlob.type });

                await localForage.setItem(id, resizedFile).then(value => {
                    images.push({ id, url: window.URL.createObjectURL(value) });
                });
            } catch (error) {
                console.error('Error resizing image:', error);
            }
        }
        this.setState({ showAddNewPhotoModal: true, images: images });
    };

    handleCameraOpen = () => {
        this.setState({ showCamera: true });
    };

    handleCameraClose = () => {
        this.setState({ showCamera: false });
    };

    getShowCameraComponent = (handleClose: () => void) => {
        return (
            <div className="camera-wrapper">
                <div className="btn-close" onClick={() => handleClose()}>
                    <FontAwesomeIcon icon={['far', 'times']} size="lg" />
                </div>
                <Camera
                    isFullscreen
                    isDisplayStartCameraError={false}
                    onTakePhoto={this.handleTakePhoto}
                    onCameraError={this.handleCameraError}
                    idealFacingMode={FACING_MODES.ENVIRONMENT}
                    isImageMirror={false}
                />
            </div>
        );
    };

    filesAreTooBig = (files: FileList, maxSize: number): boolean => {
        for (let i = 0; i < files.length; i++) {
            const size = files.item(i)?.size || 0;
            if (size > maxSize) {
                return true;
            }
        }
        return false;
    };

    getAttachImageComponent = () => {
        return (
            <ControlLabel htmlFor="fileUpload" style={{ cursor: 'pointer', margin: 0 }}>
                <span className="btn btn-default">
                    <img src={iconPhoto} alt="" />
                    Attach Image
                </span>
                <FormControl
                    accept="image/png, image/jpeg"
                    id={'fileUpload'}
                    type="file"
                    multiple={true}
                    name="product-image"
                    onChange={(event: any) => {
                        const fileInput = event.target as HTMLInputElement;
                        const files = fileInput.files as FileList;
                        if (this.filesAreTooBig(files, maxPhotoSize)) {
                            toastr.error(this.props.t('toastMessage:invalidFormSubmission'), '', constants.toastrError);
                            return;
                        }
                        this.handleFileChange(files);
                    }}
                    style={{ display: 'none' }}
                />
            </ControlLabel>
        );
    };

    render() {
        const { t, isMobile } = this.props;
        const historicImages = (
            <HistoricalImagesModal
                show={this.state.showHistoricalImagesModal}
                hide={() => this.setState({ showHistoricalImagesModal: false })}
                t={t}
            />
        );

        if (isMobile) {
            return (
                <>
                    <MobileTakePhotoControl
                        images={this.state.images}
                        getShowCameraComponent={this.getShowCameraComponent}
                        getAttachImageComponent={this.getAttachImageComponent}
                        showCamera={this.state.showCamera}
                        showAddNewPhotoModal={this.state.showAddNewPhotoModal}
                        onAddNewModalHide={() => this.setState({ showAddNewPhotoModal: false })}
                        handleCameraClose={this.handleCameraClose}
                        handleCameraOpen={this.handleCameraOpen}
                        t={t}
                        addNewPhoto={this.props.addNewPhoto}
                        installBaseID={this.props.installBaseID}
                        showHistoricalImagesModal={() => this.setState({ showHistoricalImagesModal: true })}
                    />
                    {historicImages}
                </>
            );
        }

        return (
            <div className="take-photo-control">
                <Button bsStyle="primary" onClick={this.handleCameraOpen}>
                    <img src={iconCamera} alt="" />
                    Add New Image
                </Button>
                {this.state.showCamera && this.getShowCameraComponent(this.handleCameraClose)}
                {this.getAttachImageComponent()}
                <Button
                    bsStyle="primary"
                    style={{ marginLeft: '016px' }}
                    onClick={() => this.setState({ showHistoricalImagesModal: true })}
                >
                    {t('historicalImagesButton')}
                </Button>
                {historicImages}
                <CommonModal
                    className="job-edit"
                    show={this.state.showAddNewPhotoModal}
                    onHide={() => this.setState({ showAddNewPhotoModal: false })}
                    title="Edit Image"
                    container={document.getElementById('two-pane-layout')}
                >
                    <NewPhotoForm
                        images={this.state.images}
                        t={t}
                        loading={this.props.loading}
                        installBaseID={this.props.installBaseID}
                        addNewPhoto={this.props.addNewPhoto}
                        toggleModal={() => this.setState({ showAddNewPhotoModal: false })}
                    />
                </CommonModal>
            </div>
        );
    }
}
export default TakePhotoControl;
