import { filter, forEach, pick } from 'lodash';
import * as React from 'react';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import { AbstractControl, FieldConfig, FormGenerator, GroupProps, ValidatorFn, Validators } from 'react-reactive-form';
import { Redirect, RouteComponentProps } from 'react-router';
import { setSelectedMeasurementPointListByID } from '../../actions/measurementPointListActions';
import {
    clearHistoricalResultID,
    initSelectedResult,
    submitMeasurementPointResult,
    updateMprAnswers
} from '../../actions/measurementPointResultsActions';
import { setSelectedWorkOrderID, toggleConfirmSelectJobModal } from '../../actions/workOrderActions';
import {
    IWorkOrder,
    IinstallBase,
    IinstallBasePopulated,
    Ijob,
    ImeasurementPoint,
    ImeasurementPointAnswer,
    ImeasurementPointList,
    ImeasurementPointListTab,
    ImeasurementPointResult,
    ImeasurementPointResultWithType,
    Iproduct,
    IproductInfo,
    Iuser
} from '../../models';
import {
    jobStatusEnum,
    jobTypesIdEnum,
    measurementPointAnswerTypes,
    measurementPointResultStatusTypesEnum,
    measurementPointTypesEnum,
    workOrderTypesEnum
} from '../../models-enums';

import { TFunction } from 'i18next';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import { getInventory, setSelectedProductByID } from '../../actions/manageInventoryActions';
import { getAssignedJobs } from '../../actions/manageJobActions';
import { constants } from '../../constants/constants';
import { FormUtil } from '../common/FormUtil';
import { FormUtilMeasurementPoints } from '../common/FormUtilMobile';
import { PartsControl } from '../parts/PartsControl';
import { PhotosControl } from '../photos/PhotosControl';
import { WorkOrderLayout } from '../workOrder/WorkOrderLayout';
import { getAllFormValues } from './MeasurementPointListFormUtils';
import { PointListButtonBarContainer } from './PointListButtonBarContainer';

import { useSelector } from 'react-redux';
import { selectHistoricalResultID } from '../../reducers/measurementPointResultsReducer';
import { BannerContainer } from '../common/BannerContainer';
import RequestPartContainer from '../parts/RequestPart/RequestPartContainer';
import SAPDamageCodes from '../workOrder/SAP/SAPDamageCodes';
import Measurements, { TabType } from './Mobile/Measurements';
import { TabSelectorEntity } from './Mobile/TabSelector';

const GroupHeader = ({ label, isMobile }: { label: string; isMobile: boolean }) => (
    <Col xs={12} className={isMobile ? undefined : 'mp-row'}>
        <Row>
            <Col xs={12}>
                <h4 className={isMobile ? undefined : 'mp-header'}>{label}</h4>
            </Col>
        </Row>
    </Col>
);

interface GroupPropsEdited extends GroupProps {
    controls?: GroupProps;
}

interface Iprops extends RouteComponentProps<{ installID: string }> {
    loading: boolean;
    t: TFunction;
    productInfo: IproductInfo;
    submitMeasurementPointResult: typeof submitMeasurementPointResult;
    getInventory: typeof getInventory;
    getJobs: typeof getAssignedJobs;
    selectedInstall: IinstallBase;
    selectedJob: Ijob;
    measurementPointsByID: { [key: string]: ImeasurementPoint };
    selectedResult: ImeasurementPointResult;
    installBaseIDsNotTested: string[];
    allMeasurementPointLists: { [key: string]: ImeasurementPointList };
    clearHistoricalResultID: typeof clearHistoricalResultID;
    initSelectedResult: typeof initSelectedResult;
    historicalResultID: string;
    standardID: string;
    selectedProduct: Iproduct;
    setSelectedProductByID: typeof setSelectedProductByID;
    setSelectedMeasurementPointListByID: typeof setSelectedMeasurementPointListByID;
    isCommissioningTypeJob: boolean;
    isWorkOrderTypeJob: boolean;
    checklistMode: boolean;
    selectedWorkOrders: IWorkOrder[];
    selectedMeasurementPointList: ImeasurementPointList;
    toggleConfirmSelectJobModal: typeof toggleConfirmSelectJobModal;
    setSelectedWorkOrderID: typeof setSelectedWorkOrderID;
    historicalWorkOrderID: string;
    updateMprAnswers: typeof updateMprAnswers;
    user: Iuser;
    installBasesNotTested: IinstallBasePopulated[];
    installBasesStatus: { [key: string]: number | undefined };
    clearSelectedHistoricalWorkOrderID: () => void;
    installBaseHistory: (IWorkOrder | ImeasurementPointResultWithType)[];
    allJobs: { [key: string]: Ijob };
    measurementPoinResultsIDs: string[];
    historicalMP: ImeasurementPointResult | IWorkOrder | undefined;
    isMobile: boolean;
}

interface Istate {
    activeTabKey: TabType | number | string;
    failing: boolean;
}

interface IFromState {
    forms: { [id: string]: AbstractControl };
    activeFormIndex?: number;
    longTextCheckboxes: { [index: string]: boolean };
}

const MeasurementPointListForm = (props: Iprops) => {
    const { historicalWorkOrderID } = props;
    const historicalResultID = useSelector(selectHistoricalResultID);

    const getIsHistoricalMode = (): boolean => {
        return historicalResultID.length !== 0 || historicalWorkOrderID.length !== 0;
    };

    const getShouldHidePhotosTab = () => {
        return getIsHistoricalMode();
    };

    const getShouldHidePartsTab = (): boolean => {
        return (
            props.selectedJob.jobTypeID === jobTypesIdEnum.inspection ||
            props.selectedJob.jobTypeID === jobTypesIdEnum.audit ||
            props.selectedJob.jobTypeID === jobTypesIdEnum.commissioning ||
            props.selectedJob.jobTypeID === jobTypesIdEnum.agsRebalancing ||
            props.selectedJob.jobTypeID === jobTypesIdEnum.verification
        );
    };

    const [forms, setForms] = React.useState<IFromState>({
        forms: {},
        longTextCheckboxes: {}
    });
    const [state, setState] = React.useState<Istate>({
        activeTabKey: !getShouldHidePhotosTab()
            ? TabType.Photos
            : !getShouldHidePartsTab()
            ? TabType.Parts
            : TabType.Default,
        failing: false
    });

    const filterMeasurementPoints = (tabID: string | number) => {
        return filter(
            props.measurementPointsByID,
            (measurementPoint: ImeasurementPoint) =>
                measurementPoint.measurementPointTabID === tabID &&
                !measurementPoint.isDeleted &&
                measurementPoint.type in measurementPointTypesEnum
        );
    };
    const filterTabs = (tabs: ImeasurementPointListTab[]) => {
        return filter(tabs, tab => tab.isDeleted !== true);
    };

    const filteredTabs: ImeasurementPointListTab[] = React.useMemo(
        () => filterTabs(props.selectedMeasurementPointList.measurementPointTabs),
        [props.selectedMeasurementPointList.measurementPointTabs]
    );

    const isObjectAMeasurementPointResult = (object: any): object is ImeasurementPointResultWithType => {
        return 'jobID' in object;
    };

    const initializeWorkOrder = (index = 0) => {
        if (props.isWorkOrderTypeJob && props.selectedWorkOrders.length) {
            const workOrder = props.selectedWorkOrders[index];
            if (workOrder) {
                props.setSelectedWorkOrderID(workOrder.id);
            }
        }
    };

    const buildMeasurementPointAnswers = (formValues: {
        [key: string]: any;
    }): {
        answers: ImeasurementPointAnswer[];
        resultStatus: number;
        validationError: string;
    } => {
        let answers: ImeasurementPointAnswer[] = [];
        let resultStatus = measurementPointResultStatusTypesEnum.resultStatusPass;
        let validationError = '';
        forEach(formValues, (value: any, key: string) => {
            const measurementPoint = props.measurementPointsByID[key];

            // Process all measurement point types except for group
            if (
                (value !== undefined &&
                    measurementPoint &&
                    measurementPoint.type !== measurementPointTypesEnum.group) ||
                (value !== undefined && measurementPoint && measurementPoint.type >= measurementPointTypesEnum.date)
            ) {
                // check for moment and convert it to a string
                if (moment.isMoment(value)) {
                    value = value.toISOString();
                }
                const answerType = measurementPointAnswerTypes[measurementPoint.type];
                const rawValue = value !== null && typeof value === 'object' ? value.value : value;
                let answer: ImeasurementPointAnswer = {
                    measurementPointID: '',
                    showInReport: measurementPoint.showInReport
                };
                // check to see if the next value is a note that belongs with this answer
                // if the value is undefined, or it can not find a note it will also be undefined
                // TODO throw validation error if there is a note without a answer to go along with it
                if (formValues[`${key}_note`] !== undefined) {
                    answer = {
                        measurementPointID: key,
                        [answerType]: rawValue,
                        notes: formValues[`${key}_note`],
                        showInReport: measurementPoint.showInReport
                    };
                } else {
                    answer = {
                        measurementPointID: key,
                        [answerType]: rawValue,
                        showInReport: measurementPoint.showInReport
                    };
                }
                if (measurementPoint.type === measurementPointTypesEnum.passFail && rawValue === 2) {
                    resultStatus = measurementPointResultStatusTypesEnum.resultStatusFail;
                    if (!answer.notes && measurementPoint.allowNotes) {
                        // validationError = 'Missing notes on a failed test.'; // decided not to require notes BMA-498
                    }
                }
                // verify that the select option belongs to the measurement point
                if (measurementPoint.type === measurementPointTypesEnum.select && rawValue) {
                    if (
                        !measurementPoint.selectOptions ||
                        (measurementPoint.selectOptions &&
                            measurementPoint.selectOptions.find(item => item.id === rawValue) === undefined)
                    ) {
                        console.error(
                            '[buildMeasurementPointAnswers]: selection option value incorrect',
                            measurementPoint,
                            rawValue
                        );
                        validationError = 'Selection option value is incorrect.';
                    }
                }
                // For Long Text and Standard Text, we need to save the actual answer in the textValue field, which is the 2nd item in measurementPointAnswerTypes enum
                if (measurementPoint.type === measurementPointTypesEnum.longText && rawValue) {
                    let showInReport = measurementPoint.showInReport;
                    if (Object.keys(forms.longTextCheckboxes).includes(key)) {
                        showInReport = forms.longTextCheckboxes[key];
                    }

                    answer = {
                        measurementPointID: key,
                        [measurementPointAnswerTypes[2]]: rawValue,
                        showInReport
                    };
                }
                answers = [...answers, answer];
            }
        });
        // if this is a maintenance or repair job, then set the resultStatus accordingly
        if (
            props.selectedJob.jobTypeID === jobTypesIdEnum.maintenance ||
            props.selectedJob.jobTypeID === jobTypesIdEnum.servicePlan
        ) {
            resultStatus = measurementPointResultStatusTypesEnum.resultStatusMaintain;
        }
        if (props.selectedJob.jobTypeID === jobTypesIdEnum.commissioning) {
            resultStatus = measurementPointResultStatusTypesEnum.resultStatusCommissioned;
        }
        if (props.selectedJob.jobTypeID === jobTypesIdEnum.repair) {
            resultStatus = measurementPointResultStatusTypesEnum.resultStatusRepaired;
        }

        return { answers, resultStatus, validationError };
    };

    const updateMPRAnswers = (key: string, value: string | null) => {
        if (!value) {
            return;
        }

        const { formValues } = getAllFormValues(forms.forms, false, (activeTabKey: any) => {
            setState({ ...state, activeTabKey });
        });
        const { answers } = buildMeasurementPointAnswers({ ...formValues, [key]: value });
        props.updateMprAnswers(answers);
    };

    /*
     * subscribe to value changes for the form on this specific tab
     */
    const subscribeToValueChanges = (frms: any, id: string | number) => {
        const filteredMeasurementPoints = filterMeasurementPoints(id);
        const mpsWithoutGroups = filteredMeasurementPoints.filter(mp => mp.type !== measurementPointTypesEnum.group);

        if (mpsWithoutGroups.length === 0) {
            return;
        }

        forEach(mpsWithoutGroups, point => {
            const formControl = frms.forms[id]?.get(point.id);

            if (!formControl) {
                console.error('[subscribeToValueChanges]: missing form control for this measurement point', point, id);
                return;
            }

            formControl.valueChanges.subscribe((value: string | null) => {
                updateMPRAnswers(point.id, value);
            });

            if (point.allowNotes) {
                const key = `${point.id}_note`;
                frms.forms[id].get(key).valueChanges.subscribe((value: string | null) => {
                    updateMPRAnswers(key, value);
                });
            }
        });
    };

    /*
     * checkIfWorkOrdersValid
     * check to see if we have work orders
     * each work order must have closing notes
     * submit each WO form in order to trigger showing validtion errors
     */
    const checkIfWorkOrdersValid = () => {
        if (props.isWorkOrderTypeJob === false || props.selectedWorkOrders.length === 0) {
            return true;
        }
        let isValid = true;
        if (!isValid) {
            document.dispatchEvent(new CustomEvent('submitWorkOrderForms'));
        }
        return isValid;
    };

    /*
     * find the index of the current device.  then try to find the next device that does not have any results
     * also make sure it has a measurement point list
     */
    const getNextDeviceID = () => {
        const { installID } = props.match.params;
        const { isCommissioningTypeJob, installBaseIDsNotTested, installBasesStatus } = props;
        const filteredInstallBasesStatus = pick(installBasesStatus, installBaseIDsNotTested);
        const currentIndex = installBaseIDsNotTested.indexOf(installID);
        let nextIndex = 0;

        if (currentIndex !== -1) {
            nextIndex = currentIndex + 1;
        }

        // if the next index explodes the array then set the index to 0;
        if (nextIndex >= installBaseIDsNotTested.length) {
            nextIndex = 0;
        }
        const nextDeviceID = installBaseIDsNotTested[nextIndex];

        if (nextDeviceID === installID) {
            return '';
        }

        return nextDeviceID || '';
    };

    const getShouldHideSAPDamageCodeTab = () => props.selectedJob.jobTypeID !== jobTypesIdEnum.warrantyBM;

    const getWorkOrderTitle = (workOrder: IWorkOrder | undefined, t: TFunction): string => {
        if (!workOrder) {
            return '';
        }
        const checklist = workOrder.preventativeMaintenanceChecklist;
        const pmpWorkOrderTitle = checklist ? checklist.name : `${t('manageWorkOrder:maintenanceTabTitle')}`;
        const repairWorkOrderTitle = `${t('manageWorkOrder:repairTabTitle')}${
            workOrder.number ? `: ${workOrder.number}` : ''
        }`;
        const warrantyWorkOrderTitle = `${t('manageInventory:warranty')}: ${workOrder.number}`;

        switch (workOrder.type) {
            case workOrderTypesEnum.pmp:
                return pmpWorkOrderTitle;
            case workOrderTypesEnum.warranty:
                return warrantyWorkOrderTitle;
            case workOrderTypesEnum.repair:
                return repairWorkOrderTitle;
            default:
                return repairWorkOrderTitle;
        }
    };

    const getFormColumnWidths = React.useCallback(
        (allowNotes: boolean | undefined, guideText: string | undefined, measurementPointType: any) => {
            const columnWidths = {
                mainCol: props.isMobile ? 12 : 6,
                labelCol: props.isMobile ? 6 : 3,
                measurementPointCol: 12,
                guideCol: 0,
                noteCol: 0
            };

            if (allowNotes && !guideText) {
                return {
                    ...columnWidths,
                    mainCol: 10,
                    noteCol: 2
                };
            } else if (allowNotes && guideText) {
                return {
                    ...columnWidths,
                    mainCol: 10,
                    measurementPointCol: 10,
                    guideCol: 2,
                    noteCol: 2
                };
            } else if (!allowNotes && guideText) {
                return {
                    ...columnWidths,
                    mainCol: 9,
                    measurementPointCol: 9,
                    guideCol: 3,
                    labelCol: 3
                };
            } else if (measurementPointType === measurementPointTypesEnum.group) {
                return {
                    ...columnWidths,
                    mainCol: 12
                };
            }

            return columnWidths;
        },
        []
    );

    const isUserCopyingHistory = (): boolean => {
        if (props.historicalMP !== undefined && props.historicalMP.id !== '') {
            return true;
        }

        return false;
    };

    // This is a callback function for when a user clicks "Hide in Report" checkbox on a Long Text MP
    const showInReportHandler = (id: string) => {
        if (id !== '') {
            return (event: React.ChangeEvent<HTMLInputElement>) => {
                if (forms.longTextCheckboxes[id] === undefined) {
                    const newCheckbox = {
                        [id]: !props.measurementPointsByID[id].showInReport
                    };

                    setForms({ ...forms, longTextCheckboxes: { ...forms.longTextCheckboxes, ...newCheckbox } });
                } else {
                    const newCheckbox = {
                        [id]: !forms.longTextCheckboxes[id]
                    };

                    setForms({ ...forms, longTextCheckboxes: { ...forms.longTextCheckboxes, ...newCheckbox } });
                }

                const { formValues } = getAllFormValues(forms.forms, false, (activeTabKey: any) => {
                    setState({ ...state, activeTabKey });
                });
                const { answers } = buildMeasurementPointAnswers({ ...formValues });
                // Find the index of the answer for this long text MP
                const index = answers.findIndex((answer: any) => answer.measurementPointID === id);

                if (index !== -1) {
                    const currentAnswer = answers[index];
                    answers[index].showInReport = !currentAnswer.showInReport;
                    props.updateMprAnswers(answers);
                }
            };
        }
    };

    const formControls: { [key: string]: FieldConfig } = React.useMemo(() => {
        const { isMobile, selectedResult } = props;
        const { measurementPointAnswers } = selectedResult;
        let defaultControls = {};

        let newControl: Record<string, any> = {};
        const disabled = props.historicalResultID;
        const history = props.installBaseHistory;
        let lastMP: IWorkOrder | ImeasurementPointResultWithType | undefined;
        let historicDataToUse: any = undefined;

        // If the user selected a historical item, and clicked Copy History, this is how we load up those answers
        if (isObjectAMeasurementPointResult(props.historicalMP)) {
            historicDataToUse = { ...props.historicalMP, measurementPointListType: 0 };
        }

        lastMP = undefined;
        if (historicDataToUse !== undefined && historicDataToUse.id !== '') {
            lastMP = historicDataToUse;
        } else if (history !== undefined && history.length > 0) {
            for (const value of history) {
                // Check if the value is an IWorkOrder or ImeasurementPointResultWithType
                if (isObjectAMeasurementPointResult(value)) {
                    const tempMPR = value as ImeasurementPointResultWithType;
                    // Make sure the item in History, is of the same job type we are currently on
                    if (tempMPR.jobTypeID !== undefined) {
                        // This can happen if you save and log the same MP multiple times without closing the job
                        if (tempMPR.jobTypeID === '00000000-0000-0000-0000-000000000000') {
                            // To be safe, let's see if we can find the job in state and get it's real jobTypeID
                            const tempJob = props.allJobs[tempMPR.jobID];
                            if (tempJob !== undefined) {
                                // If job was found, see if jobType is the same
                                if (props.selectedJob.jobTypeID === tempJob.jobTypeID) {
                                    lastMP = value as ImeasurementPointResultWithType;
                                    break;
                                }
                            }
                        } else {
                            // If the item in history does have a jobTypeID set, check if it's the same job type
                            if (props.selectedJob.jobTypeID === tempMPR.jobTypeID) {
                                lastMP = value as ImeasurementPointResultWithType;
                                break;
                            }
                        }
                    }
                } else {
                    // this is an IWorkOrder, we don't care about you, only inspection jobs
                    lastMP = undefined;
                }
            }
        }

        let cleanedAnswers: ImeasurementPointAnswer[] = [];
        // Temp means no previous data for this job. and there is at least 1 historic MP to look at
        if (props.selectedResult.temporary === true && lastMP !== undefined) {
            // There was a refactor to this with git hash (3f897b4138dee78e39c5a2f2de37996b53cb9a3a)
            // Not sure the purpose, so I'm going to keep that code in the Else statement, bringing back the original into this IF
            if (isObjectAMeasurementPointResult(lastMP) && lastMP.measurementPointAnswers !== undefined) {
                lastMP.measurementPointAnswers.forEach(answer => {
                    const measurementPoint = props.measurementPointsByID[answer.measurementPointID];

                    if (measurementPoint) {
                        // If the user is copying history, then we want to copy all answers, even the ones that are not set to remember between inspections
                        if (
                            isUserCopyingHistory() === false &&
                            measurementPoint.selectRememberBetweenInspection === false
                        ) {
                            const initialAnswer = measurementPointAnswers.find(
                                x => x.measurementPointID === answer.measurementPointID
                            );
                            if (initialAnswer) {
                                cleanedAnswers.push(initialAnswer);
                            } else {
                                console.error('MP Answer not found');
                            }
                        } else {
                            cleanedAnswers.push(answer);
                        }
                    }
                });
            } else {
                measurementPointAnswers.forEach(answer => {
                    let lastAnswer: ImeasurementPointAnswer | undefined;
                    if (isObjectAMeasurementPointResult(lastMP) && lastMP.measurementPointAnswers !== undefined) {
                        lastAnswer = lastMP.measurementPointAnswers.find(
                            x => x.measurementPointID === answer.measurementPointID
                        );
                    }
                    if (lastAnswer) {
                        const lastMeasurementPoint = props.measurementPointsByID[lastAnswer.measurementPointID];
                        if (lastMeasurementPoint) {
                            // If the user is copying history, then we want to copy all answers, even the ones that are not set to remember between inspections
                            if (!isUserCopyingHistory() && !lastMeasurementPoint.selectRememberBetweenInspection) {
                                cleanedAnswers.push(answer);
                            } else {
                                cleanedAnswers.push(lastAnswer);
                            }
                        } else {
                            cleanedAnswers.push(answer);
                        }
                    } else {
                        cleanedAnswers.push(answer);
                    }
                });
            }
        } else {
            // if not temp, then use what we have, don't look at last MP in history
            cleanedAnswers = measurementPointAnswers;
        }
        forEach(filteredTabs, (tab: ImeasurementPointListTab) => {
            const filteredMeasurementPoints = filterMeasurementPoints(tab.id);
            let tabControls = {};

            filteredMeasurementPoints.forEach((measurementPoint: ImeasurementPoint) => {
                // determin the default value.  Might be a value set by the MeasurementPoint or by the most recent result.
                const {
                    allowNotes,
                    guideText,
                    selectOptions,
                    selectDefaultOptionID,
                    id,
                    passFailDefault,
                    label,
                    isRequired,
                    numericMinValue,
                    numericMaxValue,
                    type,
                    defaultText
                } = measurementPoint;
                let defaultValue = null;
                let noteDefaultValue = null;
                let defaultShowInReport = true;
                const convertedOptions =
                    selectOptions && selectOptions.length ? FormUtil.convertToOptions(selectOptions) : [];

                if (type === measurementPointTypesEnum.passFail && !!passFailDefault) {
                    defaultValue = passFailDefault;
                }

                if (type === measurementPointTypesEnum.select && !!selectDefaultOptionID && convertedOptions.length) {
                    defaultValue = convertedOptions.find(option => option.value === selectDefaultOptionID);
                }

                const foundAnswer = cleanedAnswers.find(answer => answer.measurementPointID === id);

                if (foundAnswer !== undefined) {
                    if (type === measurementPointTypesEnum.numeric) {
                        defaultValue = foundAnswer?.numericValue;
                    }
                    if (type === measurementPointTypesEnum.passFail) {
                        defaultValue = foundAnswer?.pass;
                    }
                    if (type === measurementPointTypesEnum.text) {
                        defaultValue = foundAnswer?.textValue;
                    }
                    if (type === measurementPointTypesEnum.date) {
                        defaultValue = foundAnswer?.dateValue;
                    }
                    if (type === measurementPointTypesEnum.select) {
                        defaultValue = convertedOptions.find(
                            option => option.value === foundAnswer?.measurementPointSelectOptionID
                        );
                    }
                    if (type === measurementPointTypesEnum.longText) {
                        defaultValue = foundAnswer?.textValue;

                        if (Object.keys(forms.longTextCheckboxes).includes(foundAnswer.measurementPointID)) {
                            defaultShowInReport = forms.longTextCheckboxes[foundAnswer.measurementPointID];
                        }
                    }

                    if (measurementPoint.allowNotes !== undefined) {
                        noteDefaultValue = foundAnswer?.notes;
                    }
                } else {
                    // For Long Text, if no answer exists, use the default text
                    if (type === measurementPointTypesEnum.longText) {
                        defaultValue = defaultText;
                        defaultShowInReport = true; // always default to showing in the report if no answer exists
                    }
                }

                const columnWidths = getFormColumnWidths(allowNotes, guideText, measurementPoint.type);

                // build the Header or an actual form control
                if (measurementPoint.type === measurementPointTypesEnum.group) {
                    newControl = {
                        [`$field_${id}`]: {
                            isStatic: false, // ensures a key is added
                            render: () => <GroupHeader label={label} key={id} isMobile={isMobile} />
                        } as GroupPropsEdited
                    };
                } else {
                    let validators: ValidatorFn[] = [];
                    if (measurementPoint.type === measurementPointTypesEnum.date) {
                        validators = [FormUtil.validators.isValidMoment];
                    }
                    if (isRequired) {
                        validators = [...validators, FormUtil.validators.requiredWithTrim];
                    }
                    if (measurementPoint.type === measurementPointTypesEnum.numeric) {
                        if (numericMaxValue) {
                            validators = [...validators, Validators.max(numericMaxValue)];
                        }
                        if (numericMinValue) {
                            validators = [...validators, Validators.min(numericMinValue)];
                        }
                    }

                    if (
                        measurementPoint.type === measurementPointTypesEnum.summaryPage ||
                        measurementPoint.type === measurementPointTypesEnum.longText
                    ) {
                        validators = [...validators, FormUtil.validators.maxLength(1000)];
                    }

                    newControl = {
                        [id]: {
                            render: FormUtilMeasurementPoints[
                                measurementPointTypesEnum[
                                    measurementPoint.type
                                ] as keyof typeof FormUtilMeasurementPoints
                            ](isMobile),
                            meta: {
                                charLimit: 10000,
                                colWidth: 6,
                                columnWidths,
                                name: label,
                                label,
                                options: convertedOptions,
                                id,
                                passFailDefault,
                                guideText: measurementPoint.guideText,
                                helpText: measurementPoint.helpText,
                                isClearable: true,
                                defaultText: measurementPoint.defaultText,
                                showNormalToolbar: false,
                                initialContent: defaultValue,
                                showInReport: defaultShowInReport, // this is for MP answer, not the MP itself
                                showInReportHandler // only used for long text
                            },
                            options: {
                                validators
                            },
                            formState: { value: defaultValue, disabled }
                        } as GroupPropsEdited
                    };

                    if (measurementPoint.allowNotes) {
                        newControl = {
                            ...newControl,
                            [id]: {
                                ...newControl[id],
                                meta: {
                                    ...newControl[id].meta,
                                    colWidth: 10
                                }
                            }
                        };

                        newControl = {
                            ...newControl,
                            [`${id}_note`]: {
                                render: FormUtilMeasurementPoints.NewMeasurementPointNote,
                                meta: {
                                    name: `${id}_note`,
                                    colWidth: 2,
                                    label: `${label} note`,
                                    t: props.t,
                                    isHistoricalNote: props.historicalResultID.length > 0
                                },
                                formState: {
                                    value: noteDefaultValue,
                                    disabled
                                } // WILL NOT WORK WITHOUT DISABLED
                            }
                        };
                    }
                }

                tabControls = { ...tabControls, ...newControl } as {
                    [key: string]: GroupPropsEdited;
                };
            });
            const tabConfig = { controls: tabControls } as FieldConfig;
            defaultControls = { ...defaultControls, [tab.id]: tabConfig };
        });
        return defaultControls;
    }, [
        filteredTabs,
        props.selectedMeasurementPointList.id,
        props.selectedResult.id,
        props.selectedInstall.id,
        forms.longTextCheckboxes
    ]);

    const additionalTabs: TabSelectorEntity[] = React.useMemo(() => {
        const tabs: TabSelectorEntity[] = [];
        props.selectedWorkOrders.forEach((selectedWorkOrder: IWorkOrder) => {
            const woTitle = getWorkOrderTitle(selectedWorkOrder, props.t);
            tabs.push({
                title: woTitle,
                id: selectedWorkOrder.id,
                isWorkOrder: true,
                isMeasurementPoint: false,
                index: -1
            });
        });

        filteredTabs.forEach((measurementPointTab: ImeasurementPointListTab, index: number) => {
            tabs.push({
                title: measurementPointTab.name,
                id: measurementPointTab.id,
                isWorkOrder: false,
                isMeasurementPoint: true,
                index
            });
        });

        if (state.failing) {
            tabs.push({
                id: 'failing',
                title: 'Request Parts',
                isWorkOrder: false,
                isMeasurementPoint: false,
                index: -1
            });
        }

        return tabs;
    }, [props.selectedWorkOrders, filteredTabs, state.failing]);

    const handleSubmit = (
        e?: React.FormEvent<HTMLFormElement>,
        statusOverride?: measurementPointResultStatusTypesEnum
    ) => {
        if (e) {
            e.preventDefault();
        }

        const { formStatus, formValues } = getAllFormValues(
            forms.forms,
            true,
            (activeTabKey: any, lastErroredMeasurementPointLabel: string) => {
                setState({ ...state, activeTabKey });
                const tab: TabSelectorEntity | undefined = additionalTabs.find(
                    (tab: TabSelectorEntity) => tab.id === activeTabKey
                );

                // Ensure tab exists before trying to access its properties
                if (tab !== undefined) {
                    toastr.error(
                        `There is a mandatory field which was not filled in: Go to tab \`${tab.title}\` and see measruement point \`${lastErroredMeasurementPointLabel}\`.`,
                        '',
                        constants.toastrError
                    );
                    return;
                }
                // Handle the case when tab is undefined
                toastr.error('Unexpected error: The selected tab does not exist.', '', constants.toastrError);
            }
        );
        // if the form is invalid OR we are overiding the status which means we are marking this as Cannot Complete
        const shouldSkipValidation =
            statusOverride === measurementPointResultStatusTypesEnum.resultStatusCannotComplete;
        if ((checkIfWorkOrdersValid() === false || formStatus === 'INVALID') && shouldSkipValidation === false) {
            toastr.error('Please check invalid inputs', '', constants.toastrError);
            return;
        }
        const { answers, resultStatus, validationError } = buildMeasurementPointAnswers(formValues);
        if (validationError.length) {
            toastr.warning('Warning', validationError, constants.toastrError);
            return;
        }
        const status = statusOverride ? statusOverride : resultStatus;

        const result = {
            ...props.selectedResult,
            installBaseID: props.match.params.installID,
            measurementPointListID: props.selectedMeasurementPointList.id,
            status
        };

        // Please make sure jobTypeID is set
        if (result.jobTypeID === undefined) {
            result.jobTypeID = props.selectedJob.jobTypeID;
        }

        // TODO might need to block submitting this while the work orders are still being updated with closing notes
        props.submitMeasurementPointResult(result, answers, props.selectedWorkOrders);
        const nextDeviceID = getNextDeviceID();

        setForms({
            forms: {},
            longTextCheckboxes: {}
        });
        // TODO: workaround; nextDeviceID sometimes returns current device ID
        if (nextDeviceID.length && nextDeviceID !== props.match.params.installID) {
            props.history.push(`/devices/${nextDeviceID}`);
            toastr.success('Success', 'Saved tests and loaded next device.', constants.toastrSuccess);
        } else {
            toastr.success('Success', 'All selected devices have been tested.', constants.toastrSuccess);
            props.history.push('/devices');
        }
    };

    /*
     * require notes, skip validation, set status to cannot complete
     */
    const handleCannotComplete = (e?: any) => {
        if (!props.selectedResult.notes.length) {
            toastr.warning(
                'Warning',
                'Please enter why you cannot complete the test in the comments to the left.',
                constants.toastrWarning
            );
            return;
        }

        handleSubmit(e, measurementPointResultStatusTypesEnum.resultStatusCannotComplete);
    };

    const handleSelect = (activeTabKey: any | TabType | number | string) => {
        setState({ ...state, activeTabKey });
        initializeWorkOrder(activeTabKey);
    };

    const handleResult = () => {
        const failedAnswers = props.selectedResult.measurementPointAnswers.filter(
            answer => answer.pass !== undefined && answer.pass === 2
        );

        if (failedAnswers.length > 0) {
            setState({ ...state, failing: true });
        } else {
            setState({ ...state, failing: false });
        }
    };

    React.useEffect(() => {
        handleResult();
    }, [props.selectedResult.id]);

    React.useEffect(() => {
        // If an answer has changed, we need to check if anything is set to Fail
        // If something is set to Fail, show the Request Parts tab
        handleResult();
    }, [props.selectedResult.measurementPointAnswers]);

    React.useEffect(() => {
        if (props.selectedInstall.isDeleted) {
            toastr.warning(
                props.t('toastMessage:warning'),
                props.t('toastMessage:assetDeleted'),
                constants.toastrWarning
            );
        }
        if (!props.selectedInstall.id.length) {
            return;
        }

        if (props.selectedMeasurementPointList.id.length && props.selectedResult.id.length) {
            handleResult();
        }
        // verify that we are editing a device that belongs to this facility
        if (props.selectedInstall.facilityID !== props.selectedJob.facilityID) {
            props.history.push('/jobs');
        }

        // PointListButtonBar needs the active work order to be set
        initializeWorkOrder();
    }, []);

    React.useEffect(() => {
        if (additionalTabs.length > 0) {
            setState({ ...state, activeTabKey: additionalTabs[0].id });
        }
    }, []);

    React.useEffect(() => {
        props.initSelectedResult(props.selectedInstall.id);

        // verify that we are editing a device that belongs to this facility
        if (props.selectedInstall.facilityID !== props.selectedJob.facilityID) {
            props.history.push('/jobs');
        }

        // If the device is changed while on this screen, reset the current tab to the first in the list. if the list is empty, default to the photo tab
        if (additionalTabs.length > 0) {
            setState({ ...state, activeTabKey: additionalTabs[0].id });
        } else {
            setState({ ...state, activeTabKey: 'photos' });
        }

        // If the install base has changed, updated the selected work order, PointListButtonBar needs the active work order to be set
        initializeWorkOrder();
    }, [props.selectedInstall.id, historicalResultID]);

    const setForm = (form: AbstractControl, id: string | number) => {
        if (form === null) {
            console.warn('form is null', form, id);
            return;
        }
        forms.forms[id] = form;
        forms.forms[id].meta = {
            loading: props.loading
        };

        setForms(forms);
        subscribeToValueChanges(forms, id);
    };

    if (!props.selectedMeasurementPointList.id.length) {
        return (
            <Redirect
                to={{
                    pathname: '/devices'
                }}
            />
        );
    }

    // return home if their is no job or the job has been completed or if there is no installBase
    if (
        !props.selectedJob.id.length ||
        props.selectedJob.status === jobStatusEnum.completed ||
        !props.selectedInstall.id.length
    ) {
        return (
            <Redirect
                to={{
                    pathname: '/'
                }}
            />
        );
    }

    if (props.selectedInstall.isDeleted) {
        return <Redirect to={{ pathname: '/devices' }} />;
    }

    if (props.selectedProduct.id.length === 0 || props.history.location.pathname.includes(props.selectedProduct.id)) {
        return <h3>Loading product...</h3>;
    }

    if (props.isMobile) {
        return (
            <Measurements
                installID={props.match.params.installID}
                handleCannotComplete={handleCannotComplete}
                shouldHidePhotoTab={getShouldHidePhotosTab()}
                shouldHidePartsTab={getShouldHidePartsTab()}
                selectedWorkOrders={props.selectedWorkOrders}
                checklistMode={props.checklistMode}
                formControls={formControls}
                historicalWorkOrderID={props.historicalWorkOrderID}
                additionalTabs={additionalTabs}
                t={props.t}
                getWorkOrderTitle={getWorkOrderTitle}
                isFailing={state.failing}
                history={props.history}
                handleSubmit={handleSubmit}
                setForm={setForm}
                handleActiveTabSelect={handleSelect}
                activeTab={state.activeTabKey}
            />
        );
    }

    return (
        <div>
            <form onSubmit={handleSubmit} className="beacon-form">
                <Row className="measurement-point-list-form">
                    <div className="banner-wrapper">
                        <BannerContainer {...props} />
                        <PointListButtonBarContainer
                            handleCannotComplete={handleCannotComplete}
                            installID={props.match.params.installID}
                            handleSubmit={handleSubmit}
                        />
                    </div>
                    <Tabs
                        activeKey={state.activeTabKey}
                        onSelect={handleSelect}
                        id="controlled-tab-example"
                        animation={false}
                    >
                        {!getShouldHidePhotosTab() && (
                            <Tab eventKey={TabType.Photos} title={'Photos'} className="measurement-point-list">
                                <PhotosControl installID={props.match.params.installID} />
                            </Tab>
                        )}
                        {!getShouldHidePartsTab() && (
                            <Tab
                                eventKey={TabType.Parts} // the number of work order tabs plus the non work order tabs
                                title={'Parts'}
                                className="measurement-point-list"
                            >
                                <PartsControl installID={props.match.params.installID} history={props.history} />
                            </Tab>
                        )}

                        {!getShouldHideSAPDamageCodeTab() && (
                            <Tab
                                title={'DamageCodes'}
                                eventKey={(getShouldHidePartsTab() ? 0 : 1) + (getShouldHidePhotosTab() ? 0 : 1)}
                                className="measurement-point-list"
                            >
                                <SAPDamageCodes installBase={props.selectedInstall} selectedJob={props.selectedJob} />
                            </Tab>
                        )}

                        {state.failing && (
                            <Tab eventKey={'failing'} title={'Request Parts'} className="measurement-point-list">
                                <RequestPartContainer
                                    history={props.history}
                                    installID={props.match.params.installID}
                                />
                            </Tab>
                        )}

                        {additionalTabs.map((tab: TabSelectorEntity) => {
                            if (tab?.isMeasurementPoint) {
                                return (
                                    <Tab
                                        eventKey={tab.id}
                                        title={tab.title}
                                        key={tab.id}
                                        className="measurement-point-list"
                                    >
                                        <FormGenerator
                                            fieldConfig={formControls[tab.id]}
                                            onMount={form => setForm(form, tab.id)}
                                        />
                                    </Tab>
                                );
                            }

                            if (tab?.isWorkOrder) {
                                const selectedWorkOrder: IWorkOrder | undefined = props.selectedWorkOrders.find(
                                    (workOrder: IWorkOrder) => workOrder.id === tab.id
                                );

                                const woTitle = getWorkOrderTitle(selectedWorkOrder, props.t);
                                return (
                                    <Tab
                                        eventKey={tab.id} // Two comes from parts and photos tabs
                                        title={woTitle}
                                        key={tab.id}
                                        className="work-order"
                                    >
                                        <WorkOrderLayout
                                            showChecklist={props.checklistMode}
                                            selectedWorkOrder={selectedWorkOrder}
                                            t={props.t}
                                        />
                                    </Tab>
                                );
                            }
                        })}
                    </Tabs>
                </Row>
            </form>
        </div>
    );
};

export default MeasurementPointListForm;
