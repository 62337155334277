import * as React from 'react';

import { Button, ButtonToolbar, Dropdown, MenuItem } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LinkContainer } from 'react-router-bootstrap';
import { constants } from '../../constants/constants';
import i18next from 'i18next';
import { jobTypesIdEnum } from '../../models-enums';
import { Iuser } from '../../models';
import { WithTranslation, withTranslation } from 'react-i18next';
import { downloadAppState, startSync } from '../../actions/commonActions';
import OnlineIndicatorButton from './OnlineIndicatorButton';
import { toastr } from 'react-redux-toastr';
import { useDispatch, useSelector } from 'react-redux';
import { getIsMobile } from '../../reducers/configReducer';
import { clearSelectedWorkOrderID, clearSelectedJob } from '../../actions/workOrderActions';
import { faClock } from '@fortawesome/free-solid-svg-icons';

import workOrders from '../../images/work-orders.svg';

interface Iprops {
    toggle: (isOpen: boolean) => void;
    handleMenuSelect: (eventKey: any) => void;
    menuClass: string;
    jobType: jobTypesIdEnum;
    hasJob: boolean;
    offlineStatusOverride: boolean;
    online: boolean;
    user: Iuser;
    loading: boolean;
    startSync: typeof startSync;
    downloadAppState: typeof downloadAppState;
    isAPIQueueEmpty: boolean;
}

const DropDownMenu: React.FC<Iprops & WithTranslation> = props => {
    const assetsRoute = '/devices';
    const dispatch = useDispatch();
    const isMobile = useSelector(getIsMobile);

    const allowedToSeeNPH = () => {
        // 8/25/23 - only disabling if user belongs to UK
        // If user has more than one country, they would have another country besides UK so they are good
        if (props.user.userCountries.length === 1) {
            if (props.user.userCountries[0] === constants.ukCountryID) {
                return false;
            }
        }

        return true;
    };

    return (
        <ButtonToolbar className="header-menu">
            <Dropdown onToggle={props.toggle} id="header-dropdown" onSelect={props.handleMenuSelect}>
                <Dropdown.Toggle bsStyle="link" className="header-menu-button" bsSize="sm">
                    <FontAwesomeIcon icon={['far', 'bars']} size="lg" />
                </Dropdown.Toggle>
                <div className="white-rectangle" />
                <Dropdown.Menu className={props.menuClass}>
                    {/* Mobile only container */}
                    <li className="menu-welcome">
                        <div className="menu-wecome-left">
                            <p className="welcome-text-menu">{i18next.t('welcome')}</p>

                            <p className="name-menu">{props.user.first}</p>
                        </div>
                        <div className="menu-welcome-right">
                            <OnlineIndicatorButton doubleClick={props.downloadAppState} online={props.online} />

                            <Button
                                type="button"
                                bsStyle="default"
                                disabled={props.loading}
                                onClick={() => {
                                    if (!props.online) {
                                        toastr.warning(
                                            i18next.t('toastMessage:offline'),
                                            i18next.t('toastMessage:onlineRequired'),
                                            constants.toastrWarning
                                        );
                                        return;
                                    }
                                    props.startSync();
                                }}
                                bsSize="sm"
                            >
                                {i18next.t('sync')}
                            </Button>
                        </div>
                    </li>
                    <LinkContainer
                        to={'/jobs'}
                        onClick={e => {
                            if (!props.online) {
                                e.preventDefault();
                            }
                        }}
                    >
                        <MenuItem
                            disabled={!props.online}
                            onClick={evt => {
                                if (props.online) {
                                    dispatch(clearSelectedWorkOrderID()); // what does this even do?
                                    dispatch(clearSelectedJob());
                                }
                            }}
                        >
                            <FontAwesomeIcon icon={['far', 'exchange']} fixedWidth /> &nbsp;{' '}
                            {i18next.t('common:switchJobs')}
                        </MenuItem>
                    </LinkContainer>
                    {props.hasJob && (
                        <LinkContainer to={assetsRoute}>
                            <MenuItem>
                                <FontAwesomeIcon icon={['far', 'clipboard-list']} fixedWidth /> &nbsp;{' '}
                                {props.jobType === jobTypesIdEnum.repair && i18next.t('manageWorkOrder:bannerTitle')}
                                {props.jobType !== jobTypesIdEnum.repair && i18next.t('common:manageInventory')}
                            </MenuItem>
                        </LinkContainer>
                    )}
                    {props.hasJob && (
                        <LinkContainer to={'/locations'}>
                            <MenuItem>
                                <FontAwesomeIcon icon={['far', 'building']} fixedWidth /> &nbsp;{' '}
                                {i18next.t('common:manageLocations')}
                            </MenuItem>
                        </LinkContainer>
                    )}
                    {isMobile && (
                        <LinkContainer to={'/unassigned-sap-workorders'}>
                            <MenuItem disabled={!props.online}>
                                <img
                                    width="17"
                                    src={workOrders}
                                    alt="azure"
                                    style={{
                                        margin: '0 0 0 2px'
                                    }}
                                />{' '}
                                &nbsp; {i18next.t('nsJob:sapWOMobile')}
                            </MenuItem>
                        </LinkContainer>
                    )}
                    {props.hasJob && props.jobType === jobTypesIdEnum.maintenance && (
                        <LinkContainer to={'/maintenance'}>
                            <MenuItem>
                                <FontAwesomeIcon icon={['far', 'cogs']} fixedWidth /> &nbsp;{' '}
                                {i18next.t('manageWorkOrder:maintenanceTabTitle')}
                            </MenuItem>
                        </LinkContainer>
                    )}
                    {props.hasJob &&
                        (props.jobType === jobTypesIdEnum.repair ||
                            props.jobType === jobTypesIdEnum.servicePlan ||
                            props.jobType === jobTypesIdEnum.commissioning) && (
                            <LinkContainer to={'/repair'}>
                                <MenuItem>
                                    <FontAwesomeIcon icon={['fas', 'tools']} fixedWidth /> &nbsp;{' '}
                                    {i18next.t('manageInventory:bannerTitleMobileWorkOrderMode')}
                                </MenuItem>
                            </LinkContainer>
                        )}
                    {props.hasJob && (
                        <LinkContainer
                            to={'/sap'}
                            onClick={e => {
                                if (!props.isAPIQueueEmpty) {
                                    e.preventDefault();
                                }
                            }}
                        >
                            <MenuItem disabled={!props.isAPIQueueEmpty}>
                                <FontAwesomeIcon icon={['fas', 'tools']} fixedWidth /> &nbsp;{' '}
                                {i18next.t('manageWorkOrder:sapTabTitle')}
                            </MenuItem>
                        </LinkContainer>
                    )}

                    {props.hasJob && props.jobType === jobTypesIdEnum.warrantyBM && (
                        <LinkContainer to={'/warranty'}>
                            <MenuItem>
                                <FontAwesomeIcon icon={['far', 'shield-check']} fixedWidth /> &nbsp;{' '}
                                {i18next.t('manageInventory:warranty')}
                            </MenuItem>
                        </LinkContainer>
                    )}
                    <hr className="menu-break" />
                    {props.hasJob === false && allowedToSeeNPH() && (
                        <MenuItem eventKey="5">
                            <FontAwesomeIcon icon={faClock} fixedWidth /> &nbsp;{' '}
                            {i18next.t('common:recordNonProductiveHours')}
                        </MenuItem>
                    )}
                    <MenuItem eventKey="1">
                        <FontAwesomeIcon icon="user" fixedWidth /> &nbsp; {i18next.t('common:profile')}
                    </MenuItem>
                    <MenuItem eventKey="3">
                        <FontAwesomeIcon icon={['far', 'sign-out']} fixedWidth /> &nbsp; {i18next.t('common:logout')}
                    </MenuItem>
                    <small
                        style={{
                            color: constants.colors.greyText,
                            padding: '5px 5px 5px 22px'
                        }}
                    >
                        v: {process.env.REACT_APP_VERSION}
                    </small>
                </Dropdown.Menu>
            </Dropdown>
        </ButtonToolbar>
    );
};

export default withTranslation('common')(DropDownMenu);
