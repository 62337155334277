import * as types from '../actions/actionTypes';

import {
    IWorkOrder,
    Ibuilding,
    Ifloor,
    IinstallBase,
    IinstallBasePopulated,
    IjobWorkOrder,
    Ilocation,
    ImeasurementPointResult,
    Iproduct,
    Iroom,
    ItableFiltersParams,
    Ijob,
    ImainCategory,
    IcommissioningData,
    WorkOrderSource,
    IdamageCode,
    IjobSignature,
    IcheckedItems,
    CheckedItemStatus
} from '../models';
import { filter, forEach, keyBy, map, omit, orderBy, pickBy } from 'lodash';
import { getIsWorkOrderTypeJob, selectSelectedJob, getSelectedJob, isWorkOrderTypeJob } from './manageJobReducer';
import initialState, {
    initialFacility,
    initialInstallBase,
    initialMeasurmentPointResult,
    initialProduct,
    initialTableFilters,
    initialWorkOrder
} from './initialState';
import {
    jobTypesIdEnum,
    workOrderStatusEnum,
    workOrderStatusEnumForFilter,
    inventoryStatusFilterEnum,
    measurementPointResultStatusTypesEnum
} from '../models-enums';

import { IinitialState } from '../reducers';
import { TableUtil } from '../components/common/TableUtil';
import { combineReducers } from 'redux';
import { constants, productMainCategories, subCategories, productTypes } from '../constants/constants';
import { createSelector } from 'reselect';
import moment from 'moment';
import {
    createFormValuesWithName,
    createSelectedIDWithName,
    createShowModalWithNamedType,
    createTableFiltersWithName,
    getManageJob,
    selectSelectedInstallBaseIDs
} from './commonReducers';
import {
    selectJobWorkOrders,
    selectJobWorkOrdersForJobID,
    selectJobWorkOrdersWithWorkOrders,
    selectWorkOrdersByID
} from './commonSelectors';
import { compareDesc, parseISO } from 'date-fns';
import { getLocationPathname, selectIsWorkOrderMode } from './locationReducer';
import { FieldConfig } from 'react-reactive-form';
import { getManageJobCommentById } from './manageJobCommentsReducer';
import {
    getHistoricalResultID,
    getHistoricalWorkOrderID,
    getMeasurementPointResults,
    selectAllInstallBasePartsForJob,
    selectInstallBasePartsPopulated,
    selectJobWorkOrderForHistoricalWorkOrder
} from './partsReducer';
import { getContacts } from './contactsReducer';
import { OfflineProductsProvider } from '../store/offlineProductsProvider';
import { debug } from 'console';

const offlineProductsProvider = new OfflineProductsProvider();

interface InstallBaseStatuses {
    id: string;
    status: number | undefined;
}

const cleanProductObject = (product: any) => {
    return {
        ...initialProduct,
        ...pickBy(product, property => property !== null)
    };
};

export const latestResults = (data: ImeasurementPointResult[]) =>
    data.filter(res => {
        const duplicates = data
            .filter(item => item.installBaseID === res.installBaseID)
            ?.sort((a, b) => {
                if (a.updateDate && b.updateDate) {
                    return compareDesc(parseISO(a.updateDate), parseISO(b.updateDate));
                }

                return 0;
            });

        return res.id === duplicates?.[0]?.id;
    });

const cleanInstallBaseObject = (install: any, current?: IinstallBase): IinstallBase => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { latestMeasurementPointListResult, ...cleanedInstall } = install; // TODO why is the API sending the result to us???
    const workOrderResultStatus = install.workOrderResultStatus || current?.workOrderResultStatus || 1;

    return {
        ...initialInstallBase,
        ...pickBy(cleanedInstall, property => property !== null),
        workOrderResultStatus,
        latestMeasurementPointListResultStatus: install.latestMeasurementPointListResult?.status
    };
};

/*
 * SELECTORS
 */

export const getInstallBasesForFacility = (state: { [key: string]: IinstallBase }, facilityID: string) => {
    return filter(state, { facilityID, isDeleted: false });
};
/*
 * filter by facility first
 * the rest of the filteres are handled by filterInstallBases()
 * add the entire product object to each install
 * update the status
 */
const getInstallBases = (state: IinitialState) => state.manageInventory.installBasesByID;
export const getProducts = (state: IinitialState) => state.manageInventory.productsByID;
const getResults = (state: IinitialState) => state.measurementPointResults.measurementPointResultsByID;
// sort by updateDate and filter by lastest one
const getLatestResults = (state: IinitialState) =>
    latestResults(Object.values(state.measurementPointResults.measurementPointResultsByID));
const getFacility = (state: IinitialState) =>
    state.facilities.facilitiesByID[state.manageJob.selectedJob.facilityID] || initialFacility;
const getProductInfo = (state: IinitialState) => state.productInfo;
const getJob = (state: IinitialState) => state.manageJob.selectedJob;
export const getInstallFormValues = (state: IinitialState) => state.manageInventory.installFormValues;
const getCurrentlySelectedJobID = (state: IinitialState) => state.manageJob.selectedJob.id;
const getEditProductModal = (state: IinitialState) => state.manageInventory.showEditProductModal;
const getSearchNewProductsModal = (state: IinitialState) => state.manageInventory.showSearchNewProductsModal;
const getEditInstallModal = (state: IinitialState) => state.manageInventory.showEditInstallModal;
const getSignatureModal = (state: IinitialState) => state.manageInventory.showSignatureModal;
const getNoteModal = (state: IinitialState) => state.manageInventory.showNoteModal;
const getEditJobDefaultsModal = (state: IinitialState) => state.manageInventory.showEditJobDefaultsModal;
const getCommissioningDataFormModal = (state: IinitialState) => state.manageInventory.showCommissioningDataFormModal;
const getAddHoursModal = (state: IinitialState) => state.manageInventory.showAddHoursModal;
export const getDamageCodes = (state: IinitialState) => state.manageInventory.damageCodes;
export const getRefreshInventory = (state: IinitialState) => state.manageInventory.refreshInventory;
export const showManageInventoryLoading = (state: IinitialState) => state.manageInventory.showManageInventoryLoading;

export const selectInstallBasesByID = createSelector([getInstallBases], installBases => installBases);
export const selectInventoryModals = createSelector(
    [
        getEditProductModal,
        getSearchNewProductsModal,
        getEditInstallModal,
        getSignatureModal,
        getNoteModal,
        getEditJobDefaultsModal,
        getCommissioningDataFormModal,
        getAddHoursModal
    ],
    (
        editProductModal,
        searchNewProductsModal,
        editInstallModal,
        signatureModal,
        noteModal,
        editJobDefaultsModal,
        commissioningDataFormModal,
        addHoursModal
    ) => {
        const anyModalOpen = [
            editProductModal,
            searchNewProductsModal,
            editInstallModal,
            signatureModal,
            noteModal,
            editJobDefaultsModal,
            commissioningDataFormModal,
            addHoursModal
        ].some(modal => modal);

        return anyModalOpen;
    }
);

/*
 * getLatestMeasurementPointListResultForJob
 * if the job is an AGS type then return the latest AGS MPLR
 * Otherwise retun the latest MPLR.
 * Only return the latestMPLR if it is from the current job.
 */
const getLatestMeasurementPointListResultForJob = ({
    job,
    latestMeasurementPointListResult = initialMeasurmentPointResult,
    latestAGSMeasurementPointListResult = initialMeasurmentPointResult,
    latestVerificationMeasurementPointListResult = initialMeasurmentPointResult,
    latestAuditMeasurementPointListResult = initialMeasurmentPointResult,
    latestCommissioningMeasurementPointListResult = initialMeasurmentPointResult
}: {
    job: Ijob;
    latestMeasurementPointListResult: ImeasurementPointResult;
    latestAGSMeasurementPointListResult: ImeasurementPointResult;
    latestVerificationMeasurementPointListResult: ImeasurementPointResult;
    latestAuditMeasurementPointListResult: ImeasurementPointResult;
    latestCommissioningMeasurementPointListResult: ImeasurementPointResult;
}) => {
    if (job.jobTypeID === jobTypesIdEnum.agsRebalancing) {
        if (
            latestAGSMeasurementPointListResult.jobID !== job.id ||
            latestAGSMeasurementPointListResult.temporary === true
        ) {
            latestAGSMeasurementPointListResult = initialMeasurmentPointResult;
        }
        return latestAGSMeasurementPointListResult;
    } else if (job.jobTypeID === jobTypesIdEnum.verification) {
        if (
            latestVerificationMeasurementPointListResult.jobID !== job.id ||
            latestVerificationMeasurementPointListResult.temporary === true
        ) {
            latestVerificationMeasurementPointListResult = initialMeasurmentPointResult;
        }
        return latestVerificationMeasurementPointListResult;
    } else if (job.jobTypeID === jobTypesIdEnum.audit) {
        if (
            latestAuditMeasurementPointListResult.jobID !== job.id ||
            latestAuditMeasurementPointListResult.temporary === true
        ) {
            latestAuditMeasurementPointListResult = initialMeasurmentPointResult;
        }
        return latestAuditMeasurementPointListResult;
    } else if (job.jobTypeID === jobTypesIdEnum.commissioning) {
        if (
            latestCommissioningMeasurementPointListResult.jobID !== job.id ||
            latestCommissioningMeasurementPointListResult.temporary === true
        ) {
            latestCommissioningMeasurementPointListResult = initialMeasurmentPointResult;
        }
        return latestCommissioningMeasurementPointListResult;
    } else {
        if (latestMeasurementPointListResult.jobID !== job.id || latestMeasurementPointListResult.temporary === true) {
            latestMeasurementPointListResult = initialMeasurmentPointResult;
        }
        return latestMeasurementPointListResult;
    }
};

/*
 * receive work orders that have been filtered for the job and installBase, return a workOrderStatus
 * if all the work orders for this installBase are for this job and closed
 */
export const getWorkOrderResultStatusForJobandInstall = (workOrders: IWorkOrder[]) => {
    let countIncompleteWorkOrders = 0;
    if (workOrders.length === 0) {
        return workOrderStatusEnum.new;
    }
    workOrders.forEach(wo => {
        if (wo.status !== workOrderStatusEnum.complete && wo.status !== workOrderStatusEnum.notApplicable) {
            countIncompleteWorkOrders++;
        }
    });
    if (countIncompleteWorkOrders > 0) {
        return workOrderStatusEnum.new;
    } else {
        return workOrderStatusEnum.complete;
    }
};

// take the jobWorkOrders for the job and return the workOrders for the install
export const getInstallBaseWorkOrders = (jobWorkOrdersForJob: IjobWorkOrder[], installBaseID: string) => {
    const filteredJobWorkOrders = filter(
        jobWorkOrdersForJob,
        jwo =>
            jwo.workOrder &&
            jwo.workOrder.installBaseID === installBaseID &&
            jwo.isDeleted === false &&
            jwo.workOrder.isDeleted === false
    ) as IjobWorkOrder[]; // not sure why we have to type cast here
    if (filteredJobWorkOrders) {
        return map(filteredJobWorkOrders, jwo => {
            if (jwo.workOrder) {
                return jwo.workOrder;
            } else {
                return initialWorkOrder; // this should never happen
            }
        });
    } else {
        return [];
    }
};

export const makeSelectInstallBasesPopulatedTest = createSelector(
    [
        getInstallBases,
        getProducts,
        getFacility,
        getProductInfo,
        getResults,
        getJob,
        selectJobWorkOrders,
        selectWorkOrdersByID,
        getCurrentlySelectedJobID,
        getLocationPathname
    ],
    (
        installBasesByID,
        productsByID,
        facility,
        productInfo,
        MPLResultsByID,
        job,
        jobWorkOrdersByID,
        workOrdersByID,
        jobID,
        locationPathname
    ) => {
        const jobWorkOrdersByIDFiltered = () => {
            const filteredJobWorkOrders = filter(jobWorkOrdersByID, {
                isDeleted: false
            });
            const withWorkOrders: IjobWorkOrder[] = map(filteredJobWorkOrders, jwo => {
                return {
                    ...jwo,
                    workOrder: workOrdersByID[jwo.workOrderID] || initialWorkOrder
                };
            });
            return withWorkOrders;
        };
        const jobWorkOrdersForJob = jobWorkOrdersByIDFiltered().filter(
            wo => wo.jobID === jobID && wo.isDeleted === false
        );
        const { subcategories } = productInfo;
        let facilityInstallBases = getInstallBasesForFacility(installBasesByID, facility.id);

        // For Repair jobs, we need to filter out SAP related Install Bases, those will show on the SAP screen
        if (job.jobTypeID === jobTypesIdEnum.repair && locationPathname === '/repair') {
            let nonSAPInstallBases: IinstallBase[] = [];

            facilityInstallBases.map(install => {
                const result = filter(
                    workOrdersByID,
                    x => x.installBaseID === install.id && x.isDeleted === false && x.source === WorkOrderSource.SAP
                );

                if (result.length === 0) {
                    nonSAPInstallBases.push(install);
                }

                return install;
            });

            // Probably a more elegate TS way to do this
            facilityInstallBases = [];
            facilityInstallBases = nonSAPInstallBases;
        }

        const installBasesPopulated = map(facilityInstallBases, install => {
            const product = productsByID[install.productID] || initialProduct;
            const subcategory = subcategories[product.subcategoryID];
            const locationString = TableUtil.buildLocation(install, facility);
            const latestMeasurementPointListResult =
                MPLResultsByID[install.latestMeasurementPointListResultID] || initialMeasurmentPointResult;

            const latestMeasurementPointListResultForJob = getLatestMeasurementPointListResultForJob({
                job,
                latestMeasurementPointListResult: MPLResultsByID[install.latestMeasurementPointListResultID],
                latestAGSMeasurementPointListResult: MPLResultsByID[install.latestAGSMeasurementPointListResultID],
                latestVerificationMeasurementPointListResult:
                    MPLResultsByID[install.latestVerificationMeasurementPointListResultID],
                latestAuditMeasurementPointListResult: MPLResultsByID[install.latestAuditMeasurementPointListResultID],
                latestCommissioningMeasurementPointListResult:
                    MPLResultsByID[install.latestCommissioningMeasurementPointListResultID]
            });
            const workOrders = getInstallBaseWorkOrders(jobWorkOrdersForJob, install.id);

            const workOrderResultStatus = getWorkOrderResultStatusForJobandInstall(workOrders);

            return {
                ...install,
                product: { ...product, subcategory },
                productNameString: product.name,
                locationString,
                latestMeasurementPointListResult: latestMeasurementPointListResultForJob,
                measurementPointListResultStatus: latestMeasurementPointListResult.status,
                workOrderResultStatus,
                workOrders,
                originalMeasurementPointListResultStatus: install.measurementPointListResultStatus
            } as IinstallBasePopulated; // doing this because I can't figure out why the types for product string and locationstring are not happy
        });

        return installBasesPopulated;
    }
);
export const getSelectedWorkOrders = createSelector(
    [selectSelectedInstallBaseIDs, makeSelectInstallBasesPopulatedTest],
    (selectedInstallBaseIDs, installBaseInfo) =>
        // Finds the work order IDs associated with the selected install bases
        selectedInstallBaseIDs
            .reduce(
                (workOrderIDs: (IWorkOrder | null)[], selectedInstallBaseID) =>
                    workOrderIDs.concat(
                        Object.values(installBaseInfo).find(installBase => installBase.id === selectedInstallBaseID)
                            ?.workOrders || null
                    ),
                []
            )
            .filter(workOrder => workOrder !== null)
);

export const makeSelectInstallBasesPopulated = () => {
    return createSelector(
        [getInstallBases, getProducts, getFacility, getProductInfo, getResults, getJob, selectJobWorkOrdersForJobID],
        (installBasesByID, productsByID, facility, productInfo, MPLResultsByID, job, jobWorkOrdersForJob) => {
            const { subcategories } = productInfo;
            const facilityInstallBases = getInstallBasesForFacility(installBasesByID, facility.id);
            const installBasesPopulated = map(facilityInstallBases, install => {
                const product = productsByID[install.productID] || initialProduct;
                const subcategory = subcategories[product.subcategoryID];
                const locationString = TableUtil.buildLocation(install, facility);
                const latestMeasurementPointListResult = getLatestMeasurementPointListResultForJob({
                    job,
                    latestMeasurementPointListResult: MPLResultsByID[install.latestMeasurementPointListResultID],
                    latestAGSMeasurementPointListResult: MPLResultsByID[install.latestAGSMeasurementPointListResultID],
                    latestVerificationMeasurementPointListResult:
                        MPLResultsByID[install.latestVerificationMeasurementPointListResultID],
                    latestAuditMeasurementPointListResult:
                        MPLResultsByID[install.latestAuditMeasurementPointListResultID],
                    latestCommissioningMeasurementPointListResult:
                        MPLResultsByID[install.latestCommissioningMeasurementPointListResultID]
                });
                const isCommissioningJobType = job.jobTypeID === jobTypesIdEnum.commissioning;
                const workOrders = getInstallBaseWorkOrders(jobWorkOrdersForJob, install.id);
                const workOrderResultStatus = isCommissioningJobType
                    ? install.workOrderResultStatus
                    : getWorkOrderResultStatusForJobandInstall(workOrders);

                return {
                    ...install,
                    product: { ...product, subcategory },
                    productNameString: product.name,
                    locationString,
                    latestMeasurementPointListResult,
                    measurementPointListResultStatus: isCommissioningJobType
                        ? install.measurementPointListResultStatus
                        : latestMeasurementPointListResult.status,
                    workOrderResultStatus,
                    workOrders,
                    originalMeasurementPointListResultStatus: install.measurementPointListResultStatus
                } as IinstallBasePopulated; // doing this because I can't figure out why the types for product string and locationstring are not happy
            });

            return installBasesPopulated;
        }
    );
};

const selectInstallBasesPopulatedProp = (
    state: IinitialState,
    props: { installBasesPopulated: IinstallBasePopulated[] }
) => props.installBasesPopulated;

const selectTableFilterOverridesProp = (state: IinitialState, props: { tableFilterOverrides?: ItableFiltersParams }) =>
    props.tableFilterOverrides || {};

const selectInventoryTableFilters = (state: IinitialState) => state.manageInventory.tableFilters;
const selectIsInventoryTableFiltersEmpty = (state: IinitialState) => {
    let cleanedFilters = omit(state.manageInventory.tableFilters, 'showAdditionalFilters');
    cleanedFilters = {
        ...initialTableFilters,
        ...pickBy(cleanedFilters, property => property !== null)
    };
    if (JSON.stringify(cleanedFilters) === JSON.stringify(initialState.manageInventory.tableFilters)) {
        return true;
    } else {
        return false;
    }
};
const selectIsAddWorkOrderModeProp = (state: IinitialState, props: { isAddWorkOrderMode?: boolean }) =>
    props.isAddWorkOrderMode;
const selectCommissionData = (state: IinitialState) => state.manageInventory.commissioningDataByID;
const getFilterConfig = (state: IinitialState) => state.manageInventory.filterConfig;

export const selectCommissionDataByJobId = createSelector(
    [selectCommissionData, selectSelectedJob],
    (commissionData, job) => Object.values(commissionData).find(item => item.jobID === job.id)
);

export const getInstallBaseStatus = (base: IinstallBasePopulated, jobTypeID: string) => {
    const { latestMeasurementPointListResult, workOrderResultStatus } = base;
    let status = latestMeasurementPointListResult.status;

    // Commissioning status only, other job types should be covered by latestMeasurementPointListResult
    if (jobTypeID === jobTypesIdEnum.commissioning && base.latestMeasurementPointListResultStatus) {
        status = base.latestMeasurementPointListResultStatus;
    }

    if (isWorkOrderTypeJob(jobTypeID)) {
        return workOrderResultStatus;
    }

    // These statuses are a nightmare...even though we are expecting latestMeasurementPointListResult to have the real thing, sometimes it doesn't...
    if (base.latestMeasurementPointListResultStatus && status !== base.latestMeasurementPointListResultStatus) {
        status = base.latestMeasurementPointListResultStatus;
    }

    return status;
};

export const isInstallBaseTested = (status: number, jobTypeID: string): boolean => {
    if (isWorkOrderTypeJob(jobTypeID)) {
        return status === workOrderStatusEnum.complete;
    }
    return status !== measurementPointResultStatusTypesEnum.resultStatusNotTested;
};

export const selectFilterConfig = createSelector([getFilterConfig], config => config);
export const selectInstallBases = createSelector([getInstallBases], ib => ib);
export const selectInstallBasesStatus = createSelector(
    [getIsWorkOrderTypeJob, getInstallBases],
    (isWorkOrder, bases) => {
        const items = Object.values(bases);
        const cleanItems = items.map(
            ({ id, latestMeasurementPointListResultStatus, measurementPointListResultStatus, workOrderResultStatus }) =>
                (({
                    id,
                    status: isWorkOrder ? workOrderResultStatus : latestMeasurementPointListResultStatus
                } as unknown) as InstallBaseStatuses)
        );
        const byId = cleanItems.reduce((acc, item) => {
            acc[item.id] = item.status;
            return acc;
        }, {} as { [key: string]: number | undefined });

        return byId;
    }
);

export const selectLatestResultByInstallBaseID = createSelector(
    [getInstallFormValues, getLatestResults],
    (installBase, latestMPLResultsByID) => latestMPLResultsByID.find(result => result.installBaseID === installBase.id)
);

/*
 * Filter Install bases
 */

export const filterInstallBases = createSelector(
    [
        makeSelectInstallBasesPopulatedTest,
        selectInventoryTableFilters,
        getIsWorkOrderTypeJob,
        selectIsWorkOrderMode,
        selectSelectedJob
    ],
    (installBasesPopulated, tableFilters, isWorkOrderTypeJob, isAddWorkOrderModeProp, job) => {
        let filteredInstallBases: IinstallBasePopulated[] = [];
        const jobTypeID = job.jobTypeID;

        // tablet filters
        const tableFiltersWithOverrides = {
            ...tableFilters
        };
        const {
            building,
            floor,
            location,
            room,
            measurementPointListResultStatus,
            workOrderResultStatus,
            mainCategory,
            search,
            system,
            originalMeasurementPointListResultStatus
        } = tableFiltersWithOverrides;
        const buildingID = building ? building.value : '';
        const floorID = floor ? floor.value : '';
        const locationID = location ? location.value : '';
        const roomID = room ? room.value : '';
        const measurementPointListResultStatusValue = measurementPointListResultStatus
            ? measurementPointListResultStatus.value
            : null;
        const workOrderResultStatusEnumValue = workOrderResultStatus ? workOrderResultStatus.value : null;
        const systemValue = system ? system.value : '';
        const mainCategoryIdFilter = mainCategory ? mainCategory.value : '';

        filteredInstallBases = filter(installBasesPopulated, install => {
            let shouldInclude = true;
            // This filters out specific install bases for special jobType
            const subcategory = install.product.subcategory;

            if (subcategory && subcategory.id === constants.virtualProductSubcategoryID) {
                install.isVirtual = true;
            }

            if (tableFilters.hideVirtualToggle && install.isVirtual) {
                return false;
            }

            // filter AGS Jobs with specifc product categories
            if (jobTypeID === jobTypesIdEnum.agsRebalancing && subcategory && install.isVirtual !== true) {
                const installMainCategoryID = subcategory.mainCategoryID;
                const TypeID = install.product.productTypeID;

                const IsAgss = installMainCategoryID === productMainCategories.agss.toLocaleLowerCase() ? true : false;

                const IsOutletAndTypeAgssWagd =
                    installMainCategoryID === productMainCategories.outlet.toLocaleLowerCase() &&
                    TypeID === productTypes.agssWAGD.toLocaleLowerCase()
                        ? true
                        : false;

                const IsOtherAndSubCategorySwitchSensor =
                    installMainCategoryID === productMainCategories.other.toLocaleLowerCase() &&
                    subcategory.id === subCategories.switchSensor.toLocaleLowerCase()
                        ? true
                        : false;

                if (
                    IsAgss === false &&
                    IsOutletAndTypeAgssWagd === false &&
                    IsOtherAndSubCategorySwitchSensor === false
                ) {
                    shouldInclude = false;
                }
            }
            if (buildingID && buildingID !== 'unallocated' && install.buildingID !== buildingID) {
                shouldInclude = false;
            }
            if (buildingID && buildingID === 'unallocated' && install.buildingID && install.buildingID.length > 0) {
                shouldInclude = false;
            }
            if (floorID && install.floorID !== floorID) {
                shouldInclude = false;
            }
            if (locationID && install.locationID !== locationID) {
                shouldInclude = false;
            }
            if (roomID && install.roomID !== roomID) {
                shouldInclude = false;
            }

            // Set default status
            let status = install.latestMeasurementPointListResult.status;

            // Commissioning status only, other job types should be covered by latestMeasurementPointListResult
            if (jobTypeID === jobTypesIdEnum.commissioning && install.latestMeasurementPointListResultStatus) {
                status = install.latestMeasurementPointListResultStatus;
            }

            // If not commissioning, and the status is different from the latestMeasurementPointListResult, update the status
            if (
                jobTypeID !== jobTypesIdEnum.commissioning &&
                install.latestMeasurementPointListResultStatus &&
                status !== install.latestMeasurementPointListResultStatus
            ) {
                status = install.latestMeasurementPointListResultStatus;
            }

            if (measurementPointListResultStatusValue !== null && status !== measurementPointListResultStatusValue) {
                if (measurementPointListResultStatusValue === inventoryStatusFilterEnum.resultStatusNotTestedLast12M) {
                    let dateTested =
                        install.latestMeasurementPointListResult.createDate !== ''
                            ? moment.utc(install.latestMeasurementPointListResult.createDate)
                            : moment.utc(install.createDate);
                    if (
                        moment
                            .utc(dateTested)
                            .isAfter(moment().subtract(constants.numberOfDaysForNotTestedIn12M, 'days'))
                    ) {
                        shouldInclude = false;
                    }
                } else {
                    shouldInclude = false;
                }
            }

            if (
                isWorkOrderTypeJob &&
                !isAddWorkOrderModeProp &&
                (!install.workOrders || (install.workOrders && install.workOrders.length === 0))
            ) {
                shouldInclude = false;
            }
            if (workOrderResultStatusEnumValue !== null) {
                if (
                    workOrderResultStatusEnumValue === workOrderStatusEnum.open ||
                    workOrderResultStatusEnumValue === workOrderStatusEnum.new
                ) {
                    // assume we are filtering out completed work orders
                    if (install.workOrderResultStatus === workOrderStatusEnum.complete) {
                        shouldInclude = false;
                    }
                } else {
                    if (install.workOrderResultStatus !== workOrderStatusEnum.complete) {
                        shouldInclude = false;
                    }
                }
            }
            if (
                mainCategoryIdFilter.length &&
                install.product.subcategory &&
                install.product.subcategory.mainCategoryID !== mainCategoryIdFilter
            ) {
                shouldInclude = false;
            }
            if (search) {
                const searchString = search.trim().toLowerCase();
                const inProductName = install.productNameString.toLowerCase().indexOf(searchString) !== -1;
                const inSerial = install.serialNumber
                    ? install.serialNumber.toLowerCase().indexOf(searchString) !== -1
                    : false;
                const inNickname = install.nickname
                    ? install.nickname.toLowerCase().indexOf(searchString) !== -1
                    : false;
                const inProductDescription = install.product.description
                    ? install.product.description.toLowerCase().indexOf(searchString) !== -1
                    : false;
                const inAssetNumber = install.rfid ? install.rfid.toLowerCase().indexOf(searchString) !== -1 : false;
                const inInstallRemarks = install.remarks
                    ? install.remarks.toLocaleLowerCase().indexOf(searchString) !== -1
                    : false;

                if (
                    inProductName === false &&
                    inSerial === false &&
                    inNickname === false &&
                    inProductDescription === false &&
                    inAssetNumber === false &&
                    inInstallRemarks === false
                ) {
                    shouldInclude = false;
                }
            }
            if (install.isDeleted === true) {
                shouldInclude = false;
            }
            if (systemValue && systemValue !== install.system) {
                shouldInclude = false;
            }
            if (
                jobTypeID === jobTypesIdEnum.repair &&
                originalMeasurementPointListResultStatus &&
                originalMeasurementPointListResultStatus.value !== install.originalMeasurementPointListResultStatus
            ) {
                shouldInclude = false;
            }

            return shouldInclude;
        });

        return orderBy(filteredInstallBases, ['locationString', 'productNameString'], ['asc', 'asc']);
    }
);

const getJobNotesBySelectedJobId = createSelector([getManageJobCommentById, getSelectedJob], (jobNotes, selectedJob) =>
    filter(Object.values(jobNotes), note => note.jobID === selectedJob.id)
);

const jobIsOfType = (jobType: string, ...types: jobTypesIdEnum[]) => types.find(t => t === jobType) !== undefined;

// had to make this selector because this library only allows for 12 input selectors
const getNotesAndContacts = createSelector(
    [getJobNotesBySelectedJobId, getContacts, getSelectedJob],
    (jobNotes, contacts, selectedJob) => {
        return {
            jobNotes,
            contacts: contacts[selectedJob.facilityID]
        };
    }
);
export const getCompleteJobCheckItems = createSelector(
    [
        selectJobWorkOrdersWithWorkOrders,
        getSelectedJob,
        filterInstallBases,
        getManageJob,
        selectInstallBasePartsPopulated,
        getHistoricalWorkOrderID,
        selectJobWorkOrderForHistoricalWorkOrder,
        getMeasurementPointResults,
        getHistoricalResultID,
        getNotesAndContacts,
        getInstallBases,
        getProducts
    ],
    (
        jobWorkOrdersByID,
        selectedJob,
        installBases,
        manageJob,
        installBasePartsPopulated,
        historicalWorkOrderID,
        historicalJobWO,
        mpResults,
        historicalResultID,
        notesAndContacts,
        allInstallbases,
        allProducts
    ) => {
        const { jobNotes, contacts } = notesAndContacts;
        const { jobHours, id: jobID, jobTypeID } = selectedJob;
        const jobWorkOrders = filter(jobWorkOrdersByID, { jobID: jobID, isDeleted: false });
        const { jobSignaturesByID } = manageJob;

        const selectedJobSignatures: IjobSignature[] = Object.values(jobSignaturesByID).filter(s => s.jobID === jobID);
        const partsErrorApplicable = jobIsOfType(jobTypeID, jobTypesIdEnum.repair);
        const partsError = !installBases.every(installBase => {
            if (
                installBase.workOrders &&
                installBase.workOrders.length > 0 &&
                installBase.workOrders.find(wo => wo.parts && wo.parts.length > 0)
            ) {
                // Parts contains the parts that the engineer has added to complete the job
                // installBase.workOrders.parts contains all the parts that are required to complete the job
                // We need to make sure that the engineer has used all the parts that are required to complete the job (even if the quantity is 0)
                // This only applies to jobs that have work order parts
                const parts = selectAllInstallBasePartsForJob(
                    jobID,
                    installBasePartsPopulated,
                    installBase.id,
                    historicalWorkOrderID,
                    historicalResultID,
                    historicalJobWO,
                    mpResults
                );
                const error = installBase.workOrders.every(wo =>
                    wo.parts?.every(
                        woPart =>
                            parts.find(
                                p =>
                                    p.part.id === woPart.partID &&
                                    p.workOrderPartQuantity !== undefined &&
                                    p.workOrderPartQuantity >= woPart.estimated
                            ) !== undefined
                    )
                );
                return error;
            }
            return true;
        });

        const areAllAssetedTestApplicable = jobIsOfType(
            jobTypeID,
            jobTypesIdEnum.inspection,
            jobTypesIdEnum.agsRebalancing,
            jobTypesIdEnum.verification
        );
        const isAllAssetsComplete = installBases.reduce((complete: boolean, installBase: IinstallBasePopulated) => {
            if (!complete) {
                return false;
            }
            return isInstallBaseTested(getInstallBaseStatus(installBase, selectedJob.jobTypeID), selectedJob.jobTypeID);
        }, true);

        // Checking if there are existing SAP WO's for the facility that have not been added to the job
        const sapApplicable = !jobIsOfType(jobTypeID, jobTypesIdEnum.inspection);

        const sapWOslinkedToJob = jobWorkOrders
            .filter(jwo => jwo.jobID === jobID && jwo.workOrder.source === WorkOrderSource.SAP && !jwo.isDeleted)
            .map((jwo: IjobWorkOrder) => {
                // Now that we've filtered to only SAP Work Orders, let's only return the ones that are not linked to a virtual asset
                let ib = installBases.find(installBase => installBase.id === jwo.workOrder?.installBaseID);

                // If virtual assets are being filtered out, ib might be undefined, so check the master list of installs
                if (ib === undefined) {
                    ib = Object.values(allInstallbases).find(
                        installBase => installBase.id === jwo.workOrder?.installBaseID
                    ) as IinstallBasePopulated;

                    // If we found it, let's attach it's product
                    if (ib) {
                        ib.product = allProducts[ib.productID];

                        // If it's a virtual product, mark it as such
                        if (ib.product && ib.product.subcategoryID === constants.virtualProductSubcategoryID) {
                            ib.isVirtual = true;
                        }
                    }
                }

                if (ib && ib.isVirtual !== undefined && ib.isVirtual === true) {
                    return undefined;
                }

                return jwo;
            })
            .filter((jwo: IjobWorkOrder | undefined) => jwo !== undefined); // now remove any undefined values (I'm sure this could be done better)

        const linkedAndIncompleteWOs = sapWOslinkedToJob.filter(
            wo => wo?.workOrder?.status !== workOrderStatusEnum.complete
        );

        const fail = linkedAndIncompleteWOs.length > 0;
        const sapWOStatus = fail ? CheckedItemStatus.fail : CheckedItemStatus.pass;
        const sapWOMessage = !fail
            ? 'All linked SAP WO for facility completed.'
            : `There ${sapWOslinkedToJob.length > 1 ? 'are' : 'is'} ${sapWOslinkedToJob.length} SAP ${
                  sapWOslinkedToJob.length > 1 ? 'Work Orders' : 'Work Order'
              } linked to that Facility. ${linkedAndIncompleteWOs.length} ${
                  linkedAndIncompleteWOs.length > 1 ? 'are' : 'is'
              } incomplete.`;

        const toReturn: IcheckedItems[] = [
            {
                primaryText: 'All assets tested for inspection',
                secondaryText: '(Optional)',
                passed: isAllAssetsComplete ? CheckedItemStatus.pass : CheckedItemStatus.fail,
                optional: true,
                applicable: areAllAssetedTestApplicable
            },
            {
                primaryText: 'Hours submmitted',
                secondaryText: '(Optional, but highlighted)',
                passed: (jobHours?.length || 0) > 0 ? CheckedItemStatus.pass : CheckedItemStatus.fail,
                optional: true,
                applicable: jobIsOfType(
                    jobTypeID,
                    jobTypesIdEnum.inspection,
                    jobTypesIdEnum.commissioning,
                    jobTypesIdEnum.repair,
                    jobTypesIdEnum.agsRebalancing,
                    jobTypesIdEnum.verification,
                    jobTypesIdEnum.servicePlan
                )
            },
            {
                primaryText: 'Addional job notes added',
                secondaryText: '(Optional)',
                passed: jobNotes.length > 0 ? CheckedItemStatus.pass : CheckedItemStatus.fail,
                optional: true,
                applicable: true
            },
            {
                primaryText: 'All parts used (Unused parts will go into your inventory)',
                secondaryText: '(Mandatory)',
                passed: partsError ? CheckedItemStatus.fail : CheckedItemStatus.pass,
                optional: true,
                applicable: partsErrorApplicable
            },
            {
                primaryText: 'Signatures collected',
                secondaryText: '(Mandatory)',
                passed: selectedJobSignatures.length > 0 ? CheckedItemStatus.pass : CheckedItemStatus.fail,
                optional: false,
                applicable: true
            },
            {
                primaryText: sapWOMessage,
                secondaryText: '(Optional)',
                passed: sapWOStatus,
                optional: false,
                applicable: sapApplicable
            },
            {
                primaryText: 'Faciltiy Contacts',
                secondaryText: '(Optional)',
                passed: CheckedItemStatus.neutral,
                optional: true,
                applicable: true,
                contacts
            }
        ];
        return toReturn;
    }
);

export const getFilteredInstallBasesNotComplete = createSelector(
    [filterInstallBases, getSelectedJob],
    (filteredInstallBases, job) =>
        filteredInstallBases.filter(
            ib => ib.isVirtual !== true && !isInstallBaseTested(getInstallBaseStatus(ib, job.jobTypeID), job.jobTypeID)
        )
);

export const selectMainCategoryFromProductID = (state: IinitialState, props: { productID: string }): ImainCategory => {
    const { manageInventory: manageInventoryState, productInfo } = state;
    const { productsByID } = manageInventoryState;
    const product = productsByID[props.productID];
    if (!product) {
        console.error(
            '[selectMainCategoryFromProductID]: missing product. might still be loading products.',
            props.productID,
            productsByID
        );
        return { name: 'unknown', id: '', isDeleted: false };
    }
    const subCategoryID = product.subcategoryID;
    const subCategory = productInfo.subcategories[subCategoryID];
    const mainCategoryID = subCategory.mainCategoryID;
    return productInfo.mainCategories[mainCategoryID];
};

export const selectInventoryNoDataText = createSelector(
    [getIsWorkOrderTypeJob, selectSelectedJob, selectIsInventoryTableFiltersEmpty],
    (isWorkOrderTypeJob, selectedJob, isInventoryTableFiltersEmpty) => {
        if (isWorkOrderTypeJob && isInventoryTableFiltersEmpty) {
            switch (selectedJob.jobTypeID) {
                case jobTypesIdEnum.maintenance:
                    return 'manageInventory:noDataTextMaintenance';
                case jobTypesIdEnum.repair:
                case jobTypesIdEnum.servicePlan:
                case jobTypesIdEnum.commissioning:
                    return 'manageInventory:noDataTextRepair';
                case jobTypesIdEnum.warrantyBM:
                    return 'manageInventory:noDataTextWarranty';
                default:
                    console.error('[selectInventoryNoDataText]: Should not occur');
                    return 'common:noDataText';
            }
        } else {
            return 'common:noDataText';
        }
    }
);

// simple reducer to store a set of products that are in use by the active job's facility
function productsByIDReducer(
    state: { [key: string]: Iproduct } = initialState.manageInventory.productsByID,
    action: any
): { [key: string]: Iproduct } {
    switch (action.type) {
        case types.GET_ALL_PRODUCTS_SUCCESS: {
            const newProducts = keyBy(
                map(action.payload, (product: Iproduct[]) => {
                    return cleanProductObject(product);
                }),
                'id'
            );
            return { ...state, ...newProducts };
        }
        case types.PRODUCT_UPDATES: {
            // receive updated products from /latest
            const updatedProducts = keyBy(action.products, 'id');
            return { ...state, ...updatedProducts };
        }
        case types.INSTALL_ADD: {
            if (action.product) {
                return { ...state, [action.product.id]: action.product };
            }
            return state;
        }
        case types.LOAD_SAP_WORKORDERS_SUCCESS:
            let sapProducts: Iproduct[] = [];
            action.payload.data.result.forEach((workOrder: IWorkOrder) => {
                if (workOrder.product) {
                    sapProducts.push(workOrder.product);
                }
            });

            const updatedProducts = keyBy(sapProducts, 'id');
            return { ...state, ...updatedProducts };
        case types.USER_LOGOUT_SUCCESS:
            return initialState.manageInventory.productsByID;
        default:
            return state;
    }
}
/*
 * Install Bases By ID Reducer
 */
function installBasesByIDReducer(
    state: { [key: string]: IinstallBase } = initialState.manageInventory.installBasesByID,
    action: any
): { [key: string]: IinstallBase } {
    switch (action.type) {
        case types.GET_INVENTORY_SUCCESS: {
            let installs = [];
            if (action.installBases) {
                installs = action.installBases;
            } else {
                installs = action.payload.data;
            }

            // Update Products in Redux with Products from these new Install Bases
            offlineProductsProvider.addNewProductsFromInstallBases(installs);

            const installBases = keyBy(
                map(installs, installBase => cleanInstallBaseObject(installBase, state[installBase.id])),
                'id'
            );

            return { ...state, ...installBases };
        }
        case types.UPDATE_WORK_ORDERS_BULK: {
            let updatedInstallBases: IinstallBase[] = [];
            action.workOrders.forEach((wo: IWorkOrder) => {
                const updatedInstall = {
                    ...state[wo.installBaseID],
                    workOrderResultStatus: wo.type
                };
                updatedInstallBases = [...updatedInstallBases, updatedInstall];
            });
            return { ...state, ...keyBy(updatedInstallBases, 'id') };
        }
        case types.INSTALL_UPDATE:
            if (action.fullObject) {
                return {
                    ...state,
                    [action.install.id]: {
                        ...action.install
                    }
                };
            } else {
                return {
                    ...state,
                    [action.install.id]: {
                        ...state[action.install.id],
                        ...action.install
                    }
                };
            }
        case types.INSTALL_UPDATE_BULK:
            return { ...state, ...keyBy(action.installs, 'id') };

        /*
         * It is possible to add multiple installs at the same time.
         */
        case types.INSTALL_ADD:
            return { ...state, ...action.installs };
        case types.UPDATE_MEASUREMENT_POINT_RESULT_BULK: {
            // after submitting a new results, if they are for an inspection type job, update the resultStatus and resultID on the installBases
            let installsWithNewStatus: IinstallBase[] = [];

            if (
                action.results &&
                action.results.length &&
                (action.jobTypeID === jobTypesIdEnum.inspection ||
                    action.jobTypeID === jobTypesIdEnum.agsRebalancing ||
                    action.jobTypeID === jobTypesIdEnum.audit ||
                    action.jobTypeID === jobTypesIdEnum.commissioning ||
                    action.jobTypeID === jobTypesIdEnum.verification)
            ) {
                if (action.jobTypeID === jobTypesIdEnum.inspection) {
                    forEach(action.results, (result: ImeasurementPointResult) => {
                        const installWithNewStatus = {
                            ...state[result.installBaseID],
                            measurementPointListResultStatus: result.status,
                            latestMeasurementPointListResultStatus: result.status,
                            latestMeasurementPointListResultID: result.id
                        };
                        installsWithNewStatus = [...installsWithNewStatus, installWithNewStatus];
                    });
                }
                if (action.jobTypeID === jobTypesIdEnum.agsRebalancing) {
                    forEach(action.results, (result: ImeasurementPointResult) => {
                        const installWithNewStatus = {
                            ...state[result.installBaseID],
                            latestMeasurementPointListResultStatus: result.status,
                            latestAGSMeasurementPointListResultID: result.id
                        };
                        installsWithNewStatus = [...installsWithNewStatus, installWithNewStatus];
                    });
                }

                if (action.jobTypeID === jobTypesIdEnum.audit) {
                    forEach(action.results, (result: ImeasurementPointResult) => {
                        const installWithNewStatus = {
                            ...state[result.installBaseID],
                            latestMeasurementPointListResultStatus: result.status,
                            latestAuditMeasurementPointListResultID: result.id
                        };
                        installsWithNewStatus = [...installsWithNewStatus, installWithNewStatus];
                    });
                }

                if (action.jobTypeID === jobTypesIdEnum.verification) {
                    forEach(action.results, (result: ImeasurementPointResult) => {
                        const installWithNewStatus = {
                            ...state[result.installBaseID],
                            latestMeasurementPointListResultStatus: result.status,
                            latestVerificationMeasurementPointListResultID: result.id
                        };
                        installsWithNewStatus = [...installsWithNewStatus, installWithNewStatus];
                    });
                }

                if (action.jobTypeID === jobTypesIdEnum.commissioning) {
                    forEach(action.results, (result: ImeasurementPointResult) => {
                        const installWithNewStatus = {
                            ...state[result.installBaseID],
                            latestMeasurementPointListResultStatus: result.status,
                            latestCommissioningMeasurementPointListResultID: result.id,
                            measurementPointListResultStatus: result.status,
                            workOrderResultStatus: result.status
                        };
                        installsWithNewStatus = [...installsWithNewStatus, installWithNewStatus];
                    });
                }

                return { ...state, ...keyBy(installsWithNewStatus, 'id') };
            } else {
                return state;
            }
        }

        case types.INSTALL_DELETE:
            return {
                ...state,
                [action.installBaseID]: {
                    ...state[action.installBaseID],
                    isDeleted: true
                }
            };
        case types.LOCATION_DELETE_SUCCESS: {
            const locationObject = action.locationObject as Ilocation | Ibuilding | Ifloor | Iroom;
            let locationKey: 'buildingID' | 'floorID' | 'locationID' | 'roomID' = 'buildingID';
            if ('facilityID' in locationObject) {
                // BUILDING
                locationKey = 'buildingID';
                return keyBy(
                    map(state, install => {
                        if (install[locationKey] === locationObject.id) {
                            return {
                                ...install,
                                [locationKey]: '',
                                floorID: '',
                                locationID: '',
                                roomID: ''
                            };
                        } else {
                            return install;
                        }
                    }),
                    'id'
                );
            } else if ('buildingID' in locationObject) {
                // FLOOR
                locationKey = 'floorID';
                return keyBy(
                    map(state, install => {
                        if (install[locationKey] === locationObject.id) {
                            return {
                                ...install,
                                [locationKey]: '',
                                locationID: '',
                                roomID: ''
                            };
                        } else {
                            return install;
                        }
                    }),
                    'id'
                );
            } else if ('floorID' in locationObject) {
                // LOCATION
                locationKey = 'locationID';
                return keyBy(
                    map(state, install => {
                        if (install[locationKey] === locationObject.id) {
                            return {
                                ...install,
                                [locationKey]: '',
                                roomID: ''
                            };
                        } else {
                            return install;
                        }
                    }),
                    'id'
                );
            } else if ('locationID' in locationObject) {
                // ROOM
                locationKey = 'roomID';
                return keyBy(
                    map(state, install => {
                        if (install[locationKey] === locationObject.id) {
                            return { ...install, [locationKey]: '' };
                        } else {
                            return install;
                        }
                    }),
                    'id'
                );
            }
            return state;
        }
        case types.USER_LOGOUT_SUCCESS:
            return {};
        default:
            return state;
    }
}

function commissioningDataByIDReducer(
    state: { [key: string]: IcommissioningData } = initialState.manageInventory.commissioningDataByID,
    action: any
): { [key: string]: IcommissioningData } {
    switch (action.type) {
        case types.SAVE_COMMISSIONING_DATA: {
            return { ...state, [action.commissioningData.id]: action.commissioningData };
        }
        default:
            return state;
    }
}

function selectedProductReducer(state: Iproduct = initialProduct, action: any): Iproduct {
    switch (action.type) {
        case types.CLEAR_SELECTED_MANAGE_INVENTORY_PRODUCT_ID:
            return initialProduct;
        case types.SET_SELECTED_PRODUCT:
            return action.product ? action.product : initialProduct;
        case types.USER_LOGOUT_SUCCESS:
            return initialProduct;
        default:
            return state;
    }
}

function newProductsReducer(state: { [key: string]: Iproduct } = {}, action: any): { [key: string]: Iproduct } {
    switch (action.type) {
        case types.SEARCH_PRODUCTS:
            return action.products ? action.products : {};
        case types.NEW_PRODUCTS_RESET:
            return action.products ? action.products : {};
        case types.USER_LOGOUT_SUCCESS:
            return {};
        default:
            return state;
    }
}

function recentProductsReducer(state: string[] = [], action: any): string[] {
    switch (action.type) {
        case types.ADD_RECENT_PRODUCT:
            if (state.indexOf(action.productID) >= 0) {
                return state;
            } else {
                if (state.length >= constants.searchProductRecentProductLimit) {
                    return [action.productID, ...state.splice(0, constants.searchProductRecentProductLimit)];
                }
                return [action.productID, ...state];
            }
        case types.REMOVE_RECENT_PRODUCT:
            return state.filter(productID => productID !== action.productID);
        case types.USER_LOGOUT_SUCCESS:
            return [];
        default:
            return state;
    }
}

function selectionReducer(state: string[] = initialState.manageInventory.selection, action: any): string[] {
    switch (action.type) {
        case types.INSTALL_UPDATE_SELECTION:
            return action.payload;
        case types.USER_LOGOUT_SUCCESS:
            return initialState.manageInventory.selection;
        default:
            return state;
    }
}

function bulkInstallBaseModeReducer(
    state: boolean = initialState.manageInventory.enableBulkInstallBaseMode,
    action: any
): boolean {
    switch (action.type) {
        case types.SET_INSTALL_BATCH_MODE:
            return action.payload;
        case types.INSTALL_UPDATE_BULK:
            return false;
        case types.USER_LOGOUT_SUCCESS:
            return initialState.manageInventory.enableBulkInstallBaseMode;
        default:
            return state;
    }
}

function bulkInstallBaseCategoryReducer(
    state: string = initialState.manageInventory.bulkInstallBaseCategory,
    action: any
): string {
    switch (action.type) {
        case types.SET_INSTALL_BATCH_CATEGORY:
            return action.payload;
        case types.INSTALL_UPDATE_BULK:
            return initialState.manageInventory.bulkInstallBaseCategory;
        case types.USER_LOGOUT_SUCCESS:
            return initialState.manageInventory.bulkInstallBaseCategory;
        default:
            return state;
    }
}

function filterConfigReducer(state: FieldConfig = initialState.manageInventory.filterConfig, action: any): FieldConfig {
    switch (action.type) {
        case types.ADD_FILTER_CONFIG:
            return action.payload;
        default:
            return state;
    }
}

function damageCodesByInstallBaseIDReducer(
    state: { [key: string]: IdamageCode } = initialState.manageInventory.damageCodes,
    action: any
): { [key: string]: any } {
    switch (action.type) {
        case types.SAVE_DAMAGE_CODES_SUCCESS:
            return { ...state, [action.installBaseID]: action.result };
        case types.JOB_GET_ASSIGNED_SUCCESS:
            let dataToSave: { [key: string]: IdamageCode } = {};
            if (action.payload?.data?.damageCodes?.length > 0) {
                const damageCodes: any[] = action.payload.data.damageCodes;
                damageCodes.forEach((damageCode: any) => {
                    const newDC: IdamageCode = {
                        causeCode: damageCode.causeCode,
                        damageCodeText: damageCode.damageCodeText,
                        failedPartNumber: damageCode.failedPartNumber,
                        objectPartCode: damageCode.objectPartCode,
                        objectPartGroup: damageCode.objectPartGroup,
                        rootCause: damageCode.rootCause,
                        jobID: action.payload.data.job.id,
                        installBaseID: damageCode.installBaseID
                    };
                    dataToSave = { ...dataToSave, [damageCode.installBaseID]: newDC };
                });
            }
            return { ...state, ...dataToSave };
        default:
            return state;
    }
}

function refreshInventoryReducer(state: string = initialState.manageInventory.refreshInventory, action: any): string {
    switch (action.type) {
        case types.UPDATE_MEASUREMENT_POINT_RESULT_BULK_SUCCESS:
            return action.meta.id;
        default:
            return state;
    }
}

function showManageInventoryLoadingReducer(
    state: boolean = initialState.manageInventory.showManageInventoryLoading,
    action: any
): boolean {
    switch (action.type) {
        case types.UPDATE_MANAGE_INVENTORY_LOADING:
            return action.payload;
        default:
            return state;
    }
}

const manageInventory = combineReducers({
    installBasesByID: installBasesByIDReducer,
    commissioningDataByID: commissioningDataByIDReducer,
    productsByID: productsByIDReducer,
    selectedProduct: selectedProductReducer,
    newProducts: newProductsReducer,
    recentProducts: recentProductsReducer,
    selection: selectionReducer,
    enableBulkInstallBaseMode: bulkInstallBaseModeReducer,
    bulkInstallBaseCategory: bulkInstallBaseCategoryReducer,
    showEditProductModal: createShowModalWithNamedType('EDIT_PRODUCT'),
    showSearchNewProductsModal: createShowModalWithNamedType('SEARCH_NEW_PRODUCTS'),
    showEditInstallModal: createShowModalWithNamedType('EDIT_INSTALL'),
    showSignatureModal: createShowModalWithNamedType('SIGNATURE_PAD'),
    showNoteModal: createShowModalWithNamedType('NOTE_PAD'),
    showEditJobDefaultsModal: createShowModalWithNamedType('EDIT_JOB_DEFAULTS'),
    showCommissioningDataFormModal: createShowModalWithNamedType('COMMISSIONING_DATA_FORM'),
    tableFilters: createTableFiltersWithName('MANAGE_INVENTORY'),
    productSearchFormValues: createFormValuesWithName('MANAGE_INVENTORY_PRODUCT_SEARCH'),
    installFormValues: createFormValuesWithName('MANAGE_INVENTORY_INSTALL'),
    editJobDefaultsFormValues: createFormValuesWithName('EDIT_JOB_DEFAULTS'),
    selectedProductID: createSelectedIDWithName('MANAGE_INVENTORY_PRODUCT_ID'),
    showAddHoursModal: createShowModalWithNamedType('ADD_HOURS'),
    filterConfig: filterConfigReducer,
    damageCodes: damageCodesByInstallBaseIDReducer,
    refreshInventory: refreshInventoryReducer,
    showModalAddFSE: createShowModalWithNamedType('ADD_FSE'),
    showModalEditFacilityContacts: createShowModalWithNamedType('EDIT_FACILITY_CONTACTS'),
    showManageInventoryLoading: showManageInventoryLoadingReducer
});

export default manageInventory;
