/*
 * This component has 2 primary responsibilities
 * 1)  a successful login into Azure
 * If it is an existing user, route to the dashboard or whatever private route they were trying to access before
 * being redirected to the login screen.
 * If it is a new user, route to the signup page
 * 2) provide a plane for the user to begin logging in
 */

import * as React from 'react';

import { Button, Col, Grid, Row } from 'react-bootstrap';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Iuser } from '../../models';
import { MSALlogin, getHoursCheck, resetUserLogout, userLogin, userLogoutSessionOnly } from '../../actions/userActions';
import { debugMSAL, forgotPasswordRequest } from './Auth-Utils';
import { initialSyncStart, setUpInitialAppSync } from '../../actions/commonActions';

import { RouteComponentProps } from 'react-router-dom';
import { checkSyncStatus } from '../../actions/commonActions';
import { connect } from 'react-redux';
import msalInstance from './MsalInstance';
import { TFunction } from 'i18next';
import { IinitialState } from '../../reducers';
import { selectIsLoading } from '../../reducers/commonReducers';
import Mixpanel from '../../helpers/Mixpanel';
import { langOption } from '../../i18n';
import { AuthenticationResult } from '@azure/msal-browser';

const azure = require('../../images/Azure.png');
const Loading = () => <h3>Checking Authentication...</h3>;

interface Iprops extends RouteComponentProps<{}> {
    MSALlogin: (t: TFunction) => Promise<any>;
    userLogin: (t: TFunction) => any;
    user: Iuser;
    t: TFunction;
    loading: boolean;
    checkSyncStatus: typeof checkSyncStatus;
    userLogoutSessionOnly: typeof userLogoutSessionOnly;
    online: boolean;
    getHoursCheck: typeof getHoursCheck;
    setUpInitialAppSync: typeof setUpInitialAppSync;
    initialSyncStart: typeof initialSyncStart;
    resetUserLogout: typeof resetUserLogout;
}

interface Istate {
    userLoginFailed: boolean;
    userIsAuthenticated: boolean;
}

class Login extends React.Component<Iprops & WithTranslation, Istate> {
    constructor(props: Iprops & WithTranslation) {
        super(props);

        this.state = {
            userLoginFailed: false,
            userIsAuthenticated: false
        };
    }

    componentDidMount() {
        document.addEventListener('missingUser', this.handleRedirectToSignup, false);
        const locationState: any = this.props.location.state;
        const { from } = locationState || { from: { pathname: '/jobs' } };

        debugMSAL('msal: ' + msalInstance.msalApp.getAllAccounts());

        if (msalInstance.msalApp.getAllAccounts().length > 0) {
            if (!this.props.user.isAuthenticated) {
                this.handleLogin(from);
            } else {
                debugMSAL('user is fully authenticated and the login component mounted');

                if (this.props.user.language) {
                    const lang = langOption(this.props.user.language);

                    this.props.i18n.changeLanguage(lang);
                }

                // user is authenticated, so take them back to where they came from or to the default route
                this.setState({ userIsAuthenticated: true });
                this.props.history.push(from.pathname);
            }
        } else {
            debugMSAL('no msal account');
            if (this.props.user.isAuthenticated) {
                // we get here when the token is expired.  If they are offline, we still want to redirect and allow the user to continue working
                debugMSAL('token is expired but user is authenticated. do nothing.');
                if (this.props.online === false) {
                    this.setState({ userIsAuthenticated: true });
                    this.props.history.push(from.pathname);
                }
                // else {
                //     console.error('token is expired but user is authenticated. User Logging out: ', this.props.user.isLoggingOut);
                //     if(this.props.user.isLoggingOut === false) {
                //         this.props.userLogoutSessionOnly();
                //     }
                // }
            }
        }

        msalInstance.msalApp
            .handleRedirectPromise()
            .then(this.handleRedirectResponse)
            .catch(this.handleRedirectError);
    }

    handleRedirectResponse = (response: AuthenticationResult | null) => {
        if (response && response.account && this.state.userIsAuthenticated === false) {
            this.setState({ userIsAuthenticated: true });
            msalInstance.msalApp.setActiveAccount(response.account);

            const locationState: any = this.props.location.state;
            const { from } = locationState || { from: { pathname: '/jobs' } };
            this.handleLogin(from);
        }
    };

    handleRedirectError = (error: any) => {
        this.setState({ userIsAuthenticated: false });

        // If the user is requesting Password Reset, redirect them to the forgot password page
        if (error?.errorMessage?.indexOf('AADB2C90118') > -1) {
            msalInstance.msalApp.loginRedirect(forgotPasswordRequest).catch(fprError => {
                console.error('[MSALlogin (redirect)]:', fprError);
                throw error();
            });
        }

        console.error('Redirect error:', error);
    };

    componentWillUnmount() {
        document.removeEventListener('missingUser', this.handleRedirectToSignup, false);
    }

    handleLogin = (from: any) => {
        debugMSAL('loading with MSAL account and logging user into app.');
        this.props
            .userLogin(this.props.t)
            .then(() => {
                Mixpanel.track('Login Successful');

                debugMSAL('logged user in successfully');
                if (this.props.user.isAuthenticated) {
                    this.props.history.push(from.pathname);
                    this.props.setUpInitialAppSync();
                    this.props.initialSyncStart();
                    this.props.resetUserLogout();
                    this.props.getHoursCheck();
                }
            })
            .catch((error: any) => {
                console.error('[handleLogin]:', error);
                Mixpanel.track('Login Unsuccessful');
                this.setState({ userLoginFailed: true });
                // this should be handled by a redirect and successful login.  If not then after a while this logout will run
                setTimeout(() => {
                    msalInstance.msalApp.logoutRedirect({
                        postLogoutRedirectUri: '/'
                    });
                }, 6000);
            });
    };

    handleRedirectToSignup = () => {
        this.props.history.push('/social_signup');
    };

    handleMsalLogin = () => {
        this.props
            .MSALlogin(this.props.t)
            .then(result => {
                Mixpanel.track('Login Successful');

                debugMSAL('logged user in successfully');
                if (this.props.user.isAuthenticated) {
                    this.props.setUpInitialAppSync();
                    this.props.initialSyncStart();
                    this.props.resetUserLogout();
                    if (this.props.location.pathname === '/') {
                        this.props.history.push('/jobs');
                    }
                    this.props.getHoursCheck();
                }
            })
            .catch(error => {
                console.error('[handleMsalLogin]:', error);
            });
    };

    render() {
        const { t } = this.props;
        if (this.props.online === false && (this.props.user.isAuthenticated || this.props.user.isLoggingOut)) {
            Mixpanel.identify(this.props.user.id);
            Mixpanel.people.set({
                $email: this.props.user.email,
                USER_ID: this.props.user.id
            });

            return <Loading />;
        } else {
            return (
                <div className="loginlayout">
                    <Grid>
                        <Row>
                            <Col>
                                <div className="loginForm login">
                                    <span className="loginTitle">{t('welcomeMobile')}</span>
                                    <Button
                                        bsStyle="default"
                                        className="loginBtn"
                                        onClick={this.handleMsalLogin}
                                        disabled={this.props.loading}
                                    >
                                        <img width="20" height="20" src={azure} alt="azure" />
                                        {t('loginButton')}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Grid>
                </div>
            );
        }
    }
}
const mapStateToProps = (state: IinitialState, ownProps: any) => {
    return {
        user: state.user,
        loading: selectIsLoading(state),
        online: state.offline.online
    };
};

export default withTranslation('auth')(
    connect(mapStateToProps, {
        userLogin,
        MSALlogin,
        checkSyncStatus,
        userLogoutSessionOnly,
        getHoursCheck,
        setUpInitialAppSync,
        initialSyncStart,
        resetUserLogout
    })(Login)
);
