import * as types from './actionTypes';

export const toggleSearchFacilityModal = () => ({
    type: types.TOGGLE_MODAL_SEARCH_FACILITY
});

export const updateSearchFacilityFormValues = (formValues: { [key: string]: any }) => ({
    type: types.UPDATE_FORM_VALUES_FACILITIES_FACILITY_SEARCH,
    formValues
});
export const setSearchFacilityFormValues = (formValues: { [key: string]: any }) => ({
    type: types.SET_FORM_VALUES_FACILITIES_FACILITY_SEARCH,
    formValues
});

export const updateAddFacilityFormValues = (formValues: { [key: string]: any }) => ({
    type: types.UPDATE_FORM_VALUES_FACILITIES_ADD_FACILITY,
    formValues
});
export const setAddFacilityFormValues = (formValues: { [key: string]: any }) => ({
    type: types.SET_FORM_VALUES_FACILITIES_ADD_FACILITY,
    formValues
});
