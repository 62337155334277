/*
 * Preventative Maintenance Table Container
 */

import { IWorkOrder } from '../../models';

import { PreventativeMaintenanceTableWithSelect } from './PreventativeMaintenanceTableWithSelect';
import { TFunction } from 'i18next';
import { connect } from 'react-redux';
import { setSelectedWorkOrderID, setTableFilter, updateWorkOrderSelection } from '../../actions/workOrderActions';
import { selectIsLoading } from '../../reducers/commonReducers';

import { IinitialState } from '../../reducers';
import { withTranslation } from 'react-i18next';
import { filterPMPWorkOrders } from '../../reducers/workOrderReducer';
import { initialFacility } from '../../reducers/initialState';
import { selectJobWorkOrdersWithWorkOrders } from '../../reducers/commonSelectors';

interface Iprops {
    t: TFunction;
    colorButton: string;
    overrideColumns?: any[];
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops) => {
    const jobWorkOrdersByID = selectJobWorkOrdersWithWorkOrders(state);
    const tableData: IWorkOrder[] = filterPMPWorkOrders(state);
    const { tableFilters } = state.workOrder;
    const { selectedJob } = state.manageJob;
    const facility = state.facilities.facilitiesByID[selectedJob.facilityID] || initialFacility;

    return {
        loading: selectIsLoading(state),
        tableData,
        tableFilters,
        user: state.user,
        selection: state.workOrder.selection,
        jobWorkOrdersByID,
        jobsByID: state.manageJob.jobsByID,
        fseUsers: state.manageJob.fseUsers,
        selectedJob,
        preventativeMaintenanceEnabled: facility.preventativeMaintenanceEnabled
    };
};

export const PreventativeMaintenanceTableWithSelectContainer = withTranslation('nsJob')(
    connect(mapStateToProps, {
        setSelectedWorkOrderID,
        setTableFilter,
        updateWorkOrderSelection
    })(PreventativeMaintenanceTableWithSelect)
);
