import { connect } from 'react-redux';

import { toggleWorkOrderCloseModal } from '../../actions/workOrderActions';
import { initialWorkOrder } from '../../reducers/initialState';

import { IinitialState } from '../../reducers';
import WorkOrderCloseForm from './WorkOrderCloseNotesForm';
import { withTranslation, WithTranslation } from 'react-i18next';
import { passSelected } from '../../actions/measurementPointResultsActions';
import { IinstallBasePopulated } from '../../models';
import { selectIsLoading } from '../../reducers/commonReducers';
import { WithCommonModal } from '../common/WithCommonModal';

interface Iprops {
    colorButton: string;
    installBasesPopulated: IinstallBasePopulated[];
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops & WithTranslation) => {
    const selectedWorkOrder = initialWorkOrder;

    return {
        className: 'confirm-select-modal',
        title: ownProps.t('addClosingNotesBulk', {
            count: state.manageInventory.selection.length
        }),
        loading: selectIsLoading(state),
        show: state.workOrder.showWorkOrderCloseModal,
        selectedWorkOrder,
        selection: state.manageInventory.selection
    };
};

export default withTranslation('manageWorkOrder')(
    connect(mapStateToProps, {
        toggleModal: toggleWorkOrderCloseModal,
        onHide: toggleWorkOrderCloseModal,
        passSelected
    })(WithCommonModal(WorkOrderCloseForm))
);
