/*
 * Edit Job Defaults Form
 */

import * as React from 'react';

import { Button, Col } from 'react-bootstrap';
import { FieldConfig, FormArray, FormGenerator, FormGroup, GroupProps } from 'react-reactive-form';
import { IjobDefault } from '../../../models';
import { debounce, values } from 'lodash';

import { FormUtil } from '../../common/FormUtil';
import { IeditJobDefaultsFormValues } from '../../../modelsForms';
import { TFunction } from 'i18next';
import { constants } from '../../../constants/constants';
import { initialJobDefaults } from '../../../reducers/initialState';
import { jobDefaultTypeEnum, measurementPointTypesEnum } from '../../../models-enums';
import { FormUtilMeasurementPoints } from '../../common/FormUtilMobile';
import { saveJobDefaults } from '../../../actions/measurementPointListActions';

export enum formFieldsEnum {
    oxygenFlow = 'oxygenFlow',
    oxygenPressure = 'oxygenPressure',
    medicalAirFlow = 'medicalAirFlow',
    medicalAirPressure = 'medicalAirPressure',
    carbonDioxideFlow = 'carbonDioxideFlow',
    carbonDioxidePressure = 'carbonDioxidePressure',
    nitrogenFlow = 'nitrogenFlow',
    nitrogenPressure = 'nitrogenPressure',
    nitrousOxideFlow = 'nitrousOxideFlow',
    nitrousOxidePressure = 'nitrousOxidePressure',
    vacuumFlow = 'vacuumFlow',
    vacuumPressure = 'vacuumPressure',
    agssWagdFlow = 'agssWagdFlow',
    agssWagdPressure = 'agssWagdPressure'
}

const OXYGEN_PRODUCT_TYPE_ID = 'DBAF7B99-85DF-4A65-9985-C725E86A9277';
const MEDICAL_AIR_PRODUCT_TYPE_ID = '93B4BBEA-B9B3-4A81-B971-FBD1D8D60BD1';
const CARBON_DIOXIDE_PRODUCT_TYPE_ID = 'ADD13DA4-384B-4881-94E3-69823199C85D';
const NITROGEN_PRODUCT_TYPE_ID = 'ABDE9640-CED4-4530-8150-C72B60BB3891';
const NITROUS_OXIDE_PRODUCT_TYPE_ID = '0C543D77-8F5A-4368-B8A9-BB46699CCAAB';
const VACUUM_PRODUCT_TYPE_ID = '75a1776c-ba5f-4805-a3ec-7cd2946f8879';
const AGSS_WAGD_PRODUCT_TYPE_ID = '7C0B3558-80CC-4ECE-8E73-C3E7FD1175D5';

// Function accepts jobDefault[], returns

const uuidv4 = require('uuid/v4');

const buildFormDefaultFields = (jobID: string): { [key in formFieldsEnum]: IjobDefault } => {
    // map job default to key it
    // based on product ID and enum
    // very fragile. This is all fragile. Also probably won't be used.

    return {
        oxygenFlow: {
            mpType: measurementPointTypesEnum.numeric,
            mpDefaultType: jobDefaultTypeEnum.dynamicFlowRate,
            productTypeID: OXYGEN_PRODUCT_TYPE_ID,
            defaultMpAnswer: { showInReport: true },
            jobID,
            id: uuidv4()
        },
        oxygenPressure: {
            mpType: measurementPointTypesEnum.numeric,
            mpDefaultType: jobDefaultTypeEnum.staticPressureValue,
            productTypeID: OXYGEN_PRODUCT_TYPE_ID,
            defaultMpAnswer: { showInReport: true },
            jobID,
            id: uuidv4()
        },
        medicalAirFlow: {
            mpType: measurementPointTypesEnum.numeric,
            mpDefaultType: jobDefaultTypeEnum.dynamicFlowRate,
            productTypeID: MEDICAL_AIR_PRODUCT_TYPE_ID,
            defaultMpAnswer: { showInReport: true },
            jobID,
            id: uuidv4()
        },
        medicalAirPressure: {
            mpType: measurementPointTypesEnum.numeric,
            mpDefaultType: jobDefaultTypeEnum.staticPressureValue,
            productTypeID: MEDICAL_AIR_PRODUCT_TYPE_ID,
            defaultMpAnswer: { showInReport: true },
            jobID,
            id: uuidv4()
        },
        carbonDioxideFlow: {
            mpType: measurementPointTypesEnum.numeric,
            mpDefaultType: jobDefaultTypeEnum.dynamicFlowRate,
            productTypeID: CARBON_DIOXIDE_PRODUCT_TYPE_ID,
            defaultMpAnswer: { showInReport: true },
            jobID,
            id: uuidv4()
        },
        carbonDioxidePressure: {
            mpType: measurementPointTypesEnum.numeric,
            mpDefaultType: jobDefaultTypeEnum.staticPressureValue,
            productTypeID: CARBON_DIOXIDE_PRODUCT_TYPE_ID,
            defaultMpAnswer: { showInReport: true },
            jobID,
            id: uuidv4()
        },
        nitrogenFlow: {
            mpType: measurementPointTypesEnum.numeric,
            mpDefaultType: jobDefaultTypeEnum.dynamicFlowRate,
            productTypeID: NITROGEN_PRODUCT_TYPE_ID,
            defaultMpAnswer: { showInReport: true },
            jobID,
            id: uuidv4()
        },
        nitrogenPressure: {
            mpType: measurementPointTypesEnum.numeric,
            mpDefaultType: jobDefaultTypeEnum.staticPressureValue,
            productTypeID: NITROGEN_PRODUCT_TYPE_ID,
            defaultMpAnswer: { showInReport: true },
            jobID,
            id: uuidv4()
        },
        nitrousOxideFlow: {
            mpType: measurementPointTypesEnum.numeric,
            mpDefaultType: jobDefaultTypeEnum.dynamicFlowRate,
            productTypeID: NITROUS_OXIDE_PRODUCT_TYPE_ID,
            defaultMpAnswer: { showInReport: true },
            jobID,
            id: uuidv4()
        },
        nitrousOxidePressure: {
            mpType: measurementPointTypesEnum.numeric,
            mpDefaultType: jobDefaultTypeEnum.staticPressureValue,
            productTypeID: NITROUS_OXIDE_PRODUCT_TYPE_ID,
            defaultMpAnswer: { showInReport: true },
            jobID,
            id: uuidv4()
        },
        vacuumFlow: {
            mpType: measurementPointTypesEnum.numeric,
            mpDefaultType: jobDefaultTypeEnum.dynamicFlowRate,
            productTypeID: VACUUM_PRODUCT_TYPE_ID,
            defaultMpAnswer: { showInReport: true },
            jobID,
            id: uuidv4()
        },
        vacuumPressure: {
            mpType: measurementPointTypesEnum.numeric,
            mpDefaultType: jobDefaultTypeEnum.staticPressureValue,
            productTypeID: VACUUM_PRODUCT_TYPE_ID,
            defaultMpAnswer: { showInReport: true },
            jobID,
            id: uuidv4()
        },
        agssWagdFlow: {
            mpType: measurementPointTypesEnum.numeric,
            mpDefaultType: jobDefaultTypeEnum.dynamicFlowRate,
            productTypeID: AGSS_WAGD_PRODUCT_TYPE_ID,
            defaultMpAnswer: { showInReport: true },
            jobID,
            id: uuidv4()
        },
        agssWagdPressure: {
            mpType: measurementPointTypesEnum.numeric,
            mpDefaultType: jobDefaultTypeEnum.staticPressureValue,
            productTypeID: AGSS_WAGD_PRODUCT_TYPE_ID,
            defaultMpAnswer: { showInReport: true },
            jobID,
            id: uuidv4()
        }
    };
};

interface Iprops {
    loading: boolean;
    colorButton: string;
    t: TFunction;
    jobDefaultsForJob: IjobDefault[];
    selectedJobID: string;
    saveJobDefaults: typeof saveJobDefaults;
    toggleModal: () => void;
    updateFormValue: (formValue: { [key: string]: any }) => void;
    setFormValues: (formValues: { [key: string]: any }) => void;
    formValues: Partial<IeditJobDefaultsFormValues>;
}

interface Istate {
    fieldConfig: FieldConfig;
}

class EditJobDefaultsForm extends React.Component<Iprops, Istate> {
    private formGroup: FormGroup | any;
    private subscription: any;
    private updateFormValueDebounced: any;
    static defaultProps = {
        selectedItem: initialJobDefaults
    };
    constructor(props: Iprops) {
        super(props);
        this.updateFormValueDebounced = debounce(this.props.updateFormValue, constants.formDebounceTime);
        this.state = {
            fieldConfig: this.buildFieldConfig()
        };
    }
    componentDidMount() {
        this.props.setFormValues(this.itemToFormValues());
        this.setState({
            fieldConfig: this.buildFieldConfig(this.itemToFormValues())
        });
    }

    componentWillUnmount() {
        this.props.setFormValues({});
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    /*
     * itemToFormValues - take the selectedItem and convert it to IeditJobDefaultsFormValues
     */
    itemToFormValues = (): IeditJobDefaultsFormValues => {
        const oxygenFlowJobDefault = this.props.jobDefaultsForJob.find(
            item =>
                item.productTypeID === OXYGEN_PRODUCT_TYPE_ID &&
                item.mpDefaultType === jobDefaultTypeEnum.dynamicFlowRate
        );
        const oxygenPressureJobDefault = this.props.jobDefaultsForJob.find(
            item =>
                item.productTypeID === OXYGEN_PRODUCT_TYPE_ID &&
                item.mpDefaultType === jobDefaultTypeEnum.staticPressureValue
        );
        const medicalAirFlowJobDefault = this.props.jobDefaultsForJob.find(
            item =>
                item.productTypeID === MEDICAL_AIR_PRODUCT_TYPE_ID &&
                item.mpDefaultType === jobDefaultTypeEnum.dynamicFlowRate
        );
        const medicalAirPressureJobDefault = this.props.jobDefaultsForJob.find(
            item =>
                item.productTypeID === MEDICAL_AIR_PRODUCT_TYPE_ID &&
                item.mpDefaultType === jobDefaultTypeEnum.staticPressureValue
        );
        const carbonDioxideFlowJobDefault = this.props.jobDefaultsForJob.find(
            item =>
                item.productTypeID === CARBON_DIOXIDE_PRODUCT_TYPE_ID &&
                item.mpDefaultType === jobDefaultTypeEnum.dynamicFlowRate
        );
        const carbonDioxidePressureJobDefault = this.props.jobDefaultsForJob.find(
            item =>
                item.productTypeID === CARBON_DIOXIDE_PRODUCT_TYPE_ID &&
                item.mpDefaultType === jobDefaultTypeEnum.staticPressureValue
        );
        const nitrogenFlowJobDefault = this.props.jobDefaultsForJob.find(
            item =>
                item.productTypeID === NITROGEN_PRODUCT_TYPE_ID &&
                item.mpDefaultType === jobDefaultTypeEnum.dynamicFlowRate
        );
        const nitrogenPressureJobDefault = this.props.jobDefaultsForJob.find(
            item =>
                item.productTypeID === NITROGEN_PRODUCT_TYPE_ID &&
                item.mpDefaultType === jobDefaultTypeEnum.staticPressureValue
        );
        const nitrousOxideFlowJobDefault = this.props.jobDefaultsForJob.find(
            item =>
                item.productTypeID === NITROUS_OXIDE_PRODUCT_TYPE_ID &&
                item.mpDefaultType === jobDefaultTypeEnum.dynamicFlowRate
        );
        const nitrousOxidePressureJobDefault = this.props.jobDefaultsForJob.find(
            item =>
                item.productTypeID === NITROUS_OXIDE_PRODUCT_TYPE_ID &&
                item.mpDefaultType === jobDefaultTypeEnum.staticPressureValue
        );
        const vacuumFlowJobDefault = this.props.jobDefaultsForJob.find(
            item =>
                item.productTypeID === VACUUM_PRODUCT_TYPE_ID &&
                item.mpDefaultType === jobDefaultTypeEnum.dynamicFlowRate
        );
        const vacuumPressureJobDefault = this.props.jobDefaultsForJob.find(
            item =>
                item.productTypeID === VACUUM_PRODUCT_TYPE_ID &&
                item.mpDefaultType === jobDefaultTypeEnum.staticPressureValue
        );
        const agssWagdFlowJobDefault = this.props.jobDefaultsForJob.find(
            item =>
                item.productTypeID === AGSS_WAGD_PRODUCT_TYPE_ID &&
                item.mpDefaultType === jobDefaultTypeEnum.dynamicFlowRate
        );
        const agssWagdPressureJobDefault = this.props.jobDefaultsForJob.find(
            item =>
                item.productTypeID === AGSS_WAGD_PRODUCT_TYPE_ID &&
                item.mpDefaultType === jobDefaultTypeEnum.staticPressureValue
        );
        return {
            oxygenFlow: oxygenFlowJobDefault?.defaultMpAnswer.numericValue,
            oxygenPressure: oxygenPressureJobDefault?.defaultMpAnswer.numericValue,
            medicalAirFlow: medicalAirFlowJobDefault?.defaultMpAnswer.numericValue,
            medicalAirPressure: medicalAirPressureJobDefault?.defaultMpAnswer.numericValue,
            carbonDioxideFlow: carbonDioxideFlowJobDefault?.defaultMpAnswer.numericValue,
            carbonDioxidePressure: carbonDioxidePressureJobDefault?.defaultMpAnswer.numericValue,
            nitrogenFlow: nitrogenFlowJobDefault?.defaultMpAnswer.numericValue,
            nitrogenPressure: nitrogenPressureJobDefault?.defaultMpAnswer.numericValue,
            nitrousOxideFlow: nitrousOxideFlowJobDefault?.defaultMpAnswer.numericValue,
            nitrousOxidePressure: nitrousOxidePressureJobDefault?.defaultMpAnswer.numericValue,
            vacuumFlow: vacuumFlowJobDefault?.defaultMpAnswer.numericValue,
            vacuumPressure: vacuumPressureJobDefault?.defaultMpAnswer.numericValue,
            agssWagdFlow: agssWagdFlowJobDefault?.defaultMpAnswer.numericValue,
            agssWagdPressure: agssWagdPressureJobDefault?.defaultMpAnswer.numericValue
        };
    };

    /*
     * formValuesToItem - convert the formValues to the shape of the selectedItem
     */
    formValuesToItem = (): { [key: string]: IjobDefault } => {
        const formValues = this.formGroup.value;

        const defaultFormFields = buildFormDefaultFields(this.props.selectedJobID);

        const reducedValues = Object.keys(formValues).reduce((collection, key) => {
            const jobDefaultFormValue = formValues[key] as number;
            const defaultFormField = defaultFormFields[key as keyof typeof formFieldsEnum];
            if (jobDefaultFormValue !== undefined) {
                const originalJobDefault = this.props.jobDefaultsForJob.find(
                    item =>
                        item.productTypeID === defaultFormField.productTypeID &&
                        item.mpDefaultType === defaultFormField.mpDefaultType
                );
                const newJobDefault: IjobDefault = {
                    ...defaultFormField, // ensure we have the correct shape
                    ...originalJobDefault, // spread the original if there is one
                    defaultMpAnswer: {
                        showInReport: true,
                        numericValue: jobDefaultFormValue // update the answer from the form value
                    }
                };
                collection = { ...collection, [key]: newJobDefault };
            }
            return collection;
        }, {});
        return reducedValues;
    };

    buildFieldConfig = (defaultValues: Partial<IeditJobDefaultsFormValues> = this.props.formValues) => {
        const disabled = false;
        let {
            oxygenFlow,
            oxygenPressure,
            carbonDioxideFlow,
            carbonDioxidePressure,
            medicalAirFlow,
            medicalAirPressure,
            nitrogenFlow,
            nitrogenPressure,
            nitrousOxideFlow,
            nitrousOxidePressure,
            vacuumFlow,
            vacuumPressure,
            agssWagdFlow,
            agssWagdPressure
        } = defaultValues;
        const isMobile = false;

        // Field config to configure form
        const fieldConfigControls = {
            oxygenFlow: {
                render: FormUtilMeasurementPoints.numeric(isMobile),
                meta: {
                    label: 'manageInventory:jobDefaultsForm.oxygenFlow',
                    type: 'input',
                    columnWidths: {
                        mainCol: 6,
                        measurementPointCol: 8,
                        guideCol: 4
                    },
                    name: 'oxygenFlow',
                    guideText: 'l/min',
                    isRequired: false
                },
                formState: {
                    value: oxygenFlow,
                    disabled
                }
            },
            oxygenPressure: {
                render: FormUtilMeasurementPoints.numeric(isMobile),
                meta: {
                    label: 'manageInventory:jobDefaultsForm.oxygenPressure',
                    type: 'input',
                    columnWidths: {
                        mainCol: 6,
                        measurementPointCol: 8,
                        guideCol: 4
                    },
                    guideText: 'psig/InHg',
                    name: 'oxygenPressure',
                    isRequired: false
                },
                formState: {
                    value: oxygenPressure,
                    disabled
                }
            },
            carbonDioxideFlow: {
                render: FormUtilMeasurementPoints.numeric(isMobile),
                meta: {
                    label: 'manageInventory:jobDefaultsForm.carbonDioxideFlow',
                    type: 'input',
                    columnWidths: {
                        mainCol: 6,
                        measurementPointCol: 8,
                        guideCol: 4
                    },
                    guideText: 'l/min',
                    name: 'carbonDioxideFlow',
                    isRequired: false
                },
                formState: {
                    value: carbonDioxideFlow,
                    disabled
                }
            },
            carbonDioxidePressure: {
                render: FormUtilMeasurementPoints.numeric(isMobile),
                meta: {
                    label: 'manageInventory:jobDefaultsForm.carbonDioxidePressure',
                    type: 'input',
                    columnWidths: {
                        mainCol: 6,
                        measurementPointCol: 8,
                        guideCol: 4
                    },
                    guideText: 'psig/InHg',
                    name: 'carbonDioxidePressure',
                    isRequired: false
                },
                formState: {
                    value: carbonDioxidePressure,
                    disabled
                }
            },
            medicalAirFlow: {
                render: FormUtilMeasurementPoints.numeric(isMobile),
                meta: {
                    label: 'manageInventory:jobDefaultsForm.medicalAirFlow',
                    type: 'input',
                    columnWidths: {
                        mainCol: 6,
                        measurementPointCol: 8,
                        guideCol: 4
                    },
                    guideText: 'l/min',
                    name: 'medicalAirFlow',
                    isRequired: false
                },
                formState: {
                    value: medicalAirFlow,
                    disabled
                }
            },
            medicalAirPressure: {
                render: FormUtilMeasurementPoints.numeric(isMobile),
                meta: {
                    label: 'manageInventory:jobDefaultsForm.medicalAirPressure',
                    type: 'input',
                    columnWidths: {
                        mainCol: 6,
                        measurementPointCol: 8,
                        guideCol: 4
                    },
                    guideText: 'psig/InHg',
                    name: 'medicalAirPressure',
                    isRequired: false
                },
                formState: {
                    value: medicalAirPressure,
                    disabled
                }
            },
            nitrogenFlow: {
                render: FormUtilMeasurementPoints.numeric(isMobile),
                meta: {
                    label: 'manageInventory:jobDefaultsForm.nitrogenFlow',
                    type: 'input',
                    columnWidths: {
                        mainCol: 6,
                        measurementPointCol: 8,
                        guideCol: 4
                    },
                    guideText: 'l/min',
                    name: 'nitrogenFlow',
                    isRequired: false
                },
                formState: {
                    value: nitrogenFlow,
                    disabled
                }
            },
            nitrogenPressure: {
                render: FormUtilMeasurementPoints.numeric(isMobile),

                meta: {
                    label: 'manageInventory:jobDefaultsForm.nitrogenPressure',
                    type: 'input',
                    columnWidths: {
                        mainCol: 6,
                        measurementPointCol: 8,
                        guideCol: 4
                    },
                    guideText: 'psig/InHg',
                    name: 'nitrogenPressure',
                    isRequired: false
                },
                formState: {
                    value: nitrogenPressure,
                    disabled
                }
            },
            nitrousOxideFlow: {
                render: FormUtilMeasurementPoints.numeric(isMobile),

                meta: {
                    label: 'manageInventory:jobDefaultsForm.nitrousOxideFlow',
                    type: 'input',
                    columnWidths: {
                        mainCol: 6,
                        measurementPointCol: 8,
                        guideCol: 4
                    },
                    guideText: 'l/min',
                    name: 'nitrousOxideFlow',
                    isRequired: false
                },
                formState: {
                    value: nitrousOxideFlow,
                    disabled
                }
            },
            nitrousOxidePressure: {
                render: FormUtilMeasurementPoints.numeric(isMobile),

                meta: {
                    label: 'manageInventory:jobDefaultsForm.nitrousOxidePressure',
                    type: 'input',
                    columnWidths: {
                        mainCol: 6,
                        measurementPointCol: 8,
                        guideCol: 4
                    },
                    guideText: 'psig/InHg',
                    name: 'nitrousOxidePressure',
                    isRequired: false
                },
                formState: {
                    value: nitrousOxidePressure,
                    disabled
                }
            },
            vacuumFlow: {
                render: FormUtilMeasurementPoints.numeric(isMobile),

                meta: {
                    label: 'manageInventory:jobDefaultsForm.vacuumFlow',
                    type: 'input',
                    columnWidths: {
                        mainCol: 6,
                        measurementPointCol: 8,
                        guideCol: 4
                    },
                    guideText: 'l/min',
                    name: 'vacuumFlow',
                    isRequired: false
                },
                formState: {
                    value: vacuumFlow,
                    disabled
                }
            },
            vacuumPressure: {
                render: FormUtilMeasurementPoints.numeric(isMobile),

                meta: {
                    label: 'manageInventory:jobDefaultsForm.vacuumPressure',
                    type: 'input',
                    columnWidths: {
                        mainCol: 6,
                        measurementPointCol: 8,
                        guideCol: 4
                    },
                    guideText: 'psig/InHg',
                    name: 'vacuumPressure',
                    isRequired: false
                },
                formState: {
                    value: vacuumPressure,
                    disabled
                }
            },
            agssWagdFlow: {
                render: FormUtilMeasurementPoints.numeric(isMobile),

                meta: {
                    label: 'manageInventory:jobDefaultsForm.agssWagdFlow',
                    type: 'input',
                    columnWidths: {
                        mainCol: 6,
                        measurementPointCol: 8,
                        guideCol: 4
                    },
                    guideText: 'l/min',
                    name: 'agssWagdFlow',
                    isRequired: false
                },
                formState: {
                    value: agssWagdFlow,
                    disabled
                }
            },
            agssWagdPressure: {
                render: FormUtilMeasurementPoints.numeric(isMobile),

                meta: {
                    label: 'manageInventory:jobDefaultsForm.agssWagdPressure',
                    type: 'input',
                    columnWidths: {
                        mainCol: 6,
                        measurementPointCol: 8,
                        guideCol: 4
                    },
                    guideText: 'psig/InHg',
                    name: 'agssWagdPressure',
                    isRequired: false
                },
                formState: {
                    value: agssWagdPressure,
                    disabled
                }
            }
        } as { [key in formFieldsEnum]: GroupProps };
        const fieldConfig = {
            controls: { ...fieldConfigControls }
        };
        return FormUtil.translateForm(fieldConfig, this.props.t);
    };

    /*
     * (reusable)
     * subscribe to the formGroup changes
     */
    subscribeToChanges = () => {
        for (const key in this.formGroup.controls) {
            if (this.formGroup.controls.hasOwnProperty(key)) {
                this.subscription = this.formGroup.get(key).valueChanges.subscribe((value: any) => {
                    this.onValueChanges(value, key);
                });
            }
        }
    };

    /*
     * (reusable)
     * set the table filters to redux on each value change
     */
    onValueChanges = (value: any, key: string) => {
        switch (key) {
            default:
                this.updateFormValueDebounced({ [key]: value });
                break;
        }
    };

    handleSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
        e.preventDefault();

        this.props.saveJobDefaults(values(this.formValuesToItem()));

        this.props.toggleModal();
    };

    setForm = (form: FormGroup | FormArray) => {
        this.formGroup = form;
        this.formGroup.meta = {
            loading: this.props.loading
        };
        if (!this.subscription) {
            setTimeout(() => {
                this.subscribeToChanges();
            }, 300);
        }
    };

    render() {
        const { t } = this.props;

        const formClassName = `clearfix job-form beacon-form ${this.props.colorButton}`;

        return (
            <form onSubmit={this.handleSubmit} className={formClassName}>
                <div>
                    <FormGenerator onMount={this.setForm} fieldConfig={this.state.fieldConfig} />
                </div>
                <Col xs={12} className="form-buttons text-right">
                    <Button
                        bsStyle="default"
                        type="button"
                        className="pull-left"
                        onClick={() => {
                            this.props.toggleModal();
                            // this.props.setFormvalues({});
                        }}
                    >
                        {t('cancel')}
                    </Button>
                    <Button bsStyle={this.props.colorButton} type="submit" disabled={this.props.loading}>
                        {t('save')}
                    </Button>
                </Col>
            </form>
        );
    }
}

export default EditJobDefaultsForm;
