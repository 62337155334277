import { connect } from 'react-redux';
import { IinitialState } from '../../reducers';
import TakePhotoControl from './TakePhotoControl';
import { withTranslation, WithTranslation } from 'react-i18next';
import { constants } from '../../constants/constants';
import { selectIsLoading } from '../../reducers/commonReducers';
import { addNewPhoto } from '../../actions/photosActions';
import { getIsMobile } from '../../reducers/configReducer';

interface Iprops {
    colorButton?: string;
    installID: string;
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops & WithTranslation) => {
    return {
        loading: selectIsLoading(state),
        installBaseID: ownProps.installID,
        currentTile: constants.getTileByURL(window.location.pathname),
        isMobile: getIsMobile(state)
    };
};

export const TakePhotoContainer = withTranslation('manageWorkOrder')(
    connect(mapStateToProps, {
        addNewPhoto
    })(TakePhotoControl)
);
