import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { TFunction } from 'i18next';
import React, { useEffect, useState } from 'react';
import API from '../../constants/apiEndpoints';
import { msalFetch } from '../auth/Auth-Utils';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { IinitialState } from '../../reducers';
import { Iphoto } from '../../models';
import { Grid, Row } from 'react-bootstrap';
import moment from 'moment';
import { beginAjaxCall, endAjaxCall } from '../../actions/ajaxStatusActions';
import { constants } from '../../constants/constants';

interface Iprops {
    installBaseID: string;
    t: TFunction;
    beginAjaxCall: typeof beginAjaxCall;
    endAjaxCall: typeof endAjaxCall;
}

function HistoricalImages(props: Iprops) {
    const [photos, setPhotos] = useState<Iphoto[]>([]);

    useEffect(() => {
        const axiosOptions: AxiosRequestConfig = {
            method: 'get',
            params: { installBaseID: props.installBaseID }
        };
        const url = `${API.photo.getByInstallBaseID}`;
        props.beginAjaxCall();
        msalFetch(url, axiosOptions)
            .then((resp: AxiosResponse<{ result: Iphoto[]; count: number; page: number }>) => {
                props.endAjaxCall();
                const sortedPhotos = resp.data.result.sort((a, b) =>
                    moment(a.createDate).isSameOrAfter(b.createDate) ? -1 : 1
                );
                setPhotos(sortedPhotos);
            })
            .catch(e => {
                props.endAjaxCall();
                constants.handleError(e, 'Error loading historical images', 'error');
            });
    }, [props.installBaseID]);

    return (
        <Grid style={{ width: 'inherit' }}>
            {photos.map(p => {
                return (
                    <Row style={{ padding: '12px' }} key={p.id}>
                        <div style={{ fontWeight: 600 }}>
                            {p.createDate
                                ? moment
                                      .utc(p.createDate)
                                      .local(true)
                                      .format('DD-MMM-YY LT')
                                : ''}
                        </div>
                        <img src={p.thumbnailUrl} />
                        <div>{p.comments ? p.comments : ''}</div>
                    </Row>
                );
            })}
        </Grid>
    );
}

const mapStateToProps = (state: IinitialState) => ({
    installBaseID: state.measurementPointResults.selectedResult.installBaseID
});

export default withTranslation('manageWorkOrder')(
    connect(mapStateToProps, { endAjaxCall, beginAjaxCall })(HistoricalImages)
);
