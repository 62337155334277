import React from 'react';
import SignatureCanvas from 'react-signature-canvas';
import * as localForage from 'localforage';
import { Col, Button, FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import { EditorState } from 'draft-js';
import { TFunction } from 'i18next';
import { isEmpty, keyBy } from 'lodash';
import { toastr } from 'react-redux-toastr';

import RichTextEditor from '../../components/common/RichTextEditor';
import { IsignatureFormData, IinstallBasePopulated, Ijob, IjobSignature, Iuser } from '../../models';
import { constants } from '../../constants/constants';
import { jobTypesIdEnum, SignatureTypeEnum } from '../../models-enums';
import { toggleModalSignaturePad as ItoggleModalSignaturePad } from '../../actions/manageInventoryActions';
import { submitJobSignature, toggleIsCollectingSignatures } from '../../actions/workOrderActions';
import { completeJobWithFinalCheck, updateJob } from '../../actions/manageJobActions';

interface Iprops {
    t: TFunction;
    toggleModal: () => void;
    toggleIsJobClosingWithSignature: () => void;
    submitSignature: typeof submitJobSignature;
    completeJobWithFinalCheck: typeof completeJobWithFinalCheck;
    toggleModalSignaturePad: typeof ItoggleModalSignaturePad;
    toggleIsCollectingSignatures: typeof toggleIsCollectingSignatures;
    updateJob: typeof updateJob;
    loading: boolean;
    installBasesPopulated: IinstallBasePopulated[];
    selectedJob: Ijob;
    selectedJobSignatures: IjobSignature[];
    selectedJobCoverLetter?: string;
    validateParts: boolean;
    isCollectingSignatures: boolean;
    isJobClosingWithSignature: boolean;
    user: Iuser;
}

interface Istate {
    customerName: string;
    editorState: string | EditorState;
    signatures: { [key: string]: IjobSignature };
}

const canvasDimensions = {
    width: 468,
    height: 200
};

const prevSignatureWrap = {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center'
};

class SignaturePad extends React.Component<Iprops, Istate> {
    private formData: IsignatureFormData = new FormData();
    private customerSignaturePad: any = {};
    private serviceEngineerSignaturePad: any = {};

    constructor(props: any) {
        super(props);
        this.state = {
            customerName: '',
            editorState: this.props.selectedJobCoverLetter ?? constants.coverLetter,
            signatures: {}
        };
        this.customerSignaturePad = React.createRef();
        this.serviceEngineerSignaturePad = React.createRef();
    }

    componentDidMount() {
        this.getSignatures(this.props.selectedJobSignatures).then(signatures => {
            this.setState({ signatures });

            if (signatures.Customer) {
                this.setState({ customerName: signatures.Customer.signedBy ?? '' });
            }
        });
    }

    onChange = (editorState: EditorState) => {
        this.setState({
            editorState
        });
    };

    handleInputChange(event: any) {
        const { value } = event.currentTarget;

        this.setState({ customerName: value });
    }

    areSignaturesAvailable = (): { [key: string]: boolean } => {
        const signatures = this.state.signatures as { [key: string]: IjobSignature };
        const availableSignatures = [signatures.Customer, signatures.FSE];
        const all = availableSignatures.every(sig => Boolean(sig?.uri));
        const some = availableSignatures.some(sig => Boolean(sig?.uri));

        return {
            all,
            some
        };
    };

    areSummissionsValid(): { [key: string]: boolean } {
        const summissions = [
            (this.customerSignaturePad as SignatureCanvas)?.isEmpty(),
            (this.serviceEngineerSignaturePad as SignatureCanvas)?.isEmpty()
        ];
        const all = summissions.every((sub: boolean) => sub === false);
        const some = summissions.some((sub: boolean) => sub === false);
        const existingSignatures = !isEmpty(this.state.signatures);
        const nameOnly = Boolean(this.state.customerName) && !existingSignatures && !some;

        return {
            all,
            some,
            existingSignatures,
            nameOnly
        };
    }

    isValidSubmission(): { msg: string; valid: boolean } {
        if (
            this.props.selectedJob.jobTypeID === jobTypesIdEnum.verification &&
            this.state.editorState === constants.coverLetter &&
            !this.props.selectedJobCoverLetter
        ) {
            return { msg: 'Make sure to fill out the cover letter', valid: false };
        }

        if (this.state.customerName === '') {
            return { msg: 'Customer name is required', valid: false };
        }

        const isValid = this.areSignaturesAvailable().some || this.areSummissionsValid().all;

        return { msg: 'Missing Form Data', valid: isValid };
    }

    submit = () => {
        const validationCheck = this.isValidSubmission();

        if (!validationCheck.valid) {
            toastr.error(validationCheck.msg, constants.toastrError);

            return;
        }

        const customerCanvas = this.customerSignaturePad as SignatureCanvas;
        const engineerCanvas = this.serviceEngineerSignaturePad as SignatureCanvas;
        const hasSignature: { [key: string]: boolean } = { customer: false, engineer: false };
        const signatureUri: { [key: string]: string | undefined } = { customer: undefined, engineer: undefined };

        // check if the current signature we are looking at was previously created and is in state
        if (this.state.signatures !== undefined && Object.keys(this.state.signatures).length > 0) {
            if (this.state.signatures.Customer !== undefined) {
                signatureUri.customer = this.state.signatures.Customer.uri;
                hasSignature.customer = true;
            }

            if (this.state.signatures.FSE !== undefined) {
                signatureUri.engineer = this.state.signatures.FSE.uri;
                hasSignature.engineer = true;
            }
        }

        if (this.props.selectedJob.jobTypeID === jobTypesIdEnum.verification) {
            this.props.updateJob({
                ...this.props.selectedJob,
                coverLetter: this.state.editorState as string
            });
        }

        if (
            !this.props.isCollectingSignatures &&
            this.props.isJobClosingWithSignature &&
            this.areSignaturesAvailable().all &&
            !this.areSummissionsValid().all
        ) {
            this.cancel();
            this.props.completeJobWithFinalCheck();
        } else if (this.areSummissionsValid().some) {
            customerCanvas?.getTrimmedCanvas().toBlob(blob => {
                if (blob && !customerCanvas?.isEmpty()) {
                    this.props.submitSignature(
                        this.props.t,
                        this.props.selectedJob.id,
                        blob,
                        customerCanvas?.toDataURL(),
                        SignatureTypeEnum.Customer,
                        this.state.customerName,
                        false
                    );
                }
            });

            engineerCanvas?.getTrimmedCanvas().toBlob(blob => {
                if (blob && !engineerCanvas?.isEmpty()) {
                    this.props.submitSignature(
                        this.props.t,
                        this.props.selectedJob.id,
                        blob,
                        engineerCanvas?.toDataURL(),
                        SignatureTypeEnum.FSE,
                        `${this.props.user.first} ${this.props.user.last}`,
                        true,
                        this.props.installBasesPopulated
                    );
                }
            });
        } else {
            this.cancel();
        }
    };

    clear = () => {
        this.setState({
            customerName: '',
            editorState: this.props.selectedJobCoverLetter ?? constants.coverLetter,
            signatures: {}
        });
        this.customerSignaturePad?.clear();
        this.serviceEngineerSignaturePad?.clear();
    };

    cancel = () => {
        if (this.props.isJobClosingWithSignature) {
            this.props.toggleIsJobClosingWithSignature();
        }
        if (this.props.isCollectingSignatures) {
            this.props.toggleIsCollectingSignatures();
        }

        this.props.toggleModal();
    };

    getSignatures = async (selectedJobSignatures: IjobSignature[]) => {
        const withURI = await selectedJobSignatures.map(async signature => {
            const uri = await localForage.getItem<string>(signature.id);

            return { ...signature, uri: uri || undefined } as IjobSignature;
        });
        const signatuesWithImgs = await Promise.all(withURI);
        const byType: { [key: string]: IjobSignature } = keyBy(
            signatuesWithImgs,
            (signature: IjobSignature) => SignatureTypeEnum[signature.type]
        );

        return byType;
    };

    render() {
        const { loading, selectedJob, t } = this.props;
        const isVerificationJob = selectedJob.jobTypeID === jobTypesIdEnum.verification;
        const signatures = this.state.signatures as { [key: string]: IjobSignature };

        return (
            <div className="beacon-form">
                {isVerificationJob && (
                    <Col xs={12}>
                        <FormGroup bsSize="sm">
                            <ControlLabel>{t('coverLetter')}</ControlLabel>
                            <RichTextEditor
                                onChange={this.onChange}
                                initialContent={constants.coverLetter}
                                readOnly={false}
                                charLimit={1000}
                            />
                            <FormControl.Feedback />
                        </FormGroup>
                    </Col>
                )}
                <Col xs={12}>
                    <FormGroup>
                        <ControlLabel>{t('authorizedCustomerName')}</ControlLabel>
                        <FormControl
                            onChange={event => {
                                this.handleInputChange(event);
                            }}
                            type="text"
                            value={this.state.customerName}
                        ></FormControl>
                    </FormGroup>
                </Col>

                <Col xs={12}>
                    <FormGroup>
                        <ControlLabel>{t('customerConfirmationSignature')}</ControlLabel>
                        <div className="signaturePad">
                            {signatures.Customer?.uri ? (
                                <div
                                    style={{
                                        ...canvasDimensions,
                                        ...prevSignatureWrap
                                    }}
                                >
                                    <img src={signatures.Customer.uri} alt="" />
                                </div>
                            ) : (
                                <SignatureCanvas
                                    ref={ref => {
                                        this.customerSignaturePad = ref;
                                    }}
                                    penColor="#333333"
                                    canvasProps={canvasDimensions}
                                />
                            )}
                        </div>
                    </FormGroup>
                </Col>
                <Col xs={12}>
                    <FormGroup>
                        <ControlLabel>{t('serviceEngineerConfirmationSignature')}</ControlLabel>
                        <div className="signaturePad">
                            {signatures.FSE?.uri ? (
                                <div
                                    style={{
                                        ...canvasDimensions,
                                        ...prevSignatureWrap
                                    }}
                                >
                                    <img src={signatures.FSE.uri} alt="" />
                                </div>
                            ) : (
                                <SignatureCanvas
                                    ref={ref => {
                                        this.serviceEngineerSignaturePad = ref;
                                    }}
                                    penColor="#333333"
                                    canvasProps={canvasDimensions}
                                />
                            )}
                        </div>
                    </FormGroup>
                </Col>

                <Col xs={12} className="form-buttons text-right">
                    <Button bsStyle="default" type="button" className="pull-left" onClick={this.cancel}>
                        {t('common:cancel')}
                    </Button>
                    <Button
                        bsStyle="default"
                        type="button"
                        className="pull-left"
                        onClick={this.clear}
                        style={{
                            marginLeft: '1rem'
                        }}
                    >
                        {t('clearSignature')}
                    </Button>
                    <Button bsStyle="default" disabled={loading} onClick={this.submit}>
                        {t('common:save')}
                    </Button>
                </Col>
            </div>
        );
    }
}

export default SignaturePad;
