import * as types from '../actions/actionTypes';

import initialState, {
    initialOfflineJobStatus,
    initialOfflineFacilityStatus,
    initialTableFilters,
    initialMeasurmentPointResult
} from './initialState';
import { filter, forEach, map, orderBy } from 'lodash';
import { createSelector } from 'reselect';

import { IinitialState } from '.';
import {
    Ijob,
    ImeasurementPointResultWithType,
    IofflineFacilityStatus,
    IofflineJobStatus,
    IWorkOrder,
    ItableFiltersReducer,
    IinstallBasePopulated,
    Ifacility,
    WorkOrderSource
} from '../models';
import moment from 'moment';
import { selectCompleteWorkOrdersByInstallBaseID } from './workOrderReducer';
import { selectMeasurementPointListResultsByInstallBaseID } from './measurementPointResultsReducer';
import { jobTypesIdEnum } from '../models-enums';
import { TableUtil } from '../components/common/TableUtil';

export function modalToggleWithName(state = false, action: any, modalName: string): boolean {
    switch (action.type) {
        case `TOGGLE_MODAL_${modalName}`:
            return !state;
        case `SHOW_MODAL_${modalName}`:
            return true;
        case `HIDE_MODAL_${modalName}`:
            return false;
        case types.CLOSE_ALL_MODALS:
            return false;
        default:
            return state;
    }
}

export function createShowModalWithNamedType(modalName = '') {
    return (state = false, action: any) => {
        switch (action.type) {
            case `TOGGLE_MODAL_${modalName}`:
                return !state;
            case `SHOW_MODAL_${modalName}`:
                return true;
            case `HIDE_MODAL_${modalName}`:
                return false;
            case types.CLOSE_ALL_MODALS:
                return false;
            default:
                return state;
        }
    };
}

export function hideCommissioningModal() {
    return (state = initialState, action: any) => {
        switch (action.type) {
            case `TOGGLE_MODAL_JOB_CLOSING_WITH_SIGNATURE`:
                state.workOrder.showJobSignatureModal = false;
                return state;
            default:
                return state;
        }
    };
}

export function createTableFiltersWithName(tableName: string) {
    return (state: ItableFiltersReducer = initialTableFilters, action: any): ItableFiltersReducer => {
        switch (action.type) {
            case `SET_TABLE_FILTER_${tableName}`:
                return { ...state, ...action.filters } as ItableFiltersReducer;
            case `CLEAR_TABLE_FILTER_${tableName}`:
                return initialTableFilters;
            case types.USER_LOGOUT_SUCCESS:
                return initialTableFilters;
            default:
                return state;
        }
    };
}

export const initialSyncActiveReducer = (state = false, action: any): boolean => {
    switch (action.type) {
        case types.INITIAL_SYNC_END:
            return false;
        case types.INITIAL_SYNC_START:
            return true;
        case types.USER_LOGOUT_SUCCESS:
            return false;
        default:
            return state;
    }
};

export function createFormValuesWithName(tableName: string) {
    return (state: { [key: string]: any } = {}, action: any): { [key: string]: any } => {
        switch (action.type) {
            case `SET_FORM_VALUES_${tableName}`:
                return { ...action.formValues };
            case `UPDATE_FORM_VALUES_${tableName}`:
                return { ...state, ...action.formValues };
            case types.USER_LOGOUT_SUCCESS:
                return {};
            default:
                return state;
        }
    };
}

export const offlineStatusOverrideReducer = (
    state: boolean = initialState.offlineStatusOverride,
    action: any
): boolean => {
    switch (action.type) {
        case types.OVERRIDE_OFFLINE_STATUS:
            return true;
        case types.OVERRIDE_OFFLINE_STATUS_RESET:
            return false;
        case types.USER_LOGOUT_SUCCESS:
            return initialState.offlineStatusOverride;
        default:
            return state;
    }
};

function offlineFacilityStatusReducer(
    state: IofflineFacilityStatus = initialOfflineFacilityStatus,
    action: any
): IofflineFacilityStatus {
    switch (action.type) {
        case types.GET_FACILITY_SUCCESS: {
            return { ...state, locations: true };
        }
        case types.GET_PHOTOS_SUCCESS: {
            return { ...state, photos: true };
        }
        case types.GET_INVENTORY_SUCCESS: {
            return { ...state, installBases: true };
        }
        case types.LOAD_WORKORDERS_SUCCESS: {
            return { ...state, workOrders: true };
        }
        case types.GET_MEASUREMENT_POINT_FACILITY_RESULTS_SUCCESS: {
            return { ...state, measurementPointResults: true };
        }
        default:
            return state;
    }
}

export function offlineStatusByFacilityIdReducer(
    state: { [key: string]: IofflineFacilityStatus } = initialState.manageJob.offlineStatusByID,
    action: any
): { [key: string]: IofflineFacilityStatus } {
    switch (action.type) {
        case types.GET_MEASUREMENT_POINT_FACILITY_RESULTS_SUCCESS:
        case types.LOAD_WORKORDERS_SUCCESS:
        case types.GET_INVENTORY_SUCCESS:
        case types.GET_PHOTOS_SUCCESS:
        case types.GET_FACILITY_SUCCESS:
            return {
                ...state,
                [action.facilityID]: offlineFacilityStatusReducer(state[action.facilityID], action)
            };

        default:
            return state;
    }
}
function offlineJobStatusReducer(state: IofflineJobStatus = initialOfflineJobStatus, action: any): IofflineJobStatus {
    switch (action.type) {
        case types.GET_SIMPLE_MEASUREMENT_POINT_JOB_RESULTS_SUCCESS: {
            return { ...state, simpleMeasurementPointResults: true };
        }
        default:
            return state;
    }
}

export function offlineStatusByJobIdReducer(
    state: { [key: string]: IofflineJobStatus } = initialState.manageJob.offlineStatusByID,
    action: any
): { [key: string]: IofflineJobStatus } {
    switch (action.type) {
        case types.GET_SIMPLE_MEASUREMENT_POINT_JOB_RESULTS_SUCCESS:
            if (action.jobIDs) {
                let updatedOfflineStatus = {};
                forEach(action.jobIDs, jobID => {
                    updatedOfflineStatus = {
                        ...updatedOfflineStatus,
                        [jobID]: offlineJobStatusReducer(state[jobID], action)
                    };
                });
                return { ...state, ...updatedOfflineStatus };
            } else return state;

        default:
            return state;
    }
}

/*
 * Common selectors
 */

const getOfflineFacilityStatus = (state: IinitialState) => state.offlineStatusByFacilityID;
const getOfflineJobStatus = (state: IinitialState) => state.offlineStatusByJobID;
const getJobProp = (state: IinitialState, props: { job: Ijob }) => props.job;
export const getManageJob = (state: IinitialState) => state.manageJob;
const getSelectedJob = (state: IinitialState) => state.manageJob.selectedJob;

const selectIsOfflineBusy = (state: IinitialState) => state.offline.busy;
const selectAjaxCallsInProgress = (state: IinitialState) => state.ajaxCallsInProgress;
export const selectOutbox = (state: IinitialState) => state.offline.outbox;
const selectIsOnline = (state: IinitialState) => state.offline.online;

export const selectIsLoading = createSelector(
    [selectIsOfflineBusy, selectAjaxCallsInProgress],
    (isOfflineBusy, ajaxCallsInProgress) => {
        return false; //isOfflineBusy || ajaxCallsInProgress > 0;
    }
);

export const selectIsOnlineAndEmptyQueue = createSelector([selectOutbox, selectIsOnline], (outbox, isOnline) => {
    if (outbox.length === 0 && isOnline) {
        return true;
    }

    return false;
});

export const isAPIRequestInQueue = (state: IinitialState, type: string) => {
    return state.offline.outbox.some((item: any) => item.type === type);
};

const getWorkOrder = (state: IinitialState) => state.workOrder;
const getProducts = (state: IinitialState) => state.manageInventory.productsByID;
export const getWorkOrdersById = (state: IinitialState) =>
    createSelector([getWorkOrder, getProducts], (workOrder, products) => {
        const workOrders: { [key: string]: IWorkOrder } = Object.values(workOrder.workOrdersByID).reduce(
            (carry: { [key: string]: IWorkOrder }, wo: IWorkOrder) => {
                // Splice in Product ID and update product name.
                if (wo.productId) {
                    wo.product = products[wo.productId];
                }

                return {
                    ...carry,
                    [wo.id]: wo
                };
            },
            {}
        );

        return workOrders;
    });
export const getUnassignedSAPWorkOrdersById = (state: IinitialState) => state.workOrder.unlinkedSapWorkOrdersByID;
export const getManageInventory = (state: IinitialState) => state.manageInventory;
export const getManageInventorySelection = createSelector(
    getManageInventory,
    manageInventory => manageInventory.selection
);
export const getFacilities = (state: IinitialState) => state.facilities;
export const getFacilitiesById = createSelector(getFacilities, facilities => facilities.facilitiesByID);

export const getFacilityIDsFromSelection = createSelector(
    [getFacilitiesById, getManageInventorySelection, getUnassignedSAPWorkOrdersById],
    (facilities, selections, sapWorkOrders) => {
        const selectedWorkOrders = selections.map(
            (workOrderId: string) => sapWorkOrders[workOrderId.replace('select-', '')]
        );

        const facilityIds = Object.values(selectedWorkOrders)
            .reduce((carry: string[], workOrder) => {
                if (workOrder?.facility === undefined) {
                    return carry;
                }

                carry.push(workOrder.facility.id);
                return carry;
            }, [])
            .filter((item, i, ar) => ar.indexOf(item) === i);

        return facilityIds;
    }
);
export const selectSelectedInstallBaseIDs = (state: IinitialState) => {
    return state.manageInventory.selection.map(item => {
        return item.split('select-')[1];
    });
};

export const getSelectedFacility = createSelector(
    [getFacilitiesById, getSelectedJob],
    (facilities, selectedJob) => facilities[selectedJob.facilityID]
);
export const getInstallBasesById = createSelector(
    getManageInventory,
    manageInventory => manageInventory.installBasesByID
);
const getInstallBasePopulated = (workOrdersById: any, manageJob: any, manageInventory: any, facilitiesByID: any) => {
    const workOrders = filter(workOrdersById, {
        source: WorkOrderSource.SAP
    });

    if (workOrders.length === 0) {
        return [];
    }
    const installBasePopulated: IinstallBasePopulated[] = [];

    const { selectedJob } = manageJob;

    const facility: Ifacility = facilitiesByID[selectedJob.facilityID];

    const installBases = manageInventory.installBasesByID;

    // Need to confirm if these work orders are for the facility we are working on
    workOrders.forEach(wo => {
        if (installBases[wo.installBaseID]?.facilityID === facility?.id) {
            // Check to see if there is a JobWorkOrder for this WorkOrder
            const woInUse = filter(manageJob.jobWorkOrdersByID, {
                workOrderID: wo.id
            });

            // If this WorkOrder has already been assigned to a job, it can't be used again
            if (woInUse.length === 0) {
                const installBase = installBases[wo?.installBaseID];
                const product = manageInventory.productsByID[installBase?.productID];

                // product name is a string and must be wrapped in an array in order to push items to it
                if (installBase !== undefined && product !== undefined) {
                    const productNameParts: string[] = [product.name];
                    if (product.sapMaterialNumber) {
                        productNameParts.push(product.sapMaterialNumber);
                    }

                    if (product.deescription) {
                        productNameParts.push(product.description);
                    }

                    let ibp: IinstallBasePopulated = {
                        ...installBases[wo.installBaseID],
                        product,
                        productNameString: productNameParts.join(': '),
                        locationString: TableUtil.buildLocation(installBase, facility),
                        woNumberString: wo.number,
                        workOrderID: wo.id,
                        activityDescriptionString: wo.activityDescription,
                        latestMeasurementPointListResult: initialMeasurmentPointResult,
                        latestMeasurementPointListResultForJob: initialMeasurmentPointResult,
                        originalMeasurementPointListResultStatus: 0
                    };

                    installBasePopulated.push(ibp);
                }
            }
        }
    });

    return installBasePopulated;
};

export const getUnassignedWOrkOrderInstallBasePopulatd = createSelector(
    [getUnassignedSAPWorkOrdersById, getManageJob, getManageInventory, getFacilitiesById],
    (unassignedSapWorkOrdersById, manageJob, manageInventory, facilitiesByID) => {
        return getInstallBasePopulated(unassignedSapWorkOrdersById, manageJob, manageInventory, facilitiesByID);
    }
);

export const getWorkOrderInstallBasePopulated = createSelector(
    [getWorkOrder, getProducts, getManageJob, getManageInventory, getFacilitiesById, getSelectedJob],
    (workOrder, products, manageJob, manageInventory, facilitiesByID, selectedJob) => {
        /*
        TODO: Using the getWorkOrdersById selector would cause a function to be returned instead of the actual data. Moved logic into this selector for now.
        */

        // This selector is currently only used in SAPWorkOrderContainer, which requires SAP work orders.
        const workOrders =
            Object.keys(workOrder.unlinkedSapWorkOrdersByID).length > 0
                ? workOrder.unlinkedSapWorkOrdersByID
                : workOrder.workOrdersByID;

        const workOrdersById: { [key: string]: IWorkOrder } = Object.values(workOrders).reduce(
            (carry: { [key: string]: IWorkOrder }, wo: IWorkOrder) => {
                // Splice in Product ID and update product name.
                if (wo.productId) {
                    wo.product = products[wo.productId];
                }

                carry[wo.id] = wo;
                return carry;
            },
            {}
        );

        return getInstallBasePopulated(workOrdersById, manageJob, manageInventory, facilitiesByID);
    }
);

/*
 * prepare the array for the install base history
 * gather completed MPLRs and work orders and stuff them into a single array
 */
export const selectInstallBaseHistoryByInstallBaseID = (
    state: IinitialState,
    installBaseID: string
): (IWorkOrder | ImeasurementPointResultWithType)[] => {
    const installBaseMeasurementPointListResults = selectMeasurementPointListResultsByInstallBaseID(
        state,
        installBaseID
    );
    const installBaseCompletedWorkOrders = selectCompleteWorkOrdersByInstallBaseID(state, installBaseID);
    const installBaseMPLRsWithMeasurementType: ImeasurementPointResultWithType[] = map(
        installBaseMeasurementPointListResults,
        result => {
            return {
                ...result,
                measurementPointListType:
                    state.measurementPointLists.measurementPointListsByID[result.measurementPointListID]?.type
            };
        }
    );
    const MPLRsAndWorkOrders = [...installBaseMPLRsWithMeasurementType, ...installBaseCompletedWorkOrders];

    // Since we're combining work orders and MPRs into 1 list, we need to sort them differently by date
    return orderBy(
        MPLRsAndWorkOrders,
        res => {
            if ('closingNotes' in res) {
                // this is a work order
                return moment.utc(res.closingDate).unix();
            } else {
                return moment.utc(res.updateDate).unix();
            }
        },
        'desc'
    );
};

export function createSelectedIDWithName(name: string) {
    return (state = '', action: any): string => {
        switch (action.type) {
            case `SET_SELECTED_${name}`:
                return action.id;
            case `CLEAR_SELECTED_${name}`:
                return '';
            case types.USER_LOGOUT_SUCCESS:
                return '';
            default:
                return state;
        }
    };
}

export const offlineStatusSelector = (
    offlineFacilityStatus: { [key: string]: IofflineFacilityStatus },
    offlineJobStatus: { [key: string]: IofflineJobStatus },
    job: Ijob
) => {
    let isComplete = true;

    const jobStatus = offlineJobStatus[job.id] || initialOfflineJobStatus;

    if (jobStatus.simpleMeasurementPointResults === false) {
        isComplete = false;
    }

    if (job.jobTypeID === jobTypesIdEnum.verification && jobStatus.simpleMeasurementPointResults === false) {
        isComplete = false;
    }

    return isComplete;
};

export const selectOfflineStatusForJob = createSelector(
    [getOfflineFacilityStatus, getOfflineJobStatus, getJobProp],
    offlineStatusSelector
);

export const selectOfflineStatusForJobTest = createSelector(
    [getOfflineFacilityStatus, getOfflineJobStatus, getSelectedJob],
    offlineStatusSelector
);
