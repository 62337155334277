/*
 * Manage Install Form
 * Edit Install items
 */

import * as React from 'react';

import { Button, Col, ControlLabel, FormGroup } from 'react-bootstrap';
import { AbstractControl, FieldConfig, FormGenerator, GroupProps, Validators } from 'react-reactive-form';
import {
    bulkUpdateInstalls,
    deleteInstall,
    saveInstall,
    setEnableInstallBatchMode,
    setInstallFormValues,
    updateInstall,
    updateInstallFormValues
} from '../../actions/manageInventoryActions';
import {
    Ibuilding,
    Ifacility,
    IinstallBasePopulated,
    Ijob,
    Iproduct,
    IproductInfo,
    ItableFiltersReducer,
    Iuser
} from '../../models';
import { initialInstallBasePopulated, initialProduct } from '../../reducers/initialState';

import { TFunction } from 'i18next';
import { debounce, find, forEach, omit } from 'lodash';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import { RouteComponentProps } from 'react-router';
import { capitalEquipmentCategories, constants, productPlaceholderImages } from '../../constants/constants';
import { IinstallFormValues } from '../../modelsForms';
import { FormUtil } from '../common/FormUtil';
import { msalFetch } from '../auth/Auth-Utils';
import API from '../../constants/apiEndpoints';

interface Iprops extends RouteComponentProps<any> {
    updateInstall: typeof updateInstall;
    saveInstall: typeof saveInstall;
    toggleModal: () => void;
    selectedItem: IinstallBasePopulated;
    loading: boolean;
    colorButton: string;
    t: TFunction;
    tableFilters: ItableFiltersReducer;
    selectedProduct: Iproduct;
    mainCategoryID: string;
    facility: Ifacility;
    deleteInstall: typeof deleteInstall;
    saveAnyLocation: (
        name: string,
        facilityID: string,
        installBaseLocations?: {
            buildingID?: string;
            floorID?: string;
            locationID?: string;
        }
    ) => { id: string } | any;
    user: Iuser;
    showCancel?: boolean;
    productInfo: IproductInfo;
    enableBulkInstallBaseMode?: boolean;
    bulkUpdateInstalls: typeof bulkUpdateInstalls;
    selection?: string[];
    setEnableInstallBatchMode: typeof setEnableInstallBatchMode;
    setInstallFormValues: typeof setInstallFormValues;
    updateInstallFormValues: typeof updateInstallFormValues;
    formValues: IinstallFormValues;
    selectedJob: Ijob;
    isAPIQueueEmpty: boolean;
}

interface Istate {
    fieldConfig: FieldConfig;
    isGeneratingSerialNumber: boolean;
}

class ManageInstallForm extends React.Component<Iprops, Istate> {
    private formGroup: FormGroup | any;
    private subscription: any;
    private updateFormValueDebounced: any;
    static defaultProps = {
        selectedItem: initialInstallBasePopulated,
        selectedProduct: initialProduct
    };

    constructor(props: Iprops) {
        super(props);
        this.updateFormValueDebounced = debounce(this.props.updateInstallFormValues, constants.formDebounceTime);
        this.state = {
            fieldConfig: { controls: {} },
            isGeneratingSerialNumber: false
        };
    }

    componentDidMount() {
        // we should have a product if adding new, an id if editing, or in bulk mode.
        if (
            (!this.props.selectedProduct ||
                (this.props.selectedProduct && this.props.selectedProduct.id.length === 0)) &&
            (!this.props.selectedItem || (this.props.selectedItem && !this.props.selectedItem.id.length)) &&
            !this.props.bulkUpdateInstalls
        ) {
            console.error('[componentDidMount]: missing product and install');
        }
        if (this.props.enableBulkInstallBaseMode && this.props.location.pathname.includes('device')) {
            this.props.setEnableInstallBatchMode(false);
        }
        this.props.setInstallFormValues(this.itemToFormValues());
        this.setState({
            fieldConfig: this.buildFieldConfig(this.itemToFormValues())
        });
    }

    componentDidUpdate(prevProps: Iprops) {
        if (
            JSON.stringify(prevProps.selectedItem) !== JSON.stringify(this.props.selectedItem) &&
            this.props.selectedItem.id.length
        ) {
            this.setState({
                fieldConfig: this.buildFieldConfig(this.itemToFormValues())
            });
            this.props.setInstallFormValues(this.itemToFormValues());
        }
        // since the date-time controls manage their own state, we cannot patch them
        if (prevProps.formValues.prodDate !== this.props.formValues.prodDate) {
            this.setState({ fieldConfig: this.buildFieldConfig() });
        }

        if (
            JSON.stringify(prevProps.formValues.floorID) !== JSON.stringify(this.props.formValues.floorID) ||
            JSON.stringify(prevProps.formValues.locationID) !== JSON.stringify(this.props.formValues.locationID) ||
            JSON.stringify(prevProps.formValues.roomID) !== JSON.stringify(this.props.formValues.roomID) ||
            JSON.stringify(prevProps.formValues.buildingID) !== JSON.stringify(this.props.formValues.buildingID)
        ) {
            // this.setState({ fieldConfig: this.buildFieldConfig() });
            this.updateFormGroup();
        }
        if (JSON.stringify(this.props.facility) !== JSON.stringify(prevProps.facility)) {
            this.setState({ fieldConfig: this.buildFieldConfig() });
        }
    }

    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    updateFormGroup = (defaultValues: IinstallFormValues = this.prepValuesFromRedux()) => {
        forEach(defaultValues, (value, key) => {
            FormUtil.patchControl(this.formGroup, key, value);
        });
        // patch the options
        FormUtil.patchControl(this.formGroup, 'buildingID', defaultValues.buildingID, defaultValues.buildingOptions);
        FormUtil.patchControl(this.formGroup, 'floorID', defaultValues.floorID, defaultValues.floorOptions);
        FormUtil.patchControl(this.formGroup, 'locationID', defaultValues.locationID, defaultValues.locationOptions);
        FormUtil.patchControl(this.formGroup, 'roomID', defaultValues.roomID, defaultValues.roomOptions);
    };

    /*
     * itemToFormValues - take the selectedItem and convert it to formValues
     */
    itemToFormValues = (): IinstallFormValues => {
        let { buildingID, floorID, locationID, roomID, system } = this.props.selectedItem;

        let selectedSystemsOption;
        if (system) {
            selectedSystemsOption = {
                value: system,
                label: this.props.t('systemsOptionLabel', {
                    count: system
                })
            };
        }
        // when creating new, default to the selected locations in the table filters
        if (!this.props.selectedItem.id.length) {
            const { building, floor, location, room } = this.props.tableFilters;
            buildingID = building ? building.value : buildingID;
            floorID = floor ? floor.value : floorID;
            locationID = location ? location.value : locationID;
            roomID = room ? room.value : roomID;
        }

        const cleanedItem = omit(this.props.selectedItem, [
            'product',
            'workOrders',
            'latestMeasurementPointListResult',
            'facility'
        ]);

        return {
            ...cleanedItem,
            sapMaterialNumber: this.props.selectedItem.product.sapMaterialNumber,
            system: selectedSystemsOption,
            ...this.getAllFilteredLocations(buildingID, floorID, locationID, roomID)
        };
    };

    /*
     * formValuesToItem - convert the formValues to the shape of the selectedItem
     */
    formValuesToItem = (): any => {
        const formValues = FormUtil.getValues(this.formGroup.value);
        const cleanedItem = omit(this.props.selectedItem, [
            // if the user cleared any values out of the form on update, we don't want to just send back in the original install values
            ...Object.keys(this.state.fieldConfig.controls).filter(fieldName => formValues[fieldName] === undefined),
            'product',
            'workOrders',
            'fullLocation',
            'latestMeasurementPointListResult'
        ]);

        // verify the facilities match
        if (
            this.props.selectedItem.facilityID &&
            this.props.selectedItem.facilityID.length &&
            this.props.selectedItem.facilityID !== this.props.facility.id
        ) {
            console.error('[formValuesToItem]:', this.props.selectedItem, this.props.facility.id);
            toastr.error(
                this.props.t('toastMessage:error'),
                this.props.t('toastMessage:errorMatchingFacilities'),
                constants.toastrError
            );
            throw new Error('facilities do not match');
        }

        let cleanedInstall = {
            ...cleanedItem,
            ...formValues,
            productID: this.props.selectedItem.productID || this.props.selectedProduct.id,
            facilityID: this.props.facility.id,
            quantity: parseInt(`${formValues.quantity}`, 10)
        };

        // Make sure there are no empty strings for IDs
        cleanedInstall.latestAGSMeasurementPointListResultID =
            cleanedInstall.latestAGSMeasurementPointListResultID === ''
                ? undefined
                : cleanedInstall.latestAGSMeasurementPointListResultID;
        cleanedInstall.latestMeasurementPointListResultID =
            cleanedInstall.latestMeasurementPointListResultID === ''
                ? undefined
                : cleanedInstall.latestMeasurementPointListResultID;
        cleanedInstall.latestVerificationMeasurementPointListResultID =
            cleanedInstall.latestVerificationMeasurementPointListResultID === ''
                ? undefined
                : cleanedInstall.latestVerificationMeasurementPointListResultID;
        cleanedInstall.latestAuditMeasurementPointListResultID =
            cleanedInstall.latestAuditMeasurementPointListResultID === ''
                ? undefined
                : cleanedInstall.latestAuditMeasurementPointListResultID;
        cleanedInstall.latestCommissioningMeasurementPointListResultID =
            cleanedInstall.latestCommissioningMeasurementPointListResultID === ''
                ? undefined
                : cleanedInstall.latestCommissioningMeasurementPointListResultID;

        return cleanedInstall;
    };

    /*
     * (reusable)
     * subscribe to the formGroup changes
     */
    subscribeToChanges = () => {
        for (const key in this.formGroup.controls) {
            if (this.formGroup.controls.hasOwnProperty(key)) {
                this.subscription = this.formGroup.get(key).valueChanges.subscribe((value: any) => {
                    this.onValueChanges(value, key);
                });
            }
        }
    };
    /*
     * (reusable)
     * set the table filters to redux on each value change
     */
    onValueChanges = (value: any, key: string) => {
        switch (key) {
            case 'prodDate':
                this.handleNewManufactureDate(value);
                break;
            default:
                if (moment.isMoment(value)) {
                    value = value.format(constants.momentSQLFormat);
                }
                this.updateFormValueDebounced({ [key]: value });
                break;
        }
    };

    /*
     * prepValuesFromRedux
     * help filter and build the selected locations, and options
     */
    prepValuesFromRedux = () => {
        const formValues = FormUtil.getValues(this.props.formValues) as {
            [key: string]: any;
        };

        return {
            ...this.props.formValues,
            ...this.getAllFilteredLocations(
                formValues.buildingID,
                formValues.floorID,
                formValues.locationID,
                formValues.roomID
            )
        };
    };

    /*
  * buildFieldConfig
  accepts defaultValues and returns a formConfig object
  */
    buildFieldConfig = (defaultValues: IinstallFormValues = this.prepValuesFromRedux()) => {
        const isCapitalEquipmentType = Object.values(capitalEquipmentCategories).includes(this.props.mainCategoryID);
        let quantityValidators: any = [];
        const disabled = this.canEditInstalls() === false;
        const shouldRequireQuantity =
            this.props.selectedItem.id.length || this.props.enableBulkInstallBaseMode === true ? false : true;

        if (shouldRequireQuantity) {
            quantityValidators = [Validators.min(1), Validators.max(1000), FormUtil.validators.requiredWithTrim];
        }

        const fieldConfigControls = {
            $field_0: {
                isStatic: false, // ensures a key is added
                render: () => (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FormUtil.TextLabelStatic
                            meta={{
                                label: this.props.t('productInfo'),
                                colWidth: 12,
                                value: this.props.selectedProduct.name
                            }}
                        />
                        <img
                            width="50"
                            height="50"
                            style={{ marginRight: 15, background: 'white' }}
                            src={this.props.selectedProduct.imagePath || ''}
                            onError={(e: any) => {
                                e.target.onerror = null;
                                const placeholder = productPlaceholderImages.find(
                                    img => img.categoryId.toLocaleLowerCase() === this.props.mainCategoryID
                                );
                                if (placeholder) {
                                    e.target.src = require(`../../images/products/${placeholder.src}`);
                                } else {
                                    e.target.src = require('../../images/Azure.png');
                                    console.error(
                                        '[buildFieldConfig -> field_0 -> onError]: missing image for product',
                                        this.props.mainCategoryID
                                    );
                                }
                            }}
                            alt="icon"
                        />
                    </div>
                )
            },
            nickname: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'nickname',
                    colWidth: 12,
                    type: 'input',
                    name: 'nickname',
                    required: false
                },
                options: {
                    validators: Validators.maxLength(40)
                },
                formState: { value: defaultValues.nickname, disabled }
            },
            serialNumber: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'serialNumber',
                    colWidth: 6,
                    type: 'input',
                    name: 'serial-number',
                    required: isCapitalEquipmentType
                },
                options: {
                    validators: isCapitalEquipmentType
                        ? [Validators.maxLength(20), Validators.required, Validators.pattern(/^[\w-/]+$/)]
                        : [Validators.maxLength(20), Validators.pattern(/^[\w-/]+$/)]
                },
                formState: { value: defaultValues.serialNumber, disabled }
            },
            generateSNButton: {
                render: ({ meta }) => (
                    <div style={{ position: 'relative', top: '24px' }}>
                        <Col xs={meta.colWidth}>
                            <Button bsStyle={meta.style} onClick={meta.buttonAction} disabled={meta.disabled}>
                                {meta.buttonName}
                            </Button>
                        </Col>
                    </div>
                ),
                meta: {
                    buttonName: 'Generate S/N',
                    style: 'primary',
                    colWidth: 6,
                    buttonAction: () => this.generateSerialNumber(),
                    disabled
                }
            },
            rfid: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'rfid',
                    colWidth: 12,
                    type: 'input',
                    name: 'rfid',
                    required: false
                },
                options: {
                    validators: Validators.maxLength(20)
                },
                formState: { value: defaultValues.rfid, disabled }
            },
            sapEquipmentNumber: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'sapEquipmentNumber',
                    colWidth: 6,
                    type: 'input',
                    name: 'sapEquipmentNumber',
                    required: false
                },
                options: {
                    validators: Validators.maxLength(20)
                },
                formState: { value: defaultValues.sapEquipmentNumber, disabled: true }
            },
            sapMaterialNumber: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'sapMaterialNumber',
                    colWidth: 6,
                    type: 'input',
                    name: 'sapMaterialNumber',
                    required: false
                },
                options: {
                    validators: Validators.maxLength(20)
                },
                formState: { value: defaultValues.sapMaterialNumber, disabled: true }
            },
            remarks: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'remarks',
                    colWidth: 12,
                    componentClass: 'textarea',
                    name: 'remarks',
                    required: false
                },
                options: {
                    validators: Validators.maxLength(1000)
                },
                formState: { value: defaultValues.remarks, disabled }
            },
            quantity: {
                options: {
                    validators: quantityValidators
                },
                render: FormUtil.TextInput,
                inputType: 'number',
                meta: {
                    label: 'quantity',
                    colWidth: 6,
                    type: 'number',
                    defaultValue: 1,
                    name: 'quantity',
                    style: shouldRequireQuantity ? undefined : { display: 'none' }
                },
                formState: { value: defaultValues.quantity || 1, disabled: isCapitalEquipmentType }
            },
            $field_2: {
                isStatic: false, // ensures a key is added
                render: () => (
                    <FormUtil.TextLabelStatic
                        meta={{
                            label: this.props.t('locationLabel'),
                            colWidth: 12
                        }}
                    />
                )
            },
            $field_3: {
                isStatic: false, // ensures a key is added
                render: () => (
                    <Col
                        xs={12}
                        style={{
                            display: `${defaultValues.importedLocation ? 'block' : 'none'}`
                        }}
                    >
                        <FormGroup bsSize="sm">
                            <ControlLabel>{this.props.t('imported location')}</ControlLabel>
                            <h5 className="queue-form-label">{defaultValues.importedLocation}</h5>
                        </FormGroup>
                    </Col>
                )
            },
            buildingID: {
                render: FormUtil.CreatableSelect,
                meta: {
                    options: defaultValues.buildingOptions,
                    label: 'building',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    isMulti: false,
                    handleCreate: this.handleCreateBuilding,
                    name: 'building',
                    required: false,
                    isClearable: true
                },
                formState: {
                    value: defaultValues.buildingID,
                    disabled
                }
            },
            floorID: {
                render: FormUtil.CreatableSelect,
                meta: {
                    options: defaultValues.floorOptions,
                    label: 'floor',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    isMulti: false,
                    handleCreate: this.handleCreateFloor,
                    name: 'floor',
                    required: false,
                    isClearable: true
                },
                formState: {
                    value: defaultValues.floorID,
                    disabled
                }
            },
            locationID: {
                render: FormUtil.CreatableSelect,
                meta: {
                    options: defaultValues.locationOptions,
                    label: 'location',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    isMulti: false,
                    handleCreate: this.handleCreateLocation,
                    name: 'location',
                    required: false,
                    isClearable: true
                },
                formState: {
                    value: defaultValues.locationID,
                    disabled
                }
            },
            roomID: {
                render: FormUtil.CreatableSelect,
                meta: {
                    options: defaultValues.roomOptions,
                    label: 'room',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    isMulti: false,
                    handleCreate: this.handleCreateRoom,
                    name: 'room',
                    required: false,
                    isClearable: true
                },
                formState: {
                    value: defaultValues.roomID,
                    disabled
                }
            },
            position: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'position',
                    colWidth: 12,
                    name: 'position',
                    required: false
                },
                options: {
                    validators: Validators.maxLength(145)
                },
                formState: { value: defaultValues.position, disabled }
            },
            system: {
                render: FormUtil.Select,
                meta: {
                    options: constants.constructSystems(this.props.t),
                    label: 'system',
                    colWidth: 12,
                    placeholder: 'nsJob:typePlaceholder',
                    name: 'system',
                    disableSort: true,
                    required: false
                },
                formState: { value: defaultValues.system, disabled }
            },
            prodDate: {
                render: FormUtil.Datetime,
                meta: {
                    label: 'manufacture date',
                    colWidth: 12,
                    showTime: false,
                    name: 'manufacture-date',
                    required: false,
                    placeholder: 'DD-MMM-YY'
                },
                options: {
                    validators: [FormUtil.validators.isValidMoment]
                },
                formState: { value: defaultValues.prodDate, disabled }
            },
            $field_4: {
                isStatic: false, // ensures a key is added
                render: () => (
                    <FormUtil.TextLabelStatic
                        meta={{
                            label: this.props.t('labelOtherDates'),
                            colWidth: 12
                        }}
                    />
                )
            },
            installDate: {
                render: FormUtil.Datetime,
                meta: {
                    label: 'install date',
                    colWidth: 12,
                    showTime: false,
                    name: 'install-date',
                    required: false,
                    placeholder: 'DD-MMM-YY'
                },
                options: {
                    validators: [FormUtil.validators.isValidMoment]
                },
                formState: { value: defaultValues.installDate, disabled }
            },
            lastQuarterlyMaintenanceDate: {
                render: FormUtil.Datetime,
                meta: {
                    label: 'lastQuarterlyMaintenanceDate',
                    colWidth: 6,
                    showTime: false,
                    name: 'quarterly-date',
                    required: false,
                    placeholder: 'DD-MMM-YY'
                },
                options: {
                    validators: [FormUtil.validators.isValidMoment]
                },
                formState: {
                    value: defaultValues.lastQuarterlyMaintenanceDate,
                    disabled
                }
            },
            lastSixMonthMaintenanceDate: {
                render: FormUtil.Datetime,
                meta: {
                    label: 'lastSixMonthMaintenanceDate',
                    colWidth: 6,
                    showTime: false,
                    name: 'six-month-date',
                    required: false,
                    alignRight: true,
                    placeholder: 'DD-MMM-YY'
                },
                options: {
                    validators: [FormUtil.validators.isValidMoment]
                },
                formState: {
                    value: defaultValues.lastSixMonthMaintenanceDate,
                    disabled
                }
            },
            lastYearlyMaintenanceDate: {
                render: FormUtil.Datetime,
                meta: {
                    label: 'lastYearlyMaintenanceDate',
                    colWidth: 6,
                    showTime: false,
                    name: 'yearly-date',
                    required: false,
                    placeholder: 'DD-MMM-YY'
                },
                options: {
                    validators: [FormUtil.validators.isValidMoment]
                },
                formState: {
                    value: defaultValues.lastYearlyMaintenanceDate,
                    disabled
                }
            },
            lastTwoYearMaintenanceDate: {
                render: FormUtil.Datetime,
                meta: {
                    label: 'lastTwoYearMaintenanceDate',
                    colWidth: 6,
                    showTime: false,
                    name: 'two-year-date',
                    required: false,
                    alignRight: true,
                    placeholder: 'DD-MMM-YY'
                },
                options: {
                    validators: [FormUtil.validators.isValidMoment]
                },
                formState: {
                    value: defaultValues.lastTwoYearMaintenanceDate,
                    disabled
                }
            },
            lastThreeYearMaintenanceDate: {
                render: FormUtil.Datetime,
                meta: {
                    label: 'lastThreeYearMaintenanceDate',
                    colWidth: 6,
                    showTime: false,
                    name: 'three-year-date',
                    required: false,
                    placeholder: 'DD-MMM-YY'
                },
                options: {
                    validators: [FormUtil.validators.isValidMoment]
                },
                formState: {
                    value: defaultValues.lastThreeYearMaintenanceDate,
                    disabled
                }
            },
            lastFiveYearMaintenanceDate: {
                render: FormUtil.Datetime,
                meta: {
                    label: 'lastFiveYearMaintenanceDate',
                    colWidth: 6,
                    showTime: false,
                    name: 'five-year-date',
                    required: false,
                    alignRight: true,
                    placeholder: 'DD-MMM-YY'
                },
                options: {
                    validators: [FormUtil.validators.isValidMoment]
                },
                formState: {
                    value: defaultValues.lastFiveYearMaintenanceDate,
                    disabled
                }
            }
        } as { [key: string]: GroupProps };

        return FormUtil.translateForm(
            {
                controls: { ...fieldConfigControls }
            },
            this.props.t
        );
    };

    handleNewManufactureDate = (value: string | null) => {
        if (moment.isMoment(value) === false) {
            return;
        }
        const newDate = moment.isMoment(value) ? value.format(constants.momentSQLFormat) : value;
        let newDateFieldValues: { [key: string]: any } = { prodDate: newDate };
        if (newDate) {
            const dates = [
                'installDate',
                'lastQuarterlyMaintenanceDate',
                'lastThreeYearMaintenanceDate',
                'lastFiveYearMaintenanceDate',
                'lastTwoYearMaintenanceDate',
                'lastYearlyMaintenanceDate',
                'lastSixMonthMaintenanceDate'
            ];
            dates.forEach(date => {
                const dateValue = this.props.formValues[date];
                if (!dateValue) {
                    newDateFieldValues = {
                        ...newDateFieldValues,
                        [date]: newDate
                    };
                }
            });
            this.props.updateInstallFormValues(newDateFieldValues);
        }
    };

    handleFieldLengthError = (name: string) => {
        if (name.length > 250) {
            toastr.error('Field too long', '', constants.toastrError);
            return true;
        }
    };

    handleCreateBuilding = (name: string) => {
        if (this.handleFieldLengthError(name)) {
            return;
        }
        const locationObject = this.props.saveAnyLocation(name, this.props.facility.id, {});
        this.props.updateInstallFormValues({
            buildingID: { label: name, value: locationObject.id }
        });
    };

    handleCreateFloor = (name: string) => {
        const { buildingID } = this.formGroup.value;
        if (!buildingID) {
            toastr.error('Please select a building first.', '', constants.toastrError);
            return;
        }
        if (this.handleFieldLengthError(name)) {
            return;
        }
        const locationObject = this.props.saveAnyLocation(name, this.props.facility.id, {
            buildingID: buildingID.value
        });
        this.props.updateInstallFormValues({
            floorID: { label: name, value: locationObject.id }
        });
    };

    handleCreateLocation = (name: string) => {
        const { buildingID, floorID } = this.formGroup.value;
        if (!buildingID || !floorID) {
            toastr.error('Please select a building and a floor first.', '', constants.toastrError);
            return;
        }
        if (this.handleFieldLengthError(name)) {
            return;
        }
        const locationObject = this.props.saveAnyLocation(name, this.props.facility.id, {
            buildingID: buildingID.value,
            floorID: floorID.value
        });
        this.props.updateInstallFormValues({
            locationID: { label: name, value: locationObject.id }
        });
    };

    handleCreateRoom = (name: string) => {
        const { buildingID, floorID, locationID } = this.formGroup.value;
        if (!buildingID || !floorID || !locationID) {
            toastr.error('Please select a building, floor, and location first.', '', constants.toastrError);
            return;
        }
        if (this.handleFieldLengthError(name)) {
            return;
        }
        const locationObject = this.props.saveAnyLocation(name, this.props.facility.id, {
            buildingID: buildingID.value,
            floorID: floorID.value,
            locationID: locationID.value
        });
        this.props.updateInstallFormValues({
            roomID: { label: name, value: locationObject.id }
        });
    };

    getAllFilteredLocations = (buildingID?: string, floorID?: string, locationID?: string, roomID?: string) => {
        let selectedBuilding, selectedFloor, selectedLocation, selectedRoom;

        if (buildingID) {
            selectedBuilding = find(this.props.facility.buildings, {
                id: buildingID
            }) as Ibuilding;
            if (selectedBuilding) {
                selectedFloor = find(selectedBuilding.floors, {
                    id: floorID
                });
                if (selectedFloor) {
                    selectedLocation = find(selectedFloor.locations, {
                        id: locationID
                    });
                    if (selectedLocation) {
                        selectedRoom = find(selectedLocation.rooms, {
                            id: roomID
                        });
                    }
                }
            }
        }

        const buildingOptions = FormUtil.convertToOptions(this.props.facility.buildings);
        const floorOptions = FormUtil.convertToOptions(selectedBuilding ? selectedBuilding.floors : []);
        const locationOptions = FormUtil.convertToOptions(selectedFloor ? selectedFloor.locations : []);
        const roomOptions = FormUtil.convertToOptions(selectedLocation ? selectedLocation.rooms : []);

        return {
            buildingOptions,
            floorOptions,
            locationOptions,
            roomOptions,
            buildingID: FormUtil.convertToSingleOption(selectedBuilding),
            floorID: FormUtil.convertToSingleOption(selectedFloor),
            locationID: FormUtil.convertToSingleOption(selectedLocation),
            roomID: FormUtil.convertToSingleOption(selectedRoom)
        };
    };

    handleSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (this.formGroup.status === 'INVALID') {
            this.formGroup.markAsSubmitted();
            toastr.error('Please check invalid inputs', '', constants.toastrError);
            return;
        }

        if (this.props.facility) {
            if (this.props.enableBulkInstallBaseMode && this.props.selection) {
                // BULK EDIT
                const toastrConfirmOptions = {
                    onOk: () => {
                        this.props.bulkUpdateInstalls(this.formValuesToItem());
                    },
                    onCancel: () => null,
                    okText: this.props.t('buttonBulkUpdateInstalls'),
                    cancelText: this.props.t('common:cancel')
                };
                toastr.confirm(
                    this.props.t('toastMessage:confirmBulkUpdateInstalls', {
                        count: this.props.selection.length
                    }),
                    toastrConfirmOptions
                );
            } else if (this.props.selectedItem.id.length) {
                // EDIT
                this.props.updateInstall(this.formValuesToItem(), this.props.t, true);
            } else if (this.props.selectedProduct && this.props.selectedProduct.id.length) {
                // SAVE NEW
                this.props.saveInstall(this.formValuesToItem(), this.props.t, this.props.selectedProduct);
            } else {
                console.error('[handleSubmit]: missing product when saving a new install.');
            }
        } else {
            console.error('[handleSubmit]: missing facility, unable to save install');
            toastr.error('Error', 'Missing facility, please try again or contact support', constants.toastrError);
        }
    };

    handleDelete = () => {
        const bulkMode = this.props.enableBulkInstallBaseMode && this.props.selection;

        // They want to display the SAP asset delete message for all assets, keeping this in here for now in
        // case they change their mind because this message makes no sense to display for all assets
        const needsDoubleCheck = true; //this.props.selectedItem.workOrders?.find(wo => wo.source === WorkOrderSource.SAP);
        const del = bulkMode
            ? () => {
                  this.props.bulkUpdateInstalls({ isDeleted: true });
              }
            : () => {
                  if (this.props.match.url !== '/devices') {
                      this.props.history.push('/devices');
                  }
                  this.props.deleteInstall(this.props.selectedItem.id);
              };

        const toastrConfirmAgainOptions = {
            onOk: del,
            onCancel: () => null,
            okText: this.props.t('installDeleteOk'),
            cancelText: this.props.t('common:cancel')
        };

        const okText = bulkMode ? this.props.t('buttonBulkDeleteInstalls') : this.props.t('installDeleteOk');
        const toastrText = bulkMode
            ? this.props.t('toastMessage:confirmBulkDeleteInstalls', {
                  count: this.props.selection?.length
              })
            : this.props.t('installDeleteConfirm');

        const toastrConfirmOptions = {
            onOk: needsDoubleCheck
                ? () => {
                      toastr.confirm(this.props.t('installDeleteSecondWarning'), toastrConfirmAgainOptions);
                  }
                : del,
            onCancel: () => null,
            okText,
            cancelText: this.props.t('common:cancel')
        };
        toastr.confirm(toastrText, toastrConfirmOptions);
    };

    setForm = (form: AbstractControl) => {
        this.formGroup = form;
        this.formGroup.meta = {
            loading: this.props.loading
        };
        if (!this.subscription) {
            setTimeout(() => {
                this.subscribeToChanges();
            }, 300);
        }
    };

    canEditInstalls = () => {
        return (
            constants.hasSecurityFunction(this.props.user, constants.securityFunctions.ManageInventory.id) ||
            constants.hasSecurityFunction(this.props.user, constants.securityFunctions.FSE.id)
        );
    };

    generateSerialNumber = () => {
        if (!this.state.isGeneratingSerialNumber) {
            this.setState({ isGeneratingSerialNumber: true });
            msalFetch(API.inventory.getNextSerialNumber, { method: 'GET' })
                .then(result => {
                    const serialNumber = result.data;
                    this.formGroup.patchValue({ serialNumber });
                })
                .catch(error => constants.handleError(error, 'generate serial number'))
                .finally(() => {
                    this.setState({ isGeneratingSerialNumber: false });
                });
        }
    };

    render() {
        const { t } = this.props;
        const formClassName = `${this.props.colorButton}`;
        const deleteButtonStyle =
            !this.props.selectedItem.id.length && this.props.enableBulkInstallBaseMode === false
                ? { marginRight: '15px', display: 'none' }
                : { marginRight: '15px' };

        return (
            <div className={formClassName} style={{ marginTop: '-20px' }}>
                <form onSubmit={this.handleSubmit} className="clearfix beacon-form">
                    <FormGenerator onMount={this.setForm} fieldConfig={this.state.fieldConfig} />
                    <Col xs={12} className="form-buttons text-right">
                        {this.props.showCancel !== false && (
                            <Button
                                bsStyle="default"
                                type="button"
                                className="pull-left"
                                onClick={this.props.toggleModal}
                            >
                                {t('common:cancel')}
                            </Button>
                        )}
                        <Button
                            bsStyle={!!this.props.formValues.sapEquipmentNumber ? 'grey' : 'warning'}
                            style={deleteButtonStyle}
                            type="button"
                            className=""
                            disabled={this.props.loading || !!this.props.formValues.sapEquipmentNumber}
                            onClick={this.handleDelete}
                        >
                            {t('common:delete')}
                        </Button>
                        <Button bsStyle={this.props.colorButton} type="submit" disabled={this.props.loading}>
                            {t('common:save')}
                        </Button>
                    </Col>
                </form>
            </div>
        );
    }
}
export default ManageInstallForm;
