import * as React from 'react';

import { Col } from 'react-bootstrap';

import { PartTableContainer } from './PartTableContainer';
import PartFormContainer from './PartFormContainer';
import { Ihistory } from '../../models';
interface Iprops {
    installID: string;
    history: Ihistory;
}

export const PartsControl = (props: Iprops) => {
    return (
        <Col xs={12} style={{ paddingTop: '20px' }}>
            <PartFormContainer installID={props.installID} history={props.history} />
            <PartTableContainer installID={props.installID} />
        </Col>
    );
};
