import * as React from 'react';

import { Button, Modal } from 'react-bootstrap';
import { AppSyncItem } from '../../models';
import { AppSyncItemStatus } from '../../models-enums';
import { ProgressBar } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import * as types from '../../actions/actionTypes';
import { userLogoutSessionOnly } from '../../actions/userActions';
const logo = require('../../images/LoadingLogo.png');

interface Props {
    show: boolean;
    message?: string;
    syncItems?: AppSyncItem[];
    downloadSpeed: number | undefined;
    restartAppSync?: () => void;
}

interface SyncProgress {
    currentIndex: number;
    totalItems: number;
}
const Loading: React.FC<Props> = props => {
    const [displaySyncItems, setDisplaySyncItems] = React.useState<AppSyncItem[]>([]);
    const [syncProgress, setSyncProgress] = React.useState<SyncProgress>({ currentIndex: 0, totalItems: 0 });
    const dispatch = useDispatch();

    React.useEffect(() => {
        // Only run this logic if the modal is showing
        if (props.show) {
            // First Load
            if (displaySyncItems.length === 0 && props.syncItems) {
                setDisplaySyncItems(props.syncItems);
                setSyncProgress({ currentIndex: 0, totalItems: props.syncItems.length });
            } else if (
                props.syncItems &&
                displaySyncItems.length === 1 &&
                displaySyncItems[0].name === '' &&
                displaySyncItems[0].displayName === ''
            ) {
                // If something fails and we're retrying, displaySyncItems will have a single item with empty strings for some reason
                setDisplaySyncItems(props.syncItems);
                setSyncProgress({ currentIndex: 0, totalItems: props.syncItems.length });
            } else if (displaySyncItems.length > 0 && props.syncItems && props.syncItems.length > 0) {
                // Props.SyncItems array will shrink as API calls are completed, so if we notice a difference, we can assume that an item has been completed
                let itemsCompleted = 0;
                displaySyncItems.forEach((item: AppSyncItem) => {
                    const foundItem = props.syncItems?.find(syncItem => syncItem.name === item.name);

                    if (!foundItem) {
                        itemsCompleted++;
                        item.status = AppSyncItemStatus.success;
                    } else {
                        item.status = foundItem.status;
                    }
                });
                setDisplaySyncItems(displaySyncItems);
                setSyncProgress({ ...syncProgress, currentIndex: itemsCompleted });
            }
        } else {
            // If we're not showing the modal, make sure the local state is cleared
            if (displaySyncItems.length > 0) {
                setDisplaySyncItems([]);
            }

            // If we're not showing the modal, make sure the download speed is cleared for next use
            if (props.downloadSpeed) {
                dispatch({ type: types.SET_DOWNLOAD_SPEED, payload: undefined });
            }
        }
    }, [props.syncItems, props.show]);

    const getCurrentSyncItem = () => {
        let itemToDisplay = '';

        if (displaySyncItems && displaySyncItems.length > 0) {
            const inProgressItems = displaySyncItems.filter(item => item.status === AppSyncItemStatus.inProgress);
            if (inProgressItems.length > 0) {
                itemToDisplay = inProgressItems[0].displayName;
            }
        }

        return itemToDisplay;
    };

    const logoutUser = () => {
        dispatch(userLogoutSessionOnly());
    };

    return (
        <Modal show={props.show} className="loading-modal" onHide={() => null}>
            <Modal.Body className="text-center">
                <img style={{ width: '275.84px' }} src={logo} alt="loading" />
                <h3 className="modal-title">{props.message || 'LOADING...'}</h3>

                {displaySyncItems && displaySyncItems.length > 0 && (
                    <div className="loading-container">
                        <ProgressBar
                            className="loading-progress"
                            active
                            now={syncProgress.currentIndex}
                            max={syncProgress.totalItems}
                        />
                        {syncProgress.currentIndex} of {syncProgress.totalItems} items synced, loading{' '}
                        {getCurrentSyncItem()}
                        {props.downloadSpeed && (
                            <div className="loading-speed">Download Speed: {props.downloadSpeed.toFixed(2)} Mbps</div>
                        )}
                        <div className="loading-action-container">
                            <button className="exit-logOut-btn" onClick={logoutUser}>
                                Exit & Log Out
                            </button>
                            <button className="retry-btn" onClick={props.restartAppSync}>
                                Retry
                            </button>
                        </div>
                    </div>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default Loading;
