/*
 * Edit Job Modal and Redux Connector
 */
import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';

import CommissioningDataForm from './CommissioningDataForm';
import { IinitialState } from '../../../reducers';
import { WithCommonModal } from '../../common/WithCommonModal';
import { saveCommissioningData } from '../../../actions/manageInventoryActions';
import { selectIsLoading } from '../../../reducers/commonReducers';
import { toggleCommissioningDataFormModal, updateJobDefaultsFormValue } from '../../../actions/manageInventoryActions';
import { selectCommissionDataByJobId } from '../../../reducers/manageInventoryReducer';

interface Iprops {
    colorButton: any;
    selectedJobID: string;
    disabled?: boolean;
    modalClass?: string;
    secondModal?: boolean;
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops & WithTranslation) => {
    const title = ownProps.t('manageInventory:commissioningDataFrom');
    const className = ownProps.secondModal ? 'commissioning-data second-modal' : 'commissioning-data';
    const existingCommissioningData = selectCommissionDataByJobId(state);

    return {
        title,
        className,
        loading: selectIsLoading(state),
        existingCommissioningData,
        show: state.manageInventory.showCommissioningDataFormModal,
        wideModal: true
    };
};

export default withTranslation('manageInventory')(
    connect(mapStateToProps, {
        updateFormValue: updateJobDefaultsFormValue,
        toggleModal: toggleCommissioningDataFormModal,
        onHide: toggleCommissioningDataFormModal,
        saveCommissioningData
    })(WithCommonModal(CommissioningDataForm))
);
