/*
 * SearchAllPartsForm
 */

import * as React from 'react';

import { TFunction } from 'i18next';
import { debounce, find } from 'lodash';
import { Button, Col } from 'react-bootstrap';
import { FieldConfig, FormArray, FormGenerator, FormGroup, GroupProps, Validators } from 'react-reactive-form';
import { toastr } from 'react-redux-toastr';

import { searchPartsForAsyncSelect } from '../../actions/partsActions';
import { getBundleOptions } from '../../components/common/ShoppingCart/ShoppingCartSubSubItem';
import { constants } from '../../constants/constants';
import { Ipart } from '../../models';
import { partTypeEnum } from '../../models-enums';
import { IgenericFormValues } from '../../modelsForms';
import { initialPart } from '../../reducers/initialState';
import { FormUtil } from '../common/FormUtil';

interface Iprops {
    loading: boolean;
    colorButton: string;
    t: TFunction;
    closeModal?: () => void;
    onSubmit: (part: Ipart) => void;
    shouldShowQuantity?: boolean;
    shouldShowQuote?: boolean;
    partType: partTypeEnum;
}

interface Istate {
    fieldConfig: FieldConfig;
    parts: Ipart[];
}

export class SearchAllPartsForm extends React.Component<Iprops, Istate> {
    private formGroup: FormGroup | any;
    private subscription: any;
    static defaultProps = {
        shouldShowQuantity: false,
        shouldShowQuote: false
    };
    constructor(props: Iprops) {
        super(props);
        this.state = {
            fieldConfig: this.buildFieldConfig(),
            parts: []
        };
    }
    componentDidMount() {
        this.setState({
            fieldConfig: this.buildFieldConfig(this.itemToFormValues())
        });
    }

    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    /*
     * itemToFormValues - take the selectedItem and convert it to formValues
     */
    itemToFormValues = (): IgenericFormValues<Ipart> => {
        return {};
    };

    /*
     * formValuesToItem - convert the formValues to the shape of the selectedItem
     */
    formValuesToItem = (): Ipart => {
        const processedFormValues = FormUtil.getValues(this.formGroup.value);
        const foundPart =
            (find(this.state.parts, {
                id: processedFormValues.partID
            }) as Ipart) || initialPart;
        return {
            ...initialPart,
            ...foundPart,
            ...processedFormValues
        };
    };

    loadOptions = debounce((searchTerm, callback) => {
        searchPartsForAsyncSelect(searchTerm, this.props.partType).then(result => {
            this.setState({ parts: result.parts });
            callback(result.partOptions);
        });
    }, 500);

    buildFieldConfig = (defaultValues = this.itemToFormValues()) => {
        const disabled = false;

        // Field config to configure form
        let fieldConfigControls = {
            partID: {
                options: {
                    validators: [Validators.required]
                },
                render: FormUtil.AsyncSelect,
                meta: {
                    label:
                        this.props.partType === partTypeEnum.default
                            ? 'searchAllPartsModal.part'
                            : 'searchAllPartsModal.labor',
                    colWidth: 10,
                    loadOptions: this.loadOptions,
                    name: 'part',
                    required: true,
                    isMulti: false
                },
                formState: {
                    value: null,
                    disabled
                }
            },
            bundle: {
                options: {
                    validators: [Validators.required]
                },
                render: FormUtil.Select,
                meta: {
                    options: getBundleOptions(),
                    label: 'partCartModal.bundle',
                    colWidth: 4,
                    name: 'bundle',
                    required: true
                },
                formState: {
                    value: 1,
                    disabled
                }
            },
            quantity: {
                options: {
                    validators: [Validators.required]
                },
                render: FormUtil.NumericInput,
                meta: {
                    label: 'searchAllPartsModal.quantity',
                    colWidth: 3,
                    name: 'quantity',
                    required: true
                },
                formState: {
                    value: 1,
                    disabled
                }
            }
        } as { [key: string]: GroupProps };

        if (this.props.shouldShowQuantity === false) {
            delete fieldConfigControls.quantity;
        }

        if (this.props.shouldShowQuote === false) {
            delete fieldConfigControls.bundle;
        }

        const fieldConfig = {
            controls: { ...fieldConfigControls }
        };

        return FormUtil.translateForm(fieldConfig, this.props.t);
    };

    handleSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if (this.formGroup.status === 'INVALID') {
            this.formGroup.markAsSubmitted();
            toastr.error('Please check invalid inputs', '', constants.toastrError);
            return;
        }

        this.props.onSubmit(this.formValuesToItem());

        if (this.props.closeModal) {
            this.props.closeModal();
        }
    };

    setForm = (form: FormGroup | FormArray) => {
        this.formGroup = form;
        this.formGroup.meta = {
            loading: this.props.loading
        };
    };

    render() {
        const { t, partType, closeModal } = this.props;

        const formClassName = `clearfix job-form beacon-form ${this.props.colorButton}`;
        if (partType === partTypeEnum.default && closeModal) {
            return (
                <form
                    onSubmit={this.handleSubmit}
                    className={formClassName}
                    style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}
                >
                    <FormGenerator onMount={this.setForm} fieldConfig={this.state.fieldConfig} />
                    <Col xs={12} className="form-buttons labor text-right" style={{ marginTop: '24px', width: 'auto' }}>
                        <Button
                            bsStyle="default"
                            type="button"
                            className="pull-left"
                            onClick={() => {
                                closeModal();
                            }}
                        >
                            {t('cancel')}
                        </Button>
                        <Button bsStyle={this.props.colorButton} type="submit">
                            {t('searchAllPartsModal.addButton')}
                        </Button>
                    </Col>
                </form>
            );
        } else {
            return (
                <form
                    onSubmit={this.handleSubmit}
                    className={formClassName}
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '100%' }}
                >
                    <FormGenerator onMount={this.setForm} fieldConfig={this.state.fieldConfig} />
                    <Col xs={4} className="form-buttons text-right" style={{ marginTop: '14px', width: 'auto' }}>
                        <Button bsStyle={this.props.colorButton} type="submit">
                            {t('searchAllPartsModal.addButton')}
                        </Button>
                    </Col>
                </form>
            );
        }
    }
}
