import React, { useState } from 'react';
import styled from 'styled-components';
import { Iphoto } from '../../../models';
import CommonMobileModal from '../../common/CommonMobileModal';
import EditPhotoForm from '../EditPhotoForm';

interface IProps {
    data: Iphoto[];
    t: any;
    loading: boolean;
    installBaseID: string;
    updatePhoto: any;
    deletePhoto: any;
}

const ListView = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const PhotoContainer = styled.div`
    display: flex;
    gap: 24px;
    flex-direction: row;
    width: 100%;
    padding: 16px;
    border-bottom: 1px solid #dbdcdd;
`;

const ImageCol = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 144px;

    & > span {
        height: 40px;
        width: 100%;

        font-family: 'Roboto Condensed';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;

        /* Blues/Aqua */

        color: #3bb9ea;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

const Image = styled.img`
    border: 1px solid #dbdcdd;
    border-radius: 4px;
`;

const RightCol = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
`;

const ImgSection = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 16px;
`;

const ImgSectionTitle = styled.span`
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #a2acb4;
    padding-bottom: 4px;
`;

const ReportWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const CheckboxWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
`;

const Checkbox = () => {
    return (
        <CheckboxWrapper>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
                <path fill="#007BC3" d="M16 0H4a4 4 0 00-4 4v12a4 4 0 004 4h12a4 4 0 004-4V4a4 4 0 00-4-4z" />
                <path stroke="#fff" strokeWidth="3" d="M4 8.485l4.243 4.243 8.485-8.485" />
            </svg>
        </CheckboxWrapper>
    );
};

export default (props: IProps) => {
    const [selectedPhoto, setSelectedPhoto] = useState<Iphoto | null>(null);

    if (selectedPhoto) {
        return (
            <CommonMobileModal
                show={true}
                onHide={() => {
                    setSelectedPhoto(null);
                }}
                className=""
                title="Edit Image"
            >
                <EditPhotoForm
                    t={props.t}
                    loading={props.loading}
                    installBaseID={props.installBaseID}
                    formValues={selectedPhoto}
                    updatePhoto={props.updatePhoto}
                    deletePhoto={props.deletePhoto}
                    toggleModal={() => setSelectedPhoto(null)}
                    isMobile={true}
                />
            </CommonMobileModal>
        );
    }

    return (
        <ListView>
            {props.data.map((photo: Iphoto, index) => {
                return (
                    <PhotoContainer key={photo.id}>
                        <ImageCol>
                            <Image width="144px" src={photo.thumbnailUrl} />
                            <span
                                onClick={() => {
                                    setSelectedPhoto(photo);
                                }}
                            >
                                Edit Image
                            </span>
                        </ImageCol>
                        <RightCol>
                            <ImgSection>
                                <ImgSectionTitle>Comments</ImgSectionTitle>
                                {photo.comments}
                            </ImgSection>
                            <ImgSection>
                                <ImgSectionTitle>Date Taken</ImgSectionTitle>
                                {photo.createDate}
                            </ImgSection>
                            {photo.isInReport && (
                                <ImgSection>
                                    <ImgSectionTitle>Report</ImgSectionTitle>
                                    <ReportWrapper>
                                        <span>Show Image in Report</span> <Checkbox />
                                    </ReportWrapper>
                                </ImgSection>
                            )}
                        </RightCol>
                    </PhotoContainer>
                );
            })}
        </ListView>
    );
};
