import { ImanageAssetPhotosReducer, Iphoto } from '../models';
import { filter, keyBy, map, orderBy, pickBy } from 'lodash';
import initialState, { initialPhoto } from './initialState';
import {
    ADD_NEW_PHOTO_SUCCESS,
    GET_PHOTOS_SUCCESS,
    GET_PHOTOS_FAILED,
    PUT_PHOTO_SUCCESS,
    DELETE_PHOTO_SUCCESS
} from '../actions/actionTypes';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import { IinitialState } from '.';
import moment from 'moment';
import { getSelectedJob } from './manageJobReducer';

export const cleanPhotoObject = (photo: any): Iphoto => {
    return {
        ...initialPhoto,
        ...pickBy(photo, (property, key) => property !== null)
    };
};

const getPhotos = (state: IinitialState) => state.photos.photos;
const getInstallParam = (state: IinitialState, prop: { installBaseID: string }) => prop.installBaseID;
export const selectPhotosForTable = createSelector(
    [getPhotos, getInstallParam, getSelectedJob],
    (allPhotos, installBaseID, selectedJob) => {
        return orderBy(
            filter(allPhotos, { isDeleted: false, installBaseID, jobID: selectedJob.id }),
            res => moment.utc(res?.updateDate || res?.createDate).unix(),
            'desc'
        );
    }
);

function photos(state: { [key: string]: Iphoto } = initialState.photos.photos, action: any): { [key: string]: Iphoto } {
    switch (action.type) {
        case GET_PHOTOS_SUCCESS: {
            const newPhotos = map(action.payload.data.result, photo => {
                return cleanPhotoObject(photo);
            });
            const keyedNewPhotos = keyBy(newPhotos, 'id');
            return { ...state, ...keyedNewPhotos };
        }
        // We can guarantee we already have the photo added, only things that can change are comments and isInReport
        case PUT_PHOTO_SUCCESS: {
            const { id, comments, isInReport } = action.payload;
            const photo = state[id];
            const updatedPhoto = { ...photo, comments, isInReport };
            return { ...state, [id]: updatedPhoto };
        }
        case ADD_NEW_PHOTO_SUCCESS: {
            const newPhoto = cleanPhotoObject(action.payload);
            return { ...state, [newPhoto.id]: newPhoto };
        }

        case DELETE_PHOTO_SUCCESS: {
            const photoToDelete = state[action.payload];
            const deletedPhoto = { ...photoToDelete, isDeleted: true };
            return { ...state, [deletedPhoto.id]: deletedPhoto };
        }
        case GET_PHOTOS_FAILED:
            return {};
        default:
            return state;
    }
}

const manageAssetPhotos = combineReducers<ImanageAssetPhotosReducer>({
    photos
});

export default manageAssetPhotos;
