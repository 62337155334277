import { TFunction } from 'i18next';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { IWorkOrder } from '../../../models';
import { TabType } from './Measurements';

export interface TabSelectorEntity {
    title: string;
    id: string | number;
    isWorkOrder: boolean;
    isMeasurementPoint: boolean;
    index: number;
}

interface Iprops {
    tabs: TabSelectorEntity[];
    t: TFunction;
    getWorkOrderTitle: (workOrder: IWorkOrder, t: TFunction) => string;
    setActiveTab: (tab: TabType | number | string) => void;
    activeTab: number | TabType | string;
}

const SelectorWrapper = styled.div`
    width: 80%;
    cursor: pointer;
    user-select: none;
`;

const InputWrapper = styled.div`
    background: #ffffff;
    padding-left: 12px;
    padding-right: 12px;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #013564;
    width: 100%;
    min-height: 41px;
    height: 41px;
    max-height: 41px;
    border-radius: 4px 4px 0px 0px;
    z-index: 999;
`;

const ArrowSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="7" fill="none" viewBox="0 0 11 7">
            <path
                stroke="#CCC"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M1 1.25l4.5 4.5 4.5-4.5"
            ></path>
        </svg>
    );
};

const Arrow = styled(ArrowSvg)`
    width: 9px;
    height: 9px;

    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    right: 12px;
`;

const Placeholder = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #007bc3;
    height: 100%;

    & > span {
        font-family: 'Roboto Condensed';
        font-style: normal;
        font-weight: 800;
        font-size: 14px;
        color: #013564;
    }
`;

const Menu = styled.div`
    background-color: #fff;
    max-height: 400px;
    z-index: 998;
    position: relative;
    overflow-y: scroll;

    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: center;
    align-items: center;
    padding-top: 4px;
    padding-bottom: 8px;
`;

const Dropdown = styled.div`
    width: 80%;
    border: ${(props: any) => props.isOpen && '1px solid #F1F1F1'};
    box-shadow: ${(props: any) => props.isOpen && '0px 5px 6px rgba(0, 0, 0, 0.25)'};
    border-radius: ${(props: any) => props.isOpen && '4px'};
`;

const Item = styled.div`
    height: 33px;
    padding-left: 12px;
    display: flex;
    align-items: center;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: ${(props: any) => (props.highlight ? '800' : '400')};
    font-size: 14px;
    color: #007bc3;
    width: 100%;
    background-color: ${(props: any) => props.highlight && '#C1EDFF'};
`;

const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 997;
`;

export default (props: Iprops) => {
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [hoveredIndex, setHoveredIndex] = React.useState<number>();

    const placeholder: string = useMemo(() => {
        const activeTab: TabSelectorEntity | undefined = props.tabs.find(
            (tab: TabSelectorEntity) => tab.id === props.activeTab
        );
        if (!activeTab) {
            return 'Select from list';
        }

        return activeTab.title;
    }, [props.tabs, props.activeTab]);

    return (
        <SelectorWrapper>
            {isOpen && (
                <Backdrop
                    onClick={() => {
                        setIsOpen(false);
                    }}
                />
            )}
            <Dropdown isOpen={isOpen}>
                <InputWrapper
                    onClick={() => {
                        setIsOpen(isOpen ? false : true);
                    }}
                >
                    <Placeholder>
                        <span>{placeholder}</span>
                        <Arrow />
                    </Placeholder>
                </InputWrapper>
                {isOpen && (
                    <Menu>
                        {props.tabs.map((tab: TabSelectorEntity, index: number) => {
                            return (
                                <Item
                                    key={tab.id}
                                    highlight={index === hoveredIndex || props.activeTab === tab.id}
                                    onMouseOver={() => {
                                        setHoveredIndex(index);
                                    }}
                                    onClick={() => {
                                        props.setActiveTab(tab.id);
                                        setIsOpen(false);
                                    }}
                                >
                                    {tab.title}
                                </Item>
                            );
                        })}
                    </Menu>
                )}
            </Dropdown>
        </SelectorWrapper>
    );
};
