import * as React from 'react';

/*
 * Manage Job and Devices
 */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce } from 'lodash';
import moment from 'moment';
import { Button, Col, Row } from 'react-bootstrap';
import { WithTranslation, withTranslation } from 'react-i18next';
import { FieldConfig } from 'react-reactive-form';
import { connect, useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import ReactTable, { RowInfo, SortingRule } from 'react-table';
import selectTableHOC, { SelectTableAdditionalProps } from 'react-table/lib/hoc/selectTable';
import * as types from '../../actions/actionTypes';
// import { setIsDownloadingJob } from '../../actions/commonActions';
import { getContactsByFacility } from '../../actions/contactsActions';
import {
    clearTableFilter,
    getCommissioningInstallBases,
    getInventory,
    setSelectedProduct,
    setTableFilter,
    toggleCommissioningDataFormModal,
    toggleEditInstallModal,
    toggleEditJobDefaultsModal,
    toggleModalNotePad,
    toggleModalSignaturePad,
    updateInstallBaseSelection
} from '../../actions/manageInventoryActions';
import {
    addOpenedInspectionJob,
    addWorkOrdersToJob,
    setJobFinalCheckMoal,
    updateJob
} from '../../actions/manageJobActions';
import {
    bulkCannotCompleteSelected,
    getMeasurementPointListResultsForJobs
} from '../../actions/measurementPointResultsActions';
import {
    getSAPWorkOrders,
    setSelectedWorkOrderID,
    toggleAddRepairWorkOrderModal,
    toggleConfirmSelectJobModal
} from '../../actions/workOrderActions';
import { constants, countriesWithJobDefaults } from '../../constants/constants';
import {
    IWorkOrder,
    Ifacility,
    IinstallBasePopulated,
    Ijob,
    IjobPopulated,
    Ioption,
    Iproduct,
    IproductInfo,
    ItableFiltersReducer,
    Itile,
    Iuser,
    WorkOrderSource
} from '../../models';
import {
    jobStatusEnum,
    jobTypesIdEnum,
    jobTypesIdEnumInverse,
    measurementPointResultStatusTypesEnum,
    workOrderStatusEnum
} from '../../models-enums';
import { IinitialState } from '../../reducers';
import {
    selectIsLoading,
    selectOfflineStatusForJob,
    selectOfflineStatusForJobTest,
    selectOutbox
} from '../../reducers/commonReducers';
import { selectJobWorkOrdersForCurrentJob } from '../../reducers/commonSelectors';
import { getIsMobile } from '../../reducers/configReducer';
import { initialFacility, initialInstallBasePopulated, initialJob } from '../../reducers/initialState';
import { selectIsWorkOrderMode } from '../../reducers/locationReducer';
import {
    filterInstallBases,
    getProducts,
    getRefreshInventory,
    selectFilterConfig,
    selectInstallBasesByID,
    selectInventoryNoDataText,
    showManageInventoryLoading
} from '../../reducers/manageInventoryReducer';
import {
    getIsAddWorkOrderMode,
    getIsCommissioningTypeJob,
    getIsMaintenanceTypeJob,
    getIsWorkOrderTypeJob,
    jobFinalCheckModalOn
} from '../../reducers/manageJobReducer';
import { Banner } from '../common/Banner';
import { FormUtil } from '../common/FormUtil';
import Loading from '../common/Loading';
import SearchTableForm from '../common/SearchTableForm';
import { TableUtil } from '../common/TableUtil';
import FilterAssetTableModal from '../common/mobile/FilterAssetTableModal';
import EditJobModal from '../manageJob/EditJobModal';
import CommissioningDataSignatureModal from '../workOrder/CommissioningDataSignatureModal';
import ConfirmSelectJobModal from '../workOrder/ConfirmSelectJobModal';
import WorkOrderCloseModal from '../workOrder/WorkOrderCloseModal';
import AddFSEModal from './AddFSEModal';
import AddHoursModalContainer from './AddHoursModalContainer';
import CommissioningDataModal from './CommissioningData/CommissioningDataModal';
import EditFacilityContactsModal from './EditFacilityContactsModal';
import EditInstallModal from './EditInstallModal';
import EditJobDefaultsModal from './EditJobDefaults/EditJobDefaultsModal';
import EditProductModal from './EditProductModal';
import { InventoryActionButtonContainer } from './InventoryActionButtonContainer';
import { JobFinalCheckModal } from './JobFinalCheckModal';
import NoteModal from './NoteModal';
import { SearchInventoryTableContainer } from './SearchInventoryTableContainer';
import SearchNewProductsModal from './SearchNewProductsModal';
import SignatureModal from './SignatureModal';

interface Iprops extends RouteComponentProps<any> {
    // Add your regular properties here
    showNotePadModal: boolean;
    showEditProductModal: boolean;
    showEditInstallModal: boolean;
    showSignatureModal: boolean;
    loading: boolean;
}

interface IdispatchProps {
    // Add your dispatcher properties here
    toggleCommissioningDataFormModal: typeof toggleCommissioningDataFormModal;
    toggleConfirmSelectJobModal: typeof toggleConfirmSelectJobModal;
    toggleEditInstallModal: typeof toggleEditInstallModal;
    toggleModalSignaturePad: typeof toggleModalSignaturePad;
    toggleModalNotePad: typeof toggleModalNotePad;
    getCommissioningInstallBases: typeof getCommissioningInstallBases;
    getMeasurementPointListResultsForJobs: typeof getMeasurementPointListResultsForJobs;
    toggleSecurityFunctionsModal: () => void;
    getContactsByFacility: typeof getContactsByFacility;
    productInfo: IproductInfo;
    facilityOptions: Ioption[];
    user: Iuser;
    setTableFilter: typeof setTableFilter;
    tableFilters: ItableFiltersReducer;
    selectedProduct: Iproduct;
    setSelectedProduct: typeof setSelectedProduct;
    selectedJob: Ijob;
    updateJob: typeof updateJob;
    openedInspectionJobIDs: string[];
    workOrderUpdatedSuccess?: string;
    toggleEditJobDefaultsModal: typeof toggleEditJobDefaultsModal;
    addOpenedInspectionJob: typeof addOpenedInspectionJob;
    showSearchNewProductsModal: boolean;
    facility: Ifacility;
    addWorkOrdersToJob: typeof addWorkOrdersToJob;
    updateInstallBaseSelection: typeof updateInstallBaseSelection;
    selection: string[];
    enableBulkInstallBaseMode: boolean;
    showAdditionalFilters?: boolean;
    isMaintenanceTypeJob: boolean;
    isWorkOrderTypeJob: boolean;
    isAddWorkOrderMode: boolean;
    setSelectedWorkOrderID: typeof setSelectedWorkOrderID;
    noDataText: string;
    bulkCannotCompleteSelected: typeof bulkCannotCompleteSelected;
    isRepairTypeJob: boolean;
    isCommissioningTypeJob: boolean;
    isJobDownloaded: boolean;
    lastSync: number;
}

interface Istate {
    selectedRow: any;
    selectedInstall: IinstallBasePopulated;
    selectAll: boolean;
    showFilterSearch: boolean;
    isMenuOpen: boolean;
}

const SelectTable = selectTableHOC(ReactTable);

const ManageInventory: React.FC<Iprops & IdispatchProps & WithTranslation & SelectTableAdditionalProps> = props => {
    const prevProps = React.useRef<
        (Iprops & IdispatchProps & WithTranslation & SelectTableAdditionalProps) | undefined
    >();
    const upperWrap = React.useRef<HTMLDivElement>(null);
    const {
        addOpenedInspectionJob: addOpInspJob,
        t,
        updateInstallBaseSelection: updateIBS,
        history,
        isRepairTypeJob,
        isCommissioningTypeJob,
        isJobDownloaded,
        isWorkOrderTypeJob,
        location,
        openedInspectionJobIDs,
        selection: propsSelection,
        selectedJob,
        user
        //        workOrderUpdatedSuccess
    } = props;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const finalJobCheckModal = useSelector(jobFinalCheckModalOn);
    const outbox = useSelector(selectOutbox);
    const [buttonInAction, setButtonInAction] = React.useState<boolean>(false);
    const [state, setState] = React.useState<Istate>({
        selectedRow: {},
        selectedInstall: initialInstallBasePopulated,
        selectAll: false,
        showFilterSearch: false,
        isMenuOpen: false
    });
    React.useEffect(() => {
        if (propsSelection.length === 0) {
            setState(st => ({ ...st, selectAll: false }));
        }
    }, [propsSelection]);
    const dispatch = useDispatch();
    const currentTile: Itile = React.useMemo(() => constants.getTileByURL(location.pathname), [location.pathname]);

    let checkboxTable: any;
    let tableClassName = `beacon-table -highlight ${currentTile.color}`;
    // selectors
    const isAddWorkOrderMode = useSelector(selectIsWorkOrderMode);
    const isJobDownloadedTest = useSelector(selectOfflineStatusForJobTest);
    const filteredInstallBases = useSelector(filterInstallBases);
    const installBases = useSelector(selectInstallBasesByID);
    const products = useSelector(getProducts);

    const mobileFilterConfig = useSelector(selectFilterConfig);
    const isMobile = useSelector(getIsMobile);
    const shouldRefreshInventory = useSelector(getRefreshInventory);
    const setTableFilterDebounced = debounce<(formValues: { [key: string]: any }) => void>(
        props.setTableFilter,
        constants.formDebounceTime
    );
    const jobWorkOrders = useSelector(selectJobWorkOrdersForCurrentJob);
    const showLoading = useSelector(showManageInventoryLoading);

    const resetSelection = React.useCallback(() => {
        if (propsSelection.length > 0) {
            setState(st => ({ ...st, selectAll: false }));
            updateIBS([]);
        }
    }, [propsSelection, updateIBS]);

    React.useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            if (outbox && outbox.length > 0) {
                // This triggers the confirmation dialog
                const message =
                    'You have pending items to sync, please wait until they are complete or you may lose data.'; // Browsers won't actually use custom text it seems
                event.preventDefault();
                event.returnValue = message; // Required for some browsers to show the prompt
                return message;
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [outbox]);

    const searchFieldConfig: FieldConfig = React.useMemo(() => {
        const { search } = props.tableFilters;
        const disabled = false;
        const mainSearchControls = {
            search: {
                // TODO adding scope for this in a change request. waiting for approval.  Still need to update view to flow to two rows when on smaller width
                render: FormUtil.TextInputWithoutValidation,
                meta: {
                    label: 'search',
                    colWidth: 5,
                    colWidthLarge: 4,
                    type: 'text',
                    placeholder: t('manageInventory:tableFilters.searchPlaceholder')
                },
                formState: { value: search, disabled }
            }
        };

        return { controls: { ...mainSearchControls } };
    }, [props.tableFilters.search]);

    const hasVirtualProduct = (installBases: IinstallBasePopulated[]) => {
        const anyVirtualProducts = installBases?.some(installBase => {
            return installBase?.product?.subcategoryID === constants.virtualProductSubcategoryID;
        });

        return anyVirtualProducts;
    };

    const scrollTop = React.useCallback(() => {
        const tableDiv = document.getElementsByClassName('rt-tbody');

        if (tableDiv && tableDiv.length) {
            tableDiv[0].scrollTop = 0;
            upperWrap.current?.scrollIntoView();
        }
    }, []);

    const checkForEmptyRepairJob = React.useCallback(() => {
        const { jobTypeID } = selectedJob;
        if (jobTypeID === jobTypesIdEnum.repair) {
            const isEmpty = jobWorkOrders.length === 0 && filteredInstallBases.length === 0;
            let ibs: any = [];

            if (jobWorkOrders && jobWorkOrders.length > 0) {
                jobWorkOrders.forEach((jwo: any) => {
                    if (jwo && jwo.workOrder && jwo.workOrder.installBase) {
                        ibs.push(jwo.workOrder.installBase);
                    } else if (jwo && jwo.workOrder) {
                        // Convert this to IinstallBasePopulated so we can attach a product
                        const foundIB = installBases[jwo.workOrder.installBaseID] as IinstallBasePopulated;
                        if (foundIB) {
                            // Attach the product
                            foundIB.product = products[foundIB.productID];
                            ibs.push(foundIB);
                        }
                    }
                });
            }

            if (isEmpty) {
                history.push('/repair');
                toastr.warning(t('toastMessage:warning'), t('toastMessage:addAssetsToJob'), constants.toastrWarning);
            }
            // Check if any added work orders have a virtual product, if so, take user to the repair screen so they can added assets
            // Since virtuals are automatically filtered out now, filteredInstallBases might be empty, so check ibs as well
            else if (
                (filteredInstallBases.length === 1 && hasVirtualProduct(filteredInstallBases)) ||
                (ibs.length === 1 && hasVirtualProduct(ibs))
            ) {
                history.push('/repair');
                toastr.warning(t('toastMessage:warning'), t('toastMessage:addAssetsToJob'), constants.toastrWarning);
            }
        }
    }, [jobWorkOrders, filteredInstallBases, selectedJob, history, t]);

    const checkForEmptyServicePlanJob = React.useCallback(() => {
        const { jobTypeID } = selectedJob;
        const isEmpty = jobWorkOrders.length === 0 && filteredInstallBases.length === 0;

        if (jobTypeID === jobTypesIdEnum.servicePlan && isEmpty) {
            history.push('/repair');
            toastr.warning(t('toastMessage:warning'), t('toastMessage:addAssetsToJob'), constants.toastrWarning);
        }
    }, [jobWorkOrders, filteredInstallBases, selectedJob, history, t]);

    const checkForEmptyCommissioningJob = React.useCallback(() => {
        const { jobTypeID } = selectedJob;

        if (jobTypeID === jobTypesIdEnum.commissioning && filteredInstallBases.length === 0) {
            history.push('/repair');
            toastr.warning(t('toastMessage:warning'), t('toastMessage:emptyCommissioningJob'), constants.toastrWarning);
        }
    }, [filteredInstallBases, selectedJob, history, t]);

    const checkForEmptyMaintenanceJob = React.useCallback(() => {
        const { jobTypeID } = selectedJob;
        if (jobTypeID === jobTypesIdEnum.maintenance && filteredInstallBases.length === 0) {
            history.push('/maintenance');
            toastr.warning(t('toastMessage:warning'), t('toastMessage:emptyMaintenanceJob'), constants.toastrWarning);
        }
    }, [filteredInstallBases, selectedJob, history, t]);

    const checkForEmptyWarrantyJob = React.useCallback(() => {
        const { jobTypeID } = selectedJob;
        if (jobTypeID === jobTypesIdEnum.warrantyBM && filteredInstallBases.length === 0) {
            history.push('/warranty');
            toastr.warning(t('toastMessage:warning'), t('toastMessage:addAssetsToJob'), constants.toastrWarning);
        }
    }, [filteredInstallBases, selectedJob, history, t]);

    const checkForFirstTimeOpeningNewInspectionJob = React.useCallback(() => {
        const jobID = selectedJob.id;
        const shouldOpenJobDefaultsModal =
            selectedJob.jobTypeID === jobTypesIdEnum.inspection &&
            !openedInspectionJobIDs.includes(jobID) &&
            countriesWithJobDefaults.includes(props.facility.countryID);
        if (shouldOpenJobDefaultsModal) {
            dispatch({ type: types.SHOW_MODAL_EDIT_JOB_DEFAULTS });
            addOpInspJob(jobID);
        }
    }, [selectedJob, openedInspectionJobIDs, props.facility.countryID, addOpInspJob]);

    const handleUpdatedInstallBases = React.useCallback(() => {
        updateIBS([]);
    }, [updateIBS]);

    /**
     * Toggle a single checkbox for select table
     */
    const toggleSelection = (key: string, shift: boolean, row: any) => {
        // if (row && row.isVirtual && row.isVirtual === true) {
        //     return;
        // }

        // start off with the existing state
        let selection = [...propsSelection];
        const keyIndex = selection.indexOf(key);

        // check to see if the key exists
        if (keyIndex >= 0) {
            // it does exist so we will remove it using destructing
            selection = [...selection.slice(0, keyIndex), ...selection.slice(keyIndex + 1)];
        } else {
            // it does not exist so add it
            selection.push(key);
        }
        // reset the single selected install
        setState(st => ({ ...st, selectedInstall: initialInstallBasePopulated }));
        // update the state
        updateIBS(selection);
    };

    /**
     * Toggle all checkboxes for select table
     */
    const toggleAll = () => {
        const { keyField = 'id' } = props;

        const selection: string[] = [];
        const selectAll = !state.selectAll;
        if (selectAll && keyField !== undefined) {
            // we need to get at the internals of ReactTable
            const wrappedInstance = checkboxTable.getWrappedInstance();
            // the 'sortedData' property contains the currently accessible records based on the filter and sort
            const currentRecords: any[] = wrappedInstance.getResolvedState().sortedData;
            // we just push all the IDs onto the selection array
            currentRecords.forEach(item => {
                // Don't select any virtual products
                let virtualProduct = false;
                if (item._original.isVirtual && item._original.isVirtual === true) {
                    virtualProduct = true;
                }

                if (!virtualProduct) {
                    selection.push(`select-${item._original[keyField]}`);
                }
            });
        }
        setState(st => ({ ...st, selectAll }));
        updateIBS(selection);
    };

    /**
     * Whether or not a row is selected for select table
     */
    const isSelected = (key: string) => {
        return propsSelection.includes(`select-${key}`);
    };

    /*
     * Set Columns sets columns to state
     * setting columns here in order to reset them if and after we receive mainCategory and manufacturer options
     * The status column does not display when the job type is an Audit because there is no testing in an audit job
     */
    const columns = React.useMemo(() => {
        // eslint-disable-next-line no-shadow
        let columns: any[] = [];
        const mainColumns = [
            {
                Header: isMobile ? 'check all' : 'location',
                id: 'location',
                accessor: ({ locationString, isVirtual }: IinstallBasePopulated) => {
                    const cssStyle = isVirtual ? 'disabled-label' : '';
                    return (
                        <>
                            <span className={`${cssStyle} mobile-label`}>Loc</span>
                            <span className={cssStyle}>{locationString}</span>
                        </>
                    );
                },
                minWidth: 230,
                maxWidth: 280,
                sortMethod: (a: React.ReactElement, b: React.ReactElement) => {
                    const nodeA = a.props.children[1];
                    const nodeB = b.props.children[1];

                    return nodeA.props.children.localeCompare(nodeB.props.children);
                }
            },
            {
                Header: isMobile ? '' : 'name',
                accessor: ({
                    product: { name, description, sapMaterialNumber },
                    isVirtual,
                    sapEquipmentNumber
                }: IinstallBasePopulated) => {
                    const nameParts: string[] = [];
                    const cssStyle = isVirtual ? 'disabled-label' : '';
                    const cssLoading = name === '' ? 'loading-skele' : '';

                    nameParts.push(name);
                    if (sapMaterialNumber) {
                        nameParts.push(sapMaterialNumber);
                    }

                    if (description) {
                        nameParts.push(description);
                    }

                    const newName: string = nameParts.join(': ');
                    return (
                        <>
                            <span className={`${cssStyle} mobile-label`}>Name</span>
                            <span className={`${cssStyle} ${cssLoading} ${sapEquipmentNumber ? 'blue-bold' : ''}`}>
                                {newName}
                            </span>
                        </>
                    );
                },
                id: 'name',
                sortMethod: (a: React.ReactElement, b: React.ReactElement) => {
                    const nodeA = a.props.children[1];
                    const nodeB = b.props.children[1];

                    return nodeA.props.children.localeCompare(nodeB.props.children);
                }
            }
        ];
        const statusColumn = [
            {
                Header: 'status',
                id: 'status',
                accessor: (base: IinstallBasePopulated) => {
                    const { latestMeasurementPointListResult, workOrderResultStatus, isVirtual } = base;
                    const cssStyle = isVirtual ? 'disabled-label' : '';
                    let status = latestMeasurementPointListResult.status;

                    if (isWorkOrderTypeJob) {
                        return (
                            <>
                                <span className={`${cssStyle} mobile-label`}>Status</span>
                                <span
                                    className={`${cssStyle} status ${workOrderStatusEnum[workOrderResultStatus]} type-${workOrderResultStatus}`}
                                >{`${t('manageWorkOrder:' + workOrderStatusEnum[workOrderResultStatus])}`}</span>
                            </>
                        );
                    }

                    // These statuses are a nightmare...even though we are expecting latestMeasurementPointListResult to have the real thing, sometimes it doesn't...
                    if (
                        base.latestMeasurementPointListResultStatus &&
                        status !== base.latestMeasurementPointListResultStatus
                    ) {
                        status = base.latestMeasurementPointListResultStatus;
                    }

                    return (
                        <>
                            <span className={`${cssStyle} mobile-label`}>Status</span>
                            <span
                                className={`${cssStyle} status ${measurementPointResultStatusTypesEnum[status]} type-${status}`}
                            >{`${t(
                                'manageMeasurementPointLists:' + measurementPointResultStatusTypesEnum[status]
                            )}`}</span>
                        </>
                    );
                },
                minWidth: 60,
                sortMethod: (a: React.ReactElement, b: React.ReactElement) => {
                    const nodeA = a.props.children[1];
                    const nodeB = b.props.children[1];
                    return nodeA.props.children.localeCompare(nodeB.props.children);
                }
            }
        ];
        // when repairing, users want to see which asset has failed
        const repairStatusColumn = [
            {
                Header: isMobile ? '' : 'status',
                id: 'status',
                accessor: ({ originalMeasurementPointListResultStatus, isVirtual }: IinstallBasePopulated) => {
                    const cssStyle = isVirtual ? 'disabled-label' : '';
                    return (
                        <>
                            <span className={`${cssStyle} mobile-label`}>Status</span>
                            <span
                                className={`${cssStyle} status ${measurementPointResultStatusTypesEnum[originalMeasurementPointListResultStatus]} type-${originalMeasurementPointListResultStatus}`}
                            >{`${t(
                                'manageMeasurementPointLists:' +
                                    measurementPointResultStatusTypesEnum[originalMeasurementPointListResultStatus]
                            )}`}</span>
                        </>
                    );
                },
                minWidth: 60,
                sortMethod: (a: React.ReactElement, b: React.ReactElement) => {
                    const nodeA = a.props.children[1];
                    const nodeB = b.props.children[1];
                    return nodeA.props.children.localeCompare(nodeB.props.children);
                }
            }
        ];

        if (isRepairTypeJob === true && isAddWorkOrderMode === true) {
            columns = TableUtil.translateHeaders([...mainColumns, ...repairStatusColumn], t);
        } else if (isAddWorkOrderMode === true) {
            columns = TableUtil.translateHeaders(mainColumns, t);
        } else {
            columns = TableUtil.translateHeaders([...mainColumns, ...statusColumn], t);
        }

        return columns;
    }, [isRepairTypeJob, isCommissioningTypeJob, isMobile, isWorkOrderTypeJob, isAddWorkOrderMode, t]);

    /*
     * Handle user clicking on a product row
     * set the selected product to state and open the modal
     */
    const getTrProps = (finalState: any, rowInfo?: RowInfo, column?: undefined, instance?: any) => {
        if (rowInfo) {
            return {
                onClick: (e: React.MouseEvent<HTMLFormElement>) => {
                    if (!buttonInAction) {
                        // Do not allow virtual products to be opened
                        const selectedInstall: IinstallBasePopulated = rowInfo.original;
                        if (selectedInstall.isVirtual) {
                            return;
                        }

                        // If we have historical MP to copy, but it doesn't match the current install base, clear that history. This can happen if you copy history,
                        // then go to a new asset, you'll end up with the history copied to the wrong asset.
                        dispatch({ type: 'CLEAR_HISTORICAL_MP_TO_COPY' });

                        // If you're on a repair job, selecting assets to add to the job, don't navigate to device screen, toggle the selection instead
                        if (
                            (props.selectedJob.jobTypeID === jobTypesIdEnum.repair ||
                                props.selectedJob.jobTypeID === jobTypesIdEnum.servicePlan ||
                                props.selectedJob.jobTypeID === jobTypesIdEnum.commissioning) &&
                            history.location.pathname === '/repair'
                        ) {
                            toggleSelection(`select-${rowInfo.original.id}`, false, null);
                        } else if (
                            props.selectedJob.jobTypeID === jobTypesIdEnum.warrantyBM &&
                            history.location.pathname === '/warranty'
                        ) {
                            toggleSelection(`select-${rowInfo.original.id}`, false, null);
                        } else {
                            history.push(`/devices/${rowInfo.original.id}`);
                        }
                    }
                },
                style: {
                    background: state.selectedRow[rowInfo.viewIndex]
                        ? constants.colors[`${currentTile.color}Tr` as keyof typeof constants.colors]
                        : ''
                }
            };
        }

        return {};
    };

    const onSortedChanged = (newSorted: SortingRule[], column: any, shiftKey: boolean) => {
        props.setTableFilter({ sorted: newSorted });
        setState(st => ({ ...st, selectedRow: {} }));
    };

    const canEditInstalls = React.useCallback(() => {
        const manageInventory =
            constants.hasSecurityFunction(user, constants.securityFunctions.ManageInventory.id) ||
            constants.hasSecurityFunction(user, constants.securityFunctions.FSE.id);
        if (!manageInventory) {
            toastr.warning(
                'Warning',
                'user does not have permission to edit devices.  Please contact support',
                constants.toastrWarning
            );
        }
    }, [user]);

    const onPageChange = (page: number) => {
        props.setTableFilter({ page });
    };

    const onPageSizeChange = (rows: number) => {
        props.setTableFilter({ rows, page: 0 });
    };

    const setBannerTitle = (isWorkOrderMode: boolean, jobTypeID: string) => {
        if (isWorkOrderMode && (jobTypeID === jobTypesIdEnum.repair || jobTypeID === jobTypesIdEnum.servicePlan)) {
            return t('bannerTitleMobileWorkOrderMode');
        } else if (isWorkOrderMode && jobTypeID === jobTypesIdEnum.warrantyBM) {
            return t('manageInventory:warranty');
        } else if (jobTypeID === jobTypesIdEnum.repair || jobTypeID === jobTypesIdEnum.servicePlan) {
            return t('bannerTitleRepairs');
        } else {
            return t('bannerTitleMobile');
        }
    };

    const initialize = () => {
        checkForEmptyRepairJob();
        checkForEmptyServicePlanJob();
        checkForEmptyCommissioningJob();
        checkForEmptyMaintenanceJob();
        checkForEmptyWarrantyJob();
        checkForFirstTimeOpeningNewInspectionJob();
    };

    const onSearchValueChanges = React.useCallback(
        (value: any, key: string) => {
            switch (key) {
                case 'startDate': {
                    const startDateMoment = moment.isMoment(value) ? value : moment(value);
                    setTableFilterDebounced({
                        startDate: startDateMoment.toISOString(),
                        page: 0
                    });

                    break;
                }
                default:
                    setTableFilterDebounced({ [key]: value, page: 0 });
            }
        },
        [setTableFilterDebounced]
    );

    if (props.showAdditionalFilters) {
        tableClassName = tableClassName + ' additional-filters';
    }

    if (isAddWorkOrderMode) {
        tableClassName = tableClassName + ' adding-workorder';
    }

    const colorButton = constants.colors[`${currentTile.color}Button` as keyof typeof constants.colors];
    const jobType: string = t(jobTypesIdEnumInverse[selectedJob.jobTypeID] + 'Job');
    // TODO Turn this into a selector
    const bannerTitle = setBannerTitle(isAddWorkOrderMode, selectedJob.jobTypeID);
    const bannerSubSubTitle = isAddWorkOrderMode === true ? '' : `${jobType}: ${selectedJob.jobNumber}`;

    const tableData: IinstallBasePopulated[] = React.useMemo(() => {
        if (isJobDownloadedTest) {
            // If Job is Inspection or AGSS, don't show Virtual Products unless the Install base has a work order from SAP
            if (
                props.selectedJob.jobTypeID === jobTypesIdEnum.inspection ||
                props.selectedJob.jobTypeID === jobTypesIdEnum.agsRebalancing
            ) {
                return filteredInstallBases.filter((installBase: IinstallBasePopulated) => {
                    if (
                        installBase.product &&
                        installBase.product.subcategoryID === constants.virtualProductSubcategoryID
                    ) {
                        if (installBase.workOrders && installBase.workOrders.length > 0) {
                            if (
                                installBase.workOrders.some(
                                    (workOrder: IWorkOrder) => workOrder.source === WorkOrderSource.SAP
                                )
                            ) {
                                return true;
                            }
                        }
                        return false;
                    } else {
                        return true;
                    }
                });
            } else {
                return filteredInstallBases;
            }
        }
        return [];
    }, [filteredInstallBases, isJobDownloadedTest]);

    // React.useEffect(() => {
    //     if (workOrderUpdatedSuccess === '') {
    //         return;
    //     }

    //     console.log('workOrderUpdatedSuccess', workOrderUpdatedSuccess);
    //     dispatch(getInventory(selectedJob.facilityID));
    // }, [workOrderUpdatedSuccess]);

    React.useEffect(() => {
        resetSelection();
        scrollTop();
    }, [isAddWorkOrderMode]);

    React.useEffect(() => {
        if (prevProps?.current?.showEditInstallModal !== props.showEditInstallModal && !props.showEditInstallModal) {
            props.setSelectedProduct();
            setState(st => ({ ...st, selectedInstall: initialInstallBasePopulated }));
        }

        // scroll top and reset selection every time a filter changes
        if (JSON.stringify(prevProps?.current?.tableFilters) !== JSON.stringify(props.tableFilters)) {
            scrollTop();
        }

        if (props.isJobDownloaded === true && prevProps?.current?.isJobDownloaded === false) {
            initialize();
            toastr.success(t('toastMessage:success'), t('toastMessage:jobDownloadSuccess'), {
                ...constants.toastrSuccess,
                timeOut: 5000
            });
        }

        prevProps.current = props;
    }, [props.showEditInstallModal, props.tableFilters, props.isJobDownloaded, t]);

    React.useEffect(() => {
        document.addEventListener('updatedInstallBases', handleUpdatedInstallBases, false);

        //dispatch(getContactsByFacility(selectedJob.facilityID));
        dispatch(setJobFinalCheckMoal(false));
        updateIBS([]);
        canEditInstalls();
        //dispatch(getSAPWorkOrders());
        return () => {
            document.removeEventListener('updatedInstallBases', handleUpdatedInstallBases, false);
        };
    }, []);

    // React.useEffect(() => {
    //     if (isJobDownloaded === true) {
    //         initialize();
    //         dispatch(setIsDownloadingJob(false));
    //     }
    // }, [isJobDownloaded]);

    // After bulk action is complete, refresh inventory
    React.useEffect(() => {
        if (shouldRefreshInventory !== '') {
            dispatch(getInventory(selectedJob.facilityID));
            let toastSuccess = 'toastMessage:mobileBulkPassResultsSuccessMessage';
            toastr.success(t('success'), t(toastSuccess), constants.toastrSuccess);

            // Reset the flag to show the bulk action was completed
            dispatch({
                type: types.UPDATE_MEASUREMENT_POINT_RESULT_BULK_SUCCESS,
                meta: { id: '' }
            });
        }
    }, [shouldRefreshInventory]);

    if (props.productInfo.mainCategoryOptions.length === 0) {
        return (
            <Col xs={12}>
                <h4> loading... </h4>
            </Col>
        );
    }

    // return home if there is no job or the job has been completed
    if (!selectedJob.id.length || selectedJob.status === jobStatusEnum.completed) {
        return (
            <Redirect
                to={{
                    pathname: '/jobs'
                }}
            />
        );
    }

    // handle onMenuToggle callback
    const onMenuToggle = (isOpen: boolean) => {
        setState(st => ({ ...st, isMenuOpen: isOpen }));
    };

    return (
        <div
            className="manage-inventory"
            data-location={location.pathname.replace('/', '')}
            data-btn="true"
            data-manage="true"
            data-empty={tableData.length === 0}
        >
            {/* <Loading
                show={!props.isJobDownloaded}
                message={'Please wait...  loading hospital data'}
                downloadSpeed={undefined}
            /> */}

            <Loading show={showLoading} message={'Please wait...  preparing data'} downloadSpeed={undefined} />

            <div ref={upperWrap} className="upper-wrap" data-menuopen={state.isMenuOpen}>
                <Banner
                    title={bannerTitle}
                    img={currentTile.srcBanner}
                    color={currentTile.color}
                    subtitle={props.facility.name}
                    subsubtitle={bannerSubSubTitle}
                />
                <SearchInventoryTableContainer
                    facility={props.facility}
                    colorButton={colorButton}
                    installBasesPopulated={tableData}
                    history={history}
                    location={location}
                    match={props.match}
                    resetSelection={resetSelection}
                />

                {isMobile && (
                    <Row className="search-form-container">
                        {searchFieldConfig && (
                            <Col xs={10}>
                                <SearchTableForm
                                    fieldConfig={searchFieldConfig}
                                    handleSubmit={() => {
                                        return;
                                    }}
                                    loading={props.loading}
                                    colorButton={
                                        constants.colors[`${currentTile.color}Button` as keyof typeof constants.colors]
                                    }
                                    t={props.t}
                                    subscribeValueChanges={true}
                                    onValueChanges={onSearchValueChanges}
                                    showSearchButton={false}
                                />
                            </Col>
                        )}

                        <Col className="search-actions-container" xs={2}>
                            <Button
                                disabled={props.loading}
                                className="mng-job-filter"
                                onClick={() => {
                                    setState(currSt => ({ ...currSt, showFilterSearch: true }));
                                }}
                            >
                                <FontAwesomeIcon icon={['fas', 'filter']} size="xs" />
                                {t('filterSearch')}
                            </Button>

                            {props.isAddWorkOrderMode === false && (
                                <InventoryActionButtonContainer
                                    t={props.t}
                                    facility={props.facility}
                                    history={props.history}
                                    location={props.location}
                                    match={props.match}
                                    installBasesPopulated={tableData}
                                    colorButton={'blue'}
                                    resetSelection={() => {
                                        return;
                                    }}
                                    onMenuToggle={onMenuToggle}
                                />
                            )}

                            {props.isAddWorkOrderMode === true && (
                                <Button
                                    onClick={props.toggleConfirmSelectJobModal}
                                    disabled={props.selection.length === 0}
                                    className="add-assets-btn-mobile"
                                    title={props.selection.length > 0 ? 'add' : 'must select at least one'}
                                >
                                    {props.t('addSelectedAssetToJob')}
                                </Button>
                            )}
                        </Col>
                    </Row>
                )}
            </div>
            <Row id="table-wrap">
                <Col xs={12}>
                    {
                        <SelectTable
                            data={tableData}
                            columns={columns}
                            showPagination={tableData?.length >= 9}
                            getTrProps={getTrProps}
                            defaultPageSize={props.tableFilters.rows || constants.tablePageSizeDefault}
                            onPageSizeChange={onPageSizeChange}
                            showPageSizeOptions={true}
                            pageSizeOptions={constants.tablePageSizeOptions}
                            className={tableClassName}
                            previousText={t('common:previous')}
                            nextText={t('common:next')}
                            onSortedChange={onSortedChanged}
                            sortable={true}
                            multiSort={false}
                            noDataText={t(props.noDataText)}
                            resizable={false}
                            onPageChange={onPageChange}
                            page={props.tableFilters.page}
                            toggleSelection={toggleSelection}
                            selectAll={state.selectAll}
                            selectType="checkbox"
                            toggleAll={toggleAll}
                            isSelected={isSelected}
                            ref={r => (checkboxTable = r)}
                            keyField="id"
                            loading={props.loading}
                        />
                    }
                </Col>
            </Row>
            <EditProductModal colorButton={colorButton} selectedItem={props.selectedProduct} />

            <EditInstallModal
                selectedItem={state.selectedInstall}
                colorButton={colorButton}
                history={history}
                location={location}
                match={props.match}
            />

            {finalJobCheckModal && <JobFinalCheckModal t={props.t} />}

            <SignatureModal colorButton={colorButton} installBasesPopulated={tableData} />
            <CommissioningDataSignatureModal
                colorButton={constants.colors[`${currentTile.color}Button` as keyof typeof constants.colors]}
                selectedJob={selectedJob}
                installBasesPopulated={tableData}
            />
            <NoteModal colorButton="primary" filteredInstallBases={tableData} />
            <SearchNewProductsModal colorButton={colorButton} selectedItem={props.selectedProduct} />
            <WorkOrderCloseModal colorButton={colorButton} installBasesPopulated={tableData} />
            <EditJobModal colorButton={colorButton} selectedFacilityID={props.facility.id} />

            <AddHoursModalContainer colorButton={colorButton} wideModal={true} />

            <EditJobDefaultsModal colorButton={colorButton} selectedJobID={selectedJob.id} />
            <CommissioningDataModal colorButton={colorButton} selectedJobID={selectedJob.id} />
            <ConfirmSelectJobModal
                facilityID={selectedJob.facilityID}
                history={history}
                location={location}
                match={props.match}
                selectedInstallID={state.selectedInstall.id}
                forSAP={false}
            />
            <AddFSEModal selectedJob={selectedJob as IjobPopulated} t={props.t} />
            <EditFacilityContactsModal t={props.t} />
            {mobileFilterConfig && searchFieldConfig !== undefined && (
                <FilterAssetTableModal
                    show={state.showFilterSearch}
                    closeFilterAssetModal={() => {
                        setState(currSt => ({ ...currSt, showFilterSearch: false }));
                    }}
                    bgColor="#3BB9EA"
                    brColor="#007BC3"
                    primaryColor="#007AC3"
                    setTableFilter={props.setTableFilter}
                    tableFilters={props.tableFilters}
                    clearTableFilter={clearTableFilter}
                    buildings={props.facility.buildings}
                    selectedJob={selectedJob}
                    productInfo={props.productInfo}
                    isWorkOrderTypeJob={isWorkOrderTypeJob}
                    isJobDownloaded={isJobDownloaded}
                    isAddWorkOrderMode={props.isAddWorkOrderMode}
                ></FilterAssetTableModal>
            )}
        </div>
    );
};

/*
 * AddCustomerModal will connect to redux, impliment CommonModal, as well as AddCustomerForm
 */

const makeMapStateToProps = () => {
    const mapStateToProps = (state: IinitialState, ownProps: Iprops) => {
        const selectedJob = state.manageJob.jobsByID[state.manageJob.selectedJob.id] || initialJob;
        const {
            facilities,
            manageInventory,
            syncStatus: { lastSync }
        } = state;
        const { openedInspectionJobIDs } = state.manageJob;
        const selectedFacility = facilities.facilitiesByID[selectedJob.facilityID] || initialFacility;
        const isJobDownloaded = selectOfflineStatusForJob(state, {
            job: selectedJob
        });

        const isWorkOrderTypeJob = getIsWorkOrderTypeJob(state);
        const isCommissioningTypeJob = getIsCommissioningTypeJob(state);
        const isMaintenanceTypeJob = getIsMaintenanceTypeJob(state);
        const isAddWorkOrderMode = getIsAddWorkOrderMode(state);
        // TODO Refactor into selector

        const noDataText = selectInventoryNoDataText(state);
        const isRepairTypeJob = selectedJob.jobTypeID === jobTypesIdEnum.repair;

        return {
            user: state.user,
            loading: selectIsLoading(state),
            lastSync,
            openedInspectionJobIDs,
            //workOrderUpdatedSuccess: getWorkOrderUpdatedSuccess(state),
            showNotePadModal: manageInventory.showNoteModal,
            showEditProductModal: manageInventory.showEditProductModal,
            showEditInstallModal: manageInventory.showEditInstallModal,
            showSignatureModal: manageInventory.showSignatureModal,
            showJobDefaultsModal: manageInventory.showEditJobDefaultsModal,
            showSearchNewProductsModal: manageInventory.showSearchNewProductsModal,
            facilityOptions: FormUtil.convertToOptions(state.user.facilities),
            productInfo: state.productInfo,
            tableFilters: manageInventory.tableFilters,
            selectedProduct: manageInventory.selectedProduct,
            selectedJob: state.manageJob.selectedJob,
            facility: selectedFacility,
            selection: manageInventory.selection,
            enableBulkInstallBaseMode: manageInventory.enableBulkInstallBaseMode,
            showAdditionalFilters: manageInventory.tableFilters.showAdditionalFilters,
            isWorkOrderTypeJob,
            isMaintenanceTypeJob,
            isAddWorkOrderMode,
            noDataText,
            isRepairTypeJob,
            isCommissioningTypeJob,
            isJobDownloaded
        };
    };

    return mapStateToProps;
};

export default withTranslation('manageInventory')(
    connect(makeMapStateToProps, {
        toggleCommissioningDataFormModal,
        toggleEditInstallModal,
        toggleAddRepairWorkOrderModal,
        toggleConfirmSelectJobModal,
        toggleModalSignaturePad,
        toggleModalNotePad,
        toggleEditJobDefaultsModal,
        addOpenedInspectionJob,
        setTableFilter,
        setSelectedProduct,
        addWorkOrdersToJob,
        updateJob,
        updateInstallBaseSelection,
        setSelectedWorkOrderID,
        bulkCannotCompleteSelected,
        getCommissioningInstallBases,
        getMeasurementPointListResultsForJobs,
        getContactsByFacility
    })(ManageInventory)
);
