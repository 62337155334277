/*
 * Common Modal displays the modal
 */

import * as React from 'react';
import CommonModal, { IPropsCommonModal } from './CommonModal';

export const WithCommonModal = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
    const commonModalWrap: React.FC<P & IPropsCommonModal> = props => (
        <CommonModal {...props}>
            <WrappedComponent {...props} />
        </CommonModal>
    );

    return commonModalWrap;
};
// React.Component<P & IPropsCommonModal>
