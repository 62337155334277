import * as React from 'react';

import { IWorkOrder } from '../../models';
import { ListGroup, ListGroupItem, Col } from 'react-bootstrap';

interface Iprops {
    selectedWorkOrder: IWorkOrder;
}

export const MaintenanceChecklist = ({ selectedWorkOrder }: Iprops) => {
    if (
        !selectedWorkOrder.preventativeMaintenanceChecklist ||
        (selectedWorkOrder.preventativeMaintenanceChecklist &&
            selectedWorkOrder.preventativeMaintenanceChecklist.preventativeMaintenancePoints.length === 0)
    ) {
        return null;
    }
    return (
        <ListGroup style={{ marginTop: '15px' }} className="checklist">
            {selectedWorkOrder.preventativeMaintenanceChecklist.preventativeMaintenancePoints.map(
                (checklist, index) => {
                    return (
                        <Col xs={6} key={checklist.id}>
                            <ListGroupItem>
                                {index + 1}. {checklist.label}
                            </ListGroupItem>
                        </Col>
                    );
                }
            )}
        </ListGroup>
    );
};
