/**
 * Checks if the given value is an integer.
 * @function
 *
 * @param {string} val - possible integer
 * @returns {boolean} - true if value is integer
 */
export const isInteger = (val: string): boolean => {
    if (isNaN((val as unknown) as number)) {
        return false;
    }

    let x = parseFloat(val);

    return (x | 0) === x;
};

/**
 * Checks if the given value is a float.
 * @function
 *
 * @param val - possible float
 * @returns {boolean} - true if value is float
 */
export const isFloat = (val: string): boolean => {
    let newVal: any = val;
    newVal = parseFloat(val);

    return !isNaN(newVal);
};

/**
 * Checks if the given value is a positive number.
 * @function
 *
 * @param val - possible positive number
 * @returns {boolean} - true if value is positive number
 */
export const isPositiveNumber = (val: string): boolean => Number.isInteger(Number(val)) && Number(val) > 0;
