/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { filter, omit } from 'lodash';
import { CLEAR_CART } from '../actions/actionTypes';
import { Ijob, IquotePartCart, IshoppingCart, IshoppingCartItem } from '../models';
import initialState from './initialState';

/**
 * SELECTORS
 */
export const getQuantity = (state: IshoppingCart, itemID: string): number => state.itemsByID[itemID].quantity || 0;

export const getAddedIDs = (state: IshoppingCart) => state.addedIDs;

export const getRequestTableData = (cart: IquotePartCart, installID: string, addedIDs: string[]) => {
    const { quoteItems } = cart;
    const tableData: any = [];
    quoteItems.forEach(item => {
        if (item.installBaseID === installID && addedIDs.includes(item.id) && item.isDeleted === false) {
            tableData.push(item);
        }
    });
    return tableData;
};

export const getTotal = (state: IshoppingCart) => {
    let total = 0;
    if (state.addedIDs.length === 0) {
        return -1;
    } else {
        state.addedIDs.forEach((item: string) => {
            let itemQuantity = 1;
            if (state.itemsByID[item].quantity) {
                itemQuantity = state.itemsByID[item].quantity!;
            }
            total = total + Number(itemQuantity);
        });
    }
    return total;
};

/**
 * Reducers
 */

const addedIds = (state: string[] = initialState.cart.addedIDs, action: any, cartName: string) => {
    switch (action.type) {
        case `ADD_TO_CART_${cartName}`:
            if (state.indexOf(action.item.id) !== -1) {
                return state;
            }
            return [...state, action.item.id];
        case `DELETE_FROM_CART_${cartName}`:
            if (state.indexOf(action.itemID) === -1) {
                return state;
            }
            return filter(state, item => item !== action.itemID);
        case CLEAR_CART: {
            return [];
        }
        default:
            return state;
    }
};

const itemsByID = (
    state: { [key: string]: IshoppingCartItem } = initialState.cart.itemsByID,
    action: any,
    cartName: string
) => {
    switch (action.type) {
        case `ADD_TO_CART_${cartName}`: {
            const { item } = action;
            let tempQuantity = 1;

            if (item.quantity && item.quantity !== undefined) {
                tempQuantity = parseInt(item.quantity);
            }
            return {
                ...state,
                [item.id]: {
                    ...item,
                    quantity: tempQuantity
                }
            };
        }
        case `DECREASE_FROM_CART_${cartName}`:
            let tempQuantity = 1;

            if (
                state[action.item.id] &&
                state[action.item.id].quantity &&
                state[action.item.id].quantity !== undefined
            ) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                tempQuantity = state[action.item.id].quantity!;
            }

            tempQuantity -= 1;

            return {
                ...state,
                [action.itemID]: {
                    ...state[action.itemID],
                    quantity: tempQuantity
                }
            };
        case `UPDATE_QUANTITY_CART_${cartName}`:
            return {
                ...state,
                [action.itemID]: {
                    ...state[action.itemID],
                    quantity: state[action.itemID] ? action.quantity : 1
                }
            };
        case `DELETE_FROM_CART_${cartName}`:
            return omit(state, [action.itemID]);
        case CLEAR_CART: {
            return [];
        }
        default:
            return state;
    }
};

const messageReducer = (state: string = initialState.cart.message, action: any, cartName: string) => {
    switch (action.type) {
        case `UPDATE_MESSAGE_CART_${cartName}`:
            return action.message;
        default:
            return state;
    }
};

/*
 * main cart reducer
 */
export const cartReducerWithName = (cartName: string) => {
    return (state = initialState.cart, action: any) => {
        switch (action.type) {
            // case `CHECKOUT_${cartName}_SUCCESS`:
            // return initialState.cart;
            case `CHECKOUT_${cartName}_FAILED`:
                return state;
            case `SET_CART_${cartName}`:
                return action.cart;
            case `CLEAR_CART_${cartName}`:
                return [];
            default:
                return {
                    addedIDs: addedIds(state.addedIDs, action, cartName),
                    itemsByID: itemsByID(state.itemsByID, action, cartName),
                    message: messageReducer(state.message, action, cartName)
                };
        }
    };
};
