import * as types from './actionTypes';
import moment from 'moment';
import {
    IWorkOrder,
    IinstallBasePopulated,
    ImeasurementPointAnswer,
    ImeasurementPointResult,
    ThunkResult,
    ImeasurementPointResultWithType,
    IdamageCode
} from '../models';
import { filter, map, find } from 'lodash';
import { jobTypesIdEnum, measurementPointResultStatusTypesEnum } from '../models-enums';

import API from '../constants/apiEndpoints';
import { TFunction } from 'i18next';
import { completeWorkOrdersHelper } from './workOrderActions';
import { constants } from '../constants/constants';
import { getIsWorkOrderTypeJob } from '../reducers/manageJobReducer';
import { selectMeasurementPointListForInstallBase } from '../reducers/measurementPointListsReducer';
import { selectWorkOrdersByInstallBaseID } from '../reducers/workOrderReducer';
import { toastr } from 'react-redux-toastr';
import { getMeasurementPointListResultsForJobsHelper } from './workerActions';
import {
    initInstallBaseResult,
    prepareMeasurementPointListResults,
    validateNewResult
} from './measurementPointResultUtil';
// import { latestResults } from '../reducers/manageInventoryReducer';
import { selectInstallBaseHistoryByInstallBaseID } from '../reducers/commonReducers';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { beginAjaxCall } from './ajaxStatusActions';
import { filterInstallBases } from '../reducers/manageInventoryReducer';
import { msalFetch } from '../components/auth/Auth-Utils';
const uuidv4 = require('uuid/v4');

export function updateMeasurementPointResult(
    result: ImeasurementPointResult,
    historicalResult: boolean
): ThunkResult<any> {
    return dispatch => {
        dispatch({
            type: types.UPDATE_MEASUREMENT_POINT_RESULT,
            result
        });

        if (historicalResult) {
            dispatch({
                type: types.SET_HISTORICAL_RESULT_ID,
                resultID: result.id
            });
        }
    };
}

export function getPagedMPLRForJob(jobID: string, facilityID: string, page: number): ThunkResult<any> {
    return (dispatch, getState) => {
        let startTime: number;
        let lastLoaded = 0;
        let packetCount = 0;

        const root = process.env.REACT_APP_SERVER_DOMAIN;
        const batch = 500;
        const url = `${root}/MeasurementPoint/GetPagedMeasurementPointListResultsForJob?JobID=${jobID}&page=${page}&perPage=${batch}`;

        let axiosOptions: AxiosRequestConfig = {
            method: 'get',
            timeout: 1000 * 60 * 5,
            onDownloadProgress: progressEvent => {
                const currentTime = new Date().getTime();
                if (!startTime) {
                    startTime = currentTime;
                }
                const timeElapsedInSeconds = (currentTime - startTime) / 1000;
                const loadedBits = progressEvent.loaded * 8 - lastLoaded;
                const speedInBps = loadedBits / timeElapsedInSeconds;
                const speedInMbps = speedInBps / 1_000_000;

                if (packetCount % 10 === 0) {
                    dispatch({ type: types.SET_DOWNLOAD_SPEED, downloadSpeed: speedInMbps.toFixed(2) });
                }

                packetCount++;
                lastLoaded = progressEvent.loaded;
            }
        };

        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (!data.data) {
                    throw Error('Failed to get MPLR for Job');
                } else {
                    dispatch({
                        type: types.GET_MEASUREMENT_POINT_FACILITY_RESULTS_SUCCESS,
                        results: data.data,
                        facilityID,
                        updateDate: moment().unix()
                    });
                }
            })
            .catch((error: any) => {
                console.error('getPagedMPLRForJob', error);
            });
    };
}

export function saveDamageCode(result: IdamageCode, installBaseID: string): ThunkResult<any> {
    return dispatch => {
        dispatch({
            type: types.SAVE_DAMAGE_CODES_SUCCESS,
            result,
            installBaseID
        });
    };
}

export function getMeasurementPointListResultsForJobs(jobId: string, facilityID: string): ThunkResult<any> {
    return dispatch => {
        dispatch(getMeasurementPointListResultsForJobsHelper(jobId, facilityID));
    };
}

/*
 * If we are in Historical mode, load the historical Result.  Otherwise:
 * 1) load the result that has been edited (temporary)
 * 2) load a previous result we use to copy to a new result
 * 3) create a completely new result
 */
export const initSelectedResult = (installID: string): ThunkResult<any> => {
    return (dispatch, getState) => {
        const { selectedJob } = getState().manageJob;
        const { historicalResultID, measurementPointResultsByID } = getState().measurementPointResults;
        const filteredMPR = Object.values(measurementPointResultsByID).filter(
            result => result.jobID === selectedJob.id
        );

        if (historicalResultID.length) {
            const historicalResult = filteredMPR.find(result => result.id === historicalResultID);

            if (historicalResult) {
                return dispatch(updateMeasurementPointResult(historicalResult, true));
            }
        } else {
            dispatch(updateMeasurementPointResult(initInstallBaseResult(getState, { installID }), false));
        }
    };
};

export const getMeasurementPointListResultHistory = (installBaseId: string, jobTypeId: string): ThunkResult<any> => {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const url = `${API.GET.measurementPoint.getMeasurementPointListResultHistory}?installBaseId=${installBaseId}&jobTypeId=${jobTypeId}`;
        const axiosOptions: AxiosRequestConfig = {
            url,
            method: 'get'
        };

        dispatch({
            type: types.GET_MEASUREMENT_POINT_RESULT_HISTORY,
            meta: {
                offline: {
                    effect: { axiosOptions, message: 'Get MPLR History' },
                    commit: {
                        type: types.SET_MEASUREMENT_POINT_RESULT_HISTORY
                    }
                }
            }
        });
    };
};

const submitMeasurementPointResultHelper = (
    updatedResults: ImeasurementPointResult[],
    isBulk: boolean = false
): ThunkResult<any> => {
    return (dispatch, getState) => {
        // we can send slimmed down work order objects;
        let results = prepareMeasurementPointListResults(updatedResults);
        const jobTypeID = getState().manageJob.selectedJob.jobTypeID;

        // Damage codes
        for (let i = 0; i < results.length; i++) {
            // Look for any damage codes for this install base id
            const installBaseID = results[i].installBaseID;
            const damageCodeIndex = Object.keys(getState().manageInventory.damageCodes).indexOf(installBaseID);
            let damageCode: any = null;
            if (damageCodeIndex > -1) {
                damageCode = Object.values(getState().manageInventory.damageCodes)[damageCodeIndex];
            }

            results[i].DamageCode = damageCode;
        }

        const batchAmount = 25;

        for (let i = 0; i < results.length; i += batchAmount) {
            const batch = results.slice(i, i + batchAmount);
            const lastRun = batch.length < batchAmount;

            const measurementPointResultsForRollback = map(batch, measurementPointResult => ({
                ...measurementPointResult,
                isDeleted: true
            }));

            // TODO clean the results we send to the API
            const axiosOptions = {
                url: API.POST.measurementPoint.addResults,
                method: 'post',
                data: batch,
                timeout: 600_000
            };

            dispatch({
                type: types.UPDATE_MEASUREMENT_POINT_RESULT_BULK,
                results: batch,
                jobTypeID,
                meta: {
                    offline: {
                        effect: { axiosOptions, message: 'Saving Answers' },
                        rollback: {
                            type: types.UPDATE_MEASUREMENT_POINT_RESULT_BULK,
                            results: measurementPointResultsForRollback,
                            jobTypeID
                        },
                        // if it's the last run of the batch, need to update the inventory, creating a random guid to make sure the useEffect fires each time
                        commit:
                            lastRun && isBulk
                                ? { type: types.UPDATE_MEASUREMENT_POINT_RESULT_BULK_SUCCESS, meta: { id: uuidv4() } }
                                : {}
                    }
                }
            });
        }

        // Clear the selection of the table
        dispatch({
            type: types.INSTALL_UPDATE_SELECTION,
            payload: []
        });
    };
};

export function submitMeasurementPointResult(
    updatedResult: ImeasurementPointResult,
    updatedAnswers: ImeasurementPointAnswer[],
    selectedWorkOrders: IWorkOrder[]
): ThunkResult<any> {
    return (dispatch, getState) => {
        const combinedAnswers = map(updatedAnswers, answer => {
            const originalAnswer = find(updatedResult.measurementPointAnswers, {
                measurementPointID: answer.measurementPointID
            });
            return { ...originalAnswer, ...answer };
        });
        const updatedResultWithAnswers = {
            ...updatedResult,
            measurementPointAnswers: combinedAnswers
        };
        // double check that the installBase exists
        const { manageInventory } = getState();

        const installBase = manageInventory.installBasesByID[updatedResultWithAnswers.installBaseID];

        if (!installBase) {
            console.error('[submitMeasurementPointResult]:', updatedResultWithAnswers);
            throw new Error('Error, missing asset. Please contact support.');
        }

        dispatch(submitMeasurementPointResultHelper([updatedResultWithAnswers]));

        if (selectedWorkOrders.length) {
            dispatch(
                completeWorkOrdersHelper(selectedWorkOrders, 'activeClosingNotes', 'closingNotes', 'closingNotesList')
            );
        }
    };
}

export function updateMprAnswers(updatedAnswers: ImeasurementPointAnswer[]): ThunkResult<any> {
    return (dispatch, getState) => {
        const { selectedResult } = getState().measurementPointResults;
        const combinedAnswers = map(updatedAnswers, answer => {
            const originalAnswer = find(selectedResult.measurementPointAnswers, {
                measurementPointID: answer.measurementPointID
            });
            return { ...originalAnswer, ...answer };
        });
        dispatch(updateMeasurementPointResult({ ...selectedResult, measurementPointAnswers: combinedAnswers }, false));
    };
}

export function bulkCannotCompleteSelected(
    notes: string,
    installBasesPopulated: IinstallBasePopulated[]
): ThunkResult<any> {
    return (dispatch, getState) => {
        const { manageInventory } = getState();
        const { selection } = manageInventory;
        const { selectedJob } = getState().manageJob;
        const facility = getState().facilities.facilitiesByID[selectedJob.facilityID];

        const selectedInstallBaseIDs = selection.map(item => {
            return item.split('select-')[1];
        });

        // TODO turn into a helper function
        const uninspectedInstalls = filter(installBasesPopulated, install => {
            let shouldInclude = true;
            if (selectedInstallBaseIDs.indexOf(install.id) === -1) {
                shouldInclude = false;
            }
            if (
                install.latestMeasurementPointListResult.status !==
                measurementPointResultStatusTypesEnum.resultStatusNotTested
            ) {
                shouldInclude = false;
            }
            if (install.isDeleted === true) {
                shouldInclude = false;
            }
            return shouldInclude;
        });

        let cannotCompleteResultsCollection: ImeasurementPointResult[] = [];
        let errorCollection: string[] = [];
        uninspectedInstalls.forEach(install => {
            const selectedMeasurementPointList = selectMeasurementPointListForInstallBase(getState(), {
                productID: install.productID,
                standardID: facility.standardID,
                shouldShowWarning: false
            });

            if (selectedMeasurementPointList.id.length === 0) {
                errorCollection = [...errorCollection, 'missing mpl'];
                return;
            }
            const newResult = initInstallBaseResult(getState, {
                installID: install.id
            });

            const cannotCompleteResult = {
                ...newResult,
                status: measurementPointResultStatusTypesEnum.resultStatusCannotComplete,
                notes
            };
            cannotCompleteResultsCollection = [...cannotCompleteResultsCollection, cannotCompleteResult];
        });

        if (errorCollection.length) {
            toastr.warning(
                'Warning',
                'Some assets do not have measurement point lists and were not submitted.',
                constants.toastrWarning
            );
        } else {
            toastr.success('Success', 'All devices updated successfully.', constants.toastrSuccess);
        }

        // submit the successul results
        dispatch(submitMeasurementPointResultHelper(cannotCompleteResultsCollection, true));
    };
}

export function startBulkCannotCompleteSelected(
    installBasesPopulated: IinstallBasePopulated[],
    t: TFunction
): ThunkResult<any> {
    return (dispatch, getState) => {
        const { manageInventory } = getState();
        const { selection } = manageInventory;

        const selectedInstallBaseIDs = selection.map(item => {
            return item.split('select-')[1];
        });

        const uninspectedInstalls = filter(installBasesPopulated, install => {
            let shouldInclude = true;
            if (selectedInstallBaseIDs.indexOf(install.id) === -1) {
                shouldInclude = false;
            }
            if (
                install.latestMeasurementPointListResult.status !==
                measurementPointResultStatusTypesEnum.resultStatusNotTested
            ) {
                shouldInclude = false;
            }
            if (install.isDeleted === true) {
                shouldInclude = false;
            }
            return shouldInclude;
        });

        if (!uninspectedInstalls.length) {
            toastr.warning('Warning', 'All devices have already been inspected.', constants.toastrWarning);
            return;
        }

        const toastrConfirmOptions = {
            onOk: () => {
                dispatch({ type: types.TOGGLE_MODAL_NOTE_PAD });
            },
            onCancel: () => null,
            okText: t('bulkCannotCompleteConfirmButton'),
            cancelText: t('common:cancel')
        };
        toastr.confirm(
            t('confirmBulkCannotCompleteInstallsTitle', {
                count: selection.length
            }),
            toastrConfirmOptions
        );
    };
}

const isObjectAMeasurementPointResult = (object: any): object is ImeasurementPointResultWithType => {
    if (object === undefined) {
        return false;
    }

    return 'jobID' in object;
};

/*
 * passSelectedHelper
 * skip saving a result for this install if there is already a result for this install and job,
 * the most recent result for this install from another job is a fail
 */
const passSelectedHelper = (
    uninspectedInstalls: IinstallBasePopulated[],
    t: TFunction,
    closingNotes?: string
): ThunkResult<any> => {
    return (dispatch, getState) => {
        try {
            const { manageJob, facilities } = getState();
            const { selectedJob } = manageJob;
            const isWorkOrderTypeJob = getIsWorkOrderTypeJob(getState());
            const facility = facilities.facilitiesByID[selectedJob.facilityID];
            let toastSuccess = 'toastMessage:mobileBulkPassResultsSuccessMessage';
            let toastFail = 'toastMessage:mobileBulkPassResultsNotAllPassedWarningMessage';

            let resultErrorCollection: Array<{
                installID: string;
                resultErrorMessages: string[];
            }> = [];
            if (isWorkOrderTypeJob) {
                toastSuccess = 'toastMessage:mobileBulkPassWOsSuccessMessageWO';
                toastFail = 'toastMessage:mobileBulkPassWOsNotAllPassedWarningMessageWO';
            }
            let passingResultsCollection: ImeasurementPointResult[] = [];
            let workOrdersCollection: IWorkOrder[] = [];
            uninspectedInstalls.forEach(install => {
                const selectedMeasurementPointList = selectMeasurementPointListForInstallBase(getState(), {
                    productID: install.productID,
                    standardID: facility.standardID,
                    shouldShowWarning: false
                });

                if (selectedMeasurementPointList.id.length) {
                    const newResult = initInstallBaseResult(getState, {
                        installID: install.id
                    });

                    // If the measurementPointResult has a status of Not Tested, let's double check the history and make sure, we CANNOT Pass an asset if it's last status was Failed
                    if (newResult.status === measurementPointResultStatusTypesEnum.resultStatusNotTested) {
                        const installBaseHistory = selectInstallBaseHistoryByInstallBaseID(getState(), install.id);
                        let lastMP: IWorkOrder | ImeasurementPointResultWithType | undefined;

                        for (const value of installBaseHistory) {
                            // Check if the value is an IWorkOrder or ImeasurementPointResultWithType
                            if (isObjectAMeasurementPointResult(value)) {
                                const tempMPR = value as ImeasurementPointResultWithType;

                                // Make sure the item in History, is of the same job type we are currently on
                                if (tempMPR.jobTypeID !== undefined && selectedJob.jobTypeID === tempMPR.jobTypeID) {
                                    lastMP = value as ImeasurementPointResultWithType;
                                    break;
                                }
                            } else {
                                // this is an IWorkOrder, don't think we care about this one...
                                lastMP = undefined;
                            }
                        }

                        // So we found a MPR in history for this job type, that has a different status, we'll use that one
                        if (
                            isObjectAMeasurementPointResult(lastMP) &&
                            lastMP.measurementPointAnswers !== undefined &&
                            newResult.status !== lastMP.status
                        ) {
                            newResult.status = lastMP.status;
                        }
                    }

                    // validate the new result
                    const resultErrorMessages = validateNewResult(selectedMeasurementPointList, newResult, getState);
                    if (!resultErrorMessages.length) {
                        let newStatus = measurementPointResultStatusTypesEnum.resultStatusPass;
                        if (selectedJob.jobTypeID === jobTypesIdEnum.repair) {
                            newStatus = measurementPointResultStatusTypesEnum.resultStatusRepaired;
                        }
                        if (
                            selectedJob.jobTypeID === jobTypesIdEnum.maintenance ||
                            selectedJob.jobTypeID === jobTypesIdEnum.servicePlan
                        ) {
                            newStatus = measurementPointResultStatusTypesEnum.resultStatusMaintain;
                        }
                        if (selectedJob.jobTypeID === jobTypesIdEnum.commissioning) {
                            newStatus = measurementPointResultStatusTypesEnum.resultStatusCommissioned;
                        }
                        const passingResult = {
                            ...newResult,
                            status: newStatus
                        };
                        passingResultsCollection = [...passingResultsCollection, passingResult];
                        if (isWorkOrderTypeJob && closingNotes) {
                            const selectedWorkOrders = selectWorkOrdersByInstallBaseID(getState(), install.id);
                            const selectedWOsWithNotes = map(selectedWorkOrders, wo => {
                                return { ...wo, closingNotes };
                            });
                            workOrdersCollection = [...workOrdersCollection, ...selectedWOsWithNotes];
                        }
                    } else {
                        resultErrorCollection = [
                            ...resultErrorCollection,
                            { installID: install.id, resultErrorMessages }
                        ];
                    }
                } else {
                    resultErrorCollection = [
                        ...resultErrorCollection,
                        {
                            installID: install.id,
                            resultErrorMessages: ['missing measurement point list']
                        }
                    ];

                    console.error('[passSelectedHelper]: missing measurement point list');
                }
            });

            // Mainly for passingResultsCollection, we want to wait for all API calls to finish before showing a success prompt
            // Success prompt will actually happen in manageInventory when shouldRefreshInventory useEffect fires
            let showSucessPrompt = true;

            // submit the results that passed validation
            if (passingResultsCollection.length) {
                showSucessPrompt = false;
                dispatch(submitMeasurementPointResultHelper(passingResultsCollection, true));
            }

            if (isWorkOrderTypeJob && workOrdersCollection.length) {
                dispatch(completeWorkOrdersHelper(workOrdersCollection));
            }

            if (resultErrorCollection.length) {
                toastr.warning(t('toastMessage:warning'), t(toastFail), constants.toastrWarning);

                resultErrorCollection.forEach(resultError => {
                    const errorBase = uninspectedInstalls.find(install => install.id === resultError.installID);

                    toastr.warning(
                        t('toastMessage:error'),
                        `${errorBase?.productNameString}.\nThe asset failed during last inspection. Batch function "Pass" would not be applied to it.`,
                        constants.toastrError
                    );
                });
            } else {
                if (showSucessPrompt) {
                    toastr.success(t('success'), t(toastSuccess), constants.toastrSuccess);
                }
            }
        } catch (error) {
            console.error('[passSelectedHelper]:', error);
        } finally {
            dispatch({ type: types.UPDATE_MANAGE_INVENTORY_LOADING, payload: false });
        }
    };
};

/*
 * Loop over all the selected devices
 * find all the uninspected installs and send those to the passSelectedHelper
 * If no uninspected, then show a warning
 */
export function passSelected(t: TFunction, closingNotes?: string): ThunkResult<any> {
    return (dispatch, getState) => {
        const { manageInventory } = getState();
        const installBases = filterInstallBases(getState());
        const { selection } = manageInventory;
        const selectedInstallBaseIDs = selection.map(item => {
            return item.split('select-')[1];
        });

        // we only want to grab installs that have not been tested for this job yet
        const uninspectedInstalls = filter(installBases, install => {
            let shouldInclude = true;
            if (selectedInstallBaseIDs.indexOf(install.id) === -1) {
                shouldInclude = false;
            }
            if (
                install.latestMeasurementPointListResult.status !==
                measurementPointResultStatusTypesEnum.resultStatusNotTested
            ) {
                shouldInclude = false;
            }
            if (install.isDeleted === true) {
                shouldInclude = false;
            }
            return shouldInclude;
        });

        if (!uninspectedInstalls.length) {
            toastr.warning('Warning', 'All devices have already been inspected.', constants.toastrWarning);
            return;
        }

        const toastrConfirmOptions = {
            onOk: () => {
                dispatch({ type: types.UPDATE_MANAGE_INVENTORY_LOADING, payload: true });
                setTimeout(() => {
                    dispatch(passSelectedHelper(uninspectedInstalls, t, closingNotes));
                }, 100);
            },
            onCancel: () => null,
            okText: t('buttonBulkPass'),
            cancelText: t('common:cancel')
        };
        toastr.confirm(
            t('confirmBulkPassInstalls', {
                count: selection.length
            }),
            toastrConfirmOptions
        );
    };
}

export const clearHistoricalResultID = () => ({
    type: types.CLEAR_HISTORICAL_RESULT_ID
});

export const clearHistoricalMPResult = () => ({
    type: types.CLEAR_HISTORICAL_MP_RESULT
});
