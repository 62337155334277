/*
 * Comment on a Measurement Point List
 */

import * as React from 'react';

import { Button, Col, ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import { toggleModalNotePad as toggleModalNotePad_action } from '../../actions/manageInventoryActions';

import { IinstallBasePopulated } from '../../models';
import { TFunction } from 'i18next';
import { bulkCannotCompleteSelected } from '../../actions/measurementPointResultsActions';

interface Iprops {
    toggleModal: () => void;
    colorButton: string;
    t: TFunction;
    showCancel?: boolean;
    bulkCannotCompleteSelected: typeof bulkCannotCompleteSelected;
    filteredInstallBases: IinstallBasePopulated[];
    toggleModalNotePad: typeof toggleModalNotePad_action;
}

export class NotePad extends React.Component<Iprops, any> {
    constructor(props: Iprops) {
        super(props);
        this.state = {
            notes: ''
        };
    }

    handleInputChange(event: any) {
        const { value } = event.currentTarget;
        this.setState({ notes: value });
    }

    componentWillUnmount() {
        this.setState({});
    }

    handleSubmit = () => {
        this.props.bulkCannotCompleteSelected(this.state.notes, this.props.filteredInstallBases);
        this.props.toggleModal();
    };

    render() {
        const { t } = this.props;
        return (
            <div className="beacon-form">
                <Col xs={12}>
                    <FormGroup>
                        <ControlLabel>{t('cannotCompleteCommentLabel')}</ControlLabel>
                        <FormControl
                            onChange={event => {
                                this.handleInputChange(event);
                            }}
                            componentClass="textarea"
                            type="text"
                            rows={20}
                            value={this.state.notes}
                        ></FormControl>
                    </FormGroup>
                </Col>
                <Col xs={12} className="form-buttons text-right">
                    <Button bsStyle="default" type="button" className="pull-left" onClick={this.props.toggleModal}>
                        {this.props.t('common:cancel')}
                    </Button>
                    <Button bsStyle={this.props.colorButton} type="submit" onClick={() => this.handleSubmit()}>
                        {this.props.t('common:save')}
                    </Button>
                </Col>
            </div>
        );
    }
}
