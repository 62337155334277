import React from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { Col, Button, FormGroup as FGBS, ControlLabel, FormControl } from 'react-bootstrap';
import { FieldConfig, FormArray, FormGenerator, FormGroup, GroupProps } from 'react-reactive-form';
import { TFunction } from 'i18next';
import { debounce } from 'lodash';
import { toastr } from 'react-redux-toastr';

import { IdefaultReport, IinstallBasePopulated, Ijob, IjobSignature, Iuser } from '../../models';
import { constants } from '../../constants/constants';
import { setJobCoverLetter } from '../../actions/manageJobActions';
import { FormUtil } from '../common/FormUtil';
import { submitJobSignature } from '../../actions/workOrderActions';
import { SignatureTypeEnum } from '../../models-enums';

interface Iprops {
    toggleModal: () => void;
    toggleIsJobClosingWithSignature: () => void;
    submitSignature: typeof submitJobSignature;
    setJobCoverLetter: typeof setJobCoverLetter;
    updateFormValue: (formValue: { [key: string]: any }) => void;
    loading: boolean;
    t: TFunction;
    installBasesPopulated: IinstallBasePopulated[];
    selectedJob: Ijob;
    selectedJobSignatures: IjobSignature[];
    validateParts: boolean;
    isJobClosingWithSignature: boolean;
    verificationReport: IdefaultReport;
    user: Iuser;
}

interface Istate {
    hospitalName: string;
    fieldConfig: FieldConfig;
}

const canvasDimensions = {
    width: 468,
    height: 200
};

class VerificationSignaturePad extends React.Component<Iprops, Istate> {
    private formGroup: FormGroup | any;
    private subscription: any;
    private hospitalSignaturePad: any = {};
    private updateFormValueDebounced: any;

    constructor(props: any) {
        super(props);
        this.hospitalSignaturePad = React.createRef();
        this.updateFormValueDebounced = debounce(this.props.updateFormValue, constants.formDebounceTime);
        this.state = {
            hospitalName: '',
            fieldConfig: this.buildFieldConfig()
        };
    }

    componentDidMount() {
        this.setState({
            fieldConfig: this.buildFieldConfig()
        });
    }

    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    handleInputChange(event: any) {
        const { value } = event.currentTarget;
        this.setState({ hospitalName: value });
    }

    isValidSubmission(): boolean {
        return !this.hospitalSignaturePad.isEmpty() && this.state.hospitalName !== '';
    }

    buildFieldConfig = () => {
        const disabled = false;
        const coverLetterValue = this.props.verificationReport?.defaultCoverLetter;
        // Field config to configure form
        const fieldConfigControls = {
            coverLetter: {
                render: FormUtil.RichTextEditor,
                meta: {
                    label: 'coverLetter',
                    colWidth: 12,
                    name: 'coverLetter',
                    type: 'text',
                    initialContent: coverLetterValue ? coverLetterValue : '',
                    charLimit: 1000
                },
                options: {
                    validators: FormUtil.validators.requiredRichText
                },
                formState: {
                    value: '',
                    disabled
                }
            }
        } as { [key: string]: GroupProps };
        const fieldConfig = {
            controls: { ...fieldConfigControls }
        };

        return FormUtil.translateForm(fieldConfig, this.props.t);
    };

    subscribeToChanges = () => {
        for (const key in this.formGroup.controls) {
            if (this.formGroup.controls.hasOwnProperty(key)) {
                this.subscription = this.formGroup.get(key).valueChanges.subscribe((value: any) => {
                    this.onValueChanges(value, key);
                });
            }
        }
    };

    onValueChanges = (value: any, key: string) => {
        switch (key) {
            default:
                this.updateFormValueDebounced({ [key]: value });
                break;
        }
    };

    setForm = (form: FormGroup | FormArray) => {
        this.formGroup = form;
        this.formGroup.meta = {
            loading: this.props.loading
        };
        if (!this.subscription) {
            setTimeout(() => {
                this.subscribeToChanges();
            }, 300);
        }
    };

    submit = () => {
        const formValues = FormUtil.getValues(this.formGroup.value);

        if (!this.isValidSubmission()) {
            if (this.props.isJobClosingWithSignature) {
                this.props.toggleIsJobClosingWithSignature();
            }

            toastr.error('Missing Form Data', constants.toastrError);
            return;
        }

        const hospitalCanvas = this.hospitalSignaturePad as SignatureCanvas;

        this.props.setJobCoverLetter(this.props.selectedJob, formValues.coverLetter as string);
        hospitalCanvas?.getTrimmedCanvas().toBlob(blob => {
            if (blob) {
                this.props.submitSignature(
                    this.props.t,
                    this.props.selectedJob.id,
                    blob,
                    hospitalCanvas?.toDataURL(),
                    SignatureTypeEnum.Hospital,
                    this.state.hospitalName,
                    true
                );
            }
        });
    };

    clear = () => {
        this.hospitalSignaturePad.clear();
    };

    render() {
        const { loading, t } = this.props;

        return (
            <div className="beacon-form">
                <Col xs={12}>
                    <FGBS>
                        <ControlLabel>{t('printedHospitalName')}</ControlLabel>
                        <FormControl
                            onChange={event => {
                                this.handleInputChange(event);
                            }}
                            type="text"
                            value={this.state.hospitalName}
                        ></FormControl>
                    </FGBS>
                </Col>

                <Col xs={12}>
                    <FGBS>
                        <ControlLabel>{t('hospitalConfirmationSignature')}</ControlLabel>
                        <div className="signaturePad">
                            <SignatureCanvas
                                ref={ref => {
                                    this.hospitalSignaturePad = ref;
                                }}
                                penColor="#333333"
                                canvasProps={canvasDimensions}
                            />
                        </div>
                    </FGBS>
                </Col>
                <FormGenerator onMount={this.setForm} fieldConfig={this.state.fieldConfig} />
                <Col xs={12} className="form-buttons text-right">
                    <Button bsStyle="default" type="button" className="pull-left" onClick={this.props.toggleModal}>
                        {t('common:cancel')}
                    </Button>
                    <Button
                        bsStyle="default"
                        type="button"
                        className="pull-left"
                        onClick={this.clear}
                        style={{
                            marginLeft: '1rem'
                        }}
                    >
                        {t('manageInventory:clearSignature')}
                    </Button>
                    <Button bsStyle="default" disabled={loading} onClick={this.submit}>
                        {t('common:save')}
                    </Button>
                </Col>
            </div>
        );
    }
}

export default VerificationSignaturePad;
