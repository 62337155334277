import * as React from 'react';

import { Col, DropdownButton, MenuItem } from 'react-bootstrap';
import {
    bulkUpdateInstalls,
    getReportPreview,
    setEnableInstallBatchMode,
    startBulkUpdateInstallProduct
} from '../../actions/manageInventoryActions';
import { passSelected, startBulkCannotCompleteSelected } from '../../actions/measurementPointResultsActions';

import { TFunction } from 'i18next';
import { filter, find } from 'lodash';
import { toastr } from 'react-redux-toastr';
import { RouteComponentProps } from 'react-router';
import { completeJob, unlinkWorkOrders } from '../../actions/manageJobActions';
import { constants } from '../../constants/constants';
import {
    IWorkOrder,
    Ifacility,
    IinstallBasePart,
    IinstallBasePopulated,
    Ijob,
    Iuser,
    WorkOrderSource
} from '../../models';
import { measurementPointListTypeEnum, workOrderStatusEnum } from '../../models-enums';
import { securityFunctions } from '../../constants/securityFunctions';

interface Iprops extends RouteComponentProps<any> {
    t: TFunction;
    facility: Ifacility;
    selectedJob: Ijob;
    toggleSearchNewProductsModal: () => void;
    toggleEditJobModal: () => void;
    completeJob: typeof completeJob;
    passSelected: typeof passSelected;
    startBulkCannotCompleteSelected: typeof startBulkCannotCompleteSelected;
    showCompleteJobButton: boolean;
    installBasesPopulated: IinstallBasePopulated[];
    colorButton: string;
    setEnableInstallBatchMode: typeof setEnableInstallBatchMode;
    toggleJobSignatureModal: () => void;
    toggleModalSignaturePad: () => void;
    toggleCommissioningDataFormModal: () => void;
    toggleEditInstallModal: () => void;
    startBulkUpdateInstallProduct: typeof startBulkUpdateInstallProduct;
    toggleIsJobClosingWithSignature: () => void;
    toggleIsCollectingSignatures: () => void;
    selection: string[];
    bulkUpdateInstalls: typeof bulkUpdateInstalls;
    toggleAddHoursModal: () => void;
    toggleEditJobDefaultsModal: () => void;
    isAuditJob: boolean;
    isCommissioningJob: boolean;
    isMaintenanceJob: boolean;
    isRepairJob: boolean;
    isVerificationJob: boolean;
    isWarrantyJob: boolean;
    isServicePlanJob: boolean;
    isAgsJob: boolean;
    isAnnualJob: boolean;
    showEditJobDefaultsButton: boolean;
    resetSelection: () => void;
    getReportPreview: typeof getReportPreview;
    online: boolean;
    onMenuToggle?: (isOpen: boolean) => void;
    unlinkWorkOrders: typeof unlinkWorkOrders;
    toggleModalAddFSE: () => void;
    toggleModalEditFacilityContacts: () => void;
    workOrders: { [key: string]: IWorkOrder };
    installBaseParts: { [key: string]: IinstallBasePart };
    user: Iuser;
}

export const InventoryActionButton = (props: Iprops) => {
    const {
        t,
        facility,
        toggleSearchNewProductsModal,
        toggleEditJobDefaultsModal,
        toggleEditJobModal,
        history,
        toggleAddHoursModal,
        showCompleteJobButton,
        showEditJobDefaultsButton,
        installBasesPopulated,
        colorButton,
        toggleEditInstallModal,
        toggleCommissioningDataFormModal,
        toggleJobSignatureModal,
        toggleModalSignaturePad,
        selection,
        isCommissioningJob,
        isMaintenanceJob,
        isRepairJob,
        isVerificationJob,
        isWarrantyJob,
        isAgsJob,
        isAnnualJob,
        isServicePlanJob,
        isAuditJob,
        resetSelection,
        onMenuToggle,
        toggleModalAddFSE,
        toggleModalEditFacilityContacts,
        user
    } = props;

    const checkSelectionLimit = (limit = constants.bulkSelectionLimit) => {
        if (selection.length >= limit) {
            toastr.warning(
                t('toastMessage:warning'),
                t('toastMessage:bulkSelectionLimit', {
                    limit
                }),
                constants.toastrWarning
            );
            return Promise.reject(false);
        } else if (selection.length === 0) {
            toastr.warning(
                t('toastMessage:warning'),
                t('toastMessage:bulkSelectionMissing', {
                    limit
                }),
                constants.toastrWarning
            );
            return Promise.reject(false);
        } else {
            return Promise.resolve(true);
        }
    };

    const selectionToInstalls = (
        selection: string[],
        installBases: IinstallBasePopulated[]
    ): IinstallBasePopulated[] => {
        return selection
            .map(item => item.split('select-')[1])
            .map(id => installBases.find(i => i.id === id))
            .filter(i => i !== undefined) as IinstallBasePopulated[];
    };

    const enableUnlinkWOS = (selection: string[], installBases: IinstallBasePopulated[]): boolean =>
        selectionToInstalls(selection, installBases).every(installBase =>
            installBase.workOrders?.find(wo => wo.source === WorkOrderSource.SAP)
        );

    const needDeleteDoubleCheck = (selection: string[], installBases: IinstallBasePopulated[]): boolean =>
        selection
            .map(item => item.split('select-')[1])
            .find(id => {
                const installBase = installBases.find(i => i.id === id);
                return installBase?.workOrders?.find(wo => wo.source === WorkOrderSource.SAP);
            }) !== undefined;

    const checkSelectionPassLimit = () => checkSelectionLimit(constants.bulkSelectionPassLimit);

    const unlinkWorkOrdersCheck = () => {
        let showWarning = false;

        const selectedWorkOrders = selection.map(item => {
            const installBaseId = item.split('select-')[1];
            return find(Object.values(props.workOrders), (x: IWorkOrder) => x.installBaseID === installBaseId);
        });

        const anyCompletedWorkOrders = selectedWorkOrders.some(wo => wo?.status !== workOrderStatusEnum.new);
        if (anyCompletedWorkOrders) {
            toastr.error(t('toastMessage:error'), 'Completed work orders cannot be unlinked.', constants.toastrError);
            return;
        }

        // Check if the job has hours
        if (props.selectedJob.jobHours && props.selectedJob.jobHours.length > 0) {
            showWarning = true;
        }
        // Have any work order parts been added to this job?
        const partsUsed = filter(props.installBaseParts, x => x.jobID === props.selectedJob.id);
        if (partsUsed.length > 0) {
            const WOPartsUsed = partsUsed.filter(x => x.workOrderPartQuantity && x.workOrderPartQuantity > 0);
            if (WOPartsUsed.length > 0) {
                let selectedWorkOrdersHaveWOPartsUsed = false;
                // Check if the used work order parts belong to any of the selected work orders that are trying to be unlinked
                WOPartsUsed.map((x: IinstallBasePart) => {
                    if (selectedWorkOrders.some(wo => wo?.installBaseID === x.installBaseID)) {
                        selectedWorkOrdersHaveWOPartsUsed = true;
                    }
                });

                if (selectedWorkOrdersHaveWOPartsUsed) {
                    showWarning = true;
                }
            }
        }

        if (showWarning) {
            const toastrConfirmOptions = {
                onOk: () => {
                    props.unlinkWorkOrders(t);
                },
                onCancel: () => null,
                okText: 'Go Ahead',
                cancelText: 'Cancel'
            };
            toastr.confirm(props.t('nsJob:removeSAPWorkOrderWarning'), toastrConfirmOptions);
        } else {
            props.unlinkWorkOrders(t);
        }
    };

    const getAddJobDataURL = (): string => {
        if (isAgsJob) {
            return `/simple_list?type=${measurementPointListTypeEnum.agsRebalancing}`;
        } else if (isCommissioningJob) {
            return `/simple_list?type=${measurementPointListTypeEnum.commissioning}`;
        } else if (isMaintenanceJob || isRepairJob) {
            return `/simple_list?type=${measurementPointListTypeEnum.touchpoint}`;
        } else if (isVerificationJob) {
            return `/simple_list?type=${measurementPointListTypeEnum.verification}`;
        } else if (isAnnualJob) {
            return `/simple_list?type=${measurementPointListTypeEnum.annual}`;
        } else if (isAuditJob) {
            return `/simple_list?type=${measurementPointListTypeEnum.audit}`;
        }

        return `/simple_list`;
    };

    const handleOnSelect = (eventKey: any) => {
        switch (eventKey) {
            case 1: // new install
                toggleSearchNewProductsModal();
                props.setEnableInstallBatchMode(false);
                break;
            case 3: // add job
                toggleEditJobModal();
                break;
            case 4:
                toggleAddHoursModal();
                break;
            case 5:
                history.push('/job_comments');
                break;
            case 50:
                history.push(`/simple_list?type=${measurementPointListTypeEnum.purity}`);
                break;
            case 51:
                history.push(`/simple_list?type=${measurementPointListTypeEnum.verificationChecklist}`);
                break;
            case 52:
                toggleEditJobDefaultsModal();
                break;
            case 6: // complete job
                props.toggleIsJobClosingWithSignature();
                props.completeJob(t, installBasesPopulated, isCommissioningJob, facility?.countryID);
                break;
            // client asked to remove bulk close work orders April 15 2020
            case 7: // Get Report Preview
                props.getReportPreview(props.selectedJob.id);
                break;
            case 8: // pass
                checkSelectionPassLimit().then(() => {
                    props.passSelected(t);
                });
                break;
            case 9: // cannot complete all selected
                checkSelectionPassLimit().then(() => {
                    props.startBulkCannotCompleteSelected(installBasesPopulated, t);
                });
                break;
            case 10: // edit
                checkSelectionLimit()
                    .then(() => {
                        toggleEditInstallModal();
                        props.setEnableInstallBatchMode(true);
                    })
                    .catch();
                break;
            case 11: // delete
                // Check if SAP assets, if so, display a different message
                const selectedInstallBaseIDs = selection.map(item => {
                    return item.split('select-')[1];
                });
                const sapInstallBasesToUpdate = filter(installBasesPopulated, install => {
                    if (selectedInstallBaseIDs.indexOf(install.id) === -1) {
                        return false;
                    }
                    if (install.isDeleted === true) {
                        return false;
                    }
                    return !!install.sapEquipmentNumber;
                });
                if (sapInstallBasesToUpdate.length > 0) {
                    toastr.warning(
                        t('toastMessage:warning'),
                        t('toastMessage:bulkDeleteSAPAssetsWarning'),
                        constants.toastrWarning
                    );
                    return;
                }
                // They want to display the SAP asset delete message for all assets, keeping this in here for now in
                // case they change their mind because this message makes no sense to display for all assets
                const doubleCheck = true; //needDeleteDoubleCheck(selection, installBasesPopulated);
                checkSelectionLimit()
                    .then(() => {
                        const toastrConfirmOptions = {
                            onOk: doubleCheck
                                ? () => {
                                      toastr.confirm(t('installDeleteSecondWarning'), toastConfirmAgainOptions);
                                  }
                                : () => {
                                      props.bulkUpdateInstalls({ isDeleted: true });
                                  },
                            onCancel: () => null,
                            okText: t('buttonBulkDeleteInstalls'),
                            cancelText: t('common:cancel')
                        };
                        const toastConfirmAgainOptions = {
                            onOk: () => {
                                props.bulkUpdateInstalls({ isDeleted: true });
                            },
                            onCancel: () => null,
                            okText: t('buttonBulkDeleteInstalls'),
                            cancelText: t('common:cancel')
                        };
                        toastr.confirm(
                            t('toastMessage:confirmBulkDeleteInstalls', {
                                count: selection.length
                            }),
                            toastrConfirmOptions
                        );
                    })
                    .catch();
                break;
            case 12: // change product
                checkSelectionLimit()
                    .then(() => {
                        props.startBulkUpdateInstallProduct(t);
                    })
                    .catch();
                break;
            case 13: // reset selection
                resetSelection();
                break;
            case 14: // add commissioning data
                toggleCommissioningDataFormModal();
                break;
            case 15: // add job signatures
                props.toggleIsCollectingSignatures();
                if (isCommissioningJob && facility?.countryID === constants.ukCountryID) {
                    toggleJobSignatureModal();
                } else {
                    toggleModalSignaturePad();
                }
                break;
            case 16: // add job data
                history.push(getAddJobDataURL());
                break;
            case 17: // unlink work orders
                checkSelectionLimit()
                    .then(() => {
                        unlinkWorkOrdersCheck();
                    })
                    .catch(e => {});
                break;
            case 18: //Add Additional FSE
                toggleModalAddFSE();
                break;
            case 19: //Edit Facility Contacts
                toggleModalEditFacilityContacts();
                break;
            default:
                break;
        }
    };

    let anyVirtualIBs = false;

    if (
        props.selection &&
        props.selection.length > 0 &&
        props.installBasesPopulated &&
        props.installBasesPopulated.length > 0
    ) {
        props.selection.forEach(selection => {
            const installBase = props.installBasesPopulated.find(ib => ib.id === selection.split('select-')[1]);
            if (installBase && installBase.isVirtual) {
                anyVirtualIBs = true;
            }
        });
    }

    return (
        <Col xs={2} lg={1} className="inventory-action-button" style={{ paddingTop: '25px' }}>
            <DropdownButton
                pullRight
                bsStyle={colorButton}
                title={t('manageInventory:actions')}
                id="inventory-action-button"
                onSelect={handleOnSelect}
                onToggle={onMenuToggle}
            >
                <div style={{ backgroundColor: '#286090', padding: '0px 2px' }}>
                    <p style={{ marginLeft: '21px', color: '#fff' }}>{t('mobile:jobActions')}</p>
                </div>
                <MenuItem eventKey={16}>Add Job Data</MenuItem>
                <MenuItem eventKey={3}>{t('mobile:addJobButton')}</MenuItem>
                <MenuItem eventKey={4}>{t('mobile:addHoursButton')}</MenuItem>
                <MenuItem eventKey={5}>{t('mobile:addJobNotes')}</MenuItem>
                <MenuItem eventKey={18}>Add Additional FSE</MenuItem>
                {isCommissioningJob && <MenuItem eventKey={14}>{t('mobile:addCommissioningData')}</MenuItem>}
                <MenuItem eventKey={15}>{t('mobile:collectSignatures')}</MenuItem>
                {/* Something about the React.Fragment prevents lumping these two together with our switchcase */}
                {isVerificationJob && <MenuItem eventKey={50}>{t('mobile:purity')}</MenuItem>}
                {isVerificationJob && <MenuItem eventKey={51}>{t('mobile:verificationChecklist')}</MenuItem>}
                {showEditJobDefaultsButton && (
                    <MenuItem eventKey={52}>{t('manageInventory:editJobDefaultsModal')}</MenuItem>
                )}
                {constants.hasSecurityFunction(user, securityFunctions.FSEFacilityContactEdit.id) && (
                    <MenuItem eventKey={19}>{t('manageInventory:editFacilitycontacts')}</MenuItem>
                )}
                {showCompleteJobButton && <MenuItem eventKey={6}>{t('mobile:completeJobButton')}</MenuItem>}
                {showCompleteJobButton && props.online && (
                    <MenuItem eventKey={7}>{t('mobile:getReportPreview')}</MenuItem>
                )}

                <hr />
                <div style={{ backgroundColor: '#286090', padding: '0px 2px' }}>
                    <p style={{ marginLeft: '21px', color: '#fff' }}>{t('mobile:individualActions')}</p>
                </div>
                <MenuItem eventKey={1}>{t('manageInventory:newInstall')}</MenuItem>

                <hr />
                <div style={{ backgroundColor: '#286090', padding: '0px 2px' }}>
                    <p style={{ marginLeft: '21px', color: '#fff' }}>{t('mobile:batchActions')}</p>
                </div>
                <MenuItem
                    eventKey={8}
                    disabled={
                        isMaintenanceJob ||
                        isRepairJob ||
                        isWarrantyJob ||
                        isServicePlanJob ||
                        isCommissioningJob ||
                        anyVirtualIBs
                    }
                >
                    {t('mobile:passAll')}
                </MenuItem>
                <MenuItem
                    eventKey={9}
                    disabled={
                        isMaintenanceJob ||
                        isRepairJob ||
                        isWarrantyJob ||
                        isServicePlanJob ||
                        isCommissioningJob ||
                        anyVirtualIBs
                    }
                >
                    {t('mobile:cannotAllActionButtonItem')}
                </MenuItem>
                <MenuItem eventKey={17} disabled={!enableUnlinkWOS(selection, installBasesPopulated)}>
                    {t('manageInventory:unlinkWorkOrders')}
                </MenuItem>

                <MenuItem disabled={anyVirtualIBs} eventKey={10}>
                    {t('mobile:editSelection')}
                </MenuItem>
                <MenuItem disabled={anyVirtualIBs} eventKey={11}>
                    {t('mobile:deleteSelection')}
                </MenuItem>
                <MenuItem disabled={anyVirtualIBs} eventKey={12}>
                    {t('mobile:editSelectionProduct')}
                </MenuItem>
                <MenuItem eventKey={13}>{t('mobile:resetSelection')}</MenuItem>
            </DropdownButton>
        </Col>
    );
};
