import { createSelector } from 'reselect';
import { IinitialState } from '.';
import * as types from '../actions/actionTypes';
import initialState from './initialState';
import { Iconfig } from '../models';

export const getConfig = (state: IinitialState): Iconfig => state.config;

export const getIsMobile = createSelector([getConfig], config => config.isMobile);
export const getWorkOrderUpdatedSuccess = createSelector([getConfig], config => config.workOrderLoadedKey);

export default function configReducer(state: Iconfig = initialState.config, action: any): Iconfig {
    switch (action.type) {
        case types.SET_IS_MOBILE:
            return { ...state, isMobile: action.value as boolean };
        case types.JOB_UPDATE_WORKORDERS_SUCCESS:
            return { ...state, workOrderLoadedKey: action.key as string };
        default:
            return state;
    }
}
