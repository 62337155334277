import * as types from './actionTypes';

import { AxiosRequestConfig, AxiosResponse } from 'axios';

import API from '../constants/apiEndpoints';
import { appSyncItemNames, constants } from '../constants/constants';
import { msalFetch } from '../components/auth/Auth-Utils';
import { IjobDefault, ThunkResult } from '../models';
import moment from 'moment';
import { AppSyncItemStatus } from '../models-enums';

export function getAllMeasurementPointLists(showError: boolean = true): ThunkResult<any> {
    return dispatch => {
        dispatch({
            type: types.UPDATE_INITIAL_APP_SYNC_ITEM_STATUS,
            name: appSyncItemNames.MeasurementPointLists,
            status: AppSyncItemStatus.inProgress
        });

        const axiosOptions: AxiosRequestConfig = {
            method: 'get',
            params: { pagingType: 'none', includeDeleted: true }
        };

        const url = API.GET.measurementPoint.getall;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (!data.data) {
                    dispatch({
                        type: types.UPDATE_INITIAL_APP_SYNC_ITEM_STATUS,
                        name: appSyncItemNames.MeasurementPointLists,
                        status: AppSyncItemStatus.failed
                    });
                    throw new Error('unable to get inspection questions');
                } else {
                    dispatch({
                        type: types.MANAGE_MEASUREMENT_POINT_LISTS_SUCCESS,
                        allLists: data.data.result,
                        updateDate: moment().unix()
                    });

                    dispatch({
                        type: types.REMOVE_INITIAL_APP_SYNC_ITEM,
                        name: appSyncItemNames.MeasurementPointLists
                    });
                    return data;
                }
            })
            .catch((error: any) => {
                console.error('[getAllMeasurementPointLists]:', error);
                dispatch({
                    type: types.UPDATE_INITIAL_APP_SYNC_ITEM_STATUS,
                    name: appSyncItemNames.MeasurementPointLists,
                    status: AppSyncItemStatus.failed
                });
                dispatch({ type: types.MANAGE_MEASUREMENT_POINT_LISTS_FAILED });
                if (showError) {
                    constants.handleError(error, 'get inspection questions');
                }

                throw error;
            });
    };
}

export const saveJobDefaults = (jobDefaults: IjobDefault[]) => ({
    type: types.SAVE_JOB_DEFAULTS,
    payload: jobDefaults
});

export const setSelectedMeasurementPointListByID = (id: string): ThunkResult<any> => {
    return (dispatch, getState) => {
        const { measurementPointListsByID } = getState().measurementPointLists;
        const foundMeasurementPointList = measurementPointListsByID[id];
        if (foundMeasurementPointList) {
            dispatch({
                type: types.SET_SELECTED_MEASUREMENT_POINT_LIST,
                measurementPointList: foundMeasurementPointList
            });
        }
    };
};
