/*
 * Edit Job Comment Connector
 */

import { TFunction } from 'i18next';
import { connect } from 'react-redux';

import { IinitialState } from '../../reducers';
import {
    updateJobComment,
    toggleEditJobCommentModal,
    addJobComment,
    setSelectedJobComment,
    deleteJobComment
} from '../../actions/manageJobCommentsActions';
import { EditJobCommentForm } from './EditJobCommentForm';
import { RouteComponentProps } from 'react-router';
import { selectDeficiencyCodesByStandardID } from '../../reducers/manageJobCommentsReducer';
import { initialFacility } from '../../reducers/initialState';
import { selectIsLoading } from '../../reducers/commonReducers';

interface Iprops extends RouteComponentProps<{}> {
    colorButton: any;
    t: TFunction;
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops) => {
    const facility = state.facilities.facilitiesByID[state.manageJob.selectedJob.facilityID] || initialFacility;
    const { showEditJobCommentModal, selectedJobComment, deficiencyCodesByID } = state.manageJobComment;
    const filteredDeficiencyCodes = selectDeficiencyCodesByStandardID(deficiencyCodesByID, facility.standardID);
    return {
        user: state.user,
        loading: selectIsLoading(state),
        showModal: showEditJobCommentModal,
        selectedJobComment,
        deficiencyCodesByID: filteredDeficiencyCodes
    };
};

export const EditJobCommentContainer = connect(mapStateToProps, {
    updateJobComment,
    toggleEditJobCommentModal,
    addJobComment,
    setSelectedJobComment,
    deleteJobComment
})(EditJobCommentForm);
