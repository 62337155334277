import { pickBy, map, keyBy, filter, orderBy } from 'lodash';

import { IjobComment, IdeficiencyCode, ItableFiltersParams } from '../models';
import { createTableFiltersWithName, createShowModalWithNamedType } from './commonReducers';
import { initialJobComment, initialDeficiencyCode } from './initialState';
import * as types from '../actions/actionTypes';
import moment from 'moment';
import { combineReducers } from 'redux';
import { IinitialState } from '.';
import { createSelector } from 'reselect';

const cleanJobCommentObject = (jobComment: IjobComment) => {
    return {
        ...initialJobComment,
        ...pickBy(jobComment, (property, key) => property !== null)
    };
};
const cleanJobCommentCodeObject = (jobCommentCode: IdeficiencyCode) => {
    return {
        ...initialDeficiencyCode,
        ...pickBy(jobCommentCode, (property, key) => property !== null)
    };
};

function jobCommentsByID(state: { [key: string]: IjobComment } = {}, action: any): { [key: string]: IjobComment } {
    switch (action.type) {
        case types.MANAGE_JOB_COMMENTS_SUCCESS: {
            const newData = map(action.payload.data.result, item => {
                return cleanJobCommentObject(item);
            });
            return { ...state, ...keyBy(newData, 'id') };
        }
        case types.MANAGE_JOB_COMMENTS_ADD: {
            return { ...state, [action.jobComment.id]: action.jobComment };
        }
        case types.MANAGE_JOB_COMMENTS_UPDATE: {
            return {
                ...state,
                [action.jobComment.id]: cleanJobCommentObject(action.jobComment)
            }; // clean it here beacuase we receive updated comments from /latest
        }

        case types.USER_LOGOUT_SUCCESS:
            return {};
        default:
            return state;
    }
}
function deficiencyCodesByID(
    state: { [key: string]: IdeficiencyCode } = {},
    action: any
): { [key: string]: IdeficiencyCode } {
    switch (action.type) {
        case types.MANAGE_JOB_COMMENT_CODES_SUCCESS: {
            const newData = map(action.codes, item => {
                return cleanJobCommentCodeObject(item);
            });
            return { ...state, ...keyBy(newData, 'id') };
        }
        case types.USER_LOGOUT_SUCCESS:
            return {};
        default:
            return state;
    }
}

function selectedJobComment(state: IjobComment = initialJobComment, action: any): IjobComment {
    switch (action.type) {
        case types.MANAGE_JOB_COMMENTS_SELECT:
            return action.jobComment;
        case types.MANAGE_JOB_COMMENTS_ADD:
            return action.jobComment;
        case types.MANAGE_JOB_COMMENTS_UPDATE: {
            // this helps update the job /latest
            const foundJobComment = action.jobComment.id === state.id;
            if (foundJobComment) {
                return cleanJobCommentObject(action.jobComment);
            } else {
                return state;
            }
        }
        case types.USER_LOGOUT_SUCCESS:
            return initialJobComment;
        default:
            return state;
    }
}

const manageJobComment = combineReducers({
    jobCommentsByID,
    selectedJobComment,
    showEditJobCommentModal: createShowModalWithNamedType('EDIT_JOB_COMMENT'),
    tableFilters: createTableFiltersWithName('MANAGE_JOB_COMMENTS'),
    deficiencyCodesByID,
    showVerificationSignatureModal: createShowModalWithNamedType('VERIFICATION_SIGNATURE_PAD')
});

export default manageJobComment;

/*
 * SELECTORS
 */

export const getManageJobCommentById = (state: IinitialState) => state.manageJobComment.jobCommentsByID;
const getShowEditJobCommentModal = (state: IinitialState) => state.manageJobComment.showEditJobCommentModal;
const getShowVerificationSignatureModal = (state: IinitialState) =>
    state.manageJobComment.showVerificationSignatureModal;

export const selectJobCommentsModals = createSelector(
    [getShowEditJobCommentModal, getShowVerificationSignatureModal],
    (editJobCommentModal, verificationSignatureModal) => {
        const anyModalOpen = [editJobCommentModal, verificationSignatureModal].some(modal => modal);

        return anyModalOpen;
    }
);

export const selectDeficiencyCodesByStandardID = (state: { [key: string]: IdeficiencyCode }, standardID: string) => {
    return keyBy(filter(state, { standardID, isDeleted: false }), 'id');
};

export const selectVisibleJobComments = (
    state: { [key: string]: IjobComment },
    tableFilters: ItableFiltersParams,
    jobID: string
) => {
    const { commentType, createDate, userID } = tableFilters;
    const createDateMoment = moment.isMoment(createDate) ? createDate : moment(createDate);

    const filteredJobComments = filter(state, jobComment => {
        let shouldInclude = true;
        if (jobComment.jobID !== jobID) {
            shouldInclude = false;
        }
        if (commentType && commentType.value === false && jobComment.isDeficiency === true) {
            // only show dificiencies
            shouldInclude = false;
        }
        if (commentType && commentType.value === true && jobComment.isDeficiency === false) {
            // only show non-dificiencis
            shouldInclude = false;
        }
        if (createDate && !createDateMoment.isSame(moment.utc(jobComment.createDate), 'day')) {
            shouldInclude = false;
        }
        if (userID && userID.value !== jobComment.userID) {
            shouldInclude = false;
        }
        if (jobComment.isDeleted) {
            shouldInclude = false;
        }

        return shouldInclude;
    });
    return orderBy(filteredJobComments, res => moment.utc(res.createDate).unix(), 'desc');
};
