/*
 * Confirm Select Job Form
 * confirm which job the repair work order should be created for
 */

import * as React from 'react';

import { Button, Col } from 'react-bootstrap';
import { FieldConfig, FormArray, FormGenerator, FormGroup } from 'react-reactive-form';

import { FormUtil } from '../common/FormUtil';
import { Ijob, Ioption, IWorkOrder, IjobWorkOrder } from '../../models';
import { RouteComponentProps } from 'react-router-dom';
import { TFunction } from 'i18next';
import { constants } from '../../constants/constants';
import {
    selectJobIDForWorkOrder,
    addWorkOrdersToJob,
    setSelectedJob,
    toggleEditJobModal,
    updateJobFormValue,
    openJob
} from '../../actions/manageJobActions';
import { addCommissioningInstallBases, addCommissioningInstallBasesToJob } from '../../actions/manageInventoryActions';
import { toastr } from 'react-redux-toastr';
import { toggleAddRepairWorkOrderModal, bulkCreateWorkOrders } from '../../actions/workOrderActions';
import { jobTypesIdEnum, workOrderPrioritiesEnum, workOrderTypesEnum } from '../../models-enums';
import { initialWorkOrder } from '../../reducers/initialState';
import moment from 'moment';
const uuidv4 = require('uuid/v4');

interface Iprops extends RouteComponentProps<any> {
    loading: boolean;
    colorButton?: string;
    t: TFunction;
    jobOptions: Ioption[];
    toggleModal: () => void;
    toggleEditJobModal: typeof toggleEditJobModal;
    toggleAddRepairWorkOrderModal: typeof toggleAddRepairWorkOrderModal;
    show: boolean;
    selectJobIDForWorkOrder: typeof selectJobIDForWorkOrder;
    addWorkOrder: (workOrder: IWorkOrder) => Promise<any>;
    addWorkOrdersToJob: typeof addWorkOrdersToJob;
    selectedInstallID?: string;
    selectedJobID: string;
    selectedJob: Ijob;
    enableBulkInstallBaseMode: boolean;
    bulkCreateWorkOrders: typeof bulkCreateWorkOrders;
    addCommissioningInstallBases: typeof addCommissioningInstallBases;
    addCommissioningInstallBasesToJob: typeof addCommissioningInstallBasesToJob;
    workOrderMode: workOrderTypesEnum;
    selectedMaintenanceWorkOrders: string[];
    clearTableFilter: () => void;
    forSAP: boolean;
    sapWorkOrder: IWorkOrder[] | undefined;
    jobsByID: { [key: string]: Ijob };
    setSelectedJob: typeof setSelectedJob;
    newJobOption?: boolean;
    updateJobFormValue: typeof updateJobFormValue;
    jobWorkOrders: { [key: string]: IjobWorkOrder };
    openJob: typeof openJob;
}
interface State {
    fieldConfig: FieldConfig;
}

export class ConfirmSelectJobForm extends React.Component<Iprops, State> {
    private formGroup: FormGroup | any;
    static defaultProps = {
        colorButton: constants.colors[`blueButton`],
        jobOptions: [],
        selectedJobID: ''
    };
    constructor(props: Iprops) {
        super(props);
        this.state = {
            fieldConfig: this.buildFieldConfig()
        };
    }

    componentDidMount() {
        this.setState({ fieldConfig: this.buildFieldConfig() });
    }

    buildFieldConfig = () => {
        const { selectedJobID } = this.props;
        let selectedJobOption: Ioption | null = null;

        if (selectedJobID.length) {
            const foundJobOption = this.props.jobOptions.find(item => item.value === selectedJobID);

            if (foundJobOption) {
                selectedJobOption = foundJobOption;
            }
        }

        const fieldConfigControls = {
            jobID: {
                render: FormUtil.Select,
                meta: {
                    options: this.props.jobOptions,
                    label: 'manageWorkOrder:existingJob',
                    colWidth: 12,
                    placeholder: 'jobPlaceholder',
                    name: 'job'
                },
                options: {
                    validators: [FormUtil.validators.requiredWithTrim]
                },
                formState: {
                    value: selectedJobOption,
                    disabled: false
                }
            }
        };

        return FormUtil.translateForm(
            {
                controls: { ...fieldConfigControls }
            },
            this.props.t
        );
    };

    exitToJob = (job: Ijob) => {
        this.props.setSelectedJob(job);
        this.props.toggleModal();
        this.props.clearTableFilter();
        this.props.history.push('/devices');
    };

    addCommissionToJob = (job: Ijob) => {
        if (this.props.sapWorkOrder !== undefined) {
            const installBaseIds = this.props.sapWorkOrder.map(wo => {
                return wo.installBaseID;
            });
            this.props.addCommissioningInstallBasesToJob(job.id, installBaseIds);
        } else {
            this.props.addCommissioningInstallBases(job);
        }
    };

    // you can't add more than one WO to an inspection job
    invalidAddWOtoInspectionJob = (job: Ijob, jwos: { [key: string]: IjobWorkOrder }, toAdd: IWorkOrder[] = []) =>
        job.jobTypeID === jobTypesIdEnum.inspection &&
        (toAdd.length > 1 ||
            (toAdd.length > 0 && Object.values(jwos).find(jwo => jwo.jobID === job.id && !jwo.isDeleted)));

    // TODO this is pretty fragile code.  It might help to put this logic inside an action
    handleSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (this.formGroup.status === 'INVALID') {
            this.formGroup.markAsSubmitted();
            toastr.error('Please check invalid inputs', '', constants.toastrError);
            return;
        }
        const job = this.props.jobsByID[this.formGroup.value.jobID.value];
        if (this.props.forSAP) {
            if (this.invalidAddWOtoInspectionJob(job, this.props.jobWorkOrders, this.props.sapWorkOrder)) {
                toastr.error(this.props.t('sapWOInspectionJobError'), '', constants.toastrError);
                return;
            }
            if (this.props.sapWorkOrder !== undefined) {
                const workOrderIds = this.props.sapWorkOrder.map(wo => {
                    return wo.id;
                });
                // Assign the WorkOrders to the Job, which creates JobWorkOrders
                this.props.addWorkOrdersToJob(workOrderIds, this.formGroup.value.jobID.value, this.props.t, true);

                this.exitToJob(job);

                return;
            } else {
                console.error('InstallBase ID is required for SAP');
                return;
            }
        }

        // this portion of code is from the maintenence view and selecting maintenance work orders
        if (this.props.workOrderMode === workOrderTypesEnum.pmp) {
            this.props.addWorkOrdersToJob(
                this.props.selectedMaintenanceWorkOrders,
                this.formGroup.value.jobID.value,
                this.props.t
            );
            this.exitToJob(job);
            return;
        }

        // this portion of code is from the Repair or Warranty view when selecting installBases
        if (
            (!this.props.forSAP &&
            !this.props.selectedInstallID && // If we have selected a device we will create repair or maintenance workOrders
                this.props.workOrderMode === workOrderTypesEnum.repair) ||
            this.props.workOrderMode === workOrderTypesEnum.warranty
        ) {
            this.props.bulkCreateWorkOrders(
                this.formGroup.value.jobID.value,
                this.props.t,
                this.props.selectedInstallID
            );
            this.exitToJob(job);
            return;
        }

        // To get to this portion of the code, it's easiest to be inside a single Repair WO or Warranty WO and click the Repair tab
        if (this.props.selectedInstallID && !this.props.forSAP) {
            const newWorkOrder: IWorkOrder = {
                ...initialWorkOrder,
                id: uuidv4(),
                priority: workOrderPrioritiesEnum.highRisk,
                dueDate: moment.utc().format(constants.momentSQLFormat),
                installBaseID: this.props.selectedInstallID,
                source: this.props.forSAP ? 1 : 0
            };

            this.props.addWorkOrdersToJob([newWorkOrder.id], this.formGroup.value.jobID.value, this.props.t);
        } else {
            console.error('[handleSubmit]: submitting confirmSelectJobForm but no conditionals matched true');
        }

        this.props.toggleModal();
    };

    setForm = (form: FormGroup | FormArray) => {
        this.formGroup = form;
        this.formGroup.meta = {
            loading: this.props.loading
        };
    };

    newJob = () => {
        this.props.toggleEditJobModal();
    };

    render() {
        const { t } = this.props;

        return (
            <form onSubmit={this.handleSubmit} className="clearfix beacon-form">
                <FormGenerator onMount={this.setForm} fieldConfig={this.state.fieldConfig} />
                <Col xs={12}>
                    <p style={{ margin: '0 auto' }}>{t('addWorkOrderToJobDescription')}</p>
                </Col>
                <Col xs={12} className="form-buttons text-right">
                    <Button bsStyle="default" type="button" className="pull-left" onClick={this.props.toggleModal}>
                        {t('common:cancel')}
                    </Button>
                    {this.props.newJobOption && (
                        <Button style={{ marginRight: '15px' }} bsStyle="default" type="button" onClick={this.newJob}>
                            {t('saveModalTitle')}
                        </Button>
                    )}
                    <Button bsStyle={this.props.colorButton} type="submit" disabled={this.props.loading}>
                        {t('mobile.confirmJobModal.save')}
                    </Button>
                </Col>
            </form>
        );
    }
}
