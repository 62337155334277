import * as types from './actionTypes';
import WebWorker from '../webWorkers/workerSetup';
import worker from '../webWorkers/worker';
import { ThunkResult } from '../models';
import { acquireToken } from '../components/auth/Auth-Utils';
import { appSyncItemNames } from '../constants/constants';
import { updateVisibleProducts } from './manageInventoryActions';
import { beginAjaxCall, endAjaxCall } from './ajaxStatusActions';
import moment from 'moment';
import Queue from 'queue-promise';
import { AxiosRequestConfig } from 'axios';
import { AppSyncItemStatus } from '../models-enums';
import localforage from 'localforage';

/*
 * Next Steps
 * one issue right now is that when getting Products or getting results fails - the user will see an error toast then a success toast, then it will log them out
 * We can change the getAllProductsHelper into a promise that waits for the whole thing to complete, but then this will
 * cause the downloading hospital data full screen loading to show for longer.
 */

let myWorker: WebWorker | undefined; // since this is outside of the action function, it might be loading inside the MSAL refresh iFrame

export const getAllProductsHelper = (showError: boolean = true): ThunkResult<any> => {
    return (dispatch, getState) => {
        return acquireToken().then(async authResponse => {
            if (!authResponse || (authResponse && !authResponse.accessToken)) {
                throw new Error('failed to retrieve token.  Likely because 3rd party cookies are disabled');
            }

            // If we have products, we only want to get the delta
            let updateDate: string | undefined | null = await localforage.getItem('med-gas-mobile_all-products_delta');

            const token = authResponse.accessToken;
            if (myWorker) {
                dispatch({
                    type: types.UPDATE_INITIAL_APP_SYNC_ITEM_STATUS,
                    name: appSyncItemNames.Products,
                    status: AppSyncItemStatus.inProgress
                });

                myWorker.postMessage({ action: 'getAllProducts', token, showError, updateDate });
            }
        });
    };
};

const getAllProductsSuccess = (): ThunkResult<any> => {
    return dispatch => {
        dispatch(endAjaxCall());
        dispatch({ type: types.REMOVE_INITIAL_APP_SYNC_ITEM, name: appSyncItemNames.Products });
        dispatch(updateVisibleProducts());
    };
};
export const getJobSimpleMeasurementPointResults = (jobIDs: string[]): ThunkResult<any> => {
    return dispatch => {
        dispatch(beginAjaxCall());
        return acquireToken().then(authResponse => {
            if (!authResponse || (authResponse && !authResponse.accessToken)) {
                throw new Error('failed to retrieve token.  Likely because 3rd party cookies are disabled');
            }
            const token = authResponse.accessToken;
            if (myWorker) {
                myWorker.postMessage({
                    action: 'getSimpleJobMeasurementPointResults',
                    token,
                    jobIDs
                });
            }
        });
    };
};

const getJobSimpleMeasurementPointResultsSuccess = (data: { results: any; jobIDs: string[] }): ThunkResult<any> => {
    return dispatch => {
        dispatch({
            type: types.GET_SIMPLE_MEASUREMENT_POINT_JOB_RESULTS_SUCCESS,
            results: data.results,
            jobIDs: data.jobIDs,
            updateDate: moment().unix()
        });
    };
};

const queue = new Queue({
    concurrent: 1,
    interval: 100,
    start: false
});
queue.on('resolve', data => data);
queue.on('reject', error => console.error('[queue (reject)]:', error));

//const installBasesByID = useSelector(selectInstallBasesByID);

export const getMeasurementPointListResultsForJobsHelper = (jobId: string, facilityID: string): ThunkResult<any> => {
    return (dispatch, getState) => {
        return acquireToken().then(authResponse => {
            if (!authResponse || (authResponse && !authResponse.accessToken)) {
                throw new Error('failed to retrieve token. Likely because 3rd party cookies are disabled');
            }
            const installs = getState().manageInventory.installBasesByID;
            const installsForFacilityNumber = Object.values(installs).filter(
                install => install.facilityID === facilityID
            ).length;

            const root = process.env.REACT_APP_SERVER_DOMAIN;
            const token = authResponse.accessToken;
            const batchAmount = 500;
            const numberOfRuns = Math.ceil(installsForFacilityNumber / batchAmount);

            // If the facility has no installs, we still want to dispatch the success action with an empty payload
            if (numberOfRuns === 0) {
                dispatch({
                    type: types.GET_MEASUREMENT_POINT_FACILITY_RESULTS_SUCCESS,
                    results: [],
                    facilityID: facilityID,
                    updateDate: moment().unix()
                });
            } else {
                for (let i = 0; i < numberOfRuns; i++) {
                    let axiosOptions: AxiosRequestConfig = {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            Accept: 'application/json',
                            'Content-Type': 'application/json'
                        },
                        method: 'get',
                        timeout: 1000 * 60 * 5,
                        url: `${root}/MeasurementPoint/GetPagedMeasurementPointListResultsForJob?JobID=${jobId}&page=${i}&perPage=${batchAmount}`
                    };

                    dispatch({
                        type: types.GET_MEASUREMENT_POINT_FACILITY_RESULTS,
                        meta: {
                            offline: {
                                effect: { axiosOptions, message: 'Get MPLR for Job' },
                                commit: {
                                    type: types.GET_MEASUREMENT_POINT_FACILITY_RESULTS_SUCCESS,
                                    facilityID: facilityID,
                                    updateDate: moment().unix()
                                }
                            }
                        }
                    });
                }
            }
        });
    };
};

const getFacilityMeasurementPointResultsSuccess = (data: { results: any; facilityID: string }): ThunkResult<any> => {
    return dispatch => {
        dispatch({
            type: types.GET_MEASUREMENT_POINT_FACILITY_RESULTS_SUCCESS,
            results: data.results,
            facilityID: data.facilityID,
            updateDate: moment().unix()
        });
        queue.dequeue(); // trigger the next item in the queue
        if (queue.size === 0) {
            dispatch(endAjaxCall());
        }
    };
};

/*
 * 3/7/24 - Don't log out the user, if an api call fails
 * TODO - Might be good to add a toast error back, if the user is online, and only recieved a network error
 * If you're offline and it fails, we must assume it's because you're offline, but that doesn't warrent a logout
 */
export function initWorker(): ThunkResult<any> {
    return (dispatch, getState) => {
        myWorker = new WebWorker(worker);
        myWorker.addEventListener('message', (event: any) => {
            switch (event.data.action) {
                case 'downloadProgress': {
                    dispatch({ type: types.SET_DOWNLOAD_SPEED, downloadSpeed: event.data.data });
                    break;
                }
                case 'getAllProductsSucess': {
                    dispatch(getAllProductsSuccess());
                    break;
                }
                case 'getResultsSuccess': {
                    dispatch(getFacilityMeasurementPointResultsSuccess(event.data));
                    break;
                }
                case 'getResultsFailed': {
                    dispatch(endAjaxCall());
                    console.error('[initWorker -> getResultsFailed]:', event, event.data);
                    // if (errorCount < 1) {
                    //     toastr.error(
                    //         'Error',
                    //         'Downloading hospital data failed.  Logging out... Please login and try again or contact support.',
                    //         constants.toastrError
                    //     );
                    //     errorCount++;
                    // }
                    // setTimeout(() => {
                    //     document.dispatchEvent(new CustomEvent('startUserLogoutSessionOnly'));
                    //     errorCount = 0;
                    // }, 120000);
                    break;
                }
                case 'getSimpleResultsSuccess': {
                    dispatch(getJobSimpleMeasurementPointResultsSuccess(event.data));
                    break;
                }
                case 'getSimpleResultsFailed': {
                    dispatch(endAjaxCall());
                    console.error('[initWorker -> getSimpleResultsFailed]:', event, event.data);
                    // if (errorCount < 1) {
                    //     toastr.error(
                    //         'Error',
                    //         'Downloading hospital data failed.  Logging out... Please login and try again or contact support.',
                    //         constants.toastrError
                    //     );
                    //     errorCount++;
                    // }
                    // setTimeout(() => {
                    //     document.dispatchEvent(new CustomEvent('startUserLogoutSessionOnly'));
                    //     errorCount = 0;
                    // }, 120000);
                    break;
                }
                case 'getAllProductsFailed': {
                    dispatch(endAjaxCall());
                    dispatch({
                        type: types.UPDATE_INITIAL_APP_SYNC_ITEM_STATUS,
                        name: appSyncItemNames.Products,
                        status: AppSyncItemStatus.failed
                    });
                    console.error('[initWorker -> getAllProductsFailed]:', event, event.data);
                    // if (errorCount < 1) {
                    //     toastr.error(
                    //         'Error',
                    //         'Downloading hospital data failed.  Logging out... Please login and try again or contact support.',
                    //         constants.toastrError
                    //     );
                    //     errorCount++;
                    // }
                    // setTimeout(() => {
                    //     document.dispatchEvent(new CustomEvent('startUserLogoutSessionOnly'));
                    //     errorCount = 0;
                    // }, 120000);
                    break;
                }

                default:
                    break;
            }
        });

        // error initializing the worker
        myWorker.addEventListener('error', (event: any) => {
            console.error('[initWorker]: recieved error', event);
        });
    };
}
