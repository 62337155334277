/*
 * The HeaderMenu only displays if we have an authenticated user.
 * It is responsible for displaying the welcome message and the dropdown menu for logged in users
 * spinner is from : http://tobiasahlin.com/spinkit/
 */

import * as React from 'react';

import { WithTranslation, withTranslation } from 'react-i18next';
import {
    downloadAppState,
    offlineStatusOverrideOn,
    offlineStatusOverrideReset,
    resetOutbox,
    startSync
} from '../../actions/commonActions';
import { toggleEditProfileModal, userLogout } from '../../actions/userActions';
import { toastr } from 'react-redux-toastr';

import { Badge, Button } from 'react-bootstrap';
import CubeLoader from './CubeLoader';
import DropDownMenu from './DropDownMenu';
import EditProfileModal from './EditProfileModal';
import { IinitialState } from '../../reducers';
import { Iuser } from '../../models';
import OfflineStatusOverrideIndicator from './OfflineStatusOverrideIndicator';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { selectIsLoading, selectIsOnlineAndEmptyQueue } from '../../reducers/commonReducers';
import { jobTypesIdEnum } from '../../models-enums';
import { constants } from '../../constants/constants';
import { saveQuoteCart, setSelectedCart, getAllParts } from '../../actions/partsActions';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getTotal } from '../../reducers/cartReducer';
import { LinkContainer } from 'react-router-bootstrap';
import AddNPHModalContainer from '../manageJob/modals/AddNPHModalContainer';
import HelpManual from '../../images/icons/BM_HelpManual.svg';

interface Iprops extends RouteComponentProps {
    user: Iuser;
    userLogout: typeof userLogout;
    loading: boolean;
    toggleEditProfileModal: typeof toggleEditProfileModal;
    hasJob: boolean;
    jobType: jobTypesIdEnum;
    outbox: any[];
    retryScheduled: boolean;
    cartTotal: number;
    startSync: typeof startSync;
    resetOutbox: typeof resetOutbox;
    online: boolean;
    downloadAppState: typeof downloadAppState;
    offlineStatusOverride: boolean;
    offlineStatusOverrideReset: typeof offlineStatusOverrideReset;
    offlineStatusOverrideOn: typeof offlineStatusOverrideOn;
    setSelectedCart: () => void;
    syncInProgress: boolean;
    isAPIQueueEmpty: boolean;
}

interface Istate {
    menuOpen: boolean;
    showOfflineQueueStatus: boolean;
    showOfflineQueueReset: boolean;
    localCartTotal: number;
    showNonProductiveJobHours: boolean;
}

class Header extends React.Component<Iprops & WithTranslation, Istate> {
    private updateOfflineStatusTimeout: any;
    constructor(props: Iprops & WithTranslation) {
        super(props);
        this.state = {
            menuOpen: false,
            showOfflineQueueStatus: false,
            showOfflineQueueReset: false,
            localCartTotal: 0,
            showNonProductiveJobHours: false
        };
    }
    componentDidMount() {
        if (this.props.outbox.length || process.env.NODE_ENV === 'development') {
            let showOfflineQueueReset = false;
            if (process.env.NODE_ENV === 'development') {
                showOfflineQueueReset = false;
            }
            this.setState({
                showOfflineQueueStatus: true,
                showOfflineQueueReset
            });
        }
        this.props.setSelectedCart();
    }
    componentDidUpdate(prevProps: Iprops) {
        if (this.props.outbox.length !== prevProps.outbox.length) {
            if (this.props.outbox.length) {
                this.updateOfflineStatusTimeout = setTimeout(() => {
                    this.setState({ showOfflineQueueStatus: true });
                }, 3000);
            } else {
                clearTimeout(this.updateOfflineStatusTimeout);
                this.setState({ showOfflineQueueStatus: false });
            }
        }
    }
    handleMenuSelect = (eventKey: any) => {
        switch (eventKey) {
            case '1':
                this.props.toggleEditProfileModal();
                break;
            case '2':
                this.props.offlineStatusOverrideOn();
                break;
            case '3':
                this.props.userLogout(this.props.t);
                break;
            case '4':
                this.props.offlineStatusOverrideReset();
                break;
            case '5':
                this.setState({
                    showNonProductiveJobHours: true
                });
                break;
            default:
                break;
        }
    };

    isEmailDomainInternal(email: string): boolean {
        const internalDomains = ['@beaconmedaes.com', '@atlascopco.com', '@thebigpixel.net', '@class1.com'];

        for (const domain of internalDomains) {
            if (email.includes(domain)) {
                return true;
            }
        }

        return false;
    }

    render() {
        const { t, cartTotal } = this.props;
        if (!this.props.user.isAuthenticated) {
            return <CubeLoader show={this.props.loading} />;
        }

        const menuClass = this.state.menuOpen ? 'menu-open' : '';

        return (
            <span>
                <CubeLoader show={this.props.loading} />
                <OfflineStatusOverrideIndicator
                    offlineStatusOverride={this.props.offlineStatusOverride}
                    offlineStatusOverrideReset={this.props.offlineStatusOverrideReset}
                />

                {/* when offline gets stuck, use this */}
                <span className="offline-reset-status" style={{ display: 'flex' }}>
                    {this.props.outbox.length > 0 && (
                        <div>
                            <span
                                className="pending-text"
                                onDoubleClick={() => {
                                    this.setState({
                                        showOfflineQueueReset: !this.state.showOfflineQueueReset
                                    });
                                }}
                            >
                                {this.props.outbox.length} item(s) pending.
                            </span>

                            {this.state.showOfflineQueueReset && (
                                <Button type="button" bsStyle="default" onClick={this.props.resetOutbox} bsSize="sm">
                                    {t('reset')}
                                </Button>
                            )}
                        </div>
                    )}

                    {!this.props.syncInProgress && (
                        <Button
                            type="button"
                            bsStyle="default"
                            disabled={this.props.loading}
                            onClick={() => {
                                if (!this.props.online) {
                                    toastr.warning(
                                        t('toastMessage:offline'),
                                        t('toastMessage:onlineRequired'),
                                        constants.toastrWarning
                                    );
                                    return;
                                }
                                this.props.startSync();
                            }}
                            bsSize="sm"
                        >
                            {t('sync')}
                        </Button>
                    )}

                    {this.props.syncInProgress && (
                        <FontAwesomeIcon style={{ marginLeft: '8px' }} icon={faSpinner} spin={true} size="lg" />
                    )}

                    <div className="help-button-wrap">
                        <img
                            src={HelpManual}
                            alt="icon"
                            className="help-icon"
                            onClick={() => {
                                window.open(
                                    this.isEmailDomainInternal(this.props.user.email)
                                        ? 'https://doc.clickup.com/9012013137/d/h/8cjgu2h-3292/26881703f6fb6c9'
                                        : 'https://doc.clickup.com/9012013137/p/h/8cjgu2h-3932/605e4a1c68aef87',
                                    '_blank'
                                );
                            }}
                            height="28"
                            width="28"
                        />
                    </div>

                    {/* <OnlineIndicatorButton doubleClick={this.props.downloadAppState} online={this.props.online} /> */}
                </span>
                <span className="profile">
                    <span className="profile-text">
                        <span className="welcome-text">
                            {t('welcome')}
                            &nbsp;
                        </span>

                        <span className="name">{this.props.user.first}</span>
                        <LinkContainer to="/quote_part_cart">
                            <span className="shop-cart">
                                <Button
                                    type="button"
                                    bsStyle="primary"
                                    bsSize="sm"
                                    disabled={!cartTotal}
                                    style={{
                                        padding: ' 4px 18px',
                                        fontSize: '1.5rem',
                                        marginBottom: '4px'
                                    }}
                                >
                                    <FontAwesomeIcon icon="shopping-cart" />
                                    {cartTotal > 0 && cartTotal > 0 && (
                                        <Badge className="animate-badge">{cartTotal} </Badge>
                                    )}
                                </Button>
                            </span>
                        </LinkContainer>
                        <span className="vertical" />
                    </span>
                    <DropDownMenu
                        toggle={isOpen => {
                            this.setState({ menuOpen: isOpen });
                        }}
                        handleMenuSelect={this.handleMenuSelect}
                        menuClass={menuClass}
                        jobType={this.props.jobType}
                        hasJob={this.props.hasJob}
                        offlineStatusOverride={this.props.offlineStatusOverride}
                        online={this.props.online}
                        user={this.props.user}
                        loading={this.props.loading}
                        startSync={this.props.startSync}
                        downloadAppState={this.props.downloadAppState}
                        isAPIQueueEmpty={this.props.isAPIQueueEmpty}
                    />
                </span>
                <EditProfileModal colorButton="primary" />
                <AddNPHModalContainer
                    colorButton={constants.colors.blueButton}
                    show={this.state.showNonProductiveJobHours}
                    onClose={() => {
                        this.setState({
                            showNonProductiveJobHours: false
                        });
                    }}
                />
            </span>
        );
    }
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops) => {
    let cartTotal = getTotal(state.selectedCart);
    // If -1 then selectedCart in redux is empty, need to verify that quoteCartsById doesn't have anything
    if (cartTotal === -1) {
        cartTotal = 0;
        setSelectedCart();
    }
    return {
        user: state.user,
        hasJob: state.manageJob.selectedJob.id.length > 0,
        jobType: state.manageJob.selectedJob.jobTypeID as jobTypesIdEnum,
        loading: selectIsLoading(state),
        outbox: state.offline.outbox,
        retryScheduled: state.offline.retryScheduled,
        online: state.offline.online,
        offlineStatusOverride: state.offlineStatusOverride,
        cartTotal,
        syncInProgress: state.syncManager.isSyncRunning,
        isAPIQueueEmpty: selectIsOnlineAndEmptyQueue(state)
    };
};

export default withTranslation('common')(
    connect(
        mapStateToProps,
        {
            saveQuoteCart,
            userLogout,
            getAllParts,
            toggleEditProfileModal,
            startSync,
            resetOutbox,
            downloadAppState,
            offlineStatusOverrideReset,
            offlineStatusOverrideOn,
            setSelectedCart
        },
        null,
        { pure: false }
    )(Header)
);
