import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { QuotePartShoppingCartContainer } from './QuotePartShoppingCartContainer';

interface Iprops extends RouteComponentProps<any> {
    nothing: string;
}

const QuotePartCartLayoutComponent = ({ t }: Iprops & WithTranslation) => {
    return (
        <div className="quote-part-shopping-cart">
            <Row>
                <Col xs={12}>
                    <h2 style={{ paddingLeft: '20px' }}>{t('title')}</h2>
                </Col>
            </Row>
            <Row style={{ paddingTop: '20px' }}>
                <Col xs={12} md={12}>
                    <QuotePartShoppingCartContainer cartName="QUOTE_PARTS" colorButton="primary" />
                </Col>
                <Col xs={12} md={6}></Col>
            </Row>
        </div>
    );
};

export const QuotePartCartLayout = withTranslation('nsPart')(QuotePartCartLayoutComponent);
