import * as React from 'react';
import { CheckedItemStatus, IcheckedItems } from '../../models';
import ConfirmModal from '../common/ConfirmModal';
import { useDispatch, useSelector } from 'react-redux';
import { getCompleteJobCheckItems } from '../../reducers/manageInventoryReducer';
import { TFunction } from 'i18next';
import { Button } from 'react-bootstrap';
import { getIsMobile } from '../../reducers/configReducer';
import MobileModalAlt from '../common/mobile/MobileModalAlt';
import { completeJobAPI, setJobFinalCheckMoal } from '../../actions/manageJobActions';
import { getSelectedJob } from '../../reducers/commonSelectors';
import finalCheckIcon from '../../images/icons/final-check.svg';
import { constants } from '../../constants/constants';
import { selectOutbox } from '../../reducers/commonReducers';
import { startSync } from '../../actions/commonActions';
import { selectIsSyncRunning } from '../../reducers/syncManagerReducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { TOGGLE_MODAL_EDIT_FACILITY_CONTACTS } from '../../actions/actionTypes';
import { securityFunctions } from '../../constants/securityFunctions';
import { IinitialState } from '../../reducers';

interface Iprops {
    t: TFunction;
}

export function JobFinalCheckModal(props: Iprops) {
    const [checkList, setCheckList] = React.useState<IcheckedItems[]>([]);
    const completeJobCheckItems = useSelector(getCompleteJobCheckItems);
    const isMobile = useSelector(getIsMobile);
    const job = useSelector(getSelectedJob);
    const [canCloseJob, setCanCloseJob] = React.useState(false);
    const dispatch = useDispatch();
    const outbox = useSelector(selectOutbox);
    const isSyncRunning = useSelector(selectIsSyncRunning);
    const user = useSelector((state: IinitialState) => state.user);
    const [closeJobWhenReady, setCloseJobWhenReady] = React.useState(false);

    const onOk = () => {
        setCloseJobWhenReady(false); // Reset this flag, just in case state is retained if you close this modal and reopen it??
        dispatch(setJobFinalCheckMoal(false));
        const jobToClose = { ...job, status: 'Completed' };
        dispatch(completeJobAPI(jobToClose));
    };

    const close = () => {
        setCloseJobWhenReady(false);
        dispatch(setJobFinalCheckMoal(false));
    };

    const syncOutbox = () => {
        if (canCloseJob) setCloseJobWhenReady(true);

        dispatch(startSync());
    };

    const anyPendingOutboxItems = () => {
        if (outbox.length > 0) {
            return true;
        }

        return false;
    };

    React.useEffect(() => {
        // When the component unmounts, make sure the modal is closed
        // we had a live bug where users somehow left the page, maybe refreshed the browser? Or closed the tab and opened a new one?
        // and this value never got reset.
        return () => {
            dispatch(setJobFinalCheckMoal(false));
        };
    }, []);

    React.useEffect(() => {
        if (closeJobWhenReady && !isSyncRunning && !anyPendingOutboxItems()) {
            // User has clicked to close the job after syncing the outbox, let's close the modal and the job
            onOk();
        }
    }, [isSyncRunning, outbox]);

    React.useEffect(() => {
        let canClose = true;

        completeJobCheckItems.forEach(item => {
            if (item.applicable && !item.optional && item.passed === CheckedItemStatus.fail) {
                canClose = false;
            }
        });

        if (anyPendingOutboxItems()) {
            canClose = false;
        }

        const syncChecklist: IcheckedItems = {
            primaryText: 'Pending changes are synced',
            secondaryText: '(Mandatory)',
            passed: anyPendingOutboxItems() ? CheckedItemStatus.fail : CheckedItemStatus.pass,
            optional: false,
            applicable: true
        };

        setCheckList([syncChecklist, ...completeJobCheckItems]);
        setCanCloseJob(canClose);
    }, [completeJobCheckItems, outbox]);

    const getItemClass = (item: IcheckedItems) => {
        return `${getValitiyClass(item)} ${getApplicableClass(item)}`;
    };

    const getValitiyClass = (item: IcheckedItems): string => {
        if (item.contacts) {
            return 'neutral';
        }
        switch (item.passed) {
            case CheckedItemStatus.fail: {
                return 'invalid';
            }
            case CheckedItemStatus.pass: {
                return 'valid';
            }
            default: {
                return 'neutral';
            }
        }
    };

    const getApplicableClass = (item: IcheckedItems) =>
        item.applicable || (!item.applicable && item.passed === CheckedItemStatus.pass) ? 'applicable' : 'inapplicable';

    const mobileTilteStyle: any = {
        background: 'var(--blues-blue-main, #007BC3)',
        color: 'var(--white, #FFF)',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        padding: '16px',
        display: 'flex',
        alignItems: 'center',
        gap: '8px'
    };

    const title = (
        <div style={isMobile ? mobileTilteStyle : {}} className="title">
            <img
                style={
                    isMobile
                        ? { width: '40px', height: '40px', flexShrink: 0 }
                        : { width: '52px', height: '52px', paddingBottom: '12px' }
                }
                src={finalCheckIcon}
            />
            <span>{props.t('nsJob:finalCheck')} </span>
        </div>
    );

    const buttons = (
        <>
            <Button onClick={close}>{props.t('common:cancel')}</Button>

            {isSyncRunning && <FontAwesomeIcon style={{ marginLeft: '16px' }} icon={faSpinner} spin={true} size="lg" />}

            {anyPendingOutboxItems() && !isSyncRunning && (
                <Button
                    style={{ marginLeft: '24px' }}
                    bsStyle="primary"
                    onClick={() => {
                        syncOutbox();
                    }}
                >
                    {canCloseJob ? props.t('common:syncAndClose') : props.t('common:sync')}
                </Button>
            )}

            {!anyPendingOutboxItems() && !isSyncRunning && (
                <Button
                    style={{ marginLeft: '24px' }}
                    bsStyle="primary"
                    disabled={!canCloseJob}
                    onClick={() => {
                        onOk();
                    }}
                >
                    {props.t('nsJob:closeJob')}
                </Button>
            )}
        </>
    );

    const mobileFooter = <div className={'form-buttons'}>{buttons}</div>;

    const webFooter = (
        <div className="footer" style={{ textAlign: 'center' }}>
            <p style={{ paddingBottom: '24px' }}>{props.t('manageInventory:finalJobCheckQuestion')}</p>
            {buttons}
        </div>
    );

    const content = (
        <div className="content">
            <ul>
                {checkList.map((item, index) => (
                    <React.Fragment key={item.primaryText}>
                        <li key={index} className={getItemClass(item)}>
                            <span> {item.primaryText}</span>
                            {item.contacts &&
                                constants.hasSecurityFunction(user, securityFunctions.FSEFacilityContactEdit.id) && (
                                    <a
                                        href="/"
                                        onClick={e => {
                                            e.preventDefault();
                                            close();
                                            dispatch({ type: TOGGLE_MODAL_EDIT_FACILITY_CONTACTS });
                                        }}
                                    >
                                        {' '}
                                        - Edit Facility Contacts
                                    </a>
                                )}
                        </li>
                        {item.contacts && (
                            <>
                                {item.contacts.map((contact, subIndex) => (
                                    <li className={'facility-contact'} key={subIndex}>
                                        <span>{`${contact.user?.first || contact.firstName} ${contact.user?.last ||
                                            contact.lastName}; ${contact.user?.email ||
                                            contact.email}; Notications: `}</span>
                                        <span
                                            style={{
                                                color: contact.enableNotifications
                                                    ? constants.colors.green
                                                    : constants.colors.red
                                            }}
                                        >
                                            {contact.enableNotifications ? 'ON' : 'OFF'}
                                        </span>
                                    </li>
                                ))}
                            </>
                        )}
                    </React.Fragment>
                ))}
            </ul>
        </div>
    );

    if (!isMobile) {
        return (
            <>
                <div className="backdrop">
                    <ConfirmModal>
                        <div className="modal-content final-check">
                            {title}
                            {content}
                            {webFooter}
                        </div>
                    </ConfirmModal>
                </div>
            </>
        );
    } else {
        return (
            <MobileModalAlt show={true} onHide={close} className="" title={title} headerStyle={{ padding: '0px' }}>
                <div className="modal-content final-check final-check-mobile">
                    {content}
                    {mobileFooter}
                </div>
            </MobileModalAlt>
        );
    }
}
