/*
 * RiskAssessmentForm
 */

import * as React from 'react';

import { Button, Col } from 'react-bootstrap';
import { FieldConfig, FormArray, FormGenerator, FormGroup, Validators } from 'react-reactive-form';

import { FormUtil } from '../common/FormUtil';
import { TFunction } from 'i18next';
import { constants } from '../../constants/constants';
import { toastr } from 'react-redux-toastr';
import { updateJob } from '../../actions/manageJobActions';
import { Ijob } from '../../models';
import RiskAssessmentList from './RiskAssessmentList';

interface Iprops {
    loading: boolean;
    colorButton: string;
    t: TFunction;
    closeModal: () => void;
    toggleViewJobModal: () => void;
    updateJob: typeof updateJob;
    selectedJob: Ijob;
}

interface Istate {
    fieldConfig: FieldConfig;
}

export class RiskAssessmentForm extends React.Component<Iprops, Istate> {
    private formGroup: FormGroup | any;

    constructor(props: Iprops) {
        super(props);
        this.state = {
            fieldConfig: this.buildFieldConfig()
        };
    }
    componentDidMount() {
        this.setState({
            fieldConfig: this.buildFieldConfig({})
        });
    }

    loadOptions = [
        { label: 'I am OK to commence work', value: true },
        { label: 'I am not OK to commence work', value: false }
    ];

    buildFieldConfig = (defaultValues = {}) => {
        const disabled = false;

        // Field config to configure form
        let fieldConfigControls = {
            riskAssessment: {
                options: {
                    validators: [Validators.required]
                },
                render: FormUtil.Select,
                meta: {
                    label: 'mobile.viewRiskAssessmentModal.riskAssessment',
                    colWidth: 12,
                    options: this.loadOptions,
                    name: 'Risk Assessment',
                    required: true,
                    isMulti: false
                },
                formState: {
                    value: null,
                    disabled
                }
            }
        };

        const fieldConfig = {
            controls: { ...fieldConfigControls }
        };
        return FormUtil.translateForm(fieldConfig, this.props.t);
    };

    handleSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (this.formGroup.status === 'INVALID') {
            this.formGroup.markAsSubmitted();
            toastr.error('Please check invalid inputs', '', constants.toastrError);
            return;
        }
        this.props.updateJob({
            ...this.props.selectedJob,
            isRiskAccepted: this.formGroup.value.riskAssessment.value
        });
        this.props.closeModal();
    };
    setForm = (form: FormGroup | FormArray) => {
        this.formGroup = form;
    };

    render() {
        const { t } = this.props;

        const formClassName = `clearfix job-form beacon-form ${this.props.colorButton}`;

        return (
            <form onSubmit={this.handleSubmit} className={formClassName}>
                <FormGenerator onMount={this.setForm} fieldConfig={this.state.fieldConfig} />
                <RiskAssessmentList />
                <Col xs={12} className="form-buttons text-right">
                    <Button
                        bsStyle="default"
                        type="button"
                        className="pull-left"
                        onClick={() => {
                            this.props.toggleViewJobModal();
                            this.props.closeModal();
                        }}
                    >
                        {t('common:cancel')}
                    </Button>
                    <Button bsStyle={this.props.colorButton} type="submit">
                        {t('mobile.viewRiskAssessmentModal.saveButton')}
                    </Button>
                </Col>
            </form>
        );
    }
}
