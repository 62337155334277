/*
 * Manage Install - Container
 */

import { IinstallBasePopulated } from '../../models';
import {
    deleteInstall,
    saveInstall,
    toggleEditInstallModal,
    updateInstall,
    bulkUpdateInstalls,
    setEnableInstallBatchMode,
    setInstallFormValues,
    updateInstallFormValues
} from '../../actions/manageInventoryActions';

import EditInstallForm from './EditInstallForm';
import { RouteComponentProps } from 'react-router';
import { TFunction } from 'i18next';
import { cleanFacility } from '../../reducers/facilitiesReducer';
import { connect } from 'react-redux';
import { saveAnyLocation } from '../../actions/manageLocationActions';
import { withTranslation, WithTranslation } from 'react-i18next';
import { IinitialState } from '../../reducers';
import { selectIsLoading, selectIsOnlineAndEmptyQueue } from '../../reducers/commonReducers';
import { filter } from 'lodash';
import { initialMeasurmentPointResult } from '../../reducers/initialState';

interface Iprops extends RouteComponentProps<any> {
    selectedItem: IinstallBasePopulated;
    colorButton: string;
    t: TFunction;
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops & WithTranslation) => {
    const cleanedFacility = cleanFacility(state.facilities.facilitiesByID[state.manageJob.selectedJob.facilityID]);
    const selectedJob = state.manageJob.selectedJob;
    let selectedProduct = ownProps.selectedItem.product?.id
        ? ownProps.selectedItem.product
        : state.manageInventory.selectedProduct;
    let mainCategoryID = '';

    // Check if selectedProduct actually got set, if it didn't, check if the user is bulk editing assets
    if (selectedProduct?.id === '') {
        if (state.manageInventory.selection && state.manageInventory.selection.length > 0) {
            // They are bulk editing, so let's populate the form with the 1st one?
            const selectedInstallBaseIDs = state.manageInventory.selection.map(item => {
                return item.split('select-')[1];
            });

            const filteredInstallBases = filter(
                state.manageInventory.installBasesByID,
                installBase => selectedInstallBaseIDs.indexOf(installBase.id) !== -1
            );
            if (filteredInstallBases.length > 0) {
                const filteredProducts = filteredInstallBases.map(value => {
                    return state.manageInventory.productsByID[value.productID];
                });

                // For now, only going to populate the modal if 1 installbase was selected
                // If you are editing multiple, we are not prepopulating, and only changing
                // data that is entered new
                if (filteredProducts.length === 1) {
                    selectedProduct = filteredProducts[0];
                    const populated: IinstallBasePopulated = {
                        product: selectedProduct,
                        ...filteredInstallBases[0],
                        productNameString: '',
                        locationString: '',
                        activityDescriptionString: undefined,
                        woNumberString: undefined,
                        workOrderID: undefined,
                        latestMeasurementPointListResult: initialMeasurmentPointResult,
                        originalMeasurementPointListResultStatus: 0,
                        isVirtual: false
                    };

                    ownProps.selectedItem = populated;
                }
            }
        }
    }

    if (selectedProduct && selectedProduct.subcategoryID) {
        mainCategoryID = state.productInfo.subcategories[selectedProduct.subcategoryID].mainCategoryID;
    }

    return {
        user: state.user,
        loading: selectIsLoading(state),
        facility: cleanedFacility,
        productInfo: state.productInfo,
        tableFilters: state.manageInventory.tableFilters,
        selectedProduct,
        selectedJob,
        mainCategoryID,
        formValues: state.manageInventory.installFormValues,
        isAPIQueueEmpty: selectIsOnlineAndEmptyQueue(state)
    };
};

export const EditInstallContainer = withTranslation('manageInventory')(
    connect(mapStateToProps, {
        updateInstall,
        saveInstall,
        toggleModal: toggleEditInstallModal,
        deleteInstall,
        saveAnyLocation,
        bulkUpdateInstalls, //  this could only be in EditInstallModal but the typings are strange
        setEnableInstallBatchMode,
        setInstallFormValues,
        updateInstallFormValues
    })(EditInstallForm)
);
