/*
 * Signature Modal
 */

import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import SignaturePad from './SignaturePad';
import { IinitialState } from '../../reducers';
import { IinstallBasePopulated } from '../../models';
import { WithCommonModal } from '../common/WithCommonModal';
import { selectIsLoading } from '../../reducers/commonReducers';
import {
    submitJobSignature,
    toggleIsCollectingSignatures,
    toggleIsJobClosingWithSignature
} from '../../actions/workOrderActions';
import { toggleModalSignaturePad } from '../../actions/manageInventoryActions';
import { completeJobWithFinalCheck, updateJob } from '../../actions/manageJobActions';
import { jobSourceEnum } from '../../models-enums';

interface Iprops {
    colorButton: string;
    installBasesPopulated: IinstallBasePopulated[];
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops & WithTranslation) => {
    const job = state.manageJob.selectedJob;
    const validateParts = job.source === jobSourceEnum.BPCS;
    const selectedJobSignatures = Object.values(state.manageJob.jobSignaturesByID).filter(
        signature => signature.jobID === job.id
    );
    const { isCollectingSignatures, isJobClosingWithSignature } = state.workOrder;

    return {
        className: 'signature-modal',
        show: state.manageInventory.showSignatureModal,
        user: state.user,
        title: isJobClosingWithSignature ? ownProps.t('signaturePadModalTitle') : ownProps.t('collectJobSignatures'),
        selectedJob: job,
        selectedJobSignatures,
        selectedJobCoverLetter: job.coverLetter,
        isJobClosingWithSignature,
        isCollectingSignatures,
        validateParts,
        installBasesPopulated: ownProps.installBasesPopulated,
        loading: selectIsLoading(state)
    };
};

export default withTranslation('manageInventory')(
    connect(mapStateToProps, {
        updateJob,
        completeJobWithFinalCheck,
        submitSignature: submitJobSignature,
        toggleModalSignaturePad,
        toggleIsCollectingSignatures,
        toggleModal: toggleModalSignaturePad,
        toggleIsJobClosingWithSignature,
        onHide: toggleModalSignaturePad
    })(WithCommonModal(SignaturePad))
);
