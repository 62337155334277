import { combineReducers } from 'redux';
import { IinitialState } from '.';
import * as types from '../actions/actionTypes';
import initialState from './initialState';

export const selectIsSyncRunning = (state: IinitialState) => state.syncManager.isSyncRunning;
export const selectIsJobDownloading = (state: IinitialState) => state.syncManager.downloadingJob;
export const selectIsDownloadingJobFailed = (state: IinitialState) => state.syncManager.downloadingJobFailed;

function isSyncRunningReducer(state: boolean = initialState.syncManager.isSyncRunning, action: any): boolean {
    switch (action.type) {
        case types.SET_SYNC_STATUS:
            return action.payload.isSyncRunning;
        default:
            return state;
    }
}

function isJobDownloadingReducer(state: boolean = initialState.syncManager.downloadingJob, action: any): boolean {
    switch (action.type) {
        case types.SET_DOWNLOADING_JOB:
            return action.payload.downloadingJob;
        default:
            return state;
    }
}

// Not currently used, but could be useful in the future
function isDownloadingJobFailedReducer(
    state: boolean = initialState.syncManager.downloadingJobFailed,
    action: any
): boolean {
    switch (action.type) {
        case types.SET_DOWNLOADING_JOB_FAILED:
            return action.payload.downloadingJobFailed;
        default:
            return state;
    }
}

const syncManagerReducer = combineReducers({
    isSyncRunning: isSyncRunningReducer,
    downloadingJob: isJobDownloadingReducer,
    downloadingJobFailed: isDownloadingJobFailedReducer
});

export default syncManagerReducer;
