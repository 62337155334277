/*
 * Show Requested Part - Container
 */

import { connect } from 'react-redux';

import { decreaseFromCart, deleteFromCart, updateQuantityCart } from '../../../actions/shoppingCartActions';
import { selectIsLoading } from '../../../reducers/commonReducers';
import { FormUtil } from '../../common/FormUtil';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RequestPart } from './RequestPart';
import { Ihistory } from '../../../models';
import { selectSuggestedPartsForTable } from '../../../reducers/partsReducer';
import { IinitialState } from '../../../reducers';
import {
    addPartToCart,
    deleteQuoteItemFromQuoteCart,
    saveQuoteCart,
    setSelectedCart
} from '../../../actions/partsActions';
import { getRequestTableData } from '../../../reducers/cartReducer';
import { selectQuoteCartForJob } from '../PartUtilities';

interface Iprops {
    history: Ihistory;
    colorButton?: string;
    installID: string;
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops & WithTranslation) => {
    const installBase = state.manageInventory.installBasesByID[ownProps.installID];
    const jobCart = selectQuoteCartForJob(state);
    const tableData = getRequestTableData(jobCart, ownProps.installID, state.selectedCart.addedIDs);

    const selectData = selectSuggestedPartsForTable(state, { installBase });

    const { historicalWorkOrderID } = state.workOrder;
    const { historicalResultID } = state.measurementPointResults;
    const disabled = historicalWorkOrderID.length > 0 || historicalResultID.length > 0;

    return {
        className: 'shopping-cart',
        loading: selectIsLoading(state),
        selectData,
        tableData,
        selectedCart: state.selectedCart,
        title: ownProps.t('partCartModal.modalTitle'),
        facilityOptions: FormUtil.convertToOptions(state.user.facilities),
        productInfo: state.productInfo,
        tableFilters: state.manageInventory.tableFilters,
        addPartToCart: typeof addPartToCart,
        deleteQuoteItemFromQuoteCart: typeof deleteQuoteItemFromQuoteCart,
        disabled
    };
};

export default withTranslation('nsPart')(
    connect(mapStateToProps, {
        addPartToCart,
        saveQuoteCart,
        decreaseFromCart,
        updateQuantityCart,
        setSelectedCart,
        deleteFromCart,
        deleteQuoteItemFromQuoteCart
    })(RequestPart)
);
