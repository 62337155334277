import * as React from 'react';
import { IPropsCommonModal } from './CommonModal';
import styled from 'styled-components';

const Modal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #fff;
    z-index: 1100;

    display: flex;
    flex-direction: column;
`;

const Title = styled.div`
    height: 64px;
    padding: 16px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #000000;
`;
const Content = styled.div`
    height: calc(100vh - 64px - 84px);
    overflow-y: scroll;
    overflow-x: hidden;
`;

const Footer = styled.div``;

const CloseButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;

    &:hover {
        cursor: pointer;
    }
`;

const CloseButton = (props: any) => {
    return (
        <CloseButtonWrapper onClick={props.onClick}>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 32 32">
                <path
                    fill="#007BC3"
                    fillRule="evenodd"
                    d="M11.05 9.636a1 1 0 00-1.414 1.414l4.95 4.95-4.95 4.95a1 1 0 001.414 1.414l4.95-4.95 4.95 4.95a1 1 0 001.414-1.414L17.414 16l4.95-4.95a1 1 0 00-1.415-1.414L16 14.586l-4.95-4.95z"
                    clipRule="evenodd"
                ></path>
            </svg>
        </CloseButtonWrapper>
    );
};

export const MobileButtonGroup = styled.div`
    position: fixed;
    bottom: 0;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #f5f5f5;
    width: 100vw;
    gap: 24px;

    & > button {
        flex: 1;
    }
`;

const CommonMobileModal = (props: IPropsCommonModal) => {
    const backdrop = props.backdrop ? props.backdrop : 'static';

    if (!props.show) {
        return null;
    }

    return (
        <>
            <Modal
                show={props.show}
                onHide={props.onHide}
                container={
                    props.container || document.getElementById('two-pane-layout') || document.querySelector('.app')
                }
                backdrop={backdrop}
            >
                {props.title && (
                    <Title>
                        {props.title} <CloseButton onClick={props.onHide} />
                    </Title>
                )}
                <Content hasTitle={props.title || false} hasFooter={props.footer || false}>
                    {props.children}
                </Content>
                {props.footer && <Footer>{props.footer}</Footer>}
            </Modal>
        </>
    );
};

export default CommonMobileModal;
