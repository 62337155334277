/*
 * Not Applicable Button Row Container
 */

import { WithTranslation, withTranslation } from 'react-i18next';
import { IWorkOrder } from '../../models';
import { IinitialState } from '../../reducers';
import { connect } from 'react-redux';
import { updateWorkOrder, deleteWorkOrder } from '../../actions/workOrderActions';
import { selectIsLoading } from '../../reducers/commonReducers';
import NotApplicableButtonRow from './NotApplicableButtonRow';

interface Iprops {
    selectedWorkOrder: IWorkOrder;
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops & WithTranslation) => {
    return {
        loading: selectIsLoading(state)
    };
};

export const NotApplicableButtonRowContainer = withTranslation('manageWorkOrder')(
    connect(mapStateToProps, {
        updateWorkOrder,
        deleteWorkOrder
    })(NotApplicableButtonRow)
);
