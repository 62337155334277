/* eslint-disable jsx-a11y/anchor-is-valid */

import * as React from 'react';

import { Breadcrumb, BreadcrumbItem, Button } from 'react-bootstrap';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
    Ibuilding,
    Ifacility,
    Ifloor,
    Ijob,
    Ilocation,
    Ioption,
    Iroom,
    ItableFiltersReducer,
    Itile,
    Iuser
} from '../../models';
import { IinitialState } from '../../reducers';
import { getIsMobile } from '../../reducers/configReducer';
import ReactTable, { RowInfo, SortingRule } from 'react-table';
/*
 * hopsital Managers manage their locations
 */
import { Redirect, RouteComponentProps } from 'react-router-dom';
import {
    cloneAnyLocationObject,
    deleteAnyLocation,
    filterLocations,
    saveAnyLocation,
    setTableFilter,
    toggleEditLocationModal,
    updateAnyLocation
} from '../../actions/manageLocationActions';
import { emptyTile, initialBuilding, initialFloor, initialJob, initialLoc } from '../../reducers/initialState';

import { Banner } from '../common/Banner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormUtil } from '../common/FormUtil';
import { TableUtil } from '../common/TableUtil';
import { cleanFacility } from '../../reducers/facilitiesReducer';
import { connect } from 'react-redux';
import { constants } from '../../constants/constants';
import { jobStatusEnum } from '../../models-enums';
import { toastr } from 'react-redux-toastr';
import { selectIsLoading } from '../../reducers/commonReducers';
import styled from 'styled-components';
import { selectFilterConfig } from '../../reducers/manageInventoryReducer';
import { FieldConfig } from 'react-reactive-form';
import EditLocationModal from './EditLocationModal';

interface Iprops extends RouteComponentProps<any> {
    // Add your regular properties here
    showEditLocationModal: boolean;
    loading: boolean;
    isMobile: boolean;
}

interface IdispatchProps {
    // Add your dispatcher properties here
    toggleEditLocationModal: typeof toggleEditLocationModal;
    saveAnyLocation: typeof saveAnyLocation;
    updateAnyLocation: typeof updateAnyLocation;
    deleteAnyLocation: typeof deleteAnyLocation;
    facilityOptions: Ioption[];
    user: Iuser;
    tableData: Array<Ibuilding | Ifloor | Ilocation | Iroom>;
    facility: Ifacility;
    setTableFilter: typeof setTableFilter;
    tableFilters: ItableFiltersReducer;
    selectedBuilding: Ibuilding;
    selectedFloor: Ifloor;
    selectedLocation: Ilocation;
    cloneAnyLocationObject: typeof cloneAnyLocationObject;
    filterLocations: typeof filterLocations;
    selectedJob: Ijob;
    mobileFilterConfig: FieldConfig;
}

interface Istate {
    selectedRow: any;
    currentTile: Itile;
    selectedItem: any;
}

const ManageLocationWrapper = styled.div`
    & > .ReactTable {
        max-height: ${(props: any) =>
            props.isBuildingLocation
                ? 'calc(100vh - 54px - 75.42px - 38px)'
                : 'calc(100vh - 54px - 75.42px - 38px - 40px)'};
    }
`;

const LocationActions = styled.div`
    display: flex;
    justify-content: ${(props: any) => (props.isMobile ? 'flex-start' : 'flex-end')};
    flex-direction: ${(props: any) => (props.isMobile ? 'row-reverse' : 'row')};
    & > .btn,
    .btn-link {
        padding: ${(props: any) => (props.isMobile ? '5px 12px' : '10px 24px')};
    }
`;

const MobileAddContainerFlex = styled.div`
    width: 100%;
    padding: 16px;
`;

const MobileTableAddButton = styled(Button)`
    background-color: #fff;
    color: #000;
    font-size: 16px;
    height: 43px;
    line-height: 1;
    max-height: 43px;
    padding: 13px 24px;
    width: 100%;

    & > svg {
        margin-right: 9px;
        font-size: 12px;
    }
`;

const MobileBreadcrumb = styled.div`
    display: flex;
    height: 40px;
    justify-content: start;
    align-items: center;
    padding-left: 16px;
    font-size: 14px;
    color: #007bc3;

    & > div > svg {
        margin-right: 20px;
        margin-left: 12px;
        color: #fd9a24;
    }

    & > div > span {
        color: #cccccc;
        padding-left: 7px;
        padding-right: 7px;
    }
`;

const TableAddButton = (props: any) => {
    // Short circuit and use mobile flex container for button.

    if (props.isMobile) {
        return (
            <MobileAddContainerFlex>
                <MobileTableAddButton {...props} />
            </MobileAddContainerFlex>
        );
    }

    return <Button className="table-add-button" bsStyle="link" {...props} />;
};

class ManageLocation extends React.Component<Iprops & IdispatchProps & WithTranslation, Istate> {
    public searchFieldConfigBanner: any;
    public buttonInAction = false;
    private isMobile = false;
    public preventToggleModal = false;

    constructor(props: Iprops & IdispatchProps & WithTranslation) {
        super(props);
        this.state = {
            selectedRow: {},
            currentTile: emptyTile,
            selectedItem: {}
        };
    }

    componentDidMount() {
        this.setState({
            currentTile: constants.getTileByURL(this.props.location.pathname)
        });
        this.setMobileView();
        if (this.props.tableFilters.facilityID !== this.props.facility.id) {
            this.props.setTableFilter({
                facilityID: this.props.facility.id,
                buildingID: undefined,
                locationID: undefined,
                floorID: undefined,
                page: 0
            });
        }
        // if we do not have a facility id, do not filter,  there is a redirect in the render statement that will redirect back home
        if (this.props.facility.id.length) {
            this.props.filterLocations(this.props.facility.id);
        }
    }

    componentDidUpdate(prevProps: Iprops & IdispatchProps, prevState: Istate) {
        // update the table when we get new data
        if (JSON.stringify(prevProps.facility) !== JSON.stringify(this.props.facility)) {
            this.props.filterLocations(this.props.facility.id);
        }
        if (JSON.stringify(prevProps.tableFilters) !== JSON.stringify(this.props.tableFilters)) {
            this.props.filterLocations(this.props.facility.id);
            // scroll top every time a filter changes

            const tableDiv = document.getElementsByClassName('rt-tbody');
            if (tableDiv && tableDiv.length) {
                tableDiv[0].scrollTop = 0;
            }
        }
    }

    setMobileView = () => {
        if (window.innerWidth !== undefined && window.innerWidth > 1024) {
            this.isMobile = false;
        } else {
            this.isMobile = true;
        }
    };

    handleEdit(selectedItem: any) {
        this.setState({ selectedItem });
    }

    handleDelete(deletedItem: any) {
        const toastrConfirmOptions = {
            onOk: () => {
                this.props.deleteAnyLocation(deletedItem, this.props.facility.id);
            },
            onCancel: () => null,
            okText: this.props.t('deleteOk'),
            cancelText: this.props.t('common:cancel')
        };
        toastr.confirm(this.props.t('deleteConfirm'), toastrConfirmOptions);
    }

    /*
     * Reset the table filters (not used currently)
     */
    resetTableFilters = () => {
        const facility = this.props.tableFilters.facility
            ? this.props.tableFilters.facility
            : this.props.facilityOptions[0];
        this.props.setTableFilter({
            facility,
            mainCategory: undefined,
            search: '',
            page: 0
        });
    };

    handleClone = (locationObject: Ibuilding | Ifloor | Ilocation | Iroom) => {
        if ('facilityID' in locationObject) {
            // BUILDING
            locationObject =
                this.props.facility.buildings.find(item => item.id === locationObject.id) || locationObject;
        } else if ('buildingID' in locationObject) {
            // FLOOR
            locationObject =
                this.props.selectedBuilding.floors.find(floor => floor.id === locationObject.id) || locationObject;
        } else if ('floorID' in locationObject) {
            // LOCATION
            locationObject =
                this.props.selectedFloor.locations.find(item => item.id === locationObject.id) || locationObject;
        } else {
            // ROOM
            locationObject =
                this.props.selectedLocation.rooms.find(item => item.id === locationObject.id) || locationObject;
        }
        this.props.cloneAnyLocationObject(locationObject, this.props.facility.id, this.props.t);
    };

    /*
     * Handle user clicking on a location row
     * set the selected location to state and open the modal
     */
    getTrProps = (finalState: any, rowInfo?: RowInfo, column?: undefined, instance?: any) => {
        if (rowInfo) {
            return {
                onClick: (e: React.MouseEvent<HTMLFormElement>) => {
                    const locationObject = rowInfo.original as Ilocation | Ibuilding | Ifloor | Iroom;
                    if (this.buttonInAction && this.preventToggleModal === false) {
                        this.props.toggleEditLocationModal();
                    } else if (this.preventToggleModal === false) {
                        if ('facilityID' in locationObject) {
                            // BUILDING
                            this.props.setTableFilter({
                                facilityID: this.props.facility.id,
                                buildingID: locationObject.id,
                                locationID: undefined,
                                floorID: undefined,
                                page: 0
                            });
                        } else if ('buildingID' in locationObject) {
                            // FLOOR
                            this.props.setTableFilter({
                                locationID: undefined,
                                floorID: locationObject.id,
                                page: 0
                            });
                        } else if ('floorID' in locationObject) {
                            // LOCATION
                            this.props.setTableFilter({
                                locationID: locationObject.id,
                                page: 0
                            });
                        } else {
                            // ROOM - we don't do anthything when they select a room...
                        }
                    }
                    this.buttonInAction = false;
                    this.preventToggleModal = false;
                },
                style: {
                    background: this.state.selectedRow[rowInfo.viewIndex]
                        ? constants.colors[`${this.state.currentTile.color}Tr` as keyof typeof constants.colors]
                        : ''
                }
            };
        } else {
            return {};
        }
    };

    // get location type
    getLocationType = () => {
        if (this.props.selectedLocation.id.length) {
            return 'Room';
        } else if (this.props.selectedFloor.id.length) {
            return 'Location';
        } else if (this.props.tableFilters.buildingID) {
            return 'Floor';
        } else {
            return 'Building';
        }
    };

    handleBCClick = (
        item: any,
        lType: string,
        e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLDivElement>
    ) => {
        e.preventDefault();
        switch (lType) {
            case 'Facility':
                this.props.setTableFilter({
                    facilityID: this.props.facility.id,
                    buildingID: undefined,
                    locationID: undefined,
                    floorID: undefined,
                    page: 0
                });
                break;
            case 'Building':
                this.props.setTableFilter({
                    buildingID: item.id,
                    locationID: undefined,
                    floorID: undefined,
                    page: 0
                });
                break;
            case 'Floor':
                this.props.setTableFilter({
                    locationID: undefined,
                    floorID: item.id,
                    page: 0
                });
                break;
            case 'Location':
                this.props.setTableFilter({ locationID: item.id, page: 0 });
                break;
            default:
                break;
        }
    };

    makeSandwhich = (label: string, handler?: any) => {
        if (typeof handler !== 'undefined') {
            return (
                <BreadcrumbItem active>
                    <a href="#" onClick={handler}>
                        {label}
                    </a>
                </BreadcrumbItem>
            );
        }
        return <BreadcrumbItem active>{label}</BreadcrumbItem>;
    };
    // get breadcrumb path
    getBreadcrumbs = () => {
        return (
            <Breadcrumb>
                {this.props.tableFilters.buildingID ? (
                    <BreadcrumbItem active>
                        <a
                            href="#"
                            onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
                                this.handleBCClick(initialBuilding, 'Facility', e)
                            }
                        >
                            Buildings
                        </a>
                    </BreadcrumbItem>
                ) : (
                    ''
                )}
                {/* building crumbs */}
                {this.getLocationType() === 'Floor' && this.props.tableFilters.buildingID
                    ? this.makeSandwhich(this.props.selectedBuilding.name)
                    : this.props.tableFilters.buildingID
                    ? this.makeSandwhich(this.props.selectedBuilding.name, (e: React.MouseEvent<HTMLAnchorElement>) =>
                          this.handleBCClick(this.props.selectedBuilding, 'Building', e)
                      )
                    : ''}
                {/* Floor crumbs */}
                {this.getLocationType() === 'Location' && this.props.selectedFloor.id.length > 0
                    ? this.makeSandwhich(this.props.selectedFloor.name)
                    : this.props.selectedFloor.id
                    ? this.makeSandwhich(this.props.selectedFloor.name, (e: React.MouseEvent<HTMLAnchorElement>) =>
                          this.handleBCClick(this.props.selectedFloor, 'Floor', e)
                      )
                    : ''}
                {/* Location crumbs */}
                {this.getLocationType() === 'Room' && this.props.selectedLocation.id.length > 0
                    ? this.makeSandwhich(this.props.selectedLocation.name)
                    : this.props.selectedLocation.id
                    ? this.makeSandwhich(this.props.selectedLocation.name, (e: React.MouseEvent<HTMLAnchorElement>) =>
                          this.handleBCClick(this.props.selectedLocation, 'Location', e)
                      )
                    : ''}
            </Breadcrumb>
        );
    };

    getMobileBreadcrumbs = () => {
        const { selectedBuilding, selectedFloor, selectedLocation } = this.props;

        return (
            <MobileBreadcrumb>
                <div
                    onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                        this.handleBCClick(initialBuilding, 'Facility', event);
                    }}
                >
                    <FontAwesomeIcon icon={'chevron-left'} />
                </div>
                {selectedBuilding && (
                    <div
                        onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                            this.handleBCClick(selectedBuilding, 'Building', event);
                        }}
                    >
                        {selectedBuilding.name}
                    </div>
                )}
                {selectedFloor && selectedFloor.name && (
                    <div
                        onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                            this.handleBCClick(selectedFloor, 'Floor', event);
                        }}
                    >
                        <span>/</span> {selectedFloor.name}
                    </div>
                )}
                {selectedLocation && selectedLocation.name && (
                    <div
                        onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                            this.handleBCClick(selectedFloor, 'Floor', event);
                        }}
                    >
                        <span>/</span> {selectedLocation.name}
                    </div>
                )}
            </MobileBreadcrumb>
        );
    };

    onPageChange = (page: number) => {
        this.props.setTableFilter({ page });
    };
    onPageSizeChange = (rows: number) => {
        this.props.setTableFilter({ rows, page: 0 });
    };

    onSortedChanged = (newSorted: SortingRule[], column: any, shiftKey: boolean) => {
        this.props.setTableFilter({ sorted: newSorted, page: 0 });
        this.setState({ selectedRow: {} });
    };

    render() {
        const { t, isMobile } = this.props;

        if (
            !this.props.facility.id.length ||
            !this.props.selectedJob.id.length ||
            this.props.selectedJob.status === jobStatusEnum.completed
        ) {
            return (
                <Redirect
                    to={{
                        pathname: '/'
                    }}
                />
            );
        }

        const columns = TableUtil.translateHeaders(
            [
                {
                    Header: isMobile ? this.getLocationType() : 'name',
                    accessor: 'name',
                    minWidth: isMobile ? undefined : 300
                },
                {
                    Header: '',
                    minWidth: isMobile ? undefined : 200,
                    Cell: row => (
                        <LocationActions isMobile={isMobile}>
                            <Button
                                bsStyle="link"
                                onClick={() => {
                                    this.buttonInAction = true;
                                    this.preventToggleModal = true;
                                    this.handleClone(row.original);
                                }}
                            >
                                clone
                            </Button>
                            <Button
                                bsStyle="link"
                                onClick={() => {
                                    this.buttonInAction = true;
                                    this.handleEdit(row.original);
                                }}
                            >
                                <FontAwesomeIcon icon={['far', 'edit']} />
                            </Button>
                            <Button
                                bsStyle="link"
                                style={{ color: 'red' }}
                                onClick={() => {
                                    this.buttonInAction = true;
                                    this.preventToggleModal = true;
                                    this.handleDelete(row.original);
                                }}
                            >
                                <FontAwesomeIcon icon={['far', 'times']} />
                            </Button>
                        </LocationActions>
                    )
                }
            ],
            t
        );

        return (
            <ManageLocationWrapper
                className={!isMobile && 'manage-location'}
                isBuildingLocation={this.getLocationType() === 'Building'}
                data-manage="true"
                data-btn="true"
            >
                <div className="upper-wrap" data-menuopen="false">
                    <Banner
                        title={t('bannerTitle')}
                        img={this.state.currentTile.srcBanner}
                        color={this.state.currentTile.color}
                    />
                    {!isMobile && this.getBreadcrumbs()}
                    <TableAddButton
                        isMobile={isMobile}
                        onClick={() => {
                            this.setState({ selectedItem: {} }, () => {
                                this.props.toggleEditLocationModal();
                            });
                        }}
                    >
                        {isMobile && <FontAwesomeIcon icon={['far', 'plus']} />}
                        {t(`manageLocation:new${this.getLocationType()}`)}
                    </TableAddButton>
                </div>
                {isMobile && this.getLocationType() !== 'Building' && this.getMobileBreadcrumbs()}
                <div id="table-wrap">
                    <ReactTable
                        data={this.props.tableData}
                        columns={columns}
                        getTrProps={this.getTrProps}
                        defaultPageSize={this.props.tableFilters.rows || constants.tablePageSizeDefault}
                        onPageSizeChange={this.onPageSizeChange}
                        showPageSizeOptions={true}
                        pageSizeOptions={constants.tablePageSizeOptions}
                        className={`beacon-table -highlight ${this.state.currentTile.color}`}
                        previousText={t('common:previous')}
                        nextText={t('common:next')}
                        sortable={false}
                        multiSort={false}
                        noDataText={t('common:noDataText')}
                        resizable={false}
                        expanded={this.state.selectedRow}
                        onPageChange={this.onPageChange}
                        page={this.props.tableFilters.page}
                        defaultSorted={[{ id: 'name', desc: false }]}
                    />
                </div>
                {this.props.showEditLocationModal && (
                    <EditLocationModal
                        selectedItem={this.state.selectedItem}
                        selectedType={this.getLocationType()}
                        // bgColor="#3BB9EA"
                        // brColor="#007BC3"
                        // primaryColor="#007AC3"
                        colorButton={
                            constants.colors[`${this.state.currentTile.color}Button` as keyof typeof constants.colors]
                        }
                    />
                )}
            </ManageLocationWrapper>
        );
    }
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops) => {
    const selectedJob = state.manageJob.jobsByID[state.manageJob.selectedJob.id] || initialJob;
    // facility location object selectors
    // TODO these can be refactored to a id indexed and the facilitiesReducer can be improved by splitting it up into multiple reducers
    const facility = cleanFacility(state.facilities.facilitiesByID[selectedJob.facilityID]);
    const selectedBuilding =
        facility.buildings.find(building => building.id === state.manageLocation.tableFilters.buildingID) ||
        initialBuilding;
    const selectedFloor =
        selectedBuilding.floors.find(floor => floor.id === state.manageLocation.tableFilters.floorID) || initialFloor;
    const selectedLocation =
        selectedFloor.locations.find(location => location.id === state.manageLocation.tableFilters.locationID) ||
        initialLoc;
    const isMobile: boolean = getIsMobile(state);

    return {
        user: state.user,
        loading: selectIsLoading(state),
        showEditLocationModal: state.manageLocation.showEditLocationModal,
        facilityOptions: FormUtil.convertToOptions(state.user.facilities),
        tableData: state.manageLocation.visibleLocations,
        facility,
        tableFilters: state.manageLocation.tableFilters,
        selectedBuilding,
        selectedFloor,
        selectedLocation,
        selectedJob,
        isMobile,
        mobileFilterConfig: selectFilterConfig(state)
    };
};

export default withTranslation('manageLocation')(
    connect(mapStateToProps, {
        saveAnyLocation,
        updateAnyLocation,
        deleteAnyLocation,
        toggleEditLocationModal,
        setTableFilter,
        cloneAnyLocationObject,
        filterLocations
    })(ManageLocation)
);
