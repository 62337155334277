/*
 * Hours Table Container
 */

import { connect } from 'react-redux';
import { orderBy, filter } from 'lodash';
import { IinitialState } from '../../reducers';
import moment from 'moment';
import { AddHoursTable } from './AddHoursTable';
import { setSelectedJobHourID, deleteJobHour } from '../../actions/manageJobActions';
import { withTranslation, WithTranslation } from 'react-i18next';
import { selectIsLoading } from '../../reducers/commonReducers';

interface Iprops {
    colorButton?: string;
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops & WithTranslation) => {
    const filteredJobHours = filter(state.manageJob.selectedJob.jobHours, {
        isDeleted: false
    });
    return {
        loading: selectIsLoading(state),
        tableData: orderBy(filteredJobHours, res => moment.utc(res.createDate).unix(), 'desc'),
        selectedJobHourID: state.manageJob.selectedJobHourID
    };
};

export const AddHoursTableContainer = withTranslation('nsJob')(
    connect(mapStateToProps, {
        setSelectedJobHourID,
        deleteJobHour
    })(AddHoursTable)
);
