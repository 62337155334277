import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { saveDamageCode } from '../../../actions/measurementPointResultsActions';
import { constants } from '../../../constants/constants';
import {
    IdamageCode,
    IinstallBase,
    Ijob,
    ImainFailureCodes,
    Ioption,
    IreasonFailureCodes,
    IsubFailureCodes
} from '../../../models';
import { jobStatusEnum } from '../../../models-enums';
import { IinitialState } from '../../../reducers';
import { getDamageCodes, getProducts } from '../../../reducers/manageInventoryReducer';

interface Iprops {
    installBase: IinstallBase;
    selectedJob: Ijob;
}

/*
 *   Damage Codes Flow:
 *   1. Object Part should be given to use (this is currently todo, so it's hardcoded for now)
 *   2. Use the Object Part to filter the Main Failure Codes, the user must select one
 *   3. Use the Main Failure Code to filter the Sub Failure Codes, the user must select one
 */

interface Istate {
    objectPartGroups: Ioption[];
    selectedPartGroup: Ioption;

    mainFailureCodes: Ioption[];
    selectedMainFailureCode: Ioption;

    subFailureCodes: Ioption[];
    selectedSubFailureCode: Ioption;

    reasonFailureCodes: Ioption[];
    selectedReasonFailureCode: Ioption;

    failedPartNumber: string;

    rootCause: string;
}

const defaultOption = { label: '', value: '' };

const SAPDamageCodes: React.FC<Iprops & WithTranslation> = props => {
    const disaptch = useDispatch();
    const damageCodes = useSelector(getDamageCodes);
    const products = useSelector(getProducts);

    const [state, setState] = React.useState<Istate>({
        objectPartGroups: [],
        selectedPartGroup: defaultOption,
        mainFailureCodes: [],
        selectedMainFailureCode: defaultOption,
        subFailureCodes: [],
        selectedSubFailureCode: defaultOption,
        reasonFailureCodes: [],
        selectedReasonFailureCode: defaultOption,
        failedPartNumber: '',
        rootCause: ''
    });

    React.useEffect(() => {
        // if the product opg is set then user can't change it
        const productOPG = products[props.installBase.productID].pgc;
        let objectPartGroupOptions: Ioption[] = [];
        let selectedPartGroup: Ioption | undefined = undefined;

        // init objectPartGroups based on installBase objectPartGroup
        if (productOPG) {
            // single option
            const singleOPGOption = { label: productOPG, value: productOPG } as Ioption;
            objectPartGroupOptions = [singleOPGOption];
            selectedPartGroup = singleOPGOption;
        } else {
            // multiple options
            objectPartGroupOptions = [
                ...new Set(constants.mainFailureCodes.map((value: ImainFailureCodes) => value.PGC))
            ].map((value: string) => ({ label: value, value: value }));
        }

        // restore prior values
        const damageCode = damageCodes[props.installBase.id];

        if (damageCode && damageCode.objectPartGroup) {
            selectedPartGroup = { label: damageCode?.objectPartGroup, value: damageCode?.objectPartGroup } as Ioption;
        }

        if (selectedPartGroup) {
            // OBJECT PART CODES
            const filteredMainFailureCodes = filterMainFailureCodes(selectedPartGroup);
            const selectedMainFailureCode = filteredMainFailureCodes.find(
                x => x.value === damageCode?.objectPartCode
            ) as Ioption;
            // DAMAGE CODES
            const filteredSubFailureCodes = filterSubFailureCodes(selectedMainFailureCode);
            const selectedSubFailureCode = filteredSubFailureCodes.find(
                x => x.value === damageCode?.damageCodeText
            ) as Ioption;
            // CAUSE CODE
            const filteredReasonFailureCodes = filterReasonFailureCodes(selectedSubFailureCode);
            const selectedReasonFailureCode = filteredReasonFailureCodes.find(
                x => x.value === damageCode?.causeCode
            ) as Ioption;

            setState(prev => ({
                ...prev,
                selectedPartGroup: selectedPartGroup ? selectedPartGroup : defaultOption,
                selectedMainFailureCode,
                selectedSubFailureCode,
                selectedReasonFailureCode,
                failedPartNumber: damageCode?.failedPartNumber || '',
                rootCause: damageCode?.rootCause || '',
                objectPartGroups: objectPartGroupOptions
            }));
        } else {
            setState(prev => ({
                ...prev,
                objectPartGroups: objectPartGroupOptions
            }));
        }
    }, [props.installBase.id]);

    const canEditDamageCodes = () => {
        if (props.selectedJob.status === jobStatusEnum.new) {
            return true;
        }
        return false;
    };

    // Convert array of main failure codes to array of options filtered by object part group (selectedPGC)
    const filterMainFailureCodes = (selectedPGC: Ioption): Ioption[] => {
        const list = selectedPGC
            ? constants.mainFailureCodes
                  .filter((item: ImainFailureCodes) => item.PGC === selectedPGC.value)
                  .map((item: ImainFailureCodes) => ({ label: item.MainCodeDescription, value: item.MainCode }))
            : [];
        setState(prev => ({ ...prev, mainFailureCodes: list }));
        return list;
    };

    const filterSubFailureCodes = (selectedMainFailureCode: Ioption): Ioption[] => {
        const list = selectedMainFailureCode
            ? constants.subFailureCodes
                  .filter((item: IsubFailureCodes) => item.MainFailureCode === selectedMainFailureCode.value)
                  .map((item: IsubFailureCodes) => ({
                      label: item.SubCodeDescription,
                      value: item.SubFailureCode.toString()
                  }))
            : [];
        setState(prev => ({ ...prev, subFailureCodes: list }));
        return list;
    };

    const filterReasonFailureCodes = (selectedSubFailureCode: Ioption): Ioption[] => {
        const list = selectedSubFailureCode
            ? constants.reasonFailureCodes
                  .filter(
                      (item: IreasonFailureCodes) =>
                          Number(item.SubFailureCode) === Number(selectedSubFailureCode.value)
                  )
                  .map((item: IreasonFailureCodes) => ({
                      label: item.ReasonFailureCodeDescription,
                      value: item.ReasonFailureCode.toString()
                  }))
            : [];
        setState(prev => ({ ...prev, reasonFailureCodes: list }));
        return list;
    };

    React.useEffect(() => {
        const damageCodes: IdamageCode = {
            objectPartGroup: state.selectedPartGroup.value,
            objectPartCode: state.selectedMainFailureCode?.value,
            damageCodeText: `${state.selectedSubFailureCode?.value}`,
            causeCode: `${state.selectedReasonFailureCode?.value}`,
            failedPartNumber: state.failedPartNumber,
            rootCause: state.rootCause,
            jobID: props.selectedJob.id,
            installBaseID: props.installBase.id
        };
        disaptch(saveDamageCode(damageCodes, props.installBase.id));
    }, [
        state.selectedPartGroup,
        state.selectedReasonFailureCode,
        state.selectedMainFailureCode,
        state.selectedSubFailureCode,
        state.failedPartNumber,
        state.rootCause
    ]);

    const objectPartGroupChange = (value: any) => {
        if (state.selectedPartGroup?.value === value?.value) return;
        filterMainFailureCodes(value);
        setState(prev => ({
            ...prev,
            selectedPartGroup: value || defaultOption,
            selectedMainFailureCode: defaultOption
        }));
        objectPartCodeChange(defaultOption);
    };

    const objectPartCodeChange = (value: any) => {
        if (state.selectedMainFailureCode?.value === value?.value) return;
        filterSubFailureCodes(value);
        setState(prev => ({
            ...prev,
            selectedMainFailureCode: value || defaultOption,
            selectedSubFailureCode: defaultOption
        }));
        subFailurePartCodeChange(defaultOption);
    };

    const subFailurePartCodeChange = (value: any) => {
        if (state.selectedSubFailureCode?.value === value?.value) return;
        filterReasonFailureCodes(value);
        setState(prev => ({
            ...prev,
            selectedSubFailureCode: value || defaultOption,
            selectedReasonFailureCode: defaultOption
        }));
        reasonCodeChange(defaultOption);
    };

    const reasonCodeChange = (value: any) => {
        setState(prev => ({ ...prev, selectedReasonFailureCode: value || defaultOption }));
    };

    const failedPartNumberChanged = (value: any) => {
        if (value) {
            setState({ ...state, failedPartNumber: value.target.value });
        }
    };

    const rootCauseChanged = (value: any) => {
        if (value) {
            setState({ ...state, rootCause: value.target.value });
        }
    };

    return (
        <div className="damage-code-form">
            <label htmlFor="objectPartGroup">Object Part Group</label>
            <Select
                className="beacon-select"
                name="objectPartGroup"
                onChange={objectPartGroupChange}
                options={state.objectPartGroups}
                value={state.selectedPartGroup}
                isDisabled={canEditDamageCodes() === false || state.objectPartGroups.length === 1}
            />

            <label htmlFor="object-part-code">Object Part Code</label>
            <Select
                className="beacon-select"
                name="object-part-code"
                onChange={objectPartCodeChange}
                options={state.mainFailureCodes}
                value={state.selectedMainFailureCode}
                isDisabled={canEditDamageCodes() === false}
            />

            <label htmlFor="damage-code">Damage Code</label>
            <Select
                className="beacon-select"
                name="damage-code"
                value={state.selectedSubFailureCode}
                onChange={subFailurePartCodeChange}
                isDisabled={canEditDamageCodes() === false}
                options={state.subFailureCodes}
            />

            <label htmlFor="cause-code">Cause Code</label>
            <Select
                className="beacon-select"
                name="cause-code"
                isDisabled={canEditDamageCodes() === false}
                value={state.selectedReasonFailureCode}
                options={state.reasonFailureCodes}
                onChange={reasonCodeChange}
            />

            <label htmlFor="failed-part-number">Failed Part Number</label>
            <input
                id="failed-part-number"
                name="failed-part-number"
                value={state.failedPartNumber}
                onChange={failedPartNumberChanged}
                className="form-control"
                disabled={canEditDamageCodes() === false}
                maxLength={40}
            />

            <label htmlFor="root-cause">Root Cause</label>
            <input
                id="root-cause"
                name="root-cause"
                value={state.rootCause}
                onChange={rootCauseChanged}
                className="form-control"
                disabled={canEditDamageCodes() === false}
                maxLength={40}
                data-1p-ignore
            />
        </div>
    );
};

export default withTranslation('manageInventory')(
    connect(
        (state: IinitialState) => {
            const {} = state;
            return {
                // variables
            };
        },
        {
            // functions
        }
    )(SAPDamageCodes)
);
