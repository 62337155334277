import * as localForage from 'localforage';
import * as types from './actionTypes';

import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { AppSyncItem, IfacilityWithoutBuildings, Ijob, ItableFiltersParams } from '../models';
import { ThunkAction } from 'redux-thunk';
import { filter, forEach, isEmpty } from 'lodash';
import {
    ensureLatestProducts,
    getInventory,
    getInventoryForJob,
    updatePersistedProducts,
    updateVisibleProducts
} from './manageInventoryActions';
import {
    getFSEUsers,
    getAssignedJobs,
    receiveUpdatedJobs,
    setDefaultFacilityID,
    getNextJobNumber,
    getFSEUsersOnline
} from './manageJobActions';
import { getJobCommentCodes, getJobComments } from './manageJobCommentsActions';

import API from '../constants/apiEndpoints';
import { OfflineProductsProvider } from '../store/offlineProductsProvider';
import { beginAjaxCall, endAjaxCall } from './ajaxStatusActions';
import { appSyncItemNames, constants } from '../constants/constants';
import { getAllMeasurementPointLists } from './measurementPointListActions';
import { getLocationsFacility } from './manageLocationActions';
import moment from 'moment';

import { msalFetch } from '../components/auth/Auth-Utils';
import { toastr } from 'react-redux-toastr';
import { TFunction } from 'i18next';
import { IinitialState } from '../reducers';
import {
    getAllProductsHelper,
    getJobSimpleMeasurementPointResults,
    getMeasurementPointListResultsForJobsHelper
} from './workerActions';
import { AppSyncItemStatus, jobStatusEnum, jobTypesIdEnum } from '../models-enums';
import { getJobSignatures, getSAPWorkOrders, getWorkOrders } from './workOrderActions';
import { getCodes } from './manageCodeActions';
import { getAllParts, setSelectedCart } from './partsActions';
import { getPhotos } from './photosActions';
import Queue from 'queue-promise';
import { selectActiveOfflineJobs } from '../reducers/manageJobReducer';
import { openJob } from './manageJobActions';
import { getLaborRates, getNonProductiveHours } from './userActions';
import { getContactsByFacility } from './contactsActions';
import { getPagedMPLRForJob } from './measurementPointResultsActions';
const uuidv4 = require('uuid/v4');

const offlineProductsProvider = new OfflineProductsProvider();
type ThunkResult<R> = ThunkAction<R, IinitialState, undefined, any>;

const queue = new Queue({
    concurrent: 2,
    interval: 100
});

const apiCallsForSignIn = [
    {
        name: appSyncItemNames.NonProductiveHours,
        displayName: 'Non Productive Hours',
        status: AppSyncItemStatus.inQueue
    },
    {
        name: appSyncItemNames.NextJobNumber,
        displayName: 'Next Job Number',
        status: AppSyncItemStatus.inQueue
    },
    {
        name: appSyncItemNames.SAPMapping,
        displayName: 'SAP Mapping',
        status: AppSyncItemStatus.inQueue
    },
    {
        name: appSyncItemNames.ProductInfo,
        displayName: 'Product Info',
        status: AppSyncItemStatus.inQueue
    },
    {
        name: appSyncItemNames.FSEUsers,
        displayName: 'FSE Users',
        status: AppSyncItemStatus.inQueue
    },
    {
        name: appSyncItemNames.JobCommentCodes,
        displayName: 'Job Comment Codes',
        status: AppSyncItemStatus.inQueue
    },
    {
        name: appSyncItemNames.Jobs,
        displayName: 'Jobs',
        status: AppSyncItemStatus.inQueue
    },
    {
        name: appSyncItemNames.MeasurementPointLists,
        displayName: 'Measurement Point Lists',
        status: AppSyncItemStatus.inQueue
    },
    {
        name: appSyncItemNames.LaborRates,
        displayName: 'Labor Rates',
        status: AppSyncItemStatus.inQueue
    },
    {
        name: appSyncItemNames.Parts,
        displayName: 'Parts',
        status: AppSyncItemStatus.inQueue
    },
    {
        name: appSyncItemNames.Products,
        displayName: 'Products',
        status: AppSyncItemStatus.inQueue
    }
];

const apiCallsForAppRefresh = [
    {
        name: appSyncItemNames.Jobs,
        displayName: 'Jobs',
        status: AppSyncItemStatus.inQueue
    },
    {
        name: appSyncItemNames.LaborRates,
        displayName: 'Labor Rates',
        status: AppSyncItemStatus.inQueue
    },
    {
        name: appSyncItemNames.MeasurementPointLists,
        displayName: 'Measurement Point Lists',
        status: AppSyncItemStatus.inQueue
    },
    {
        name: appSyncItemNames.Products,
        displayName: 'Products',
        status: AppSyncItemStatus.inQueue
    }
];

const apiCallsForDownloadJob = [
    {
        name: appSyncItemNames.OpenJob,
        displayName: 'Open Job',
        status: AppSyncItemStatus.inQueue
    },
    {
        name: appSyncItemNames.Facility,
        displayName: 'Facility',
        status: AppSyncItemStatus.inQueue
    },
    {
        name: appSyncItemNames.JobPhotos,
        displayName: 'Job Photos',
        status: AppSyncItemStatus.inQueue
    },
    {
        name: appSyncItemNames.JobComments,
        displayName: 'Job Comments',
        status: AppSyncItemStatus.inQueue
    },
    {
        name: appSyncItemNames.JobSignatures,
        displayName: 'Job Signatures',
        status: AppSyncItemStatus.inQueue
    },
    {
        name: appSyncItemNames.FacilityContacts,
        displayName: 'Facility Contacts',
        status: AppSyncItemStatus.inQueue
    },
    {
        name: appSyncItemNames.Inventory,
        displayName: 'Inventory',
        status: AppSyncItemStatus.inQueue
    },
    {
        name: appSyncItemNames.SAPWorkOrders,
        displayName: 'SAP Work Orders',
        status: AppSyncItemStatus.inQueue
    },
    {
        name: appSyncItemNames.MeasurementPointListResults,
        displayName: 'Measurement Point List Results',
        status: AppSyncItemStatus.inQueue
    }
    // {
    //     name: appSyncItemNames.Products,
    //     displayName: 'Products',
    //     status: AppSyncItemStatus.inQueue
    // }
];

export const isJobWorkOrderFlow = (job: Ijob): boolean => {
    if (
        job &&
        (job.jobTypeID === jobTypesIdEnum.repair ||
            job.jobTypeID === jobTypesIdEnum.servicePlan ||
            job.jobTypeID === jobTypesIdEnum.commissioning ||
            job.jobTypeID === jobTypesIdEnum.maintenance ||
            job.jobTypeID === jobTypesIdEnum.warrantyBM)
    ) {
        return true;
    }

    return false;
};

export const toggleEditFacilityModal = () => ({
    type: types.TOGGLE_MODAL_EDIT_FACILITY
});

export const setTableFilter = (filters: ItableFiltersParams) => ({
    type: types.SET_TABLE_FILTER_MANAGE_INVENTORY,
    filters
});
export const initialSyncStart = () => {
    return {
        type: types.INITIAL_SYNC_START
    };
};
export const initialSyncEnd = () => {
    return {
        type: types.INITIAL_SYNC_END
    };
};

export function setInitialAppSyncStatus(name: string, status: AppSyncItemStatus): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch({
            type: types.UPDATE_INITIAL_APP_SYNC_ITEM_STATUS,
            name: name,
            status: status
        });
    };
}

export function getFacilitiesByCustomer(customerID: string): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const axiosOptions: AxiosRequestConfig = {
            method: 'get',
            params: { customerID }
        };

        const url = API.GET.facility.getbycustomer;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>): { hasFacilities: boolean } => {
                if (!data.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.GET_FACILITIES_SUCCESS,
                        facilities: data.data
                    });
                    if (data.data.length === 0) {
                        return { hasFacilities: false };
                    } else {
                        return { hasFacilities: true };
                    }
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.GET_FACILITIES_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'get facilities');
                console.error('[getFacilitiesByCustomer]:', error);
                throw new Error(error);
            });
    };
}

// No rollback here because one must be online
export function addFacility(facility: Partial<IfacilityWithoutBuildings>): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const newID = uuidv4();
        const data = {
            ...facility,
            id: newID
        };
        const axiosOptions: AxiosRequestConfig = {
            method: 'post',
            data
        };

        const url = API.POST.facility;
        return msalFetch(url, axiosOptions)
            .then((response: AxiosResponse<any>) => {
                if (!response.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.FACILITY_ADD_SUCCESS,
                        facility: response.data
                    });
                    dispatch({ type: types.TOGGLE_MODAL_ADD_FACILITY });
                    toastr.success('Success', 'Saved Facility', constants.toastrSuccess);
                    dispatch(setDefaultFacilityID(response.data.id));

                    dispatch({
                        type: types.TOGGLE_MODAL_EDIT_JOB
                    });

                    return response;
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.FACILITY_ADD_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'add facility');
                console.error('[addFacility]:', error);
            })
            .finally(() => {
                dispatch({
                    type: types.SET_FORM_VALUES_FACILITIES_ADD_FACILITY,
                    formValues: {}
                });
            });
    };
}

export function getSAPJobMapping(): ThunkResult<any> {
    return (dispatch, getState) => {
        const axiosOptions: AxiosRequestConfig = {
            method: 'get'
        };

        dispatch({
            type: types.UPDATE_INITIAL_APP_SYNC_ITEM_STATUS,
            name: appSyncItemNames.SAPMapping,
            status: AppSyncItemStatus.inProgress
        });

        const url = API.GET.sapJobMapping.getAll;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (!data.data) {
                    dispatch({
                        type: types.UPDATE_INITIAL_APP_SYNC_ITEM_STATUS,
                        name: appSyncItemNames.SAPMapping,
                        status: AppSyncItemStatus.failed
                    });
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.SET_SAP_JOB_MAPPING,
                        data: data.data
                    });

                    dispatch({ type: types.REMOVE_INITIAL_APP_SYNC_ITEM, name: appSyncItemNames.SAPMapping });
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.UPDATE_INITIAL_APP_SYNC_ITEM_STATUS,
                    name: appSyncItemNames.SAPMapping,
                    status: AppSyncItemStatus.failed
                });
                constants.handleError(error, 'get sap job mapping');
                console.error('[getSAPJobMapping]:', error);
            });
    };
}

export function getProductInfo(showError: boolean = true): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch({
            type: types.UPDATE_INITIAL_APP_SYNC_ITEM_STATUS,
            name: appSyncItemNames.ProductInfo,
            status: AppSyncItemStatus.inProgress
        });

        const axiosOptions: AxiosRequestConfig = {
            method: 'get',
            params: {}
        };

        const url = API.inventory.getproductinfo;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (!data.data) {
                    dispatch({
                        type: types.UPDATE_INITIAL_APP_SYNC_ITEM_STATUS,
                        name: appSyncItemNames.ProductInfo,
                        status: AppSyncItemStatus.failed
                    });
                    throw new Error('error getting product info');
                } else {
                    dispatch({
                        type: types.GET_PRODUCT_INFO_SUCCESS,
                        data: data.data,
                        updateDate: moment().unix()
                    });

                    dispatch({ type: types.REMOVE_INITIAL_APP_SYNC_ITEM, name: appSyncItemNames.ProductInfo });
                }
            })
            .catch(error => {
                dispatch({ type: types.GET_PRODUCT_INFO_FAILED });
                dispatch({
                    type: types.UPDATE_INITIAL_APP_SYNC_ITEM_STATUS,
                    name: appSyncItemNames.ProductInfo,
                    status: AppSyncItemStatus.failed
                });
                if (showError) {
                    constants.handleError(error, 'get product info');
                }
                throw error;
            });
    };
}

/*
 * getJobDependenciesHelper
 * retrieve all the facilities, inventory, and measurement point results needed.  Work Orders come in the job objects.
 */
// export const getJobDependenciesHelper = (jobs: Ijob[], shouldOpenJob: boolean): ThunkResult<any> => {
//     return (dispatch, getState): Promise<any> => {
//         return new Promise((resolve, reject) => {
//             console.log('[getJobDependenciesHelper]: getting job dependencies');
//             const getFSEUsersWithoutType = getFSEUsers as any;
//             const getJobSimpleMeasurementPointResultsWithoutType = getJobSimpleMeasurementPointResults as any;
//             const getMeasurementPointListResultsForJobsHelperWithoutType = getMeasurementPointListResultsForJobsHelper as any;
//             const getPMPWorkOrdersWithoutType = getWorkOrders as any;
//             const getLocationsFacilityWithoutType = getLocationsFacility as any;
//             const getAllPartsWithoutType = getAllParts as any;
//             const getPhotosWithoutType = getPhotos as any;
//             const getInventoryWithoutTypes = getInventory as any;
//             const getSelectedCart = setSelectedCart as any;
//             const openJobType = openJob as any;
//             const getSAPWorkOrder = getSAPWorkOrders as any;
//             const getStandardCodes = getCodes as any;
//             const ensureLatestProductsCall = ensureLatestProducts as any;

//             let loadedFacilityIDs: string[] = []; // keep track of the facilities we have loaded the location and inventory info for
//             let verificationJobIDs: string[] = []; // keep track of verification jobs we need to load simple measurement point results for

//             // only get dependencies for active jobs
//             const activeJobs = filter(jobs, job => job.status !== jobStatusEnum.completed);

//             queue.on('end', () => {
//                 dispatch(updateVisibleProducts());
//                 resolve(true);
//             });
//             queue.on('reject', error => {
//                 reject(error);
//             });
//             if (getState().manageJob.selectedJob.facilityID) {
//                 const getcontacts = getContactsByFacility as any;
//                 queue.enqueue(() => dispatch(getcontacts(getState().manageJob.selectedJob.facilityID)));
//             }
//             queue.enqueue(() => dispatch(getFSEUsersWithoutType())); // TODO do we need to get users everytime we get job dependencies?
//             for (let i = 0; i < activeJobs.length; i++) {
//                 const job = activeJobs[i];

//                 // we now need to use simple MPLR for Job Data, not just verification jobs
//                 verificationJobIDs = [...verificationJobIDs, job.id];

//                 if (loadedFacilityIDs.indexOf(job.facilityID) >= 0) {
//                     // only get locations and inventory if we have not already gotten them for this facility
//                     return;
//                 }

//                 // If user manually hits the sync button, we want to make sure all JobWorkOrders get downloaded
//                 if (shouldOpenJob) {
//                     queue.enqueue(() => dispatch(openJobType(job.id, false)));
//                 }

//                 loadedFacilityIDs = [...loadedFacilityIDs, job.facilityID];
//                 queue.enqueue(() => dispatch(getLocationsFacilityWithoutType(job.facilityID)));
//                 queue.enqueue(() => dispatch(getPMPWorkOrdersWithoutType(job.facilityID)));
//                 queue.enqueue(() => dispatch(getInventoryWithoutTypes(job.facilityID)));
//                 queue.enqueue(() => dispatch(getPhotosWithoutType(job.id, job.facilityID)));
//                 queue.enqueue(() => dispatch(getStandardCodes()));
//                 queue.enqueue(() => dispatch(getSAPWorkOrder()));
//                 queue.enqueue(() => dispatch(ensureLatestProductsCall()));
//                 queue.enqueue(() =>
//                     dispatch(getMeasurementPointListResultsForJobsHelperWithoutType(job.id, job.facilityID))
//                 );
//             }

//             queue.enqueue(() => dispatch(getAllPartsWithoutType()));
//             queue.enqueue(() => dispatch(getJobSimpleMeasurementPointResultsWithoutType(verificationJobIDs)));
//             queue.enqueue(() => dispatch(getSelectedCart()));
//         });
//     };
// };

/*
 * /latest endpoint retrieves all the changes for all data required for offline operation
 Intentionally not dispatching beginAjaxCall()
 */
export function getLatestHelper(lastSync: number, currentJob?: string): ThunkResult<any> {
    return (dispatch, getState) => {
        console.log('[getLatestHelper]: getting latest data');
        const updated = moment(lastSync, 'X').toISOString();
        const offlineJobIds = selectActiveOfflineJobs(getState()).map(job => job.id);
        const axiosOptions: AxiosRequestConfig = {
            method: 'post',
            data: {
                updated,
                JobIDs: currentJob ? [currentJob] : offlineJobIds
            }
        };
        const url = API.update.getLatest;

        const getJobsPromise = (getAssignedJobs as any) as () => Promise<any>;
        dispatch(getJobsPromise());

        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (!data.data) {
                    throw new Error('unable to check latest');
                } else {
                    const {
                        jobs,
                        installBases,
                        facilities,
                        measurementPointLists,
                        measurementPointListResults,
                        products,
                        codes,
                        simpleMeasurementPointListResults
                    } = data.data;
                    let latestPromises: Array<Promise<void | void[]>> = []; // collect all the promises that help process the response from /latest
                    dispatch({
                        type: types.UPDATE_SYNC_STATUS_SUCCESS,
                        lastSync,
                        updateDate: moment().unix()
                    });

                    if (jobs.length) {
                        dispatch(receiveUpdatedJobs(jobs));
                    }
                    if (facilities.length) {
                        dispatch({
                            type: types.GET_FACILITIES_SUCCESS,
                            facilities
                        });
                    }
                    if (isEmpty(installBases) === false) {
                        dispatch({
                            type: types.GET_INVENTORY_SUCCESS,
                            installBases
                        });
                    }
                    if (products.length) {
                        dispatch({ type: types.PRODUCT_UPDATES, products });
                        latestPromises = [...latestPromises, updatePersistedProducts(products)];
                    }
                    if (codes.length) {
                        dispatch({
                            type: types.MANAGE_JOB_COMMENT_CODES_SUCCESS,
                            codes
                        });
                    }

                    if (measurementPointListResults.length) {
                        dispatch({
                            type: types.GET_MEASUREMENT_POINT_FACILITY_RESULTS_SUCCESS,
                            results: measurementPointListResults,
                            updateDate: moment().unix()
                        });
                    }

                    if (simpleMeasurementPointListResults.length) {
                        dispatch({
                            type: types.GET_SIMPLE_MEASUREMENT_POINT_JOB_RESULTS_SUCCESS,
                            results: simpleMeasurementPointListResults,
                            updateDate: moment().unix()
                        });
                    }

                    if (measurementPointLists.length) {
                        // loop over the lists and update them:
                        forEach(measurementPointLists, measurementPointList => {
                            dispatch({
                                type: types.MANAGE_MEASUREMENT_POINT_LIST_UPDATE,
                                measurementPointList
                            });
                        });
                    }
                    return Promise.all(latestPromises);
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.UPDATE_SYNC_STATUS_FAILED
                });
                // constants.handleError(
                //     error,
                //     'Downloading updated data failed.  Please continue to work offline, or check your internet connection and try again.',
                //     'warning'
                // );

                console.error('[getLatestHelper]:', error);
            })
            .finally(() => {
                dispatch(endAjaxCall());
            });
    };
}

// After sign in, shove all the endpoints into the queue that we want to process
export const setUpInitialAppSync = (): ThunkResult<any> => {
    return (dispatch, getState) => {
        let initialAppSyncList: AppSyncItem[] = [];

        initialAppSyncList.push(...apiCallsForSignIn);

        dispatch({
            type: types.SET_INITIAL_APP_SYNC_LIST,
            initialAppSyncList
        });
    };
};

// Initial need for this, is for a signed in user, recieving the new Labor Rates from the API.
// We need to make sure that data is pulled down, instead of forcing them to sign out and back in.
// This can be later ultilized for other required data, just to make sure redux always has what it needs.
export const checkForMissingData = (): ThunkResult<any> => {
    return (dispatch, getState) => {
        if (getState().laborRates.length === 0) {
            dispatch(getLaborRates());
        }
    };
};

export const downloadJobV2 = (job: Ijob): ThunkResult<any> => {
    return (dispatch, getState) => {
        dispatch(initialSyncStart());

        let initialAppSyncList: AppSyncItem[] = [];
        initialAppSyncList.push(...apiCallsForDownloadJob);

        let initialAppsyncPromises: Array<Promise<AxiosResponse<any>> | Promise<any>> = [];

        const downloadJob = openJob as any;
        const getFacility = getLocationsFacility as any;
        const getFacilityWorkOrders = getWorkOrders as any;
        const getJobPhotos = getPhotos as any;
        const getComments = getJobComments as any;
        const getSignatures = getJobSignatures as any;
        const getContacts = getContactsByFacility as any;
        const getInventory = getInventoryForJob as any;
        const getSAPWOs = getSAPWorkOrders as any;
        const getMPLR = (getPagedMPLRForJob as any) as (
            jobID: string,
            facilityID: string,
            page: number
        ) => Promise<any>;
        const getProducts = ensureLatestProducts as any;

        initialAppsyncPromises = [
            dispatch(downloadJob(job.id)),
            dispatch(getFacility(job.facilityID, true)),
            dispatch(getJobPhotos(job.id, job.facilityID, true)),
            dispatch(getComments(job, true)),
            dispatch(getSignatures(job.id, true)),
            dispatch(getContacts(job.facilityID, true)),
            dispatch(getSAPWOs(true))
        ];

        if (isJobWorkOrderFlow(job)) {
            // If it's a work order flow, we need to get the work orders
            initialAppSyncList.push({
                name: appSyncItemNames.WorkOrders,
                displayName: 'Work Orders',
                status: AppSyncItemStatus.inQueue
            });

            initialAppsyncPromises.push(dispatch(getFacilityWorkOrders(job.facilityID, true)));
        }

        dispatch({
            type: types.SET_INITIAL_APP_SYNC_LIST,
            initialAppSyncList
        });

        // Process inventory first
        const inventoryPromise = dispatch(getInventory(job.facilityID, job.id)).then(() => {
            // If a facility has thousands of installs, we need to batch the MPLR calls
            const installs = getState().manageInventory.installBasesByID;
            const installsForFacilityNumber = Object.values(installs).filter(
                install => install.facilityID === job.facilityID
            ).length;

            const batchAmount = 500;
            const numberOfRuns = Math.ceil(installsForFacilityNumber / batchAmount);

            const additionalPromises: Array<Promise<any>> = [];

            // Handle MPLR dispatches
            if (numberOfRuns === 0) {
                dispatch({
                    type: types.GET_MEASUREMENT_POINT_FACILITY_RESULTS_SUCCESS,
                    results: [],
                    facilityID: job.facilityID,
                    updateDate: moment().unix()
                });

                dispatch({
                    type: types.REMOVE_INITIAL_APP_SYNC_ITEM,
                    name: appSyncItemNames.MeasurementPointListResults
                });
            } else {
                // This could be way more linear, but for now, we're just going to put 1 MPLR item in the queue, even if we have multiple runs
                // Then once all promsies are resolved, we'll remove the item from the queue, so it feels like 1 long call, but it's just multiple calls happening
                dispatch({
                    type: types.UPDATE_INITIAL_APP_SYNC_ITEM_STATUS,
                    name: appSyncItemNames.MeasurementPointListResults,
                    status: AppSyncItemStatus.inProgress
                });

                for (let i = 0; i < numberOfRuns; i++) {
                    additionalPromises.push(dispatch(getMPLR(job.id, job.facilityID, i)));
                }
            }

            // Handle getProducts dispatch
            additionalPromises.push(dispatch(getProducts()));

            // Return a new Promise that waits for all MPLR and getProducts dispatches
            return Promise.all(additionalPromises);
        });

        // Add inventoryPromise to the initial promises
        initialAppsyncPromises.push(inventoryPromise);

        Promise.all(initialAppsyncPromises)
            .then(() => {
                // Final dispatch when everything is done. Mark this job as having been downloaded.
                dispatch({
                    type: types.GET_SIMPLE_MEASUREMENT_POINT_JOB_RESULTS_SUCCESS,
                    jobIDs: [job.id],
                    results: [],
                    updateDate: moment().unix()
                });
            })
            .catch((error: any) => {
                console.error('[initialAppSync]:', error);
            })
            .finally(() => {
                console.log('[Sync] Completed:', initialAppsyncPromises);
                dispatch({
                    type: types.REMOVE_INITIAL_APP_SYNC_ITEM,
                    name: appSyncItemNames.MeasurementPointListResults
                });
                dispatch(initialSyncEnd());
                Axios.defaults.timeout = constants.httpTimeout; // set the timeout back to default
            });
    };
};

// Technically this only processes the items in the Redux store now
export const initialAppSyncV2 = (
    onlyProcessItemsInRedux: boolean = false,
    onlyProcessItemsAppRefresh: boolean = false
): ThunkResult<any> => {
    return (dispatch, getState) => {
        dispatch(initialSyncStart());
        let initialAppSyncList: AppSyncItem[] = [];

        if (onlyProcessItemsInRedux) {
            const currentAppSyncList = getState().syncStatus.initialAppSyncList;
            // Reset the status of all items back to inQueue
            currentAppSyncList.forEach((item: AppSyncItem) => {
                initialAppSyncList.push({ ...item, status: AppSyncItemStatus.inQueue });
            });
        } else if (onlyProcessItemsAppRefresh) {
            initialAppSyncList.push(...apiCallsForAppRefresh);
        } else {
            initialAppSyncList.push(...apiCallsForSignIn);
        }

        console.log('[Sync] Processing:', initialAppSyncList);

        dispatch({
            type: types.SET_INITIAL_APP_SYNC_LIST,
            initialAppSyncList
        });

        Axios.defaults.timeout = constants.httpTimeoutInitialSync; // temporarily set the timeout longer

        // Define all the actions that are required to be run during the initial app sync
        const getProductInfoWithoutType = getProductInfo as any;
        const getFSEUsersWithoutType = getFSEUsersOnline as any;
        const getAllProductsHelperWithoutType = getAllProductsHelper as any;
        const getAllMeasurementPointListsWithoutType = getAllMeasurementPointLists as any;
        const getJobCommentCodesWithoutType = getJobCommentCodes as any;
        const getJobsWithoutType = getAssignedJobs as any;
        const getNextJobNumberWithoutType = getNextJobNumber as any;
        const getNPH = getNonProductiveHours as any;
        const getSAPMapping = getSAPJobMapping as any;
        const getUserLaborRates = getLaborRates as any;
        const getAllPartsWithoutType = getAllParts as any;

        let initialAppsyncPromises: Array<Promise<AxiosResponse<any>> | Promise<any>> = [];

        if (onlyProcessItemsInRedux) {
            // If processing only failed items, we need to loop through the list and only process the items that have failed
            initialAppSyncList.forEach((item: AppSyncItem) => {
                switch (item.name) {
                    case appSyncItemNames.Products:
                        initialAppsyncPromises.push(dispatch(getAllProductsHelperWithoutType(false)));
                        break;
                    case appSyncItemNames.ProductInfo:
                        initialAppsyncPromises.push(dispatch(getProductInfoWithoutType(false)));
                        break;
                    case appSyncItemNames.FSEUsers:
                        initialAppsyncPromises.push(dispatch(getFSEUsersWithoutType(false)));
                        break;
                    case appSyncItemNames.MeasurementPointLists:
                        initialAppsyncPromises.push(dispatch(getAllMeasurementPointListsWithoutType(false)));
                        break;
                    case appSyncItemNames.JobCommentCodes:
                        initialAppsyncPromises.push(dispatch(getJobCommentCodesWithoutType(false)));
                        break;
                    case appSyncItemNames.Jobs:
                        initialAppsyncPromises.push(dispatch(getJobsWithoutType(false, true)));
                        break;
                    case appSyncItemNames.NonProductiveHours:
                        initialAppsyncPromises.push(dispatch(getNPH(false)));
                        break;
                    case appSyncItemNames.NextJobNumber:
                        initialAppsyncPromises.push(dispatch(getNextJobNumberWithoutType(false)));
                        break;
                    case appSyncItemNames.SAPMapping:
                        initialAppsyncPromises.push(dispatch(getSAPMapping()));
                        break;
                    case appSyncItemNames.LaborRates:
                        initialAppsyncPromises.push(dispatch(getUserLaborRates()));
                        break;
                    case appSyncItemNames.Parts:
                        initialAppsyncPromises.push(dispatch(getAllPartsWithoutType(true)));
                        break;
                }
            });
        } else if (onlyProcessItemsAppRefresh) {
            // Process all required items after app update
            initialAppsyncPromises = [
                dispatch(getJobsWithoutType(false, true)),
                dispatch(getUserLaborRates(false)),
                dispatch(getAllMeasurementPointListsWithoutType(false)),
                dispatch(getAllProductsHelperWithoutType(false))
            ];
        } else {
            // Process all required items
            initialAppsyncPromises = [
                dispatch(getNextJobNumberWithoutType(false)),
                dispatch(getUserLaborRates(false)),
                dispatch(getNPH(false)),
                dispatch(getSAPMapping()),
                dispatch(getProductInfoWithoutType(false)),
                dispatch(getFSEUsersWithoutType(false)),
                dispatch(getJobCommentCodesWithoutType(false)),
                dispatch(getJobsWithoutType(false, true)),
                dispatch(getAllMeasurementPointListsWithoutType(false)),
                dispatch(getAllPartsWithoutType(true)),
                dispatch(getAllProductsHelperWithoutType(false))
            ];
        }

        Promise.all(initialAppsyncPromises)
            .then(() => {
                dispatch({
                    type: types.UPDATE_SYNC_STATUS_SUCCESS,
                    updateDate: moment().unix()
                });
            })
            .catch((error: any) => {
                console.error('[initialAppSync]:', error);
            })
            .finally(() => {
                console.log('[Sync] Completed:', initialAppSyncList);
                dispatch(initialSyncEnd());
                Axios.defaults.timeout = constants.httpTimeout; // set the timeout back to default
            });
    };
};

/*
 * check sync status often to make sure we have the most up to date data from the server
 * the first time this runs - use the dedicated endpoints for each type of data because /latest only returns updates since a certain date.
 * MPLresults are a bit special because we need the assigned jobs before we get the results.
 */
export const initialAppSync = (forceInitialAppSync = false): ThunkResult<any> => {
    return (dispatch, getState) => {
        if (getState().initialSyncActive === true) {
            return Promise.resolve(true);
        }
        Axios.defaults.timeout = constants.httpTimeoutInitialSync; // temporarily set the timeout longer
        dispatch(beginAjaxCall());
        dispatch(initialSyncStart());
        const getProductInfoWithoutType = getProductInfo as any;
        const getFSEUsersWithoutType = getFSEUsers as any;
        const getAllProductsHelperWithoutType = getAllProductsHelper as any;
        const getAllMeasurementPointListsWithoutType = getAllMeasurementPointLists as any;
        const getJobCommentCodesWithoutType = getJobCommentCodes as any;
        const getJobsWithoutType = getAssignedJobs as any;
        const getNextJobNumberWithoutType = getNextJobNumber as any;
        const getNPH = getNonProductiveHours as any;
        const getSAPMapping = getSAPJobMapping as any;
        const initialAppsyncPromises: Array<Promise<AxiosResponse<any>> | Promise<any>> = [
            dispatch(getAllProductsHelperWithoutType(false)),
            dispatch(getProductInfoWithoutType(false)),
            dispatch(getFSEUsersWithoutType(false)),
            dispatch(getAllMeasurementPointListsWithoutType(false)),
            dispatch(getJobCommentCodesWithoutType(false)),
            dispatch(getJobsWithoutType(false)),
            dispatch(getNPH(false)),
            dispatch(getNextJobNumberWithoutType(false)),
            dispatch(getSAPMapping())
        ];

        Promise.all(initialAppsyncPromises)
            .then(() => {
                dispatch(initialSyncEnd());
                dispatch({
                    type: types.UPDATE_SYNC_STATUS_SUCCESS,
                    updateDate: moment().unix()
                });
                Axios.defaults.timeout = constants.httpTimeout; // set the timeout back to default
            })
            .catch((error: any) => {
                if (forceInitialAppSync === false) {
                    document.dispatchEvent(new CustomEvent('initialSyncError'));
                } else {
                    constants.handleError(
                        error,
                        'Downloading updated data failed.  Please continue to work offline, or check your internet connection and try again.',
                        'warning'
                    );
                }
                dispatch(endAjaxCall());
                dispatch(initialSyncEnd());
                console.error('[initialAppSync]:', error);
            });
    };
};

export function checkSyncStatus(forceInitialAppSync?: boolean, forceLatest?: boolean): ThunkResult<any> {
    return (dispatch, getState) => {
        if (!getState().offline.online) {
            return;
        }
        if (getState().offline.outbox.length) {
            console.info('[checkSyncStatus]: items pending in the outbox, skipping checking sync status');
            return;
        }

        const { lastSync } = getState().syncStatus;
        const now = moment();
        if (
            !lastSync ||
            (lastSync && now.diff(moment(lastSync, 'X'), 'days') > constants.maxDaysBetweenFullSync) ||
            forceInitialAppSync
        ) {
            dispatch(initialAppSync(forceInitialAppSync));
        } else if (now.diff(moment(lastSync, 'X'), 'seconds') > 300 || forceLatest) {
            console.log('[checkSyncStatus]: getting latest data');
            dispatch(getLatestHelper(lastSync));
            //dispatch(getProductInfo()); // TODO do we need to add product info to /latest?
            //dispatch(getFSEUsers());
            //dispatch(getAllParts(forceLatest));
        }
    };
}

/*
* Initial App Sync runs after the user logs in and the app launches for the first time
or if it has been several days since the last sync which happens when forceInitialAppSync = true
*/

export const resetOutbox = () => ({
    type: 'Offline/RESET_STATE'
});

export const retryOutbox = () => ({
    type: 'Offline/SCHEDULE_RETRY',
    payload: { delay: 100 }
});

export const resetBusyOutbox = () => ({
    type: 'Offline/BUSY',
    payload: { busy: false }
});

export const setSyncStatus = (isSyncRunning: boolean) => ({
    type: types.SET_SYNC_STATUS,
    payload: { isSyncRunning }
});

export const setIsDownloadingJob = (downloadingJob: boolean) => ({
    type: types.SET_DOWNLOADING_JOB,
    payload: { downloadingJob }
});

export const setDownloadJobFailed = (downloadingJobFailed: boolean) => ({
    type: types.SET_DOWNLOADING_JOB_FAILED,
    payload: { downloadingJobFailed }
});

export function startSync(): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(setSyncStatus(true));
        dispatch(retryOutbox());
        dispatch(resetBusyOutbox());
        if (getState().offline.outbox.length === 0) {
            dispatch(checkSyncStatus(false, true));
            const { selectedJob } = getState().manageJob;
            // if (selectedJob && selectedJob.id) {
            //     dispatch(getJobDependenciesHelper([selectedJob], true)); // redownload dependencies for the selected job
            // }
        }

        dispatch(getSAPWorkOrders());
    };
}
export const offlineStatusOverrideOn = (): ThunkResult<any> => {
    return (dispatch, getState) => {
        localForage.setItem('offlineStatusOverride', true).then(() => {
            window.dispatchEvent(new CustomEvent('offline'));
        });
        dispatch({
            type: types.OVERRIDE_OFFLINE_STATUS
        });
    };
};
export const offlineStatusOverrideReset = (): ThunkResult<any> => {
    return (dispatch, getState) => {
        localForage.setItem('offlineStatusOverride', false).then(() => {
            window.dispatchEvent(new CustomEvent('online'));
        });

        dispatch({
            type: types.OVERRIDE_OFFLINE_STATUS_RESET
        });
    };
};

export function saveAppLog(t: TFunction): ThunkResult<any> {
    return (dispatch, getState) => {
        const { outbox } = getState().offline;
        let data: { log: string; jobID: string | null } = { log: JSON.stringify(outbox), jobID: null };

        const { selectedJob } = getState().manageJob;
        if (selectedJob && selectedJob.id && selectedJob.id.length > 0) {
            data = { ...data, jobID: selectedJob.id };
        }

        const axiosOptions: AxiosRequestConfig = {
            method: 'post',
            data
        };
        const url = API.saveAppLog;

        dispatch(beginAjaxCall());
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                dispatch(endAjaxCall());
            })
            .catch(error => {
                dispatch(endAjaxCall());
                console.error('[saveAppLog]: downloading state to user computer', error);
                // toastr.error(t('error'), t('toastMessage:saveAppStateError'), {
                //     ...constants.toastrError,
                //     timeOut: 0
                // });
                throw error;
            });
    };
}

/*
 * download the app state to file
 */
export function downloadAppState(): ThunkResult<any> {
    return (dispatch, getState) => {
        offlineProductsProvider.getAllProducts().then(products => {
            const dateString = moment.utc().format('YYYY-MM-DDThh-mma');
            const mainBackupFileName = `MyMedGasBackup_${dateString}.txt`;
            const productBackupFileName = `MyMedGasProductsBackup_${dateString}.txt`;
            const appStateFile = new File([JSON.stringify(getState())], mainBackupFileName, {
                type: 'text/plain',
                lastModified: new Date(0).valueOf()
            });
            const appProductsFile = new File([JSON.stringify(products)], productBackupFileName, {
                type: 'text/plain',
                lastModified: new Date(0).valueOf()
            });

            const fr = new FileReader();
            fr.onload = function(event) {
                const url = window.URL.createObjectURL(appStateFile);
                const a = window.document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = mainBackupFileName;
                window.document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            };
            fr.readAsText(appStateFile);
            setTimeout(() => {
                fr.onload = function(event) {
                    const url = window.URL.createObjectURL(appProductsFile);
                    const a = window.document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = productBackupFileName;
                    window.document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                };
                fr.readAsText(appProductsFile);
            }, 2000);
        });
    };
}

export const setLocationPathname = (pathname: string) => ({
    type: types.SET_LOCATION_PATHNAME,
    payload: { pathname }
});

export const closeAllModals = () => ({
    type: types.CLOSE_ALL_MODALS
});
