import * as React from 'react';

/*
 * The New User Manage
 */
import { Button, Col, Row } from 'react-bootstrap';
import { Ifacility, Ijob, IjobPopulated, ItableFiltersReducer, Itile, Iuser } from '../../models';
import queryString from 'query-string';
import ReactTable, { Column, RowInfo, SortingRule } from 'react-table';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
    getAssignedJobs,
    getDefaultReports,
    setSelectedJob,
    setTableFilter,
    toggleEditJobModal,
    toggleViewJobModal,
    toggleSearchFacilityModal,
    toggleAddFacilityModal,
    toggleRiskAssessmentModal,
    addOpenedMaintenanceJob,
    updateJobFinalCheckModal
} from '../../actions/manageJobActions';
import styled from 'styled-components';
import { Banner } from '../common/Banner';
import CommonModal from '../common/CommonModal';
import EditJobModal from './EditJobModal';
import { FieldConfig } from 'react-reactive-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormUtil } from '../common/FormUtil';
import { IinitialState } from '../../reducers';
import { RouteComponentProps } from 'react-router-dom';
import SearchTableForm from '../common/SearchTableForm';
import { TableUtil } from '../common/TableUtil';
import { ViewJobContainer } from './ViewJobContainer';
import { connect } from 'react-redux';
import { constants, daysUntilJobIsOverdue } from '../../constants/constants';
import { emptyTile, initialJob } from '../../reducers/initialState';
import { filterJobs, selectEditJobModal } from '../../reducers/manageJobReducer';
import { debounce, isEmpty } from 'lodash';
import { jobTypesIdEnumInverse, jobTypesIdEnum } from '../../models-enums';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import { updateVisibleProducts } from '../../actions/manageInventoryActions';
import { selectIsLoading, selectIsOnlineAndEmptyQueue } from '../../reducers/commonReducers';
import SearchFacilityModal from './SearchFacilityModal';
import AddFacilityModal from './AddFacilityModal';
import { RiskAssessmentModal, riskAssessmentQueryParamsEnum } from './RiskAssessmentModal';
import { addQuery } from '../common/OtherUtils';
import FilterTableModal from '../common/mobile/FilterTableModal';
import { LinkContainer } from 'react-router-bootstrap';
import { getIsMobile } from '../../reducers/configReducer';
import CommonMobileModal from '../common/CommonMobileModal';
import { CheckHoursModal } from './modals/CheckHoursModal';
import { clearSelectedJob } from '../../actions/workOrderActions';

const SAPButton = styled(Button)`
    /* margin-left: 10px; */
    background-color: #3fb5fc;
    border-color: #007ac3;
    color: #fff;
    margin-top: 32px;
`;

const SearchActionsContainer = styled(Col)`
    display: flex;
    justify-content: align-items;

    button {
        margin: 32px 10px 0 0;
        padding: 10px 20px;
    }
`;

interface RowInfoJob extends RowInfo {
    original: IjobPopulated;
}

interface Iprops extends RouteComponentProps<any> {
    isEditJobModalOpen: boolean;
    isMobile: boolean;
    queryParams: typeof riskAssessmentQueryParamsEnum;
    loading: boolean;
}

interface IdispatchProps {
    // Add your dispatcher properties here
    toggleEditJobModal: typeof toggleEditJobModal;
    toggleViewJobModal: typeof toggleViewJobModal;
    toggleRiskAssessmentModal: typeof toggleRiskAssessmentModal;
    toggleSearchFacilityModal: typeof toggleSearchFacilityModal;
    toggleAddFacilityModal: typeof toggleAddFacilityModal;
    getJobs: typeof getAssignedJobs;
    getDefaultReports: typeof getDefaultReports;
    showEditJobModal: boolean;
    showViewJobModal: boolean;
    openedMaintenanceJobIDs: string[];
    addOpenedMaintenanceJob: typeof addOpenedMaintenanceJob;
    setTableFilter: typeof setTableFilter;
    tableFilters: ItableFiltersReducer;
    tableData: IjobPopulated[];
    fseUsers: Iuser[];
    user: Iuser;
    setSelectedJob: typeof setSelectedJob;
    facilitiesByID: { [key: string]: Ifacility };
    data: { [key: string]: IjobPopulated };
    initComplete: boolean;
    selectedJob: Ijob;
    updateVisibleProducts: typeof updateVisibleProducts;
    online: boolean;
    defaultCustomerID: string;
    defaultFacilityID: string;
    showCheckHoursModal: boolean | undefined;
    isAPIQueueEmpty: boolean;
    modalOn: boolean;
    updateJobFinalCheckModal: typeof updateJobFinalCheckModal;
    clearSelectedJob: typeof clearSelectedJob;
    outbox: any[];
}

interface Istate {
    selectedRow: any;
    currentTile: Itile;
    searchFieldConfig: FieldConfig;
    columns: any[];
    showFilterSearch: boolean;
}

const jobIsOverdue = (job: IjobPopulated): boolean =>
    moment(job.startDate).add(daysUntilJobIsOverdue, 'days') < moment();

class ManageJob extends React.Component<Iprops & IdispatchProps & WithTranslation, Istate> {
    private setTableFilterDebounced: (formValues: { [key: string]: any }) => void;
    private isMobile = false;

    constructor(props: Iprops & IdispatchProps & WithTranslation) {
        super(props);
        this.state = {
            selectedRow: null,
            currentTile: emptyTile,
            searchFieldConfig: this.buildSearchFieldConfig(),
            columns: [],
            showFilterSearch: false
        };
        this.setTableFilterDebounced = debounce(this.props.setTableFilter, constants.formDebounceTime);
    }

    componentDidMount() {
        this.props.clearSelectedJob();
        this.setState({
            currentTile: constants.getTileByURL(this.props.location.pathname)
        });
        this.props.getDefaultReports(false);

        window.addEventListener('resize', this.handleResizeEvent);
        window.addEventListener('beforeunload', this.handleBeforeUnload);
        this.setMobileView();
        this.handleResize(true);

        // If the user is doing something odd like refreshing the page while the modal is open, or closing the tab and opening a new one, make sure to close the modal
        if (this.props.modalOn) {
            this.props.updateJobFinalCheckModal(false);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResizeEvent);
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
    }

    componentDidUpdate(prevProps: Iprops & IdispatchProps, prevState: Istate) {
        if (prevProps.showViewJobModal !== this.props.showViewJobModal && !this.props.showViewJobModal) {
            this.setState({ selectedRow: null });
        }

        // automatically get inventory every time a fitler changes or switch between showCompleted
        if (JSON.stringify(prevProps.tableFilters) !== JSON.stringify(this.props.tableFilters)) {
            if (this.isMobile) {
                // Rebuild the search field config from the values from props table filter, which gets passed back down to the FilterTableModal
                this.setState({ searchFieldConfig: this.buildSearchFieldConfig() });
            } else {
                const tableDiv = document.getElementsByClassName('rt-tbody');
                if (tableDiv && tableDiv.length) {
                    tableDiv[0].scrollTop = 0;
                }
                if (this.props.tableFilters.showCompleted && this.props.tableFilters.showCompleted.value === true) {
                    if (!this.props.online) {
                        toastr.warning(
                            'Warning',
                            'Please connect to the internet in order to view completed jobs.',
                            constants.toastrWarning
                        );
                        this.setTableFilterDebounced({
                            showCompleted: { value: false, label: 'Active' },
                            page: 0
                        });
                        return;
                    } else {
                        this.props.getJobs();
                    }
                }
            }
        }

        if (prevProps.isEditJobModalOpen !== this.props.isEditJobModalOpen) {
            if (this.props.isMobile && this.props.isEditJobModalOpen) {
                this.props.toggleSearchFacilityModal();
            }
        }
    }

    handleBeforeUnload = (event: BeforeUnloadEvent) => {
        if (this.props.outbox && this.props.outbox.length > 0) {
            // This triggers the confirmation dialog
            const message = 'You have pending items to sync, please wait until they are complete or you may lose data.'; // Browsers won't actually use custom text it seems
            event.preventDefault();
            event.returnValue = message; // Required for some browsers to show the prompt
            return message;
        }
    };

    setColumns = (forMobile: boolean) => {
        if (forMobile) {
            this.setState({
                columns: TableUtil.translateHeaders(
                    [
                        {
                            id: 'facility',
                            Header: 'facility',
                            accessor: ({ facilityID }: IjobPopulated) => {
                                return !isEmpty(this.props.facilitiesByID) && this.props.facilitiesByID[facilityID]
                                    ? this.props.facilitiesByID[facilityID].name
                                    : '';
                            },
                            minWidth: 150,
                            Cell: props => (
                                <div className="mngJob-header-column">
                                    <p className="mngJob-table-mobile-header">FACILITY</p>{' '}
                                    <p className="mngJob-table-value first-row">{props.value}</p>
                                </div>
                            )
                        },
                        {
                            id: 'jobNumber',
                            Header: 'jobNumber',
                            accessor: ({ jobNumber }: IjobPopulated) => (jobNumber ? jobNumber : ''),
                            width: 120,
                            Cell: props => (
                                <div className="mngJob-header-column">
                                    <p className="mngJob-table-mobile-header">JOB NUMBER</p>{' '}
                                    <p className="mngJob-table-value">{props.value}</p>
                                </div>
                            )
                        },
                        {
                            id: 'status',
                            Header: 'status',
                            accessor: 'status',
                            width: 120,
                            Cell: props => (
                                <div className="mngJob-header-column">
                                    <p className="mngJob-table-mobile-header">STATUS</p>{' '}
                                    <p className="mngJob-table-value">{props.value}</p>
                                </div>
                            )
                        },
                        {
                            id: 'type',
                            Header: 'type',
                            accessor: ({ jobTypeID }: IjobPopulated) => {
                                return this.props.t(
                                    jobTypesIdEnumInverse[jobTypeID as keyof typeof jobTypesIdEnumInverse] || ''
                                );
                            },
                            width: 170,
                            Cell: props => (
                                <div className="mngJob-header-column">
                                    <p className="mngJob-table-mobile-header">TYPE</p>{' '}
                                    <p className="mngJob-table-value">{props.value}</p>
                                </div>
                            )
                        },
                        {
                            id: 'startDate',
                            Header: 'startDate',
                            accessor: ({ startDate }: IjobPopulated) => {
                                return startDate
                                    ? moment
                                          .utc(startDate)
                                          .local(true)
                                          .format('DD-MMM-YY')
                                    : 'n/a';
                            },
                            width: 120,
                            Cell: props => (
                                <div className="mngJob-header-column">
                                    <p className="mngJob-table-mobile-header">START DATE</p>{' '}
                                    <p className="mngJob-table-value">{props.value}</p>
                                </div>
                            )
                        }
                    ],
                    this.props.t
                )
            });
        } else {
            this.setState({
                columns: TableUtil.translateHeaders(
                    [
                        {
                            id: 'status',
                            Header: 'status',
                            accessor: 'status',
                            width: 120,
                            Cell: props => (
                                <div className="mngJob-header-column">
                                    <p className="mngJob-table-value first-row">{props.value}</p>
                                </div>
                            )
                        },
                        {
                            id: 'type',
                            Header: 'type',
                            accessor: ({ jobTypeID }: IjobPopulated) => {
                                return this.props.t(
                                    jobTypesIdEnumInverse[jobTypeID as keyof typeof jobTypesIdEnumInverse] || ''
                                );
                            },
                            width: 170,
                            Cell: props => (
                                <div className="mngJob-header-column">
                                    <p className="mngJob-table-value">{props.value}</p>
                                </div>
                            )
                        },
                        {
                            id: 'jobNumber',
                            Header: 'jobNumber',
                            accessor: ({ jobNumber }: IjobPopulated) => (jobNumber ? jobNumber : ''),
                            width: 120,
                            Cell: props => (
                                <div className="mngJob-header-column">
                                    <p className="mngJob-table-value">{props.value}</p>
                                </div>
                            )
                        },
                        {
                            id: 'facility',
                            Header: 'facility',
                            accessor: ({ facilityID }: IjobPopulated) => {
                                return !isEmpty(this.props.facilitiesByID) && this.props.facilitiesByID[facilityID]
                                    ? this.props.facilitiesByID[facilityID].name
                                    : '';
                            },
                            minWidth: 150,
                            Cell: props => (
                                <div className="mngJob-header-column">
                                    <p className="mngJob-table-value">{props.value}</p>
                                </div>
                            )
                        },
                        {
                            id: 'startDate',
                            Header: 'startDate',
                            accessor: ({ startDate }: IjobPopulated) => {
                                return startDate
                                    ? moment
                                          .utc(startDate)
                                          .local(true)
                                          .format('DD-MMM-YY')
                                    : 'n/a';
                            },
                            sortMethod: (a: any, b: any) => {
                                return new Date(b).getTime() - new Date(a).getTime();
                            },
                            width: 120,
                            Cell: props => (
                                <div className="mngJob-header-column">
                                    <p className="mngJob-table-value">{props.value}</p>
                                </div>
                            )
                        },
                        {
                            id: 'endDate',
                            Header: 'endDate',
                            accessor: ({ endDate }: IjobPopulated) => {
                                return endDate
                                    ? moment
                                          .utc(endDate)
                                          .local(true)
                                          .format('DD-MMM-YY')
                                    : 'n/a';
                            },
                            sortMethod: (a: any, b: any) => {
                                return new Date(b).getTime() - new Date(a).getTime();
                            },
                            width: 120,
                            Cell: props => (
                                <div className="mngJob-header-column">
                                    <p className="mngJob-table-value">{props.value}</p>
                                </div>
                            )
                        },
                        {
                            id: 'downloaded',
                            Header: 'downloaded',
                            width: 130,
                            accessor: item => {
                                if (item.offlineStatus === true) {
                                    return (
                                        <span
                                            style={{
                                                color: constants.colors.blue,
                                                padding: '10px'
                                            }}
                                        >
                                            <FontAwesomeIcon icon="download" />
                                        </span>
                                    );
                                } else {
                                    return null;
                                }
                            }
                        },
                        {
                            id: 'map',
                            Header: 'map',
                            accessor: 'facilityID',
                            Cell: () => (
                                <span
                                    style={{
                                        color: constants.colors.blue,
                                        padding: '10px'
                                    }}
                                >
                                    <FontAwesomeIcon icon={['far', 'map-marked-alt']} />
                                </span>
                            ),
                            width: 90
                        }
                    ],
                    this.props.t
                )
            });
        }
    };

    handleResizeEvent = () => {
        this.handleResize();
    };

    handleResize = (renderFirstTime = false) => {
        // Only re-render the table if they are moving from Mobile to Desktop or from Desktop to Mobile
        if (this.isMobile && window.innerWidth !== undefined && window.innerWidth > 1024) {
            this.isMobile = false;
            this.setColumns(this.isMobile);
        } else if (this.isMobile === false && window.innerWidth !== undefined && window.innerWidth < 1024) {
            this.isMobile = true;
            this.setColumns(this.isMobile);
        } else if (renderFirstTime) {
            // should only hit this once on page load
            this.setColumns(this.isMobile);
        }
    };

    setMobileView = () => {
        if (window.innerWidth !== undefined && window.innerWidth > 1024) {
            this.isMobile = false;
        } else {
            this.isMobile = true;
        }
    };

    // TODO Still using constants here instead of translating enums
    buildSearchFieldConfig = (): FieldConfig => {
        const { startDate, endDate, jobType, showCompleted, search } = this.props.tableFilters;
        const disabled = false;
        const mainSearchControls = {
            search: {
                // TODO adding scope for this in a change request. waiting for approval.  Still need to update view to flow to two rows when on smaller width
                render: FormUtil.TextInputWithoutValidation,
                meta: {
                    label: 'search',
                    colWidth: 4,
                    colWidthLarge: 3,
                    type: 'text',
                    placeholder: 'nsJob:searchPlaceholderMobile'
                },
                formState: { value: search, disabled }
            },
            showCompleted: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    label: 'status',
                    options: constants.jobStatusOptions,
                    colWidth: 3,
                    colWidthLarge: 2,
                    type: 'select',
                    placeholder: 'nsJob:statusPlaceholder'
                },
                formState: {
                    value: showCompleted || {
                        value: false,
                        label: 'Active'
                    },
                    disabled
                }
            },
            jobType: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    label: 'type',
                    options: FormUtil.convertEnumToOptions(jobTypesIdEnum),
                    colWidth: 4,
                    colWidthLarge: 3,
                    type: 'select',
                    placeholder: 'nsJob:typePlaceholder',
                    isClearable: true,
                    shouldTranslate: true
                },
                formState: { value: jobType, disabled }
            },
            startDate: {
                render: FormUtil.Datetime,
                meta: {
                    label: 'dateRange',
                    colWidth: 3,
                    colWidthLarge: 2,
                    defaultValue: startDate ? startDate : '', // Datetime does not support formState
                    showTime: false,
                    placeholder: 'DD-MMM-YY'
                },
                options: {
                    validators: [FormUtil.validators.isValidMoment]
                }
            },
            endDate: {
                render: FormUtil.Datetime,
                meta: {
                    label: 'dateRangeEnd',
                    colWidth: 3,
                    colWidthLarge: 2,
                    defaultValue: endDate ? endDate : '',
                    showTime: false,
                    placeholder: 'DD-MMM-YY'
                },
                options: {
                    validators: [FormUtil.validators.isValidMoment]
                }
            }
        };

        return { controls: { ...mainSearchControls } };
    };

    /*
     * (reusable)
     * set the row color after a user selects it
     */
    getTrProps = (finalState: any, rowInfo?: RowInfoJob, column?: undefined, instance?: any) => {
        if (rowInfo) {
            const isSelected = rowInfo.index === this.state.selectedRow;
            const isOverdue = jobIsOverdue(rowInfo.original);
            let background = '';
            if (isSelected) {
                background = constants.colors[`${this.state.currentTile.color}Tr` as keyof typeof constants.colors];
            } else if (isOverdue) {
                background = constants.colors.redTr;
            }

            return {
                style: {
                    background
                }
            };
        } else {
            return {};
        }
    };

    onPageChange = (page: number) => {
        this.setTableFilterDebounced({ page });
    };

    /*
     * (reusable)
     * set the table filters to redux on each value change
     */
    onSearchValueChanges = (value: any, key: string) => {
        switch (key) {
            case 'startDate': {
                const startDateMoment = moment.isMoment(value) ? value : moment(value);
                this.setTableFilterDebounced({
                    startDate: startDateMoment.toISOString(),
                    page: 0
                });

                break;
            }
            case 'endDate': {
                const endDateMoment = moment.isMoment(value) ? value : moment(value);
                this.setTableFilterDebounced({
                    endDate: endDateMoment.toISOString(),
                    page: 0
                });
                break;
            }
            case 'showCompleted': {
                this.setTableFilterDebounced({ showCompleted: value, page: 0 });

                if (value && value.value) {
                    toastr.warning(
                        this.props.t('toastMessage:warning'),
                        this.props.t('mobile.searchCompletedJobWarning'),
                        constants.toastrWarning
                    );
                }

                break;
            }
            default:
                this.setTableFilterDebounced({ [key]: value, page: 0 });
        }
    };

    /*
     * (reusable)
     * set the sorted changes to redux
     */
    onPageSizeChange = (rows: number) => {
        this.setTableFilterDebounced({ rows, page: 0 });
    };

    onSortedChanged = (newSorted: SortingRule[], column: any, shiftKey: boolean) => {
        this.setTableFilterDebounced({ sorted: newSorted, page: 0 });
        this.setState({ selectedRow: {} });
    };

    checkForFirstTimeOpeningNewMaintenanceJob = (rowInfo: RowInfoJob) => {
        const jobID = this.props.selectedJob.id;
        const { usCountryID } = constants;
        const shouldOpenRiskAssessmentModal =
            rowInfo.original.jobTypeID === jobTypesIdEnum.maintenance &&
            !this.props.openedMaintenanceJobIDs.includes(jobID) &&
            rowInfo.original.facility.countryID === usCountryID;
        if (shouldOpenRiskAssessmentModal) {
            addQuery(riskAssessmentQueryParamsEnum.riskAssess, 'true', this.props.history);
            this.props.addOpenedMaintenanceJob(jobID);
            this.props.toggleViewJobModal();
        } else {
            this.props.toggleViewJobModal();
        }
    };

    getTdProps = (finalState: any, rowInfo?: RowInfoJob, column?: Column, instance?: any) => {
        if (
            rowInfo &&
            column &&
            column.id &&
            column.id === 'map' &&
            this.props.facilitiesByID[rowInfo.original.facilityID]
        ) {
            const facility = this.props.facilitiesByID[rowInfo.original.facilityID];
            const { address, address2, city, state, postalCode } = facility;
            const address2WithComma = address2.length ? address2 + ',' : address2; // only add this comma if there is an address2
            const encodedAddress = encodeURIComponent(
                `${address}, ${address2WithComma} ${city}, ${state}, ${postalCode}`
            );
            return {
                onClick: () => {
                    window.open(`https://www.google.com/maps/search/?api=1&query=${encodedAddress}`);
                },
                style: { textAlign: 'center' }
            };
        } else if (rowInfo) {
            return {
                onClick: () => {
                    const { offlineStatus } = rowInfo.original as any;

                    // If we are not online and up todate, you cannot open a job unless it's already downloaded
                    if (
                        this.props.isAPIQueueEmpty === false &&
                        offlineStatus !== undefined &&
                        offlineStatus === false
                    ) {
                        toastr.warning(
                            this.props.t('common:warning'),
                            'Please connect to the internet and sync your pending changes before opening a new job.',
                            constants.toastrWarning
                        );
                        return;
                    }

                    this.setState({
                        selectedRow: rowInfo.index
                    });
                    this.checkForFirstTimeOpeningNewMaintenanceJob(rowInfo);
                    this.props.setSelectedJob(rowInfo.original);
                    this.props.updateVisibleProducts();
                }
            };
        } else {
            return {};
        }
    };

    closeFilterJobModal = () => {
        this.setState({ showFilterSearch: false });
    };

    render() {
        // if (this.props.initComplete === false) {
        //     return (
        //         <Col xs={12}>
        //             <h4> Loading Jobs... </h4>
        //         </Col>
        //     );
        // }

        const modalTitle = (
            <span>
                {this.props.t('mobile.viewJobModal.jobInformation')}

                {this.isMobile === false && (
                    <small className="pull-right job-status">
                        {this.props.t(this.props.selectedJob ? this.props.selectedJob.status : '')}
                    </small>
                )}
            </span>
        );
        const { t } = this.props;

        return (
            <div className="manage-job" data-btn="true" data-manage="true">
                <div className="upper-wrap" data-menuopen="false">
                    <Banner
                        title={t('bannerTitleMobile')}
                        img={this.state.currentTile.srcBanner}
                        color={this.state.currentTile.color}
                    />
                    <Row className="search-form-container">
                        <Col xs={12} className="search-form-fields">
                            <SearchTableForm
                                fieldConfig={this.state.searchFieldConfig}
                                handleSubmit={() => {
                                    return;
                                }}
                                loading={this.props.loading}
                                colorButton={
                                    constants.colors[
                                        `${this.state.currentTile.color}Button` as keyof typeof constants.colors
                                    ]
                                }
                                t={this.props.t}
                                subscribeValueChanges={true}
                                onValueChanges={this.onSearchValueChanges}
                                showSearchButton={false}
                            />
                        </Col>
                        <SearchActionsContainer className="mobile-job-header">
                            <Button
                                disabled={this.props.loading}
                                className="mng-job-filter"
                                onClick={() => {
                                    this.setState({ showFilterSearch: true });
                                }}
                            >
                                <FontAwesomeIcon icon={['fas', 'filter']} size="xs" />
                                {t('filterSearch')}
                            </Button>
                            <Button
                                type="submit"
                                disabled={!this.props.isAPIQueueEmpty}
                                className="add-job-btn"
                                onClick={() => {
                                    if (!this.props.online) {
                                        toastr.warning(
                                            t('toastMessage:warning'),
                                            t('toastMessage:addJobForNewFacilityOnlineWarning'),
                                            constants.toastrWarning
                                        );
                                        return;
                                    }
                                    this.props.toggleSearchFacilityModal();
                                    this.props.setSelectedJob(initialJob);
                                }}
                            >
                                <FontAwesomeIcon icon={['far', 'plus']} />
                                {t('newJob')}
                            </Button>
                            {!this.props.isMobile && (
                                <LinkContainer to="/unassigned-sap-workorders">
                                    <SAPButton type="submit" disabled={!this.props.isAPIQueueEmpty} bsStyle="primary">
                                        {t('sapWO')}
                                    </SAPButton>
                                </LinkContainer>
                            )}
                        </SearchActionsContainer>
                    </Row>
                </div>
                <Row id="table-wrap">
                    <Col className="manage-job-table" xs={12}>
                        <ReactTable
                            data={this.props.tableData}
                            onSortedChange={this.onSortedChanged}
                            columns={this.state.columns}
                            getTrProps={this.getTrProps}
                            defaultPageSize={this.props.tableFilters.rows || constants.tablePageSizeDefault}
                            onPageSizeChange={this.onPageSizeChange}
                            showPageSizeOptions={true}
                            pageSizeOptions={constants.tablePageSizeOptions}
                            className={`beacon-table -highlight ${this.state.currentTile.color}`}
                            previousText={t('common:previous')}
                            nextText={t('common:next')}
                            onPageChange={this.onPageChange}
                            page={this.props.tableFilters.page}
                            sortable={true}
                            noDataText={t('common:noDataText')}
                            resizable={false}
                            getTdProps={this.getTdProps}
                        />
                    </Col>
                </Row>

                {this.isMobile && (
                    <CommonMobileModal
                        className="job-edit"
                        show={this.props.showViewJobModal}
                        onHide={this.props.toggleViewJobModal}
                        title={modalTitle}
                        container={document.getElementById('two-pane-layout')}
                    >
                        <ViewJobContainer
                            colorButton={
                                constants.colors[
                                    `${this.state.currentTile.color}Button` as keyof typeof constants.colors
                                ]
                            }
                            t={this.props.t}
                            location={this.props.location}
                            match={this.props.match}
                            history={this.props.history}
                        />
                    </CommonMobileModal>
                )}

                {this.isMobile === false && (
                    <CommonModal
                        className="job-edit"
                        show={this.props.showViewJobModal}
                        onHide={this.props.toggleViewJobModal}
                        title={modalTitle}
                        container={document.getElementById('two-pane-layout')}
                    >
                        <ViewJobContainer
                            colorButton={
                                constants.colors[
                                    `${this.state.currentTile.color}Button` as keyof typeof constants.colors
                                ]
                            }
                            t={this.props.t}
                            location={this.props.location}
                            match={this.props.match}
                            history={this.props.history}
                        />
                    </CommonModal>
                )}

                <FilterTableModal
                    fieldConfig={this.state.searchFieldConfig}
                    show={this.state.showFilterSearch}
                    closeFilterJobModal={this.closeFilterJobModal}
                    bgColor="#00B8B6"
                    brColor="#005553"
                    primaryColor="#007AC3"
                    setTableFilter={this.props.setTableFilter}
                ></FilterTableModal>

                <RiskAssessmentModal
                    colorButton={
                        constants.colors[`${this.state.currentTile.color}Button` as keyof typeof constants.colors]
                    }
                    history={this.props.history}
                    queryParams={this.props.queryParams}
                />
                <SearchFacilityModal
                    colorButton={
                        constants.colors[`${this.state.currentTile.color}Button` as keyof typeof constants.colors]
                    }
                    secondModal={false}
                />
                <AddFacilityModal
                    colorButton={
                        constants.colors[`${this.state.currentTile.color}Button` as keyof typeof constants.colors]
                    }
                    secondModal={true}
                />
                <EditJobModal
                    colorButton={
                        constants.colors[`${this.state.currentTile.color}Button` as keyof typeof constants.colors]
                    }
                    secondModal={true}
                />

                {this.props.showCheckHoursModal !== undefined && this.props.showCheckHoursModal === true && (
                    <CheckHoursModal t={t} />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops) => {
    // wait until app init is complete before we filter jobs in order to improve performance
    const tableData = state.syncStatus.isInitialSyncComplete ? filterJobs(state) : [];
    tableData.sort((a, b) => {
        if (jobIsOverdue(a)) {
            return -1;
        }
        return 1;
    });

    const { openedMaintenanceJobIDs } = state.manageJob;
    const queryParams = queryString.parse(ownProps.location.search) as typeof riskAssessmentQueryParamsEnum;

    return {
        user: state.user,
        loading: selectIsLoading(state),
        isEditJobModalOpen: selectEditJobModal(state),
        showEditJobModal: state.manageJob.showEditJobModal,
        showSearchFacilityModal: state.manageJob.showSearchFacilityModal,
        showAddFacilityModal: state.manageJob.showAddFacilityModal,
        showViewJobModal: state.manageJob.showViewJobModal,
        queryParams,
        openedMaintenanceJobIDs,
        tableData,
        fseUsers: state.manageJob.fseUsers,
        tableFilters: state.manageJob.tableFilters,
        facilitiesByID: state.facilities.facilitiesByID,
        jobsByID: state.manageJob.jobsByID,
        initComplete: state.syncStatus.isInitialSyncComplete,
        selectedJob: state.manageJob.selectedJob,
        online: state.offline.online,
        defaultFacilityID: state.manageJob.defaultFacilityID,
        isMobile: getIsMobile(state),
        showCheckHoursModal: state.user.showRecordHours,
        isAPIQueueEmpty: selectIsOnlineAndEmptyQueue(state),
        modalOn: state.manageJob.jobFinalCheckModal,
        outbox: state.offline.outbox
    };
};
export default withTranslation('nsJob')(
    connect(mapStateToProps, {
        getJobs: getAssignedJobs,
        getDefaultReports,
        toggleEditJobModal,
        toggleViewJobModal,
        toggleRiskAssessmentModal,
        toggleSearchFacilityModal,
        toggleAddFacilityModal,
        addOpenedMaintenanceJob,
        setTableFilter,
        setSelectedJob,
        updateVisibleProducts,
        updateJobFinalCheckModal,
        clearSelectedJob
    })(ManageJob)
);
