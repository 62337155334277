/*
 * Manage Inventory Form
 * Edit inventory items
 */

import * as React from 'react';

import {
    AbstractControl,
    FieldConfig,
    FormGenerator,
    FormGroup,
    GroupProps,
    Observable,
    Validators
} from 'react-reactive-form';
import { Button, Col } from 'react-bootstrap';
import { Ifacility, Ioption, Iproduct, IproductInfo, Isubcategory, ItableFiltersReducer, Iuser } from '../../models';
import {
    createProductName,
    saveProduct,
    selectProductToAdd,
    updateProduct
} from '../../actions/manageInventoryActions';

import { FormUtil } from '../common/FormUtil';
import { constants } from '../../constants/constants';
import { filter } from 'lodash';
import { toastr } from 'react-redux-toastr';
import { TFunction } from 'i18next';
import { initialProduct } from '../../reducers/initialState';
import { productCommunicationMethodEnum } from '../../models-enums';

const uuidv4 = require('uuid/v4');

interface IstateChanges extends Observable<any> {
    next: () => void;
}
interface AbstractControlEdited extends AbstractControl {
    stateChanges: IstateChanges;
}

interface Iprops {
    toggleModal: () => void;
    selectedItem: Iproduct;
    loading: boolean;
    colorButton: string;
    t: TFunction;
    productInfo: IproductInfo;
    tableFilters: ItableFiltersReducer;
    saveProduct: typeof saveProduct;
    updateProduct: typeof updateProduct;
    selectProductToAdd: typeof selectProductToAdd;
    facility: Ifacility;
    user: Iuser;
}

class ManageInventoryForm extends React.Component<Iprops, {}> {
    private formGroup: FormGroup | any;
    public fieldConfig: FieldConfig;
    private subscription: any;
    static defaultProps = {
        selectedItem: initialProduct
    };
    constructor(props: Iprops) {
        super(props);
        this.fieldConfig = FormUtil.translateForm(
            this.buildFieldConfig(this.props.productInfo, this.canEditInstalls() === false),
            this.props.t
        );
    }

    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    subscribeToValueChanges = () => {
        this.subscription = this.formGroup.get('mainCategoryID').valueChanges.subscribe((value: Ioption | null) => {
            if (value !== null) {
                this.updateControlOptions(this.filterSubcategories(value.value), 'subcategoryID');
                this.updateControlOptions(this.filterProductTypes(value.value), 'productTypeID');
                this.updateControlOptions(this.filterSystemSizes(value.value), 'systemSizeID');
            }
        });
    };
    buildFieldConfig = (productInfo: IproductInfo, disabled: boolean) => {
        const {
            sku,
            origin,
            description,
            brandID,
            subcategoryID,
            productTypeID,
            powerID,
            systemSizeID,
            isActive,
            isCompliant,
            isFinalProduct,
            hasSpares,
            communicationMethod,
            productStandards
        } = this.props.selectedItem;
        let filteredSubcategoryOptions: Ioption[] = [];
        let productTypeOptions = productInfo.productTypeOptions;
        let systemSizeOptions = productInfo.systemSizeOptions;
        let selectedMainCategory: any = null;
        const selectedBrand = brandID ? productInfo.brands[brandID] : null;
        const selectedOrigin = origin ? productInfo.originOptions.find(o => o.value === origin) : null;
        const selectedSubcategory = subcategoryID ? productInfo.subcategories[subcategoryID] : null;
        const selectedProductType = productTypeID ? productInfo.productTypes[productTypeID] : null;
        const selectedPower = powerID ? productInfo.powers[powerID] : null;
        const selectedSystemSize = systemSizeID ? productInfo.systemSizes[systemSizeID] : null;
        const selectedCommunicationMethod = {
            value: communicationMethod,
            label: this.props.t(productCommunicationMethodEnum[communicationMethod])
        };

        if (subcategoryID) {
            // Note: for now grabbing the mainCategoryID.  when we actually support having multiple mainCategories related to a single subCategory, we will need to store the mainCategoryID on the product object
            const mainCategoryID = productInfo.subcategories[subcategoryID].mainCategoryID;
            if (mainCategoryID) {
                selectedMainCategory = productInfo.mainCategories[mainCategoryID];
                filteredSubcategoryOptions = FormUtil.convertToOptions(this.filterSubcategories(mainCategoryID));
                productTypeOptions = FormUtil.convertToOptions(this.filterProductTypes(mainCategoryID));
                systemSizeOptions = FormUtil.convertToOptions(this.filterSystemSizes(mainCategoryID));
            }
        }
        const standard_ids = new Set(productStandards.map(standard => standard['standardID']));
        const selectedProductStandards: Ioption[] = productInfo.standardOptions.filter(option =>
            standard_ids.has(option['value'])
        );

        const fieldConfigControls = {
            sku: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'productForm.sku',
                    colWidth: 12,
                    type: 'input',
                    name: 'sku',
                    required: false
                },
                options: {
                    validators: Validators.maxLength(250)
                },
                formState: { value: sku, disabled }
            },
            description: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'productForm.description',
                    colWidth: 12,
                    componentClass: 'textarea',
                    name: 'description',
                    required: false
                },
                options: {
                    validators: Validators.maxLength(1000)
                },
                formState: { value: description, disabled }
            },
            // origin: {
            //     render: FormUtil.SelectWithoutValidation,
            //     meta: {
            //         label: 'common:origin',
            //         options: originOptions,
            //         colWidth: 12,
            //         type: 'select',
            //         isClearable: true
            //     },
            //     formState: {
            //         value: FormUtil.convertToSingleOption(selectedOrigin)
            //     }
            // },
            brandID: {
                render: FormUtil.Select,
                meta: {
                    options: productInfo.brandOptions,
                    label: 'productForm.brand',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    isMulti: false,
                    name: 'brand'
                },
                options: {
                    validators: FormUtil.validators.requiredWithTrim
                },
                formState: {
                    value: FormUtil.convertToSingleOption(selectedBrand),
                    disabled
                }
            },
            mainCategoryID: {
                render: FormUtil.Select,
                meta: {
                    options: productInfo.mainCategoryOptions,
                    label: 'mainCategory',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    isMulti: false,
                    name: 'main-category'
                },
                options: {
                    validators: [FormUtil.validators.requiredWithTrim]
                },
                formState: {
                    value: FormUtil.convertToSingleOption(selectedMainCategory),
                    disabled: this.props.selectedItem.id.length // do not allow users to change since this will cause strange issues
                }
            },
            subcategoryID: {
                render: FormUtil.Select,
                meta: {
                    options: selectedMainCategory ? filteredSubcategoryOptions : [],
                    label: 'productForm.subcategory',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    isMulti: false,
                    name: 'subcategory'
                },
                options: {
                    validators: FormUtil.validators.requiredWithTrim
                },
                formState: {
                    value: FormUtil.convertToSingleOption(selectedSubcategory),
                    disabled
                }
            },
            productTypeID: {
                render: FormUtil.Select,
                meta: {
                    options: productTypeOptions,
                    label: 'productForm.productType',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    isMulti: false,
                    name: 'gas-type',
                    isClearable: true,
                    required: false
                },
                formState: {
                    value: FormUtil.convertToSingleOption(selectedProductType),
                    disabled
                }
            },
            powerID: {
                render: FormUtil.Select,
                meta: {
                    options: productInfo.powerOptions,
                    label: 'productForm.power',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    isMulti: false,
                    name: 'power',
                    isClearable: true,
                    required: false
                },
                formState: {
                    value: FormUtil.convertToSingleOption(selectedPower),
                    disabled
                }
            },
            // model
            systemSizeID: {
                render: FormUtil.Select,
                meta: {
                    options: systemSizeOptions,
                    label: 'productForm.systemSize',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    isMulti: false,
                    name: 'system-size',
                    isClearable: true,
                    required: false
                },
                formState: {
                    value: FormUtil.convertToSingleOption(selectedSystemSize),
                    disabled
                }
            },
            communicationMethod: {
                render: FormUtil.Select,
                meta: {
                    options: FormUtil.convertEnumToOptions(productCommunicationMethodEnum),
                    label: 'productForm.communicationMethod',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    isMulti: false,
                    name: 'communicationMethod',
                    isClearable: false,
                    required: true,
                    shouldTranslate: true
                },
                formState: {
                    value: selectedCommunicationMethod,
                    disabled
                }
            },
            productStandards: {
                render: FormUtil.Select,
                meta: {
                    options: productInfo.standardOptions,
                    label: 'productForm.productStandards',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    isMulti: true,
                    name: 'product-standards'
                },
                options: {
                    validators: FormUtil.validators.requiredWithTrim
                },
                formState: {
                    value: selectedProductStandards,
                    disabled
                }
            },
            isActive: {
                render: FormUtil.Toggle,
                meta: { label: 'productForm.active', colWidth: 6 },
                formState: { value: isActive, disabled }
            },
            isCompliant: {
                render: FormUtil.Toggle,
                meta: { label: 'productForm.compliant', colWidth: 6 },
                formState: { value: isCompliant, disabled }
            },
            isFinalProduct: {
                render: FormUtil.Toggle,
                meta: { label: 'productForm.isFinalProduct', colWidth: 6 },
                formState: { value: isFinalProduct, disabled }
            },
            hasSpares: {
                render: FormUtil.Toggle,
                meta: { label: 'productForm.spares', colWidth: 6 },
                formState: { value: hasSpares, disabled }
            }
        } as { [key: string]: GroupProps };

        return {
            controls: { ...fieldConfigControls }
        };
    };

    filterSubcategories = (mainCategoryID: string) => {
        return filter(this.props.productInfo.subcategories, (sub: Isubcategory) => {
            return sub.mainCategoryID === mainCategoryID;
        });
    };

    filterProductTypes = (mainCategoryID: string) => {
        return filter(this.props.productInfo.productTypes, type => {
            return type.mainCategoryIDs.indexOf(mainCategoryID) !== -1;
        });
    };
    filterSystemSizes = (mainCategoryID: string) => {
        return filter(this.props.productInfo.systemSizes, systemSize => {
            return systemSize.mainCategoryIDs.indexOf(mainCategoryID) !== -1;
        });
    };
    updateControlOptions = (options: any[], controlKey: string) => {
        const control = this.formGroup.get(controlKey) as AbstractControlEdited;
        control.meta.options = FormUtil.convertToOptions(options);
        control.stateChanges.next();
        this.formGroup.patchValue({ [controlKey]: null });
    };

    handleSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (this.formGroup.status === 'INVALID') {
            this.formGroup.markAsSubmitted();
            toastr.error('Please check invalid inputs', '', constants.toastrError);
            return;
        }

        const formValues = FormUtil.getValues(this.formGroup.value);

        // when mainCategoryID is disabled it is not included in the form value
        let mainCategoryID = this.formGroup.value.mainCategoryID;
        if (!mainCategoryID) {
            const subCategory = this.props.productInfo.subcategories[this.props.selectedItem.subcategoryID];
            mainCategoryID = FormUtil.convertToSingleOption(
                this.props.productInfo.mainCategories[subCategory.mainCategoryID]
            );
        }
        let productStandards = this.formGroup.value.productStandards;
        productStandards = productStandards.map((item: any) => ({
            StandardID: item.value
        }));

        const productName = createProductName({
            ...this.formGroup.value,
            mainCategoryID
        });

        // mobile can only creat new products - NO editing
        const newItem = {
            ...this.props.selectedItem,
            ...formValues,
            name: productName,
            id: uuidv4(),
            productStandards
        };

        let cleanedItem: any = { ...newItem };
        cleanedItem.mergedProductID =
            cleanedItem.mergedProductID && cleanedItem.mergedProductID !== '' ? cleanedItem.mergedProductID : null;
        cleanedItem.powerID = cleanedItem.powerID && cleanedItem.powerID !== '' ? cleanedItem.powerID : null;

        this.props.saveProduct(cleanedItem);
        this.props.selectProductToAdd(cleanedItem);
    };
    setForm = (form: AbstractControl) => {
        this.formGroup = form;
        this.formGroup.meta = {
            loading: this.props.loading
        };
        this.subscribeToValueChanges();
    };

    canEditInstalls = () => {
        return (
            constants.hasSecurityFunction(this.props.user, constants.securityFunctions.ManageInventory.id) ||
            constants.hasSecurityFunction(this.props.user, constants.securityFunctions.FSE.id)
        );
    };

    render() {
        const { t } = this.props;

        return (
            <div className={this.props.colorButton}>
                {!(this.props.selectedItem && this.props.selectedItem.id) && (
                    <Col xs={12}>
                        <p style={{ lineHeight: '1.4rem' }}>{t('newProductInstructions')}</p>
                    </Col>
                )}

                <form onSubmit={this.handleSubmit} className="beacon-form clearfix">
                    <FormGenerator onMount={this.setForm} fieldConfig={this.fieldConfig} />
                    <Col xs={12} className="form-buttons text-right">
                        <Button bsStyle="default" type="button" className="pull-left" onClick={this.props.toggleModal}>
                            {t('common:cancel')}
                        </Button>
                        <Button bsStyle={this.props.colorButton} type="submit" disabled={this.props.loading}>
                            {t('common:save')}
                        </Button>
                    </Col>
                </form>
            </div>
        );
    }
}
export default ManageInventoryForm;
