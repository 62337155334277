/*
 * Edit Job Modal and Redux Connector
 */

import * as React from 'react';

import { IjobDefault } from '../../../models';

import CommonModal from '../../common/CommonModal';
import EditJobDefaultsForm from './EditJobDefaultsForm';

import { IinitialState } from '../../../reducers';
import { connect } from 'react-redux';
import { selectIsLoading } from '../../../reducers/commonReducers';
import { withTranslation, WithTranslation } from 'react-i18next';
import { IeditJobDefaultsFormValues } from '../../../modelsForms';
import { selectJobDefaultsForJob } from '../../../reducers/measurementPointListsReducer';
import {
    setJobDefaultsFormValues,
    toggleEditJobDefaultsModal,
    updateJobDefaultsFormValue
} from '../../../actions/manageInventoryActions';
import { saveJobDefaults } from '../../../actions/measurementPointListActions';

interface Iprops {
    colorButton: any;
    selectedJobID: string;
    secondModal?: boolean;
}

interface IdispatchProps {
    showEditJobDefaultsModal: boolean;
    loading: boolean;
    toggleModal: () => void;
    selectedJobID: string;
    jobDefaultsForJob: IjobDefault[];
    saveJobDefaults: typeof saveJobDefaults;
    updateFormValue: (formValue: { [key: string]: any }) => void;
    setFormValues: (formValues: Partial<IeditJobDefaultsFormValues>) => void;
    formValues: Partial<IeditJobDefaultsFormValues>;
}

class EditJobDefaultsModal extends React.Component<Iprops & IdispatchProps & WithTranslation, {}> {
    render() {
        const modalTitle = this.props.t('manageInventory:editJobDefaultsModal');
        return (
            <CommonModal
                show={this.props.showEditJobDefaultsModal}
                className="job-edit"
                onHide={this.props.toggleModal}
                title={modalTitle}
                container={document.getElementById('two-pane-layout')}
                secondModal={this.props.secondModal}
            >
                <EditJobDefaultsForm {...this.props} />
            </CommonModal>
        );
    }
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops) => {
    // TODO Add modal title of product
    return {
        loading: selectIsLoading(state),
        showEditJobDefaultsModal: state.manageInventory.showEditJobDefaultsModal,
        jobDefaultsForJob: selectJobDefaultsForJob(state, {
            jobID: ownProps.selectedJobID
        }),
        formValues: state.manageInventory.editJobDefaultsFormValues
    };
};

export default withTranslation('manageInventory')(
    connect(mapStateToProps, {
        setFormValues: setJobDefaultsFormValues,
        updateFormValue: updateJobDefaultsFormValue,
        toggleModal: toggleEditJobDefaultsModal,
        saveJobDefaults
    })(EditJobDefaultsModal)
);
