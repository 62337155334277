import * as React from 'react';

import { Button } from 'react-bootstrap';
import i18next from 'i18next';

interface Iprops {
    offlineStatusOverrideReset: () => void;
    offlineStatusOverride: boolean;
}

const OfflineStatusOverrideIndicator = (props: Iprops) => {
    return props.offlineStatusOverride ? (
        <span className="offline-status-override">
            <span className="pending-text">{i18next.t('common:offlineModeActive')}</span>
            <Button
                type="button"
                bsStyle="default"
                onClick={() => {
                    props.offlineStatusOverrideReset();
                }}
                bsSize="sm"
            >
                {i18next.t('common:goOnline')}
            </Button>
        </span>
    ) : null;
};

export default OfflineStatusOverrideIndicator;
