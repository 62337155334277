import React, { useEffect, useLayoutEffect, useMemo } from 'react';
import { FieldConfig, FormArray, FormGenerator, FormGroup, Handler, InputType } from 'react-reactive-form';
import { useSelector } from 'react-redux';
import { selectDeficiencyCodesByStandardID } from '../../reducers/manageJobCommentsReducer';
import { IinitialState } from '../../reducers';
import { initialFacility } from '../../reducers/initialState';
import { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { getIsMobile } from '../../reducers/configReducer';
import NoteIcon from '../../images/note-icon.svg';
import CommonModal from '../common/CommonModal';
import { FormUtil } from '../common/FormUtil';
import RTE from '../common/TinyMCERichTextEditor';
import CommonMobileModal from '../common/CommonMobileModal';
import { Ioption } from '../../models';

interface IProps {
    meta: { [key: string]: any };
    handler: (inputType?: InputType, value?: string) => Handler;
}

const NewMPNoteButton = ({ meta, handler }: IProps) => {
    const isMobile = useSelector(getIsMobile);
    const facility = useSelector(
        (state: IinitialState) =>
            state.facilities.facilitiesByID[state.manageJob.selectedJob.facilityID] || initialFacility
    );
    const codeOptions = useSelector((state: IinitialState) =>
        selectDeficiencyCodesByStandardID(state.manageJobComment.deficiencyCodesByID, facility.standardID)
    );

    const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [form, setForm] = useState<FormGroup | FormArray>();
    const [content, setContent] = useState<string>('');

    const Modal = useMemo(() => (isMobile ? CommonMobileModal : CommonModal), [isMobile]);
    const buttonStyle = handler().value && handler().value.length ? 'warning' : 'default';

    const [fieldConfig] = useState<FieldConfig>({
        controls: {
            codeID: {
                render: FormUtil.Select,
                meta: {
                    label: 'Standard Text',
                    colWidth: 12,
                    isMulti: false,
                    name: 'code',
                    required: false,
                    options: FormUtil.convertToOptions(codeOptions)
                },
                formState: {
                    value: undefined,
                    disabled: false
                }
            }
        }
    });

    useLayoutEffect(() => {
        if (showModal) {
            setContent(handler().value || '');
        }
    }, [showModal]);

    const onMount = (mountForm: FormGroup | FormArray) => {
        if (isFirstRender) {
            setForm(mountForm);
            setIsFirstRender(false);
        }
    };

    useEffect(() => {
        const onChange = (value: Ioption) => {
            const { description: html } = codeOptions[value.value];
            setContent(html);
        };
        form?.get('codeID').valueChanges.subscribe(onChange);
        return () => {
            form?.get('codeID').valueChanges.unsubscribe(onChange);
        };
    }, [form, setContent, showModal]);

    const save = () => {
        handler().onChange(content);
        setShowModal(false);
        setContent('');
    };

    const close = () => {
        setShowModal(false);
        setContent('');
    };

    return (
        <>
            <Col xs={2} className="mp-row">
                <Button
                    type="button"
                    bsStyle={buttonStyle}
                    onClick={() => {
                        setShowModal(true);
                    }}
                    className="pull-right"
                    style={
                        isMobile
                            ? {
                                  marginTop: '20px',
                                  width: '100%',
                                  height: '36px',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center'
                              }
                            : meta.center
                            ? { marginLeft: '45px' }
                            : { marginTop: '25px' }
                    }
                >
                    {isMobile ? <img src={NoteIcon} /> : meta.t('notes')}
                </Button>
            </Col>
            <Modal
                enforceFocus={false}
                show={showModal}
                onHide={() => setShowModal(false)}
                className={isMobile ? '' : 'measurement-point-note-modal'}
                title={meta.t(meta.label)}
                container={isMobile && document.getElementById('two-pane-layout')}
            >
                <form className="beacon-form">
                    <Row style={{ margin: '0px 5px' }}>
                        <FormGenerator onMount={onMount} fieldConfig={fieldConfig} />
                    </Row>
                    <Row style={{ margin: '0px 20px' }}>
                        <RTE content={content} setContent={setContent} />
                    </Row>
                    <Col xs={12} className="form-buttons text-right">
                        <Button bsStyle="default" type="button" className="pull-left" onClick={close}>
                            {meta.t('common:cancel')}
                        </Button>
                        <Button
                            bsStyle="default"
                            type="button"
                            className="pull-left"
                            style={{ marginLeft: '10px' }}
                            onClick={() => {
                                setContent('');
                            }}
                        >
                            {meta.t('mobile:resetSelection')}
                        </Button>
                        {!meta.isHistoricalNote && (
                            <Button bsStyle="primary" type="button" onClick={save}>
                                {meta.t('common:save')}
                            </Button>
                        )}
                    </Col>
                </form>
            </Modal>
        </>
    );
};

export default NewMPNoteButton;
