import * as React from 'react';
import * as localForage from 'localforage';
import ReactTable from 'react-table';
import { Button } from 'react-bootstrap';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { TFunction } from 'i18next';
import moment from 'moment';
import { Itile, Iphoto } from '../../models';
import { constants } from '../../constants/constants';
import { TableUtil } from '../common/TableUtil';
import CommonModal from '../common/CommonModal';
import { getPhotos, updatePhoto, deletePhoto } from '../../actions/photosActions';
import EditPhotoForm from './EditPhotoForm';
import { initialPhoto } from '../../reducers/initialState';
import loadingPlaceholder from '../../images/Photo_Loading.png';
import { find, forEach, map } from 'lodash';
import PhotoList from './Mobile/PhotoList';

interface Iprops {
    installBaseID: string;
    getPhotos: typeof getPhotos;
    updatePhoto: typeof updatePhoto;
    deletePhoto: typeof deletePhoto;
    tableData: Iphoto[];
    t: TFunction;
    loading: boolean;
    currentTile: Itile;
    isMobile: boolean;
}
interface Istate {
    selectedPhoto: Iphoto;
    showEditPhotoModal: boolean;
    columns: any[];
    activePhoto: number;
    lightboxIsOpen: boolean;
    tableDataWithFallback: Iphoto[];
}

export class PhotoTable extends React.PureComponent<Iprops, Istate> {
    static defaultProps = {
        currentTile: constants.getTileByURL(window.location.pathname)
    };
    constructor(props: Iprops) {
        super(props);
        this.state = {
            selectedPhoto: initialPhoto,
            showEditPhotoModal: false,
            columns: this.columns(this.props.t),
            activePhoto: 0,
            lightboxIsOpen: false,
            tableDataWithFallback: props.tableData
        };
    }

    componentDidMount(): void {
        this.handleImageFallback();
    }
    componentDidUpdate(prevProps: Iprops): void {
        if (prevProps.tableData !== this.props.tableData) {
            this.handleImageFallback();
        }
    }

    addLocalImageUrl = (image: Iphoto) => {
        return localForage.getItem(image.id).then((item: any) => {
            const localUrl = window.URL.createObjectURL(item);
            return { ...image, lightboxUrl: localUrl, thumbnailUrl: localUrl };
        });
    };

    handleImageFallback = () => {
        const imageLocalPromises: Array<Promise<any>> = [];
        forEach(this.props.tableData, image => {
            if (image.lightboxUrl.startsWith('blob:')) {
                imageLocalPromises.push(this.addLocalImageUrl(image));
            } else if (image.lightboxUrl.length === 0) {
                // TODO: fallback to default image
            }
        });
        Promise.all(imageLocalPromises).then(localImages => {
            const tableDataWithFallback = map(this.props.tableData, image => {
                const foundLocalImage = find(localImages, { id: image.id });
                if (foundLocalImage) {
                    return foundLocalImage;
                } else {
                    return image;
                }
            });
            this.setState({ tableDataWithFallback });
        });
    };

    openLightbox = (event: any, { photo, index }: any): void => {
        this.setState({ activePhoto: index, lightboxIsOpen: true });
    };

    closeLightbox = () => {
        this.setState({ activePhoto: 0, lightboxIsOpen: false });
    };

    columns = (t: TFunction) =>
        TableUtil.translateHeaders(
            [
                {
                    Header: 'photoHeader',
                    accessor: 'thumbnailUrl',
                    width: 180,
                    Cell: row => (
                        <img
                            style={{
                                maxWidth: '160px',
                                cursor: 'pointer'
                            }}
                            src={row.value || loadingPlaceholder}
                            alt=""
                            onClick={() =>
                                this.setState({
                                    activePhoto: row.index,
                                    lightboxIsOpen: true
                                })
                            }
                        />
                    )
                },
                {
                    Header: 'commentsHeader',
                    accessor: 'comments',
                    minWidth: 100
                },
                {
                    Header: 'dateHeader',
                    accessor: 'createDate',
                    width: 120,
                    Cell: row => moment.utc(row.value).format('MM/DD/YYYY')
                },
                {
                    Header: 'showInReportHeader',
                    accessor: 'isInReport',
                    width: 140,
                    Cell: row => (
                        <div style={{ textAlign: 'center' }}>
                            <input type="checkbox" checked={row.value} disabled />
                        </div>
                    )
                },
                {
                    Header: '',
                    id: 'delete',
                    width: 100,
                    Cell: row => {
                        return (
                            <Button
                                bsStyle="default"
                                onClick={() => {
                                    this.setState({
                                        selectedPhoto: row.original,
                                        showEditPhotoModal: true
                                    });
                                }}
                            >
                                Edit
                            </Button>
                        );
                    }
                }
            ],
            t
        );

    render() {
        const { loading, t, isMobile } = this.props;
        const tableClassName = `beacon-table photos -highlight ${this.props.currentTile.color}`;

        if (isMobile) {
            return (
                <PhotoList
                    data={this.state.tableDataWithFallback}
                    t={t}
                    installBaseID={this.props.installBaseID}
                    loading={loading}
                    updatePhoto={this.props.updatePhoto}
                    deletePhoto={this.props.deletePhoto}
                />
            );
        }

        return (
            <div>
                <ReactTable
                    data={this.state.tableDataWithFallback}
                    pageSize={this.state.tableDataWithFallback.length || 3}
                    columns={this.state.columns}
                    showPageSizeOptions={false}
                    showPagination={false}
                    className={tableClassName}
                    sortable={false}
                    multiSort={false}
                    noDataText={t('addPhotoTableNoDataText')}
                    resizable={false}
                    loading={loading}
                />

                <CommonModal
                    className="job-edit"
                    show={this.state.showEditPhotoModal}
                    onHide={() => this.setState({ showEditPhotoModal: false })}
                    title="Edit Image"
                    container={document.getElementById('two-pane-layout')}
                >
                    <EditPhotoForm
                        t={t}
                        loading={loading}
                        installBaseID={this.props.installBaseID}
                        formValues={this.state.selectedPhoto}
                        updatePhoto={this.props.updatePhoto}
                        deletePhoto={this.props.deletePhoto}
                        toggleModal={() => this.setState({ showEditPhotoModal: false })}
                    />
                </CommonModal>

                <ModalGateway>
                    {this.state.lightboxIsOpen && (
                        <Modal
                            onClose={this.closeLightbox}
                            styles={{
                                positioner: (base: any) => ({
                                    ...base,
                                    zIndex: 10000
                                }),
                                blanket: (base: any) => ({
                                    ...base,
                                    zIndex: 10000
                                })
                            }}
                        >
                            <Carousel
                                currentIndex={this.state.activePhoto}
                                views={this.state.tableDataWithFallback.map(photo => ({
                                    ...photo,
                                    source: photo.lightboxUrl,
                                    caption: photo.comments
                                }))}
                            />
                        </Modal>
                    )}
                </ModalGateway>
            </div>
        );
    }
}
