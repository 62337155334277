/*
 * Comment on a Measurement Point List
 */

import * as React from 'react';

import { AbstractControl, FieldConfig, FormGenerator, FormGroup, GroupProps } from 'react-reactive-form';
import { IinstallBasePopulated, Ijob, ImeasurementPointResult } from '../../models';

import { FormUtil } from '../common/FormUtil';
import { RouteComponentProps } from 'react-router';
import { Row } from 'react-bootstrap';
import { updateMeasurementPointResult } from '../../actions/measurementPointResultsActions';
import { TFunction } from 'i18next';

interface Iprops extends RouteComponentProps<{ installID: string }> {
    loading: boolean;
    colorButton: string;
    t: TFunction;
    showCancel?: boolean;
    updateMeasurementPointResult: typeof updateMeasurementPointResult;
    selectedInstall: IinstallBasePopulated;
    selectedJob: Ijob;
    selectedResult: ImeasurementPointResult;
}
interface Istate {
    fieldConfig: FieldConfig;
    commentLength: number;
}
export class MeasurementPointListCommentForm extends React.Component<Iprops, Istate> {
    private formGroup: FormGroup | any;
    private subscription: any;
    private submitTimeout: any;
    constructor(props: Iprops) {
        super(props);
        this.state = {
            fieldConfig: FormUtil.translateForm(this.buildFieldConfig(), this.props.t),
            commentLength: this.props.selectedResult.notes.length
        };
    }

    /*
     * LeftColumnContainer waits to render the Comment form until there is a selectedResult with an ID.  this helps load the initial comment reliably.
     */

    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        clearTimeout(this.submitTimeout);
    }
    componentDidUpdate(prevProps: Iprops) {
        if (prevProps.selectedResult.id !== this.props.selectedResult.id) {
            // this.formGroup.reset();
            const fieldConfig = FormUtil.translateForm(this.buildFieldConfig(), this.props.t);
            this.setState({ fieldConfig });
        }
    }
    subscribeToValueChanges = () => {
        this.subscription = this.formGroup.get('comment').valueChanges.subscribe((value: string | null) => {
            if (value !== null) {
                this.setState({ commentLength: value.length });

                clearTimeout(this.submitTimeout);
                this.submitTimeout = setTimeout(() => {
                    this.handleSubmit(value); // this causes performance issues so we use a rudamentary debounce
                }, 300);
            }
        });
    };

    buildFieldConfig = () => {
        const comment = this.props.selectedResult.notes;
        const disabled = false;

        const fieldConfigControls = {
            comment: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'comment',
                    colWidth: 12,
                    name: 'comment',
                    componentClass: 'textarea',
                    required: false,
                    rows: 4,
                    maxLength: 1000
                },
                formState: { value: comment, disabled }
            }
        } as { [key: string]: GroupProps };

        return {
            controls: { ...fieldConfigControls }
        };
    };
    handleSubmit = (comment: string) => {
        if (this.formGroup.status === 'INVALID') {
            this.formGroup.markAsSubmitted();
            // toastr.error('Please check invalid inputs', '', constants.toastrError);
            return;
        }
        const result = { ...this.props.selectedResult, notes: comment };
        this.props.updateMeasurementPointResult(result, false);
    };

    setForm = (form: AbstractControl) => {
        this.formGroup = form;
        this.formGroup.meta = {
            loading: this.props.loading
        };
        this.subscribeToValueChanges();
    };

    render() {
        const formClassName = `${this.props.colorButton}`;
        return (
            <div className={formClassName}>
                <Row className="clearfix beacon-form">
                    <FormGenerator onMount={this.setForm} fieldConfig={this.state.fieldConfig} />
                    <p className="lbl-character-count">{this.state.commentLength}/1000 characters</p>
                </Row>
            </div>
        );
    }
}
