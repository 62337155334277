/*
 * Manage Location Form
 * Edit location items
 */

import * as React from 'react';

import { AbstractControl, FormGenerator, FormGroup, Validators } from 'react-reactive-form';
import { Breadcrumb, BreadcrumbItem, Button, Modal } from 'react-bootstrap';
import { Ibuilding, Ifacility, Ifloor, Ilocation, ItableFiltersReducer } from '../../models';
import { saveAnyLocation, updateAnyLocation, toggleEditLocationModal } from '../../actions/manageLocationActions';
import { FormUtil } from '../common/FormUtil';
import { constants } from '../../constants/constants';
import { forEach } from 'lodash';
import { toastr } from 'react-redux-toastr';
import { TFunction } from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WithTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { getIsMobile } from '../../reducers/configReducer';

interface Iprops {
    selectedItem?: any;
    selectedType: 'Building' | 'Floor' | 'Location' | 'Room';
    loading: boolean;
    colorButton: string;
    t: TFunction;
    tableFilters: ItableFiltersReducer;
    saveAnyLocation: typeof saveAnyLocation;
    updateAnyLocation: typeof updateAnyLocation;
    toggleEditLocationModal: typeof toggleEditLocationModal;
    facility: Ifacility;
    selectedBuilding: Ibuilding;
    selectedFloor: Ifloor;
    selectedLocation: Ilocation;
    show: boolean;
    title: string;
}

const MobileButtonGroup = styled.div`
    color: #000;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 24px;
`;

type IButtonGroupProps = {
    isMobile: boolean;
};

const ButtonGroup: React.FunctionComponent<IButtonGroupProps> = ({ children, isMobile }) => {
    if (isMobile) {
        return <MobileButtonGroup>{children}</MobileButtonGroup>;
    }

    return <>{children}</>;
};

const ManageLocationForm: React.FC<Iprops & WithTranslation> = props => {
    const [formGroup, setFormGroup] = React.useState<FormGroup | any>();
    const isMobile = useSelector(getIsMobile);

    const handleClose = () => {
        props.toggleEditLocationModal();
    };

    const fieldConfig = React.useMemo(() => {
        return {
            controls: {
                name: {
                    options: {
                        validators: [FormUtil.validators.requiredWithTrim, Validators.maxLength(250)]
                    },
                    render: FormUtil.TextInput,
                    meta: {
                        label: 'name',
                        colWidth: 12,
                        type: 'input',
                        autoFocus: true
                    }
                }
            }
        };
    }, []);

    React.useEffect(() => {
        if (!props.selectedItem || !formGroup) {
            return;
        }

        forEach(props.selectedItem, (value, key) => {
            if (typeof value === 'string' && key.split('ID').length === 1) {
                // it is a string and did Not find 'ID'
                formGroup.patchValue({ [key]: value });
            }
        });
    }, [props.selectedItem, formGroup]);

    const handleSubmit = (e: React.MouseEvent<Button>) => {
        e.preventDefault();
        if (formGroup.status === 'INVALID') {
            formGroup.markAsSubmitted();
            toastr.error('Please check invalid inputs', '', constants.toastrError);
            return;
        }

        const { name } = formGroup.value;
        if (props.selectedItem && props.selectedItem.id && props.selectedItem.id.length) {
            const newItem = {
                ...props.selectedItem,
                name
            };
            // updating a location object
            props.updateAnyLocation(newItem, props.facility.id);
            return;
        }
        // creating a new location
        props.saveAnyLocation(name, props.facility.id);
    };

    const setForm = (form: AbstractControl) => {
        const formGroup = form;
        formGroup.meta = {
            loading: props.loading
        };
        setFormGroup(formGroup);
    };

    // get breadcrumb path
    const getBreadcrumbs = () => {
        return (
            <Breadcrumb>
                {props.selectedBuilding.id ? <BreadcrumbItem active>{props.selectedBuilding.name}</BreadcrumbItem> : ''}
                {props.selectedFloor.id ? <BreadcrumbItem active>{props.selectedFloor.name}</BreadcrumbItem> : ''}
                {props.selectedLocation.id ? <BreadcrumbItem active>{props.selectedLocation.name}</BreadcrumbItem> : ''}
            </Breadcrumb>
        );
    };

    return (
        <div className={isMobile ? 'location-table-modal-mobile' : undefined}>
            <Modal
                className={isMobile ? 'location-table-modal-mobile' : undefined}
                show={props.show}
                onHide={handleClose}
            >
                <Modal.Header style={{ borderBottom: 'none' }}>
                    <Modal.Title>{props.title}</Modal.Title>
                    {isMobile && (
                        <Button onClick={handleClose}>
                            <FontAwesomeIcon icon={['far', 'times']} size="lg" />
                        </Button>
                    )}
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <FormGenerator onMount={setForm} fieldConfig={fieldConfig} />
                    </form>
                </Modal.Body>
                <Modal.Footer style={{ borderTop: 'none' }}>
                    <ButtonGroup isMobile={isMobile}>
                        <Button
                            onClick={handleClose}
                            style={{
                                backgroundColor: '#fff',
                                borderColor: '#ccc',
                                color: '#000'
                            }}
                        >
                            {props.t('common:cancel')}
                        </Button>
                        <Button
                            onClick={handleSubmit}
                            style={{
                                backgroundColor: '#FD9A24',
                                borderColor: '#C97B1D'
                            }}
                        >
                            {props.t('common:save')}
                        </Button>
                    </ButtonGroup>
                </Modal.Footer>
            </Modal>
        </div>
    );
};
export default ManageLocationForm;
