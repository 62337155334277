/*
 * Search Facility Modal
 * responsible for searching for a facility
 */

import { connect } from 'react-redux';

import { Ifacility } from '../../models';
import SearchFacilityForm from './SearchFacilityForm';
import { toggleSearchFacilityModal } from '../../actions/facilitiesActions';
import {
    emptySearchedFacilities,
    searchFacilities,
    setDefaultFacilityID,
    toggleAddFacilityModal,
    toggleEditJobModal
} from '../../actions/manageJobActions';
import { withTranslation, WithTranslation } from 'react-i18next';
import { WithCommonModal } from '../common/WithCommonModal';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';
import { constants } from '../../constants/constants';
interface Iprops {
    colorButton: any;
    modalClass?: string;
    selectedFacility?: Ifacility;
    secondModal?: boolean;
    disabled?: boolean;
}

const defaultProps = {
    className: 'search-facility'
};

const mapStateToProps = (state: IinitialState, ownProps: Iprops & WithTranslation) => {
    const title = ownProps.t('facility:searchFacility');
    const canAddFacility = constants.hasSecurityFunction(state.user, constants.securityFunctions.FSECustomer.id);
    return {
        ...defaultProps,
        user: state.user,
        loading: selectIsLoading(state),
        show: state.manageJob.showSearchFacilityModal,
        searchedFacilities: state.manageJob.searchedFacilities,
        title,
        canAddFacility
    };
};

export default withTranslation('facility')(
    connect(mapStateToProps, {
        toggleModal: toggleSearchFacilityModal,
        onHide: toggleSearchFacilityModal,
        searchFacilities,
        toggleAddFacilityModal,
        toggleEditJobModal,
        setDefaultFacilityID,
        emptySearchedFacilities
    })(WithCommonModal(SearchFacilityForm))
);
