import { find, reduce } from 'lodash';
import { createSelector } from 'reselect';
import {
    IshoppingCartItem,
    IquotePartPopulated,
    IshoppingCartGroupedItem,
    IquotePartCart,
    IshoppingCartWithGroupedItems,
    IshoppingCart,
    IinstallBase,
    Iproduct
} from '../../models';
import { jobStatusEnum } from '../../models-enums';
import { IinitialState } from '../../reducers';
import { getSelectedJob } from '../../reducers/commonSelectors';
import { initialQuotePartCart, initialPart } from '../../reducers/initialState';
import { TableUtil } from '../common/TableUtil';

interface IproductWithParts extends Iproduct {
    installBaseID: string;
    parts: {
        [key: string]: IshoppingCartItem;
    };
}

export const shoppingCartItemsToQuoteParts = (
    cartItems: IshoppingCartItem[],
    originalQuoteParts: IquotePartPopulated[]
): IquotePartPopulated[] => {
    return originalQuoteParts.map(quotePart => {
        const foundCartItem = find(cartItems, { id: quotePart.id });

        return {
            ...quotePart,
            quantity: foundCartItem?.quantity || quotePart.quantity,
            bundle: foundCartItem?.bundle || quotePart.bundle,
            isDeleted: quotePart.isDeleted
        };
    });
};

/**
 *
 * @param quoteParts
 * @returns quoteParts converted to shoppingCartItems grouped by product then grouped by installBase.
 * This sets things up for displaying them in the cart
 */
export const quotePartsToCartItems = (quoteParts: IquotePartPopulated[]): { [key: string]: IshoppingCartItem } => {
    return quoteParts.reduce((cartItems: { [key: string]: IshoppingCartItem }, quotePart): {
        [key: string]: IshoppingCartItem;
    } => {
        let name = `Part: ${quotePart.part.number} - ${quotePart.part.description}`;

        return {
            ...cartItems,
            [quotePart.id]: {
                id: quotePart.id,
                quantity: typeof quotePart.quantity === 'string' ? parseInt(quotePart.quantity) : quotePart.quantity,
                cost: 0,
                name,
                bundle: quotePart.bundle || 1
            }
        };
    }, {});
};

export const quotePartCartToShoppingCart = (quotePartCart: IquotePartCart): IshoppingCart => {
    const existingCartItems = quotePartsToCartItems(quotePartCart.quoteItems);
    return {
        addedIDs: Object.keys(existingCartItems),
        message: quotePartCart.message,
        itemsByID: existingCartItems
        // quantity: existingCartItems.quantity
    };
};

// const getSelectedCart = (state: IinitialState) => state.selectedCart;
const getQuoteCartsByID = (state: IinitialState) => state.parts.quoteCartsByID;
const getParts = (state: IinitialState) => state.parts.partsByID;
const getInstallBases = (state: IinitialState) => state.manageInventory.installBasesByID;
const getProducts = (state: IinitialState) => state.manageInventory.productsByID;
const getFacilities = (state: IinitialState) => state.facilities.facilitiesByID;
/**
 * get the quoteCart for a job and populate the part object
 */
export const selectQuoteCartForJob = createSelector(
    [getQuoteCartsByID, getSelectedJob, getParts, getInstallBases, getProducts],
    (carts, selectedJob, parts, installBases, products): IquotePartCart => {
        // If Job is completed, no cart should be shown
        if (selectedJob.status === jobStatusEnum.completed) {
            return initialQuotePartCart;
        }

        const cart = find(carts, { isDeleted: false, jobID: selectedJob.id }) || initialQuotePartCart;
        /*
         *   SB - 11/2/21
         *   Removed isDeleted check for now, for some reason Labor Parts are being marked as Deleted so this
         *   is filtering them out from the cart, can add back in once this issue is better understood
         */
        const quoteItemsWithPopulatedParts = reduce(
            cart.quoteItems,
            (collector: IquotePartPopulated[], item): IquotePartPopulated[] => {
                const part = parts[item.partID] || initialPart;

                if (item.isDeleted === false) {
                    return [...collector, { ...item, part }];
                }

                return collector;
            },
            []
        );

        return { ...cart, quoteItems: quoteItemsWithPopulatedParts };
    }
);

/**
 * @returns quoteParts converted to shoppingCartItems grouped by product then grouped by installBase.
 * This sets things up for displaying them in the cart
 */
export const selectCartWithGroupedQuoteParts = createSelector(
    [selectQuoteCartForJob, getInstallBases, getProducts, getFacilities],
    (selectedQuoteCart, installBases, allProducts, facilities): IshoppingCartWithGroupedItems => {
        const existingCartItems = quotePartsToCartItems(selectedQuoteCart.quoteItems);
        const uniqueInstallBases = selectedQuoteCart.quoteItems.reduce((installs: IinstallBase[], quoteItem) => {
            if (
                quoteItem.isDeleted === false &&
                quoteItem.installBaseID &&
                typeof find(installs, { id: quoteItem.installBaseID }) === 'undefined'
            ) {
                const install = installBases[quoteItem.installBaseID];
                return [...installs, install];
            }
            return installs;
        }, []);
        const laborItemParts: IquotePartPopulated[] = selectedQuoteCart.quoteItems.filter(
            x => x.installBaseID === undefined || x.installBaseID === null
        );
        const productsWithParts: IproductWithParts[] = uniqueInstallBases.map(install => {
            const product = allProducts[install.productID];
            const productParts = selectedQuoteCart.quoteItems.filter(item => item.installBaseID === install.id);
            const productPartsWithPopulatedParts = quotePartsToCartItems(productParts);

            return {
                installBaseID: install.id,
                ...product,
                parts: productPartsWithPopulatedParts
            };
        });
        let laborItems: IshoppingCartItem[] = [];
        let groupedItems: IshoppingCartGroupedItem[] = [];

        laborItemParts.forEach((laborItem: IquotePartPopulated) => {
            const cartItem = {
                name: `${laborItem.part.number} - ${laborItem.part.description}`,
                id: laborItem.id,
                quantity: laborItem.quantity,
                bundle: laborItem.bundle
            };
            laborItems.push(cartItem);
        });
        productsWithParts.forEach(product => {
            const installBase = uniqueInstallBases.find(uiBase => uiBase.id === product.installBaseID);
            const locationString = installBase
                ? TableUtil.buildLocation(installBase, facilities[installBase.facilityID])
                : '';

            if (installBase !== undefined) {
                const quoteItems = selectedQuoteCart.quoteItems.filter(item => item.installBaseID === installBase.id);
                const cartItems: IshoppingCartItem[] = quoteItems.map((tempQuoteItem: IquotePartPopulated) => ({
                    name: `${tempQuoteItem.part.number} - ${tempQuoteItem.part.description}`,
                    id: tempQuoteItem.id,
                    quantity: tempQuoteItem.quantity,
                    bundle: tempQuoteItem.bundle
                }));

                groupedItems.push({
                    name: product.name,
                    subItems: [
                        {
                            name:
                                locationString || installBase.serialNumber
                                    ? `${locationString} - ${installBase.serialNumber}`
                                    : 'no location or serial',
                            subSubItems: cartItems
                        }
                    ]
                });
            }
        });

        return {
            addedIDs: Object.keys(existingCartItems),
            message: selectedQuoteCart.message,
            itemsByID: existingCartItems,
            groupedItems,
            laborItems
        };
    }
);
