import * as React from 'react';
import { useSelector } from 'react-redux';

import { constants } from '../../constants/constants';
import { getIsMobile } from '../../reducers/configReducer';

type Iprops = {
    title: string;
    img: any;
    subtitle?: string;
    subsubtitle?: string;
    color: string;
};

export const Banner: React.FunctionComponent<Iprops> = ({ title, img, color, subtitle, subsubtitle, children }) => {
    const isMobile = useSelector(getIsMobile);
    const colorIndex = `${color}Banner`;

    if (isMobile) {
        return (
            <div
                className="banner purple soso"
                style={{
                    backgroundColor: color,
                    background: `linear-gradient(${constants.colors[colorIndex as keyof typeof constants.colors]},
          ${constants.colors[colorIndex as keyof typeof constants.colors]})`
                }}
            >
                <span className="title">{title}</span>
                {subtitle && <span className="subtitle">{subtitle}</span>}
                {subsubtitle && (
                    <span style={{ marginLeft: '20px' }} className="subtitle">
                        {subsubtitle}
                    </span>
                )}
                {children}
            </div>
        );
    }

    return (
        <div
            className="banner purple soso"
            style={{
                backgroundColor: color,
                background: `linear-gradient(${constants.colors[colorIndex as keyof typeof constants.colors]},
      ${constants.colors[colorIndex as keyof typeof constants.colors]}), url(${img}) no-repeat left/cover`
            }}
        >
            <span className="title">{title}</span>
            {subtitle && <span className="subtitle">{subtitle}</span>}
            {subsubtitle && (
                <span style={{ marginLeft: '20px' }} className="subtitle">
                    {subsubtitle}
                </span>
            )}
            {children}
        </div>
    );
};
