import { combineReducers } from 'redux';
import { IinitialState } from '.';
import * as types from '../actions/actionTypes';
import initialState from './initialState';
import { IsapJobMapping } from '../models';
import { keyBy } from 'lodash';

export const selectSAPJobMappingsByID = (state: IinitialState) => state.sapJobMappingReducer.sapJobMappingsByID;

function sapJobMappingsByID(
    state: { [key: string]: IsapJobMapping } | any = ({} = initialState.sapJobMappings.sapJobMappingsByID),
    action: any
): { [key: string]: IsapJobMapping } {
    switch (action.type) {
        case types.SET_SAP_JOB_MAPPING:
            return keyBy(action.data, 'id');
        default:
            return state;
    }
}

const sapJobMappingReducer = combineReducers({
    sapJobMappingsByID
});

export default sapJobMappingReducer;
