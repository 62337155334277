export default class WebWorker extends Worker {
	constructor(worker: any) {
	  const code = worker.toString();
	  const blob = new Blob(['(' + code + ')()'], { type: 'application/javascript' });
  
	  // Handle potential errors when creating the URL
	  let url: string;
	  try {
		url = URL.createObjectURL(blob);
	  } catch (error) {
		console.error('Failed to create Blob URL:', error);
		throw new URIError('Failed to create Blob URL for the worker');
	  }
  
	  super(url);
  
	  // Revoke the Blob URL after the worker is initialized to avoid memory leaks
	  URL.revokeObjectURL(url);
  
	  return this;
	}
}