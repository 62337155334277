/*
 * BannerContainer connects the Banner to redux for Measurement Points
 */

import * as React from 'react';

import { Ifacility, IinstallBasePopulated, IinstallBase, Iproduct } from '../../models';
import { initialFacility, initialInstallBasePopulated, initialProduct } from '../../reducers/initialState';

import { Banner } from './Banner';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LinkContainer } from 'react-router-bootstrap';
import { RouteComponentProps } from 'react-router';
import { TableUtil } from './TableUtil';
import { connect } from 'react-redux';
import { constants } from '../../constants/constants';
import { IinitialState } from '../../reducers';
import { clearSelectedHistoricalWorkOrderID } from '../../actions/workOrderActions';
import { clearHistoricalMPResult, clearHistoricalResultID } from '../../actions/measurementPointResultsActions';

interface Iprops extends RouteComponentProps<{ installID: string }> {
    installBasesNotTested: IinstallBasePopulated[];
}

interface IdispatchProps {
    selectedInstall: IinstallBase;
    facility: Ifacility;
    historicalResultID: string;
    selectedProduct: Iproduct;
    clearHistoricalMPResult: typeof clearHistoricalMPResult;
    clearSelectedHistoricalWorkOrderID: typeof clearSelectedHistoricalWorkOrderID;
    clearHistoricalResultID: typeof clearHistoricalResultID;
}
interface Istate {
    nextInstallID: string;
    prevInstallID: string;
}

class BannerContainerClass extends React.Component<IdispatchProps & Iprops, Istate> {
    constructor(props: IdispatchProps & Iprops) {
        super(props);
        this.state = {
            nextInstallID: '',
            prevInstallID: ''
        };
    }
    componentDidMount() {
        this.setInstallIDs();
    }
    clearHistoricalResult = () => {
        this.props.clearHistoricalResultID();
        this.props.clearSelectedHistoricalWorkOrderID();
        this.props.clearHistoricalMPResult();
    };
    setInstallIDs = () => {
        let nextInstallID = '';
        let prevInstallID = '';
        const { installBasesNotTested } = this.props;
        const { installID } = this.props.match.params;
        const currentIndex = installBasesNotTested.findIndex(install => install.id === installID);
        if (currentIndex + 1 < installBasesNotTested.length) {
            nextInstallID = installBasesNotTested[currentIndex + 1].id;
        }

        if (currentIndex > 0) {
            prevInstallID = installBasesNotTested[currentIndex - 1].id;
        }
        this.setState({ nextInstallID, prevInstallID });
    };

    componentDidUpdate(prevProps: Iprops) {
        if (prevProps.match.params.installID !== this.props.match.params.installID) {
            this.setInstallIDs();
        }
    }
    render() {
        if (!this.props.selectedInstall) {
            return null;
        }

        return (
            <div className="device-detail-banner-container banner purple soso">
                <LinkContainer
                    to={`/devices/${this.state.prevInstallID}`}
                    style={{
                        color: constants.colors.orange,
                        padding: '4px 16px'
                    }}
                    type="button"
                    onClick={this.clearHistoricalResult}
                >
                    <Button
                        bsStyle="link"
                        bsSize="sm"
                        disabled={this.state.prevInstallID.length === 0 || this.props.historicalResultID.length > 0}
                    >
                        <FontAwesomeIcon size="lg" icon="chevron-left" style={{ height: '3em' }} />
                    </Button>
                </LinkContainer>

                <Banner
                    title={this.props.selectedProduct.name}
                    subtitle={TableUtil.buildLocation(this.props.selectedInstall, this.props.facility)}
                    img=""
                    color=""
                />
                <LinkContainer
                    to={`/devices/${this.state.nextInstallID}`}
                    style={{
                        color: constants.colors.orange,
                        padding: '4px 16px'
                    }}
                    type="button"
                    onClick={this.clearHistoricalResult}
                >
                    <Button
                        bsStyle="link"
                        bsSize="sm"
                        disabled={this.state.nextInstallID.length === 0 || this.props.historicalResultID.length > 0}
                    >
                        <FontAwesomeIcon size="lg" icon="chevron-right" style={{ height: '3em' }} />
                    </Button>
                </LinkContainer>
            </div>
        );
    }
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops) => {
    const selectedInstall =
        state.manageInventory.installBasesByID[ownProps.match.params.installID] || initialInstallBasePopulated;
    const selectedProduct = state.manageInventory.productsByID[selectedInstall.productID] || initialProduct;

    return {
        selectedInstall,
        facility: state.facilities.facilitiesByID[state.manageJob.selectedJob.facilityID] || initialFacility,
        historicalResultID: state.measurementPointResults.historicalResultID,
        selectedProduct
    };
};

export const BannerContainer = connect(mapStateToProps, {
    clearSelectedHistoricalWorkOrderID,
    clearHistoricalResultID,
    clearHistoricalMPResult
})(BannerContainerClass);
