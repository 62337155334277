/*
 * Signature Modal
 */
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import VerificationSignaturePad from './VerificationSignaturePad';
import { WithCommonModal } from '../common/WithCommonModal';
import { selectIsLoading } from '../../reducers/commonReducers';
import { toggleModalVerificationSignaturePad } from '../../actions/manageJobCommentsActions';
import { IdefaultReport, IinstallBasePopulated, Ijob } from '../../models';
import { IinitialState } from '../../reducers';
import { updateVerificationSummaryFormValue } from '../../actions/workOrderActions';
import { setJobCoverLetter } from '../../actions/manageJobActions';
import { jobSourceEnum, reportTypeEnum } from '../../models-enums';
import { submitJobSignature, toggleIsJobClosingWithSignature } from '../../actions/workOrderActions';

interface Iprops {
    colorButton: string;
    installBasesPopulated: IinstallBasePopulated[];
    selectedJob: Ijob;
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops & WithTranslation) => {
    const job = state.manageJob.jobsByID[ownProps.selectedJob.id];
    const validateParts = job.source === jobSourceEnum.BPCS;
    const selectedJobSignatures = Object.values(state.manageJob.jobSignaturesByID).filter(
        signature => signature.jobID === job.id
    );
    const { isJobClosingWithSignature } = state.workOrder;
    const verificationReport = Object.values(state.manageJob.defaultReportsByID).find(
        (report: IdefaultReport) => report.reportType === reportTypeEnum.verification
    ) as IdefaultReport;

    return {
        className: 'signature-modal',
        show: state.manageJobComment.showVerificationSignatureModal,
        verificationReport,
        title: ownProps.t('signaturePadModalTitle'),
        user: state.user,
        selectedJob: job,
        selectedJobSignatures,
        isJobClosingWithSignature,
        validateParts,
        installBasesPopulated: ownProps.installBasesPopulated,
        loading: selectIsLoading(state)
    };
};

export default withTranslation('manageJobComments')(
    connect(mapStateToProps, {
        submitSignature: submitJobSignature,
        setJobCoverLetter,
        toggleModal: toggleModalVerificationSignaturePad,
        toggleIsJobClosingWithSignature,
        onHide: toggleModalVerificationSignaturePad,
        updateFormValue: updateVerificationSummaryFormValue
    })(WithCommonModal(VerificationSignaturePad))
);
