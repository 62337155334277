import * as types from '../actions/actionTypes';

import { ImeasurementPointAnswer, IsimpleMeasurementPointResult } from '../models';
import { initialMeasurementPointResultAnswer, initialSimpleMeasurementPointResult } from './initialState';
import { keyBy, pickBy, filter, find, flatten } from 'lodash';
import moment from 'moment';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import { IinitialState } from '.';
import { getSelectedJobID } from './commonSelectors';

const cleanResult = (result: IsimpleMeasurementPointResult): IsimpleMeasurementPointResult => {
    let cleanedAnswers: ImeasurementPointAnswer[] = [];
    if (result.measurementPointAnswers && result.measurementPointAnswers.length) {
        cleanedAnswers = result.measurementPointAnswers.map(answer => {
            return {
                ...initialMeasurementPointResultAnswer,
                ...pickBy(answer, (property, key) => property !== null)
            };
        });
    }
    return {
        ...initialSimpleMeasurementPointResult,
        ...pickBy(result, (property, key) => property !== null),
        measurementPointAnswers: cleanedAnswers
    };
};
/*
 * SELECTORS
 */

export const selectMostRecentTemporarySimpleResult = (
    state: { [key: string]: IsimpleMeasurementPointResult },
    measurementPointListID: string,
    jobID: string,
    temporary: boolean
) => {
    const filteredResults = filter(state, {
        measurementPointListID,
        jobID,
        temporary
    });
    if (filteredResults.length === 0) {
        return initialSimpleMeasurementPointResult;
    }
    return filteredResults.reduce((previous, current) => {
        if (moment.utc(previous.updateDate).isAfter(moment.utc(current.updateDate))) {
            return previous;
        } else {
            return current;
        }
    }, initialSimpleMeasurementPointResult);
};

/*
 * selectMostRecentResult
 * find the most recent result for this job, measurement type, and MPL
 */
export const selectMostRecentSimpleResult = (
    state: { [key: string]: IsimpleMeasurementPointResult },
    jobID: string,
    MPListID: string
) => {
    const filteredResults = filter(state, {
        jobID,
        measurementPointListID: MPListID
    });
    if (filteredResults.length === 0) {
        return initialSimpleMeasurementPointResult;
    }
    return filteredResults.reduce((previous, current) => {
        if (moment.utc(previous.updateDate).isAfter(moment.utc(current.updateDate))) {
            return previous;
        } else {
            return current;
        }
    }, initialSimpleMeasurementPointResult);
};

export const selectSimpleMeasurementPointListResultsByJobID = (state: IinitialState, jobID: string) => {
    return filter(
        state.simpleMeasurementPointResults.simpleMeasurementPointResultsByID,
        result => result.jobID === jobID && result.isDeleted === false && result.temporary !== true
    );
};

const selectJobIDFromProps = (state: IinitialState, props: { jobID: string }) => props.jobID;
const selectSimpleMeasurementPointResults = (state: IinitialState) =>
    state.simpleMeasurementPointResults.simpleMeasurementPointResultsByID;

/*
 * select the temporary result for this job
 * we can assume there is only one if there is any because if it finds one, another temporary result will not be created
 */
export const selectTemporaryResultByJobID = createSelector(
    [selectSimpleMeasurementPointResults, selectJobIDFromProps],
    (simpleMeasurementPointResultsByID, jobID) => {
        return find(simpleMeasurementPointResultsByID, {
            jobID,
            temporary: true
        });
    }
);

const selectMPListIDProp = (state: IinitialState, props: { MPListID: string }) => props.MPListID;

const selectSMPLRbyID = (state: IinitialState) => state.simpleMeasurementPointResults.simpleMeasurementPointResultsByID;

export const selectSMPLRsForJobAndMPL = createSelector(
    [selectSMPLRbyID, getSelectedJobID, selectMPListIDProp],
    (simpleMeasurementPointResultsByID, jobID, MPListID) => {
        return filter(simpleMeasurementPointResultsByID, {
            jobID,
            measurementPointListID: MPListID,
            isDeleted: false
        });
    }
);

/*
 * REDUCERS
 */

const simpleMeasurementPointResultsByIDReducer = (
    state: { [key: string]: IsimpleMeasurementPointResult } = {},
    action: any
): { [key: string]: IsimpleMeasurementPointResult } => {
    switch (action.type) {
        case types.ADD_SIMPLE_MEASUREMENT_POINT_RESULT:
            return { ...state, [action.result.id]: cleanResult(action.result) };
        case types.UPDATE_SIMPLE_MEASUREMENT_POINT_RESULT:
            return { ...state, [action.result.id]: cleanResult(action.result) };
        case types.GET_SIMPLE_MEASUREMENT_POINT_JOB_RESULTS_SUCCESS: {
            const filteredResults: IsimpleMeasurementPointResult[] = flatten(
                action.results.filter((res: any) => res?.result?.length !== 0).map((res: any) => res?.result)
            );

            const newResults = keyBy(
                filteredResults.map((result: IsimpleMeasurementPointResult) => cleanResult(result)),
                'id'
            );
            return { ...state, ...newResults };
        }
        case types.USER_LOGOUT_SUCCESS:
            return {};
        default:
            return state;
    }
};

const selectedSimpleResult = (
    state: IsimpleMeasurementPointResult = initialSimpleMeasurementPointResult,
    action: any
): IsimpleMeasurementPointResult => {
    switch (action.type) {
        case types.ADD_SIMPLE_MEASUREMENT_POINT_RESULT:
            return action.result;
        case types.UPDATE_SIMPLE_MEASUREMENT_POINT_RESULT:
            if (state.id === action.result.id) {
                return { ...state, ...cleanResult(action.result) };
            } else {
                return cleanResult(action.result);
            }
        case types.GET_SIMPLE_MEASUREMENT_POINT_JOB_RESULTS_SUCCESS: {
            const newResults = keyBy(
                action.results.map((res: IsimpleMeasurementPointResult) => cleanResult(res)),
                'id'
            );
            return { ...state, ...newResults };
        }
        case types.RESET_SIMPLE_MEASUREMENT_POINT_RESULT:
            return initialSimpleMeasurementPointResult;
        case types.USER_LOGOUT_SUCCESS:
            return initialSimpleMeasurementPointResult;
        default:
            return state;
    }
};

const simpleMeasurementPointResultsReducer = combineReducers({
    simpleMeasurementPointResultsByID: simpleMeasurementPointResultsByIDReducer,
    selectedSimpleResult
});

export default simpleMeasurementPointResultsReducer;
