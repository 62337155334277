/*
 * Inventory Action Button Container connets the Inventory Action Button to redux
 */

import { TFunction } from 'i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { IinitialState } from '../../reducers';
import { selectIsLoading } from '../../reducers/commonReducers';
import { JobCommentActionButton } from './JobCommentActionButton';

interface Iprops extends RouteComponentProps<any> {
    t: TFunction;
    colorButton: string;
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops) => {
    return {
        loading: selectIsLoading(state)
    };
};

export const JobCommentActionButtonContainer = connect(mapStateToProps, {})(JobCommentActionButton);
