/*
 * Search Inventory Table Form
 * searching inventory has some special dropdowns for locations
 */

import * as React from 'react';

import { AbstractControl, FieldConfig, FormGenerator, GroupProps } from 'react-reactive-form';
import { Button, Col, Row } from 'react-bootstrap';
import { Ijob, Ioption, IproductInfo, ItableFiltersReducer } from '../../models';
import { forEach } from 'lodash';
import { FormUtil } from '../common/FormUtil';
import { RouteComponentProps } from 'react-router';
import { TFunction } from 'i18next';
import { setTableFilter } from '../../actions/manageLocationActions';
import { setSelectedCart } from '../../actions/partsActions';
import { setFilterConfig } from '../../actions/manageInventoryActions';
import { useSelector } from 'react-redux';
import { getSAPWorkOrders } from '../../reducers/workOrderReducer';
import { jobTypeOptions } from '../../constants/constants';
import { isMoment } from 'moment';

interface Iprops extends RouteComponentProps<{}> {
    handleSubmit: any;
    loading: boolean;
    showSearchButton?: boolean;
    colorButton: string;
    t: TFunction;
    setTableFilter: typeof setTableFilter;
    tableFilters: ItableFiltersReducer;
    facilities: string[];
    productInfo: IproductInfo;
    startAddWorkOrders: () => void;
    clearTableFilter: () => void;
    setFilterConfig: typeof setFilterConfig;
    isWorkOrderTypeJob: boolean;
    isAddWorkOrderMode: boolean;
    isJobDownloaded: boolean;
    selectedJob: Ijob;
    selection: string[];
    resetSelection: () => void;
    setSelectedCart: typeof setSelectedCart;
    isUnassignedWorkOrder: boolean;
    multipleFacilitiesSelected: boolean;
    addButtonText: string;
}

const SearchInventoryTableForm = (props: Iprops) => {
    const { multipleFacilitiesSelected, facilities, t, isUnassignedWorkOrder } = props;
    const [searchForm, setSearchForm] = React.useState<AbstractControl | null>(null);
    const [workOrdersSameSAPType, setWorkOrdersSameSAPType] = React.useState<boolean>(false);
    const sapWorkOrders = useSelector(getSAPWorkOrders);

    const searchControls: FieldConfig = React.useMemo(() => {
        const searchControl = {
            search: {
                render: FormUtil.TextInputWithoutValidation,
                meta: {
                    label: 'search',
                    colWidth: 3,
                    type: 'input',
                    placeholder: 'tableFilters.searchPlaceholder',
                    name: 'asset-search'
                },
                formState: { value: '', disabled: false }
            }
        } as { [key: string]: GroupProps };

        const facilityControl = {
            facility: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    options: facilities.map((facility: string) => ({ label: facility, value: facility })),
                    label: 'facility',
                    colWidth: 3,
                    isMulti: false,
                    isClearable: true,
                    name: 'facility'
                },
                formState: {
                    value: '',
                    disabled: false
                }
            }
        } as { [key: string]: GroupProps };

        let translatedJobTypeOptions = jobTypeOptions.map((option: Ioption) => ({
            ...option,
            label: t(`nsJob:` + option.label)
        }));

        const additionalControls = {
            jobType: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    options: translatedJobTypeOptions,
                    label: 'jobType',
                    colWidth: 3,
                    colWidthLarge: 2,
                    isMulti: false,
                    isClearable: true,
                    name: 'jobType'
                },
                formState: {
                    value: '',
                    disabled: false
                }
            },
            startDate: {
                render: FormUtil.Datetime,
                meta: {
                    label: 'nsJob:dateRange',
                    colWidth: 3,
                    colWidthLarge: 2,
                    defaultValue: '', // Datetime does not support formState
                    showTime: false,
                    isClearable: true,
                    placeholder: 'DD-MMM-YY'
                },
                options: {
                    validators: [FormUtil.validators.isValidMoment]
                }
            },
            endDate: {
                render: FormUtil.Datetime,
                meta: {
                    label: 'nsJob:dateRangeEnd',
                    colWidth: 3,
                    colWidthLarge: 2,
                    defaultValue: '',
                    showTime: false,
                    isClearable: true,
                    placeholder: 'DD-MMM-YY'
                },
                options: {
                    validators: [FormUtil.validators.isValidMoment]
                }
            }
        };

        if (isUnassignedWorkOrder) {
            return FormUtil.translateForm(
                { controls: { ...searchControl, ...facilityControl, ...additionalControls } },
                t
            );
        } else {
            return FormUtil.translateForm({ controls: { ...searchControl, ...additionalControls } }, t);
        }
    }, [facilities.length]);

    React.useEffect(() => {
        const params = new URLSearchParams(props.location.search);
        if (params && params.get('switch_job')) {
            props.clearTableFilter();
            props.history.replace('/devices');
            return;
        }

        props.setFilterConfig(FormUtil.translateForm(searchControls, props.t));

        return () => {
            props.setTableFilter({ page: 0, search: '' });
        };
    }, []);

    const canAddSelectedWorkOrders = () => {
        if (props.selection.length === 0) {
            return false;
        }

        if (multipleFacilitiesSelected) {
            return false;
        }

        if (!workOrdersSameSAPType) {
            return false;
        }

        return true;
    };

    React.useEffect(() => {
        const { selection } = props;
        if (selection.length === 0) {
            setWorkOrdersSameSAPType(false);
        } else if (selection.length === 1) {
            setWorkOrdersSameSAPType(true);
        } else {
            // You cannot add multiple work orders to a job if they have different suggested job types
            const allEqual = (arr: any[]) => arr.every(val => val === arr[0]);
            const wos = selection.map(item => sapWorkOrders[item.split('select-')[1]]?.suggestedJobTypeID);

            setWorkOrdersSameSAPType(allEqual(wos));
        }
    }, [props.selection]);

    /*
     * (reusable)
     * set the table filters to redux on each value change
     */
    const onValueChanges = (value: any, key: string) => {
        switch (key) {
            case 'startDate':
            case 'endDate':
                props.setTableFilter({ [key]: value && isMoment(value) ? value.toISOString() : '' });
                break;
            default:
                props.setTableFilter({ [key]: value ? value : '', page: 0 });
                break;
        }
    };

    const subscribeToValueChanges = (form: AbstractControl) => {
        forEach(searchControls.controls, (_: any, key) => {
            form.get(key).valueChanges.subscribe((value: any) => {
                onValueChanges(value, key);
            });
        });

        setSearchForm(form);
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        props.handleSubmit(searchForm);
    };

    const setForm = (form: AbstractControl) => {
        subscribeToValueChanges(form);
        forEach(searchControls.controls, (_: any, key) => {
            onValueChanges(form.get(key).value, key);
        });
    };

    return (
        <Row className="search-table-form beacon-form" style={{}}>
            <form onSubmit={handleSubmit}>
                <Col xs={props.isAddWorkOrderMode ? 12 : 9} lg={10} style={{ paddingLeft: '0px' }}>
                    <FormGenerator onMount={setForm} fieldConfig={searchControls} />
                </Col>
                {props.isAddWorkOrderMode && (
                    <Col xs={3} style={{ marginTop: '5px' }}>
                        <Button
                            onClick={props.startAddWorkOrders}
                            disabled={canAddSelectedWorkOrders() === false}
                            title={props.selection.length > 0 ? 'add' : 'must select at least one'}
                        >
                            {props.addButtonText}
                        </Button>
                    </Col>
                )}
                {props.isAddWorkOrderMode && (
                    <Col xs={2} style={{ marginTop: '5px' }}>
                        <Button
                            onClick={props.resetSelection}
                            disabled={props.selection.length === 0}
                            title={props.selection.length > 0 ? 'reset' : 'must select at least one'}
                        >
                            {props.t('resetSelectionButton')}
                        </Button>
                    </Col>
                )}
            </form>
        </Row>
    );
};

export default SearchInventoryTableForm;
