/*
 * Part Table Container
 */

import { connect } from 'react-redux';
import { IinitialState } from '../../reducers';
import { PartTable } from './PartTable';
import { withTranslation, WithTranslation } from 'react-i18next';
import { constants } from '../../constants/constants';
import { updateWorkOrder } from '../../actions/workOrderActions';

import { selectIsLoading } from '../../reducers/commonReducers';
import {
    clearSelectedInstallBasePartID,
    deleteInstallBasePart,
    setSelectedInstallBasePartID
} from '../../actions/partsActions';
import { selectAllInstallBasePartsForInstallBaseAndJob } from '../../reducers/partsReducer';

interface Iprops {
    colorButton?: string;
    installID: string;
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops & WithTranslation) => {
    const { selectedInstallBasePartID } = state.parts;
    const tableData = selectAllInstallBasePartsForInstallBaseAndJob(state, {
        installBaseID: ownProps.installID
    });
    const isUS = state.user.countryID === constants.usCountryID;
    const { historicalWorkOrderID } = state.workOrder;
    const { historicalResultID } = state.measurementPointResults;
    const disabled = historicalWorkOrderID.length > 0 || historicalResultID.length > 0;

    return {
        loading: selectIsLoading(state),
        tableData,
        isUS,
        currentTile: constants.getTileByURL(window.location.pathname),
        selectedInstallBasePartID,
        disabled,
        selectedJob: state.manageJob.selectedJob
    };
};

export const PartTableContainer = withTranslation('manageWorkOrder')(
    connect(mapStateToProps, {
        deleteInstallBasePart,
        updateWorkOrder,
        setSelectedInstallBasePartID,
        clearSelectedInstallBasePartID
    })(PartTable)
);
