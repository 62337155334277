/*
 * Header simply displays the header on all pages
 */

import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import HeaderMenu from './HeaderMenu';
import { selectInventoryModals } from '../../reducers/manageInventoryReducer';
import { selectJobModals } from '../../reducers/manageJobReducer';
import { selectLocationPathname } from '../../reducers/locationReducer';
import { getIsMobile } from '../../reducers/configReducer';
import { selectJobCommentsModals } from '../../reducers/manageJobCommentsReducer';
import { selectLocationModals } from '../../reducers/manageLocationReducer';
import { selectWOModals } from '../../reducers/workOrderReducer';
import { clearSelectedJob } from '../../actions/workOrderActions';
import { selectOutbox } from '../../reducers/commonReducers';
import { selectIsSyncRunning } from '../../reducers/syncManagerReducer';
import { setSyncStatus } from '../../actions/commonActions';

const headerImg = require('../../images/Beacon_MMG_Logo_120px.svg');

const Header = (props: any) => {
    const inventoryModals = useSelector(selectInventoryModals);
    const isMobile = useSelector(getIsMobile);
    const jobCommentsModals = useSelector(selectJobCommentsModals);
    const jobModals = useSelector(selectJobModals);
    const locationModals = useSelector(selectLocationModals);
    const pathname = useSelector(selectLocationPathname);
    const woModals = useSelector(selectWOModals);
    const headerWrap = useRef<HTMLDivElement>(null);
    const prevPathname = useRef<string>(pathname);
    const dispatch = useDispatch();
    const getOutBox = useSelector(selectOutbox);
    const getIsSyncRunning = useSelector(selectIsSyncRunning);

    useEffect(() => {
        const shouldScroll = [
            !inventoryModals,
            !jobCommentsModals,
            !jobModals,
            !locationModals,
            !woModals,
            pathname !== prevPathname.current
        ].some(item => item);

        if (isMobile && shouldScroll) {
            headerWrap?.current?.scrollIntoView();
        }
    }, [inventoryModals, jobCommentsModals, jobModals, locationModals, woModals, isMobile, pathname]);

    const linkClicked = () => {
        dispatch(clearSelectedJob());
    };

    useEffect(() => {
        if (getOutBox.length === 0 && getIsSyncRunning === true) {
            dispatch(setSyncStatus(false));
        }
    }, [getOutBox, getIsSyncRunning]);

    return (
        <div ref={headerWrap} className="header">
            <Link to={'/jobs'} onClick={linkClicked}>
                <img src={headerImg} alt="logo" />
            </Link>
            <HeaderMenu {...props} />
        </div>
    );
};

export default Header;
