import { keyBy, map, forEach, pickBy, find, omit, filter } from 'lodash';

import {
    ImeasurementPointList,
    ImeasurementPoint,
    ImeasurementPointListTab,
    IjobDefault,
    IinstallBase
} from '../models';

import { initialMeasurementPointList, initialMeasurementPoint } from './initialState';
import * as types from '../actions/actionTypes';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import { IinitialState } from '.';
import { getInstallFormValues, selectMainCategoryFromProductID } from './manageInventoryReducer';
import { MPLTypebyJobTypesID, jobTypesIdEnumInverse, measurementPointListTypeEnum } from '../models-enums';
import { constants, mainCategories } from '../constants/constants';
import { toastr } from 'react-redux-toastr';
import { getSelectedJobID } from './commonSelectors';

/*
 * PRIVATE HELPER FUNCTIONS
 */
const cleanMeasurementPoints = (points: any): ImeasurementPoint[] => {
    return map(points, (point: any) => {
        return {
            ...initialMeasurementPoint,
            ...pickBy(point, property => property !== null)
        };
    });
};

const cleanMeasurementPointList = (list: any): ImeasurementPointList => {
    const cleanedList = {
        ...initialMeasurementPointList,
        ...pickBy(list, property => property !== null)
    };

    const tabsWithoutMeasurementPoints = map(cleanedList.measurementPointTabs, tab => {
        return omit(tab, 'measurementPoints');
    });
    return {
        ...cleanedList,
        measurementPointTabs: tabsWithoutMeasurementPoints
    };
};

/*
 * SELECTORS
 */
const selectStandardByID = (state: IinitialState, props: { standardID: string }) =>
    state.productInfo.standards[props.standardID];
const selectMeasurementPointLists = (state: IinitialState) => state.measurementPointLists.measurementPointListsByID;

const getSelectedJob = (state: IinitialState) => state.manageJob.selectedJob;
const getShouldShowWarning = (state: IinitialState, props: { shouldShowWarning: boolean }) => props.shouldShowWarning;
const selectMeasurementPointsByID = (state: IinitialState) => state.measurementPointLists.measurementPointsByID;
const selectTabIDProp = (state: IinitialState, props: { tabID: string }) => props.tabID;
const getMeasurementPointProp = (state: IinitialState, props: { measurementPoint: ImeasurementPoint }) =>
    props.measurementPoint;

const getJobDefaults = (state: IinitialState) => state.measurementPointLists.jobDefaultsByID;
const getMeasurementPointResultsByID = (state: IinitialState) =>
    state.measurementPointResults.measurementPointResultsByID;
const getInstallBases = (state: IinitialState) => state.manageInventory.installBasesByID;
const getProducts = (state: IinitialState) => state.manageInventory.productsByID;
const location = (state: IinitialState) => state.location.pathname;

export const selectMeasurementPointResultsIDs = createSelector(
    [getMeasurementPointResultsByID],
    measurementPointResultsByID => Object.keys(measurementPointResultsByID)
);

export const selectJobDefaultsForJob = createSelector(
    [getSelectedJobID, getJobDefaults],
    (selectedJobID, jobDefaultsByID) => {
        // Potential optimization. jobDefaultsByID could be have a composite key of jobID and ID.
        return filter(jobDefaultsByID, jobDefault => {
            return jobDefault.jobID === selectedJobID;
        });
    }
);

export const selectMeasurementPointsForTabID = createSelector(
    [selectMeasurementPointsByID, selectTabIDProp],
    (measurementPointsByID, tabID) => {
        return keyBy(filter(measurementPointsByID, { measurementPointTabID: tabID }), 'id');
    }
);

export const selectJobDefaultForMeasurementPoint = createSelector(
    [
        getSelectedJobID,
        getJobDefaults,
        getMeasurementPointProp,
        selectMeasurementPointLists,
        getMeasurementPointResultsByID,
        getInstallBases,
        getProducts,
        location
    ],
    (
        jobID,
        jobDefaults,
        measurementPoint,
        measurementPointListsByID,
        measurementPointResultsByID,
        installBases,
        products,
        pathName
    ) => {
        let productTypeID: string | null = null;
        let listId: string | null = null;
        let installBaseId: string | null = null;
        let installBase: IinstallBase | null = null;

        // Look for measurementPointListID by tabID
        Object.values(measurementPointListsByID).forEach(list => {
            const mpList: any = list.measurementPointTabs.find(x => x.id === measurementPoint.measurementPointTabID);

            if (mpList) {
                listId = mpList.measurementPointListID;

                return;
            }
        });

        if (listId !== null) {
            // If a measurementPointList was found, now let's find a measurementPointResult with a maching measurementPointListID
            Object.values(measurementPointResultsByID).forEach(result => {
                if (result.measurementPointListID === listId) {
                    installBaseId = result.installBaseID;
                    return;
                }
            });

            // INFO: This is selector is called BEFORE measurementPointResults is updated and is therefore not pulling the current installBaseId when viewing a device.
            // Checking if the install base ID from the URL matches, if not, default to what's in the URL as that always seems to be correct.
            const devicesIndex = pathName.indexOf('/devices/');
            if (devicesIndex !== -1) {
                const deviceId = pathName.substring(devicesIndex + '/devices/'.length);
                if (installBaseId !== deviceId) {
                    installBaseId = deviceId;
                }
            }

            // If an installBase was found, now we can look up the product, and the glorious productTypeID that started this search
            if (installBaseId !== null) {
                installBase = installBases[installBaseId];

                if (installBase && installBase.productID) {
                    const p = products[installBase.productID];

                    if (p) {
                        productTypeID = p.productTypeID;
                    }
                }
            }
        }
        // Without filtering by productTypeID, the default answer with just pick the 1st one from the jobDefaults, which is wrong
        if (productTypeID !== null) {
            return find(
                jobDefaults,
                item =>
                    item.jobID === jobID &&
                    item.mpType === measurementPoint.type &&
                    item.mpDefaultType === measurementPoint.jobDefaultType &&
                    item.productTypeID.toLowerCase() === productTypeID?.toLowerCase()
            );
        } else {
            return find(
                jobDefaults,
                item =>
                    item.jobID === jobID &&
                    item.mpType === measurementPoint.type &&
                    item.mpDefaultType === measurementPoint.jobDefaultType
            );
        }
    }
);

// TODO this can be improved by checking to make sure the type is a valid type and then we could return as measurementPointListTypeEnum
const getMeasurementPointListTypeProp = (state: IinitialState, props: { measurementPointListType: string | number }) =>
    props.measurementPointListType ? parseInt(props.measurementPointListType.toString()) : null;

interface Query {
    type?: number;
    standardID: string;
    isDeleted: boolean;
    forJob?: boolean;
}

export const selectSimpleMeasurementPointList = createSelector(
    [
        selectMeasurementPointLists,
        selectStandardByID,
        getSelectedJob,
        getShouldShowWarning,
        getMeasurementPointListTypeProp
    ],
    (measurementPointListsByID, standard, selectedJob, shouldShowWarning, measurementPointListType) => {
        const query: Query = {
            standardID: standard.id,
            isDeleted: false
        };

        if (measurementPointListType !== null) {
            query.type = measurementPointListType;
        }

        let forJob = true;

        // These two types are from the existing flow (these will be removed at some point)
        if (
            measurementPointListType === measurementPointListTypeEnum.purity ||
            measurementPointListType === measurementPointListTypeEnum.verificationChecklist
        ) {
            forJob = false;
        }

        // Going the path of readability over "coolness", long day, brain hurts
        let foundList: ImeasurementPointList | undefined = undefined;
        // If this a job level MPL then filter the main category to the Job category
        if (forJob) {
            foundList = find(measurementPointListsByID, (item: ImeasurementPointList) => {
                return (
                    item.standardID === query.standardID &&
                    item.isDeleted === query.isDeleted &&
                    query.type === item.type &&
                    item.mainCategoryID === mainCategories.job
                );
            });
        } else {
            // This should only be for the purity and verification checklist (old way)
            foundList = find(measurementPointListsByID, (item: ImeasurementPointList) => {
                return (
                    item.standardID === query.standardID &&
                    item.isDeleted === query.isDeleted &&
                    query.type === item.type
                );
            });
        }
        if (shouldShowWarning && foundList === undefined) {
            toastr.error(
                'Error',
                `There are no measurement points defined for ${
                    measurementPointListType ? `this list type: ${measurementPointListType}, ` : ''
                }job type: ${
                    jobTypesIdEnumInverse[selectedJob.jobTypeID as keyof typeof jobTypesIdEnumInverse]
                }, and standard: ${standard.name}.`,
                constants.toastrError
            );
        }
        // TODO populate the measurement points on each tab here rather than in the pointlistForm.tsx

        return foundList || initialMeasurementPointList;
    }
);

export const selectMeasurementPointListForInstallBase = createSelector(
    [
        selectMainCategoryFromProductID,
        selectMeasurementPointLists,
        selectStandardByID,
        getSelectedJob,
        getShouldShowWarning
    ],
    (category, measurementPointListsByID, standard, selectedJob, shouldShowWarning) => {
        const MPLType = MPLTypebyJobTypesID[selectedJob.jobTypeID];
        // if you believe you shouldn't be seeing this, please check Meassurements on the web app
        const foundList = find(measurementPointListsByID, {
            type: MPLType,
            mainCategoryID: category?.id,
            standardID: standard?.id,
            isDeleted: false
        });

        if (shouldShowWarning && foundList === undefined) {
            toastr.error(
                'Error',
                `There are no measurement points defined for this category: ${category?.name}, job type: ${
                    jobTypesIdEnumInverse[selectedJob.jobTypeID as keyof typeof jobTypesIdEnumInverse]
                }, and standard: ${standard?.name} `,
                constants.toastrError
            );
        }
        // TODO populate the measurement points on each tab here rather than in the pointlistForm.tsx

        return foundList || initialMeasurementPointList;
    }
);

/*
 * REDUCERS
 */

/*
 * data - keyed by the MPLID
 */
function measurementPointListsReducer(
    state: { [key: string]: ImeasurementPointList } = {},
    action: any
): { [key: string]: ImeasurementPointList } {
    switch (action.type) {
        case types.MANAGE_MEASUREMENT_POINT_LISTS_SUCCESS: {
            if (!action.allLists || (action.allLists && !action.allLists.length)) {
                console.error('[measurementPointListsReducer]: missing measurement point lists');
                return state;
            }
            const measurementPointLists = action.allLists.map((measurementPointList: ImeasurementPointList) => {
                return cleanMeasurementPointList(measurementPointList);
            });
            return keyBy(measurementPointLists, 'id');
        }

        case types.MANAGE_MEASUREMENT_POINT_LIST_UPDATE: {
            if (action.measurementPointList) {
                return {
                    ...state,
                    [action.measurementPointList.id]: {
                        ...cleanMeasurementPointList(action.measurementPointList)
                    }
                };
            } else {
                return state;
            }
        }

        case types.USER_LOGOUT_SUCCESS:
            return {};
        default:
            return state;
    }
}
const measurementPointsByID = (
    state: { [key: string]: ImeasurementPoint } = {},
    action: any
): { [key: string]: ImeasurementPoint } => {
    switch (action.type) {
        case types.MANAGE_MEASUREMENT_POINT_LISTS_SUCCESS: {
            const measurmentPointLists = action.allLists;
            let measurementPoints: { [key: string]: ImeasurementPoint } = {};
            forEach(measurmentPointLists, (list: ImeasurementPointList) => {
                forEach(list.measurementPointTabs, (tab: ImeasurementPointListTab) => {
                    measurementPoints = {
                        ...measurementPoints,
                        ...keyBy(cleanMeasurementPoints(tab.measurementPoints), 'id')
                    };
                });
            });
            return measurementPoints;
        }
        case types.MANAGE_MEASUREMENT_POINT_LIST_UPDATE: {
            let mPoints: ImeasurementPoint[] = [];
            forEach(action.measurementPointList.measurementPointTabs, mTab => {
                mPoints = [...mPoints, ...cleanMeasurementPoints(mTab.measurementPoints)];
            });
            return { ...state, ...keyBy(mPoints, 'id') };
        }
        case types.USER_LOGOUT_SUCCESS:
            return {};
        default:
            return state;
    }
};

/*
 * data - keyed by the MPLID
 */
function jobDefaultsReducer(state: { [key: string]: IjobDefault } = {}, action: any): { [key: string]: IjobDefault } {
    switch (action.type) {
        case types.SAVE_JOB_DEFAULTS:
            return { ...state, ...keyBy(action.payload, 'id') };
        default:
            return state;
    }
}

const manageMeasurementPointLists = combineReducers({
    measurementPointListsByID: measurementPointListsReducer,
    jobDefaultsByID: jobDefaultsReducer,
    measurementPointsByID
});

export default manageMeasurementPointLists;
