import * as React from 'react';

import { WorkOrderContainer } from './WorkOrderContainer';
import { IWorkOrder } from '../../models';
import { MaintenanceChecklist } from './MaintenanceChecklist';
import { Row, Col } from 'react-bootstrap';
import { getWorkOrderTitle } from '../../reducers/workOrderReducer';
import { TFunction } from 'i18next';
import { NotApplicableButtonRowContainer } from './NotApplicableButtonRowContainer';
import { getIsMobile } from '../../reducers/configReducer';
import { useSelector } from 'react-redux';

interface Iprops {
    showChecklist: boolean;
    selectedWorkOrder?: IWorkOrder;
    t: TFunction;
}

export const WorkOrderLayout = ({ showChecklist, selectedWorkOrder, t }: Iprops) => {
    const isMobile = useSelector(getIsMobile);
    const padding = isMobile ? '0px' : '20px';

    if (!selectedWorkOrder) {
        return null;
    }

    if (!showChecklist) {
        return (
            <div style={{ paddingTop: padding }}>
                <WorkOrderContainer selectedWorkOrder={selectedWorkOrder} />
            </div>
        );
    }

    return (
        <div style={{ paddingTop: '20px' }} className="work-order-layout">
            <Row>
                <Col xs={isMobile ? 12 : 6}>
                    <h3>{getWorkOrderTitle(selectedWorkOrder, t)}</h3>
                </Col>
                <Col xs={isMobile ? 12 : 6}>
                    <NotApplicableButtonRowContainer selectedWorkOrder={selectedWorkOrder} />
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <MaintenanceChecklist selectedWorkOrder={selectedWorkOrder} />
                </Col>
            </Row>
            <hr />
            <Row>
                <Col xs={12}>
                    <WorkOrderContainer selectedWorkOrder={selectedWorkOrder} />
                </Col>
            </Row>
        </div>
    );
};
