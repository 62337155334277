import { TFunction } from 'i18next';
import { filter, forEach } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, Row, Tab, Tabs } from 'react-bootstrap';
import { AbstractControl, FieldConfig, FormGenerator, GroupProps, ValidatorFn, Validators } from 'react-reactive-form';
import { toastr } from 'react-redux-toastr';
import { Redirect, RouteComponentProps } from 'react-router';
import { setSelectedMeasurementPointListByID } from '../../../actions/measurementPointListActions';
import {
    initSelectedSimpleResult,
    resetSelectedSimpleResult,
    submitSimpleMeasurementPointResult,
    updateSMprAnswers
} from '../../../actions/simpleMeasurementPointResultsActions';
import { toggleConfirmSelectJobModal } from '../../../actions/workOrderActions';
import {
    Ijob,
    ImeasurementPoint,
    ImeasurementPointAnswer,
    ImeasurementPointList,
    ImeasurementPointListTab,
    IsimpleMeasurementPointResult
} from '../../../models';
import {
    jobStatusEnum,
    measurementPointAnswerTypes,
    measurementPointListTypeEnum,
    measurementPointResultStatusTypesEnum,
    measurementPointTypesEnum
} from '../../../models-enums';

import { FormUtil } from '../../common/FormUtil';
import { FormUtilMeasurementPoints } from '../../common/FormUtilMobile';

import { LinkContainer } from 'react-router-bootstrap';
import { constants } from '../../../constants/constants';
import { getAllFormValues } from '../MeasurementPointListFormUtils';
import { simpleQueryParamsEnum } from './SimpleMeasurementPointListContainer';

const GroupHeader = ({ label }: { label: string }) => (
    <Col xs={12} className="mp-row">
        <Row>
            <Col xs={12}>
                <h4 className="mp-header">{label}</h4>
            </Col>
        </Row>
    </Col>
);

interface IFromState {
    forms: { [index: string]: AbstractControl };
    activeFormIndex?: number;
}

interface ILongTextCheckboxState {
    checkboxes: { [index: string]: boolean };
}

interface GroupPropsEdited extends GroupProps {
    controls?: GroupProps;
}

interface Iprops extends RouteComponentProps {
    queryParams: typeof simpleQueryParamsEnum;
    loading: boolean;
    t: TFunction;
    submitSimpleMeasurementPointResult: typeof submitSimpleMeasurementPointResult;
    selectedJob: Ijob;
    measurementPointsByID: { [key: string]: ImeasurementPoint };
    resetSelectedSimpleResult: typeof resetSelectedSimpleResult;
    selectedSimpleResult: IsimpleMeasurementPointResult;
    allMeasurementPointLists: { [key: string]: ImeasurementPointList };
    initSelectedSimpleResult: typeof initSelectedSimpleResult;
    setSelectedMeasurementPointListByID: typeof setSelectedMeasurementPointListByID;
    selectedMeasurementPointList: ImeasurementPointList;
    toggleConfirmSelectJobModal: typeof toggleConfirmSelectJobModal;
    updateSMprAnswers: typeof updateSMprAnswers;
    isMobile: boolean;
}

const SimpleMeasurementPointListForm: React.FC<Iprops> = props => {
    const [tabKey, setTabKey] = useState<number>(1);
    const [statusOverride, setStatusOverride] = useState<number>(0);
    const {
        isMobile,
        t,
        measurementPointsByID,
        updateSMprAnswers,
        loading,
        selectedSimpleResult,
        selectedMeasurementPointList,
        submitSimpleMeasurementPointResult,
        history,
        initSelectedSimpleResult,
        queryParams,
        selectedJob,
        resetSelectedSimpleResult
    } = props;
    const subscription = React.useRef<any>(null);

    const filterTabs = (tabs: ImeasurementPointListTab[]) => {
        return filter(tabs, tab => tab.isDeleted !== true);
    };

    const filteredTabs = useMemo(() => filterTabs(selectedMeasurementPointList.measurementPointTabs), [
        selectedMeasurementPointList.measurementPointTabs
    ]);

    const [forms, setForms] = React.useState<IFromState>({
        forms: {}
    });

    const [longTextCheckboxes, setLongTextCheckboxes] = React.useState<ILongTextCheckboxState>({
        checkboxes: {}
    });

    const handleValueChange = (index: number, point: ImeasurementPoint, value: string | null) => {
        if (!value) {
            return;
        }
        const { formValues } = getAllFormValues(forms.forms, true, (tabKey: any) => {});
        const { answers } = buildMeasurementPointAnswers({ ...formValues, [point.id]: value });

        updateSMprAnswers(answers);
    };

    const subscribeToValueChanges = (forms: any, index: number) => {
        if (filteredTabs.length === 0) {
            toastr.error(
                t('error'),
                'Unexpected error loading the form. Please try again or contact support.',
                constants.toastrError
            );
            return;
        }
        const tab = filteredTabs[index];
        if (!tab) {
            return;
        }

        const filteredMeasurementPoints = filterMeasurementPoints(tab.id);
        const mpsWithoutGroups = filteredMeasurementPoints.filter(mp => mp.type !== measurementPointTypesEnum.group);
        if (mpsWithoutGroups.length === 0) {
            return;
        }
        mpsWithoutGroups.forEach(point => {
            const formControl = forms.forms[index].get(point.id);
            if (!formControl) {
                console.error(
                    '[subscribeToValueChanges]: missing form control for this measurement point',
                    point,
                    index
                );
                return;
            }
            formControl.valueChanges.subscribe((value: any) => {
                if (value !== null) {
                    handleValueChange(index, point, value);
                }
            });
        });
    };

    const setForm = (form: AbstractControl, index: number) => {
        if (form === null) {
            console.warn('form is null', form, index);
            return;
        }

        forms.forms[index] = form;
        forms.forms[index].meta = {
            loading
        };

        setForms(forms);
        subscribeToValueChanges(forms, index);
    };

    const handleSelect = (tabKey: any) => {
        setTabKey(tabKey);
    };

    /*
     * filter out deleted points as well as any customer measurementPoints that do not belong to the current customer
     */
    const filterMeasurementPoints = (tabID: string) => {
        // ruturns all elements that are truthy
        // Not deleted, and if has customerID it matches the job customerID
        return filter(
            measurementPointsByID,
            (measurementPoint: ImeasurementPoint) =>
                measurementPoint.measurementPointTabID === tabID &&
                measurementPoint.isDeleted !== true &&
                measurementPoint.type in measurementPointTypesEnum
        );
    };

    const formatSummaryText = (text: string) => {
        // Wrap each "variable" in a span with a style of blue text
        const defaultText = text.replace(/\{\{([^}]+)\}\}/g, (match: any, value: any) => {
            const placeholderSpan = `<span style="color:rgb(41, 105, 176)">${match}</span>`;
            const regex = new RegExp(`<span[^>]*?style="color:rgb(41, 105, 176)">${match}</span>`, 'g');

            // Check if the placeholder already has the specified style applied
            if (!text.match(regex)) {
                return placeholderSpan;
            } else {
                return match; // Placeholder already has the style, return original content
            }
        });

        return defaultText;
    };

    const buildMeasurementPointAnswers = (formValues: {
        [key: string]: any;
    }): {
        answers: ImeasurementPointAnswer[];
        resultStatus: number;
        validationError: string;
    } => {
        let answers: ImeasurementPointAnswer[] = [];
        let resultStatus = measurementPointResultStatusTypesEnum.resultStatusPass;
        let validationError = '';

        forEach(formValues, (value: any, key: string) => {
            const measurementPoint = measurementPointsByID[key];

            if (
                value === undefined ||
                !measurementPoint ||
                (measurementPoint.type >= measurementPointTypesEnum.group &&
                    measurementPoint.type < measurementPointTypesEnum.date)
            ) {
                return;
            }

            // check for moment and convert it to a string
            if (moment.isMoment(value)) {
                value = value.toISOString();
            }
            const answerType = measurementPointAnswerTypes[measurementPoint.type];
            const rawValue = value !== null && typeof value === 'object' ? value.value : value;
            // start by defaulting to true
            let defaultShowInReport = true;
            let answer: ImeasurementPointAnswer = {
                measurementPointID: '',
                showInReport: defaultShowInReport
            };
            // check to see if the next value is a note that belongs with this answer
            // if the value is undefined, or it can not find a note it will also be undefined
            // TODO throw validation error if there is a note without a answer to go along with it
            if (formValues[`${key}_note`] !== undefined) {
                answer = {
                    measurementPointID: key,
                    [answerType]: rawValue,
                    notes: formValues[`${key}_note`],
                    showInReport: defaultShowInReport
                };
            } else {
                answer = {
                    measurementPointID: key,
                    [answerType]: rawValue,
                    showInReport: defaultShowInReport
                };
            }

            // Verify that the select option belongs to the measurement point
            if (
                measurementPoint.type === measurementPointTypesEnum.select &&
                rawValue &&
                (!measurementPoint.selectOptions ||
                    (measurementPoint.selectOptions &&
                        measurementPoint.selectOptions.find(item => item.id === rawValue) === undefined))
            ) {
                console.error(
                    '[buildMeasurementPointAnswers]: selection option value incorrect',
                    measurementPoint,
                    rawValue
                );
                validationError = 'Selection option value is incorrect.';
            }

            if (measurementPoint.type === measurementPointTypesEnum.longText && rawValue) {
                // Was the checkbox changed for this Long text answer?
                if (Object.keys(longTextCheckboxes.checkboxes).includes(key)) {
                    defaultShowInReport = longTextCheckboxes.checkboxes[key];
                } else {
                    // Checkbox wasn't changed, we need to find the previously saved value for showInReport for this answer, don't assume it's True
                    const longTextAnswer = props.selectedSimpleResult.measurementPointAnswers.filter(
                        x => x.measurementPointID === key
                    );
                    if (longTextAnswer.length > 0) {
                        defaultShowInReport = longTextAnswer[0].showInReport;
                    }
                }

                // For Long Text we need to save the actual answer in the textValue field, which is the 2nd item in measurementPointAnswerTypes enum
                answer = {
                    measurementPointID: key,
                    [measurementPointAnswerTypes[2]]: rawValue,
                    showInReport: defaultShowInReport
                };
            }

            if (measurementPoint.type === measurementPointTypesEnum.summaryPage && rawValue) {
                // For Long Text we need to save the actual answer in the textValue field, which is the 2nd item in measurementPointAnswerTypes enum
                answer = {
                    measurementPointID: key,
                    [measurementPointAnswerTypes[2]]: formatSummaryText(rawValue),
                    showInReport: defaultShowInReport
                };
            }

            answers = [...answers, answer];
        });
        return { answers, resultStatus, validationError };
    };

    const handleSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
        if (e) {
            e.preventDefault();
        }

        const { formStatus, formValues } = getAllFormValues(forms.forms, true, (tabKey: any) => {
            const index = Number.parseInt(tabKey) + 1;
            setTabKey(index);
        });
        const shouldSkipValidation =
            statusOverride === measurementPointResultStatusTypesEnum.resultStatusCannotComplete;
        if (formStatus === 'INVALID' && shouldSkipValidation === false) {
            toastr.error('Please check invalid inputs', '', constants.toastrError);
            return;
        }

        const { answers, resultStatus, validationError } = buildMeasurementPointAnswers(formValues);

        if (validationError.length) {
            toastr.warning('Warning', validationError, constants.toastrError);
            return;
        }

        const status = statusOverride ? statusOverride : resultStatus;

        const result = {
            ...selectedSimpleResult,
            measurementPointListID: selectedMeasurementPointList.id,
            status
        };

        submitSimpleMeasurementPointResult(result, answers);
        setStatusOverride(0);

        history.push('/devices');
    };

    useEffect(() => {
        initSelectedSimpleResult(queryParams.type);

        return () => {
            if (subscription.current) {
                subscription.current.unsubscribe();
            }
            resetSelectedSimpleResult();
        };
    }, []);

    useEffect(() => {
        if (!selectedMeasurementPointList.id.length) {
            console.error('[useEffect]: device is missing the measurement point list');
            history.push('/devices');
            return;
        }
    }, [selectedMeasurementPointList.id, selectedSimpleResult.id]);

    useEffect(() => {
        if (selectedJob.status === jobStatusEnum.completed) {
            handleCompletingJob();
        }
    }, [selectedJob.status]);

    const showInReportHandler = (id: string) => {
        return (event: React.ChangeEvent<HTMLInputElement>) => {
            if (id !== '') {
                if (longTextCheckboxes.checkboxes[id] === undefined) {
                    const answer = props.selectedSimpleResult.measurementPointAnswers.filter(
                        x => x.measurementPointID === id
                    )[0];
                    let showInReport = true;
                    if (answer && answer.measurementPointID !== '') {
                        showInReport = answer.showInReport;
                    }

                    const newCheckbox = {
                        [id]: !showInReport
                    };

                    setLongTextCheckboxes({ checkboxes: { ...longTextCheckboxes.checkboxes, ...newCheckbox } });
                } else {
                    const newCheckbox = {
                        [id]: !longTextCheckboxes.checkboxes[id]
                    };

                    setLongTextCheckboxes({ checkboxes: { ...longTextCheckboxes.checkboxes, ...newCheckbox } });
                }
            }
        };
    };

    const formControls: { [key: string]: FieldConfig } = useMemo(() => {
        const formControls: { [key: string]: FieldConfig } = {};
        const disabled = false;
        let newControl: Record<string, any> = {};
        const { measurementPointAnswers } = selectedSimpleResult;

        filteredTabs.forEach((tab: ImeasurementPointListTab, index) => {
            const filteredMeasurementPoints = filterMeasurementPoints(tab.id);
            let tabControls = {};
            filteredMeasurementPoints.forEach((measurementPoint: ImeasurementPoint) => {
                // determin the default value.  Might be a value set by the MeasurementPoint or by the most recent result.
                const {
                    allowNotes,
                    guideText,
                    selectOptions,
                    selectDefaultOptionID,
                    id,
                    passFailDefault,
                    label,
                    isRequired,
                    numericMinValue,
                    numericMaxValue,
                    type,
                    defaultText
                } = measurementPoint;
                let defaultValue = null;
                let noteDefaultValue = null;
                let defaultShowInReport = true;
                const convertedOptions =
                    selectOptions && selectOptions.length ? FormUtil.convertToOptions(selectOptions) : [];
                if (type === measurementPointTypesEnum.passFail && !!passFailDefault) {
                    defaultValue = passFailDefault;
                }
                if (type === measurementPointTypesEnum.select && !!selectDefaultOptionID && convertedOptions.length) {
                    defaultValue = convertedOptions.find(option => option.value === selectDefaultOptionID);
                }

                // Set the Default Long Text value from the MP (which is configred in Web)
                if (type === measurementPointTypesEnum.longText) {
                    defaultValue = defaultText === undefined ? '' : defaultText;
                }

                if (type === measurementPointTypesEnum.summaryPage) {
                    defaultValue = defaultText === undefined ? '' : defaultText;
                }

                if (measurementPointAnswers) {
                    const foundAnswer = measurementPointAnswers.find(answer => answer.measurementPointID === id);
                    if (type === measurementPointTypesEnum.numeric && foundAnswer?.numericValue !== undefined) {
                        defaultValue = foundAnswer.numericValue;
                    }
                    if (type === measurementPointTypesEnum.passFail && foundAnswer?.pass !== undefined) {
                        defaultValue = foundAnswer.pass;
                    }
                    if (type === measurementPointTypesEnum.text && foundAnswer?.textValue !== undefined) {
                        defaultValue = foundAnswer.textValue;
                    }

                    if (type === measurementPointAnswerTypes.longText && foundAnswer?.textValue !== undefined) {
                        defaultValue = foundAnswer.textValue;
                    }

                    if (type === measurementPointTypesEnum.date && foundAnswer?.dateValue !== undefined) {
                        defaultValue = foundAnswer.dateValue;
                    }

                    if (
                        type === measurementPointTypesEnum.select &&
                        foundAnswer?.measurementPointSelectOptionID !== undefined
                    ) {
                        defaultValue = convertedOptions.find(
                            option => option.value === foundAnswer.measurementPointSelectOptionID
                        );
                    }

                    if (type === measurementPointTypesEnum.summaryPage && foundAnswer?.textValue !== undefined) {
                        defaultValue = foundAnswer.textValue;
                    }

                    // Handle showInReport for long text fields
                    if (foundAnswer && type === measurementPointTypesEnum.longText) {
                        if (
                            Object.keys(longTextCheckboxes.checkboxes).includes(foundAnswer.measurementPointID) ===
                            false
                        ) {
                            defaultShowInReport = foundAnswer.showInReport;
                        } else {
                            defaultShowInReport = longTextCheckboxes.checkboxes[foundAnswer.measurementPointID];
                        }
                    } else if (type === measurementPointTypesEnum.longText) {
                        // If no answers have been saved, check if any checkboxes have been toggled for long text fields
                        if (Object.keys(longTextCheckboxes.checkboxes).includes(id) === true) {
                            defaultShowInReport = longTextCheckboxes.checkboxes[id];
                        }
                    }

                    // Handle long text answer, since we have to rerender the form to update the long text checkbox
                    if (type === measurementPointTypesEnum.longText && foundAnswer !== undefined) {
                        let useFormValue = false;

                        // Has the checkbox been changed for this Long text answer?
                        if (Object.keys(longTextCheckboxes.checkboxes).includes(id) === true) {
                            useFormValue = true;
                        }

                        // If the check box has been changed, then we know we are not loading the page for the first time, user action has taken place
                        // so use the form value
                        if (useFormValue) {
                            const index = tabKey - 1;
                            const stateAnswer = forms.forms[index];

                            if (stateAnswer !== undefined) {
                                const stateAnswerIndex = Object.keys(stateAnswer.value).indexOf(id);

                                if (stateAnswerIndex !== -1) {
                                    const stateAnswerValue = Object.values(stateAnswer.value)[stateAnswerIndex];

                                    if (stateAnswerValue !== undefined) {
                                        defaultValue = stateAnswerValue;
                                    }
                                }
                            }
                        }
                    }

                    if (measurementPoint.allowNotes !== undefined) {
                        noteDefaultValue = foundAnswer?.notes;
                    }
                }
                let columnWidths = {
                    mainCol: 7,
                    labelCol: 3,
                    measurementPointCol: 7,
                    guideCol: 0,
                    noteCol: 0
                };
                if (allowNotes && !guideText) {
                    columnWidths = {
                        ...columnWidths,
                        noteCol: 2,
                        mainCol: 10
                    };
                } else if (allowNotes && guideText) {
                    columnWidths = {
                        ...columnWidths,
                        guideCol: 2,
                        measurementPointCol: 10,
                        noteCol: 2,
                        mainCol: 10
                    };
                } else if (!allowNotes && guideText) {
                    columnWidths = {
                        ...columnWidths,
                        guideCol: 3,
                        measurementPointCol: 9,
                        labelCol: 3
                    };
                } else if (measurementPoint.type === measurementPointTypesEnum.group) {
                    columnWidths = {
                        ...columnWidths,
                        mainCol: 12
                    };
                }

                // build the Header or an actual form control
                if (measurementPoint.type === measurementPointTypesEnum.group) {
                    newControl = {
                        [`$field_${id}`]: {
                            isStatic: false, // ensures a key is added
                            render: () => <GroupHeader label={label} key={id} />
                        } as GroupPropsEdited
                    };
                } else {
                    let validators: ValidatorFn[] = [];
                    if (measurementPoint.type === measurementPointTypesEnum.date) {
                        validators = [FormUtil.validators.isValidMoment];
                    }
                    if (isRequired) {
                        validators = [...validators, FormUtil.validators.requiredWithTrim];
                    }
                    if (
                        measurementPoint.type === measurementPointTypesEnum.summaryPage ||
                        measurementPoint.type === measurementPointTypesEnum.longText
                    ) {
                        validators = [...validators, FormUtil.validators.maxLength(1000)];
                    }
                    if (measurementPoint.type === measurementPointTypesEnum.numeric) {
                        if (numericMaxValue) {
                            validators = [...validators, Validators.max(numericMaxValue)];
                        }
                        if (numericMinValue) {
                            validators = [...validators, Validators.min(numericMinValue)];
                        }
                    }

                    newControl = {
                        [id]: {
                            render: FormUtilMeasurementPoints[
                                measurementPointTypesEnum[
                                    measurementPoint.type
                                ] as keyof typeof FormUtilMeasurementPoints
                            ](isMobile),
                            meta: {
                                charLimit: 1000,
                                label,
                                colWidth: 6,
                                columnWidths,
                                name: label,
                                options: convertedOptions,
                                id,
                                passFailDefault,
                                guideText: measurementPoint.guideText,
                                helpText: measurementPoint.helpText,
                                isClearable: true,
                                showNormalToolbar: false,
                                initialContent: defaultValue,
                                showInReport: defaultShowInReport, // this is for MP answer, not the MP itself
                                showInReportHandler: showInReportHandler, // only used for long text
                                overrideRTEText: true
                            },
                            options: {
                                validators
                            },
                            formState: { value: defaultValue, disabled }
                        } as GroupPropsEdited
                    };

                    if (
                        measurementPoint.allowNotes &&
                        (props.selectedMeasurementPointList.type === measurementPointListTypeEnum.purity ||
                            props.selectedMeasurementPointList.type ===
                                measurementPointListTypeEnum.verificationChecklist)
                    ) {
                        newControl = {
                            ...newControl,
                            [id]: {
                                ...newControl[id],
                                meta: {
                                    ...newControl[id].meta,
                                    colWidth: 10
                                }
                            }
                        };
                        newControl = {
                            ...newControl,

                            [`${id}_note`]: {
                                render: FormUtilMeasurementPoints.NewMeasurementPointNote,
                                meta: {
                                    name: `${id}_note`,
                                    colWidth: 2,
                                    label: `${label} note`,
                                    t
                                },
                                formState: {
                                    value: noteDefaultValue,
                                    disabled
                                }
                            }
                        };
                    }
                }

                tabControls = { ...tabControls, ...newControl } as {
                    [key: string]: GroupPropsEdited;
                };
            });
            const tabConfig = { controls: tabControls } as FieldConfig;
            formControls[index] = tabConfig;
        });

        return formControls;
    }, [
        selectedMeasurementPointList.id.length,
        selectedSimpleResult.id.length,
        selectedJob.id.length,
        longTextCheckboxes.checkboxes
    ]);

    const handleCompletingJob = () => {
        toastr.success('Success', 'This job has been completed.', constants.toastrSuccess);
        history.push('/jobs');
    };

    if (!selectedMeasurementPointList.id.length) {
        return (
            <Redirect
                to={{
                    pathname: '/devices'
                }}
            />
        );
    }
    // return home if their is no job or the job has been completed or if there is no installBase
    if (!selectedJob.id.length || selectedJob.status === jobStatusEnum.completed) {
        return (
            <Redirect
                to={{
                    pathname: '/'
                }}
            />
        );
    }

    const tabClassName = filteredTabs.length === 1 ? 'hide-single-tab' : 'more than one';
    return (
        <div>
            <form onSubmit={handleSubmit} className="beacon-form">
                <Row className="mp-button-row">
                    <Col xs={12}>
                        <LinkContainer to="/devices">
                            <Button bsStyle="link" type="button">
                                {t('close')}
                            </Button>
                        </LinkContainer>
                        <Button bsStyle="link" type="submit" className="pull-right" disabled={loading}>
                            {t('saveLog')}
                        </Button>
                    </Col>
                </Row>
                <Row style={{ height: 'calc(100vh - 130px)' }} className="measurement-point-list-form">
                    <Tabs
                        activeKey={tabKey}
                        onSelect={handleSelect}
                        id="controlled-tab-example"
                        className={tabClassName}
                        animation={false}
                    >
                        {filteredTabs.map((tab, index) => {
                            return (
                                <Tab
                                    eventKey={index + 1}
                                    title={tab.name}
                                    key={tab.id}
                                    className="measurement-point-list"
                                    data-tab={index + 1}
                                >
                                    <FormGenerator
                                        onMount={form => setForm(form, index)}
                                        fieldConfig={formControls[index]}
                                    />
                                </Tab>
                            );
                        })}
                    </Tabs>
                </Row>
            </form>
        </div>
    );
};

export default SimpleMeasurementPointListForm;
