/*
 * Manage Location Modal
 */

import { IinitialState } from '../../reducers';

import { initialBuilding, initialFloor, initialLoc } from '../../reducers/initialState';
import { saveAnyLocation, updateAnyLocation, toggleEditLocationModal } from '../../actions/manageLocationActions';

import EditLocationForm from './EditLocationForm';
import { connect } from 'react-redux';
import { selectIsLoading } from '../../reducers/commonReducers';
import { WithTranslation, withTranslation } from 'react-i18next';

interface Iprops {
    colorButton: string;
    selectedItem: any;
    selectedType: 'Building' | 'Floor' | 'Location' | 'Room';
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops & WithTranslation) => {
    let modalTitle;
    if (ownProps.selectedItem && ownProps.selectedItem.id) {
        modalTitle = ownProps.t(`edit${ownProps.selectedType}`);
    } else {
        modalTitle = ownProps.t(`new${ownProps.selectedType}`);
    }
    const facility = state.facilities.facilitiesByID[state.manageJob.selectedJob.facilityID];
    const selectedBuilding =
        facility.buildings.find(building => building.id === state.manageLocation.tableFilters.buildingID) ||
        initialBuilding;
    const selectedFloor =
        selectedBuilding.floors.find(floor => floor.id === state.manageLocation.tableFilters.floorID) || initialFloor;
    const selectedLocation =
        selectedFloor.locations.find(location => location.id === state.manageLocation.tableFilters.locationID) ||
        initialLoc;

    return {
        className: 'user-edit',
        loading: selectIsLoading(state),
        show: state.manageLocation.showEditLocationModal,
        tableFilters: state.manageLocation.tableFilters,
        facility: state.facilities.facilitiesByID[state.manageJob.selectedJob.facilityID],
        selectedBuilding,
        selectedFloor,
        selectedLocation,
        title: modalTitle
    };
};

export default withTranslation('manageLocation')(
    connect(mapStateToProps, {
        saveAnyLocation,
        updateAnyLocation,
        toggleEditLocationModal
    })(EditLocationForm)
);
