/*
 * All parts form
 */

import { Col, Button } from 'react-bootstrap';
import { FormGroup, FormGenerator, FieldConfig, Validators, GroupProps, FormArray } from 'react-reactive-form';
import { find, debounce } from 'lodash';

import * as React from 'react';
import { TFunction } from 'i18next';

import { toastr } from 'react-redux-toastr';
import { FormUtil } from '../../common/FormUtil';
import { Ipart } from '../../../models';
import { initialPart } from '../../../reducers/initialState';
import { searchPartsForAsyncSelect } from '../../../actions/partsActions';
import { partTypeEnum } from '../../../models-enums';
import { IgenericFormValues } from '../../../modelsForms';
import { constants } from '../../../constants/constants';

export interface ImetaNumberInputWithButton {
    label: string;
    placeholder?: string;
    buttonAction: (id: string) => void;
    id: string;
}

/*
 * Input row with a button to delete the cart item
 */

interface Iprops {
    loading?: boolean;
    colorButton?: string;
    onSubmit: (part: Ipart, installID: string) => void;
    installID: string;
    t: TFunction;
    partType: partTypeEnum;
    disabled: boolean;
}
interface Istate {
    fieldConfig: FieldConfig;
    parts: Ipart[];
    disabledOverride: boolean;
}

export class AllPartsForm extends React.Component<Iprops, Istate> {
    private userForm: FormGroup | any;
    private subscription: any;

    constructor(props: Iprops) {
        super(props);
        this.state = {
            fieldConfig: { controls: {} },
            parts: [],
            disabledOverride: false
        };
    }

    componentDidMount() {
        this.setState({
            fieldConfig: this.buildFieldConfig(this.itemToFormValues())
        });
    }

    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    componentDidUpdate(prevProps: Iprops) {
        if (prevProps.disabled !== this.props.disabled) {
            this.setState({
                fieldConfig: this.buildFieldConfig(this.itemToFormValues())
            });
        }
    }

    /*
     * itemToFormValues - take the selectedItem and convert it to formValues
     */
    itemToFormValues = (): IgenericFormValues<Ipart> => {
        return {};
    };

    formValuesToItem = (): Ipart => {
        const processedFormValues = FormUtil.getValues(this.userForm.value);
        const foundPart =
            (find(this.state.parts, {
                id: processedFormValues.partID
            }) as Ipart) || initialPart;
        return {
            ...initialPart,
            ...foundPart,
            ...processedFormValues
        };
    };

    loadOptions = debounce((searchTerm, callback) => {
        searchPartsForAsyncSelect(searchTerm, this.props.partType).then(result => {
            if (result.networkError) {
                // If we got a network error, and we haven't already disabled the form, rebuild the form with the disabledOverride set to true
                if (this.state.disabledOverride === false) {
                    this.setState({
                        parts: result.parts,
                        disabledOverride: true,
                        fieldConfig: this.buildFieldConfig(this.itemToFormValues(), true)
                    });
                } else {
                    this.setState({ parts: result.parts, disabledOverride: true });
                }
                callback(result.partOptions);
            } else {
                // If the form was previously disabled, but we got no network issues, rebuild the form with the disabledOverride set to false
                if (this.state.disabledOverride === true) {
                    this.setState({
                        parts: result.parts,
                        disabledOverride: false,
                        fieldConfig: this.buildFieldConfig(this.itemToFormValues())
                    });
                } else {
                    this.setState({ parts: result.parts, disabledOverride: false });
                }
                callback(result.partOptions);
            }
        });
    }, 500);

    buildFieldConfig = (defaultValues = this.itemToFormValues(), disableForm: boolean = false) => {
        // Field config to configure form
        let fieldConfigControls = {
            partID: {
                options: {
                    validators: [Validators.required]
                },
                render: FormUtil.AsyncSelect,
                meta: {
                    label: 'All Parts (not including labor)',
                    loadOptions: this.loadOptions,
                    name: 'part',
                    required: true,
                    colWidth: 8,
                    isMulti: false
                },
                formState: {
                    value: null,
                    disabled: this.props.disabled || disableForm
                }
            },
            quantity: {
                options: {
                    validators: [Validators.required, FormUtil.validators.isValidPositiveInteger]
                },
                render: FormUtil.NumericInput,
                meta: {
                    label: 'searchAllPartsModal.quantity',
                    colWidth: 2,
                    name: 'quantity',
                    required: true
                },
                formState: {
                    value: 1,
                    disabled: this.props.disabled || disableForm
                }
            }
        } as { [key: string]: GroupProps };

        const fieldConfig = {
            controls: { ...fieldConfigControls }
        };

        return FormUtil.translateForm(fieldConfig, this.props.t);
    };

    handleSubmit = (e: React.MouseEvent<Button>) => {
        e.preventDefault();
        if (this.userForm.status === 'INVALID') {
            this.userForm.markAsSubmitted();
            return toastr.error(this.props.t('toastMessage:invalidFormSubmission'), '', constants.toastrError);
        }
        this.props.onSubmit(this.formValuesToItem(), this.props.installID);
    };

    setForm = (form: FormGroup | FormArray) => {
        this.userForm = form;
        this.userForm.meta = {
            loading: this.props.loading
        };
    };

    render() {
        const { t } = this.props;

        const formClassName = `clearfix beacon-form manage-form ${this.props.colorButton}`;

        return (
            <form className={formClassName}>
                <Col xs={12} className="form-buttons">
                    <FormGenerator onMount={this.setForm} fieldConfig={this.state.fieldConfig} />

                    <Button
                        bsStyle={this.props.colorButton}
                        type="button"
                        onClick={e => this.handleSubmit(e)}
                        style={{ margin: '24px 0 0 44px' }}
                        disabled={this.props.disabled || this.props.loading}
                    >
                        {t('Add')}
                    </Button>
                </Col>
            </form>
        );
    }
}
