import * as types from '../actions/actionTypes';

import {
    IWorkOrder,
    IjobWorkOrder,
    IpreventativeMaintenanceChecklist,
    IjobPopulated,
    WOClosingNote,
    IinstallBase
} from '../models';
import {
    createFormValuesWithName,
    createSelectedIDWithName,
    createShowModalWithNamedType,
    createTableFiltersWithName
} from './commonReducers';
import { camelCase, filter, forEach, keyBy, mapKeys, omit, pickBy } from 'lodash';
import initialState, { initialPreventativeMaintenanceChecklist, initialWorkOrder } from './initialState';

import { IinitialState } from '.';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import { workOrderStatusEnum, workOrderTypesEnum } from '../models-enums';
import { TFunction } from 'i18next';
import { selectJobWorkOrdersForJobID } from './commonSelectors';

export const isJson = (str: string): boolean => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }

    return true;
};
// convert keys to camelCase
export const ccWONotes = (notesList: WOClosingNote[]): WOClosingNote[] =>
    // note on typing: for mapKeys to go from a Dictionary to WOClosingNote, it must first be coerced t unknown
    notesList.map(
        note => (mapKeys(note, (value, key) => camelCase(key) as keyof WOClosingNote) as unknown) as WOClosingNote
    );
const fmtCN = (notes: string): string => {
    const shouldFmt = isJson(notes);

    if (shouldFmt) {
        const parsed: WOClosingNote[] = JSON.parse(notes);
        const cleaned = ccWONotes(parsed);

        return JSON.stringify(cleaned);
    }

    return notes;
};

const cleanWorkOrderObject = (workOrder: any): IWorkOrder => {
    const normalizedWorkOrder = omit(workOrder, 'jobWorkOrders', 'facility');
    return {
        ...initialWorkOrder,
        ...pickBy(normalizedWorkOrder, property => property !== null)
    };
};
const cleanPreventativeMaintenanceChecklist = (checklist: IpreventativeMaintenanceChecklist) => {
    return {
        ...initialPreventativeMaintenanceChecklist,
        ...pickBy(checklist, property => property !== null)
    } as IpreventativeMaintenanceChecklist;
};

/*
 * WORK ORDER SELECTORS
 */

const getSelectedJob = (state: IinitialState) => state.manageJob.selectedJob;

export const selectWorkOrdersByID = (state: IinitialState) => state.workOrder.workOrdersByID;
const selectSAPWorkOrdersByID = (state: IinitialState) => state.workOrder.unlinkedSapWorkOrdersByID;

export const selectCurrentWorkOrderID = (state: IinitialState) => state.workOrder.selectedWorkOrderID;

export const selectWorkOrdersByInstallBaseID = (state: IinitialState, installBaseID: string) => {
    return filter(state.workOrder.workOrdersByID, {
        installBaseID,
        isDeleted: false
    });
};
const selectInstallBaseIDProp = (state: IinitialState, props: { installBaseID: string }) => props.installBaseID;
const selectTableFilters = (state: IinitialState) => state.workOrder.tableFilters;

const getShowWorkOrderCloseModal = (state: IinitialState) => state.workOrder.showWorkOrderCloseModal;
const getShowAddRepairWorkOrderModal = (state: IinitialState) => state.workOrder.showAddRepairWorkOrderModal;
const getShowConfirmSelectJobModal = (state: IinitialState) => state.workOrder.showConfirmSelectJobModal;
const getShowJobSignatureModal = (state: IinitialState) => state.workOrder.showJobSignatureModal;

export const selectWOModals = createSelector(
    [
        getShowWorkOrderCloseModal,
        getShowAddRepairWorkOrderModal,
        getShowConfirmSelectJobModal,
        getShowJobSignatureModal
    ],
    (workOrderCloseModal, addRepairWorkOrderModal, confirmSelectJobModal, jobSignatureModal) => {
        const anyModalOpen = [
            workOrderCloseModal,
            addRepairWorkOrderModal,
            confirmSelectJobModal,
            jobSignatureModal
        ].some(modal => modal);

        return anyModalOpen;
    }
);

export const getWorkOrderTitle = (workOrder: IWorkOrder, t: TFunction): string => {
    const checklist = workOrder.preventativeMaintenanceChecklist;
    const pmpWorkOrderTitle = checklist ? checklist.name : `${t('manageWorkOrder:maintenanceTabTitle')}`;
    const repairWorkOrderTitle = `${t('manageWorkOrder:repairTabTitle')}: ${workOrder.number}`;
    const warrantyWorkOrderTitle = `${t('manageInventory:warranty')}`;
    switch (workOrder.type) {
        case workOrderTypesEnum.pmp:
            return pmpWorkOrderTitle;
        case workOrderTypesEnum.warranty:
            return warrantyWorkOrderTitle;
        case workOrderTypesEnum.repair:
            return repairWorkOrderTitle;
        default:
            return repairWorkOrderTitle;
    }
};

export const getCurrentWorkOrder = createSelector(
    [selectWorkOrdersByID, selectCurrentWorkOrderID],
    (woByID, currentWOID) => woByID[currentWOID] || initialWorkOrder
);

export const getSAPWorkOrders = createSelector([selectSAPWorkOrdersByID], sapWorkOrders => {
    return sapWorkOrders;
});

export const selectWorkOrdersForInstallAndJob = createSelector(
    [selectJobWorkOrdersForJobID, selectInstallBaseIDProp],
    (jobWorkOrdersForJob: IjobWorkOrder[], installBaseID: string) => {
        const filteredJobWorkOrders = filter(jobWorkOrdersForJob, jobWorkOrder => {
            let shouldInclude = true;
            if (jobWorkOrder.workOrder && jobWorkOrder.workOrder.installBaseID !== installBaseID) {
                shouldInclude = false;
            }
            if (jobWorkOrder.workOrder && jobWorkOrder.workOrder.isDeleted === true) {
                shouldInclude = false;
            }
            if (jobWorkOrder.isDeleted === true) {
                shouldInclude = false;
            }
            return shouldInclude;
        });
        let workOrders: IWorkOrder[] = [];
        forEach(filteredJobWorkOrders, jwo => {
            if (jwo.workOrder) {
                workOrders = [...workOrders, jwo.workOrder];
            }
        });
        return workOrders;
    }
);

export const selectCompleteWorkOrdersByInstallBaseID = (state: IinitialState, installBaseID: string) => {
    return filter(state.workOrder.workOrdersByID, {
        installBaseID,
        isDeleted: false,
        status: workOrderStatusEnum.complete
    });
};

export const selectPMPWorkOrders = (state: IinitialState) => {
    return filter(state.workOrder.workOrdersByID, {
        type: workOrderTypesEnum.pmp
    });
};
const getInstallBases = (state: IinitialState) => state.manageInventory.installBasesByID;

export const getSAPWorkOrdersWithInstallBase = createSelector(
    [selectSAPWorkOrdersByID, getInstallBases],
    (sapWorkOrders, installBases) => {
        return Object.values(sapWorkOrders).reduce((carry, sapWorkOrder) => {
            const installBase: IinstallBase = installBases[sapWorkOrder.installBaseID];

            return {
                ...carry,
                [sapWorkOrder.id]: {
                    ...sapWorkOrder,
                    installBase
                }
            };
        }, {} as { [key: string]: IWorkOrder });
    }
);

export const filterPMPWorkOrders = createSelector(
    [selectPMPWorkOrders, selectTableFilters, getInstallBases, getSelectedJob],
    (pmpWosByID, tableFilters, installBasesByID, selectedJob) => {
        const { search } = tableFilters;
        const { facilityID } = selectedJob;
        const searchString = search.toLowerCase();
        const filteredPMPWorkOrders = filter(pmpWosByID, (pmpWo: IWorkOrder) => {
            const installBase = installBasesByID[pmpWo.installBaseID];

            let shouldInclude = false;

            // If the install base doesn't belong to the current facility, stop now MMG-1652
            if (installBase && installBase.facilityID !== facilityID) {
                return false;
            }

            if (searchString && searchString.length) {
                // If search text is in installBaseLocation OR productName, then include it
                if (pmpWo.installBaseLocation && pmpWo.installBaseLocation.toLowerCase().search(searchString) !== -1) {
                    shouldInclude = true;
                }
                if (pmpWo.productName && pmpWo.productName.toLowerCase().search(searchString) !== -1) {
                    shouldInclude = true;
                }
            }

            // If no search text was passed in, return all install bases for this facility
            if (searchString.length === 0 && installBase?.facilityID === facilityID) {
                shouldInclude = true;
            }

            return shouldInclude;
        });

        return filteredPMPWorkOrders;
    }
);

/*
 * WORK ORDER REDUCERS
 */

export function workOrdersByID(
    state: { [key: string]: IWorkOrder } = initialState.workOrder.workOrdersByID,
    action: any
): { [key: string]: IWorkOrder } {
    switch (action.type) {
        // TODO is this the only way to go about this? Could these be in a separate reducer instead?
        case types.LOAD_SAP_WORKORDERS_SUCCESS:
            const cleanedPmpWorkOrders = action.payload.data.result.map((pmpWO: IWorkOrder) => {
                return cleanWorkOrderObject(pmpWO);
            });
            return { ...state, ...keyBy(cleanedPmpWorkOrders, 'id') };
        case types.LOAD_WORKORDERS_SUCCESS: {
            const cleanedPmpWorkOrders = action.payload.data.result.map((pmpWO: IWorkOrder) => {
                return cleanWorkOrderObject(pmpWO);
            });
            return { ...state, ...keyBy(cleanedPmpWorkOrders, 'id') };
        }
        case types.UPDATE_WORK_ORDER: {
            const oldWorkOrder = state[action.workOrder.id];
            const newWorkOrder = { ...oldWorkOrder, ...action.workOrder };
            return {
                ...state,
                [action.workOrder.id]: newWorkOrder
            };
        }
        case types.DELETE_WORKORDER: {
            return {
                ...state,
                [action.workOrderID]: {
                    ...state[action.workOrderID],
                    isDeleted: action.isDeleted
                }
            };
        }
        case types.JOB_UPDATE: {
            if (action.job && action.job.jobWorkOrders) {
                let newWOs: IWorkOrder[] = [];
                action.job.jobWorkOrders.forEach((jwo: IjobWorkOrder) => {
                    newWOs = [...newWOs, cleanWorkOrderObject(jwo.workOrder)];
                });
                return { ...state, ...keyBy(newWOs, 'id') };
            } else {
                return state;
            }
        }
        case types.UPDATE_WORK_ORDERS_BULK: {
            return { ...state, ...keyBy(action.workOrders, 'id') };
        }
        case types.INSTALL_UPDATE:
        case types.INSTALL_UPDATE_BULK: {
            if (action.workOrders && action.workOrders.length > 0) {
                return { ...state, ...keyBy(action.workOrders, 'id') };
            } else {
                return state;
            }
        }
        case types.OPEN_JOB_SUCCESS: {
            if (action.sapWorkOrders.length > 0) {
                return { ...state, ...keyBy(action.sapWorkOrders, 'id') };
            } else {
                return { ...state };
            }
        }
        case types.JOB_GET_ASSIGNED_SUCCESS: {
            let newWOrders: IWorkOrder[] = [];
            let jobs = action.jobs;

            if (action.payload && action.payload.data && action.payload.data.job) {
                jobs = [action.payload.data.job];
            }

            jobs.forEach((job: IjobPopulated) => {
                if (job.jobWorkOrders && job.jobWorkOrders.length) {
                    job.jobWorkOrders.forEach(jobWorkOrder => {
                        if (jobWorkOrder.workOrder) {
                            newWOrders = [...newWOrders, cleanWorkOrderObject(jobWorkOrder.workOrder)];
                        }
                    });
                }
            });

            // TODO: closingNotesList comes back with capitalized keys, so we need to clean it
            // there's probably a better way to go about this, but short on time
            newWOrders = newWOrders.map(wo => {
                const closingNotesList: WOClosingNote[] | null = wo.closingNotesList
                    ? ccWONotes(wo.closingNotesList)
                    : wo.closingNotes && isJson(wo.closingNotes)
                    ? ccWONotes(JSON.parse(wo.closingNotes))
                    : null;

                return {
                    ...wo,
                    closingNotes: wo.closingNotes && isJson(wo.closingNotes) ? fmtCN(wo.closingNotes) : wo.closingNotes,
                    closingNotesList
                };
            });

            return { ...state, ...keyBy(newWOrders, 'id') };
        }
        case types.JOB_UPDATE_WORKORDERS_SUCCESS: {
            // This is the same as JOB_GET_ASSIGNED_SUCCESS right above, except it just processes one job, after you add work orders to a job
            if (action.payload.data) {
                const updatedJob: IjobPopulated = action.payload.data;
                let newWOrders: IWorkOrder[] = [];

                if (updatedJob.jobWorkOrders && updatedJob.jobWorkOrders.length) {
                    updatedJob.jobWorkOrders.forEach(jobWorkOrder => {
                        if (jobWorkOrder.workOrder) {
                            newWOrders = [...newWOrders, cleanWorkOrderObject(jobWorkOrder.workOrder)];
                        }
                    });
                }

                newWOrders = newWOrders.map(wo => {
                    const closingNotesList: WOClosingNote[] | null = wo.closingNotesList
                        ? ccWONotes(wo.closingNotesList)
                        : wo.closingNotes && isJson(wo.closingNotes)
                        ? ccWONotes(JSON.parse(wo.closingNotes))
                        : null;

                    return {
                        ...wo,
                        closingNotes:
                            wo.closingNotes && isJson(wo.closingNotes) ? fmtCN(wo.closingNotes) : wo.closingNotes,
                        closingNotesList
                    };
                });
            }

            return { ...state };
        }
        case types.ADD_WORKORDER: {
            return { ...state, [action.workOrder.id]: action.workOrder };
        }
        case types.SET_MEASUREMENT_POINT_RESULT_HISTORY: {
            let newWOrders: IWorkOrder[] = [];

            action.payload.data.map((res: any) => {
                // res will be a ImeasurementPointResult, but in this one case, the API is sending some nested goodies
                if (res && res.job && res.job.jobWorkOrders) {
                    res.job.jobWorkOrders.forEach((jwo: IjobWorkOrder) => {
                        if (jwo.workOrder) {
                            newWOrders = [...newWOrders, cleanWorkOrderObject(jwo.workOrder)];
                        }
                    });
                }
            });

            return { ...state, ...keyBy(newWOrders, 'id') };
        }
        case types.USER_LOGOUT_SUCCESS:
            return initialState.workOrder.workOrdersByID;
        default:
            return state;
    }
}

/*
 * SAP WO's that are new or reopened, are not tied to a job yet, and assigned to the currently signed in user
 */
function sapWorkOrdersByIDReducer(
    state: { [key: string]: IWorkOrder } = initialState.workOrder.unlinkedSapWorkOrdersByID,
    action: any
): { [key: string]: IWorkOrder } {
    switch (action.type) {
        case types.LOAD_SAP_WORKORDERS_SUCCESS: {
            return { ...keyBy(action.payload.data.result, 'id') };
        }
        case types.UNLINK_SAP_WORKORDER: {
            return { ...state, [action.id]: { ...action.workOrder, isDeleted: false } };
        }
        case types.USER_LOGOUT_SUCCESS: {
            return initialState.manageJob.jobWorkOrdersByID;
        }
        case types.DELETE_UNLINKED_SAP_WORKORER:
        case types.DELETE_WORKORDER: {
            return {
                ...state,
                [action.workOrderID]: {
                    ...state[action.workOrderID],
                    isDeleted: action.isDeleted
                }
            };
        }
        default:
            return state;
    }
}

export function preventativeMaintenanceChecklistsByID(
    state: { [key: string]: IpreventativeMaintenanceChecklist } = initialState.workOrder
        .preventativeMaintenanceChecklistsByID,
    action: any
): { [key: string]: IpreventativeMaintenanceChecklist } {
    switch (action.type) {
        case types.LOAD_WORKORDERS_SUCCESS: {
            let pmcs: IpreventativeMaintenanceChecklist[] = [];
            action.payload.data.result.forEach((workOrder: IWorkOrder) => {
                if (workOrder.preventativeMaintenanceChecklist) {
                    pmcs = [...pmcs, cleanPreventativeMaintenanceChecklist(workOrder.preventativeMaintenanceChecklist)];
                }
            });
            return keyBy(pmcs, 'id');
        }
        case types.USER_LOGOUT_SUCCESS:
            return initialState.workOrder.preventativeMaintenanceChecklistsByID;
        default:
            return state;
    }
}
function selection(state: string[] = initialState.workOrder.selection, action: any): string[] {
    switch (action.type) {
        case types.WORKORDER_UPDATE_SELECTION:
            return action.payload;
        case types.USER_LOGOUT_SUCCESS:
            return initialState.workOrder.selection;
        default:
            return state;
    }
}

export default combineReducers({
    selectedWorkOrderID: createSelectedIDWithName('WORKORDER_ID'),
    historicalWorkOrderID: createSelectedIDWithName('HISTORICAL_WORKORDER_ID'),
    workOrdersByID,
    unlinkedSapWorkOrdersByID: sapWorkOrdersByIDReducer,
    preventativeMaintenanceChecklistsByID,
    showWorkOrderCloseModal: createShowModalWithNamedType('CLOSING_NOTES'),
    workOrderFormValues: createFormValuesWithName('MANAGE_WORKORDER'),
    showAddRepairWorkOrderModal: createShowModalWithNamedType('ADD_REPAIR_WORKORDER'),
    showConfirmSelectJobModal: createShowModalWithNamedType('CONFIRM_SELECT_JOB'),
    isJobClosingWithSignature: createShowModalWithNamedType('JOB_CLOSING_WITH_SIGNATURE'), //
    isCollectingSignatures: createShowModalWithNamedType('IS_COLLECTING_SIGNATURES'), //
    showJobSignatureModal: createShowModalWithNamedType('JOB_SIGNATURE'), //
    tableFilters: createTableFiltersWithName('MANAGE_WORKORDER'),
    selection
});
