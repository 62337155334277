import * as React from 'react';
import { toastr } from 'react-redux-toastr';
import { useHistory } from 'react-router-dom';

import { Button, Col, Row } from 'react-bootstrap';
import { ImeasurementPointResult, ImeasurementPointResultWithType } from '../../models';

import { LinkContainer } from 'react-router-bootstrap';
import { TFunction } from 'i18next';
import { clearHistoricalMPResult, clearHistoricalResultID } from '../../actions/measurementPointResultsActions';
import { constants } from '../../constants/constants';
import {
    toggleAddRepairWorkOrderModal,
    clearSelectedWorkOrderID,
    clearSelectedHistoricalWorkOrderID,
    toggleConfirmSelectJobModal,
    updateWorkOrder
} from '../../actions/workOrderActions';
import { jobTypesIdEnum, workOrderStatusEnum } from '../../models-enums';
import { useDispatch, useSelector } from 'react-redux';
import * as types from '../../actions/actionTypes';
import { selectSelectedJob } from '../../reducers/manageJobReducer';
import { getInventory } from '../../actions/manageInventoryActions';
import { getIsMobile } from '../../reducers/configReducer';
import { getCurrentWorkOrder } from '../../reducers/workOrderReducer';
import { default as PointListButtonBarMobile } from './Mobile/PointListButtonBar';

interface Iprops {
    t: TFunction;
    loading: boolean;
    updateWorkOrder: typeof updateWorkOrder;
    toggleAddRepairWorkOrderModal: typeof toggleAddRepairWorkOrderModal;
    handleCannotComplete: () => void;
    clearHistoricalResultID: typeof clearHistoricalResultID;
    historicalResultID: string;
    historicalWorkOrderID: string;
    selectedResult: ImeasurementPointResult;
    showTestProceduresButton: boolean;
    clearSelectedWorkOrderID: typeof clearSelectedWorkOrderID;
    clearSelectedHistoricalWorkOrderID: typeof clearSelectedHistoricalWorkOrderID;
    toggleConfirmSelectJobModal: typeof toggleConfirmSelectJobModal;
    clearHistoricalMPResult: typeof clearHistoricalMPResult;
    isWorkOrderTypeJob: boolean;
    jobTypeID: string;
    installID: string;
    handleSubmit: () => void;
}

export const PointListButtonBar = (props: Iprops) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [failing, setFailing] = React.useState<boolean>(false);
    const [hasClosingNotes, setHasClosingNotes] = React.useState<boolean>(false);
    const history = useHistory();
    const clearHistoricalResult = () => {
        props.clearHistoricalResultID();
        props.clearSelectedHistoricalWorkOrderID();
        props.clearHistoricalMPResult();
    };
    const { historicalResultID, t, jobTypeID } = props;
    const isAuditJob = jobTypeID === jobTypesIdEnum.audit;
    const isMaintenanceJob = jobTypeID === jobTypesIdEnum.maintenance;
    const isRepairJob = jobTypeID === jobTypesIdEnum.repair;
    const isWarrantyJob = jobTypeID === jobTypesIdEnum.warrantyBM;
    const isCommissiongJob = jobTypeID === jobTypesIdEnum.commissioning;
    const isServicePlanJob = jobTypeID === jobTypesIdEnum.servicePlan;
    const selectedJob = useSelector(selectSelectedJob);
    const dispatch = useDispatch();
    const isMobile = useSelector(getIsMobile);
    const selectedWorkOrder = useSelector(getCurrentWorkOrder);

    const copyHistory = (event: any) => {
        event.preventDefault();

        // Closes historic view, and probably resets global value(s) in redux
        clearHistoricalResult();

        // Determine which object type we are looking at, some jobs have history work orders, others have historic MP Results
        if (historicalResultID.length) {
            // Need to merge ImeasurementPointResult into ImeasurementPointResultWithType
            const result: ImeasurementPointResultWithType = { ...props.selectedResult, measurementPointListType: 0 };

            dispatch({
                type: types.SET_HISTORICAL_MP_RESULT,
                payload: result
            });
        } else if (props.historicalWorkOrderID.length) {
            dispatch({
                type: types.SET_HISTORICAL_MP_RESULT,
                payload: selectedWorkOrder
            });
        } else {
            // Shouldn't be here, probably add a toast here as well
            console.error('Failed to find historic MPs');
        }
    };

    const fetchStatuses = React.useCallback(() => {
        clearHistoricalResult();
        dispatch(getInventory(selectedJob.facilityID));
    }, [dispatch, selectedJob.facilityID, clearHistoricalResult]);

    React.useEffect(() => {
        const failedAnswers = props.selectedResult.measurementPointAnswers.filter(
            answer => answer.pass !== undefined && answer.pass === 2
        );

        if (failedAnswers.length > 0) {
            setFailing(true);
        } else {
            setFailing(false);
        }
    }, [props.selectedResult]);

    // disable Save & Log if no closing notes are entered
    React.useEffect(() => {
        if (!selectedWorkOrder) {
            return;
        }

        const closingNotes = selectedWorkOrder?.closingNotes;
        const closingNotesList = selectedWorkOrder?.closingNotesList;

        switch (true) {
            case !isRepairJob && !isMaintenanceJob && !isWarrantyJob && !isServicePlanJob && !isCommissiongJob:
                setHasClosingNotes(true);
                break;
            case closingNotes && closingNotes?.length > 0 && Boolean(closingNotes):
                setHasClosingNotes(true);
                break;
            case Boolean(closingNotesList):
                setHasClosingNotes(true);
                break;
            default:
                setHasClosingNotes(false);
                break;
        }
    }, [selectedWorkOrder]);

    const closeJob = () => {
        const hasWorkingNotes = selectedWorkOrder.activeClosingNotes;
        const isComplete = selectedWorkOrder.status === workOrderStatusEnum.complete;
        if (isRepairJob && !isComplete && hasWorkingNotes) {
            const toastrConfirmOptions = {
                onOk: () => {
                    // clear out any working notes
                    props.updateWorkOrder({ ...selectedWorkOrder, activeClosingNotes: null });
                    fetchStatuses();
                    history.push('/devices');
                },
                onCancel: () => null,
                okText: t('common:ok'),
                cancelText: t('common:cancel')
            };
            toastr.confirm(t('closeWarning'), toastrConfirmOptions);
        } else {
            fetchStatuses();
            history.push('/devices');
        }
    };

    if (isMobile) {
        return (
            <PointListButtonBarMobile
                isHistoricalWorkOrder={historicalResultID.length > 0 || props.historicalWorkOrderID.length > 0}
                isAuditJob={isAuditJob}
                isRepairJob={isRepairJob}
                closeJob={closeJob}
                t={t}
                loading={props.loading}
                handleRepairClick={() => {
                    props.toggleConfirmSelectJobModal();
                    props.clearSelectedWorkOrderID();
                }}
                handleCannotComplete={props.handleCannotComplete}
                hasClosingNotes={hasClosingNotes}
                fetchStatuses={fetchStatuses}
                clearHistoricalResult={clearHistoricalResult}
                copyHistory={copyHistory}
                handleSubmit={props.handleSubmit}
            />
        );
    }

    if (historicalResultID.length || props.historicalWorkOrderID.length) {
        return (
            <Row className="mp-button-row">
                <Col xs={12}>
                    <LinkContainer to="/devices">
                        <Button bsStyle="link" type="button" onClick={fetchStatuses}>
                            {t('close')}
                        </Button>
                    </LinkContainer>
                    <Button
                        bsStyle="link"
                        type="button"
                        style={{ color: constants.colors.orange }}
                        disabled={props.loading}
                        onClick={clearHistoricalResult}
                    >
                        {t('finishViewingHistoricalResultButton')}
                    </Button>

                    <Button bsStyle="link" type="button" disabled={props.loading} onClick={copyHistory}>
                        Copy History
                    </Button>
                </Col>
            </Row>
        );
    } else if (isAuditJob) {
        return (
            <Row className="mp-button-row">
                <Col xs={12}>
                    <LinkContainer to="/devices">
                        <Button bsStyle="link" type="button" onClick={fetchStatuses}>
                            {t('close')}
                        </Button>
                    </LinkContainer>
                    <Button bsStyle="link" type="submit" className="pull-right" disabled={props.loading}>
                        {t('saveLog')}
                    </Button>
                </Col>
            </Row>
        );
    }

    return (
        <Row className="mp-button-row">
            <Col xs={12}>
                <Button bsStyle="link" type="button" onClick={closeJob} disabled={props.loading}>
                    {t('close')}
                </Button>
                {isRepairJob === false && (
                    <Button
                        bsStyle="link"
                        type="button"
                        style={{ color: constants.colors.orange }}
                        disabled={props.loading}
                        onClick={() => {
                            props.toggleConfirmSelectJobModal();
                            props.clearSelectedWorkOrderID();
                        }}
                    >
                        {t('repairButton')}
                    </Button>
                )}
                <Button
                    bsStyle="link"
                    type="submit"
                    className="pull-right"
                    disabled={props.loading || !hasClosingNotes}
                    data-withclosingnotes={hasClosingNotes}
                >
                    {t('saveLog')}
                </Button>
                <Button
                    bsStyle="link"
                    type="button"
                    className="pull-right"
                    style={{
                        display: props.showTestProceduresButton ? '' : 'none'
                    }}
                    disabled={props.loading}
                    onClick={props.handleCannotComplete}
                >
                    {t('cannotComplete')}
                </Button>
            </Col>
        </Row>
    );
};
