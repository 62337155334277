/*
 * MeasurementPointListContainer connects the MeasurementPointList form to redux
 */

import { WithTranslation, withTranslation } from 'react-i18next';
import {
    clearHistoricalResultID,
    initSelectedResult,
    submitMeasurementPointResult,
    updateMprAnswers
} from '../../actions/measurementPointResultsActions';
import {
    clearSelectedHistoricalWorkOrderID,
    setSelectedWorkOrderID,
    toggleConfirmSelectJobModal
} from '../../actions/workOrderActions';
import {
    getAllMeasurementPointLists,
    setSelectedMeasurementPointListByID
} from '../../actions/measurementPointListActions';
import {
    initialInstallBase,
    initialJob,
    initialMeasurementPointList,
    initialWorkOrder
} from '../../reducers/initialState';

import { IinitialState } from '../../reducers';
import { IinstallBasePopulated, ImeasurementPointResult, IWorkOrder } from '../../models';
import MeasurementPointListForm from '../measurementPointList/MeasurementPointListForm';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { getIsCommissioningTypeJob, getIsWorkOrderTypeJob } from '../../reducers/manageJobReducer';
import { jobTypesIdEnum } from '../../models-enums';
import {
    selectMeasurementPointListForInstallBase,
    selectMeasurementPointResultsIDs
} from '../../reducers/measurementPointListsReducer';
import { selectWorkOrdersForInstallAndJob } from '../../reducers/workOrderReducer';
import { getInventory, setSelectedProductByID } from '../../actions/manageInventoryActions';
import { selectInstallBaseHistoryByInstallBaseID, selectIsLoading } from '../../reducers/commonReducers';
import { getAssignedJobs } from '../../actions/manageJobActions';
import { selectInstallBasesStatus } from '../../reducers/manageInventoryReducer';
import { getIsMobile } from '../../reducers/configReducer';

interface Iprops extends RouteComponentProps<{ installID: string }> {
    installBasesNotTested: IinstallBasePopulated[];
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops & WithTranslation) => {
    const selectedInstall =
        state.manageInventory.installBasesByID[ownProps.match.params.installID] || initialInstallBase;
    const selectedJob = state.manageJob.jobsByID[state.manageJob.selectedJob.id] || initialJob;
    const selectedProduct = state.manageInventory.productsByID[selectedInstall.productID];
    const facility = state.facilities.facilitiesByID[selectedJob.facilityID];
    const isWorkOrderTypeJob = getIsWorkOrderTypeJob(state);
    const checklistMode = selectedJob.jobTypeID === jobTypesIdEnum.maintenance;
    const { historicalWorkOrderID } = state.workOrder;
    const { historicalResultID, selectedResult } = state.measurementPointResults;
    const selectedHistoricalWO = state.workOrder.workOrdersByID[historicalWorkOrderID];
    let selectedWorkOrders: IWorkOrder[] = [];
    let selectedMeasurementPointList = initialMeasurementPointList;
    const installBaseHistory = selectInstallBaseHistoryByInstallBaseID(state, selectedInstall.id);
    const installBasesStatus = selectInstallBasesStatus(state);
    const allJobs = state.manageJob.jobsByID;
    let historicalMP: ImeasurementPointResult | IWorkOrder = state.measurementPointResults.historicalMPToCopy;
    const isCommissioningTypeJob = getIsCommissioningTypeJob(state);

    // In ManageInventory, we are dispatching CLEAR_HISTORICAL_MP_TO_COPY to clear out the historicalMPToCopy. Just in case, let's double check that
    // if there is historicalMPToCopy, it's actually for this install base, if it's not, send an empty object to the form so no Answers are copied
    if (historicalMP.installBaseID !== selectedInstall.id) {
        historicalMP = initialWorkOrder;
    }

    if (isWorkOrderTypeJob) {
        selectedWorkOrders = selectWorkOrdersForInstallAndJob(state, {
            installBaseID: selectedInstall.id
        });
    }

    if (historicalWorkOrderID.length && selectedHistoricalWO) {
        selectedWorkOrders = [selectedHistoricalWO];
    }

    // TODO change selectedMeasurementPointList to selectedMeasurementPointListOverride - for viewing historical results
    if (facility) {
        // facility might be undefined after an app upgrade
        selectedMeasurementPointList = selectMeasurementPointListForInstallBase(state, {
            productID: selectedInstall.productID,
            standardID: facility.standardID,
            shouldShowWarning: true
        });
    }

    return {
        user: state.user,
        loading: selectIsLoading(state),
        selectedInstall,
        installBasesStatus,
        allMeasurementPointLists: state.measurementPointLists.measurementPointListsByID,
        productInfo: state.productInfo,
        selectedJob,
        measurementPointsByID: state.measurementPointLists.measurementPointsByID,
        measurementPoinResultsIDs: selectMeasurementPointResultsIDs(state),
        selectedResult,
        measurementPointListsReducer: state.measurementPointLists,
        historicalResultID,
        standardID: facility ? facility.id : '', // facility might be undefined after an app upgrade
        checklistMode,
        selectedWorkOrders,
        isWorkOrderTypeJob,
        isCommissioningTypeJob,
        selectedProduct,
        selectedMeasurementPointList,
        historicalWorkOrderID,
        installBaseHistory,
        allJobs,
        historicalMP,
        isMobile: getIsMobile(state)
    };
};

export const MeasurementPointListContainer = withTranslation('measurementPoints')(
    connect(mapStateToProps, {
        getJobs: getAssignedJobs,
        getAllMeasurementPointLists,
        submitMeasurementPointResult,
        getInventory,
        clearHistoricalResultID,
        initSelectedResult,
        setSelectedProductByID,
        setSelectedMeasurementPointListByID,
        toggleConfirmSelectJobModal,
        setSelectedWorkOrderID,
        updateMprAnswers,
        clearSelectedHistoricalWorkOrderID
    })(MeasurementPointListForm)
);
