import * as createMigration from 'redux-persist-migrate';
import * as localForage from 'localforage';

import { AnyAction, applyMiddleware, compose, createStore } from 'redux';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { NetworkCallback, OfflineAction } from '@redux-offline/redux-offline/lib/types';
import rootReducer, { IinitialState } from '../reducers';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import { toastr } from 'react-redux-toastr';
import { PersistorConfig } from 'redux-persist';
import TrackJSLogger from './TrackJSLogger';
import { constants } from '../constants/constants';
import initialState from '../reducers/initialState';
import { msalFetch } from '../components/auth/Auth-Utils';
import { offline } from '@redux-offline/redux-offline';
import offlineConfig from '@redux-offline/redux-offline/lib/defaults/index';
import { rootMigrations } from './migrations/rootMigrations';
import { store } from '../index';
import { setSyncStatus, setIsDownloadingJob } from '../actions/commonActions';
import * as types from '../actions/actionTypes';
import { clearSelectedJob, clearSelectedWorkOrderID } from '../actions/workOrderActions';
// import { TFunction } from 'i18next';

const handleCriticalOutboxError = (showToast = true, action: OfflineAction) => {
    console.error('[handleCriticalOutboxError]: critical outbox error', action);
    if (showToast) {
        document.dispatchEvent(new CustomEvent('criticalOutboxErrorWithToast'));
    } else {
        document.dispatchEvent(new CustomEvent('criticalOutboxError'));
    }
};

const effect = ({ axiosOptions, message }: { axiosOptions: AxiosRequestConfig; message: string }, action: any) => {
    const url = axiosOptions?.url || ''; // if this is an empty string, it will generate a 404 response and be discarded.
    return msalFetch(url, axiosOptions).catch((err: AxiosError) => {
        // If there is an error, set the sync status to false
        if (store.getState().syncManager.isSyncRunning === true) {
            store.dispatch(setSyncStatus(false));
            store.dispatch(
                toastr.warning(
                    'Warning', // ('common:warning'), // Need to figure out how to get the t function here
                    'Sync was unable to complete. Please find a better internet connection and try again.',
                    constants.toastrWarning
                )
            );
        }

        // Are we currently downloading a job?
        // Check if one of the required API calls failed
        // if so, take the user back to the job screen and tell them to try again with better internet
        // TODO: this download job needs to be refactored, we shouldn't navigate to /devices if we don't have the job downloaded yet...
        if (store.getState().syncManager.downloadingJob === true && action && action.type) {
            const actionType = action.type;
            if (actionType === types.DOWNLOAD_JOB) {
                store.dispatch(setIsDownloadingJob(false));
                store.dispatch(clearSelectedWorkOrderID());
                store.dispatch(clearSelectedJob());
                store.dispatch(
                    toastr.warning(
                        'Warning', // ('common:warning'), // Need to figure out how to get the t function here
                        'Downloading job was unable to complete. Please find a better internet connection and try again.',
                        constants.toastrWarning
                    )
                );
                window.location.href = '/jobs';
            }
        }

        // If it's a network related error, like a timeout, don't show a toast to the user, just let the app keep retrying
        if (
            err !== undefined &&
            err.code &&
            (err.code === 'ECONNABORTED' || err.code.trim() === 'Error: Network Error')
        ) {
            console.error('[msalFetch Network Error]:', message, err);
            throw err;
        } else if (err !== undefined && err.message && err.message.trim() === 'Network Error') {
            console.error('[msalFetch Network Error]:', message, err);
            throw err;
        } else if (err !== undefined && err.response && err.response.status >= 500) {
            constants.handleError(err, message, 'error');
        } else {
            console.error('[msalFetch]:', message, err);
            constants.handleError(err, message);
            throw err;
        }
    });
};

// if discard returns false, then it will try again
const discard = (error: AxiosError, action: OfflineAction, retries: number) => {
    const { request, response } = error;

    let requestType = '';

    if (action && action.meta && action.meta.offline && action.meta.offline.effect) {
        // object "effect" has type object, so TS is not happy
        const { axiosOptions } = action.meta.offline.effect as any;
        if (axiosOptions && axiosOptions.method) {
            requestType = axiosOptions.method.toLowerCase().trim();
        }
    }

    if (!request && (requestType === 'post' || requestType === 'put')) {
        // If the user is offline or in a state where they are trying to come back online, don't discard any POST or PUT requests, we don't want to lose any data
        // Once they have a stable connection again, and token, "request" shouldn't be undefined anymore
        return false;
    }

    if (!request) {
        throw error;
    } // There was an error creating the request
    if (!response) {
        return false;
    } // There was no response
    if (response.status === 401) {
        return false; // unauthorized
    }

    // If it's a Bad Request, then the app sent an incorrect payload, so we should discard it
    if (response.status >= 400 && response.status < 500) {
        return true;
    }

    // if it is a 500, send it up to the AppLog table
    if (response.status >= 500) {
        handleCriticalOutboxError(false, action);
        return true;
    } else {
        return false;
    }
    //  IDEA: if retryScheduled true and online true, we could show a banner with a button to discard the request.
};

const retry = (action: OfflineAction, retries: number): number | null => {
    return 60000; // retry in 1 minute
};

const isOnline = (callback: NetworkCallback, status: boolean) => {
    localForage.getItem('offlineStatusOverride', (error, offlineStatusOverride) => {
        if (error || typeof offlineStatusOverride !== 'boolean') {
            callback(status);
        } else {
            if (offlineStatusOverride === true) {
                callback(false);
            } else {
                callback(status);
            }
        }
    });
};
const detectNetwork = (callback: NetworkCallback) => {
    if (typeof window !== 'undefined' && window.addEventListener) {
        window.addEventListener(
            'online',
            () => {
                isOnline(callback, true);
            },
            false
        );
        window.addEventListener(
            'offline',
            () => {
                isOnline(callback, false);
            },
            false
        );
    }
};
export const persistConfig: PersistorConfig = {
    keyPrefix: 'my-med-gas-mobile_',
    debounce: 300,
    storage: localForage,
    blacklist: ['showEditProfileModal', 'ajaxCallsInProgress', 'toastr', 'initialSyncActive']
};
// see https://github.com/diegoddox/react-redux-toastr/issues/249 about blacklisting toastr
const migration = createMigration.default(rootMigrations, 'appVersion');

/* eslint-disable no-constant-condition */
export default function configureStore(persistCallback: () => any) {
    const offlineConfigEdited = {
        ...offlineConfig,
        effect,
        discard,
        retry,
        detectNetwork,
        persistOptions: persistConfig,
        persistCallback
    };

    // if (process.env.NODE_ENV !== 'production') {
    if (false) {
        // ! comment in for testing
        const composeEnhancers = require('redux-devtools-extension').composeWithDevTools(
            // for inspecting while using Edge browser remotedev.io/local, does not seem to work for mobile app
            {
                actionsBlacklist: [
                    'persist/REHYDRATE',
                    'GET_INVENTORY_SUCCESS',
                    'BEGIN_AJAX_CALL',
                    'FILTER_VISIBLE_INSTALLS',
                    'MANAGE_MEASUREMENT_POINT_LISTS_SUCCESS',
                    'Offline/BUSY',
                    'UPDATE_SYNC_STATUS_SUCCESS'
                ], // this improves the perfomance of redux devtools
                autoPause: true,
                latency: 3000,
                maxAge: 40,
                shouldHotReload: false,
                trace: true,
                stateSanitizer: (state: IinitialState) => ({
                    ...state,
                    measurementPointLists: '<<LONG_BLOB>>',
                    productInfo: '<<LONG_BLOB>>',
                    manageLocation: '<<LONG_BLOB>>',
                    facilities: '<<LONG_BLOB>>',
                    measurementPointResults: '<<LONG_BLOB>>',
                    manageJob: '<<LONG_BLOB>>',
                    // manageJob: {
                    //     ...state.manageJob,
                    //     jobsByID: '<<LONG_BLOB>>',
                    //     jobWorkOrdersByID: '<<LONG_BLOB>>'
                    // },
                    manageJobComment: '<<LONG_BLOB>>',
                    parts: '<<LONG_BLOB>',
                    simpleMeasurementPointResults: '<<LONG_BLOB>>',
                    //user: '<<LONG_BLOB>',
                    photos: '<<LONG_BLOB>',
                    ts: '<<LONG_BLOB>',
                    //syncStatus: '<<LONG_BLOB>>',
                    toastr: '<<LONG_BLOB>>',
                    // offline: '<<LONG_BLOB>>',
                    manageInventory: '<<LONG_BLOB>>'
                    // manageInventory: {
                    //   ...state.manageInventory,
                    //   data: '<<LONG_BLOB>>'
                    // }
                })
            }
        );
        return createStore(
            rootReducer,
            initialState,
            composeEnhancers(
                applyMiddleware(
                    thunk as ThunkMiddleware<IinitialState, AnyAction>,
                    require('redux-immutable-state-invariant').default()
                ),
                offline(offlineConfigEdited),
                migration
            )
        );
    } else {
        return createStore(
            rootReducer,
            initialState,
            compose(
                applyMiddleware(thunk as ThunkMiddleware<IinitialState, AnyAction>, TrackJSLogger),
                offline(offlineConfigEdited),
                migration
            )
        );
    }
}
