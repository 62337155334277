/*
 * Test Procedures Container
 */

import * as React from 'react';

import { Col } from 'react-bootstrap';
import { IinitialState } from '../../reducers';

import { TFunction } from 'i18next';
import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import { initialInstallBase, initialProduct } from '../../reducers/initialState';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IproductLink } from '../../models';
import { productLinkTypeEnum } from '../../models-enums';

interface Iprops {
    colorButton: string;
    installID: string;
}

const ProductLinks = ({ productLinks, t }: { productLinks: IproductLink[]; t: TFunction }) => {
    if (productLinks && productLinks.length) {
        return (
            <div>
                {/* TODO. If we need to reuuse these styles, consider adding a rule to main Sass style sheet */}
                <Col xs={12}>
                    <ul
                        style={{
                            listStyleType: 'none',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            padding: '20px 0'
                        }}
                    >
                        {productLinks.map(productLink => {
                            if (productLink.type === 0) {
                                return <li> {t('toastMessage:error')}</li>;
                            }
                            return (
                                <li
                                    key={productLink.id}
                                    style={{
                                        paddingBottom: '20px',

                                        fontSize: '16px',
                                        width: '100%'
                                    }}
                                >
                                    <a
                                        className="btn btn-default"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                            borderColor: 'rgb(63, 181, 252)',
                                            color: 'rgb(63, 181, 252)',
                                            width: '100%'
                                        }}
                                        href={productLink.url}
                                    >
                                        {productLink.type !== productLinkTypeEnum.other
                                            ? t(`manageInventory:${productLinkTypeEnum[productLink.type]}`)
                                            : productLink.other}
                                    </a>
                                </li>
                            );
                        })}
                    </ul>
                </Col>
            </div>
        );
    } else {
        return (
            <div>
                <Col xs={12}>
                    <h4>{t('leftColumn.noProductLinks')}</h4>
                </Col>
            </div>
        );
    }
};

const mapStateToProps = (state: IinitialState, ownProps: Iprops & WithTranslation) => {
    const selectedInstall = state.manageInventory.installBasesByID[ownProps.installID] || initialInstallBase;
    const { productID } = selectedInstall;
    const selectedProduct = state.manageInventory.productsByID[productID] || initialProduct;

    return {
        loading: selectIsLoading(state),
        productLinks: selectedProduct.links
    };
};

export const ProductLinksContainer = withTranslation('measurementPoints')(connect(mapStateToProps, {})(ProductLinks));
