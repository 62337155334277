/*
 * Edit Shopping Cart Container
 */

import { connect } from 'react-redux';

import {
    addToCart,
    clearCart,
    decreaseFromCart,
    deleteFromCart,
    saveMessageInput,
    updateQuantityCart
} from '../../actions/shoppingCartActions';
import { WithTranslation, withTranslation } from 'react-i18next';
import { saveQuoteCart, addPartToCart, deleteQuoteItemFromQuoteCart } from '../../actions/partsActions';
import { IinitialState } from '../../reducers';
import { selectIsLoading } from '../../reducers/commonReducers';
import { ShoppingCartForm } from '../common/ShoppingCart/ShoppingCartForm';
import { selectCartWithGroupedQuoteParts } from './PartUtilities';
import { withRouter } from 'react-router';
interface Iprops {
    colorButton: string;
    cartName: string;
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops & WithTranslation) => {
    const selectedCart = selectCartWithGroupedQuoteParts(state);

    return {
        className: 'shopping-cart',
        loading: selectIsLoading(state),
        selectedCart
    };
};

export const QuotePartShoppingCartContainer = withRouter(
    withTranslation('nsPart')(
        connect(mapStateToProps, {
            onSubmit: saveQuoteCart,
            addToCart,
            decreaseFromCart,
            deleteFromCart,
            updateQuantityCart,
            saveMessageInput,
            addPartToCart,
            deleteQuoteItemFromQuoteCart,
            clearCart
        })(ShoppingCartForm)
    )
);
