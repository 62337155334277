/*
 * PointListButtonBarContainer
 */

import { WithTranslation, withTranslation } from 'react-i18next';
import { clearHistoricalMPResult, clearHistoricalResultID } from '../../actions/measurementPointResultsActions';

import { IinitialState } from '../../reducers';
import { connect } from 'react-redux';
import { PointListButtonBar } from './PointListButtonBar';
import {
    toggleAddRepairWorkOrderModal,
    clearSelectedWorkOrderID,
    clearSelectedHistoricalWorkOrderID,
    toggleConfirmSelectJobModal,
    updateWorkOrder
} from '../../actions/workOrderActions';
import { getIsWorkOrderTypeJob } from '../../reducers/manageJobReducer';
import { initialJob } from '../../reducers/initialState';
import { selectIsLoading } from '../../reducers/commonReducers';

interface Iprops {
    handleCannotComplete: () => void;
    installID: string;
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops & WithTranslation) => {
    const isWorkOrderTypeJob = getIsWorkOrderTypeJob(state);
    const selectedJob = state.manageJob.jobsByID[state.manageJob.selectedJob.id] || initialJob;

    return {
        loading: selectIsLoading(state),
        selectedResult: state.measurementPointResults.selectedResult,
        historicalResultID: state.measurementPointResults.historicalResultID,
        historicalWorkOrderID: state.workOrder.historicalWorkOrderID,
        showTestProceduresButton: getIsWorkOrderTypeJob(state) === false,
        isWorkOrderTypeJob,
        jobTypeID: state.manageJob.selectedJob.jobTypeID || selectedJob.jobTypeID,
        installID: ownProps.installID
    };
};

export const PointListButtonBarContainer = withTranslation('measurementPoints')(
    connect(mapStateToProps, {
        updateWorkOrder,
        clearHistoricalResultID,
        toggleAddRepairWorkOrderModal,
        clearSelectedWorkOrderID,
        clearSelectedHistoricalWorkOrderID,
        toggleConfirmSelectJobModal,
        clearHistoricalMPResult
    })(PointListButtonBar)
);
