/*
 * Cannot Complete Comment Modal
 */
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { IinstallBasePopulated } from '../../models';
import { NotePad } from './NotePad';
import { bulkCannotCompleteSelected } from '../../actions/measurementPointResultsActions';
import { WithCommonModal } from '../common/WithCommonModal';
import { toggleModalNotePad } from '../../actions/manageInventoryActions';
import { IinitialState } from '../../reducers';

interface Iprops {
    colorButton: string;
    filteredInstallBases: IinstallBasePopulated[];
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops & WithTranslation) => {
    return {
        className: 'notepad-modal',
        show: state.manageInventory.showNoteModal,
        title: ownProps.t('cannotCompleteCommentModalTitle'),
        filteredInstallBases: ownProps.filteredInstallBases
    };
};

export default withTranslation('manageInventory')(
    connect(mapStateToProps, {
        bulkCannotCompleteSelected,
        toggleModalNotePad,
        toggleModal: toggleModalNotePad,
        onHide: toggleModalNotePad
    })(WithCommonModal(NotePad))
);
