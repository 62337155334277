/*
 * Manage Product Modal
 */
import { WithTranslation, withTranslation } from 'react-i18next';

import { Iproduct } from '../../models';
import {
    saveProduct,
    selectProductToAdd,
    toggleEditProductModal,
    updateProduct
} from '../../actions/manageInventoryActions';

import EditProductForm from './EditProductForm';
import { WithCommonModal } from '../common/WithCommonModal';
import { connect } from 'react-redux';
import { IinitialState } from '../../reducers';
import { selectIsLoading } from '../../reducers/commonReducers';

interface Iprops {
    colorButton: string;
    disabled?: boolean;
    modalClass?: string;
    secondModal?: boolean;
    selectedItem: Iproduct;
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops & WithTranslation) => {
    let title;

    if (ownProps.selectedItem && ownProps.selectedItem.id) {
        title = ownProps.t('manageInventory:editModalTitle');
    } else {
        title = ownProps.t('manageInventory:saveModalTitle');
    }

    const className = ownProps.secondModal ? 'user-edit second-modal' : 'user-edit';

    return {
        className,
        user: state.user,
        loading: selectIsLoading(state),
        show: state.manageInventory.showEditProductModal,
        productInfo: state.productInfo,
        tableFilters: state.manageInventory.tableFilters,
        secondModal: state.manageInventory.showSearchNewProductsModal,
        facility: state.facilities.facilitiesByID[state.manageJob.selectedJob.facilityID],
        title
    };
};

export default withTranslation('manageInventory')(
    connect(mapStateToProps, {
        toggleModal: toggleEditProductModal,
        updateProduct,
        selectProductToAdd,
        saveProduct,
        onHide: toggleEditProductModal
    })(WithCommonModal(EditProductForm))
);
