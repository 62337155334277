/*
 * Common Modal displays the modal
 */

import * as React from 'react';
import { Modal } from 'react-bootstrap';

export interface IPropsCommonModal extends React.Props<CommonModal> {
    show: boolean;
    onHide: () => void;
    className: string;
    footer?: JSX.Element;
    title: string | JSX.Element;
    container?: any;
    backdrop?: string | boolean;
    wideModal?: boolean;
    secondModal?: boolean;
    secondWideModal?: boolean; // used when first modal is wide and this is a second modal
    headerStyle?: object;
    enforceFocus?: boolean;
}

interface State {
    classNameAnimation: string;
    renderDelayActive: boolean; // improves performance and resolves an issue where a modal crashes on app launch when it is being shown and then immediately hidden
}

class CommonModal extends React.Component<IPropsCommonModal, State> {
    constructor(props: IPropsCommonModal) {
        super(props);
        this.state = {
            classNameAnimation: '',
            renderDelayActive: true
        };
    }
    componentDidMount() {
        setTimeout(() => {
            this.setState({ renderDelayActive: false });
        }, 1000);
    }

    render() {
        if (this.state.renderDelayActive) {
            return null;
        }
        let className = `${this.props.className} ${this.state.classNameAnimation} slide-modal from-left`;
        if (this.props.secondModal === true) {
            className = `${className} second-modal`;
        }
        if (this.props.wideModal === true) {
            className = `${className} wide-modal`;
        }
        if (this.props.secondWideModal === true) {
            className = `${className} second-wide-modal`;
        }
        const backdrop = this.props.backdrop ? this.props.backdrop : 'static';

        return (
            <Modal
                enforceFocus={this.props.enforceFocus}
                show={this.props.show}
                onHide={this.props.onHide}
                className={className}
                onExiting={() => {
                    this.setState({ classNameAnimation: '' });
                }}
                onEntering={() => {
                    this.setState({ classNameAnimation: 'after-open' });
                }}
                onEntered={() => {
                    setTimeout(() => {
                        this.setState({
                            classNameAnimation: 'after-open after-entered'
                        });
                    }, 200);
                }}
                container={
                    this.props.container || document.getElementById('two-pane-layout') || document.querySelector('.app')
                }
                backdrop={backdrop}
            >
                <Modal.Header style={this.props.headerStyle}>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.props.children}</Modal.Body>

                <Modal.Footer>{this.props.footer}</Modal.Footer>
            </Modal>
        );
    }
}

export default CommonModal;
