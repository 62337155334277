/*
 * MeasurementPointListContainer connects the MeasurementPointList form to redux
 */

import { WithTranslation, withTranslation } from 'react-i18next';

import {
    initSelectedSimpleResult,
    submitSimpleMeasurementPointResult,
    resetSelectedSimpleResult,
    updateSMprAnswers
} from '../../../actions/simpleMeasurementPointResultsActions';

import { toggleConfirmSelectJobModal } from '../../../actions/workOrderActions';
import {
    getAllMeasurementPointLists,
    setSelectedMeasurementPointListByID
} from '../../../actions/measurementPointListActions';
import { initialJob, initialMeasurementPointList } from '../../../reducers/initialState';

import { IinitialState } from '../../../reducers';
import SimpleMeasurementPointListForm from './SimpleMeasurementPointListForm';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { jobTypesIdEnum } from '../../../models-enums';
import { selectIsLoading } from '../../../reducers/commonReducers';
import queryString from 'query-string';
import { selectSimpleMeasurementPointList } from '../../../reducers/measurementPointListsReducer';

export enum simpleQueryParamsEnum {
    type = 'type'
}

const mapStateToProps = (state: IinitialState, ownProps: RouteComponentProps & WithTranslation) => {
    const queryParams = queryString.parse(ownProps.location.search) as typeof simpleQueryParamsEnum;

    const selectedJob = state.manageJob.jobsByID[state.manageJob.selectedJob.id] || initialJob;
    const checklistMode = selectedJob.jobTypeID === jobTypesIdEnum.maintenance;
    const facility = state.facilities.facilitiesByID[selectedJob.facilityID];
    // TODO change selectedMeasurementPointList to selectedMeasurementPointListOverride - for viewing historical results
    let selectedMeasurementPointList = initialMeasurementPointList;

    if (facility) {
        // facility might be undefined after an app upgrade
        selectedMeasurementPointList = selectSimpleMeasurementPointList(state, {
            measurementPointListType: queryParams?.type,
            standardID: facility.standardID,
            shouldShowWarning: true
        });
    }

    return {
        user: state.user,
        loading: selectIsLoading(state),
        allMeasurementPointLists: state.measurementPointLists.measurementPointListsByID,
        selectedJob,
        measurementPointsByID: state.measurementPointLists.measurementPointsByID,
        selectedSimpleResult: state.simpleMeasurementPointResults.selectedSimpleResult,
        measurementPointListsReducer: state.measurementPointLists,
        checklistMode,
        selectedMeasurementPointList,
        queryParams
    };
};

export const SimpleMeasurementPointListContainer = withTranslation('measurementPoints')(
    connect(mapStateToProps, {
        getAllMeasurementPointLists,
        submitSimpleMeasurementPointResult,
        updateSMprAnswers,
        initSelectedSimpleResult,
        setSelectedMeasurementPointListByID,
        toggleConfirmSelectJobModal,
        resetSelectedSimpleResult
    })(SimpleMeasurementPointListForm)
);
