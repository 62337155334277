/*
 * *********  Private Helper functions **********
 */

import { filter, isEmpty, keyBy, map, unionBy } from 'lodash';
import moment from 'moment';
import {
    ImeasurementPoint,
    ImeasurementPointAnswer,
    ImeasurementPointList,
    ImeasurementPointListTab,
    ImeasurementPointResult
} from '../models';
import {
    measurementPointPassFailTypes,
    measurementPointResultStatusTypesEnum,
    measurementPointTypesEnum
} from '../models-enums';
import { IinitialState } from '../reducers';
import { initialMeasurmentPointResult } from '../reducers/initialState';
import {
    selectJobDefaultForMeasurementPoint,
    selectMeasurementPointListForInstallBase,
    selectMeasurementPointsForTabID
} from '../reducers/measurementPointListsReducer';
import {
    selectMPLRsForJobAndMPL,
    selectMostRecentResult,
    selectMostRecentTemporaryResult
} from '../reducers/measurementPointResultsReducer';
const uuidv4 = require('uuid/v4');
/*
 ************   EXPORTS
 */

/*
 * when no results for the specific install, try to find results for the same measurementPointList
 */
export const prepareMeasurementPointListResults = (results: ImeasurementPointResult[]) => {
    return map(results, result => {
        return {
            ...result,
            measurementPointAnswers: map(result.measurementPointAnswers, answer => {
                if (Object.keys(answer).findIndex(key => key === 'numericValue') !== -1) {
                    return {
                        ...answer,
                        numericValue: Number(answer?.numericValue) ?? null
                    };
                }
                return {
                    ...answer
                };
            }),
            temporary: false,
            updateDate: moment.utc().toISOString()
        };
    });
};

/*
 *  filter MPs that are deleted, belong to a different customer, or are not required (Pass Fails are automatically required but do not have isRequired = true)
 */
export const filterMPsToValidate = (measurementPoints: { [key: string]: ImeasurementPoint }) => {
    return filter(
        measurementPoints,
        point =>
            point.isDeleted === false &&
            point.type !== measurementPointTypesEnum.group &&
            (point.isRequired === true || point.type === measurementPointTypesEnum.passFail)
    );
};

/*
 * pre-fill answers with defaults
 */
export const createDefaultAnswers = (
    measurementPointTabs: ImeasurementPointListTab[],
    getState: () => IinitialState
) => {
    let defaultMeasurementPointAnswers: ImeasurementPointAnswer[] = [];
    measurementPointTabs.forEach(tab => {
        const measurementPoints = selectMeasurementPointsForTabID(getState(), {
            tabID: tab.id
        });
        Object.values(measurementPoints).forEach(point => {
            const jobDefaultForPoint = selectJobDefaultForMeasurementPoint(getState(), { measurementPoint: point });
            const { passFail, select, numeric } = measurementPointTypesEnum;

            switch (point.type) {
                case numeric:
                    if (jobDefaultForPoint) {
                        defaultMeasurementPointAnswers = [
                            ...defaultMeasurementPointAnswers,
                            {
                                measurementPointID: point.id,
                                numericValue: jobDefaultForPoint.defaultMpAnswer.numericValue,
                                showInReport: jobDefaultForPoint.defaultMpAnswer.showInReport
                            }
                        ];
                    }
                    break;
                case passFail:
                    defaultMeasurementPointAnswers = [
                        ...defaultMeasurementPointAnswers,
                        {
                            measurementPointID: point.id,
                            pass: point.passFailDefault,
                            showInReport: point.showInReport
                        }
                    ];
                    break;
                case select:
                    defaultMeasurementPointAnswers = [
                        ...defaultMeasurementPointAnswers,
                        {
                            measurementPointID: point.id,
                            measurementPointSelectOptionID: point.selectDefaultOptionID,
                            showInReport: point.showInReport
                        }
                    ];

                    break;
                default:
            }
        });
    });
    return defaultMeasurementPointAnswers;
};

const getMostRecentResult = (results: ImeasurementPointResult[]) => {
    return results.reduce((previous, current) => {
        if (moment.utc(previous.updateDate).isAfter(moment.utc(current.updateDate))) {
            return previous;
        } else {
            return current;
        }
    });
};

const resetAnswerToDefault = (
    measurementPoint: ImeasurementPoint,
    answer: ImeasurementPointAnswer
): ImeasurementPointAnswer => {
    if (measurementPoint.type === measurementPointTypesEnum.passFail) {
        return { ...answer, notes: '', pass: measurementPoint.passFailDefault };
    } else if (measurementPoint.type === measurementPointTypesEnum.numeric) {
        return { ...answer, notes: '', numericValue: undefined };
    } else if (measurementPoint.type === measurementPointTypesEnum.date) {
        return { ...answer, notes: '', dateValue: undefined };
    } else if (measurementPoint.type === measurementPointTypesEnum.text) {
        return { ...answer, notes: '', textValue: undefined };
    } else if (measurementPoint.type === measurementPointTypesEnum.select) {
        return {
            ...answer,
            notes: '',
            measurementPointSelectOptionID: measurementPoint.selectDefaultOptionID
        };
    } else {
        return { ...answer, notes: '' };
    }
};

/*
 * Loop over the answers we received from a previous result from a similar device:
 *  clear notes
 *  for answers that we should not remember, clear or reset them to the default. (Pass/Fail and Selects have a default)
 * Don't remember if:
 ** rememberBetweenDevice = false - remember between installs that have the same MPL
 ** rememberBetweenInspection = false - remember between jobs
 * remove the Result Notes (comments)
 */
const cleanExistingResultAnswers = (
    result: ImeasurementPointResult,
    measurementPointsByID: { [key: string]: ImeasurementPoint },
    jobID: string,
    installBaseID: string
): ImeasurementPointResult => {
    const cleanedAnswers = result.measurementPointAnswers.map(answer => {
        const measurementPoint = measurementPointsByID[answer.measurementPointID];
        if (!measurementPoint) {
            console.error('[cleanExistingResultAnswers]: missing measurement point', measurementPoint, answer);
            return { ...answer, notes: '' };
        }

        if (
            measurementPoint.selectRememberBetweenDevice === undefined ||
            measurementPoint.selectRememberBetweenInspection === undefined
        ) {
            // reset answer to the default
            return resetAnswerToDefault(measurementPoint, answer);
        }

        if (
            measurementPoint.selectRememberBetweenDevice === true &&
            measurementPoint.selectRememberBetweenInspection === true
        ) {
            return { ...answer, notes: '' };
        } else if (
            measurementPoint.selectRememberBetweenDevice === false &&
            measurementPoint.selectRememberBetweenInspection === true &&
            installBaseID === result.installBaseID
        ) {
            return { ...answer, notes: '' };
        } else if (
            measurementPoint.selectRememberBetweenDevice === true &&
            measurementPoint.selectRememberBetweenInspection === false &&
            jobID === result.jobID
        ) {
            return { ...answer, notes: '' };
        } else {
            // reset answer to the default
            return resetAnswerToDefault(measurementPoint, answer);
        }
    });
    return {
        ...result,
        measurementPointAnswers: cleanedAnswers,
        notes: '',
        status: 0
    };
};

/*
 * cleanExistingInstallResultAnswers - loop over answers for this device and clean any answers that are from a different job if rememberBetweenInspections === false
 */
const cleanExistingInstallResultAnswers = (
    result: ImeasurementPointResult,
    measurementPointsByID: { [key: string]: ImeasurementPoint },
    jobID: string
): ImeasurementPointResult => {
    // if it is temporary then it has already been cleaned
    if (result.temporary) {
        return result;
    }
    const cleanedAnswers = result.measurementPointAnswers.map(answer => {
        const measurementPoint = measurementPointsByID[answer.measurementPointID];
        if (!measurementPoint) {
            console.error('[cleanExistingInstallResultAnswers]: missing measurement point', measurementPoint, answer);
        }

        if (jobID !== result.jobID && measurementPoint.selectRememberBetweenInspection === false) {
            return resetAnswerToDefault(measurementPoint, answer);
        } else {
            return answer;
        }
    });
    return {
        ...result,
        measurementPointAnswers: cleanedAnswers,
        notes: '',
        status: 0,
        previousStatus: result.status
    };
};

/*
 * getPreviousResultFromSameCategory
 * find a previous result for the same MPL and part of this job
 * this allows users to inspect one asset and then all similar assets will
 * default to these values
 */
const getPreviousResultFromSameCategory = (
    mpResults: ImeasurementPointResult[],
    installID: string,
    selectedMPL: ImeasurementPointList,
    measurementPointsByID: { [key: string]: ImeasurementPoint },
    jobID: string,
    state: IinitialState
): ImeasurementPointResult => {
    if (mpResults.length) {
        let previousResult: ImeasurementPointResult = initialMeasurmentPointResult;
        const MPLresults = selectMPLRsForJobAndMPL(state, {
            MPListID: selectedMPL.id
        });

        if (MPLresults.length) {
            const mostRecentMPLresult = getMostRecentResult(MPLresults);

            previousResult = cleanExistingResultAnswers(mostRecentMPLresult, measurementPointsByID, jobID, installID);
        }

        return previousResult;
    } else {
        return initialMeasurmentPointResult;
    }
};

/*
 * updateResultAnswerIDs
 * When creating new result, it cannot have the same answer IDs as a previous result
 */
const updateResultAnswerIDs = (resultAnswers: ImeasurementPointAnswer[]) => {
    return resultAnswers.map(answer => ({ ...answer, id: uuidv4() }));
};

/*
 * Copy a previous result to a new one
 */
const createNewResultFromPrevious = (
    previousResult: ImeasurementPointResult,
    jobID: string,
    jobTypeID: string,
    installBaseID: string,
    measurementPointTabs: ImeasurementPointListTab[],
    getState: () => IinitialState
): ImeasurementPointResult => {
    const existingAnswersWithNewIDs = updateResultAnswerIDs(previousResult.measurementPointAnswers) || [];
    const existingAnswersWithValues = filter(existingAnswersWithNewIDs, answer => {
        const hasNumeric = answer.numericValue !== undefined;
        const hasPass = answer.pass !== undefined;
        const hasDate = answer.dateValue !== undefined;
        const hasText = answer.textValue !== undefined;
        const hasOption = answer.measurementPointSelectOptionID !== undefined;

        return hasNumeric || hasPass || hasDate || hasText || hasOption;
    });
    const newAnswersWithIDs = updateResultAnswerIDs(createDefaultAnswers(measurementPointTabs, getState));

    // combine the answers, prefering existing ones with values
    // this helps when a MPL has been edited and a new question has been added
    const measurementPointAnswers = unionBy(existingAnswersWithValues, newAnswersWithIDs, 'measurementPointID');
    return {
        id: uuidv4(),
        installBaseID,
        measurementPointListID: previousResult.measurementPointListID,
        jobID,
        jobTypeID,
        temporary: true,
        notes: previousResult.notes ? previousResult.notes : '',
        status: measurementPointResultStatusTypesEnum.resultStatusNotTested,
        previousStatus: previousResult.previousStatus,
        createDate: moment.utc().toISOString(),
        updateDate: moment.utc().toISOString(),
        manualOverride: false,
        isDeleted: false,
        measurementPointAnswers,
        DamageCode: null
    };
};

/*
 *  create a completely new measurement point list result
 *  pre-fill answers with defaults
 */
const createNewResult = (
    jobID: string,
    installBaseID: string,
    measurementPointListID: string,
    measurementPointTabs: ImeasurementPointListTab[],
    getState: () => IinitialState
): ImeasurementPointResult => {
    const newAnswersWithIDs = updateResultAnswerIDs(createDefaultAnswers(measurementPointTabs, getState));

    return {
        ...initialMeasurmentPointResult,
        id: uuidv4(),
        installBaseID,
        measurementPointListID,
        jobID,
        temporary: true,
        createDate: moment.utc().toISOString(),
        updateDate: moment.utc().toISOString(),
        measurementPointAnswers: newAnswersWithIDs
    };
};

/*
 * If this is a pass fail, check if it is set to pass or if the defaultSelection is pass
 * If this is another type of measurement point, then simply check if it has an answer.
 */
const validateAnswer = (measurementPoint: ImeasurementPoint, answers: ImeasurementPointAnswer[]) => {
    let validError = '';
    const foundAnswer = answers.find(answer => answer.measurementPointID === measurementPoint.id);
    if (foundAnswer) {
        const { passFail, numeric, select, text, date, longText } = measurementPointTypesEnum;

        if (measurementPoint.type === passFail && foundAnswer.pass === measurementPointPassFailTypes.fail) {
            validError = `${measurementPoint.label} on this device failed previously.  Manual inspection required`;
        } else if (measurementPoint.isRequired) {
            if (measurementPoint.type === numeric && foundAnswer.numericValue === undefined) {
                validError = `${measurementPoint.label}: answer required.`;
            } else if (measurementPoint.type === text && foundAnswer.textValue === undefined) {
                validError = `${measurementPoint.label}: answer required.`;
            } else if (measurementPoint.type === select && foundAnswer.measurementPointSelectOptionID === undefined) {
                validError = `${measurementPoint.label}: answer required.`;
            } else if (measurementPoint.type === date && foundAnswer.dateValue === undefined) {
                validError = `${measurementPoint.label}: answer required.`;
            } else if (measurementPoint.type === longText && foundAnswer.textValue === undefined) {
                validError = `${measurementPoint.label}: answer required.`;
            }
        }
    } else {
        validError = `${measurementPoint.label}: answer required.`;
    }

    return validError;
};

/*
 * validateNewResult
 * Validate new result for passSelected()
 * loop over the measurement point tabs then the measurement points
 */
export const validateNewResult = (
    measurementPointList: ImeasurementPointList,
    newResult: ImeasurementPointResult,
    getState: () => IinitialState
) => {
    let resultErrorMessages: string[] = [];
    // if the previous result was a fail, then it is not valid for copying to the other devices in the room
    if (newResult.status === measurementPointResultStatusTypesEnum.resultStatusFail) {
        return ['most recent result for this category, '];
    }

    measurementPointList.measurementPointTabs.forEach(tab => {
        const MPsToValidate = filterMPsToValidate(
            selectMeasurementPointsForTabID(getState(), { tabID: tab.id })
            // customerID
        );
        MPsToValidate.forEach(point => {
            const resultErrors = validateAnswer(point, newResult.measurementPointAnswers);
            if (resultErrors.length) {
                resultErrorMessages = [
                    ...resultErrorMessages,
                    validateAnswer(point, newResult.measurementPointAnswers)
                ];
            }
        });
    });

    return resultErrorMessages;
};

/*
 * initInstallBaseResult
 * receives an installBaseID and creates a temporary result
 */
export const initInstallBaseResult = (getState: () => IinitialState, { installID }: { installID: string }) => {
    const {
        manageJob: { selectedJob },
        facilities: { facilitiesByID },
        manageInventory: { installBasesByID },
        measurementPointResults: { measurementPointResultsByID },
        measurementPointLists: { measurementPointsByID }
    } = getState();
    const facility = facilitiesByID[selectedJob.facilityID];
    const { standardID } = facility;
    const installBase = installBasesByID[installID];
    const filteredMPR = Object.values(measurementPointResultsByID).filter(result => result.jobID === selectedJob.id);
    const filteredMPRByID = keyBy(filteredMPR, 'id');
    const temporaryPreviousResultForInstall = selectMostRecentTemporaryResult(
        filteredMPRByID,
        installID,
        selectedJob.id,
        true
    );
    const selectedMeasurementPointList = selectMeasurementPointListForInstallBase(getState(), {
        productID: installBase.productID,
        standardID,
        shouldShowWarning: false
    });

    if (temporaryPreviousResultForInstall && temporaryPreviousResultForInstall.id.length) {
        return temporaryPreviousResultForInstall;
    } else {
        const previousResultForInstall = selectMostRecentResult(
            filteredMPRByID,
            installID,
            selectedMeasurementPointList.id
        );
        if (previousResultForInstall && previousResultForInstall.id.length) {
            return createNewResultFromPrevious(
                cleanExistingInstallResultAnswers(previousResultForInstall, measurementPointsByID, selectedJob.id),
                selectedJob.id,
                selectedJob.jobTypeID,
                installID,
                selectedMeasurementPointList.measurementPointTabs,
                getState
            );
        }
    }

    const previousResultFromSameCategory = getPreviousResultFromSameCategory(
        filteredMPR,
        installID,
        selectedMeasurementPointList,
        measurementPointsByID,
        selectedJob.id,
        getState()
    );
    const { installBaseID: previousInstallBaseId } = previousResultFromSameCategory;
    // unless current installBaseId isn't checked against the previous, this will go through and pull the previous results even if they are different.
    const fromPrevious = [
        !isEmpty(previousResultFromSameCategory),
        previousResultFromSameCategory.id.length,
        installID === previousInstallBaseId
    ].every(Boolean);

    if (fromPrevious) {
        return createNewResultFromPrevious(
            previousResultFromSameCategory,
            selectedJob.id,
            selectedJob.jobTypeID,
            installID,
            selectedMeasurementPointList.measurementPointTabs,
            getState
        );
    } else {
        return createNewResult(
            selectedJob.id,
            installID,
            selectedMeasurementPointList.id,
            selectedMeasurementPointList.measurementPointTabs,
            getState
        );
    }
};
