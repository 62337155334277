/*
 * Requested Part
 */

import { FormGroup } from 'react-bootstrap';
import { FieldConfig } from 'react-reactive-form';

import * as React from 'react';
import { TFunction } from 'i18next';

import { Ipart, IshoppingCart, IsuggestedPartPopulated } from '../../../models';
import { partTypeEnum } from '../../../models-enums';
import { AllPartsForm } from './AllPartsForm';
import { RecommendedPartsForm } from './RecommendedPartsForm';
import { RequestPartTable } from './RequestPartTable';
import { addPartToCart, deleteQuoteItemFromQuoteCart, saveQuoteCart } from '../../../actions/partsActions';
import { deleteFromCart } from '../../../actions/shoppingCartActions';

export interface ImetaNumberInputWithButton {
    label: string;
    placeholder?: string;
    buttonAction: (id: string) => void;
    id: string;
}

interface Iprops {
    loading?: boolean;
    colorButton?: string;
    selectData: IsuggestedPartPopulated[];
    tableData: IsuggestedPartPopulated[];
    installID: string;
    selectedCart: IshoppingCart;
    addPartToCart: typeof addPartToCart;
    deleteFromCart: typeof deleteFromCart;
    saveQuoteCart: typeof saveQuoteCart;
    deleteQuoteItemFromQuoteCart: typeof deleteQuoteItemFromQuoteCart;
    t: TFunction;
    disabled: boolean;
}
interface Istate {
    fieldConfig: FieldConfig;
    parts: Ipart[];
}

export class RequestPart extends React.Component<Iprops, Istate> {
    private formGroup: FormGroup | any;
    private subscription: any;
    constructor(props: Iprops) {
        super(props);
        this.state = {
            fieldConfig: { controls: {} },
            parts: []
        };
    }
    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
    handleSubmit = (part: Ipart, installID: string) => {
        this.props.addPartToCart(part, installID);
    };

    render() {
        const { t, loading, colorButton, selectData } = this.props;
        return (
            <>
                <h3 style={{ padding: ' 0 18px' }}>Request Parts</h3>

                <RecommendedPartsForm
                    t={t}
                    selectData={selectData}
                    onSubmit={this.handleSubmit}
                    installID={this.props.installID}
                    partType={partTypeEnum.default}
                    disabled={this.props.disabled}
                />
                <AllPartsForm
                    t={t}
                    partType={partTypeEnum.default}
                    onSubmit={this.handleSubmit}
                    installID={this.props.installID}
                    colorButton={colorButton}
                    loading={loading}
                    disabled={this.props.disabled}
                />
                <RequestPartTable
                    tableData={this.props.tableData}
                    t={t}
                    saveQuoteCartCB={this.props.saveQuoteCart}
                    selectedCart={this.props.selectedCart}
                    deleteFromCartCB={this.props.deleteFromCart}
                    deleteQuoteItemFromQuoteCartCB={this.props.deleteQuoteItemFromQuoteCart}
                    disabled={this.props.disabled}
                />
            </>
        );
    }
}
