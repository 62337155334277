/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import SignatureCanvas from 'react-signature-canvas';
import * as localForage from 'localforage';
import { Col, Button, FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import { TFunction } from 'i18next';
import { keyBy } from 'lodash';
import { toastr } from 'react-redux-toastr';

import { IinstallBasePopulated, Ijob, IjobSignature, Iuser } from '../../models';
import { constants } from '../../constants/constants';
import { dataURItoBlob } from '../../helpers/dataURItoBlob';
import { SignatureTypeEnum } from '../../models-enums';
import { submitJobSignature } from '../../actions/workOrderActions';
import { completeJobWithFinalCheck } from '../../actions/manageJobActions';

interface Iprops {
    toggleModal: () => void;
    toggleIsJobClosingWithSignature: () => void;
    submitSignature: typeof submitJobSignature;
    completeJobWithFinalCheck: typeof completeJobWithFinalCheck;
    t: TFunction;
    loading: boolean;
    selectedJob: Ijob;
    validateParts: boolean;
    user: Iuser;
    isJobClosingWithSignature: boolean;
    installBasesPopulated: IinstallBasePopulated[];
    selectedJobSignatures: IjobSignature[];
}

interface Istate {
    signatures: { [key: string]: IjobSignature };
    contractorRepresentativeName: string;
    contractorRepresentativeTitle: string;
    supervisingOfficerName: string;
    supervisingOfficerTitle: string;
    qualityControllerName: string;
    qualityControllerTitle: string;
    witnessedName: string;
    witnessedTitle: string;
}

interface Isignature {
    ref: any;
    type: SignatureTypeEnum;
    name: string;
    title: string;
}

const canvasDimensions = {
    width: 468,
    height: 200
};

const prevSignatureWrap = {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center'
};

class CommissioningDataSignaturePad extends React.Component<Iprops, Istate> {
    private contractorRepresentativeSignaturePad: any = {};
    private supervisingOfficerSignaturePad: any = {};
    private qualityControllerSignaturePad: any = {};
    private witnessedSignaturePad: any = {};

    constructor(props: any) {
        super(props);
        this.contractorRepresentativeSignaturePad = React.createRef();
        this.supervisingOfficerSignaturePad = React.createRef();
        this.qualityControllerSignaturePad = React.createRef();
        this.witnessedSignaturePad = React.createRef();

        this.state = {
            signatures: {},
            contractorRepresentativeName: '',
            contractorRepresentativeTitle: '',
            supervisingOfficerName: '',
            supervisingOfficerTitle: '',
            qualityControllerName: '',
            qualityControllerTitle: '',
            witnessedName: '',
            witnessedTitle: ''
        };
    }

    componentDidMount() {
        this.getSignatures(this.props.selectedJobSignatures).then(signatures => {
            this.setState({ signatures });
        });

        // Set the Signed by and Signed by Title of each signature if they were filled out
        this.props.selectedJobSignatures.forEach(signature => {
            if (signature.type === SignatureTypeEnum.ContractorRepresentative) {
                if (signature.signedBy !== undefined) {
                    this.setState({ contractorRepresentativeName: signature.signedBy! });
                }

                if (signature.signedByPosition !== undefined) {
                    this.setState({ contractorRepresentativeTitle: signature.signedByPosition! });
                }
            } else if (signature.type === SignatureTypeEnum.QualityController) {
                if (signature.signedBy !== undefined) {
                    this.setState({ qualityControllerName: signature.signedBy! });
                }

                if (signature.signedByPosition !== undefined) {
                    this.setState({ qualityControllerTitle: signature.signedByPosition! });
                }
            } else if (signature.type === SignatureTypeEnum.SupervisingOfficer) {
                if (signature.signedBy !== undefined) {
                    this.setState({ supervisingOfficerName: signature.signedBy! });
                }

                if (signature.signedByPosition !== undefined) {
                    this.setState({ supervisingOfficerTitle: signature.signedByPosition! });
                }
            } else if (signature.type === SignatureTypeEnum.Witnessed) {
                if (signature.signedBy !== undefined) {
                    this.setState({ witnessedName: signature.signedBy! });
                }

                if (signature.signedByPosition !== undefined) {
                    this.setState({ witnessedTitle: signature.signedByPosition! });
                }
            }
        });
    }

    closeModal = () => {
        if (this.props.isJobClosingWithSignature) {
            this.props.toggleIsJobClosingWithSignature();
        }
        this.props.toggleModal();
    };

    areSignaturesAvailable = (): { [key: string]: boolean } => {
        const signatures = this.state.signatures;
        const availableSignatures = [
            signatures.ContractorRepresentative,
            signatures.SupervisingOfficer,
            signatures.QualityController,
            signatures.Witnessed
        ];
        const all = availableSignatures.every(sig => Boolean(sig?.uri));
        const some = availableSignatures.some(sig => Boolean(sig?.uri));

        return {
            all,
            some
        };
    };

    areSummissionsValid(): boolean {
        const summissions = [
            this.contractorRepresentativeSignaturePad?.isEmpty(),
            this.supervisingOfficerSignaturePad?.isEmpty(),
            this.qualityControllerSignaturePad?.isEmpty(),
            this.witnessedSignaturePad?.isEmpty()
        ];

        return summissions.some((sub: boolean) => sub === false);
    }

    submit = () => {
        const isValid = this.areSignaturesAvailable().some || this.areSummissionsValid();

        if (!isValid) {
            toastr.error('Missing Form Data', constants.toastrError);

            return;
        }

        if (this.areSignaturesAvailable().all && this.props.isJobClosingWithSignature) {
            this.closeModal();
            this.props.completeJobWithFinalCheck();
        } else {
            const signatures: Isignature[] = [
                {
                    ref: this.contractorRepresentativeSignaturePad,
                    type: SignatureTypeEnum.ContractorRepresentative,
                    name: this.state.contractorRepresentativeName,
                    title: this.state.contractorRepresentativeTitle
                },
                {
                    ref: this.supervisingOfficerSignaturePad,
                    type: SignatureTypeEnum.SupervisingOfficer,
                    name: this.state.supervisingOfficerName,
                    title: this.state.supervisingOfficerTitle
                },
                {
                    ref: this.qualityControllerSignaturePad,
                    type: SignatureTypeEnum.QualityController,
                    name: this.state.qualityControllerName,
                    title: this.state.qualityControllerTitle
                },
                {
                    ref: this.witnessedSignaturePad,
                    type: SignatureTypeEnum.Witnessed,
                    name: this.state.witnessedName,
                    title: this.state.witnessedTitle
                }
            ].filter(signature => !signature.ref?.isEmpty());

            signatures.forEach((signature, index) => {
                const final = index === signatures.length - 1;
                const canvas = signature.ref as SignatureCanvas;

                let hasSignature = false;
                let signatureUri: string | undefined = undefined;

                // Safe check state
                if (this.state.signatures !== undefined && Object.keys(this.state.signatures).length > 0) {
                    // Check if the current signature we are looking at was previously created and is in state
                    const existingSignature = this.state.signatures[SignatureTypeEnum[signature.type]];
                    if (existingSignature !== undefined) {
                        signatureUri = this.state.signatures[SignatureTypeEnum[signature.type]].uri;
                        hasSignature = true;
                    }
                }

                // if the signature is already available, but name and title haven't been saved yet
                if (!canvas && Boolean(hasSignature)) {
                    this.props.submitSignature(
                        this.props.t,
                        this.props.selectedJob.id,
                        dataURItoBlob(signatureUri ?? ''),
                        signatureUri ?? '',
                        signature.type,
                        signature.name,
                        final,
                        undefined,
                        signature.title
                    );
                } else {
                    canvas?.getTrimmedCanvas().toBlob(blob => {
                        if (blob) {
                            this.props.submitSignature(
                                this.props.t,
                                this.props.selectedJob.id,
                                blob,
                                canvas?.toDataURL(),
                                signature.type,
                                signature.name,
                                final,
                                undefined,
                                signature.title
                            );
                        }
                    });
                }
            });
        }
    };

    clear = () => {
        this.setState({
            signatures: {},
            contractorRepresentativeName: '',
            contractorRepresentativeTitle: '',
            qualityControllerName: '',
            qualityControllerTitle: '',
            supervisingOfficerName: '',
            supervisingOfficerTitle: '',
            witnessedName: '',
            witnessedTitle: ''
        });
        this.contractorRepresentativeSignaturePad?.clear();
        this.supervisingOfficerSignaturePad?.clear();
        this.qualityControllerSignaturePad?.clear();
        this.witnessedSignaturePad?.clear();
    };

    getSignatures = async (selectedJobSignatures: IjobSignature[]) => {
        const withURI = await selectedJobSignatures.map(async signature => {
            const uri = await localForage.getItem<string>(signature.id);

            return { ...signature, uri: uri || undefined } as IjobSignature;
        });
        const signatuesWithImgs = await Promise.all(withURI);
        const byType: { [key: string]: IjobSignature } = keyBy(
            signatuesWithImgs,
            (signature: IjobSignature) => SignatureTypeEnum[signature.type]
        );

        return byType;
    };

    render() {
        const { loading, t } = this.props;
        const signatures = this.state.signatures;

        return (
            <div className="beacon-form">
                <Col xs={12}>
                    <FormGroup>
                        <ControlLabel>{t('contractorRepresentativeSignature')}</ControlLabel>
                        <FormControl
                            value={this.state.contractorRepresentativeName}
                            onChange={e =>
                                this.setState({ contractorRepresentativeName: (e.target as HTMLInputElement).value })
                            }
                            className="signature-control"
                            type="text"
                            placeholder={t('contractorRepresentativeName')}
                        ></FormControl>
                        <FormControl
                            value={this.state.contractorRepresentativeTitle}
                            onChange={e =>
                                this.setState({ contractorRepresentativeTitle: (e.target as HTMLInputElement).value })
                            }
                            className="signature-control"
                            type="text"
                            placeholder={t('contractorRepresentativeTitle')}
                        ></FormControl>

                        <div className="signaturePad">
                            {signatures.ContractorRepresentative?.uri ? (
                                <div
                                    style={{
                                        ...canvasDimensions,
                                        ...prevSignatureWrap
                                    }}
                                >
                                    <img src={signatures.ContractorRepresentative.uri} alt="" />
                                </div>
                            ) : (
                                <SignatureCanvas
                                    ref={ref => {
                                        this.contractorRepresentativeSignaturePad = ref;
                                    }}
                                    penColor="#333333"
                                    canvasProps={canvasDimensions}
                                />
                            )}
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>{t('supervisingOfficerSignature')}</ControlLabel>
                        <FormControl
                            value={this.state.supervisingOfficerName}
                            onChange={e =>
                                this.setState({ supervisingOfficerName: (e.target as HTMLInputElement).value })
                            }
                            className="signature-control"
                            type="text"
                            placeholder={t('supervisingOfficerName')}
                        ></FormControl>
                        <FormControl
                            value={this.state.supervisingOfficerTitle}
                            onChange={e =>
                                this.setState({ supervisingOfficerTitle: (e.target as HTMLInputElement).value })
                            }
                            className="signature-control"
                            type="text"
                            placeholder={t('supervisingOfficerTitle')}
                        ></FormControl>
                        <div className="signaturePad">
                            {signatures.SupervisingOfficer?.uri ? (
                                <div
                                    style={{
                                        ...canvasDimensions,
                                        ...prevSignatureWrap
                                    }}
                                >
                                    <img src={signatures.SupervisingOfficer.uri} alt="" />
                                </div>
                            ) : (
                                <SignatureCanvas
                                    ref={ref => {
                                        this.supervisingOfficerSignaturePad = ref;
                                    }}
                                    penColor="#333333"
                                    canvasProps={canvasDimensions}
                                />
                            )}
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>{t('qualityControllerSignature')}</ControlLabel>
                        <FormControl
                            value={this.state.qualityControllerName}
                            onChange={e =>
                                this.setState({ qualityControllerName: (e.target as HTMLInputElement).value })
                            }
                            className="signature-control"
                            type="text"
                            placeholder={t('qualityControllerName')}
                        ></FormControl>
                        <FormControl
                            value={this.state.qualityControllerTitle}
                            onChange={e =>
                                this.setState({ qualityControllerTitle: (e.target as HTMLInputElement).value })
                            }
                            className="signature-control"
                            type="text"
                            placeholder={t('qualityControllerTitle')}
                        ></FormControl>

                        <div className="signaturePad">
                            {signatures.QualityController?.uri ? (
                                <div
                                    style={{
                                        ...canvasDimensions,
                                        ...prevSignatureWrap
                                    }}
                                >
                                    <img src={signatures.QualityController.uri} alt="" />
                                </div>
                            ) : (
                                <SignatureCanvas
                                    ref={ref => {
                                        this.qualityControllerSignaturePad = ref;
                                    }}
                                    penColor="#333333"
                                    canvasProps={canvasDimensions}
                                />
                            )}
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>{t('witnessedSignature')}</ControlLabel>
                        <FormControl
                            value={this.state.witnessedName}
                            onChange={e => this.setState({ witnessedName: (e.target as HTMLInputElement).value })}
                            className="signature-control"
                            type="text"
                            placeholder={t('witnessedName')}
                        ></FormControl>
                        <FormControl
                            value={this.state.witnessedTitle}
                            onChange={e => this.setState({ witnessedTitle: (e.target as HTMLInputElement).value })}
                            className="signature-control"
                            type="text"
                            placeholder={t('witnessedTitle')}
                        ></FormControl>

                        <div className="signaturePad">
                            {signatures.Witnessed?.uri ? (
                                <div
                                    style={{
                                        ...canvasDimensions,
                                        ...prevSignatureWrap
                                    }}
                                >
                                    <img src={signatures.Witnessed.uri} alt="" />
                                </div>
                            ) : (
                                <SignatureCanvas
                                    ref={ref => {
                                        this.witnessedSignaturePad = ref;
                                    }}
                                    penColor="#333333"
                                    canvasProps={canvasDimensions}
                                />
                            )}
                        </div>
                    </FormGroup>
                </Col>
                <Col xs={12} className="form-buttons text-right">
                    <Button bsStyle="default" type="button" className="pull-left" onClick={this.closeModal}>
                        {t('common:cancel')}
                    </Button>
                    <Button
                        bsStyle="default"
                        type="button"
                        className="pull-left"
                        onClick={this.clear}
                        style={{
                            marginLeft: '1rem'
                        }}
                    >
                        {t('manageInventory:clearSignature')}
                    </Button>
                    <Button bsStyle="default" disabled={loading} onClick={this.submit}>
                        {t('common:save')}
                    </Button>
                </Col>
            </div>
        );
    }
}

export default CommissioningDataSignaturePad;
