import { connect } from 'react-redux';
import { IinitialState } from '../../reducers';
import { PhotoTable } from './PhotoTable';
import { withTranslation, WithTranslation } from 'react-i18next';
import { constants } from '../../constants/constants';
import { selectIsLoading } from '../../reducers/commonReducers';
import { getPhotos, updatePhoto, deletePhoto } from '../../actions/photosActions';
import { selectPhotosForTable } from '../../reducers/photosReducer';
import { getIsMobile } from '../../reducers/configReducer';

interface Iprops {
    colorButton?: string;
    installID: string;
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops & WithTranslation) => {
    return {
        loading: selectIsLoading(state),
        installBaseID: ownProps.installID,
        tableData: selectPhotosForTable(state, {
            installBaseID: ownProps.installID
        }),
        currentTile: constants.getTileByURL(window.location.pathname),
        isMobile: getIsMobile(state)
    };
};

export const PhotoTableContainer = withTranslation('manageWorkOrder')(
    connect(mapStateToProps, {
        getPhotos,
        updatePhoto,
        deletePhoto
    })(PhotoTable)
);
