import { filter, map, keyBy } from 'lodash';
import { createSelector } from 'reselect';
import { IinitialState } from '.';
import { initialWorkOrder } from './initialState';

export const getSelectedJobID = (state: IinitialState, props: { jobID?: string }) =>
    props?.jobID || state.manageJob.selectedJob.id;
export const getSelectedJob = (state: IinitialState) => state.manageJob.selectedJob;

export const selectJobWorkOrders = (state: IinitialState) => state.manageJob.jobWorkOrdersByID;
export const selectWorkOrdersByID = (state: IinitialState) => state.workOrder.workOrdersByID;

export const selectJobWorkOrdersWithWorkOrders = createSelector(
    [selectJobWorkOrders, selectWorkOrdersByID],
    (jobWorkOrdersByID, workOrdersByID) => {
        const filteredJobWorkOrders = filter(jobWorkOrdersByID, {
            isDeleted: false
        });
        const withWorkOrders = map(filteredJobWorkOrders, jwo => {
            return {
                ...jwo,
                workOrder: workOrdersByID[jwo.workOrderID] || initialWorkOrder
            };
        });
        return keyBy(withWorkOrders, 'id');
    }
);

/**
 * placing this here to avoid circular dependency
 */
export const selectJobWorkOrdersForJobID = createSelector(
    [selectJobWorkOrdersWithWorkOrders, getSelectedJobID],
    (jobWorkOrdersByID, jobID) => {
        return filter(jobWorkOrdersByID, { jobID, isDeleted: false });
    }
);

export const selectJobWorkOrdersForCurrentJob = createSelector(
    [selectJobWorkOrdersWithWorkOrders, getSelectedJob],
    (jobWorkOrdersByID, job) => {
        return filter(jobWorkOrdersByID, { jobID: job.id, isDeleted: false });
    }
);
