/*
 * Install Base History
 */

import * as React from 'react';

import { ListGroup, ListGroupItem } from 'react-bootstrap';

import { IMPLRwithMeasurementType, IWorkOrder, ImeasurementPointResult, Ijob } from '../../models';
import { TFunction } from 'i18next';
import {
    measurementPointResultStatusTypesEnum,
    workOrderTypesEnum,
    measurementPointListTypeEnum,
    jobTypesIdEnum
} from '../../models-enums';
import moment from 'moment';
import { clearHistoricalResultID, updateMeasurementPointResult } from '../../actions/measurementPointResultsActions';
import { clearSelectedHistoricalWorkOrderID, setSelectedHistoricalWorkOrderID } from '../../actions/workOrderActions';
import { ccWONotes } from '../../reducers/workOrderReducer';

interface Iprops {
    installBaseHistory: Array<IMPLRwithMeasurementType | IWorkOrder>;
    installBaseID: string;
    t: TFunction;
    updateMeasurementPointResult: typeof updateMeasurementPointResult;
    setSelectedHistoricalWorkOrderID: typeof setSelectedHistoricalWorkOrderID;
    clearHistoricalResultID: typeof clearHistoricalResultID;
    clearSelectedHistoricalWorkOrderID: typeof clearSelectedHistoricalWorkOrderID;
    loading: boolean;
    selectedJob: Ijob;
}

export const MeasurementPointListResultHistory = (props: Iprops) => {
    const { t } = props;
    const handleWorkOrderClick = (wo: IWorkOrder) => {
        props.setSelectedHistoricalWorkOrderID(wo.id);
    };
    const handleMPLRClick = (result: ImeasurementPointResult) => {
        props.updateMeasurementPointResult(result, true);
    };

    if (props.installBaseHistory.length) {
        return (
            <div className="result-history">
                <h4>{t('historyHeading')}</h4>
                <ListGroup>
                    {props.installBaseHistory.map(result => {
                        if (
                            (props.selectedJob.jobTypeID === jobTypesIdEnum.repair ||
                                props.selectedJob.jobTypeID === jobTypesIdEnum.maintenance ||
                                props.selectedJob.jobTypeID === jobTypesIdEnum.servicePlan ||
                                props.selectedJob.jobTypeID === jobTypesIdEnum.commissioning) &&
                            'vendor' in result &&
                            'closingNotes' in result
                        ) {
                            let { closingNotesList } = result as IWorkOrder;

                            // If for some reason the closingNotesList is not set, try to parse it from the closingNotes string
                            if (closingNotesList === null && result.closingNotes) {
                                closingNotesList = ccWONotes(JSON.parse(result.closingNotes));
                            }

                            return (
                                <ListGroupItem
                                    key={result.id}
                                    onClick={() => handleWorkOrderClick(result)}
                                    title={result.closingNotes}
                                    className="history"
                                >
                                    <span
                                        style={{
                                            minWidth: '8rem'
                                        }}
                                    >
                                        {moment
                                            .utc(result.closingDate)
                                            .local(true)
                                            .format('DD-MMM-YY LT')}
                                    </span>
                                    <span
                                        className="notes-column truncate"
                                        style={{
                                            alignItems: 'flex-start',
                                            display: closingNotesList ? 'flex' : 'block',
                                            flexDirection: 'column',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        {closingNotesList?.map((note, idx: number) => (
                                            <div key={idx}>{note.text}</div>
                                        ))}
                                    </span>
                                    <span
                                        className={`status ${workOrderTypesEnum[result.type]}`}
                                        style={{ marginLeft: '0.5rem' }}
                                    >
                                        {/* For service plan, print Maintain */}
                                        {result.type === workOrderTypesEnum.servicePlan &&
                                            t('manageWorkOrder:' + workOrderTypesEnum[2])}
                                        {/* For commissioning, print Commission */}
                                        {result.type === workOrderTypesEnum.commissioning &&
                                            t('manageWorkOrder:' + workOrderTypesEnum[5])}
                                        {/* For everything else, print it's matching name */}
                                        {result.type !== workOrderTypesEnum.servicePlan &&
                                            result.type !== workOrderTypesEnum.commissioning &&
                                            t('manageWorkOrder:' + workOrderTypesEnum[result.type])}
                                    </span>
                                </ListGroupItem>
                            );
                        } else if ('measurementPointListID' in result) {
                            // skip touchpoints because we want to only list the related work orders, not both
                            if (result.measurementPointListType === measurementPointListTypeEnum.touchpoint) {
                                return null;
                            }

                            // Same for service plan and commissioning, we want to only list the related work orders, not both
                            if (
                                result.measurementPointListType === measurementPointListTypeEnum.servicePlan ||
                                result.measurementPointListType === measurementPointListTypeEnum.commissioning
                            ) {
                                return null;
                            }

                            // If this historic item is not of the same job type, don't show the history
                            if (props.selectedJob.jobTypeID !== result.jobTypeID) {
                                return null;
                            }

                            return (
                                <ListGroupItem
                                    key={result.id}
                                    onClick={() => handleMPLRClick(result)}
                                    title={result.notes}
                                    className="history"
                                >
                                    <span>
                                        {moment
                                            .utc(result.updateDate)
                                            .local(true)
                                            .format('DD-MMM-YY LT')}
                                    </span>
                                    <span className="notes-column truncate">{result.notes}</span>
                                    <span className={`status ${measurementPointResultStatusTypesEnum[result.status]}`}>
                                        {t(
                                            'manageMeasurementPointLists:' +
                                                measurementPointResultStatusTypesEnum[result.status]
                                        )}
                                    </span>
                                </ListGroupItem>
                            );
                        } else {
                            return null;
                        }
                    })}
                </ListGroup>
            </div>
        );
    } else if (props.loading) {
        return (
            <div className="result-history">
                <h4>{t('historyHeading')}</h4>
                <p>{t('common:loadingText')}</p>
            </div>
        );
    } else {
        return (
            <div className="result-history">
                <h4>{t('historyHeading')}</h4>
                <p>{t('noInspectionMessage')}</p>
            </div>
        );
    }
};
