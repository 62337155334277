import * as types from '../actions/actionTypes';

import {
    IWorkOrder,
    Ijob,
    IjobPopulated,
    IjobWorkOrder,
    Iuser,
    IjobHour,
    IjobSignature,
    Ifacility,
    ItableFiltersParams,
    IdefaultReport
} from '../models';
import {
    createFormValuesWithName,
    createSelectedIDWithName,
    createTableFiltersWithName,
    createShowModalWithNamedType,
    offlineStatusSelector
} from './commonReducers';
import { filter, forEach, keyBy, map, omit, orderBy, pickBy, find, unionBy } from 'lodash';
import initialState, { initialJob, initialJobWorkOrder, initialFacility } from './initialState';
import { jobStatusEnum, jobTypesIdEnum, jobTypesIdEnumInverse } from '../models-enums';

import { FormUtil } from '../components/common/FormUtil';
import { IinitialState } from '.';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import moment from 'moment';
import { TFunction } from 'i18next';
import * as localForage from 'localforage';

export const isMeasurementBasedJob = (job: Ijob) => {
    return (
        job.jobTypeID === jobTypesIdEnum.inspection ||
        job.jobTypeID === jobTypesIdEnum.audit ||
        job.jobTypeID === jobTypesIdEnum.verification
    );
};
export const cleanJobObject = (job: any): Ijob => {
    const normalizedJob = omit(job, 'jobWorkOrders', 'facility', 'jobParts');
    return {
        ...initialJob,
        ...pickBy(normalizedJob, (property, key) => property !== null)
    };
};
export const cleanJobWorkOrder = (jobWorkOrder: any): IjobWorkOrder => {
    const normalizedJobWorkOrder = omit(jobWorkOrder, 'manageWorkOrder');
    return {
        ...initialJobWorkOrder,
        ...pickBy(normalizedJobWorkOrder, (property, key) => property !== null)
    };
};
export const cleanDefaultReportObject = (defaultReport: IdefaultReport) => {
    return {
        ...pickBy(defaultReport, (property, key) => property !== null)
    } as IdefaultReport;
};

/*
 * MANAGE JOB SELECTORS
 */

export const selectWorkOrdersByID = (state: IinitialState) => state.workOrder.workOrdersByID;
const getFSEUsers = (state: IinitialState) => state.manageJob.fseUsers;

export const selectActiveFseUsersByID = createSelector([getFSEUsers], users => {
    const filteredFSE = filter(users, {
        isDeleted: false,
        isActive: true
    });
    return keyBy(filteredFSE, 'id');
});

export const selectFseUserOptions = createSelector([selectActiveFseUsersByID], activeFSEUsers => {
    return FormUtil.convertToOptions(activeFSEUsers);
});

export const jobFinalCheckModalOn = (state: IinitialState) => state.manageJob.jobFinalCheckModal;
const getNextJobNumber = (state: IinitialState) => state.manageJob.nextJobNumber;
const getViewJobModal = (state: IinitialState) => state.manageJob.showViewJobModal;
const getAddFacilityModal = (state: IinitialState) => state.manageJob.showAddFacilityModal;
const getEditJobModal = (state: IinitialState) => state.manageJob.showEditJobModal;
const getSearchFacilityModal = (state: IinitialState) => state.manageJob.showSearchFacilityModal;
const getRiskAssessmentModal = (state: IinitialState) => state.manageJob.showRiskAssessmentModal;
const selectFacilities = (state: IinitialState) => state.facilities;
const selectTableFilters = (state: IinitialState) => state.manageJob.tableFilters;
const getSearchedFacilities = (state: IinitialState) => state.manageJob.searchedFacilities;
const getDefaultFacilityID = (state: IinitialState) => state.manageJob.defaultFacilityID;
const getJobsByID = (state: IinitialState) => state.manageJob.jobsByID;
const getOfflineFacilityStatus = (state: IinitialState) => state.offlineStatusByFacilityID;
const getOfflineJobStatus = (state: IinitialState) => state.offlineStatusByJobID;

function newFacilitiesReducer(state: { [key: string]: Ifacility } = {}, action: any): { [key: string]: Ifacility } {
    switch (action.type) {
        case types.GET_FACILITIES_SEARCH_SUCCESS:
            return action.facilities ? action.facilities : {};
        case types.EMPTY_SEARCHED_FACILITIES:
            return {};
        case types.USER_LOGOUT_SUCCESS:
            return {};
        default:
            return state;
    }
}

export const selectEditJobModal = createSelector([getEditJobModal], editJobModal => editJobModal);

export const selectJobModals = createSelector(
    [getViewJobModal, getAddFacilityModal, getEditJobModal, getSearchFacilityModal, getRiskAssessmentModal],
    (viewJobModal, addFacilityModal, editJobModal, searchFacilityModal, riskAssessmentModal) => {
        const anyModalOpen = [
            viewJobModal,
            addFacilityModal,
            editJobModal,
            searchFacilityModal,
            riskAssessmentModal
        ].some(modal => modal);

        return anyModalOpen;
    }
);

export const selectSearchFacilities = createSelector([getSearchedFacilities], searchedFacilities => searchedFacilities);

export const selectSearchFacilitiesByDefaultID = createSelector(
    [getSearchedFacilities, getDefaultFacilityID],
    (searchedFacilities, defaultFacilityID) => searchedFacilities[defaultFacilityID]
);

export const selectActiveOfflineJobs = createSelector(
    [getJobsByID, getOfflineFacilityStatus, getOfflineJobStatus],
    (jobsByID, offlineFacilityStatus, offlineJobStatus): Ijob[] => {
        return filter(jobsByID, job => {
            return (
                job.status !== jobStatusEnum.completed &&
                offlineStatusSelector(offlineFacilityStatus, offlineJobStatus, job)
            );
        });
    }
);

const escapeRegExp = (input: string) => {
    return input.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

export const filterJobs = createSelector(
    [selectFacilities, selectTableFilters, getJobsByID, getOfflineFacilityStatus, getOfflineJobStatus],
    (facilities, tableFilters, jobsByID, offlineFacilityStatus, offlineJobStatus) => {
        const { showCompleted, jobType, startDate, endDate, search } = tableFilters;

        // escape search string, because string.search will blow up if it contains special characters
        let searchString = search ? escapeRegExp(search.toLowerCase()) : '';
        const startDateMoment = moment.isMoment(startDate) ? startDate : moment(startDate);
        const endDateMoment = moment.isMoment(endDate) ? endDate : moment(endDate);

        return Object.values(jobsByID).reduce<IjobPopulated[]>((accumulator, job) => {
            let shouldInclude = true;
            const facility = facilities.facilitiesByID[job.facilityID] || initialFacility;
            const offlineStatus = offlineStatusSelector(offlineFacilityStatus, offlineJobStatus, job);

            const populatedJob = {
                ...job,
                facility,
                offlineStatus
            };

            if (
                (showCompleted === undefined && populatedJob.status === jobStatusEnum.completed) ||
                (showCompleted && showCompleted.value === false && populatedJob.status === jobStatusEnum.completed)
            ) {
                shouldInclude = false;
            }
            if (showCompleted && showCompleted.value === true && populatedJob.status !== jobStatusEnum.completed) {
                shouldInclude = false;
            }
            if (jobType && jobType.value !== populatedJob.jobTypeID) {
                shouldInclude = false;
            }
            if (startDate && startDateMoment.isAfter(moment.utc(populatedJob.startDate))) {
                shouldInclude = false;
            }
            if (endDate && endDateMoment.isBefore(moment.utc(populatedJob.endDate))) {
                shouldInclude = false;
            }

            if (searchString.length) {
                const inFacility = facility.name.toLowerCase().search(searchString) !== -1;
                const inJobNumber = populatedJob.jobNumber
                    ? populatedJob.jobNumber.toLowerCase().search(searchString) !== -1
                    : false;
                if (inFacility === false && inJobNumber === false) {
                    shouldInclude = false;
                }
            }
            if (job.isDeleted === true) {
                shouldInclude = false;
            }

            if (shouldInclude) {
                accumulator = [...accumulator, populatedJob];
            }

            return accumulator;
        }, []);
    }
);

export const filterJobsForSelect = (state: IinitialState, filters: { [key: string]: any }) => {
    return filter(state.manageJob.jobsByID, item => {
        if (
            filters.jobTypeID &&
            (Array.isArray(filters.jobTypeID)
                ? !filters.jobTypeID.includes(item.jobTypeID)
                : item.jobTypeID !== filters.jobTypeID)
        ) {
            return false;
        }
        if (filters.facilityID && item.facilityID !== filters.facilityID) {
            return false;
        }
        if (
            filters.measurementBasedOnly !== undefined &&
            filters.measurementBasedOnly === true &&
            isMeasurementBasedJob(item) === false
        ) {
            return false;
        }
        if (
            filters.measurementBasedOnly !== undefined &&
            filters.measurementBasedOnly === false &&
            isMeasurementBasedJob(item) === true
        ) {
            return false;
        }
        if (filters.open && item.status === jobStatusEnum.completed) {
            return false;
        }
        if (item.isDeleted === true) {
            return false;
        }

        return true;
    });
};

export const selectNextJobNumber = createSelector([getNextJobNumber], jobNumber => jobNumber);
/*
 * prepJobsForOptions: get jobs that are type repair, for the selected job facility and convert them into options
{
    facilityID,
    jobTypeID: jobTypesIdEnum.repair,
    open: true
}
 */
export const prepJobsForOptions = (
    state: IinitialState,
    { filters }: { filters: ItableFiltersParams },
    t: TFunction
) => {
    const filteredJobs = filterJobsForSelect(state, filters);
    const jobsWithName = map(filteredJobs, job => {
        const startDate = moment
            .utc(job.startDate)
            .local(true)
            .format('DD-MMM-YY');
        const jobType = t(`${jobTypesIdEnumInverse[job.jobTypeID as keyof typeof jobTypesIdEnumInverse]}`);

        const name = `${startDate} ${jobType} ${job.jobNumber} (${job.status})`;

        return { ...job, name };
    });

    return FormUtil.convertToOptions(orderBy(jobsWithName, res => moment.utc(res.startDate).unix(), 'desc'));
};

/*
 * getIsWorkOrderMode - if the job is type maintenance or repair, then it is a work order
 */
export const getIsWorkOrderTypeJob = (state: IinitialState) => {
    const selectedJobC = state.manageJob.jobsByID[state.manageJob.selectedJob.id] || initialJob;
    return isWorkOrderTypeJob(selectedJobC.jobTypeID);
};

export const isWorkOrderTypeJob = (jobTypeID: string) => {
    return (
        jobTypeID === jobTypesIdEnum.maintenance ||
        jobTypeID === jobTypesIdEnum.repair ||
        jobTypeID === jobTypesIdEnum.warrantyBM ||
        jobTypeID === jobTypesIdEnum.servicePlan ||
        jobTypeID === jobTypesIdEnum.commissioning
    );
};

export const getIsCommissioningTypeJob = (state: IinitialState) => {
    const selectedJobC = state.manageJob.jobsByID[state.manageJob.selectedJob.id] || initialJob;
    return selectedJobC.jobTypeID === jobTypesIdEnum.commissioning;
};

export const getIsAddWorkOrderMode = (state: IinitialState) => {
    const isAddWorkOrderMode =
        state.location.pathname === '/commission' ||
        state.location.pathname === '/repair' ||
        state.location.pathname === '/warranty' ||
        state.location.pathname === '/sap' ||
        state.location.pathname === '/unassigned-sap-workorders';
    return isAddWorkOrderMode;
};

export const getIsMaintenanceTypeJob = (state: IinitialState) => {
    const selectedJobC = state.manageJob.jobsByID[state.manageJob.selectedJob.id] || initialJob;
    return selectedJobC.jobTypeID === jobTypesIdEnum.maintenance;
};

export const getIsAgs = (state: IinitialState) => {
    const selectedJobC = state.manageJob.jobsByID[state.manageJob.selectedJob.id] || initialJob;
    return selectedJobC.jobTypeID === jobTypesIdEnum.agsRebalancing;
};

export const getIsVerification = (state: IinitialState) => {
    const selectedJobC = state.manageJob.jobsByID[state.manageJob.selectedJob.id] || initialJob;
    return selectedJobC.jobTypeID === jobTypesIdEnum.agsRebalancing;
};

export const getSelectedJob = (state: IinitialState) => state.manageJob.selectedJob;

export const selectSelectedJob = createSelector([getSelectedJob], job => job);

/*
 * REDUCERS
 */

function jobsByIDReducer(state: { [key: string]: Ijob } = {}, action: any): { [key: string]: Ijob } {
    switch (action.type) {
        case types.JOB_GET_ASSIGNED_SUCCESS: {
            let jobs = action.jobs;
            if (action.payload && action.payload.data && action.payload.data.job) {
                jobs = [action.payload.data.job];
            }

            const newJobs = map(jobs, job => {
                return cleanJobObject(job);
            });
            let keyedNewJobs: { [key: string]: Ijob } = {};

            keyedNewJobs = keyBy(newJobs, 'id');

            const updatedLocalJobs = Object.values(state).reduce((collector, job) => {
                if (keyedNewJobs[job.id]) {
                    return { ...collector, [job.id]: job };
                } else {
                    // did not find the local job in the jobs from the API
                    // as long as the jobs from the API are not completed, we should delete them locally
                    if (action.includeCompleted === false) {
                        return {
                            ...collector,
                            [job.id]: { ...job, isDeleted: true }
                        };
                    } else {
                        return { ...collector, [job.id]: job };
                    }
                }
            }, {});

            if (action.excludeJobHours) {
                const excludingJobHours = map(keyedNewJobs, (job, id) => {
                    const existingJobHours = state[id] ? state[id].jobHours : [];

                    return { ...job, jobHours: existingJobHours } as Ijob;
                });

                keyedNewJobs = keyBy(excludingJobHours, 'id');
            }

            return { ...updatedLocalJobs, ...keyedNewJobs };
        }
        case types.JOB_ADD_SUCCESS:
            return { ...state, [action.job.id]: action.job };
        case types.JOB_UPDATE: // clean it for when the job is received from /latest
            if (action.job && action.job.id) {
                return {
                    ...state,
                    [action.job.id]: cleanJobObject(action.job)
                };
            } else {
                console.error('[jobsByIDReducer]: invalid job object', action.job);
                return state;
            }
        case types.JOB_SAVE_HOUR: {
            const jobUpdatedHours = unionBy([action.jobHour], state[action.jobID].jobHours, 'id');
            return {
                ...state,
                [action.jobID]: {
                    ...state[action.jobID],
                    jobHours: jobUpdatedHours
                }
            };
        }
        case types.SET_JOB_COVER_LETTER:
            return {
                ...state,
                [action.payload.jobID]: { ...action.payload.job, coverLetter: action.payload.coverLetter }
            };
        case types.JOB_UPDATE_FSE_SUCCESS:
            const { id, assignedUser, assignedUserID, userJobs } = action.payload.data.value;
            return {
                ...state,
                [id]: {
                    ...state[id],
                    assignedUserID,
                    assignedUser,
                    userJobs
                }
            };
        case types.USER_LOGOUT_SUCCESS:
            return {};
        default:
            return state;
    }
}

function jobSignaturesByID(
    state: { [key: string]: IjobSignature } = {},
    action: any
): { [key: string]: IjobSignature } {
    switch (action.type) {
        case types.GET_JOB_SIGNATURE_SUCCESS: {
            const signatures = action.payload.data.map((signature: any) => {
                localForage.setItem(signature.id, `data:image/png;base64,${signature.imageBytes}`);

                return {
                    id: signature.id,
                    jobID: signature.jobID,
                    type: signature.signatureType,
                    signedBy: signature.signedBy,
                    signedByPosition: signature.signedByPosition
                } as IjobSignature;
            });

            const keyedSignatures = keyBy(signatures, 'id');

            return {
                ...state,
                ...keyedSignatures
            };
        }
        case types.ADD_JOB_SIGNATURE: {
            return {
                ...state,
                [action.signature.id]: action.signature
            };
        }
        case types.UPDATE_JOB_SIGNATURE: {
            const keyedUpdatedSignatures = keyBy(action.signatures, 'id');

            return keyedUpdatedSignatures;
        }
        default:
            return state;
    }
}

function defaultReportsByID(
    state: { [key: string]: IdefaultReport } = {},
    action: any
): { [key: string]: IdefaultReport } {
    switch (action.type) {
        case types.REPORT_MANAGE_GET_DEFAULT_SUCCESS: {
            const newData = map(action.reports, (defaultReport: IdefaultReport) => {
                return cleanDefaultReportObject(defaultReport);
            });
            return keyBy(newData, 'id');
        }
        default:
            return state;
    }
}

function jobTypes(state: any[] = [], action: any): any[] {
    switch (action.type) {
        case types.GET_JOBTYPES_SUCCESS:
            return action.jobTypes;
        case types.USER_LOGOUT_SUCCESS:
            return [];
        default:
            return state;
    }
}

function nextJobNumber(state: string = initialState.manageJob.nextJobNumber, action: any): string {
    switch (action.type) {
        case types.GET_NEXT_JOB_NUMBER_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}

function fseUsers(state: Iuser[] = [], action: any): Iuser[] {
    switch (action.type) {
        case types.GET_FSE_SUCCESS:
            return action.payload.data;
        case types.USER_LOGOUT_SUCCESS:
            return [];
        default:
            return state;
    }
}

function defaultFacilityIDReducer(state = '', action: any): string {
    switch (action.type) {
        case types.SET_DEFAULT_FACILITY_ID:
            return action.payload;
        default:
            return state;
    }
}

function selectedJobReducer(state: Ijob = initialJob, action: any): Ijob {
    switch (action.type) {
        case types.SET_SELECTED_JOB:
            return action.job as IjobPopulated;
        case types.JOB_UPDATE: {
            // this helps update the selectred job from /latest
            const foundJob = action.job && action.job.id === state.id;
            if (foundJob) {
                return cleanJobObject(action.job);
            } else {
                return state;
            }
        }
        case types.JOB_GET_ASSIGNED_SUCCESS: {
            let jobs = action.jobs;
            if (action.payload && action.payload.data && action.payload.data.job) {
                jobs = [action.payload.data.job];
            }
            const foundJobc = jobs.find((job: IjobPopulated) => job.id === state.id);

            if (foundJobc) {
                const cleanJob = cleanJobObject(foundJobc);

                if (action.excludeJobHours) {
                    const existingJobHours = state.jobHours || cleanJob.jobHours;

                    return { ...cleanJob, jobHours: existingJobHours } as Ijob;
                }

                return cleanJob;
            } else {
                return state;
            }
        }
        case types.JOB_SAVE_HOUR: {
            // safe to assume it is this job
            const jobUpdatedHours = unionBy([action.jobHour], state.jobHours, 'id');
            return { ...state, jobHours: jobUpdatedHours };
        }
        case types.CLEAR_SELECTED_JOB: {
            return initialJob;
        }
        case types.USER_LOGOUT_SUCCESS:
            return initialJob;
        default:
            return state;
    }
}

/*
 * jobWorkOrders relates jobs to work orders
 */
function jobWorkOrdersByIDReducer(
    state: { [key: string]: IjobWorkOrder } = initialState.manageJob.jobWorkOrdersByID,
    action: any
): { [key: string]: IjobWorkOrder } {
    switch (action.type) {
        case types.LOAD_WORKORDERS_SUCCESS: {
            let newJobWorkOrders: IjobWorkOrder[] = [];
            forEach(action.payload.data.result, (order: IWorkOrder) => {
                if (order.jobWorkOrders) {
                    newJobWorkOrders = [...newJobWorkOrders, ...map(order.jobWorkOrders, cleanJobWorkOrder)];
                }
            });
            return { ...state, ...keyBy(newJobWorkOrders, 'id') };
        }
        case types.JOB_GET_ASSIGNED_SUCCESS: {
            let jobs = action.jobs;
            if (action.payload && action.payload.data && action.payload.data.job) {
                jobs = [action.payload.data.job];
            }
            let newJobWorkOrdersB: IjobWorkOrder[] = [];
            forEach(jobs, (job: IjobPopulated) => {
                if (job.jobWorkOrders) {
                    newJobWorkOrdersB = [...newJobWorkOrdersB, ...map(job.jobWorkOrders, cleanJobWorkOrder)];
                }
            });
            return { ...state, ...keyBy(newJobWorkOrdersB, 'id') };
        }
        case types.JOB_UPDATE: {
            if (action.job && action.job.jobWorkOrders) {
                let updatedJobWorkOrders: IjobWorkOrder[] = [];
                forEach(action.job.jobWorkOrders, (jobWO: IjobWorkOrder) => {
                    updatedJobWorkOrders = [...updatedJobWorkOrders, jobWO];
                });
                return { ...state, ...keyBy(updatedJobWorkOrders, 'id') };
            } else {
                return state;
            }
        }
        case types.JOB_UPDATE_WORKORDERS: {
            if (action.jobWorkOrders) {
                let updatedJobWorkOrders: IjobWorkOrder[] = [];
                forEach(action.jobWorkOrders, (jobWO: IjobWorkOrder) => {
                    updatedJobWorkOrders = [...updatedJobWorkOrders, jobWO];
                });
                return { ...state, ...keyBy(updatedJobWorkOrders, 'id') };
            }

            return state;
        }
        case types.DELETE_JOB_WORKORDERS: {
            if (action.jobWorkOrderId) {
                let updatedJobWorkOrders: IjobWorkOrder[] = [];
                forEach(state, (jobWO: IjobWorkOrder) => {
                    if (jobWO.id !== action.jobWorkOrderId) {
                        updatedJobWorkOrders.push(jobWO);
                    }
                });
                return { ...keyBy(updatedJobWorkOrders, 'id') };
            } else {
                return state;
            }
        }
        case types.DELETE_WORKORDER: {
            const JWO = find(state, { workOrderID: action.workOrderID });
            if (JWO) {
                return {
                    ...state,
                    [JWO.id]: { ...JWO, isDeleted: action.isDeleted }
                };
            }
            return state;
        }
        case types.USER_LOGOUT_SUCCESS: {
            return initialState.manageJob.jobWorkOrdersByID;
        }
        default:
            return state;
    }
}

function openedInspectionJobIDsReducer(state: string[] = initialState.manageJob.openedInspectionJobIDs, action: any) {
    switch (action.type) {
        case types.ADD_OPENED_INSPECTION_JOB: {
            return [...state, action.payload];
        }
        default:
            return state;
    }
}
function openedMaintenanceJobIDsReducer(state: string[] = initialState.manageJob.openedMaintenanceJobIDs, action: any) {
    switch (action.type) {
        case types.ADD_OPENED_MAINTENANCE_JOB: {
            return [...state, action.payload];
        }
        default:
            return state;
    }
}

/*
 * tempJobHoursReducer
 */
function tempJobHoursReducer(state: { [key: string]: IjobHour } = initialState.manageJob.tempJobHours, action: any) {
    switch (action.type) {
        case types.SET_TEMP_JOB_HOURS: {
            return action.jobHours;
        }
        case types.USER_LOGOUT_SUCCESS: {
            return initialState.manageJob.tempJobHours;
        }
        default:
            return state;
    }
}

function jobFinalCheckModalReducer(state = false, action: any) {
    switch (action.type) {
        case types.SET_JOB_FINAL_CHECK_MODAL: {
            return action.payload;
        }
        default:
            return state;
    }
}

const manageJobReducer = combineReducers({
    jobsByID: jobsByIDReducer,
    jobSignaturesByID,
    defaultReportsByID,
    openedInspectionJobIDs: openedInspectionJobIDsReducer,
    openedMaintenanceJobIDs: openedMaintenanceJobIDsReducer,
    jobTypes,
    fseUsers,
    jobFinalCheckModal: jobFinalCheckModalReducer,
    nextJobNumber,
    defaultFacilityID: defaultFacilityIDReducer,
    selectedJob: selectedJobReducer,
    tempJobHours: tempJobHoursReducer,
    searchedFacilities: newFacilitiesReducer,
    selectedJobHourID: createSelectedIDWithName('JOB_HOUR_ID'),
    selectedJobIDForWorkOrder: createSelectedIDWithName('JOB_ID'),
    hourFormValues: createFormValuesWithName('HOUR'),
    jobFormValues: createFormValuesWithName('MANAGE_JOB'),
    showEditJobModal: createShowModalWithNamedType('EDIT_JOB'),
    showAddFacilityModal: createShowModalWithNamedType('ADD_FACILITY'),
    showSearchFacilityModal: createShowModalWithNamedType('SEARCH_FACILITY'),
    showRiskAssessmentModal: createShowModalWithNamedType('RISK_ASSESSMENT'),
    showViewJobModal: createShowModalWithNamedType('VIEW_JOB'),
    tableFilters: createTableFiltersWithName('MANAGE_JOB'),
    jobWorkOrdersByID: jobWorkOrdersByIDReducer
});

export default manageJobReducer;
