import * as React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { constants } from '../../constants/constants';
import { downloadAppState } from '../../actions/commonActions';

interface Iprops {
    online: boolean;
    doubleClick: typeof downloadAppState;
}

const OnlineIndicatorButton = (props: Iprops) => {
    return props.online ? (
        <span
            onDoubleClick={props.doubleClick}
            style={{
                color: constants.colors.green,
                fontSize: '20px',
                marginLeft: '20px'
            }}
        >
            <FontAwesomeIcon icon={['far', 'signal']} fixedWidth />
        </span>
    ) : (
        <span
            onDoubleClick={props.doubleClick}
            style={{
                color: constants.colors.red,
                fontSize: '20px',
                marginLeft: '20px'
            }}
        >
            <FontAwesomeIcon icon={['far', 'signal-alt-slash']} fixedWidth style={{ marginBottom: '-3px' }} />
        </span>
    );
};

export default OnlineIndicatorButton;
