import { IinitialState } from '.';
import * as types from '../actions/actionTypes';
import { IfacilityContact } from '../models';

interface contactsState {
    [key: string]: IfacilityContact[];
}

export default function contactsByFacilityReducer(state: contactsState = {}, action: any): contactsState {
    switch (action.type) {
        case types.SET_CONTACT_FOR_FACILITY:
            return { ...state, [action.id]: action.payload.data };
        case types.ADD_FACILITY_CONTACT:
            const facilityContacts = state[action.payload.facilityID];
            return { ...state, [action.payload.facilityID]: [...facilityContacts, action.payload] };
        case types.UPDATE_FACILITY_CONTACT:
            const facilityContactIndex = state[action.payload.facilityID].findIndex(fc => fc.id === action.payload.id);
            state[action.payload.facilityID][facilityContactIndex] = action.payload;
            return { ...state };
        case types.DELETE_FACILITY_CONTACT:
            const fcIndex = state[action.payload.facilityID].findIndex(
                fc => fc.id === action.payload.facilityContactID
            );
            state[action.payload.facilityID].splice(fcIndex, 1);
            return { ...state };
        default:
            return state;
    }
}
export const getContacts = (state: IinitialState) => state.contactsByFacility;
