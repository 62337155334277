/*
 * Edit Job Defaults Form
 */

import * as React from 'react';

import { Button, Col } from 'react-bootstrap';
import { FieldConfig, FormArray, FormGenerator, FormGroup, GroupProps } from 'react-reactive-form';
import { TFunction } from 'i18next';
import { debounce } from 'lodash';

import { FormUtil } from '../../common/FormUtil';
import { FormUtilMeasurementPoints } from '../../common/FormUtilMobile';
import { IcommissioningData, IcommissioningDataPayload } from '../../../models';
import { constants } from '../../../constants/constants';
import { saveCommissioningData } from '../../../actions/manageInventoryActions';
import { isInteger } from '../../../helpers/isStringNumber';

enum formFieldsEnum {
    agssMinPressureFlow = 'agssMinPressureFlow',
    agssPressureFlow = 'agssPressureFlow',
    agssSystemFlow = 'agssSystemFlow',
    agssTestFlow = 'agssTestFlow',
    certifiedGaugeNumber = 'certifiedGaugeNumber',
    cO2MinPressureFlow = 'cO2MinPressureFlow',
    cO2PressureFlow = 'cO2PressureFlow',
    cO2SystemFlow = 'cO2SystemFlow',
    cO2TestFlow = 'cO2TestFlow',
    mA4MinPressureFlow = 'mA4MinPressureFlow',
    mA4PressureFlow = 'mA4PressureFlow',
    mA4SystemFlow = 'mA4SystemFlow',
    mA4TestFlow = 'mA4TestFlow',
    n20MinPressureFlow = 'n20MinPressureFlow',
    n20PressureFlow = 'n20PressureFlow',
    n20SystemFlow = 'n20SystemFlow',
    n20TestFlow = 'n20TestFlow',
    o2MinPressureFlow = 'o2MinPressureFlow',
    o2PressureFlow = 'o2PressureFlow',
    o2SystemFlow = 'o2SystemFlow',
    o2TestFlow = 'o2TestFlow',
    saMinPressureFlow = 'saMinPressureFlow',
    saPressureFlow = 'saPressureFlow',
    saSystemFlow = 'saSystemFlow',
    saTestFlow = 'saTestFlow',
    vacMinPressureFlow = 'vacMinPressureFlow',
    vacPressureFlow = 'vacPressureFlow',
    vacSystemFlow = 'vacSystemFlow',
    vacTestFlow = 'vacTestFlow'
}

interface Iprops {
    toggleModal: () => void;
    saveCommissioningData: typeof saveCommissioningData;
    updateFormValue: (formValue: { [key: string]: any }) => void;
    t: TFunction;
    loading: boolean;
    colorButton: string;
    selectedJobID: string;
    existingCommissioningData: IcommissioningData | undefined;
}

interface Istate {
    fieldConfig: FieldConfig;
}

class CommissioningDataForm extends React.Component<Iprops, Istate> {
    private formGroup: FormGroup | any;
    private subscription: any;
    private updateFormValueDebounced: any;

    constructor(props: Iprops) {
        super(props);
        this.updateFormValueDebounced = debounce(this.props.updateFormValue, constants.formDebounceTime);
        this.state = {
            fieldConfig: this.buildFieldConfig()
        };
    }

    componentDidMount() {
        this.setState({
            fieldConfig: this.buildFieldConfig()
        });
    }

    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    formValuesToItem = (): IcommissioningDataPayload => {
        let formValues: { [key: string]: string | number } = this.formGroup.value;

        Object.keys(formValues).forEach((key: string) => {
            const value = formValues[key];

            switch (true) {
                case key === 'certifiedGaugeNumber':
                    formValues.certifiedGaugeNumber = value;
                    break;
                case isInteger(value as string):
                    formValues[key] = parseInt(value as string);
                    break;
                case !value:
                    formValues[key] = 0;
                    break;
                default:
                    formValues[key] = parseFloat(value as string);
                    break;
            }
        });

        return {
            jobID: this.props.selectedJobID,
            form: formValues
        };
    };

    buildFieldConfig = () => {
        const disabled = false;
        const isMobile = false;
        const fieldConfigControls = {
            agssMinPressureFlow: {
                render: FormUtilMeasurementPoints.numeric(isMobile),
                meta: {
                    label: 'manageInventory:commissioningDataValues.agssMinPressureFlow',
                    type: 'input',
                    columnWidths: {
                        mainCol: 6,
                        measurementPointCol: 8,
                        guideCol: 4
                    },
                    name: 'agssMinPressureFlow',
                    guideText: 'l/m',
                    isRequired: false
                },
                options: {
                    validators: [FormUtil.validators.isValidFloat]
                },
                formState: {
                    value: this.props.existingCommissioningData?.agssMinPressureFlow ?? '',
                    disabled
                }
            },
            agssPressureFlow: {
                render: FormUtilMeasurementPoints.numeric(isMobile),
                meta: {
                    label: 'manageInventory:commissioningDataValues.agssPressureFlow',
                    type: 'input',
                    columnWidths: {
                        mainCol: 6,
                        measurementPointCol: 8,
                        guideCol: 4
                    },
                    name: 'agssPressureFlow',
                    guideText: 'l/m',
                    isRequired: false
                },
                options: {
                    validators: [FormUtil.validators.isValidFloat]
                },
                formState: {
                    value: this.props.existingCommissioningData?.agssPressureFlow ?? '',
                    disabled
                }
            },
            agssSystemFlow: {
                render: FormUtilMeasurementPoints.numeric(isMobile),
                meta: {
                    label: 'manageInventory:commissioningDataValues.agssSystemFlow',
                    type: 'input',
                    columnWidths: {
                        mainCol: 6,
                        measurementPointCol: 8,
                        guideCol: 4
                    },
                    name: 'agssSystemFlow',
                    guideText: 'l/m',
                    isRequired: false
                },
                options: {
                    validators: [FormUtil.validators.isValidInteger]
                },
                formState: {
                    value: this.props.existingCommissioningData?.agssSystemFlow ?? '',
                    disabled
                }
            },
            agssTestFlow: {
                render: FormUtilMeasurementPoints.numeric(isMobile),
                meta: {
                    label: 'manageInventory:commissioningDataValues.agssTestFlow',
                    type: 'input',
                    columnWidths: {
                        mainCol: 6,
                        measurementPointCol: 8,
                        guideCol: 4
                    },
                    name: 'agssTestFlow',
                    guideText: 'l/m',
                    isRequired: false
                },
                options: {
                    validators: [FormUtil.validators.isValidInteger]
                },
                formState: {
                    value: this.props.existingCommissioningData?.agssTestFlow ?? '',
                    disabled
                }
            },
            cO2MinPressureFlow: {
                render: FormUtilMeasurementPoints.numeric(isMobile),
                meta: {
                    label: 'manageInventory:commissioningDataValues.cO2MinPressureFlow',
                    type: 'input',
                    columnWidths: {
                        mainCol: 6,
                        measurementPointCol: 8,
                        guideCol: 4
                    },
                    name: 'cO2MinPressureFlow',
                    guideText: 'l/m',
                    isRequired: false
                },
                options: {
                    validators: [FormUtil.validators.isValidFloat]
                },
                formState: {
                    value: this.props.existingCommissioningData?.cO2MinPressureFlow ?? '',
                    disabled
                }
            },
            cO2PressureFlow: {
                render: FormUtilMeasurementPoints.numeric(isMobile),
                meta: {
                    label: 'manageInventory:commissioningDataValues.cO2PressureFlow',
                    type: 'input',
                    columnWidths: {
                        mainCol: 6,
                        measurementPointCol: 8,
                        guideCol: 4
                    },
                    name: 'cO2PressureFlow',
                    guideText: 'l/m',
                    isRequired: false
                },
                options: {
                    validators: [FormUtil.validators.isValidFloat]
                },
                formState: {
                    value: this.props.existingCommissioningData?.cO2PressureFlow ?? '',
                    disabled
                }
            },
            cO2SystemFlow: {
                render: FormUtilMeasurementPoints.numeric(isMobile),
                meta: {
                    label: 'manageInventory:commissioningDataValues.cO2SystemFlow',
                    type: 'input',
                    columnWidths: {
                        mainCol: 6,
                        measurementPointCol: 8,
                        guideCol: 4
                    },
                    name: 'cO2SystemFlow',
                    guideText: 'l/m',
                    isRequired: false
                },
                options: {
                    validators: [FormUtil.validators.isValidInteger]
                },
                formState: {
                    value: this.props.existingCommissioningData?.cO2SystemFlow ?? '',
                    disabled
                }
            },
            cO2TestFlow: {
                render: FormUtilMeasurementPoints.numeric(isMobile),
                meta: {
                    label: 'manageInventory:commissioningDataValues.cO2TestFlow',
                    type: 'input',
                    columnWidths: {
                        mainCol: 6,
                        measurementPointCol: 8,
                        guideCol: 4
                    },
                    name: 'cO2TestFlow',
                    guideText: 'l/m',
                    isRequired: false
                },
                options: {
                    validators: [FormUtil.validators.isValidInteger]
                },
                formState: {
                    value: this.props.existingCommissioningData?.cO2TestFlow ?? '',
                    disabled
                }
            },
            mA4MinPressureFlow: {
                render: FormUtilMeasurementPoints.numeric(isMobile),
                meta: {
                    label: 'manageInventory:commissioningDataValues.mA4MinPressureFlow',
                    type: 'input',
                    columnWidths: {
                        mainCol: 6,
                        measurementPointCol: 8,
                        guideCol: 4
                    },
                    name: 'mA4MinPressureFlow',
                    guideText: 'l/m',
                    isRequired: false
                },
                options: {
                    validators: [FormUtil.validators.isValidFloat]
                },
                formState: {
                    value: this.props.existingCommissioningData?.mA4MinPressureFlow ?? '',
                    disabled
                }
            },
            mA4PressureFlow: {
                render: FormUtilMeasurementPoints.numeric(isMobile),
                meta: {
                    label: 'manageInventory:commissioningDataValues.mA4PressureFlow',
                    type: 'input',
                    columnWidths: {
                        mainCol: 6,
                        measurementPointCol: 8,
                        guideCol: 4
                    },
                    name: 'mA4PressureFlow',
                    guideText: 'l/m',
                    isRequired: false
                },
                options: {
                    validators: [FormUtil.validators.isValidFloat]
                },
                formState: {
                    value: this.props.existingCommissioningData?.mA4PressureFlow ?? '',
                    disabled
                }
            },
            mA4SystemFlow: {
                render: FormUtilMeasurementPoints.numeric(isMobile),
                meta: {
                    label: 'manageInventory:commissioningDataValues.mA4SystemFlow',
                    type: 'input',
                    columnWidths: {
                        mainCol: 6,
                        measurementPointCol: 8,
                        guideCol: 4
                    },
                    name: 'mA4SystemFlow',
                    guideText: 'l/m',
                    isRequired: false
                },
                options: {
                    validators: [FormUtil.validators.isValidInteger]
                },
                formState: {
                    value: this.props.existingCommissioningData?.mA4SystemFlow ?? '',
                    disabled
                }
            },
            mA4TestFlow: {
                render: FormUtilMeasurementPoints.numeric(isMobile),
                meta: {
                    label: 'manageInventory:commissioningDataValues.mA4TestFlow',
                    type: 'input',
                    columnWidths: {
                        mainCol: 6,
                        measurementPointCol: 8,
                        guideCol: 4
                    },
                    name: 'mA4TestFlow',
                    guideText: 'l/m',
                    isRequired: false
                },
                options: {
                    validators: [FormUtil.validators.isValidInteger]
                },
                formState: {
                    value: this.props.existingCommissioningData?.mA4TestFlow ?? '',
                    disabled
                }
            },
            n20MinPressureFlow: {
                render: FormUtilMeasurementPoints.numeric(isMobile),
                meta: {
                    label: 'manageInventory:commissioningDataValues.n20MinPressureFlow',
                    type: 'input',
                    columnWidths: {
                        mainCol: 6,
                        measurementPointCol: 8,
                        guideCol: 4
                    },
                    name: 'n20MinPressureFlow',
                    guideText: 'l/m',
                    isRequired: false
                },
                options: {
                    validators: [FormUtil.validators.isValidFloat]
                },
                formState: {
                    value: this.props.existingCommissioningData?.n20MinPressureFlow ?? '',
                    disabled
                }
            },
            n20PressureFlow: {
                render: FormUtilMeasurementPoints.numeric(isMobile),
                meta: {
                    label: 'manageInventory:commissioningDataValues.n20PressureFlow',
                    type: 'input',
                    columnWidths: {
                        mainCol: 6,
                        measurementPointCol: 8,
                        guideCol: 4
                    },
                    name: 'n20PressureFlow',
                    guideText: 'l/m',
                    isRequired: false
                },
                options: {
                    validators: [FormUtil.validators.isValidFloat]
                },
                formState: {
                    value: this.props.existingCommissioningData?.n20PressureFlow ?? '',
                    disabled
                }
            },
            n20SystemFlow: {
                render: FormUtilMeasurementPoints.numeric(isMobile),
                meta: {
                    label: 'manageInventory:commissioningDataValues.n20SystemFlow',
                    type: 'input',
                    columnWidths: {
                        mainCol: 6,
                        measurementPointCol: 8,
                        guideCol: 4
                    },
                    name: 'n20SystemFlow',
                    guideText: 'l/m',
                    isRequired: false
                },
                options: {
                    validators: [FormUtil.validators.isValidInteger]
                },
                formState: {
                    value: this.props.existingCommissioningData?.n20SystemFlow ?? '',
                    disabled
                }
            },
            n20TestFlow: {
                render: FormUtilMeasurementPoints.numeric(isMobile),
                meta: {
                    label: 'manageInventory:commissioningDataValues.n20TestFlow',
                    type: 'input',
                    columnWidths: {
                        mainCol: 6,
                        measurementPointCol: 8,
                        guideCol: 4
                    },
                    name: 'n20TestFlow',
                    guideText: 'l/m',
                    isRequired: false
                },
                options: {
                    validators: [FormUtil.validators.isValidInteger]
                },
                formState: {
                    value: this.props.existingCommissioningData?.n20TestFlow ?? '',
                    disabled
                }
            },
            o2MinPressureFlow: {
                render: FormUtilMeasurementPoints.numeric(isMobile),
                meta: {
                    label: 'manageInventory:commissioningDataValues.o2MinPressureFlow',
                    type: 'input',
                    columnWidths: {
                        mainCol: 6,
                        measurementPointCol: 8,
                        guideCol: 4
                    },
                    name: 'o2MinPressureFlow',
                    guideText: 'l/m',
                    isRequired: false
                },
                options: {
                    validators: [FormUtil.validators.isValidFloat]
                },
                formState: {
                    value: this.props.existingCommissioningData?.o2MinPressureFlow ?? '',
                    disabled
                }
            },
            o2PressureFlow: {
                render: FormUtilMeasurementPoints.numeric(isMobile),
                meta: {
                    label: 'manageInventory:commissioningDataValues.o2PressureFlow',
                    type: 'input',
                    columnWidths: {
                        mainCol: 6,
                        measurementPointCol: 8,
                        guideCol: 4
                    },
                    name: 'o2PressureFlow',
                    guideText: 'l/m',
                    isRequired: false
                },
                options: {
                    validators: [FormUtil.validators.isValidFloat]
                },
                formState: {
                    value: this.props.existingCommissioningData?.o2PressureFlow ?? '',
                    disabled
                }
            },
            o2SystemFlow: {
                render: FormUtilMeasurementPoints.numeric(isMobile),
                meta: {
                    label: 'manageInventory:commissioningDataValues.o2SystemFlow',
                    type: 'input',
                    columnWidths: {
                        mainCol: 6,
                        measurementPointCol: 8,
                        guideCol: 4
                    },
                    name: 'o2SystemFlow',
                    guideText: 'l/m',
                    isRequired: false
                },
                options: {
                    validators: [FormUtil.validators.isValidInteger]
                },
                formState: {
                    value: this.props.existingCommissioningData?.o2SystemFlow ?? '',
                    disabled
                }
            },
            o2TestFlow: {
                render: FormUtilMeasurementPoints.numeric(isMobile),
                meta: {
                    label: 'manageInventory:commissioningDataValues.o2TestFlow',
                    type: 'input',
                    columnWidths: {
                        mainCol: 6,
                        measurementPointCol: 8,
                        guideCol: 4
                    },
                    name: 'o2TestFlow',
                    guideText: 'l/m',
                    isRequired: false
                },
                options: {
                    validators: [FormUtil.validators.isValidInteger]
                },
                formState: {
                    value: this.props.existingCommissioningData?.o2TestFlow ?? '',
                    disabled
                }
            },
            saMinPressureFlow: {
                render: FormUtilMeasurementPoints.numeric(isMobile),
                meta: {
                    label: 'manageInventory:commissioningDataValues.saMinPressureFlow',
                    type: 'input',
                    columnWidths: {
                        mainCol: 6,
                        measurementPointCol: 8,
                        guideCol: 4
                    },
                    name: 'saMinPressureFlow',
                    guideText: 'l/m',
                    isRequired: false
                },
                options: {
                    validators: [FormUtil.validators.isValidFloat]
                },
                formState: {
                    value: this.props.existingCommissioningData?.saMinPressureFlow ?? '',
                    disabled
                }
            },
            saPressureFlow: {
                render: FormUtilMeasurementPoints.numeric(isMobile),
                meta: {
                    label: 'manageInventory:commissioningDataValues.saPressureFlow',
                    type: 'input',
                    columnWidths: {
                        mainCol: 6,
                        measurementPointCol: 8,
                        guideCol: 4
                    },
                    name: 'saPressureFlow',
                    guideText: 'l/m',
                    isRequired: false
                },
                options: {
                    validators: [FormUtil.validators.isValidFloat]
                },
                formState: {
                    value: this.props.existingCommissioningData?.saPressureFlow ?? '',
                    disabled
                }
            },
            saSystemFlow: {
                render: FormUtilMeasurementPoints.numeric(isMobile),
                meta: {
                    label: 'manageInventory:commissioningDataValues.saSystemFlow',
                    type: 'input',
                    columnWidths: {
                        mainCol: 6,
                        measurementPointCol: 8,
                        guideCol: 4
                    },
                    name: 'saSystemFlow',
                    guideText: 'l/m',
                    isRequired: false
                },
                options: {
                    validators: [FormUtil.validators.isValidInteger]
                },
                formState: {
                    value: this.props.existingCommissioningData?.saSystemFlow ?? '',
                    disabled
                }
            },
            saTestFlow: {
                render: FormUtilMeasurementPoints.numeric(isMobile),
                meta: {
                    label: 'manageInventory:commissioningDataValues.saTestFlow',
                    type: 'input',
                    columnWidths: {
                        mainCol: 6,
                        measurementPointCol: 8,
                        guideCol: 4
                    },
                    name: 'saTestFlow',
                    guideText: 'l/m',
                    isRequired: false
                },
                options: {
                    validators: [FormUtil.validators.isValidInteger]
                },
                formState: {
                    value: this.props.existingCommissioningData?.saTestFlow ?? '',
                    disabled
                }
            },
            vacMinPressureFlow: {
                render: FormUtilMeasurementPoints.numeric(isMobile),
                meta: {
                    label: 'manageInventory:commissioningDataValues.vacMinPressureFlow',
                    type: 'input',
                    columnWidths: {
                        mainCol: 6,
                        measurementPointCol: 8,
                        guideCol: 4
                    },
                    name: 'vacMinPressureFlow',
                    guideText: 'l/m',
                    isRequired: false
                },
                options: {
                    validators: [FormUtil.validators.isValidFloat]
                },
                formState: {
                    value: this.props.existingCommissioningData?.vacMinPressureFlow ?? '',
                    disabled
                }
            },
            vacPressureFlow: {
                render: FormUtilMeasurementPoints.numeric(isMobile),
                meta: {
                    label: 'manageInventory:commissioningDataValues.vacPressureFlow',
                    type: 'input',
                    columnWidths: {
                        mainCol: 6,
                        measurementPointCol: 8,
                        guideCol: 4
                    },
                    name: 'vacPressureFlow',
                    guideText: 'l/m',
                    isRequired: false
                },
                options: {
                    validators: [FormUtil.validators.isValidFloat]
                },
                formState: {
                    value: this.props.existingCommissioningData?.vacPressureFlow ?? '',
                    disabled
                }
            },
            vacSystemFlow: {
                render: FormUtilMeasurementPoints.numeric(isMobile),
                meta: {
                    label: 'manageInventory:commissioningDataValues.vacSystemFlow',
                    type: 'input',
                    columnWidths: {
                        mainCol: 6,
                        measurementPointCol: 8,
                        guideCol: 4
                    },
                    name: 'vacSystemFlow',
                    guideText: 'l/m',
                    isRequired: false
                },
                options: {
                    validators: [FormUtil.validators.isValidInteger]
                },
                formState: {
                    value: this.props.existingCommissioningData?.vacSystemFlow ?? '',
                    disabled
                }
            },
            vacTestFlow: {
                render: FormUtilMeasurementPoints.numeric(isMobile),
                meta: {
                    label: 'manageInventory:commissioningDataValues.vacTestFlow',
                    type: 'input',
                    columnWidths: {
                        mainCol: 6,
                        measurementPointCol: 8,
                        guideCol: 4
                    },
                    name: 'vacTestFlow',
                    guideText: 'l/m',
                    isRequired: false
                },
                options: {
                    validators: [FormUtil.validators.isValidInteger]
                },
                formState: {
                    value: this.props.existingCommissioningData?.vacTestFlow ?? '',
                    disabled
                }
            },
            certifiedGaugeNumber: {
                render: FormUtilMeasurementPoints.numeric(isMobile),
                meta: {
                    label: 'manageInventory:commissioningDataValues.certifiedGaugeNumber',
                    type: 'input',
                    columnWidths: {
                        mainCol: 6,
                        measurementPointCol: 8,
                        guideCol: 4
                    },
                    name: 'certifiedGaugeNumber',
                    isRequired: false
                },
                formState: {
                    value: this.props.existingCommissioningData?.certifiedGaugeNumber ?? '',
                    disabled
                }
            }
        } as { [key in formFieldsEnum]: GroupProps };
        const fieldConfig = {
            controls: { ...fieldConfigControls }
        };

        return FormUtil.translateForm(fieldConfig, this.props.t);
    };

    subscribeToChanges = () => {
        for (const key in this.formGroup.controls) {
            if (this.formGroup.controls.hasOwnProperty(key)) {
                this.subscription = this.formGroup.get(key).valueChanges.subscribe((value: any) => {
                    this.onValueChanges(value, key);
                });
            }
        }
    };

    onValueChanges = (value: any, key: string) => {
        switch (key) {
            default:
                this.updateFormValueDebounced({ [key]: value });
                break;
        }
    };

    handleSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
        e.preventDefault();
        this.props.saveCommissioningData(this.formValuesToItem());

        this.props.toggleModal();
    };

    setForm = (form: FormGroup | FormArray) => {
        this.formGroup = form;
        this.formGroup.meta = {
            loading: this.props.loading
        };
        if (!this.subscription) {
            setTimeout(() => {
                this.subscribeToChanges();
            }, 300);
        }
    };

    render() {
        const { t } = this.props;

        const formClassName = `clearfix job-form beacon-form ${this.props.colorButton}`;

        return (
            <div className={formClassName}>
                <form onSubmit={this.handleSubmit} className={formClassName}>
                    <FormGenerator onMount={this.setForm} fieldConfig={this.state.fieldConfig} />
                    <Col xs={12} className="form-buttons text-right">
                        <Button
                            bsStyle="default"
                            type="button"
                            className="pull-left"
                            onClick={() => {
                                this.props.toggleModal();
                            }}
                        >
                            {t('cancel')}
                        </Button>
                        <Button bsStyle={this.props.colorButton} type="submit" disabled={this.props.loading}>
                            {t('save')}
                        </Button>
                    </Col>
                </form>
            </div>
        );
    }
}

export default CommissioningDataForm;
