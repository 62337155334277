/*
 * Measurement Point List Layout sets up the two column full screen view for filling out Measurement Points
 */

import * as React from 'react';

import { Col, Row } from 'react-bootstrap';
import {
    getFilteredInstallBasesNotComplete,
    makeSelectInstallBasesPopulated
} from '../../reducers/manageInventoryReducer';
import { measurementPointResultStatusTypesEnum, partTypeEnum, workOrderStatusEnumForFilter } from '../../models-enums';

import { IinitialState } from '../../reducers';
import { IinstallBase, IinstallBasePopulated, Ipart, ItableFiltersParams } from '../../models';
import { LeftColumnContainer } from './LeftColumnContainer';
import { MeasurementPointListContainer } from './MeasurementPointListContainer';
import { RouteComponentProps } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import ConfirmSelectJobModal from '../workOrder/ConfirmSelectJobModal';
import { getIsWorkOrderTypeJob } from '../../reducers/manageJobReducer';
import { SearchAllPartsModalandContainer } from '../parts/SearchAllPartsModalandContainer';
import { suggestedPartsQueryParamsEnum } from '../parts/SuggestedParts';
import { constants } from '../../constants/constants';
import queryString from 'query-string';
import { savePartAsInstallBasePart } from '../../actions/partsActions';
import { getIsMobile } from '../../reducers/configReducer';
import MobileLayout from './Mobile/Layout';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Iprops extends RouteComponentProps<{ installID: string }> {
    // t: TFunction;
}

interface IdispatchProps {
    installBasesNotTested: IinstallBasePopulated[];
    installBaseIDsNotTested: string[];
    showAddRepairWorkOrderModal: boolean;
    installBase: IinstallBase;
    facilityID: string;
    queryParams: typeof suggestedPartsQueryParamsEnum;
    savePartAsInstallBasePart: typeof savePartAsInstallBasePart;
}

const MeasurementPointListLayoutC = (props: Iprops & IdispatchProps) => {
    const colorButton = constants.colors[`blueButton`];
    const handlePartSearchSubmit = (part: Ipart) => {
        props.savePartAsInstallBasePart(part, props.match.params.installID, part.quantity);
    };

    const isMobile: boolean = useSelector(getIsMobile);
    if (isMobile) {
        return <MobileLayout {...props} />;
    }

    return (
        <Row>
            <Col xs={4} style={{ paddingRight: '0' }}>
                <LeftColumnContainer {...props} />
            </Col>
            <Col xs={8} className="mp-right-column">
                <MeasurementPointListContainer {...props} />
            </Col>
            <ConfirmSelectJobModal
                selectedInstallID={props.match.params.installID}
                facilityID={props.facilityID}
                history={props.history}
                location={props.location}
                match={props.match}
                forSAP={false}
            />
            <SearchAllPartsModalandContainer
                history={props.history}
                queryParams={props.queryParams}
                colorButton={colorButton}
                onSubmit={handlePartSearchSubmit}
                shouldShowQuantity={true}
                partType={partTypeEnum.default}
            />
        </Row>
    );
};

const makeMapStateToProps = () => {
    const mapStateToProps = (state: IinitialState, ownProps: Iprops) => {
        const queryParams = queryString.parse(ownProps.location.search) as typeof suggestedPartsQueryParamsEnum;
        const isWorkOrderTypeJob = getIsWorkOrderTypeJob(state);
        let tableFilterOverrides: ItableFiltersParams = {
            measurementPointListResultStatus: {
                value: measurementPointResultStatusTypesEnum.resultStatusNotTested,
                label: ''
            }
        };

        if (isWorkOrderTypeJob) {
            tableFilterOverrides = {
                workOrderResultStatus: {
                    value: workOrderStatusEnumForFilter.open,
                    label: ''
                }
            };
        }

        const installBasesNotTested = getFilteredInstallBasesNotComplete(state);
        const installBaseIDsNotTested = installBasesNotTested.map(install => install.id);
        const installBase = state.manageInventory.installBasesByID[ownProps.match.params.installID];
        const facilityID = state.manageJob.selectedJob.facilityID;

        return {
            installBasesNotTested,
            installBaseIDsNotTested,
            showAddRepairWorkOrderModal: state.workOrder.showAddRepairWorkOrderModal,
            installBase,
            facilityID,
            queryParams
        };
    };
    return mapStateToProps;
};

export const MeasurementPointListLayout = connect(makeMapStateToProps, {
    savePartAsInstallBasePart
})(MeasurementPointListLayoutC);
