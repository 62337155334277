import { AbstractControl } from 'react-reactive-form';

export const getAllFormValues = (
    forms: { [key: string]: AbstractControl },
    shouldValidate: boolean,
    errorCallback?: (key: any, erroredMeasurementPoint: any) => void
): { formStatus: string; formValues: { [key: string]: any } } => {
    let formValues = {};
    let formStatus = '';

    let lastFormKeyWithError = null;
    let lastMeasurementPointWithError = null;
    Object.keys(forms).forEach(key => {
        const form: any = forms[key];
        if (shouldValidate && form?.status === 'INVALID') {
            formStatus = form?.status;
            form?.markAsSubmitted();
            lastFormKeyWithError = key;

            // Find invalid meaasurement point.
            Object.values(form.controls).map((formControl: any) => {
                if (formControl?.status === 'INVALID') {
                    lastMeasurementPointWithError = formControl?.meta?.label;
                }
            });
        }
        if (!form?.value) {
            return;
        }
        formValues = { ...formValues, ...form.value };
    });

    if (errorCallback && lastFormKeyWithError) {
        errorCallback(lastFormKeyWithError, lastMeasurementPointWithError);
    }

    return { formStatus, formValues };
};
