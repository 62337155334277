import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LinkContainer } from 'react-router-bootstrap';
import { connect, useSelector } from 'react-redux';
import { selectInstallBaseHistoryByInstallBaseID, selectIsLoading } from '../../../reducers/commonReducers';
import {
    Ifacility,
    IinstallBase,
    IinstallBasePopulated,
    Ijob,
    ImeasurementPointResult,
    IMPLRwithMeasurementType,
    IproductInfo,
    IWorkOrder
} from '../../../models';
import { RouteComponentProps } from 'react-router-dom';
import { installContact, toggleEditInstallModal } from '../../../actions/manageInventoryActions';
import { getAllMeasurementPointLists } from '../../../actions/measurementPointListActions';
import {
    clearHistoricalResultID,
    getMeasurementPointListResultHistory,
    updateMeasurementPointResult
} from '../../../actions/measurementPointResultsActions';
import {
    clearSelectedHistoricalWorkOrderID,
    setSelectedHistoricalWorkOrderID
} from '../../../actions/workOrderActions';
import { withTranslation } from 'react-i18next';
import {
    initialFacility,
    initialInstallBase,
    initialInstallBasePopulated,
    initialProduct
} from '../../../reducers/initialState';
import { IinitialState } from '../../../reducers';
import { getProducts } from '../../../reducers/manageInventoryReducer';
import { constants } from '../../../constants/constants';
import { LeftColumnContainer } from '../LeftColumnContainer';
import { MeasurementPointListContainer } from '../MeasurementPointListContainer';
import { suggestedPartsQueryParamsEnum } from '../../parts/SuggestedParts';
import { savePartAsInstallBasePart } from '../../../actions/partsActions';
import { TableUtil } from '../../common/TableUtil';

const Navigation = styled.div`
    display: flex;
    flex-direction: row;
    background: #007bc3;
    width: 100%;
    padding-left: 8px;
    padding-right: 8px;
    grid-row-start: 1;
`;

const Layout = styled.div`
    display: grid;
    grid-auto-flow: row dense;
    grid-template-rows: 40px 100px 41px 1fr;

    > ${MeasurementPointListContainer} {
        overflow: hidden;
    }
`;

type NavTabProps = {
    isActive: boolean;
};

const NavigationTab = styled.div`
    width: 50%;
    background: ${(props: NavTabProps) => (props.isActive ? '#013564' : '#007BC3')};
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;

    user-select: none;
    cursor: pointer;

    &:hover {
        background: #0136645c;
    }
`;

const AssetTitleBar = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 10px;
    gap: 16px;
    text-align: center

    height: 100%;
    background: #f1f1f1;

    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 120%;

    div.text-container{
        display: flex;
        flex-direction: column;
        gap: 8px;
        text-align: center;
    }

    span.location{
        font-weight: normal;
    }
`;

interface Iprops extends RouteComponentProps<{ installID: string }> {
    installBasesNotTested: IinstallBasePopulated[];
}

interface IdispatchProps {
    selectedInstall: IinstallBasePopulated;
    getAllMeasurementPointLists: typeof getAllMeasurementPointLists;
    loading: boolean;
    productInfo: IproductInfo;
    selectedJob: Ijob;
    facility: Ifacility;
    toggleEditInstallModal: typeof toggleEditInstallModal;
    updateMeasurementPointResult: typeof updateMeasurementPointResult;
    installBaseHistory: Array<IMPLRwithMeasurementType | IWorkOrder>;
    selectedResult: ImeasurementPointResult;
    setSelectedHistoricalWorkOrderID: typeof setSelectedHistoricalWorkOrderID;
    clearSelectedHistoricalWorkOrderID: typeof clearSelectedHistoricalWorkOrderID;
    clearHistoricalResultID: typeof clearHistoricalResultID;
    online: boolean;
    getMeasurementPointListResultHistory: typeof getMeasurementPointListResultHistory;

    installBaseIDsNotTested: string[];
    showAddRepairWorkOrderModal: boolean;
    installBase: IinstallBase;
    facilityID: string;
    queryParams: typeof suggestedPartsQueryParamsEnum;
    savePartAsInstallBasePart: typeof savePartAsInstallBasePart;
}

const MobileLayout = (props: Iprops & IdispatchProps & RouteComponentProps<{ installID: string }>) => {
    const [activeNavTab, setActiveNavTab] = useState(1);
    const { installBasesNotTested } = props;
    const { installID } = props.match.params;

    const products = useSelector(getProducts);
    const selectedProduct = useMemo(() => products[props.selectedInstall.productID], [installID]);

    const [nextInstallID, prevInstallID] = React.useMemo(() => {
        const currentIndex = installBasesNotTested.findIndex((install: any) => install.id === installID);

        let nextInstall_ID = '';
        let prevInstall_ID = '';
        if (currentIndex + 1 < installBasesNotTested.length) {
            nextInstall_ID = installBasesNotTested[currentIndex + 1].id;
        }

        if (currentIndex > 0) {
            prevInstall_ID = installBasesNotTested[currentIndex - 1].id;
        }

        return [nextInstall_ID, prevInstall_ID];
    }, [installID]);

    return (
        <Layout data-offset="true">
            <Navigation>
                <NavigationTab
                    isActive={activeNavTab === 0}
                    onClick={() => {
                        setActiveNavTab(0);
                    }}
                >
                    Details
                </NavigationTab>
                <NavigationTab
                    isActive={activeNavTab === 1}
                    onClick={() => {
                        setActiveNavTab(1);
                    }}
                >
                    Measurements
                </NavigationTab>
            </Navigation>
            <AssetTitleBar>
                <LinkContainer
                    to={`/devices/${prevInstallID}`}
                    style={{
                        color: constants.colors.orange,
                        padding: '4px 16px'
                    }}
                    type="button"
                >
                    <Button bsStyle="link" bsSize="sm" disabled={prevInstallID.length === 0}>
                        <FontAwesomeIcon size="lg" icon="chevron-left" style={{ height: '3em' }} />
                    </Button>
                </LinkContainer>
                <div className="text-container">
                    <span style={{ textAlign: 'center' }}>{selectedProduct?.name}</span>
                    <span className="location" style={{ textAlign: 'center' }}>
                        {props.selectedInstall && props.facility
                            ? TableUtil.buildLocation(props.selectedInstall, props.facility)
                            : ''}
                    </span>
                </div>
                <LinkContainer
                    to={`/devices/${nextInstallID}`}
                    style={{
                        color: constants.colors.orange,
                        padding: '4px 16px'
                    }}
                    type="button"
                >
                    <Button bsStyle="link" bsSize="sm" disabled={nextInstallID.length === 0}>
                        <FontAwesomeIcon size="lg" icon="chevron-right" style={{ height: '3em' }} />
                    </Button>
                </LinkContainer>
            </AssetTitleBar>
            {activeNavTab === 0 && <LeftColumnContainer {...props} />}
            {activeNavTab === 1 && <MeasurementPointListContainer {...props} />}
        </Layout>
    );
};

const mapStateToProps = (state: IinitialState, ownProps: Iprops) => {
    const install = state.manageInventory.installBasesByID[ownProps.match.params.installID] || initialInstallBase;
    const product = state.manageInventory.productsByID[install.productID] || initialProduct;
    const installWithProduct = {
        ...initialInstallBasePopulated,
        ...install,
        product
    };

    const installBaseHistory = selectInstallBaseHistoryByInstallBaseID(state, install.id);
    const facility = state.facilities.facilitiesByID[state.manageJob.selectedJob.facilityID] || initialFacility;
    return {
        user: state.user,
        loading: selectIsLoading(state),
        selectedInstall: installWithProduct,
        productInfo: state.productInfo,
        selectedJob: state.manageJob.selectedJob,
        facility,
        installBaseHistory,
        selectedResult: state.measurementPointResults.selectedResult,
        online: state.offline.online
    };
};

export default withTranslation('measurementPoints')(
    connect(mapStateToProps, {
        getAllMeasurementPointLists,
        toggleEditInstallModal,
        updateMeasurementPointResult,
        setSelectedHistoricalWorkOrderID,
        installContact,
        clearHistoricalResultID,
        clearSelectedHistoricalWorkOrderID,
        getMeasurementPointListResultHistory
    })(MobileLayout)
);
