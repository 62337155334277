import * as React from 'react';
import ConfirmModal from '../../common/ConfirmModal';
import { useDispatch, useSelector } from 'react-redux';
import { TFunction } from 'i18next';
import { Button } from 'react-bootstrap';
import { getIsMobile } from '../../../reducers/configReducer';
import MobileModalAlt from '../../common/mobile/MobileModalAlt';

import clockDeskIcon from '../../../images/icons/clock-desk.svg';
import * as types from '../../../actions/actionTypes';

interface Iprops {
    t: TFunction;
}

export function CheckHoursModal(props: Iprops) {
    const isMobile = useSelector(getIsMobile);
    const dispatch = useDispatch();

    const close = () => {
        dispatch({
            type: types.SAVE_HOURS_CHECKED,
            show: false
        });
    };

    const mobileTilteStyle: any = {
        background: 'var(--Reds-Error-Red, #DA2F2F)',
        color: 'var(--white, #FFF)',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        padding: '16px',
        display: 'flex',
        alignItems: 'center',
        gap: '8px'
    };

    const title = (
        <div style={isMobile ? mobileTilteStyle : {}} className="title">
            <img
                style={
                    isMobile
                        ? { width: '40px', height: '40px', flexShrink: 0 }
                        : { width: '52px', height: '52px', paddingBottom: '12px' }
                }
                src={clockDeskIcon}
            />
            <span>{props.t('nsJob:hoursCheck')} </span>
        </div>
    );

    const buttons = (
        <>
            <Button onClick={close}>{props.t('nsJob:acknowledgeAlert')}</Button>
        </>
    );

    const mobileFooter = <div className={'form-buttons'}>{buttons}</div>;

    const webFooter = (
        <div className="footer" style={{ textAlign: 'center' }}>
            {buttons}
        </div>
    );

    const content = (
        <div className="content">
            <p>
                It looks like you did not submit Working or Non-Productive hours. Submit your hours in the MyMedGas FSE
                app.
            </p>
            <p>Contact your planner if you have issues with this.</p>
        </div>
    );

    if (!isMobile) {
        return (
            <div className="backdrop">
                <ConfirmModal>
                    <div className="modal-content hours-check">
                        {title}
                        {content}
                        {webFooter}
                    </div>
                </ConfirmModal>
            </div>
        );
    } else {
        return (
            <MobileModalAlt show={true} onHide={close} className="" title={title} headerStyle={{ padding: '0px' }}>
                <div className="modal-content hours-check hours-check-mobile">
                    {content}
                    {mobileFooter}
                </div>
            </MobileModalAlt>
        );
    }
}
