/*
 * Manage Install Modal - Container
 */

import { connect } from 'react-redux';

import { installContact } from '../../actions/manageInventoryActions';
import InstallContactForm from './InstallContactForm';
import { initialInstallBase } from '../../reducers/initialState';
import { IinitialState } from '../../reducers';
import { withTranslation, WithTranslation } from 'react-i18next';
import { selectIsLoading } from '../../reducers/commonReducers';

interface Iprops {
    colorButton: string;
    installID: string;
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops & WithTranslation) => {
    const { installBasesByID } = state.manageInventory;

    const selectedInstall = installBasesByID[ownProps.installID] || initialInstallBase;
    return {
        loading: selectIsLoading(state),
        selectedInstall
    };
};

export default withTranslation('manageInventory')(
    connect(mapStateToProps, {
        installContact
    })(InstallContactForm)
);
