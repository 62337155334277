import * as React from 'react';
import { IPropsCommonModal } from '../CommonModal';
import styled from 'styled-components';

const Modal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #fff;
    z-index: 1100;

    display: flex;
    flex-direction: column;
`;

const Title = styled.div`
    height: 64px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #000000;
`;
const Content = styled.div`
    height: calc(100vh - 64px - 43px);
    overflow-y: hidden;
    overflow-x: hidden;
`;

const Footer = styled.div``;

export const MobileButtonGroup = styled.div`
    position: fixed;
    bottom: 0;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #f5f5f5;
    width: 100vw;
    gap: 24px;

    & > button {
        flex: 1;
    }
`;

const MobileModalAlt = (props: IPropsCommonModal) => {
    const backdrop = props.backdrop ? props.backdrop : 'static';

    if (!props.show) {
        return null;
    }

    return (
        <>
            <Modal
                className="mobile-modal-alt"
                show={props.show}
                onHide={props.onHide}
                container={
                    props.container || document.getElementById('two-pane-layout') || document.querySelector('.app')
                }
                backdrop={backdrop}
            >
                {props.title && <Title>{props.title}</Title>}
                <Content hasTitle={props.title || false} hasFooter={props.footer || false}>
                    {props.children}
                </Content>
                {props.footer && <Footer>{props.footer}</Footer>}
            </Modal>
        </>
    );
};

export default MobileModalAlt;
