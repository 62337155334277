import React, { useEffect, useMemo, useState } from 'react';
import CommonModal from '../common/CommonModal';
import { AbstractControl } from 'react-reactive-form';
import { FormUtil } from '../common/FormUtil';
import { useTranslation } from 'react-i18next';
import { FormGenerator } from 'react-reactive-form';
import { Col, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { IinitialState } from '../../reducers';
import { toggleModalAddFSE } from '../../actions/manageInventoryActions';
import { IjobPopulated, Ioption } from '../../models';
import { jobTypesIdEnumInverse } from '../../models-enums';
import { getFSEUsers, updateJobFse } from '../../actions/manageJobActions';
import { toastr } from 'react-redux-toastr';
import { TFunction } from 'i18next';

interface Iprops {
    selectedJob: IjobPopulated;
    t: TFunction;
}

const AddFSEModal = (props: Iprops) => {
    const { selectedJob } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();

    //STATE
    const [form, setForm] = useState<AbstractControl>();
    const [fseUserOptions, setFseUserOptions] = useState<Ioption[]>();

    //REDUX
    const show = useSelector((state: IinitialState) => state.manageInventory.showModalAddFSE);
    const facilitiesById = useSelector((state: IinitialState) => state.facilities.facilitiesByID);
    const fseUsers = useSelector((state: IinitialState) => state.manageJob.fseUsers);

    const onHide = () => dispatch(toggleModalAddFSE());

    useEffect(() => {
        if (!fseUsers || !fseUsers.length) dispatch(getFSEUsers());
    }, []);

    useEffect(() => {
        setFseUserOptions(fseUsers.map(user => ({ label: `${user.first} ${user.last}`, value: user.id })));
    }, [fseUsers]);

    const fieldConfig = useMemo(() => {
        const userJobIds = selectedJob?.userJobs?.filter(uj => !uj.isDeleted).map(uj => uj.userID);
        const fseLead = fseUserOptions?.find(user => user.value === selectedJob.assignedUserID);
        const additionalFSEList: Ioption[] = fseUserOptions?.filter(user => userJobIds?.includes(user.value)) || [];

        if (fseUserOptions) {
            additionalFSEList.concat(fseUserOptions?.filter(user => userJobIds?.includes(user.value)));
        }

        let facilityName: string | undefined = undefined;

        if (selectedJob && selectedJob.facilityID) {
            const foundFacility = facilitiesById[selectedJob.facilityID];
            if (foundFacility) {
                facilityName = foundFacility.name;
            }
        }

        const formControls = {
            facility: {
                render: FormUtil.TextInput,
                meta: {
                    label: props.t('common:facility'),
                    colWidth: 12,
                    type: 'text'
                },
                formState: {
                    value: facilityName,
                    disabled: true
                }
            },
            jobNumber: {
                render: FormUtil.TextInput,
                meta: {
                    label: props.t('nsJob:jobNumber'),
                    colWidth: 12,
                    type: 'text'
                },
                formState: {
                    value: selectedJob.jobNumber,
                    disabled: true
                }
            },
            type: {
                render: FormUtil.TextInput,
                meta: {
                    label: props.t('nsJob:type'),
                    colWidth: 12,
                    type: 'text'
                },
                formState: {
                    value: jobTypesIdEnumInverse[selectedJob.jobTypeID],
                    disabled: true
                }
            },
            startDate: {
                render: FormUtil.Datetime,
                meta: {
                    label: props.t('nsJob:startDate'),
                    colWidth: 12,
                    showTime: false
                },
                formState: {
                    value: selectedJob.startDate,
                    disabled: true
                }
            },
            endDate: {
                render: FormUtil.Datetime,
                meta: {
                    label: props.t('nsJob:endDate'),
                    colWidth: 12,
                    showTime: false
                },
                formState: {
                    value: selectedJob.endDate,
                    disabled: true
                }
            },
            fseLead: {
                render: FormUtil.Select,
                meta: {
                    options: fseUserOptions,
                    label: props.t('nsJob:fseLead'),
                    colWidth: 12
                },
                options: {
                    validators: [FormUtil.validators.requiredWithTrim]
                },
                formState: { value: fseLead, disabled: false }
            },
            additionalFseList: {
                render: FormUtil.Select,
                meta: {
                    options: fseUserOptions,
                    label: props.t('nsJob:jobForm.fseMembers'),
                    colWidth: 12,
                    isMulti: true,
                    required: false
                },
                formState: { value: additionalFSEList, disabled: false }
            }
        };

        return FormUtil.translateForm(
            {
                controls: { ...formControls }
            },
            t
        );
    }, [selectedJob, fseUserOptions]);

    const onSubmit = (event: React.MouseEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (form && form.value && form.value.fseLead) {
            const { fseLead, additionalFseList } = form.value;
            const {
                id,
                facilityID,
                jobTypeID,
                startDate,
                endDate,
                status,
                isDeleted,
                jobNumber,
                jobHours,
                fseNotes,
                source,
                createDate
            } = selectedJob;

            const job = {
                id,
                facilityID,
                assignedUserID: fseLead.value.toString(),
                jobTypeID,
                startDate,
                endDate,
                status,
                isDeleted,
                jobNumber,
                jobHours,
                fseNotes,
                source,
                createDate
            };

            const users: string[] = additionalFseList?.map((user: Ioption) => user.value) || null;
            const validateParts = false;

            dispatch(updateJobFse(job, users, validateParts));
        } else toastr.error('Form Incomplete', 'Please fill out all required fields');
    };

    const modalTitle = props.t('nsJob:addFseToJob');

    return (
        <CommonModal className="" title={modalTitle} show={show} onHide={onHide}>
            <form onSubmit={onSubmit}>
                <Col xs={12}>
                    {fseUserOptions && selectedJob ? (
                        <FormGenerator onMount={(form: AbstractControl) => setForm(form)} fieldConfig={fieldConfig} />
                    ) : (
                        <p>{props.t('common:loadingText')}</p>
                    )}
                </Col>
                <Col xs={12} className="form-buttons text-right">
                    <Button bsStyle="default" type="button" className="pull-left" onClick={onHide}>
                        {props.t('common:cancel')}
                    </Button>
                    <Button bsStyle="primary" type="submit">
                        {props.t('common:save')}
                    </Button>
                </Col>
            </form>
        </CommonModal>
    );
};

export default AddFSEModal;
