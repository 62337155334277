/*
 * Search Facility Form
 * Search facilities
 *
 */

import * as React from 'react';
import * as types from '../../actions/actionTypes';
import { FormGenerator, FieldConfig, FormGroup, GroupProps, FormArray } from 'react-reactive-form';
import { Col, Button, Row, ListGroup, Well } from 'react-bootstrap';
import { debounce, isEmpty, map } from 'lodash';
import { constants } from '../../constants/constants';
import { toastr } from 'react-redux-toastr';
import { WithTranslation } from 'react-i18next';

import { FormUtil } from '../common/FormUtil';
import { Ifacility, Ioption } from '../../models';

import {
    emptySearchedFacilities,
    searchFacilities,
    setDefaultFacilityID,
    toggleAddFacilityModal,
    toggleEditJobModal
} from '../../actions/manageJobActions';
import { useDispatch, useSelector } from 'react-redux';
import { getUsersActiveCountry, getUsersCountries } from '../../actions/userActions';
import { loadCountries } from '../../actions/countriesActions';
import { getCountries } from '../../reducers/countriesReducer';

interface Iprops {
    toggleModal: () => void;
    loading: boolean;
    colorButton: string;
    searchFacilities: typeof searchFacilities;
    searchedFacilities: { [key: string]: Ifacility };
    emptySearchedFacilities: typeof emptySearchedFacilities;
    toggleAddFacilityModal: typeof toggleAddFacilityModal;
    toggleEditJobModal: typeof toggleEditJobModal;
    setDefaultFacilityID: typeof setDefaultFacilityID;
    canAddFacility: boolean;
}

interface IState {
    fieldConfig: FieldConfig;
}
function SearchFacilityForm(props: Iprops & WithTranslation) {
    const usersCountries = useSelector(getUsersCountries);
    const usersActiveCountry = useSelector(getUsersActiveCountry);
    const countries = useSelector(getCountries);

    const dispatch = useDispatch();

    let formGroup: FormGroup | any;
    let subscription: any;
    const [state, setState] = React.useState<IState>();

    React.useEffect(() => {
        loadCountries(dispatch, countries);
        props.emptySearchedFacilities();
        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [countries]);

    React.useEffect(() => {
        const activeCountryOption: Ioption = {
            value: usersActiveCountry,
            label: countries.find(c => c.id === usersActiveCountry)?.name || ''
        };
        const countriesOptions: Ioption[] = countries
            .filter(c => usersCountries?.includes(c.id))
            .map(c => ({ label: c.name, value: c.id }));

        const fieldConfigControls = {
            country: {
                render: FormUtil.Select,
                meta: {
                    options: countriesOptions,
                    label: 'user:activeCountry',
                    colWidth: 6,
                    colWidthMedium: 6,
                    colWidthLarge: 6,
                    name: 'country'
                },
                formState: { value: activeCountryOption, disabled: false }
            },
            postalCode: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'user:zip',
                    colWidth: 6,
                    name: 'postal-code'
                },
                formState: { value: '', disabled: false }
            },
            address: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'user:address',
                    colWidth: 12,
                    type: 'text',
                    name: 'address'
                },
                formState: { value: '', disabled: false }
            },
            name: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'user:name',
                    colWidth: 12,
                    type: 'text',
                    name: 'name'
                },
                formState: { value: '', disabled: false }
            }
        } as { [key: string]: GroupProps };
        let fieldConfig = {
            controls: { ...fieldConfigControls }
        };

        setState({ fieldConfig: FormUtil.translateForm(fieldConfig, props.t) });
    }, [countries, usersActiveCountry, usersCountries]);

    /*
     * (reusable)
     * subscribe to the formGroup changes
     */
    const subscribeToChanges = () => {
        for (const key in formGroup.controls) {
            if (formGroup.controls.hasOwnProperty(key)) {
                subscription = formGroup.get(key).valueChanges.subscribe((value: any) => {
                    onValueChanges(value, key);
                });
            }
        }
    };

    /*
     * (reusable)
     * set the table filters to redux on each value change
     */
    const onValueChanges = (value: any, key: string) => {
        switch (key) {
            case 'country':
                handleSubmitDebounced();
                break;
            case 'name':
                if (value < 2) {
                    break;
                }
                handleSubmitDebounced();
                break;
            default:
                handleSubmitDebounced();
                break;
        }
    };

    const handleSubmit = () => {
        const { name, postalCode, address, country } = formGroup.value;
        if (isEmpty(name) && isEmpty(address) && isEmpty(postalCode)) {
            return;
        }
        if (formGroup.status === 'INVALID') {
            formGroup.markAsSubmitted();
            toastr.error(props.t('toastMessage:invalidFormSubmission'), '', constants.toastrError);
            return;
        }
        props.searchFacilities(name, postalCode, address, country.value);
    };
    const handleSubmitDebounced = debounce(handleSubmit, constants.tableSearchDebounceTime);

    const setForm = (form: FormGroup | FormArray) => {
        formGroup = form;
        formGroup.meta = {
            loading: props.loading
        };
        if (!subscription) {
            setTimeout(() => {
                subscribeToChanges();
            }, 300);
        }
    };

    const handleFacilitySelect = (facility: Ifacility) => {
        if (facility && facility.id) {
            // save the selected facility
            dispatch({ type: types.GET_FACILITY_SUCCESS, payload: { data: facility } });

            props.setDefaultFacilityID(facility.id);
            props.toggleEditJobModal();
        }
    };

    const { t } = props;
    let searchActive = false;
    if (
        !props.loading &&
        formGroup &&
        formGroup.value &&
        (formGroup.value.name || formGroup.value.postalCode || formGroup.value.address)
    ) {
        searchActive = true;
    }

    const FacilityListItem = ({
        facility,
        selectedItem,
        index
    }: {
        facility: Ifacility;
        selectedItem?: Ifacility;
        index: string;
    }) => {
        const className =
            selectedItem && selectedItem.id === index
                ? 'list-group-item new-product-item selected'
                : 'list-group-item new-product-item';
        return (
            <li
                className={className}
                onClick={() => {
                    handleFacilitySelect(facility);
                }}
            >
                <h4> {facility.name} </h4>
                <h4> {facility.address} </h4>
            </li>
        );
    };

    return (
        <form onSubmit={handleSubmit} className="clearfix beacon-form facility-form">
            {state?.fieldConfig && (
                <Row>
                    <Col xs={12}>
                        <FormGenerator onMount={setForm} fieldConfig={state.fieldConfig} />
                    </Col>
                </Row>
            )}

            <Row>
                <Col xs={12}>
                    <ListGroup className="beacon-list-group">
                        {map(props.searchedFacilities, (facility: Ifacility, index: string) => (
                            <FacilityListItem facility={facility} key={facility.id} index={index} />
                        ))}
                    </ListGroup>
                    {isEmpty(props.searchedFacilities) && searchActive && !props.loading && (
                        <Col xs={12}>
                            <Well className="text-center">{t('noFacilitiesFound')}</Well>
                        </Col>
                    )}
                </Col>
            </Row>
            <Col xs={12} className="form-buttons text-right">
                <Button
                    bsStyle="default"
                    type="button"
                    className="pull-left"
                    onClick={() => {
                        props.toggleModal();
                    }}
                >
                    {t('cancel')}
                </Button>
                <Button
                    bsStyle="link"
                    type="button"
                    disabled={props.loading || props.canAddFacility === false}
                    title={props.canAddFacility ? '' : t('notAllowedToAddCustomer')}
                    className="right-side"
                    onClick={() => {
                        props.toggleAddFacilityModal();
                    }}
                >
                    {t('addNewFacility')}
                </Button>
            </Col>
        </form>
    );
}

export default SearchFacilityForm;
