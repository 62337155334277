/*
 * Test Procedures Container
 */

import * as React from 'react';

import { Col } from 'react-bootstrap';
import { IinitialState } from '../../reducers';

import { TFunction } from 'i18next';
import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import { initialInstallBase, initialJob, initialMeasurementPointList } from '../../reducers/initialState';
import { selectMeasurementPointListForInstallBase } from '../../reducers/measurementPointListsReducer';
import { selectIsLoading } from '../../reducers/commonReducers';

interface Iprops {
    installID: string;
}

const TestProcedures = ({ testProcedures, t }: { testProcedures: string; t: TFunction }) => {
    if (testProcedures) {
        return (
            <div>
                <Col xs={12}>
                    <div dangerouslySetInnerHTML={{ __html: testProcedures }} />
                </Col>
            </div>
        );
    } else {
        return (
            <div>
                <Col xs={12}>
                    <h4>{t('leftColumn.noTestProcedures')}</h4>
                </Col>
            </div>
        );
    }
};

const mapStateToProps = (state: IinitialState, ownProps: Iprops & WithTranslation) => {
    const selectedInstall = state.manageInventory.installBasesByID[ownProps.installID] || initialInstallBase;

    const selectedJob = state.manageJob.jobsByID[state.manageJob.selectedJob.id] || initialJob;
    const facility = state.facilities.facilitiesByID[selectedJob.facilityID];

    let selectedMeasurementPointList = initialMeasurementPointList;
    if (facility) {
        // facility might be undefined after an app upgrade
        selectedMeasurementPointList = selectMeasurementPointListForInstallBase(state, {
            productID: selectedInstall.productID,
            standardID: facility.standardID,
            shouldShowWarning: false
        });
    }
    return {
        loading: selectIsLoading(state),
        testProcedures: selectedMeasurementPointList.testProcedures
    };
};

export const TestProceduresContainer = withTranslation('measurementPoints')(
    connect(mapStateToProps, {})(TestProcedures)
);
