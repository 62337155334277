import { createSelector } from 'reselect';
import { IinitialState } from '.';
import * as types from '../actions/actionTypes';
import initialState from './initialState';

export const getLocationPathname = (state: IinitialState) => state.location.pathname;

export const selectLocationPathname = createSelector([getLocationPathname], locationPathname => locationPathname);
export const selectIsWorkOrderMode = createSelector([getLocationPathname], locationPathname => {
    const locationMatch = ['/commission', '/repair', '/warranty', '/sap', '/unassigned-sap-workorders'];
    const isAddWorkOrderMode = locationMatch.some(item => locationPathname.includes(item));

    return isAddWorkOrderMode;
});

export default function locationReducer(
    state: { pathname: string } = initialState.location,
    action: any
): { pathname: string } {
    switch (action.type) {
        case types.SET_LOCATION_PATHNAME:
            return { pathname: action.payload.pathname as string };
        default:
            return state;
    }
}
