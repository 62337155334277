import * as localForage from 'localforage';
import * as types from './actionTypes';

import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { INonProductiveHour, Iuser, ThunkResult } from '../models';
import { acquireToken, msalFetch, msalRequest } from '../components/auth/Auth-Utils';
import msalInstance from '../components/auth/MsalInstance';
import API from '../constants/apiEndpoints';
import { TrackJS } from 'trackjs';
import { TFunction } from 'i18next';
import { beginAjaxCall } from './ajaxStatusActions';
import { appSyncItemNames, constants } from '../constants/constants';
import { toastr } from 'react-redux-toastr';
import Mixpanel from '../helpers/Mixpanel';
import { IinitialState } from '../reducers';
import { AppSyncItemStatus } from '../models-enums';

export const getUsersCountries = (state: IinitialState) => state.user.userCountries;
export const getUsersActiveCountry = (state: IinitialState) => state.user.countryID;

/*
 * Logout dispatches an event to pause persisting redux state
 * then we replace the entire persisted state.  This works better than dispatching USER_LOGOUT_SUCCESS because
 * dispatching that will trigger API calls which try to renew the token and results in a nasty race condition.
 */
const userLogoutHelper = (t: TFunction): ThunkResult<any> => {
    return (dispatch, getState) => {
        const { offline } = getState();
        if (offline.outbox && offline.outbox.length) {
            toastr.warning(t('warning'), t('auth:logoutSyncWarning'), {
                ...constants.toastrWarning,
                timeOut: 8000
            });
            return;
        }
        const event = new CustomEvent('userLogout');
        document.dispatchEvent(event);
        localForage.setItem('offlineStatusOverride', false);
        Mixpanel.track('User Logout');
        msalInstance.msalApp.logoutRedirect({
            postLogoutRedirectUri: '/'
        });
        // localForage
        //     .setItem('med-gas-mobile_all-products', {})
        //     .then(() => {
        //         // add a bit more time to make sure persist has enough time to clear out local storage
        //     })
        //     .catch(error => {
        //         Mixpanel.track('User Logout Error');
        //         console.error('[userLogoutHelper]:', error);
        //     })
        //     .finally(() => {
        //         msalInstance.msalApp.logoutRedirect({
        //             postLogoutRedirectUri: '/'
        //         });
        //     });
    };
};

export function userLogin(t: TFunction): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        return acquireToken().then(authResponse => {
            if (!authResponse || (authResponse && !authResponse.accessToken)) {
                throw new Error('failed to retrieve token.  Likely because 3rd party cookies are disabled');
            }
            const token = authResponse.accessToken;
            dispatch({ type: types.AAD_LOGIN, token });
            const axiosOptions: AxiosRequestConfig = {
                method: 'post'
            };
            const url = API.POST.user.login;
            return msalFetch(url, axiosOptions)
                .then((data: AxiosResponse<any>) => {
                    if (!data.data) {
                        throw new Error('missing data');
                    } else {
                        dispatch({
                            type: types.USER_LOGIN_SUCCESS,
                            user: data.data
                        });
                        TrackJS.configure({ userId: data.data.email });
                        return data;
                    }
                })
                .catch((error: any) => {
                    console.error('[userLogin]:', error);
                    dispatch({ type: types.USER_LOGIN_FAILED });
                    dispatch(userLogoutHelper(t));
                    // to avoid getting stuck, go ahead and log the user out after a longer pause
                    constants.handleError(error, 'login');
                    throw error;
                });
        });
    };
}

export function MSALlogin(t: TFunction): ThunkResult<any> {
    return async (dispatch, getState) => {
        return await msalInstance.msalApp.loginRedirect(msalRequest);
    };
}

/*
 * reauthenticate in the background if possible
 * April 24th - this can likely be removed now that we are doing login() right from the error hanndling function in constants.tsx
 */
export const adalReauth = () => {
    acquireToken();
};

export function forceUserLogout(): ThunkResult<any> {
    return () => {
        const event = new CustomEvent('userLogout');
        document.dispatchEvent(event);
        localForage.setItem('offlineStatusOverride', false);
        Mixpanel.track('User Logout');
        try {
            msalInstance.msalApp.logoutRedirect({
                postLogoutRedirectUri: '/'
            });
        } catch (e) {
            console.error('forceUserLogout', e);
        }
        // localForage
        //     .setItem('med-gas-mobile_all-products', {})
        //     .then(() => {
        //         // add a bit more time to make sure persist has enough time to clear out local storage
        //         setTimeout(() => {

        //         }, 500);
        //     })
        //     .catch(error => {
        //         Mixpanel.track('User Logout Error');
        //         console.error('[userLogoutHelper]:', error);
        //     });
    };
}

export function userLogout(t: TFunction): ThunkResult<any> {
    return (dispatch, getState) => {
        const toastrConfirmOptions = {
            onOk: () => {
                // check to make sure there is nothing in the outbox
                const { offline } = getState();
                if (offline.outbox && offline.outbox.length) {
                    toastr.warning(t('warning'), t('auth:logoutSyncWarning'), {
                        ...constants.toastrWarning,
                        timeOut: 8000
                    });
                    return;
                }
                dispatch(userLogoutHelper(t));
            },
            OnCancel: () => null,
            okText: t('common:logout'),
            cancelText: t('common:cancel')
        };
        toastr.confirm(t('common:logoutConfirm'), toastrConfirmOptions);
    };
}

export function userLogoutSessionOnly(): ThunkResult<any> {
    return (dispatch, getState) => {
        // Reset initial app sync queue
        dispatch({ type: types.CLEAR_INITIAL_APP_SYNC_LIST });

        dispatch({ type: types.USER_LOGOUT_SESSION_ONLY });
        localForage.setItem('offlineStatusOverride', false);

        if (msalInstance !== undefined && msalInstance.msalApp === undefined) {
            // Really in the weeds here...
            msalInstance.reinstantiateMsal();
            setTimeout(() => {
                msalInstance.msalApp.logoutRedirect({
                    postLogoutRedirectUri: '/'
                });
            }, 500);
            return;
        } else {
            setTimeout(() => {
                msalInstance.msalApp.logoutRedirect({
                    postLogoutRedirectUri: '/'
                });
            }, 500);
        }
    };
}

export const toggleEditProfileModal = () => ({
    type: types.TOGGLE_MODAL_EDIT_PROFILE
});

export function updateUserProfile(user: Iuser): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        dispatch({ type: types.TOGGLE_MODAL_EDIT_PROFILE });
        toastr.success('Success', 'Saved profile', constants.toastrSuccess);
        const axiosOptions = {
            url: API.POST.user.updateprofile,
            method: 'post',
            data: user
        };
        dispatch({
            type: types.USER_UPDATE_PROFILE_SUCCESS,
            user,
            meta: {
                offline: {
                    effect: { axiosOptions, message: 'update profile' }
                }
            }
        });
    };
}

export function getNonProductiveHours(showError: boolean = true): ThunkResult<any> {
    return (dispatch, getState) => {
        const axiosOptions: AxiosRequestConfig = {
            method: 'get'
        };
        const url = API.GET.user.getNonProductiveHours;

        dispatch({
            type: types.UPDATE_INITIAL_APP_SYNC_ITEM_STATUS,
            name: appSyncItemNames.NonProductiveHours,
            status: AppSyncItemStatus.inProgress
        });

        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (!data.data) {
                    dispatch({
                        type: types.UPDATE_INITIAL_APP_SYNC_ITEM_STATUS,
                        name: appSyncItemNames.NonProductiveHours,
                        status: AppSyncItemStatus.failed
                    });
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.UPDATE_NON_PRODUCTIVE_HOURS,
                        nonProductiveHours: data.data
                    });

                    dispatch({ type: types.REMOVE_INITIAL_APP_SYNC_ITEM, name: appSyncItemNames.NonProductiveHours });

                    return data;
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.UPDATE_INITIAL_APP_SYNC_ITEM_STATUS,
                    name: appSyncItemNames.NonProductiveHours,
                    status: AppSyncItemStatus.failed
                });
                dispatch({
                    type: types.UPDATE_NON_PRODUCTIVE_HOURS_FAILED,
                    error,
                    axiosOptions
                });
                if (showError) {
                    constants.handleError(error, 'Get Non Productive Hours');
                }
                console.error(error);
            });
    };
}

export const saveNonProductiveHours = (nonProductiveHour: INonProductiveHour): ThunkResult<any> => (
    dispatch,
    getState
) => {
    const currentNPH = getState().user.nonProductiveHours ? getState().user.nonProductiveHours : [];
    const axiosOptions: AxiosRequestConfig = {
        method: 'post',
        data: [nonProductiveHour],
        url: API.POST.user.saveNonProductiveHours
    };
    dispatch({
        type: types.SAVE_NON_PRODUCTIVE_HOUR,
        nonProductiveHour,
        meta: {
            offline: {
                effect: { axiosOptions, message: 'Saving Non Productive Hours' },
                rollback: {
                    type: types.UPDATE_NON_PRODUCTIVE_HOURS,
                    nonProductiveHours: currentNPH
                }
            }
        }
    });
};

export function getHoursCheck(): ThunkResult<any> {
    return (dispatch, getState) => {
        const axiosOptions: AxiosRequestConfig = {
            method: 'get'
        };
        const url = API.GET.user.hoursCheck;

        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (data.status !== 200) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.SAVE_HOURS_CHECKED,
                        show: data.data
                    });
                    return data.data;
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.SAVE_HOURS_CHECKED_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'Get Hours Check');
                console.error(error);
            });
    };
}

export const resetUserLogout = () => {
    return {
        type: types.RESET_USER_LOGOUT
    };
};

export const getLaborRates = (showError: boolean = true): ThunkResult<any> => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_INITIAL_APP_SYNC_ITEM_STATUS,
            name: appSyncItemNames.LaborRates,
            status: AppSyncItemStatus.inProgress
        });

        const axiosOptions: AxiosRequestConfig = { method: 'GET' };
        const url = API.GET.user.getLaborRates;

        return msalFetch(url, axiosOptions)
            .then(result => {
                if (!result.data) {
                    dispatch({
                        type: types.UPDATE_INITIAL_APP_SYNC_ITEM_STATUS,
                        name: appSyncItemNames.LaborRates,
                        status: AppSyncItemStatus.failed
                    });
                    throw new Error('unable to get user labor rates');
                }

                dispatch({
                    type: types.GET_USER_LABOR_RATES_SUCCESS,
                    payload: { laborRates: result.data }
                });

                dispatch({
                    type: types.REMOVE_INITIAL_APP_SYNC_ITEM,
                    name: appSyncItemNames.LaborRates
                });
                return result;
            })
            .catch(error => {
                console.error('[getLaborRates]: ', error);
                dispatch({
                    type: types.UPDATE_INITIAL_APP_SYNC_ITEM_STATUS,
                    name: appSyncItemNames.LaborRates,
                    status: AppSyncItemStatus.failed
                });

                dispatch({
                    type: types.GET_USER_LABOR_RATES_FAILED
                });

                if (showError) constants.handleError(error, 'get user labor rates');

                throw error;
            });
    };
};
