import * as types from './actionTypes';

import {
    ImeasurementPointAnswer,
    ImeasurementPointList,
    ImeasurementPointListTab,
    ThunkResult,
    IsimpleMeasurementPointResult,
    ImeasurementPointResult
} from '../models';
import { find, map, omit } from 'lodash';
import {
    selectMostRecentSimpleResult,
    selectMostRecentTemporarySimpleResult
} from '../reducers/simpleMeasurementPointResultsReducer';

import API from '../constants/apiEndpoints';
import { IinitialState } from '../reducers';
import { initialSimpleMeasurementPointResult } from '../reducers/initialState';
import moment from 'moment';

import { selectSimpleMeasurementPointList } from '../reducers/measurementPointListsReducer';
import { toastr } from 'react-redux-toastr';

import { constants } from '../constants/constants';
import { createDefaultAnswers } from './measurementPointResultUtil';

const uuidv4 = require('uuid/v4');

/*
 * updateResultAnswerIDs
 */
const updateResultAnswerIDs = (resultAnswers: ImeasurementPointAnswer[]) => {
    return resultAnswers.map(answer => ({ ...answer, id: uuidv4() }));
};

export function updateSimpleMeasurementPointResult(result: IsimpleMeasurementPointResult): ThunkResult<any> {
    return dispatch => {
        dispatch({
            type: types.UPDATE_SIMPLE_MEASUREMENT_POINT_RESULT,
            result
        });
    };
}

/*
 *  create a completely new measurement point list result
 *  pre-fill answers with defaults
 *  I may be convinced otherwise, but I don't like sharing this one. It's fine to have a distinct function here
 *  For someone not as in-the-know, having InstallBaseID be optional could really trip people up
 *  Instead, we're better off keeping them distinct.
 */
const createNewSimpleResult = (
    jobID: string,
    measurementPointList: ImeasurementPointList,
    //customerID: string,
    measurementPointTabs: ImeasurementPointListTab[],
    getState: () => IinitialState
): IsimpleMeasurementPointResult | ImeasurementPointResult => {
    const newAnswersWithIDs = updateResultAnswerIDs(createDefaultAnswers(measurementPointTabs, getState));

    return {
        ...initialSimpleMeasurementPointResult,
        id: uuidv4(),
        measurementPointListID: measurementPointList.id,
        jobID,
        temporary: true,
        createDate: moment.utc().toISOString(),
        updateDate: moment.utc().toISOString(),
        measurementPointAnswers: newAnswersWithIDs
    };
};

/*
 * initSimpleResult
 * receives a measurementPointListType and creates a temporary result
 */
const initSimpleResult = (
    getState: () => IinitialState,
    { measurementPointListType }: { measurementPointListType: string }
) => {
    const job = getState().manageJob.selectedJob;
    const facility = getState().facilities.facilitiesByID[job.facilityID];
    const { standardID } = facility;
    const selectedMeasurementPointList = selectSimpleMeasurementPointList(getState(), {
        standardID,
        shouldShowWarning: false,
        measurementPointListType
    });
    const temporaryPreviousResultForJobAndType = selectMostRecentTemporarySimpleResult(
        getState().simpleMeasurementPointResults.simpleMeasurementPointResultsByID,
        selectedMeasurementPointList.id,
        job.id,
        true
    );
    if (temporaryPreviousResultForJobAndType && temporaryPreviousResultForJobAndType.id.length) {
        return temporaryPreviousResultForJobAndType;
    } else {
        const previousResultForJobAndType = selectMostRecentSimpleResult(
            getState().simpleMeasurementPointResults.simpleMeasurementPointResultsByID,
            job.id,
            selectedMeasurementPointList.id
        );
        if (previousResultForJobAndType && previousResultForJobAndType.id.length) {
            return previousResultForJobAndType;
        } else {
            return createNewSimpleResult(
                job.id,
                selectedMeasurementPointList,
                //job.customerID,
                selectedMeasurementPointList.measurementPointTabs,
                getState
            );
        }
    }
};

/*
 * Requires query params to be passed in to decide if purity or verificationChecklist
 */
export const initSelectedSimpleResult = (measurementPointListType: string): ThunkResult<any> => {
    return (dispatch, getState) => {
        dispatch(updateSimpleMeasurementPointResult(initSimpleResult(getState, { measurementPointListType })));
    };
};

const submitSimpleMeasurementPointResultHelper = (updatedResult: IsimpleMeasurementPointResult): ThunkResult<any> => {
    return (dispatch, getState) => {
        const result = omit({ ...updatedResult, compiledNotes: null }, 'temporary');
        const originalSimpleMeasurementPointResult = getState().simpleMeasurementPointResults
            .simpleMeasurementPointResultsByID[updatedResult.id];

        const rollBackResult = !originalSimpleMeasurementPointResult
            ? { ...result, isDeleted: true }
            : originalSimpleMeasurementPointResult;

        const jobTypeID = getState().manageJob.selectedJob.jobTypeID;

        const axiosOptions = {
            url: API.POST.measurementPoint.saveSimpleResults,
            method: 'post',
            data: result
        };

        dispatch({
            type: types.UPDATE_SIMPLE_MEASUREMENT_POINT_RESULT,
            result,
            jobTypeID,
            meta: {
                offline: {
                    effect: { axiosOptions, message: 'submit tests' },
                    rollback: {
                        type: types.UPDATE_SIMPLE_MEASUREMENT_POINT_RESULT,
                        result: rollBackResult,
                        jobTypeID
                    }
                }
            }
        });
        toastr.success('Success', 'Saved tests.', constants.toastrSuccess);
    };
};

export function submitSimpleMeasurementPointResult(
    updatedResult: IsimpleMeasurementPointResult,
    updatedAnswers: ImeasurementPointAnswer[]
): ThunkResult<any> {
    return (dispatch, getState) => {
        const combinedAnswers = map(updatedAnswers, answer => {
            const originalAnswer = find(updatedResult.measurementPointAnswers, {
                measurementPointID: answer.measurementPointID
            });
            return { ...originalAnswer, ...answer };
        });
        const updatedResultWithAnswers = {
            ...updatedResult,
            measurementPointAnswers: combinedAnswers
        };

        // double check that the job exists
        const job = getState().manageJob.jobsByID[updatedResultWithAnswers.jobID];
        if (!job) {
            console.error(
                '[submitSimpleMeasurementPointResult]: missing job when trying to save result.',
                updatedResultWithAnswers
            );
            throw new Error('Error, missing job.  Please contact support.');
        }
        dispatch(submitSimpleMeasurementPointResultHelper(updatedResultWithAnswers));
    };
}

export function updateSMprAnswers(updatedAnswers: ImeasurementPointAnswer[]): ThunkResult<any> {
    return (dispatch, getState) => {
        const { selectedSimpleResult } = getState().simpleMeasurementPointResults;
        const combinedAnswers = map(updatedAnswers, answer => {
            const originalAnswer = find(selectedSimpleResult.measurementPointAnswers, {
                measurementPointID: answer.measurementPointID
            });
            return { ...originalAnswer, ...answer };
        });
        dispatch(
            updateSimpleMeasurementPointResult({
                ...selectedSimpleResult,
                measurementPointAnswers: combinedAnswers
            })
        );
    };
}

export const resetSelectedSimpleResult = () => ({
    type: types.RESET_SIMPLE_MEASUREMENT_POINT_RESULT
});
