/*
 * Edit Job Modal and Redux Connector
 */
import { Ihistory } from '../../models';

import { RiskAssessmentForm } from './RiskAssessmentForm';

import { IinitialState } from '../../reducers';
import { connect } from 'react-redux';
import { selectIsLoading } from '../../reducers/commonReducers';
import { withTranslation, WithTranslation } from 'react-i18next';
import { toggleViewJobModal, updateJob } from '../../actions/manageJobActions';
import { removeQuery } from '../common/OtherUtils';
import { WithCommonModal } from '../common/WithCommonModal';

export enum riskAssessmentQueryParamsEnum {
    riskAssess = 'riskAssess'
}
interface Iprops {
    colorButton: string;
    queryParams: typeof riskAssessmentQueryParamsEnum;
    history: Ihistory;
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops & WithTranslation) => {
    const closeModal = () => {
        removeQuery('riskAssess', ownProps.history);
    };
    return {
        className: 'job-edit',
        loading: selectIsLoading(state),
        title: ownProps.t('mobile.viewRiskAssessmentModal.riskAssessment'),
        selectedJob: state.manageJob.selectedJob,
        show: !!ownProps.queryParams.riskAssess,
        onHide: closeModal,
        closeModal
    };
};

export const RiskAssessmentModal = withTranslation('nsJob')(
    connect(mapStateToProps, {
        updateJob,
        toggleViewJobModal
    })(WithCommonModal(RiskAssessmentForm))
);
