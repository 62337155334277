import * as React from 'react';
import { useState } from 'react';
import { FieldConfig, FormArray, FormControl, FormGenerator, FormGroup, GroupProps } from 'react-reactive-form';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TFunction } from 'i18next';
import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';

import { FormUtil } from '../FormUtil';
import { IinitialState } from '../../../reducers';
import { setTableFilter } from '../../../actions/manageJobActions';
import { Ibuilding, Ifloor, Ijob, Ilocation, Ioption, IproductInfo, ItableFiltersReducer } from '../../../models';
import { find, omit } from 'lodash';
import {
    inventoryStatusFilterEnum,
    jobTypesIdEnum,
    workOrderStatusEnum,
    workOrderStatusEnumForFilter
} from '../../../models-enums';
import { constants } from '../../../constants/constants';

interface Iprops {
    tableFilters: ItableFiltersReducer;
    t: TFunction;
    show: boolean;
    closeFilterAssetModal: any;
    bgColor: string;
    brColor: string;
    primaryColor: string;
    setTableFilter: typeof setTableFilter;
    buildings: Ibuilding[];
    selectedJob: Ijob;
    productInfo: IproductInfo;
    clearTableFilter: () => void;
    isWorkOrderTypeJob: boolean;
    isAddWorkOrderMode: boolean;
    isJobDownloaded: boolean;
}
interface Istate {
    fieldConfig: FieldConfig;
}

const FilterAssetTableModal: React.FC<Iprops & WithTranslation> = props => {
    const initialFormGroup = new FormGroup({
        building: new FormControl(''),
        floor: new FormControl(''),
        location: new FormControl(''),
        room: new FormControl(''),
        measurementPointListResultStatus: new FormControl(''),
        workOrderResultStatus: new FormControl(''),
        originalMeasurementPointListResultStatus: new FormControl(''),
        mainCategory: new FormControl('')
    });

    const [searchForm, setSearchForm] = useState<FormGroup | FormArray>(initialFormGroup);

    const [show, setShow] = useState(false);
    const [state, setState] = React.useState<Istate>({
        fieldConfig: { controls: {} }
    });

    const defaultBuildingID = { value: 'unallocated', label: 'Unallocated' };

    const handleClose = () => {
        setShow(false);
        props.closeFilterAssetModal();
    };

    const applyFilter = () => {
        setShow(false);
        props.closeFilterAssetModal();
        let filter: any = {
            page: 0,
            floor: null,
            location: null,
            room: null,
            building: null,
            measurementPointListResultStatus: null,
            workOrderResultStatus: null,
            mainCategory: null,
            originalMeasurementPointListResultStatus: null
        };

        if (searchForm && searchForm.value && searchForm.value.workOrderResultStatus) {
            filter = {
                ...filter,
                workOrderResultStatus: {
                    value: searchForm.value.workOrderResultStatus.value,
                    label: searchForm.value.workOrderResultStatus.label
                }
            };
        }

        if (searchForm && searchForm.value && searchForm.value.measurementPointListResultStatus) {
            filter = {
                ...filter,
                measurementPointListResultStatus: {
                    value: searchForm.value.measurementPointListResultStatus.value,
                    label: searchForm.value.measurementPointListResultStatus.label
                }
            };
        }

        if (searchForm && searchForm.value && searchForm.value.originalMeasurementPointListResultStatus) {
            filter = {
                ...filter,
                originalMeasurementPointListResultStatus: {
                    value: searchForm.value.originalMeasurementPointListResultStatus.value,
                    label: searchForm.value.originalMeasurementPointListResultStatus.label
                }
            };
        }

        if (searchForm && searchForm.value && searchForm.value.building) {
            filter = {
                ...filter,
                building: {
                    value: searchForm.value.building.value,
                    label: searchForm.value.building.label
                }
            };
        }

        if (searchForm && searchForm.value && searchForm.value.floor) {
            filter = {
                ...filter,
                floor: {
                    value: searchForm.value.floor.value,
                    label: searchForm.value.floor.label
                }
            };
        }

        if (searchForm && searchForm.value && searchForm.value.location) {
            filter = {
                ...filter,
                location: {
                    value: searchForm.value.location.value,
                    label: searchForm.value.location.label
                }
            };
        }

        if (searchForm && searchForm.value && searchForm.value.room) {
            filter = {
                ...filter,
                room: {
                    value: searchForm.value.room.value,
                    label: searchForm.value.room.label
                }
            };
        }

        if (searchForm && searchForm.value && searchForm.value.mainCategory) {
            filter = {
                ...filter,
                mainCategory: {
                    value: searchForm.value.mainCategory.value,
                    label: searchForm.value.mainCategory.label
                }
            };
        }

        props.setTableFilter(filter);
    };

    const buildSearchControls = (): FieldConfig => {
        const {
            building,
            floor,
            location,
            room,
            measurementPointListResultStatus,
            workOrderResultStatus,
            mainCategory,
            system,
            originalMeasurementPointListResultStatus
        } = props.tableFilters;

        const disabled = false;
        const foundBuilding = find(props.buildings, (b: Ibuilding) => {
            return building && b.id === building.value;
        });
        if (building && !foundBuilding) {
            props.clearTableFilter();
        }
        let floorOptions;
        let locationOptions;
        let roomOptions;

        const buildingOptions = FormUtil.convertToOptions([...props.buildings, defaultBuildingID]);

        if (foundBuilding && typeof foundBuilding === 'object' && foundBuilding.floors) {
            floorOptions = FormUtil.convertToOptions(foundBuilding.floors);
            if (floor) {
                const foundFloor = find(foundBuilding.floors, (f: Ifloor) => {
                    return f.id === floor.value;
                });
                if (foundFloor) {
                    locationOptions = FormUtil.convertToOptions(foundFloor.locations);
                    if (location) {
                        const foundLocation = find(foundFloor.locations, (l: Ilocation) => {
                            return l.id === location.value;
                        });
                        if (foundLocation) {
                            roomOptions = FormUtil.convertToOptions(foundLocation.rooms);
                        }
                    }
                }
            }
        }

        const measurementPointListResultStatusOptions = [
            ...FormUtil.convertEnumToOptions(inventoryStatusFilterEnum)
        ].map(item => {
            return {
                value: item.value,
                label: props.t('manageInventory:' + item.label)
            };
        });
        const workOrderResultStatusOptions = FormUtil.convertEnumToOptions(workOrderStatusEnum).map(item => {
            return {
                value: item.value,
                label: props.t('manageWorkOrder:' + item.label)
            };
        });

        let mainSearchControls = {
            building: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    label: 'common:building',
                    options: buildingOptions,
                    colWidth: 5,
                    colWidthLarge: 4,
                    type: 'text',
                    placeholder: 'searchPlaceholder',
                    isClearable: true
                },
                formState: { value: building, disabled }
            },
            floor: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    label: 'floor',
                    colWidth: 4,
                    colWidthLarge: 4,
                    placeholder: 'common:searchPlaceholder',
                    options: floorOptions,
                    isClearable: true
                },
                formState: { value: floor, disabled }
            }
        } as { [key: string]: GroupProps };

        let additionalSearchControls = {
            location: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    label: 'location',
                    colWidth: 4,
                    colWidthLarge: 2,
                    placeholder: 'common:searchPlaceholder',
                    options: locationOptions,
                    isClearable: true
                },
                formState: { value: location, disabled }
            },
            room: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    label: 'room',
                    colWidth: 4,
                    colWidthLarge: 2,
                    placeholder: 'common:searchPlaceholder',
                    options: roomOptions,
                    isClearable: true
                },
                formState: { value: room, disabled }
            },

            measurementPointListResultStatus: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    label: 'status',
                    options: measurementPointListResultStatusOptions,
                    colWidth: 4,
                    colWidthLarge: 3,
                    type: 'select',
                    placeholder: 'manageInventory:measurementPointListResultStatusPlaceholder',
                    isClearable: true,
                    shouldTranslate: true
                },
                formState: {
                    value: measurementPointListResultStatus,
                    disabled
                }
            },
            workOrderResultStatus: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    label: 'manageWorkOrder:workOrderResultStatus',
                    options: workOrderResultStatusOptions,
                    colWidth: 4,
                    colWidthLarge: 3,
                    type: 'select',
                    placeholder: 'manageWorkOrder:workOrderResultStatusPlaceholder',
                    isClearable: true,
                    shouldTranslate: true
                },
                formState: { value: workOrderResultStatus, disabled }
            },
            originalMeasurementPointListResultStatus: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    options: measurementPointListResultStatusOptions.map(({ value, label }) => ({
                        value: value,
                        label: props.t('manageMeasurementPointLists:' + label)
                    })),
                    label: 'status',
                    colWidth: 4,
                    placeholder: 'Search',
                    name: 'originalMeasurementPointListResultStatus',
                    isClearable: true
                },
                formState: { value: originalMeasurementPointListResultStatus, disabled }
            },
            mainCategory: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    label: 'common:mainCategory',
                    options: props.productInfo.mainCategoryOptions,
                    colWidth: 5,
                    colWidthLarge: 4,
                    type: 'select',
                    placeholder: 'searchPlaceholder',
                    isClearable: true,
                    shouldTranslate: false
                },
                formState: { value: mainCategory, disabled }
            },
            system: {
                render: FormUtil.Select,
                meta: {
                    options: constants.constructSystems(props.t),
                    label: 'manageInventory:system',
                    colWidth: 4,
                    placeholder: 'nsJob:typePlaceholder',
                    name: 'system',
                    disableSort: true,
                    isClearable: true
                },
                formState: { value: system, disabled }
            }
        } as { [key: string]: GroupProps };

        if (props.selectedJob.jobTypeID !== jobTypesIdEnum.agsRebalancing) {
            additionalSearchControls = omit(additionalSearchControls, 'system');
        }

        if (props.isAddWorkOrderMode === true) {
            additionalSearchControls = omit(additionalSearchControls, [
                'workOrderResultStatus',
                'measurementPointListResultStatus'
            ]);
        } else {
            mainSearchControls = omit(mainSearchControls, ['$field_1']);
            additionalSearchControls = omit(additionalSearchControls, ['originalMeasurementPointListResultStatus']);
        }

        if (props.isWorkOrderTypeJob) {
            additionalSearchControls = omit(additionalSearchControls, 'measurementPointListResultStatus');
        } else {
            additionalSearchControls = omit(
                additionalSearchControls,
                'workOrderResultStatus',
                'originalMeasurementPointListResultStatus'
            );
        }

        return { controls: { ...mainSearchControls, ...additionalSearchControls } };
    };

    React.useEffect(() => {
        setShow(props.show);

        if (props.show) {
            setState({ ...state, fieldConfig: FormUtil.translateForm(buildSearchControls(), props.t) });
        }
    }, [props.show]);

    React.useEffect(() => {
        if (searchForm) {
            // Subscribe to value changes
            const buildingSubscription = searchForm.get('building')?.valueChanges.subscribe((value: Ioption) => {
                onValueChanges(value.value, 'building');
            }) as any;

            const floorSubscription = searchForm.get('floor')?.valueChanges.subscribe((value: Ioption) => {
                if (value !== null) {
                    onValueChanges(value.value, 'floor');
                }
            }) as any;

            const locationSubscription = searchForm.get('location')?.valueChanges.subscribe((value: Ioption) => {
                if (value !== null) {
                    onValueChanges(value.value, 'location');
                }
            }) as any;

            const roomSubscription = searchForm.get('room')?.valueChanges.subscribe((value: Ioption) => {
                if (value !== null) {
                    onValueChanges(value.value, 'room');
                }
            }) as any;

            const measurementPointListResultStatusSubscription = searchForm
                .get('measurementPointListResultStatus')
                ?.valueChanges.subscribe((value: Ioption) => {
                    if (value !== null) {
                        onValueChanges(value.value, 'measurementPointListResultStatus');
                    }
                }) as any;

            const workOrderResultStatusSubscription = searchForm
                .get('workOrderResultStatus')
                ?.valueChanges.subscribe((value: Ioption) => {
                    if (value !== null) {
                        onValueChanges(value.value, 'workOrderResultStatus');
                    }
                }) as any;

            const originalMeasurementPointListResultStatusSubscription = searchForm
                .get('originalMeasurementPointListResultStatus')
                ?.valueChanges.subscribe((value: Ioption) => {
                    if (value !== null) {
                        onValueChanges(value.value, 'originalMeasurementPointListResultStatus');
                    }
                }) as any;

            const mainCategorySubscription = searchForm
                .get('mainCategory')
                ?.valueChanges.subscribe((value: Ioption) => {
                    if (value !== null) {
                        onValueChanges(value.value, 'mainCategory');
                    }
                }) as any;

            // Clean up the subscription when the component unmounts
            return () => {
                if (buildingSubscription) {
                    buildingSubscription.unsubscribe();
                }
                if (floorSubscription) {
                    floorSubscription.unsubscribe();
                }
                if (locationSubscription) {
                    locationSubscription.unsubscribe();
                }
                if (roomSubscription) {
                    roomSubscription.unsubscribe();
                }
                if (measurementPointListResultStatusSubscription) {
                    measurementPointListResultStatusSubscription.unsubscribe();
                }
                if (workOrderResultStatusSubscription) {
                    workOrderResultStatusSubscription.unsubscribe();
                }
                if (originalMeasurementPointListResultStatusSubscription) {
                    originalMeasurementPointListResultStatusSubscription.unsubscribe();
                }
                if (mainCategorySubscription) {
                    mainCategorySubscription.unsubscribe();
                }
            };
        }
    }, [searchForm]);

    const filterFloors = (value: string | null) => {
        if (value) {
            const buildingID = value;
            const foundBuilding = find(props.buildings, (b: Ibuilding) => {
                return b.id === buildingID;
            });
            let newFloorOptions: any[] = [];

            if (foundBuilding) {
                newFloorOptions = FormUtil.convertToOptions(foundBuilding.floors);
            }
            const floorControl = searchForm?.get('floor') as any;
            floorControl.meta.options = newFloorOptions;
            floorControl.stateChanges.next();
        }
        const locationControl = searchForm?.get('location') as any;
        if (locationControl) {
            locationControl.meta.options = [];
            locationControl.stateChanges.next();
        }
        const roomControl = searchForm?.get('room') as any;
        if (roomControl) {
            roomControl.meta.options = [];
            roomControl.stateChanges.next();
        }

        (searchForm as FormGroup).patchValue({ floor: null, location: null, room: null });
    };

    const filterLocations = (value: string | null) => {
        if (value === null && (props.tableFilters.floor === null || props.tableFilters.floor === undefined)) {
            return;
        }

        const { building } = searchForm.value;
        if (building && value && value) {
            const floorID = value;
            const foundBuilding = find(props.buildings, (b: Ibuilding) => {
                return b.id === building.value;
            });
            let newLocationOptions: any[] = [];

            if (foundBuilding) {
                const floor = find(foundBuilding.floors, (f: Ifloor) => {
                    return f.id === floorID;
                });
                if (floor) {
                    newLocationOptions = FormUtil.convertToOptions(floor.locations);
                }
            }

            const locationControl = searchForm.get('location') as any;
            if (locationControl) {
                locationControl.meta.options = newLocationOptions;
                locationControl.stateChanges.next();
            }
        }
        // Reset other selects
        const roomControl = searchForm.get('room') as any;
        if (roomControl) {
            roomControl.meta.options = [];
        }

        (searchForm as FormGroup).patchValue({ location: null, room: null });
    };

    const filterRooms = (value: string | null) => {
        if (value === null && (props.tableFilters.location === null || props.tableFilters.location === undefined)) {
            return;
        }
        const { building, floor } = searchForm.value;
        if (building && floor && value && value) {
            const locationID = value;
            const foundBuilding = find(props.buildings, (b: Ibuilding) => {
                return b.id === building.value;
            });
            let newRoomOptions: any[] = [];

            if (foundBuilding) {
                const foundFloor = find(foundBuilding.floors, (f: Ifloor) => {
                    return f.id === floor.value;
                });
                if (foundFloor) {
                    const loc = find(foundFloor.locations, (l: Ilocation) => {
                        return l.id === locationID;
                    });
                    if (loc) {
                        newRoomOptions = FormUtil.convertToOptions(loc.rooms);
                    }
                }
            }
            const roomControl = searchForm.get('room') as any;
            roomControl.meta.options = newRoomOptions;
            roomControl.stateChanges.next();
        }
        // set to null outside of the if, because we might receive null as the value
        (searchForm as FormGroup).patchValue({ room: null });
    };

    const onValueChanges = (value: any, key: string) => {
        switch (key) {
            case 'building':
                filterFloors(value);
                break;
            case 'location':
                filterRooms(value);
                break;
            case 'floor':
                filterLocations(value);
                break;
            default:
                break;
        }
    };

    const setForm = (form: FormGroup | FormArray) => {
        form.meta = {};
        setSearchForm(form);
    };

    return (
        <div className="filter--table-modal-mobile">
            <Modal className="filter-table-modal-mobile" show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>{props.t('filterSearch')}</Modal.Title>
                    <Button onClick={handleClose}>
                        <FontAwesomeIcon icon={['far', 'times']} size="lg" style={{ color: props.primaryColor }} />
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <form className="filter-asset-form-mobile">
                        <FormGenerator onMount={setForm} fieldConfig={state.fieldConfig} />
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={applyFilter}
                        style={{
                            backgroundColor: props.bgColor,
                            borderColor: props.brColor
                        }}
                    >
                        {props.t('applyFilters')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state: IinitialState, ownProps: Iprops) => {
    return {};
};

export default withTranslation('nsJob')(connect(mapStateToProps, {})(FilterAssetTableModal));
