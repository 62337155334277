/*
 * LeftColumnContainer connects to redux and lays out the edit device, comments, and history
 */

import * as React from 'react';

import {
    Ifacility,
    IinstallBasePopulated,
    Ijob,
    ImeasurementPointResult,
    IproductInfo,
    IWorkOrder,
    IMPLRwithMeasurementType
} from '../../models';
import { IinitialState } from '../../reducers';
import { toastr } from 'react-redux-toastr';

import { Button, Panel, PanelGroup, Row } from 'react-bootstrap';
import { Redirect, RouteComponentProps } from 'react-router';
import { withTranslation, WithTranslation } from 'react-i18next';
import { toggleEditInstallModal, installContact } from '../../actions/manageInventoryActions';
import {
    initialFacility,
    initialInstallBase,
    initialInstallBasePopulated,
    initialProduct
} from '../../reducers/initialState';

import { EditInstallContainer } from '../manageInventory/EditInstallContainer';
import { MeasurementPointListCommentForm } from './MeasurementPointListCommentForm';
import { MeasurementPointListResultHistory } from './MeasurementPointListHistory';
import { connect } from 'react-redux';
import { getAllMeasurementPointLists } from '../../actions/measurementPointListActions';
import { jobStatusEnum } from '../../models-enums';
import {
    clearHistoricalResultID,
    updateMeasurementPointResult,
    getMeasurementPointListResultHistory
} from '../../actions/measurementPointResultsActions';
import {
    selectInstallBaseHistoryByInstallBaseID,
    selectIsLoading,
    selectIsOnlineAndEmptyQueue
} from '../../reducers/commonReducers';
import { clearSelectedHistoricalWorkOrderID, setSelectedHistoricalWorkOrderID } from '../../actions/workOrderActions';
import InstallContactContainer from '../manageInventory/InstallContactContainer';
import { TestProceduresContainer } from './TestProcedures';
import { ProductLinksContainer } from './ProductLinks';
import { constants } from '../../constants/constants';
import styled from 'styled-components';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Iprops extends RouteComponentProps<{ installID: string }> {}

interface IdispatchProps {
    selectedInstall: IinstallBasePopulated;
    getAllMeasurementPointLists: typeof getAllMeasurementPointLists;
    loading: boolean;
    productInfo: IproductInfo;
    selectedJob: Ijob;
    facility: Ifacility;
    toggleEditInstallModal: typeof toggleEditInstallModal;
    updateMeasurementPointResult: typeof updateMeasurementPointResult;
    installBaseHistory: Array<IMPLRwithMeasurementType | IWorkOrder>;
    selectedResult: ImeasurementPointResult;
    setSelectedHistoricalWorkOrderID: typeof setSelectedHistoricalWorkOrderID;
    clearSelectedHistoricalWorkOrderID: typeof clearSelectedHistoricalWorkOrderID;
    clearHistoricalResultID: typeof clearHistoricalResultID;
    online: boolean;
    getMeasurementPointListResultHistory: typeof getMeasurementPointListResultHistory;
    isAPIQueueEmpty: boolean;
}
interface Istate {
    activeKey: number;
}

const SideLnks = styled.ul`
    width: 100%;
    & > li {
        padding: 10px;
    }
`;

const LeftColumnContainerClass: React.FC<IdispatchProps & Iprops & WithTranslation> = props => {
    const [state, setState] = React.useState<Istate>({
        activeKey: 1
    });

    const handleSelect = (activeKey: any) => {
        setState({ activeKey });
    };

    const loadHistory = () => {
        props.getMeasurementPointListResultHistory(props.selectedInstall.id, props.selectedJob.jobTypeID);
    };

    const { t } = props;

    // return home if their is no job or the job has been completed
    if (!props.selectedJob.id.length || props.selectedJob.status === jobStatusEnum.completed) {
        return (
            <Redirect
                to={{
                    pathname: '/'
                }}
            />
        );
    }
    return (
        <div>
            <PanelGroup
                accordion
                id="measurement-accordian"
                className="measurement-accordian"
                activeKey={state.activeKey}
                onSelect={handleSelect}
            >
                <Panel eventKey={1}>
                    <Panel.Heading>
                        <Panel.Title toggle>{t('leftColumn.commentsAndHistory')}</Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible>
                        {props.selectedResult.id.length > 0 && (
                            <MeasurementPointListCommentForm {...props} colorButton="primary" showCancel={false} />
                        )}
                        <Button disabled={!props.isAPIQueueEmpty} bsStyle="primary" type="button" onClick={loadHistory}>
                            Load History
                        </Button>
                        <MeasurementPointListResultHistory
                            clearSelectedHistoricalWorkOrderID={props.clearSelectedHistoricalWorkOrderID}
                            clearHistoricalResultID={props.clearHistoricalResultID}
                            t={props.t}
                            updateMeasurementPointResult={props.updateMeasurementPointResult}
                            installBaseID={props.match.params.installID}
                            installBaseHistory={props.installBaseHistory}
                            setSelectedHistoricalWorkOrderID={props.setSelectedHistoricalWorkOrderID}
                            loading={props.loading}
                            selectedJob={props.selectedJob}
                        />
                    </Panel.Body>
                </Panel>
                <Panel eventKey={2}>
                    <Panel.Heading>
                        <Panel.Title toggle>{t('leftColumn.deviceInfo')}</Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible>
                        <Row>
                            <EditInstallContainer
                                selectedItem={props.selectedInstall}
                                colorButton="primary"
                                showCancel={false}
                                history={props.history}
                                location={props.location}
                                match={props.match}
                            />
                        </Row>
                    </Panel.Body>
                </Panel>
                <Panel eventKey={3}>
                    <Panel.Heading>
                        <Panel.Title toggle>{t('leftColumn.testProcedures')}</Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible>
                        <TestProceduresContainer installID={props.match.params.installID} />
                    </Panel.Body>
                </Panel>
                <Panel eventKey={4}>
                    <Panel.Heading>
                        <Panel.Title toggle>{t('leftColumn.contactSupport')}</Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible>
                        <InstallContactContainer colorButton="primary" installID={props.match.params.installID} />
                    </Panel.Body>
                </Panel>
                <Panel eventKey={5}>
                    <Panel.Heading>
                        <Panel.Title toggle>{t('leftColumn.productLinks')}</Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible>
                        <ProductLinksContainer colorButton="primary" installID={props.match.params.installID} />
                    </Panel.Body>
                </Panel>
                <Panel eventKey={6}>
                    <Panel.Heading>
                        <Panel.Title toggle>{t('leftColumn.usefulLinks')}</Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible>
                        <Row>
                            <SideLnks>
                                <li>
                                    <a href="https://atlascopco.dezide.com/" target={'_blank'}>
                                        TRAX
                                    </a>
                                </li>
                                <li>
                                    <a href="https://webmyquest.compressedairbusiness.com/portal/" target={'_blank'}>
                                        MyQuest
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://onevirtualoffice.sharepoint.com/sites/bp_brand_beaconmedaes"
                                        target={'_blank'}
                                    >
                                        Business Portal BeaconMedas
                                    </a>
                                </li>
                            </SideLnks>
                        </Row>
                    </Panel.Body>
                </Panel>
            </PanelGroup>
        </div>
    );
};

const mapStateToProps = (state: IinitialState, ownProps: Iprops) => {
    const jobWorkOrders = Object.values(state.manageJob.jobWorkOrdersByID).filter(
        jwo => jwo.jobID === state.manageJob.selectedJob.id && !jwo.isDeleted
    );
    const install = state.manageInventory.installBasesByID[ownProps.match.params.installID] || initialInstallBase;
    const product = state.manageInventory.productsByID[install.productID] || initialProduct;
    const installWithProduct: IinstallBasePopulated = {
        ...initialInstallBasePopulated,
        ...install,
        product,
        workOrders: jobWorkOrders
            .filter(jwo => jwo.workOrder?.installBaseID === install.id)
            .map(jwo => jwo.workOrder || state.workOrder.workOrdersByID[jwo.workOrderID]) //sometimes jobWorkOrder.workOrder is undefined
            .filter(wo => wo !== undefined) as IWorkOrder[]
    };

    const installBaseHistory = selectInstallBaseHistoryByInstallBaseID(state, install.id);
    const facility = state.facilities.facilitiesByID[state.manageJob.selectedJob.facilityID] || initialFacility;
    return {
        user: state.user,
        loading: selectIsLoading(state),
        selectedInstall: installWithProduct,
        productInfo: state.productInfo,
        selectedJob: state.manageJob.selectedJob,
        facility,
        installBaseHistory,
        selectedResult: state.measurementPointResults.selectedResult,
        online: state.offline.online,
        isAPIQueueEmpty: selectIsOnlineAndEmptyQueue(state)
    };
};

export const LeftColumnContainer = withTranslation('measurementPoints')(
    connect(mapStateToProps, {
        getAllMeasurementPointLists,
        toggleEditInstallModal,
        updateMeasurementPointResult,
        setSelectedHistoricalWorkOrderID,
        installContact,
        clearHistoricalResultID,
        clearSelectedHistoricalWorkOrderID,
        getMeasurementPointListResultHistory
    })(LeftColumnContainerClass)
);
