/*
 * Edit Shopping Cart Form
 */

import { Button, Col, ControlLabel, FormControl, Panel, PanelGroup, Row } from 'react-bootstrap';
import { FieldConfig, FieldGroup, FormBuilder } from 'react-reactive-form';
import { toastr } from 'react-redux-toastr';

import { TFunction } from 'i18next';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { addPartToCart, deleteQuoteItemFromQuoteCart } from '../../../actions/partsActions';
import { clearCart, deleteFromCart, saveMessageInput, updateQuantityCart } from '../../../actions/shoppingCartActions';
import { constants } from '../../../constants/constants';
import { Ipart, IshoppingCartItem, IshoppingCartWithGroupedItems } from '../../../models';
import { partTypeEnum } from '../../../models-enums';
import { SearchAllPartsForm } from '../../manageJob/SearchAllPartsForm';
import { FormUtil } from '../FormUtil';
import { ShoppingCartSubSubItem } from './ShoppingCartSubSubItem';

export enum suggestedPartsQueryParamsEnum {
    showSearchPartsModal = 'showSearchPartsModal'
}
export interface ImetaNumberInputWithButton {
    label: string;
    placeholder?: string;
    buttonAction: (id: string) => void;
    id: string;
}
interface Iprops extends RouteComponentProps<any> {
    onSubmit: (message: string, items: IshoppingCartItem[]) => void;
    loading: boolean;
    colorButton: string;
    t: TFunction;
    selectedCart: IshoppingCartWithGroupedItems;
    updateQuantityCart: typeof updateQuantityCart;
    saveMessageInput: typeof saveMessageInput;
    deleteFromCart: typeof deleteFromCart;
    cartName: string;
    showCost?: boolean;
    addPartToCart: typeof addPartToCart;
    deleteQuoteItemFromQuoteCart: typeof deleteQuoteItemFromQuoteCart;
    clearCart: typeof clearCart;
}
interface Istate {
    fieldConfig: FieldConfig;
    activeKey: number;
    interalComments: string;
    localCart: IshoppingCartWithGroupedItems;
}

export class ShoppingCartForm extends React.Component<Iprops, Istate> {
    private subscription: any;
    private colorButton = constants.colors[`blueButton`];
    private handleLaborAdd = (part: Ipart) => {
        const matchPart = this.state.localCart.laborItems.find(
            item => item.name.includes(part.number) && parseInt(`${item.bundle}`) === part?.bundle
        );

        if (part.quantity === undefined) {
            part.quantity = 1;
        }

        if (matchPart) {
            toastr.error(this.props.t('toastMessage:duplicatedLaborPartBundle'), '', constants.toastrError);
        } else {
            this.props.addPartToCart(part, undefined, part?.bundle);
        }
    };

    constructor(props: Iprops) {
        super(props);

        this.state = {
            fieldConfig: { controls: {} },
            activeKey: 1,
            interalComments: this.props.selectedCart.message,
            localCart: this.props.selectedCart
        };
    }

    // TODO keep the message somewhere, patch the value
    componentDidMount() {
        // this.setFormConfig();
    }

    componentWillUnmount() {
        // TODO either subscribe to the cart changes and don't do anything here
        // or call something like:
        // this.props.onSubmit(this.formGroup.value.message, this.formGroup.value);
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    componentDidUpdate(prevProps: Iprops) {
        if (prevProps.selectedCart.addedIDs.length !== this.props.selectedCart.addedIDs.length) {
            this.setState({ localCart: this.props.selectedCart });
        }
    }

    formGroup = FormBuilder.group({});

    handleSelect = (activeKey: any) => {
        this.setState({ activeKey });
    };

    handleDeleteCartItem = (id: string, cartName: string) => {
        this.props.deleteQuoteItemFromQuoteCart(id);
        this.props.deleteFromCart(id, cartName);
    };

    handleSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (this.formGroup.status === 'INVALID') {
            this.formGroup.markAsSubmitted();
            toastr.error(this.props.t('toastMessage:invalidFormSubmission'), '', constants.toastrError);
            return;
        }

        this.props.onSubmit(this.state.interalComments, this.formValuesToItem(true));
        this.props.clearCart();
        this.props.history.goBack();
    };

    formValuesToItem = (updateQuantity = false): IshoppingCartItem[] => {
        const shoppingCartItems = Object.keys(this.formGroup.value).reduce(
            (items: IshoppingCartItem[], quotePartID) => {
                const item = FormUtil.getValues(this.formGroup.value[quotePartID]);

                if (updateQuantity) {
                    let tempQuantity: number = parseInt(item.quantity as string);
                    this.props.updateQuantityCart(tempQuantity, quotePartID, this.props.cartName);
                }
                return [
                    ...items,
                    {
                        id: quotePartID,
                        name: '',
                        quantity: item.quantity,
                        bundle: item.bundle
                    } as IshoppingCartItem
                ];
            },
            []
        );
        return shoppingCartItems;
    };

    handleEmptySubmit = () => {
        this.props.onSubmit('', []);
    };

    handleInputChange(event: any) {
        const { value } = event.currentTarget;
        this.setState({ interalComments: value });
    }

    render() {
        const { t } = this.props;
        const formClassName = `clearfix beacon-form manage-form ${this.props.colorButton}`;

        if (this.props.selectedCart.addedIDs.length === 0) {
            return (
                <div>
                    <h4 style={{ padding: '15px' }}>{this.props.t('partCartModal.emptyQuoteMessage')}</h4>
                    <Col xs={12} className="form-buttons text-right">
                        <Button
                            bsStyle={this.props.colorButton}
                            type="button"
                            onClick={() => this.handleEmptySubmit()}
                            disabled={this.props.loading}
                        >
                            {t('partCartModal.submitButton')}
                        </Button>
                    </Col>
                </div>
            );
        }

        return (
            <FieldGroup
                control={this.formGroup}
                strict={false}
                render={({ get, invalid, reset, value }) => (
                    <form
                        onSubmit={this.handleSubmit}
                        className={formClassName}
                        style={{ paddingLeft: '20px', paddingRight: '20px' }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                            <PanelGroup
                                accordion
                                id="cart-accordion"
                                activeKey={this.state.activeKey}
                                onSelect={this.handleSelect}
                                style={{ flexGrow: 1, marginRight: '5px' }}
                            >
                                {this.state.localCart.groupedItems.map((item, index) => {
                                    // PRODUCT
                                    return (
                                        <Panel eventKey={index + 1} key={item.name}>
                                            <Panel.Heading>
                                                <Panel.Title>{item.name}</Panel.Title>
                                            </Panel.Heading>
                                            <Panel.Body>
                                                <Row>
                                                    <Col xs={6}>{t('partCartModal.asset')}</Col>
                                                    <Col xs={3}>{t('partCartModal.bundle')}</Col>
                                                    <Col xs={3}>{t('partCartModal.quantity')}</Col>
                                                </Row>
                                                {item.subItems.map(subItem => {
                                                    // INSTALL BASE
                                                    return (
                                                        <div key={subItem.name}>
                                                            {subItem.subSubItems.map(subSubItem => (
                                                                // PART
                                                                <ShoppingCartSubSubItem
                                                                    subItemName={subItem.name}
                                                                    subSubItem={subSubItem}
                                                                    key={subSubItem.id}
                                                                    deleteAction={this.handleDeleteCartItem}
                                                                    cartName={this.props.cartName}
                                                                    main
                                                                />
                                                            ))}
                                                        </div>
                                                    );
                                                })}
                                            </Panel.Body>
                                        </Panel>
                                    );
                                })}
                            </PanelGroup>
                            <PanelGroup style={{ flexGrow: 1 }}>
                                <Panel>
                                    <Panel.Heading>
                                        <Panel.Title>{t('laborTravel')}</Panel.Title>
                                    </Panel.Heading>
                                    <Panel.Body>
                                        <Row style={{ display: 'flex' }}>
                                            <Col xs={12} style={{ padding: 0 }}>
                                                <SearchAllPartsForm
                                                    partType={partTypeEnum.labor}
                                                    colorButton={this.colorButton}
                                                    onSubmit={this.handleLaborAdd}
                                                    t={t}
                                                    loading={this.props.loading}
                                                    shouldShowQuote
                                                    shouldShowQuantity
                                                />
                                            </Col>
                                        </Row>
                                        <Row style={{ paddingTop: '20px' }}>
                                            <Col xs={6}>{t('partCartModal.asset')}</Col>
                                            <Col xs={3}>{t('partCartModal.bundle')}</Col>
                                            <Col xs={3}>{t('partCartModal.quantity')}</Col>
                                        </Row>
                                        <Row>
                                            {this.state.localCart.laborItems.map((laborItem, laborIndex) => {
                                                return (
                                                    <ShoppingCartSubSubItem
                                                        subSubItem={laborItem}
                                                        key={laborItem.id}
                                                        deleteAction={this.handleDeleteCartItem}
                                                        cartName={this.props.cartName}
                                                    />
                                                );
                                            })}
                                        </Row>
                                    </Panel.Body>
                                </Panel>

                                <Panel>
                                    <Panel.Heading>
                                        <Panel.Title>{t('partCartModal.internalComments')}</Panel.Title>
                                    </Panel.Heading>
                                    <Panel.Body>
                                        <FormControl
                                            componentClass="textarea"
                                            type="text"
                                            rows={20}
                                            value={this.state.interalComments}
                                            onChange={event => {
                                                this.handleInputChange(event);
                                            }}
                                        ></FormControl>
                                        <ControlLabel style={{ marginTop: '3px' }}>
                                            {t('cannotCompleteCommentLabel')}
                                        </ControlLabel>
                                    </Panel.Body>
                                </Panel>
                            </PanelGroup>
                        </div>

                        <Col xs={6} className="form-buttons text-left">
                            <Button
                                onClick={() => {
                                    this.props.history.goBack();
                                }}
                            >
                                {t('backButton')}
                            </Button>
                        </Col>
                        <Col xs={6} className="form-buttons text-right">
                            <Button bsStyle={this.props.colorButton} type="submit" disabled={this.props.loading}>
                                {t('partCartModal.submitButton')}
                            </Button>
                        </Col>
                    </form>
                )}
            />
        );
    }
}
