import * as localForage from 'localforage';
import * as types from './actionTypes';
import {
    GET_PHOTOS_SUCCESS,
    GET_PHOTOS_FAILED,
    PUT_PHOTO_SUCCESS,
    PUT_PHOTO_FAILED,
    DELETE_PHOTO_SUCCESS,
    DELETE_PHOTO_FAILED,
    ADD_NEW_PHOTO_SUCCESS,
    GET_PHOTOS
} from './actionTypes';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Iphoto, ThunkResult } from '../models';
import API from '../constants/apiEndpoints';
import { beginAjaxCall } from './ajaxStatusActions';
import { appSyncItemNames, constants } from '../constants/constants';
import { msalFetch } from '../components/auth/Auth-Utils';
import { AppSyncItemStatus } from '../models-enums';

const toBase64 = (file: File | Blob) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
        reader.readAsDataURL(file);
    });

export function getPhotos(jobID: string, facilityID: string, skipQueue: boolean = false): ThunkResult<any> {
    return (dispatch, getState) => {
        // If there's no job selected, don't try to get photos
        if (jobID === '') {
            console.error("Tried to get photos for a job that doesn't exist");
            return Promise.resolve(true);
        }

        const url = API.photo.getJobPhotos;
        const axiosOptions: AxiosRequestConfig = {
            method: 'get',
            params: {
                jobID: jobID
            },
            url
        };

        if (skipQueue) {
            dispatch({
                type: types.UPDATE_INITIAL_APP_SYNC_ITEM_STATUS,
                name: appSyncItemNames.JobPhotos,
                status: AppSyncItemStatus.inProgress
            });

            return msalFetch(url, axiosOptions)
                .then((data: AxiosResponse<any>) => {
                    dispatch({ type: types.REMOVE_INITIAL_APP_SYNC_ITEM, name: appSyncItemNames.JobPhotos });

                    dispatch({
                        type: GET_PHOTOS_SUCCESS,
                        payload: data,
                        facilityID
                    });
                })
                .catch((error: any) => {
                    constants.handleError(error, 'get photos');
                    console.error('[getPhotos]:', error);
                    dispatch({
                        type: types.UPDATE_INITIAL_APP_SYNC_ITEM_STATUS,
                        name: appSyncItemNames.JobPhotos,
                        status: AppSyncItemStatus.failed
                    });
                });
        } else {
            dispatch({
                type: GET_PHOTOS,
                meta: {
                    offline: {
                        effect: { axiosOptions, message: 'Get Photos' },
                        commit: { type: GET_PHOTOS_SUCCESS, facilityID }
                    }
                }
            });
        }
    };
}

export function addNewPhoto(photo: Iphoto): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const jobID = getState().manageJob.selectedJob.id;

        return localForage.getItem(photo.id).then(item => {
            if (item instanceof File || item instanceof Blob) {
                toBase64(item).then(base64Photo => {
                    /* eslint-disable @typescript-eslint/no-unused-vars */
                    const {
                        installBase,
                        productID,
                        product,
                        lightboxUrl,
                        thumbnailUrl,
                        updateDate,
                        createDate,
                        ...photoForApi
                    } = photo;
                    /* eslint-enable @typescript-eslint/no-unused-vars */

                    const axiosOptions: AxiosRequestConfig = {
                        method: 'post',
                        data: {
                            ...photoForApi,
                            file: (base64Photo as string).split(',')[1],
                            jobID
                        },
                        url: API.POST.photo.add
                    };
                    dispatch({
                        type: ADD_NEW_PHOTO_SUCCESS,
                        payload: { ...photo, jobID }
                    });
                    dispatch({
                        type: ADD_NEW_PHOTO_SUCCESS,
                        payload: { ...photo, jobID },
                        meta: {
                            offline: {
                                effect: {
                                    axiosOptions,
                                    message: 'add new photo'
                                },
                                rollback: {
                                    type: ADD_NEW_PHOTO_SUCCESS,
                                    payload: { ...photo, isDeleted: true }
                                }
                            }
                        }
                    });
                });
            } else {
                console.error('[addNewPhoto]:');
            }
        });
    };
}

export function updatePhoto(photo: Iphoto): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());

        /* eslint-disable @typescript-eslint/no-unused-vars */
        const { installBase, productID, product, file, createDate, updateDate, ...photoForApi } = photo;
        const { comments, isInReport, id } = photo;
        /* eslint-enable @typescript-eslint/no-unused-vars */
        const axiosOptions: AxiosRequestConfig = {
            method: 'put',
            data: photoForApi
        };
        dispatch({
            type: PUT_PHOTO_SUCCESS,
            payload: { id, comments, isInReport }
        });

        const url = `${API.photo.single}/update/${photo.id}`;
        return msalFetch(url, axiosOptions)
            .then((resp: AxiosResponse<any>) => {
                if (!resp.data) {
                    throw new Error('missing data');
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: PUT_PHOTO_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'update photo');
                console.error('[updatePhoto]:', error);
            });
    };
}

export function deletePhoto(id: string, installBaseID: string): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());

        const axiosOptions: AxiosRequestConfig = {
            method: 'delete'
        };
        const url = `${API.photo.single}/deletePhoto/${id}`;

        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                dispatch({
                    type: DELETE_PHOTO_SUCCESS,
                    payload: id
                });
            })
            .catch((error: any) => {
                dispatch({
                    type: DELETE_PHOTO_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'delete photo');
                console.error('[deletePhoto]:', error);
            });
    };
}
