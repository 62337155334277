import * as types from '../actions/actionTypes';

import {
    Ibrand,
    ImainCategory,
    Ipower,
    IproductInfo,
    IproductType,
    Istandard,
    Isubcategory,
    IsystemSize
} from '../models';

import { FormUtil } from '../components/common/FormUtil';
import initialState from './initialState';
import { keyBy } from 'lodash';
import { originOptions } from '../constants/constants';

/*
Brand, productType, Main Category, Power, Product Group, Standard, Subcategory, System Size
*/
export function productInfoReducer(state: IproductInfo = initialState.productInfo, action: any): IproductInfo {
    switch (action.type) {
        case types.GET_PRODUCT_INFO_SUCCESS: {
            const pi = action.data;
            const brands = keyBy(pi[0], (item: Ibrand) => item.id);
            const productTypes = keyBy(pi[1], (item: IproductType) => item.id);
            const mainCategories = keyBy(pi[2], (item: ImainCategory) => item.id);
            const powers = keyBy(pi[3], (item: Ipower) => item.id);
            const standards = keyBy(pi[4], (item: Istandard) => item.id);
            const subcategories = keyBy(pi[5], (item: Isubcategory) => item.id);
            const systemSizes = keyBy(pi[6], (item: IsystemSize) => item.id);
            const filteredBrands = keyBy(
                Object.values(brands).filter((b: Ibrand) => b.isManufacturer === true),
                (item: Ibrand) => item.id
            );

            // an options version of each one
            const brandOptions = FormUtil.convertToOptions(filteredBrands);
            const productTypeOptions = FormUtil.convertToOptions(pi[1]);
            const mainCategoryOptions = FormUtil.convertToOptions(pi[2]);
            const powerOptions = FormUtil.convertToOptions(pi[3]);
            const standardOptions = FormUtil.convertToOptions(pi[4]);
            const subcategoryOptions = FormUtil.convertToOptions(pi[5]);
            const systemSizeOptions = FormUtil.convertToOptions(pi[6]);

            return {
                brands,
                productTypes,
                mainCategories,
                powers,
                standards,
                subcategories,
                systemSizes,
                brandOptions,
                productTypeOptions,
                mainCategoryOptions,
                powerOptions,
                standardOptions,
                subcategoryOptions,
                systemSizeOptions,
                originOptions
            };
        }
        case types.USER_LOGOUT_SUCCESS:
            return initialState.productInfo;
        default:
            return state;
    }
}
