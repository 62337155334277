/*
 * Work Order Form
 *
 */

import * as React from 'react';

import { Button, Col, ControlLabel } from 'react-bootstrap';
import { FieldConfig, FormArray, FormGenerator, FormGroup, GroupProps, Validators } from 'react-reactive-form';
import { IWorkOrder, Ioption, WOClosingNote } from '../../models';
import { debounce, isEqual, omit } from 'lodash';
import {
    addWorkOrder,
    saveWorkOrderClosingNotes,
    saveWorkOrder,
    toggleEditPartModalForm,
    updateWorkOrder
} from '../../actions/workOrderActions';
import { workOrderPrioritiesEnum, workOrderStatusEnum, workOrderVendorsEnum } from '../../models-enums';

import { FormUtil } from '../common/FormUtil';
import { IworkOrderFormValues } from '../../modelsForms';
import { TFunction } from 'i18next';
import { constants } from '../../constants/constants';
import { initialWorkOrder } from '../../reducers/initialState';

import moment from 'moment';
import { getAssignedJobs } from '../../actions/manageJobActions';
import { ccWONotes } from '../../reducers/workOrderReducer';

interface Iprops {
    addWorkOrder: typeof addWorkOrder;
    saveWorkOrder: typeof saveWorkOrder;
    updateWorkOrder: typeof updateWorkOrder;
    saveWorkOrderClosingNotes: typeof saveWorkOrderClosingNotes;
    getJobs: typeof getAssignedJobs;
    loading: boolean;
    colorButton?: string;
    t: TFunction;
    selectedWorkOrder: IWorkOrder;
    standardOptions: Ioption[];
    selectedInstallBaseID: string;
    currentWorkOrder: IWorkOrder;
    shouldHideTechnicianSelect?: boolean;
    toggleEditPartModalForm: typeof toggleEditPartModalForm;
    disabled: boolean;
}

interface State {
    fieldConfig: FieldConfig;
    closingNotesFilled: boolean;
    newNote: string;
}

class WorkOrderForm extends React.Component<Iprops, State> {
    private formGroup: FormGroup | any;
    private subscription: any;
    private updateWorkOrderDebounced: (workOrder: Partial<IWorkOrder>) => void;
    static defaultProps = {
        colorButton: constants.colors[`blueButton`],
        selectedWorkOrder: initialWorkOrder
    };
    constructor(props: Iprops) {
        super(props);
        this.state = {
            fieldConfig: this.buildFieldConfig(),
            closingNotesFilled: false,
            newNote: ''
        };
        this.updateWorkOrderDebounced = debounce(this.props.updateWorkOrder, constants.formDebounceTime);
    }

    componentDidMount() {
        // we don't need to do this because this form is updating the work order directly
        document.addEventListener('submitWorkOrderForms', this.handleSubmitCustomEvent, false);
    }

    componentDidUpdate(prevProps: Iprops, prevState: State) {
        if (prevProps.selectedWorkOrder.status !== this.props.selectedWorkOrder.status) {
            this.resetForm();
        }
    }

    resetForm = () => {
        this.setState({ fieldConfig: this.buildFieldConfig() });
    };

    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        document.removeEventListener('updatedInstallBases', this.handleSubmitCustomEvent, false);
    }

    handleSubmitCustomEvent = () => {
        this.formGroup.handleSubmit();
    };

    notesListToOption = (notes: WOClosingNote[]) => {
        if (notes) {
            const fmtNotes = notes.map((note: WOClosingNote) => ({
                label: note.createDate,
                value: note.text
            }));

            return FormUtil.convertToOptions(fmtNotes);
        }

        return [];
    };

    /*
     * itemToFormValues - take the selectedItem and convert it to formValues
     */
    itemToFormValues = (): IworkOrderFormValues => {
        const { t } = this.props;
        const { vendor, priority, status } = this.props.selectedWorkOrder;
        const selectedVendor = {
            value: vendor,
            label: t(workOrderVendorsEnum[vendor])
        };
        const selectedPriority = {
            value: priority,
            label: t(workOrderPrioritiesEnum[priority])
        };
        const selectedStatus = {
            value: status,
            label: t(workOrderStatusEnum[status])
        };
        const cleanedWorkOrder = omit(this.props.selectedWorkOrder, [
            'preventativeMaintenanceChecklist',
            'jobWorkOrders',
            'closingNotesList',
            'activeClosingNotes',
            'facility',
            'product',
            'parts'
        ]);
        const activeClosingNotes = this.props.selectedWorkOrder?.activeClosingNotes
            ? FormUtil.convertToSingleOption(this.props.selectedWorkOrder.activeClosingNotes)
            : '';

        return {
            ...cleanedWorkOrder,
            closingNotesList:
                // verify if closingNotesList is set, this was breaking in the next block
                this.props.currentWorkOrder && this.props.currentWorkOrder?.closingNotesList
                    ? this.notesListToOption(this.props.currentWorkOrder?.closingNotesList as WOClosingNote[])
                    : undefined,
            activeClosingNotes,
            vendor: selectedVendor,
            priority: selectedPriority,
            status: selectedStatus
        };
    };

    /*
     * formValuesToItem - convert the formValues to the shape of the selectedItem
     */
    formValuesToItem = (): IWorkOrder => {
        const cleanedWorkOrder = omit(this.props.selectedWorkOrder, [
            'preventativeMaintenanceChecklist',
            'jobWorkOrders'
        ]);
        const formValues = FormUtil.getValues(this.formGroup.value);

        return {
            ...cleanedWorkOrder,
            ...formValues,
            installBaseID: this.props.selectedWorkOrder.installBaseID || this.props.selectedInstallBaseID
        };
    };

    buildFieldConfig = (defaultValues: IworkOrderFormValues = this.itemToFormValues()) => {
        const disabled = this.props.disabled;
        const isComplete =
            defaultValues.status &&
            defaultValues.status.value === workOrderStatusEnum.complete &&
            defaultValues.status.value;

        let due_Date = defaultValues.dueDate as string | moment.Moment;
        // if the dueDate is not a moment and there is no id, we are creating an untouched new work order and default to a month from today

        if (typeof defaultValues.dueDate === 'undefined') {
            due_Date = moment.utc(defaultValues.dueDate);
        }

        const fieldConfigControls = {
            closingNotes: {
                render: FormUtil.TextInput,
                meta: {
                    label: '',
                    hideLabel: true,
                    colWidth: 12,
                    componentClass: 'textarea',
                    rows: 2,
                    name: 'closingNote',
                    initialContent: defaultValues.activeClosingNotes
                        ? (defaultValues.activeClosingNotes as Ioption).value
                        : !defaultValues.closingNotesList
                        ? defaultValues.closingNotes
                        : ''
                },
                formState: {
                    // 12/6/21 - ClosingNotes should no longer prepopulate with data, we might need to fix this though if
                    // it's required to enter notes and close without saving and coming back
                    value: '',
                    disabled: isComplete
                }
            },
            $field_2: {
                isStatic: false, // ensures a key is added
                render: () => (
                    <Col
                        xs={6}
                        style={{
                            display: isComplete ? 'none' : 'flex',
                            justifyContent: 'flex-end',
                            marginBottom: '1rem',
                            width: '100%'
                        }}
                    >
                        <Button
                            type="button"
                            bsStyle={this.props.colorButton}
                            style={{
                                marginLeft: 'auto'
                            }}
                            disabled={!this.state.closingNotesFilled || Boolean(isComplete)}
                            onClick={() => this.saveClosingNotes(!isComplete)}
                        >
                            Save Notes
                        </Button>
                    </Col>
                )
            },
            number: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'woNumber',
                    colWidth: 3,
                    // autoFocus: true,
                    required: true,
                    name: 'number'
                },
                formState: { value: defaultValues.number, disabled }
            },
            dueDate: {
                render: FormUtil.Datetime,
                meta: {
                    label: 'dueDate',
                    colWidth: 3,
                    showTime: false,
                    name: 'expiration-date'
                },
                formState: {
                    value: due_Date,
                    disabled
                },
                options: FormUtil.validators.requiredWithTrim
            },
            /*
             * vendor is disabled when on the beacon tab.  In other words the Vendor select is only enabled on the Repair tab.
             */
            vendor: {
                options: {
                    validators: [FormUtil.validators.requiredWithTrim]
                },
                render: FormUtil.Select,
                meta: {
                    options: FormUtil.convertEnumToOptions(workOrderVendorsEnum),
                    label: 'vendor',
                    colWidth: 6,
                    name: 'vendor',
                    required: true,
                    isClearable: false,
                    shouldTranslate: true
                },
                formState: {
                    value: defaultValues.vendor,
                    disabled
                }
            },
            activityDescription: {
                render: FormUtil.TextInput,
                options: {
                    validators: [FormUtil.validators.requiredWithTrim]
                },
                meta: {
                    label: 'activityDescription',
                    colWidth: 6,
                    name: 'notes',
                    componentClass: 'textarea',
                    rows: 5,
                    initialContent: defaultValues.activityDescription,
                    required: true
                },
                formState: {
                    value: defaultValues.activityDescription,
                    disabled
                }
            },

            contactInfo: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'contactinfo',
                    colWidth: 6,
                    name: 'contactInfo'
                },
                formState: { value: defaultValues.contactInfo, disabled },
                options: {
                    validators: Validators.pattern(
                        /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/ //eslint-disable-line
                    )
                }
            },

            priority: {
                render: FormUtil.Select,
                meta: {
                    options: FormUtil.convertEnumToOptions(workOrderPrioritiesEnum),
                    label: 'priority',
                    colWidth: 3,
                    name: 'priority',
                    shouldTranslate: true
                },
                options: {
                    validators: [FormUtil.validators.requiredWithTrim]
                },
                formState: {
                    value: defaultValues.priority,
                    disabled
                }
            },

            status: {
                render: FormUtil.Select,
                meta: {
                    options: this.buildStatusOptions(),
                    label: 'status',
                    colWidth: 3,
                    name: 'status',
                    shouldTranslate: true
                },
                options: {
                    validators: [FormUtil.validators.requiredWithTrim]
                },
                formState: {
                    value: defaultValues.status,
                    disabled: this.shouldDisableStatusSelect()
                }
            },

            notes: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'notes',
                    colWidth: 6,
                    componentClass: 'textarea',
                    rows: 3,
                    name: 'notes',
                    initialContent: defaultValues.notes,
                    required: true
                },
                formState: { value: defaultValues.notes, disabled }
            },
            technician: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'tehnician',
                    colWidth: 6,
                    // autoFocus: true,
                    required: false,
                    name: 'tehnician'
                },
                formState: {
                    value: defaultValues.technician,
                    disabled
                }
            },
            $field_0: {
                isStatic: false, // ensures a key is added
                render: () => (
                    <Col
                        xs={6}
                        style={{
                            display: defaultValues.attachmentUrl1 ? '' : 'none'
                        }}
                    >
                        <Button bsStyle={this.props.colorButton} href={`${defaultValues.attachmentUrl1}`}>
                            Download Document
                        </Button>
                    </Col>
                )
            }
        } as { [key: string]: GroupProps };

        // hide the status when creating new
        if (!(this.props.selectedWorkOrder && this.props.selectedWorkOrder.id.length)) {
            delete fieldConfigControls.status;
        }

        return FormUtil.translateForm(
            {
                controls: { ...fieldConfigControls }
            },
            this.props.t
        );
    };

    /*
     * status = completed: the only selectable options should be "reopened"
     * status = reopened: empty
     * status = new: empty
     */
    buildStatusOptions = () => {
        const { status } = this.props.selectedWorkOrder;
        if (status === workOrderStatusEnum.complete) {
            return [
                {
                    value: `${workOrderStatusEnum.reopened}`,
                    label: this.props.t('reopened')
                }
            ];
        } else {
            return [];
        }
    };

    /*
     * status select should only be enabled if creating new or if the WO is completed
     */
    shouldDisableStatusSelect = () => {
        if (this.props.disabled) {
            return true;
        }
        if (this.props.selectedWorkOrder.id.length === 0) {
            return false;
        } else if (this.props.selectedWorkOrder.status === workOrderStatusEnum.complete) {
            return false;
        } else {
            return true;
        }
    };

    /*
     * (reusable)
     * subscribe to the formGroup changes
     */
    subscribeToChanges = () => {
        for (const key in this.formGroup.controls) {
            if (this.formGroup.controls.hasOwnProperty(key)) {
                this.subscription = this.formGroup.get(key).valueChanges.subscribe((value: any) => {
                    if (value !== null) {
                        this.onValueChanges(value, key);
                    }
                });
            }
        }
    };

    /*
     * (reusable)
     * set the table filters to redux on each value change
     */
    onValueChanges = (value: any, key: string) => {
        if (key === 'closingNotes') {
            this.setState({
                closingNotesFilled: Boolean(value)
            });

            if (value) {
                const newNote = this.createClosingNoteItem(value);
                this.updateWorkOrderDebounced({
                    id: this.props.selectedWorkOrder.id,
                    activeClosingNotes: newNote
                });
            }
        } else {
            this.updateWorkOrderDebounced({
                id: this.props.selectedWorkOrder.id,
                [key]: value
            });
        }
    };

    setForm = (form: FormGroup | FormArray) => {
        this.formGroup = form;
        this.formGroup.meta = {
            loading: this.props.loading
        };
        if (!this.subscription) {
            setTimeout(() => {
                this.subscribeToChanges();
            }, 300);
        }
    };

    cleanForm = () => {
        this.formGroup.reset();
    };

    createClosingNoteItem = (value: any): WOClosingNote => ({
        workOrderID: this.props.selectedWorkOrder.id,
        text: value,
        createDate: moment.utc().toISOString()
    });

    saveClosingNotes = (add: boolean) => {
        const note: WOClosingNote = {
            workOrderID: this.props.selectedWorkOrder.id,
            createDate: moment.utc().toISOString(),
            text: this.formGroup.controls.closingNotes.value
        };

        this.props.saveWorkOrderClosingNotes(this.props.selectedWorkOrder, note);
        this.formGroup.controls.closingNotes.setValue('');
        this.setState({
            closingNotesFilled: false
        });
    };

    getClosingNotesList = (wo: IWorkOrder): WOClosingNote[] => {
        let notes = wo.closingNotesList || [];
        if (wo.closingNotes) {
            try {
                const potentialNotes = ccWONotes(JSON.parse(wo.closingNotes));
                potentialNotes.forEach(potentialNote => {
                    if (notes.find(n => isEqual(n, potentialNote)) === undefined) {
                        notes = notes.concat(potentialNote);
                    }
                });
            } catch (e) {
                console.error('error parsing closing notes', e);
            }
        }
        return notes;
    };

    render() {
        return (
            <div>
                <form
                    onSubmit={() => {
                        return;
                    }}
                    className="clearfix beacon-form work-order-form"
                >
                    {/*
                        Most efficient way to throw this dynamic list is outside the FormGenerator.
                        So to fake it being between the label and input, we're hiding the actual label and showing this one.
                    */}
                    <ControlLabel
                        style={{
                            textTransform: 'uppercase',
                            wordBreak: 'unset'
                        }}
                        className="wo-closing-notes-label"
                        aria-hidden="true"
                    >
                        {this.props.t('closingNote')}
                    </ControlLabel>
                    {(this.props.selectedWorkOrder.closingNotesList || this.props.selectedWorkOrder.closingNotes) && (
                        <ul className="wo-closing-notes" style={{ marginBottom: '10px' }}>
                            {this.getClosingNotesList(this.props.selectedWorkOrder).map(note => (
                                <li key={note.workOrderID}>
                                    <time dateTime={note?.createDate}>
                                        {moment(note?.createDate).format('YYYY-MM-DD')}
                                    </time>{' '}
                                    - {note?.text}
                                </li>
                            ))}
                        </ul>
                    )}
                    <FormGenerator
                        onUnmount={this.cleanForm}
                        onMount={this.setForm}
                        fieldConfig={this.state.fieldConfig}
                    />
                </form>
            </div>
        );
    }
}
export default WorkOrderForm;
