import * as React from 'react';

import { Button, Col, Row } from 'react-bootstrap';
import { Ifacility, Ijob, ItableFiltersReducer, Itile } from '../../models';
import { WithTranslation, withTranslation } from 'react-i18next';
import { setTableFilter, toggleConfirmSelectJobModal } from '../../actions/workOrderActions';

import { Banner } from '../common/Banner';
import { FieldConfig } from 'react-reactive-form';
import { FormUtil } from '../common/FormUtil';
import { IinitialState } from '../../reducers';
import { PreventativeMaintenanceTableWithSelectContainer } from './PreventativeMaintenanceTableWithSelectContainer';
import { RouteComponentProps } from 'react-router';
import SearchTableForm from '../common/SearchTableForm';
import { connect } from 'react-redux';
import { constants } from '../../constants/constants';
import { emptyTile, initialFacility } from '../../reducers/initialState';
import { selectIsLoading } from '../../reducers/commonReducers';
import { jobTypesIdEnum } from '../../models-enums';
import { TFunction } from 'i18next';
import { toastr } from 'react-redux-toastr';

interface Iprops extends RouteComponentProps<any> {
    loading: boolean;
    t: TFunction;
}

interface IdispatchProps {
    initComplete: boolean;
    tableFilters: ItableFiltersReducer;
    setTableFilter: typeof setTableFilter;
    toggleConfirmSelectJobModal: typeof toggleConfirmSelectJobModal;
    facility: Ifacility;
    selectedJob: Ijob;
}

interface Istate {
    selectedRow: any;
    currentTile: Itile;
    searchFieldConfig: FieldConfig;
    filterLength: number;
}

class ManageWorkOrder extends React.Component<Iprops & IdispatchProps & WithTranslation, Istate> {
    private searchDebounce: any;

    constructor(props: Iprops & IdispatchProps & WithTranslation) {
        super(props);
        this.state = {
            selectedRow: null,
            currentTile: emptyTile,
            filterLength: 0,
            searchFieldConfig: this.buildSearchFieldConfig()
        };
    }

    componentDidMount() {
        this.setState({
            currentTile: constants.getTileByURL(this.props.location.pathname)
        });
        this.verifyMaintenanceJob();
    }
    verifyMaintenanceJob = () => {
        if (this.props.selectedJob.jobTypeID !== jobTypesIdEnum.maintenance) {
            toastr.warning(
                this.props.t('toastMessage:warning'),
                this.props.t('toastMessage:mobileOnlyMaintenanceJobs'),
                constants.toastrWarning
            );
        }
    };

    buildSearchFieldConfig = (): FieldConfig => {
        const { search } = this.props.tableFilters;
        const disabled = false;
        const mainSearchControls = {
            search: {
                // TODO adding scope for this in a change request. waiting for approval.  Still need to update view to flow to two rows when on smaller width
                render: FormUtil.TextInputWithoutValidation,
                meta: {
                    label: 'search',
                    colWidth: 5,
                    colWidthLarge: 4,
                    type: 'text',
                    placeholder: 'searchPlaceholderMobile'
                },
                formState: { value: search, disabled }
            }
        };

        this.setState({ filterLength: Object.keys(mainSearchControls).length });

        return { controls: { ...mainSearchControls } };
    };

    startAddWorkOrders = () => {
        this.props.toggleConfirmSelectJobModal();
    };

    onSearchValueChanges = (value: any, key: string) => {
        switch (key) {
            default:
                this.searchDebounce = setTimeout(() => {
                    this.props.setTableFilter({ [key]: value });
                }, 500);
                break;
        }
    };

    render() {
        if (this.props.initComplete === false) {
            return (
                <Col xs={12}>
                    <h4> loading... </h4>
                </Col>
            );
        }
        const { t } = this.props;

        return (
            <div
                className="manage-job"
                style={{
                    ['--filter-size' as string]: this.state.filterLength
                }}
            >
                <Banner
                    title={t('maintenance')}
                    img={this.state.currentTile.srcBanner}
                    color={this.state.currentTile.color}
                    subtitle={this.props.facility.name}
                />
                <Row data-flex="true">
                    <Col xs={9}>
                        <SearchTableForm
                            fieldConfig={this.state.searchFieldConfig}
                            handleSubmit={() => {
                                return;
                            }}
                            loading={this.props.loading}
                            colorButton={
                                constants.colors[
                                    `${this.state.currentTile.color}Button` as keyof typeof constants.colors
                                ]
                            }
                            t={this.props.t}
                            subscribeValueChanges={true}
                            onValueChanges={this.onSearchValueChanges}
                            showSearchButton={false}
                        />
                    </Col>
                    <Col xs={3} style={{ paddingTop: '35px', paddingRight: '20px' }}>
                        <Button onClick={this.startAddWorkOrders}>{this.props.t('addMaintenanceToJob')}</Button>
                    </Col>
                </Row>

                <PreventativeMaintenanceTableWithSelectContainer
                    colorButton={
                        constants.colors[`${this.state.currentTile.color}Button` as keyof typeof constants.colors]
                    }
                    location={this.props.location}
                    match={this.props.match}
                    history={this.props.history}
                />
            </div>
        );
    }
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops) => {
    const { selectedJob } = state.manageJob;
    const facility = state.facilities.facilitiesByID[selectedJob.facilityID] || initialFacility;

    return {
        user: state.user,
        loading: selectIsLoading(state),
        initComplete: state.syncStatus.isInitialSyncComplete,
        tableFilters: state.workOrder.tableFilters,
        facility,
        selectedJob
    };
};

export default withTranslation('manageWorkOrder')(
    connect(mapStateToProps, {
        setTableFilter,
        toggleConfirmSelectJobModal
    })(ManageWorkOrder)
);
