import { WithTranslation, withTranslation } from 'react-i18next';
import { setHoursFormValues, updateHoursFormValue } from '../../../actions/manageJobActions';

import AddNPHModalForm from './AddNPHModalForm';
import { IinitialState } from '../../../reducers';
import { WithCommonModal } from '../../common/WithCommonModal';
import { connect } from 'react-redux';
import { toggleAddHoursModal } from '../../../actions/manageInventoryActions';
import { initialJobHour } from '../../../reducers/initialState';
import { selectIsLoading } from '../../../reducers/commonReducers';
import { IlaborRate, INonProductiveHour } from '../../../models';
import { saveNonProductiveHours } from '../../../actions/userActions';

interface Iprops {
    show?: boolean;
    colorButton: string;
    modalClass?: string;
    shouldHideDelete?: boolean;
    secondModal?: boolean;
    disabled?: boolean;
    wideModal?: boolean;
    onClose?: () => void;
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops & WithTranslation) => {
    const { selectedJob } = state.manageJob;
    const { laborRates } = state;
    const nonProductiveJobRates = laborRates.filter(
        (laborRate: IlaborRate) => laborRate.type?.toLocaleLowerCase() === 'nonproductive'
    );

    let selectedJobHour = initialJobHour;

    // Safe check everything
    if (selectedJob && selectedJob.jobHours) {
        const foundJobHours = selectedJob.jobHours.find(item => item.id === state.manageJob.selectedJobHourID);
        if (foundJobHours) {
            selectedJobHour = foundJobHours;
        }
    }
    const title = ownProps.t('jobHoursForm.addNonProductiveHoursTitle');
    const nonProductiveHours: INonProductiveHour[] = state.user.nonProductiveHours;

    return {
        className: 'hours-modal',
        loading: selectIsLoading(state),
        show: ownProps.show !== undefined ? ownProps.show : state.manageInventory.showAddHoursModal,
        onClose: ownProps.onClose,
        formValues: {}, // TODO need to refactor this form to save the selectedItem in redux
        laborRates: nonProductiveJobRates,
        selectedJob,
        selectedJobHour,
        currentUserId: state.user.id,
        title,
        tableData: nonProductiveHours
    };
};

export default withTranslation('nsJob')(
    connect(mapStateToProps, {
        toggleModal: toggleAddHoursModal,
        updateFormValue: updateHoursFormValue,
        setFormValues: setHoursFormValues,
        onHide: toggleAddHoursModal,
        saveNonProductiveHours
    })(WithCommonModal(AddNPHModalForm))
);
