import React from 'react';
import { Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import styled from 'styled-components';
import { constants } from '../../../constants/constants';

interface Iprops {
    isHistoricalWorkOrder: boolean;
    isAuditJob: boolean;
    isRepairJob: boolean;
    closeJob: () => void;
    t: any;
    loading: boolean;
    handleRepairClick: () => void;
    handleCannotComplete: () => void;
    hasClosingNotes: boolean;
    fetchStatuses: () => void;
    clearHistoricalResult: () => void;
    copyHistory: (event: any) => void;
    handleSubmit: () => void;
}

const ButtonGroup = styled.div`
    background-color: #eeeeee;
    bottom: 0;
    column-gap: 24px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    left: 0;
    padding: 16px;
    position: fixed;
    right: 0;
    row-gap: 8px;
    z-index: 499;

    & > button {
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-basis: calc(50% - 12px);
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
`;

const PointListButtonBar = (props: Iprops) => {
    const { t, handleSubmit } = props;

    if (props.isAuditJob) {
        return (
            <ButtonGroup>
                <LinkContainer to="/devices">
                    <Button type="button" onClick={props.fetchStatuses}>
                        {t('close')}
                    </Button>
                </LinkContainer>
                <Button
                    onClick={() => {
                        handleSubmit();
                    }}
                    bsStyle="primary"
                    className="pull-right"
                    disabled={props.loading}
                >
                    {t('saveLog')}
                </Button>
            </ButtonGroup>
        );
    }

    if (props.isHistoricalWorkOrder) {
        return (
            <ButtonGroup>
                <LinkContainer to="/devices">
                    <Button type="button" onClick={props.fetchStatuses}>
                        {t('close')}
                    </Button>
                </LinkContainer>
                <Button
                    type="button"
                    style={{ color: constants.colors.orange }}
                    disabled={props.loading}
                    onClick={props.clearHistoricalResult}
                >
                    {t('finishViewingHistoricalResultButton')}
                </Button>

                <Button type="button" disabled={props.loading} onClick={props.copyHistory}>
                    Copy History
                </Button>
            </ButtonGroup>
        );
    }

    return (
        <ButtonGroup>
            <Button
                type="button"
                style={{
                    padding: '10px 20px'
                }}
                disabled={props.loading}
                onClick={props.handleCannotComplete}
            >
                {t('cannotComplete')}
            </Button>
            {props.isRepairJob === false && (
                <Button
                    type="button"
                    style={{ color: constants.colors.orange }}
                    disabled={props.loading}
                    onClick={props.handleRepairClick}
                >
                    {t('repairButton')}
                </Button>
            )}
            <Button type="button" onClick={props.closeJob} disabled={props.loading}>
                {t('close')}
            </Button>
            <Button
                disabled={props.loading || !props.hasClosingNotes}
                data-withclosingnotes={props.hasClosingNotes}
                bsStyle="primary"
                onClick={() => {
                    handleSubmit();
                }}
            >
                {t('saveLog')}
            </Button>
        </ButtonGroup>
    );
};

export default PointListButtonBar;
