/*
 *
 */

import { WithTranslation, withTranslation } from 'react-i18next';
import {
    toggleAddRepairWorkOrderModal,
    toggleConfirmSelectJobModal,
    addWorkOrder,
    bulkCreateWorkOrders
} from '../../actions/workOrderActions';

import { ConfirmSelectJobForm } from './ConfirmSelectJobForm';
import { IinitialState } from '../../reducers';
import { connect } from 'react-redux';
import { prepJobsForOptions } from '../../reducers/manageJobReducer';
import {
    selectJobIDForWorkOrder,
    addWorkOrdersToJob,
    setSelectedJob,
    toggleEditJobModal,
    updateJobFormValue,
    openJob
} from '../../actions/manageJobActions';
import { addCommissioningInstallBases, addCommissioningInstallBasesToJob } from '../../actions/manageInventoryActions';
import { selectIsLoading } from '../../reducers/commonReducers';
import { WithCommonModal } from '../common/WithCommonModal';
import { jobTypesIdEnum, workOrderTypesEnum } from '../../models-enums';
import { RouteComponentProps } from 'react-router-dom';
import { clearTableFilter } from '../../actions/manageInventoryActions';
import { IWorkOrder } from '../../models';
import { jobTypesForSAPWOs, sapBusinessIndicators } from '../../constants/constants';

interface Iprops extends RouteComponentProps<any> {
    colorButton?: string;
    selectedInstallID?: string;
    facilityID: string;
    workOrderMode?: workOrderTypesEnum;
    forSAP: boolean;
    newJobOption?: boolean;
}

export const getSAPWOsFromSelection = (state: IinitialState): IWorkOrder[] => {
    // Normally, state.manageInventory.selection is an array of installBaseIDs, but when it's forSAP, these will actually be workOrderIds
    let selectedWorkOrderIDs: string[] = [];
    state.manageInventory.selection.forEach(item => {
        selectedWorkOrderIDs.push(item.split('select-')[1]);
    });

    const workOrders =
        Object.keys(state.workOrder.unlinkedSapWorkOrdersByID).length > 0
            ? state.workOrder.unlinkedSapWorkOrdersByID
            : state.workOrder.workOrdersByID;

    // Loop through each work order that was selected from SAPWorkOrderContainter or Unassigned
    // These work order IDs should already be filtered to the correct SAP business indicator before this point
    // Let's check if these are meant for a commissioning job or a repair job
    return selectedWorkOrderIDs.map(id => workOrders[id]).filter(wo => wo !== undefined);
};
const mapStateToProps = (state: IinitialState, ownProps: Iprops & WithTranslation & RouteComponentProps) => {
    const selectedJobTypeID = state.manageJob.selectedJob.jobTypeID;
    const selectedJob = state.manageJob.selectedJob;
    const forSAP = ownProps.forSAP;
    const installBaseId: string | undefined = ownProps.selectedInstallID;

    let workOrderMode = workOrderTypesEnum.repair;
    if (ownProps.workOrderMode === workOrderTypesEnum.pmp) {
        workOrderMode = workOrderTypesEnum.pmp;
    }
    let jobFilters: { jobTypeID?: jobTypesIdEnum | jobTypesIdEnum[]; open: boolean; facilityID: string } = {
        open: true,
        facilityID: ownProps.facilityID
    };

    let sapWorkOrder: IWorkOrder[] | undefined;
    if (forSAP && state.manageInventory.selection && state.manageInventory.selection.length > 0) {
        sapWorkOrder = getSAPWOsFromSelection(state);

        if (sapWorkOrder && sapWorkOrder.length > 0) {
            // If suggestedJobType is set, then use it
            let suggestedJobTypeID = sapWorkOrder[0].suggestedJobTypeID
                ? (sapWorkOrder[0].suggestedJobTypeID as jobTypesIdEnum)
                : undefined;
            let suggestedJobTypes: jobTypesIdEnum[] = [];

            // If suggestedJobType is not set, then check the business indicator on the work order to determine which job types can be used
            if (suggestedJobTypeID === undefined) {
                if (sapWorkOrder[0].sapBusinessIndicator === sapBusinessIndicators.fixedPrice) {
                    suggestedJobTypes = [jobTypesIdEnum.repair, jobTypesIdEnum.servicePlan, jobTypesIdEnum.inspection];
                } else if (sapWorkOrder[0].sapBusinessIndicator === sapBusinessIndicators.servicePlan) {
                    suggestedJobTypes = [jobTypesIdEnum.servicePlan, jobTypesIdEnum.inspection];
                }
            }

            jobFilters = {
                ...jobFilters,
                jobTypeID: suggestedJobTypeID ? suggestedJobTypeID : suggestedJobTypes
            };
        } else jobFilters = { ...jobFilters, jobTypeID: jobTypesForSAPWOs };
    } else {
        jobFilters = { ...jobFilters, jobTypeID: jobTypesIdEnum.repair };
        if (workOrderMode === workOrderTypesEnum.pmp) {
            jobFilters = { ...jobFilters, jobTypeID: jobTypesIdEnum.maintenance };
        }

        if (
            !ownProps.selectedInstallID && // If we have selected a device we will create repair or maintenance workOrders
            selectedJobTypeID === jobTypesIdEnum.warrantyBM
        ) {
            jobFilters = {
                ...jobFilters,
                jobTypeID: selectedJobTypeID as jobTypesIdEnum
            };
        }
        if (selectedJob.jobTypeID === jobTypesIdEnum.commissioning) {
            jobFilters = { ...jobFilters, jobTypeID: jobTypesIdEnum.commissioning };
        }
        if (selectedJob.jobTypeID === jobTypesIdEnum.servicePlan) {
            jobFilters = { ...jobFilters, jobTypeID: jobTypesIdEnum.servicePlan };
        }
    }
    const jobOptions = prepJobsForOptions(state, { filters: jobFilters }, ownProps.t);
    const show = state.workOrder.showConfirmSelectJobModal;
    const title = ownProps.t('manageWorkOrder:addWorkOrderToJob');
    return {
        className: 'select-job',
        title,
        loading: selectIsLoading(state),
        jobOptions,
        show,
        selectedJobID: state.manageJob.selectedJob.id,
        selectedJob,
        enableBulkInstallBaseMode: state.manageInventory.enableBulkInstallBaseMode,
        workOrderMode,
        selectedMaintenanceWorkOrders: state.workOrder.selection,
        forSAP,
        selectedInstallID: installBaseId,
        sapWorkOrder,
        jobsByID: state.manageJob.jobsByID,
        jobWorkOrders: state.manageJob.jobWorkOrdersByID
    };
};

export default withTranslation('nsJob')(
    connect(mapStateToProps, {
        toggleModal: toggleConfirmSelectJobModal,
        onHide: toggleConfirmSelectJobModal,
        toggleAddRepairWorkOrderModal,
        selectJobIDForWorkOrder,
        addWorkOrder,
        toggleEditJobModal,
        addWorkOrdersToJob,
        bulkCreateWorkOrders,
        clearTableFilter,
        addCommissioningInstallBases,
        addCommissioningInstallBasesToJob,
        setSelectedJob,
        updateJobFormValue,
        openJob
    })(WithCommonModal(ConfirmSelectJobForm))
);
