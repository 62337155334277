import { ContentState, EditorState, convertToRaw } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import draftToHtml from 'draftjs-to-html';
import * as React from 'react';
import { Editor } from 'react-draft-wysiwyg';

interface Iprops {
    initialContent: any;
    onChange: any;
    readOnly: boolean;
    showNormalToolbar?: boolean;
    clearEditor?: boolean;
    charLimit?: number;
    overrideRTEText?: boolean;
    resetOverrideRTEText?: () => void;
}

const RichTextEditor = (props: Iprops) => {
    const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
    const [characterLimit, setCharacterLimit] = React.useState(1000);

    const customInlineFn = (element: any, { Style }: any) => {
        if (element.tagName === 'SPAN') {
            let styles = '';

            if (element.style.cssText.includes('font-size')) {
                const size = element.style.fontSize.substring(0, element.style.fontSize.length - 2);
                styles += 'fontsize-' + size;
            } else if (element.style.cssText.includes('color')) {
                styles += 'color-rgb(41, 105, 176)'; // if the content comes with color: blue for example, we want to keep that, and right now, we are only using blue
            }

            return Style(styles);
        }

        return null;
    };

    const onChange = (edtState: EditorState) => {
        setEditorState(edtState);
        const contentState = edtState.getCurrentContent();
        const rawContentState = convertToRaw(contentState);
        const plainText = rawContentState.blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');

        if (plainText.length <= characterLimit) {
            const html = draftToHtml(rawContentState);
            props.onChange(html);
        } else {
            const text = edtState
                .getCurrentContent()
                .getPlainText()
                .substring(0, characterLimit);
            const content = ContentState.createFromText(text);
            const newEditorState = EditorState.createWithContent(content);

            setEditorState(EditorState.moveFocusToEnd(newEditorState));
        }
    };

    React.useEffect(() => {
        if (props.overrideRTEText) {
            if (props.initialContent !== '') {
                setContent(props.initialContent, true);
            }

            if (props.resetOverrideRTEText) {
                props.resetOverrideRTEText();
            }
        }
    }, [props.overrideRTEText, props.initialContent]);

    const setContent = (initialContent: any, forceOnChange = false) => {
        const content = stateFromHTML(initialContent, { customInlineFn });
        const newEditState = EditorState.createWithContent(content);

        onChange(newEditState);
    };

    // programmatically clear the editor
    const onClear = () => {
        const content = stateFromHTML('', { customInlineFn });
        const newEditState = EditorState.createWithContent(content);

        setEditorState(newEditState);
    };

    React.useEffect(() => {
        if (props.charLimit) {
            setCharacterLimit(props.charLimit);
        }
    }, [props.charLimit]);

    React.useEffect(() => {
        if (props.clearEditor !== undefined) {
            onClear();
        }
    }, [props.clearEditor]);

    let showNormalToolbar = false;
    // If nothing is passed in, assume normal tool bar, or if something was passed in and it is true, show it, else show slimmed version
    if (props.showNormalToolbar === undefined || props.showNormalToolbar === true) {
        showNormalToolbar = true;
    }

    const toolbarOptions = showNormalToolbar
        ? {
              options: ['link', 'inline', 'list', 'fontSize'],
              inline: { options: ['bold', 'italic', 'underline'] },
              list: { options: ['unordered', 'ordered'] },
              fontSize: {
                  options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96]
              }
          }
        : {
              // Show a slimmed down version of the tool bar, only text formatting (these are the same now, going to leave this ability to have 2 separate options for now incase we need it later)
              options: ['link', 'inline', 'list', 'fontSize'],
              inline: { options: ['bold', 'italic', 'underline'] },
              list: { options: ['unordered', 'ordered'] },
              fontSize: {
                  options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96]
              }
          };

    return (
        <Editor
            editorState={editorState}
            onEditorStateChange={onChange}
            toolbar={toolbarOptions}
            readOnly={props.readOnly}
        />
    );
};

export default RichTextEditor;
