/*
 * Manage Install - Container
 */

import { IinstallBasePopulated } from '../../models';
import { toggleEditInstallModal } from '../../actions/manageInventoryActions';

import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import { IinitialState } from '../../reducers';
import { WithCommonModal } from '../common/WithCommonModal';
import { EditInstallContainer } from './EditInstallContainer';

interface Iprops extends RouteComponentProps<any> {
    colorButton: string;
    selectedItem: IinstallBasePopulated;
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops & WithTranslation) => {
    const className = state.manageInventory.showSearchNewProductsModal ? 'install-edit second-modal' : 'install-edit';
    let title;

    if (ownProps.selectedItem.id) {
        title = ownProps.t('editInstallModalTitle');
    } else if (state.manageInventory.enableBulkInstallBaseMode) {
        title = ownProps.t('bulkEditInstallModalTitle', {
            count: state.manageInventory.selection.length
        });
    } else {
        title = ownProps.t('saveInstallModalTitle');
    }

    return {
        className,
        show: state.manageInventory.showEditInstallModal,
        secondModal: state.manageInventory.showSearchNewProductsModal,
        enableBulkInstallBaseMode: state.manageInventory.enableBulkInstallBaseMode,
        selection: state.manageInventory.selection,
        formValues: state.manageInventory.installFormValues,
        title
    };
};

export default withTranslation('manageInventory')(
    connect(mapStateToProps, {
        toggleEditInstallModal,
        toggleModal: toggleEditInstallModal,
        onHide: toggleEditInstallModal
    })(WithCommonModal(EditInstallContainer))
);
