import * as React from 'react';

/*
 * Manage Job Comment
 */
import { Button, Col, Row } from 'react-bootstrap';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IinstallBasePopulated, Ijob, IjobComment, ItableFiltersReducer, Itile, Iuser } from '../../models';
import { IinitialState } from '../../reducers';

import { Redirect, RouteComponentProps } from 'react-router-dom';
import ReactTable, { RowInfo, SortingRule } from 'react-table';
import {
    setSelectedJobComment,
    setTableFilter,
    submitSignature,
    toggleEditJobCommentModal,
    toggleModalVerificationSignaturePad
} from '../../actions/manageJobCommentsActions';
import { emptyTile, initialJob, initialJobComment } from '../../reducers/initialState';

import moment from 'moment';
import { FieldConfig } from 'react-reactive-form';
import { connect } from 'react-redux';
import { constants } from '../../constants/constants';
import { jobStatusEnum, jobTypesIdEnum } from '../../models-enums';
import { selectIsLoading } from '../../reducers/commonReducers';
import { filterInstallBases } from '../../reducers/manageInventoryReducer';
import { selectVisibleJobComments } from '../../reducers/manageJobCommentsReducer';
import { Banner } from '../common/Banner';
import CommonModal from '../common/CommonModal';
import { FormUtil } from '../common/FormUtil';
import SearchTableForm from '../common/SearchTableForm';
import { TableUtil } from '../common/TableUtil';
import { JobCommentActionButtonContainer } from '../manageInventory/JobCommentActionButtonContainer';
import { EditJobCommentContainer } from './EditJobCommentContainer';
import VerificationSignatureModal from './VerificationSignatureModal';

interface Iprops extends RouteComponentProps<any> {
    // Add your regular properties here
    loading: boolean;
}

interface IdispatchProps {
    // Add your dispatcher properties here
    toggleEditJobCommentModal: typeof toggleEditJobCommentModal;
    toggleModalVerificationSignaturePad: typeof toggleModalVerificationSignaturePad;
    showEditJobCommentModal: boolean;
    showVerificationSignatureModal: boolean;
    filteredInstallBases: IinstallBasePopulated[];
    submitSignature: typeof submitSignature;
    setTableFilter: typeof setTableFilter;
    tableFilters: ItableFiltersReducer;
    tableData: IjobComment[];
    setSelectedJobComment: typeof setSelectedJobComment;
    data: { [key: string]: IjobComment };
    initComplete: boolean;
    fseUsers: Iuser[];
    selectedJobID: string;
    selectedJob: Ijob;
}

interface Istate {
    selectedRow: any;
    currentTile: Itile;
    searchFieldConfig: FieldConfig;
    filterLength: number;
}

class ManageJobComment extends React.Component<Iprops & IdispatchProps & WithTranslation, Istate> {
    public columns: any[];
    public buttonInAction = false;
    // private setTableFilterTimeout: any;
    constructor(props: Iprops & IdispatchProps & WithTranslation) {
        super(props);
        this.state = {
            selectedRow: null,
            currentTile: emptyTile,
            filterLength: 0,
            searchFieldConfig: this.buildSearchFieldConfig()
        };
        this.columns = TableUtil.translateHeaders(
            [
                {
                    id: 'date',
                    Header: 'date',
                    accessor: ({ createDate }: IjobComment) => {
                        return createDate
                            ? moment
                                  .utc(createDate)
                                  .local(true)
                                  .format('DD-MMM-YY')
                            : 'n/a';
                    },
                    width: 100
                },
                {
                    Header: 'engineer',
                    id: 'engineer',
                    accessor: ({ userID }: IjobComment) => {
                        const foundUser = this.props.fseUsers.find(user => user.id === userID);
                        return foundUser ? `${foundUser.first} ${foundUser.last}` : '';
                    }
                },
                {
                    id: 'comment',
                    Header: 'comment',
                    accessor: ({ text }: IjobComment) => {
                        return <div dangerouslySetInnerHTML={{ __html: text }} />;
                    },
                    minWidth: 150
                },
                {
                    Header: 'type',
                    id: 'type',
                    accessor: ({ isDeficiency }: IjobComment) => {
                        return isDeficiency ? this.props.t('deficiency') : this.props.t('info');
                    },
                    width: 120
                }
            ],
            this.props.t
        );
    }
    componentDidMount() {
        this.setState({
            currentTile: constants.getTileByURL(this.props.location.pathname)
        });
    }
    componentDidUpdate(prevProps: Iprops & IdispatchProps) {
        if (
            prevProps.showEditJobCommentModal !== this.props.showEditJobCommentModal &&
            !this.props.showEditJobCommentModal
        ) {
            this.setState({ selectedRow: null });
        }
        // scroll top every time a filter changes
        if (JSON.stringify(prevProps.tableFilters) !== JSON.stringify(this.props.tableFilters)) {
            const tableDiv = document.getElementsByClassName('rt-tbody');
            if (tableDiv && tableDiv.length) {
                tableDiv[0].scrollTop = 0;
            }
        }
    }

    buildSearchFieldConfig = (): FieldConfig => {
        const { userID, createDate, commentType } = this.props.tableFilters;
        const disabled = false;
        const mainSearchControls = {
            createDate: {
                render: FormUtil.Datetime,
                meta: {
                    label: 'manageJobComments:labelCreateDate',
                    colWidth: 6,
                    colWidthLarge: 4,
                    defaultValue: createDate ? createDate : '',
                    showTime: false
                },
                options: {
                    validators: FormUtil.validators.isValidMoment
                }
            },
            userID: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    label: 'manageJobComments:labelUser',
                    options: FormUtil.convertToOptions(this.props.fseUsers),
                    colWidth: 6,
                    colWidthLarge: 4,
                    type: 'select',
                    placeholder: 'placeholderUser',
                    isClearable: true
                },
                formState: {
                    value: userID,
                    disabled
                }
            },
            commentType: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    label: 'manageJobComments:labelType',
                    options: constants.jobCommentTypeOptions,
                    colWidth: 6,
                    colWidthLarge: 4,
                    type: 'select',
                    placeholder: 'placeholderType',
                    isClearable: true
                },
                formState: { value: commentType, disabled }
            }
        };

        this.setState({ filterLength: Object.keys(mainSearchControls).length });

        return { controls: { ...mainSearchControls } };
    };

    /*
     * (reusable)
     * Handle user clicking on a product row
     * set the selected product to state and open the modal
     */
    getTrProps = (finalState: any, rowInfo?: RowInfo, column?: undefined, instance?: any) => {
        if (rowInfo) {
            return {
                onClick: (e: React.MouseEvent<HTMLFormElement>) => {
                    if (!this.buttonInAction) {
                        this.setState({
                            selectedRow: rowInfo.index
                        });
                        this.props.toggleEditJobCommentModal();
                        this.props.setSelectedJobComment(rowInfo.original);
                    }
                },
                style: {
                    background:
                        rowInfo.index === this.state.selectedRow
                            ? constants.colors[`${this.state.currentTile.color}Tr` as keyof typeof constants.colors]
                            : ''
                }
            };
        } else {
            return {};
        }
    };
    onPageChange = (page: number) => {
        this.props.setTableFilter({ page });
    };
    /*
     * (reusable)
     * set the table filters to redux on each value change
     */
    onSearchValueChanges = (value: any, key: string) => {
        switch (key) {
            case 'createDate': {
                const startDateMoment = moment.isMoment(value) ? value : moment(value);
                this.props.setTableFilter({
                    createDate: startDateMoment.toISOString(),
                    page: 0
                });
                break;
            }
            default:
                this.props.setTableFilter({ [key]: value, page: 0 });
                break;
        }
    };
    /*
     * (reusable)
     * set the sorted changes to redux
     */
    onSortedChanged = (newSorted: SortingRule[], column: any, shiftKey: boolean) => {
        this.props.setTableFilter({ sorted: newSorted });
        this.setState({ selectedRow: {} });
    };
    onPageSizeChange = (rows: number) => {
        this.props.setTableFilter({ rows, page: 0 });
    };

    render() {
        if (this.props.initComplete === false) {
            return (
                <Col xs={12}>
                    <h4> loading... </h4>
                </Col>
            );
        }
        const { t } = this.props;

        // return home if their is no job or the job has been completed
        if (!this.props.selectedJobID.length || this.props.selectedJob.status === jobStatusEnum.completed) {
            return (
                <Redirect
                    to={{
                        pathname: '/'
                    }}
                />
            );
        }

        return (
            <div
                className="manage-job-comments"
                style={{
                    ['--filter-size' as string]: this.state.filterLength
                }}
                data-manage="true"
            >
                <Banner
                    title={t('bannerTitle')}
                    img={this.state.currentTile.srcBanner}
                    color={this.state.currentTile.color}
                />
                <Row style={{ margin: '0px' }}>
                    <Col xs={8}>
                        <SearchTableForm
                            fieldConfig={this.state.searchFieldConfig}
                            handleSubmit={() => {
                                return;
                            }}
                            loading={this.props.loading}
                            colorButton={
                                constants.colors[
                                    `${this.state.currentTile.color}Button` as keyof typeof constants.colors
                                ]
                            }
                            t={this.props.t}
                            subscribeValueChanges={true}
                            onValueChanges={this.onSearchValueChanges}
                            showSearchButton={false}
                        />
                    </Col>
                    {this.props.selectedJob.jobTypeID === jobTypesIdEnum.verification && (
                        <Col xs={2} style={{ paddingTop: '25px' }}>
                            <Button
                                className="pull-right"
                                bsStyle="link"
                                onClick={() => {
                                    this.props.toggleModalVerificationSignaturePad();
                                }}
                            >
                                {t('manageJobComments:hospitalRepSignature')}
                            </Button>
                        </Col>
                    )}
                    <Col
                        className="search-actions-container job-comments"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            height: '78px',
                            marginTop: '2px',
                            padding: '1rem',
                            width: 'auto',
                            gap: '8px'
                        }}
                    >
                        <Button
                            style={{ maxWidth: '164px' }}
                            bsStyle="link"
                            onClick={() => {
                                this.props.toggleEditJobCommentModal();
                                this.props.setSelectedJobComment(initialJobComment);
                            }}
                        >
                            {t('manageJobComments:newJobCommentButton')}
                        </Button>
                        <JobCommentActionButtonContainer
                            t={this.props.t}
                            history={this.props.history}
                            colorButton={'primary'}
                            match={this.props.match}
                            location={this.props.location}
                        />
                    </Col>
                </Row>
                <ReactTable
                    data={this.props.tableData}
                    onSortedChange={this.onSortedChanged}
                    columns={this.columns}
                    getTrProps={this.getTrProps}
                    defaultPageSize={this.props.tableFilters.rows || constants.tablePageSizeDefault}
                    onPageSizeChange={this.onPageSizeChange}
                    showPageSizeOptions={true}
                    pageSizeOptions={constants.tablePageSizeOptions}
                    className={`beacon-table -highlight ${this.state.currentTile.color}`}
                    previousText={t('common:previous')}
                    nextText={t('common:next')}
                    onPageChange={this.onPageChange}
                    page={this.props.tableFilters.page}
                    sortable={false}
                    noDataText={t('common:noDataText')}
                    resizable={false}
                />
                <VerificationSignatureModal
                    colorButton={
                        constants.colors[`${this.state.currentTile.color}Button` as keyof typeof constants.colors]
                    }
                    installBasesPopulated={this.props.filteredInstallBases}
                    selectedJob={this.props.selectedJob}
                />
                <CommonModal
                    className="job-edit"
                    show={this.props.showEditJobCommentModal}
                    onHide={this.props.toggleEditJobCommentModal}
                    title={this.props.t('titleJobCommentModal')}
                    container={document.getElementById('two-pane-layout')}
                >
                    <EditJobCommentContainer
                        colorButton={
                            constants.colors[`${this.state.currentTile.color}Button` as keyof typeof constants.colors]
                        }
                        t={this.props.t}
                        location={this.props.location}
                        match={this.props.match}
                        history={this.props.history}
                    />
                </CommonModal>
            </div>
        );
    }
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops) => {
    const selectedJob = state.manageJob.jobsByID[state.manageJob.selectedJob.id] || initialJob;
    const filteredJobComments = selectVisibleJobComments(
        state.manageJobComment.jobCommentsByID,
        state.manageJobComment.tableFilters,
        selectedJob.id
    );
    const filteredInstallBases = filterInstallBases(state);
    return {
        user: state.user,
        loading: selectIsLoading(state),
        showEditJobCommentModal: state.manageJobComment.showEditJobCommentModal,
        showVerificationSignatureModal: state.manageJobComment.showVerificationSignatureModal,
        tableData: filteredJobComments,
        tableFilters: state.manageJobComment.tableFilters,
        filteredInstallBases,
        data: state.manageJobComment.jobCommentsByID,
        initComplete: state.syncStatus.isInitialSyncComplete,
        fseUsers: state.manageJob.fseUsers,
        selectedJobID: state.manageJob.selectedJob.id,
        selectedJob
    };
};
export default withTranslation('manageJobComments')(
    connect(mapStateToProps, {
        toggleEditJobCommentModal,
        setTableFilter,
        setSelectedJobComment,
        submitSignature,
        toggleModalVerificationSignaturePad
    })(ManageJobComment)
);
