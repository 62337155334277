import * as React from 'react';
import { Button, Col } from 'react-bootstrap';
import { FieldConfig, FormArray, FormGenerator, FormGroup, Validators } from 'react-reactive-form';
import { toastr } from 'react-redux-toastr';
import { TFunction } from 'i18next';
import { Iphoto } from '../../models';
import { updatePhoto, deletePhoto } from '../../actions/photosActions';
import { FormUtil } from '../common/FormUtil';
import { constants } from '../../constants/constants';
import { initialPart } from '../../reducers/initialState';

interface Iprops {
    t: TFunction;
    loading: boolean;
    installBaseID: string;
    formValues: Iphoto;
    toggleModal: () => void;
    updatePhoto: typeof updatePhoto;
    deletePhoto: typeof deletePhoto;
    isMobile?: boolean;
}

interface State {
    fieldConfig: FieldConfig;
    formValues: any;
}

class EditPhotoForm extends React.Component<Iprops, State> {
    private formGroup: FormGroup | any;
    private subscription: any;
    static defaultProps = {
        selectedPart: initialPart,
        colorButton: constants.colors[`blueButton`]
    };
    constructor(props: Iprops) {
        super(props);
        this.state = {
            fieldConfig: this.buildFieldConfig(),
            formValues: this.props.formValues
        };
    }

    componentDidMount() {
        this.setState({
            fieldConfig: this.buildFieldConfig()
        });
    }

    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    buildFieldConfig = (defaultValues: Iphoto = this.props.formValues) => {
        const disabled = false;
        const { thumbnailUrl, comments, id, isInReport } = defaultValues;

        let fieldConfigControls = {
            file: {
                render: FormUtil.FileInput,
                meta: {
                    type: 'file',
                    label: 'alertForm.file',
                    colWidth: 12,
                    accept: 'image/png, image/jpeg',
                    name: 'alert-file',
                    required: false,
                    imageUrl: thumbnailUrl,
                    hasPreview: true,
                    style: { display: 'none' }
                }
            },
            comments: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'common:comments',
                    colWidth: 12,
                    componentClass: 'textarea',
                    rows: 5,
                    name: 'comments',
                    initialContent: comments,
                    required: false
                },
                options: {
                    validators: [Validators.maxLength(1000)]
                },
                formState: { value: comments, disabled }
            },
            isInReport: {
                render: FormUtil.Toggle,
                meta: { label: 'SHOW IN REPORT', colWidth: 12 },
                formState: { value: isInReport, disabled }
            },
            btnDeleteImage: {
                render: FormUtil.Button,
                meta: {
                    buttonName: 'Delete Image',
                    style: 'default',
                    colWidth: 12,
                    buttonAction: () => this.handleDelete(id)
                }
            }
        };

        return FormUtil.translateForm(
            {
                controls: { ...fieldConfigControls }
            },
            this.props.t
        );
    };

    subscribeToChanges = () => {
        for (const key in this.formGroup.controls) {
            if (this.formGroup.controls.hasOwnProperty(key)) {
                this.subscription = this.formGroup.get(key).valueChanges.subscribe((value: any) => {
                    this.setState(
                        {
                            formValues: {
                                ...this.state.formValues,
                                [key]: value
                            }
                        },
                        () => {
                            this.buildFieldConfig(this.state.formValues);
                        }
                    );
                });
            }
        }
    };

    handleDelete = (id: string) => {
        const toastrConfirmOptions = {
            onOk: () => {
                this.props.deletePhoto(id, this.props.installBaseID);
                this.props.toggleModal();
            },
            onCancel: () => null,
            okText: 'OK',
            cancelText: 'Cancel'
        };
        toastr.confirm('Are you sure?', toastrConfirmOptions);
    };

    handleSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();

        const { comments, isInReport } = this.formGroup.value;
        const payload = {
            ...this.props.formValues,
            comments,
            isInReport
        };

        this.props.updatePhoto(payload);
        this.props.toggleModal();
    };

    setForm = (form: FormGroup | FormArray) => {
        this.formGroup = form;
        this.formGroup.meta = {
            loading: this.props.loading
        };
        if (!this.subscription) {
            setTimeout(() => {
                this.subscribeToChanges();
            }, 300);
        }
    };

    cleanForm = () => {
        this.formGroup.reset();
        this.setState({ fieldConfig: this.buildFieldConfig() });
    };

    render() {
        const { t, toggleModal, isMobile } = this.props;
        return (
            <form className="beacon-form" onSubmit={this.handleSubmit}>
                <div className={isMobile ? undefined : 'measurement-point-list-form'}>
                    <FormGenerator onMount={this.setForm} fieldConfig={this.state.fieldConfig} />
                </div>
                <Col xs={12} className="form-buttons text-right">
                    <Button bsStyle="default" type="button" className="pull-left" onClick={toggleModal}>
                        {t('cancel')}
                    </Button>
                    <Button bsStyle="primary" type="submit" disabled={this.props.loading}>
                        {t('save')}
                    </Button>
                </Col>
            </form>
        );
    }
}

export default EditPhotoForm;
