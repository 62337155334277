import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { FormGroup, Col, ControlLabel, Button, FormControl } from 'react-bootstrap';
import { FieldControl, Validators, FormControl as ReactiveFormControl, FieldGroup } from 'react-reactive-form';
import Select, { components } from 'react-select';
import { Ioption, IshoppingCartItem } from '../../../models';

const QuantityInput = ({ handler, touched, hasError, meta, pristine, errors, submitted }: ReactiveFormControl) => {
    return (
        <FormControl
            style={{ width: '50%', display: 'inline' }}
            placeholder={meta.placeholder}
            type="number"
            name="quantity"
            {...handler()}
        />
    );
};

// add the bootstrap form-control class to the react-select select component
const ControlComponent = (props: any) => (
    <div>
        <components.Control {...props} className="form-control" />
    </div>
);

export const getBundleOptions = () => {
    let bundleOptions: Ioption[] = [];
    for (let index = 0; index < 6; index++) {
        bundleOptions = [...bundleOptions, { label: `${index + 1}`, value: index + 1 }];
    }
    return bundleOptions;
};

const BundleSelect = ({ handler, touched, hasError, meta, pristine, errors, submitted }: ReactiveFormControl) => {
    return (
        <Select
            options={getBundleOptions()}
            components={{ Control: ControlComponent }}
            classNamePrefix="react-select"
            name={meta.name || ''}
            isClearable={false}
            {...handler()}
        />
    );
};

export const ShoppingCartSubSubItem = ({
    subItemName,
    subSubItem,
    deleteAction,
    cartName,
    main = false
}: {
    subItemName?: string;
    subSubItem: IshoppingCartItem;
    deleteAction: (id: string, cartName: string) => void;
    cartName: string;
    main?: boolean;
}) => (
    <FormGroup bsSize="sm" style={{ margin: 0 }}>
        {/* offset padding/margin inconsistencies */}
        <Col xs={6} style={{ paddingLeft: main ? 0 : '15px' }}>
            <ControlLabel>
                {subItemName && (
                    <>
                        <span style={{ textTransform: 'capitalize' }}>{subItemName}</span>
                        <br />
                    </>
                )}
                <span>
                    <b>Part:</b> {subSubItem.name}
                </span>
            </ControlLabel>
        </Col>
        <FieldGroup
            name={subSubItem.id}
            render={() => {
                return (
                    <div>
                        <Col xs={3} style={{ margin: '3px 0' }}>
                            <FieldControl
                                name="bundle"
                                render={BundleSelect as any}
                                formState={{
                                    label: `${subSubItem.bundle}`,
                                    value: subSubItem.bundle
                                }}
                            />
                        </Col>
                        <Col
                            xs={3}
                            style={{
                                textAlign: 'center',
                                paddingRight: '0',
                                paddingLeft: '0'
                            }}
                        >
                            <FieldControl
                                name="quantity"
                                options={{
                                    validators: Validators.required
                                }}
                                render={QuantityInput as any}
                                formState={subSubItem.quantity}
                            />
                            <Button
                                bsStyle="link"
                                style={{
                                    fontSize: '1.6em'
                                }}
                                onClick={() => deleteAction(subSubItem.id, cartName)}
                            >
                                <FontAwesomeIcon icon={['far', 'times']} />
                            </Button>
                        </Col>
                    </div>
                );
            }}
        />
    </FormGroup>
);
