import { filter, keyBy, map, pickBy } from 'lodash';
import * as types from '../actions/actionTypes';
import { ICode, ImanageCodeReducer, ItableFiltersParams } from '../models';
import initialState, { initialCode } from './initialState';
import { combineReducers } from 'redux';
import { IinitialState } from '.';
import { createSelector } from 'reselect';

const getCodes = (state: IinitialState) => state.manageCode.codesByID;
export const selectCodes = createSelector([getCodes], codesByID => codesByID);

const cleanCodeObject = (code: any) => {
    return {
        ...initialCode,
        ...pickBy(code, property => property !== null)
    } as ICode;
};

export function codesByID(state: { [key: string]: ICode } = initialState.manageCode.codesByID, action: any) {
    switch (action.type) {
        case types.LOAD_CODES_SUCCESS: {
            const newCodes = map(action.payload.data.result, (code: ICode) => {
                return cleanCodeObject(code);
            });
            return keyBy(newCodes, 'id');
        }
        case types.ADD_CODE_SUCCESS: {
            return { ...state, [action.payload.id]: action.payload };
        }
        case types.EDIT_CODE_SUCCESS:
            return { ...state, [action.payload.id]: action.payload };
        case types.REMOVE_CODE_SUCCESS: {
            const data = { ...state };
            delete data[action.payload.id];
            return data;
        }
        case types.USER_LOGOUT_SUCCESS:
            return initialState.manageCode.codesByID;
        default:
            return state;
    }
}

// function totalPages(state = 1, action: any): number {
//     switch (action.type) {
//         case types.CODE_MANAGE_TOTAL_PAGES:
//             if (action.pages && action.pages > 0) {
//                 return action.pages;
//             }
//             return state;
//         case types.USER_LOGOUT_SUCCESS:
//             return 1;
//         default:
//             return state;
//     }
// }

const manageCode = combineReducers<ImanageCodeReducer>({
    codesByID
    //totalPages,
    // selectedCodeID: (state, action) =>
    //     createSelectedIDWithName(state, action, 'CODE_ID'),
    // showEditCodeModal: (state, action) =>
    //     modalToggleWithName(state, action, 'EDIT_CODE'),
    // tableFilters: (state, action) =>
    //     createTableFiltersWithName(state, action, 'MANAGE_CODE'),
    // codeFormValues: (state, action) =>
    //     createFormValuesWithName(state, action, 'MANAGE_CODE')
});

export default manageCode;

/*
 * SELECTORS
 */

// export const selectVisibleDeficiencyCodes = (
//     state: { [key: string]: IdeficiencyCode },
//     tableFilters: ItableFiltersParams
// ) => {
//     const filteredCodes = filter(state, code => {
//         let shouldInclude = true;
//         if (tableFilters.search) {
//             const searchString = tableFilters.search.trim().toLowerCase();
//             if (code.name.toLowerCase().indexOf(searchString) === -1) {
//                 shouldInclude = false;
//             }
//         }
//         if (tableFilters.standard && tableFilters.standard.value) {
//             shouldInclude = false;
//         }
//         return shouldInclude;
//     });
//     return orderBy(filteredCodes, res => res.name, 'asc');
// };
