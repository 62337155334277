import React from 'react';
import CommonModal from '../common/CommonModal';
import HistoricalImages from './HistoricalImages';
import { Button, Col } from 'react-bootstrap';
import { TFunction } from 'i18next';

interface Iprops {
    show: boolean;
    hide: () => void;
    t: TFunction;
}
export default function HistoricalImagesModal(props: Iprops) {
    return (
        <CommonModal
            className="job-edit"
            show={props.show}
            onHide={props.hide}
            title={props.t('historicalImagesTitle')}
            container={document.getElementById('two-pane-layout')}
        >
            <HistoricalImages />
            <Col xs={12} className="form-buttons text-right">
                <Button bsStyle="default" type="button" className="pull-left" onClick={props.hide}>
                    {props.t('cancel')}
                </Button>
            </Col>
        </CommonModal>
    );
}
