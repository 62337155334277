import * as React from 'react';
import { TakePhotoContainer } from './TakePhotoContainer';
import { PhotoTableContainer } from './PhotoTableContainer';

interface Iprops {
    installID: string;
}

export const PhotosControl = (props: Iprops) => {
    return (
        <>
            <TakePhotoContainer installID={props.installID} />
            <PhotoTableContainer installID={props.installID} />
        </>
    );
};
