/*
 * Edit alert Form
 * Add and Edit alerts
 *
 */

import * as React from 'react';

import { Button, Col } from 'react-bootstrap';
import { FieldConfig, FormArray, FormGenerator, FormGroup, Validators } from 'react-reactive-form';
import {
    Ihistory,
    IinstallBasePart,
    IinstallBasePartPopulated,
    IjobPart,
    Ioption,
    IpartStock,
    IworkOrderPart
} from '../../models';

import { includes, omit } from 'lodash';

import { FormUtil } from '../common/FormUtil';
import { IpartFormValues } from '../../modelsForms';
import { TFunction } from 'i18next';
import { constants } from '../../constants/constants';
import { initialInstallBasePartPopulated } from '../../reducers/initialState';
import { toastr } from 'react-redux-toastr';
import {
    clearSelectedInstallBasePartID,
    saveInstallBasePart,
    setSelectedInstallBasePartID
} from '../../actions/partsActions';
import { addQuery } from '../common/OtherUtils';
import { suggestedPartsQueryParamsEnum } from './SuggestedParts';

interface Iprops {
    partOptions: Ioption[];
    clearSelectedInstallBasePartID: typeof clearSelectedInstallBasePartID;
    colorButton?: string;
    disabled?: boolean;
    formValues: IpartFormValues;
    installBaseID: string;
    loading: boolean;
    selectedJobID: string;
    selectedInstallBasePartPopulated: IinstallBasePartPopulated;
    setSelectedInstallBasePartID: typeof setSelectedInstallBasePartID;
    standardOptions: Ioption[];
    t: TFunction;
    tableParts: IinstallBasePartPopulated[];
    updateFormValue: (formValue: { [key: string]: any }) => any;
    workOrderID?: string;
    jobParts: { [key: string]: IjobPart };
    partStocks: { [key: string]: IpartStock };
    workOrderParts: { [key: string]: IworkOrderPart };
    saveInstallBasePart: typeof saveInstallBasePart;
    history: Ihistory;
    isUserUSA: boolean;
    installBaseParts: IinstallBasePart[];
    isMobile: boolean;
    selectedParts: IinstallBasePartPopulated[];
}

const PartForm: React.FC<Iprops> = (props: Iprops) => {
    const [formGroup, setFormGroup] = React.useState<FormGroup | any>();

    /*
     * selectedItemToFormValues - take the selectedItem and convert it to formValues
     */
    const selectedItemToFormValues = (installBasePartPopulated: IinstallBasePartPopulated): IpartFormValues => {
        const {
            part,
            jobPartQuantity,
            partStockQuantity,
            directPartQuantity,
            workOrderPartQuantity
        } = installBasePartPopulated;
        return {
            id: part.id,
            description: part.number ? `${part.number} - ${part.description}` : undefined,
            quantity: jobPartQuantity || partStockQuantity || workOrderPartQuantity || directPartQuantity || 1
        };
    };

    const initValue = () => {
        const { selectedInstallBasePartPopulated } = props;

        const {
            part: { number, description, id },
            directPartQuantity,
            workOrderPartQuantity
        } = selectedInstallBasePartPopulated;

        const defaultPart = { disabled: false, label: '', value: '' };
        const defaultQuantity = 1;

        return {
            ...formGroup,
            value: {
                parts: id ? { value: id, label: `${number} - ${description}` } : defaultPart,
                quantity: directPartQuantity || workOrderPartQuantity || defaultQuantity
            }
        };
    };

    React.useEffect(() => {
        // When the Device changes, reset the part form
        setFormGroup(initValue());
    }, [props.installBaseID]);

    /*
     * formValuesToSelectedItem - convert the formValues to the shape of the selectedItem
     * when we are creating new, the formValues.parts = a jobPartID or a stockPartID
     * when we are editing, the formValues.parts = the partID
     */
    const formValuesToSelectedItem = (): IinstallBasePart => {
        const formValues = FormUtil.getValues(formGroup.value);
        let quantity = parseFloat(`${formValues.quantity}`);

        // This should really only apply to work order parts
        // When WO Part is selected, and we update the quantity to the WO part's estimated amount, it's not updating in the formValues
        const formQuantity = formGroup.get('quantity');
        if (formQuantity && formQuantity.value) {
            // if formGroup has a higher quantity, use that one
            const quantityValue = parseFloat(`${formQuantity.value}`);

            if (quantityValue && quantityValue > quantity) {
                quantity = quantityValue;
            }
        }

        const { part, ...installBasePart } = props.selectedInstallBasePartPopulated;

        if (installBasePart.id) {
            const isPartStock = installBasePart.partStockID?.length;
            const isJobPart = installBasePart.jobPartID?.length;
            const isDirectPart = installBasePart.directPartID?.length;
            const isWorkOrderPart = installBasePart.workOrderPartID?.length;
            return {
                ...installBasePart,
                jobPartQuantity: isJobPart ? quantity : 0,
                partStockQuantity: isPartStock ? quantity : 0,
                directPartQuantity: isDirectPart ? quantity : 0,
                workOrderPartQuantity: isWorkOrderPart ? quantity : 0
            };
        } else {
            const partStock = props.partStocks[formValues.parts as string];
            const jobPart = props.jobParts[formValues.parts as string];
            const workOrderPart = Object.values(props.workOrderParts).filter(x => x.partID === formValues.parts);
            const isDirectPart = !partStock && !jobPart && !workOrderPart;

            return {
                ...installBasePart,
                jobID: installBasePart.jobID || props.selectedJobID,
                installBaseID: installBasePart.installBaseID || props.installBaseID,
                jobPartID: jobPart ? jobPart.id : null,
                jobPartQuantity: jobPart ? quantity : 0,
                partStockID: partStock ? partStock.id : null,
                partStockQuantity: partStock ? quantity : 0,
                directPartID: isDirectPart ? (formValues.parts as string) : null,
                directPartQuantity: isDirectPart ? quantity : 0,
                workOrderPartID: workOrderPart && workOrderPart.length > 0 ? workOrderPart[0].id : null,
                workOrderPartQuantity: workOrderPart && workOrderPart.length > 0 ? quantity : 0
            };
        }
    };

    const handleAddAllParts = () => {
        // Convert workOrderParts hashmap to an array
        const installbaseParts = props.installBaseParts;

        let validationError = false;

        installbaseParts.forEach(tempIB => {
            const selectedInstallBasePartTypeID = getPartTypeID(tempIB);
            const anyPartTypeID = props.tableParts.map(iBP => getPartTypeID(iBP));

            if (includes(anyPartTypeID, selectedInstallBasePartTypeID)) {
                validationError = true;
                toastr.error(
                    props.t('common:warning'),
                    props.t('manageWorkOrder:identicalPartTypeID'),
                    constants.toastrError
                );
                return;
            }
        });

        if (validationError) {
            return;
        }

        Object.values(installbaseParts).forEach(async installBasePart => {
            // Save each installBasePart
            let installBaseID: string | undefined = undefined;

            // If the part is from a virtual IB, then use the installBaseID we are currently working on, not the virtual IB
            if (installBasePart.fromVirtualInstallBase) {
                installBaseID = props.installBaseID;
            }

            await props.saveInstallBasePart(installBasePart, props.selectedInstallBasePartPopulated, installBaseID);
        });
    };

    const formControls: FieldConfig = React.useMemo(() => {
        const { partOptions, selectedParts } = props;

        const filteredPartOptions = partOptions.filter(
            option => !selectedParts.find(part => part.id === option.value || part.partStockID === option.value)
        );

        const defaultValues: IpartFormValues = selectedItemToFormValues(initialInstallBasePartPopulated);
        const { isMobile } = props;
        const disabled = props.disabled === true;
        let saveButtonText = props.t('add');
        const { directPartQuantity, workOrderPartQuantity } = props.selectedInstallBasePartPopulated;

        if (props.selectedInstallBasePartPopulated.id.length) {
            saveButtonText = props.t('update');
        }

        let label = defaultValues.description;
        if (formGroup?.value?.parts?.label) {
            label = formGroup.value.parts.label;
        }

        let quantity = defaultValues.quantity ? defaultValues.quantity : 1;

        if (directPartQuantity || workOrderPartQuantity) {
            quantity = workOrderPartQuantity || directPartQuantity || 1;
        }

        //MMG-1794 - For WorkOrder Parts, default the quantity to the estimated quantity
        if (formGroup?.value.parts?.value) {
            const part = Object.values(props.workOrderParts).filter(x => x.partID === formGroup.value.parts.value);

            if (part && part.length > 0) {
                // There should only be 1 part here
                quantity = part[0].estimated;
            }
        }

        const buttonStyle = { marginTop: '24px', width: isMobile ? '100%' : '' };
        let fieldConfigControls = {
            parts: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    label: 'parts',
                    options: filteredPartOptions,
                    disableSort: true, // This lets us sort in the selector instead of complicating the sort logic
                    colWidthLarge: isMobile ? 12 : 6,
                    type: 'text',
                    placeholder: 'searchPlaceholder',
                    disabled: props.selectedInstallBasePartPopulated.id.length > 0 || props.disabled
                },
                options: {
                    validators: [FormUtil.validators.requiredWithTrim, Validators.min(1)]
                },
                formState: {
                    value: formGroup?.value?.parts?.value ? formGroup.value.parts.value : defaultValues.id,
                    label: label,
                    disabled: props.selectedInstallBasePartPopulated.id.length > 0
                }
            },
            quantity: {
                render: FormUtil.NumericInput,
                meta: {
                    label: 'quantity',
                    colWidth: isMobile ? 8 : 2,
                    name: 'quantity'
                },
                options: {
                    validators: [FormUtil.validators.requiredWithTrim, Validators.min(0)]
                },
                formState: { value: quantity.toString(), disabled }
            },
            $field_cancel: {
                isStatic: false,
                render: () => (
                    <Col xs={isMobile ? 4 : 2} className="parts-btn">
                        <Button
                            bsStyle="default"
                            type="button"
                            className={isMobile ? 'pull-right' : 'pull-left'}
                            style={buttonStyle}
                            onClick={() => props.clearSelectedInstallBasePartID()}
                        >
                            {props.t('common:cancel')}
                        </Button>
                    </Col>
                )
            },
            addAll: {
                render: () => (
                    <Col xs={isMobile ? 4 : 2} className="parts-btn_sm">
                        <Button
                            bsStyle={props.colorButton}
                            className="pull-right"
                            style={buttonStyle}
                            disabled={props.disabled}
                            onClick={handleAddAllParts}
                        >
                            Add all
                        </Button>
                    </Col>
                )
            },
            $field_submit: {
                isStatic: false,
                render: () => (
                    <Col xs={isMobile ? 4 : 2} className="parts-btn_sm">
                        <Button
                            bsStyle={props.colorButton}
                            type="submit"
                            className="pull-right"
                            style={buttonStyle}
                            disabled={props.disabled}
                        >
                            {saveButtonText}
                        </Button>
                    </Col>
                )
            }
        };

        // remove cancel button when adding new
        if (props.selectedInstallBasePartPopulated.id.length === 0) {
            const widerParts = {
                ...fieldConfigControls.parts,
                meta: { ...fieldConfigControls.parts.meta, colWidth: isMobile ? 12 : 6 }
            };

            return FormUtil.translateForm(
                {
                    controls: {
                        ...omit(fieldConfigControls, '$field_cancel'),
                        parts: widerParts
                    }
                },
                props.t
            );
        }
        return FormUtil.translateForm(
            {
                controls: { ...fieldConfigControls }
            },
            props.t
        );
    }, [
        formGroup?.value?.parts?.value,
        props.workOrderParts,
        props.selectedInstallBasePartPopulated.id.length,
        props.partOptions,
        props.selectedParts
    ]);

    React.useEffect(() => {
        if (!formGroup) {
            return;
        }

        for (const key in formGroup.controls) {
            formGroup.get(key).valueChanges.subscribe((value: any) => {
                onValueChanges(value, key);
            });
        }
    }, [formGroup]);

    /*
     * (reusable)
     * set the table filters to redux on each value change
     */
    const onValueChanges = (value: any, key: string) => {
        if (key === 'parts') {
            const foundWOPart = props.workOrderParts?.[value.value];

            setFormGroup({
                ...formGroup,
                value: {
                    parts: value,
                    quantity: foundWOPart ? foundWOPart.estimated : formGroup.value?.quantity
                }
            });
        } else if (key === 'quantity') {
            setFormGroup({
                ...formGroup,
                value: {
                    ...formGroup.value,
                    quantity: value
                }
            });
        } else {
            console.error('onValueChanges: key not found', key);
        }
    };

    /**
     * if this is a new part prevent them from adding another installBasePart for the same partStock or jobPart
     * REFACTOR we could move this to inside the selectPartOptions() so that the option is not even available for the user to select
     * @param installBasePart
     * @returns boolean
     */
    const getIsDuplicatePart = (installBasePart: IinstallBasePart): boolean => {
        const selectedInstallBasePartTypeID = getPartTypeID(installBasePart);
        const anyPartTypeID = props.tableParts.map(iBP => getPartTypeID(iBP));

        if (installBasePart.id.length === 0 && includes(anyPartTypeID, selectedInstallBasePartTypeID)) {
            toastr.error(
                props.t('common:warning'),
                props.t('manageWorkOrder:identicalPartTypeID'),
                constants.toastrError
            );
            return true;
        }
        return false;
    };

    const handleSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if (formGroup.status === 'INVALID') {
            setFormGroup((prev: FormGroup) => ({ ...prev, submitted: true }));
            toastr.error('Please check invalid inputs', '', constants.toastrError);
            return;
        }
        const installBasePart = formValuesToSelectedItem();

        // For work order parts, you cannot use more than the estimated quantity
        if (installBasePart.workOrderPartID && installBasePart.workOrderPartQuantity) {
            const woPart = props.workOrderParts[installBasePart.workOrderPartID];
            if (woPart) {
                if (installBasePart.workOrderPartQuantity > woPart.estimated) {
                    toastr.error('Please enter a value less than the estimated quantity', '', constants.toastrError);
                    return;
                }
            }
        }

        if (getIsDuplicatePart(installBasePart)) {
            return;
        }

        props.saveInstallBasePart(installBasePart, props.selectedInstallBasePartPopulated);

        // If it's an update, clear after the save
        if (props.selectedInstallBasePartPopulated.id.length > 0) {
            props.clearSelectedInstallBasePartID();
        }

        setFormGroup((prev: FormGroup) => ({ ...prev, value: { ...prev.value, parts: undefined } }));
    };

    const getPartTypeID = (installBasePart: IinstallBasePart) => {
        if (installBasePart.jobPartID) {
            return installBasePart.jobPartID;
        } else if (installBasePart.partStockID) {
            return installBasePart.partStockID;
        } else if (installBasePart.workOrderPartID) {
            return installBasePart.workOrderPartID;
        } else if (installBasePart.directPartID) {
            return installBasePart.directPartID;
        } else {
            console.error('[getPartTypeID]: no part type ids');
        }
        return '';
    };

    const setForm = (form: FormGroup | FormArray) => {
        const newForm = form;
        newForm.meta = {
            loading: props.loading
        };
        newForm.value = formGroup?.value || initValue();

        setFormGroup({ ...newForm });
    };

    React.useEffect(() => {
        const { selectedInstallBasePartPopulated, partOptions } = props;
        const {
            part: { number, description, id },
            directPartQuantity,
            workOrderPartQuantity
        } = selectedInstallBasePartPopulated;

        if (formGroup && formGroup.setValue && selectedInstallBasePartPopulated?.part?.id) {
            const foundPart = partOptions.find(part => part.value === selectedInstallBasePartPopulated.part.id);
            setFormGroup((prev: FormGroup) => ({
                ...prev,
                value: {
                    parts: foundPart || { value: id, label: `${number} - ${description}` },
                    quantity: directPartQuantity || workOrderPartQuantity || 1
                }
            }));
        } else {
            setFormGroup((prev: FormGroup) => ({
                ...prev,
                value: {
                    parts: undefined,
                    quantity: 1
                }
            }));
        }
    }, [props.selectedInstallBasePartPopulated, props.partOptions]);

    const { isMobile } = props;
    return (
        <form onSubmit={handleSubmit} className="clearfix beacon-form">
            <FormGenerator onMount={setForm} fieldConfig={formControls} />
            {props.isUserUSA && !props.disabled && (
                <Col xs={12} className="form-buttons">
                    <Button
                        style={{
                            width: isMobile ? '100%' : ''
                        }}
                        onClick={() => {
                            addQuery(suggestedPartsQueryParamsEnum.showSearchPartsModal, 'true', props.history);
                        }}
                    >
                        Search All Parts
                    </Button>
                </Col>
            )}
        </form>
    );
};

export default PartForm;
