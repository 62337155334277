export enum jobTypesIdEnum {
    audit = '80eedbac-ec22-45ef-9ac7-f2eb4be2db4c',
    commissioning = '1ff033de-c0b6-4f4d-bdb7-7893053092d8',
    inspection = '524235fd-4633-4b7a-9c13-c37fc39efe69',
    repair = 'ae78eaa3-89c2-490a-90c6-44e5cfa10b01', // work order based
    verification = '9c7fde18-0b94-4af8-b4aa-913c40e5aed0',
    maintenance = '86198f73-1a58-4989-a205-13a42a157989', // work order based
    agsRebalancing = 'bbfd50fe-a790-4384-8a29-00995e5d7158',
    warrantyBM = '6a70ced7-37ce-48c1-92ae-7db47981f896',
    servicePlan = '909770e7-54f9-4d7e-8854-0cb5bdc800ad'
}

export enum productLinkTypeEnum {
    specificationSheet = 1,
    salesBrochure,
    operationManual,
    sparePartsBook,
    certificate,
    other
}
export enum jobDefaultTypeEnum {
    staticPressureValue,
    dynamicFlowRate
}
export enum jobTypesIdEnumInverse {
    '80eedbac-ec22-45ef-9ac7-f2eb4be2db4c' = 'audit',
    '1ff033de-c0b6-4f4d-bdb7-7893053092d8' = 'commissioning',
    '524235fd-4633-4b7a-9c13-c37fc39efe69' = 'inspection',
    'ae78eaa3-89c2-490a-90c6-44e5cfa10b01' = 'repair', // work order based
    '9c7fde18-0b94-4af8-b4aa-913c40e5aed0' = 'verification',
    '86198f73-1a58-4989-a205-13a42a157989' = 'maintenance', // work order based
    'bbfd50fe-a790-4384-8a29-00995e5d7158' = 'agsRebalancing',
    '6a70ced7-37ce-48c1-92ae-7db47981f896' = 'warrantyBM',
    '2ac3bfc5-18fe-4ef1-bfca-adf46125ab10' = 'nonProductive',
    '909770e7-54f9-4d7e-8854-0cb5bdc800ad' = 'servicePlan'
}

export enum jobTypesIdEnumForSelect {
    Audit = '80eedbac-ec22-45ef-9ac7-f2eb4be2db4c', //
    Commissioning = '1ff033de-c0b6-4f4d-bdb7-7893053092d8',
    Inspection = '524235fd-4633-4b7a-9c13-c37fc39efe69',
    Repair = 'ae78eaa3-89c2-490a-90c6-44e5cfa10b01', // work order based
    Verification = '9c7fde18-0b94-4af8-b4aa-913c40e5aed0',
    Maintenance = '86198f73-1a58-4989-a205-13a42a157989', // work order based
    agsRebalancing = 'bbfd50fe-a790-4384-8a29-00995e5d7158',
    warrantyBM = '6a70ced7-37ce-48c1-92ae-7db47981f896',
    nonProductive = '2ac3bfc5-18fe-4ef1-bfca-adf46125ab10',
    servicePlan = '909770e7-54f9-4d7e-8854-0cb5bdc800ad'
}

export enum jobSourceEnum {
    MMG,
    BPCS
}

export enum locationObjectTypeEnum {
    building = 'buildingID',
    floor = 'floorID',
    location = 'locationID',
    room = 'roomID'
}

export enum measurementPointListTypeEnum {
    annual = 1,
    verification,
    touchpoint, // maintenance and repair
    agsRebalancing,
    purity,
    verificationChecklist,
    audit,
    commissioning = 8,
    servicePlan = 10,
    none = 100
}

export enum workOrderTabEnum {
    beacon = 'beacon',
    repair = 'repair',
    preventative = 'preventative'
}

export enum MPLTypebyJobTypesID {
    '80eedbac-ec22-45ef-9ac7-f2eb4be2db4c' = measurementPointListTypeEnum.audit,
    '1ff033de-c0b6-4f4d-bdb7-7893053092d8' = measurementPointListTypeEnum.commissioning,
    '524235fd-4633-4b7a-9c13-c37fc39efe69' = measurementPointListTypeEnum.annual,
    '9c7fde18-0b94-4af8-b4aa-913c40e5aed0' = measurementPointListTypeEnum.verification,
    'ae78eaa3-89c2-490a-90c6-44e5cfa10b01' = measurementPointListTypeEnum.touchpoint,
    '86198f73-1a58-4989-a205-13a42a157989' = measurementPointListTypeEnum.touchpoint,
    'bbfd50fe-a790-4384-8a29-00995e5d7158' = measurementPointListTypeEnum.agsRebalancing,
    '6a70ced7-37ce-48c1-92ae-7db47981f896' = measurementPointListTypeEnum.touchpoint,
    '8318428f-42c9-4675-97a0-be96f26af71c' = measurementPointListTypeEnum.touchpoint,
    '909770e7-54f9-4d7e-8854-0cb5bdc800ad' = measurementPointListTypeEnum.servicePlan,
    '2ac3bfc5-18fe-4ef1-bfca-adf46125ab10' = measurementPointListTypeEnum.none
}

export enum measurementPointTypesEnum {
    passFail = 1,
    text,
    numeric,
    select,
    group,
    date,
    longText,
    summaryPage
}

export enum measurementPointAnswerTypes {
    'pass' = 1,
    'textValue',
    'numericValue',
    'measurementPointSelectOptionID',
    'dateValue' = 6,
    'longText',
    'summaryPage'
}

export enum measurementPointPassFailTypes {
    pass = 1,
    fail,
    notApplicable
}

export enum measurementPointResultStatusTypesEnum {
    resultStatusNotTested = 0,
    // resultStatusIncomplete = 1, // We removed this 11/2019
    resultStatusFail = 2,
    resultStatusPass = 3,
    resultStatusCannotComplete = 4,
    resultStatusRepaired = 5,
    resultStatusMaintain = 6,
    resultStatusCommissioned = 7
}

export enum commissioningStatusTypesEnum {
    resultStatusNotTested = 1,
    resultStatusFail = 2,
    resultStatusPass = 3
}

export enum inventoryStatusFilterEnum {
    resultStatusNotTested = 0,
    resultStatusFail = 2,
    resultStatusPass = 3,
    resultStatusCannotComplete = 4,
    resultStatusNotTestedLast12M = 12
}

/*
  New - No results have been saved for the job
  Started - Results are in the job
  Completed - Leader has clicked complete button
  Reopened - Leader reopened the job after closing
  */
export enum jobStatusEnum {
    new = 'New',
    started = 'Started',
    completed = 'Completed',
    reopened = 'Reopened'
}

export enum workOrderTypesEnum {
    none, // front end only
    repair,
    pmp,
    warranty,
    servicePlan,
    commissioning
}

export enum workOrderStatusEnum {
    new = 1,
    complete,
    reopened,
    open,
    notApplicable
}

export enum workOrderStatusEnumForFilter {
    complete = 2,
    open = 4
}

export enum workOrderPrioritiesEnum {
    highRisk = 1,
    lowRisk,
    infectionControl
}

export enum workOrderVendorsEnum {
    internal = 1,
    other,
    beacon
}

export enum partTypeEnum {
    default,
    labor
}

export enum productCommunicationMethodEnum {
    none,
    TAE
}

export enum beaconContactTypeEnum {
    customer,
    fse
}

export enum SignatureTypeEnum {
    ContractorRepresentative = 0,
    SupervisingOfficer = 1,
    Witnessed = 2,
    Customer = 3,
    FSE = 4,
    Hospital = 5,
    QualityController = 6
}

export enum userLanguageEnum {
    English,
    French,
    German,
    Spanish,
    Thai,
    Chinese
}

export enum reportTypeEnum {
    annualInspection = 1,
    jobNotes,
    HTM,
    visit,
    agsRebalancing,
    verification,
    audit,
    commissioning,
    dailyRounds
}

export enum AppSyncItemStatus {
    inQueue = 0,
    inProgress = 1,
    success = 2,
    failed = 3
}
