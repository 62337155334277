/*
 * All parts form
 */

import { Col, Button } from 'react-bootstrap';
import { FormGroup, FormGenerator, FieldConfig, Validators, GroupProps, FormArray } from 'react-reactive-form';
import { find, orderBy } from 'lodash';

import * as React from 'react';
import { TFunction } from 'i18next';
import { FormUtil } from '../../common/FormUtil';
import { Ioption, Ipart, IsuggestedPartPopulated } from '../../../models';
import { initialPart } from '../../../reducers/initialState';
import { partTypeEnum } from '../../../models-enums';
import { IgenericFormValues } from '../../../modelsForms';
import { toastr } from 'react-redux-toastr';
import { constants } from '../../../constants/constants';

export interface ImetaNumberInputWithButton {
    label: string;
    placeholder?: string;
    buttonAction: (id: string) => void;
    id: string;
}

interface Iprops {
    loading?: boolean;
    colorButton?: string;
    installID: string;
    onSubmit: (part: Ipart, installID: string) => void;
    selectData: IsuggestedPartPopulated[];
    t: TFunction;
    partType: partTypeEnum;
    disabled: boolean;
}
interface Istate {
    fieldConfig: FieldConfig;
    parts: IsuggestedPartPopulated[];
}

export class RecommendedPartsForm extends React.Component<Iprops, Istate> {
    private userForm: FormGroup | any;
    private subscription: any;

    constructor(props: Iprops) {
        super(props);
        this.state = {
            fieldConfig: { controls: {} },
            parts: []
        };
    }
    componentDidMount() {
        this.setState({
            fieldConfig: this.buildFieldConfig(this.itemToFormValues())
        });
    }

    componentDidUpdate(prevProps: Iprops) {
        if (prevProps.disabled !== this.props.disabled) {
            this.setState({
                fieldConfig: this.buildFieldConfig(this.itemToFormValues())
            });
        }
    }

    /*
     * itemToFormValues - take the selectedItem and convert it to formValues
     */
    itemToFormValues = (): IgenericFormValues<Ipart> => {
        return {};
    };
    formValuesToItem = (): Ipart => {
        const processedFormValues = FormUtil.getValues(this.userForm.value.partID);
        const quantity = FormUtil.getValues(this.userForm.value);
        const foundPart =
            (find(this.state.parts, {
                id: processedFormValues.id
            }) as IsuggestedPartPopulated) || initialPart;
        return {
            ...initialPart,
            ...foundPart,
            ...processedFormValues,
            ...quantity
        };
    };
    loadOptions = (recommendedParts: IsuggestedPartPopulated[]): Ioption[] => {
        this.setState({ parts: recommendedParts });
        const partOptions = recommendedParts.map((item: IsuggestedPartPopulated) => {
            return {
                value: item.part.id,
                label: `(${item.productQuantity}) ${item.part.number} - ${item.part.description}`,
                ...item.part
            };
        });
        return orderBy(partOptions, 'order');
    };
    buildFieldConfig = (defaultValues = this.itemToFormValues()) => {
        // Field config to configure form
        let fieldConfigControls = {
            partID: {
                options: {
                    validators: [Validators.required]
                },
                render: FormUtil.Select,
                meta: {
                    label: 'Recommended Parts',
                    options: this.loadOptions(this.props.selectData),
                    name: 'part',
                    required: true,
                    colWidth: 8,
                    isMulti: false
                },
                formState: {
                    value: null,
                    disabled: this.props.disabled
                }
            },
            quantity: {
                options: {
                    validators: [Validators.required, FormUtil.validators.isValidPositiveInteger]
                },
                render: FormUtil.NumericInput,
                meta: {
                    label: 'searchAllPartsModal.quantity',

                    colWidth: 2,
                    name: 'quantity',
                    required: true
                },
                formState: {
                    value: 1,
                    disabled: this.props.disabled
                }
            }
        } as { [key: string]: GroupProps };

        const fieldConfig = {
            controls: { ...fieldConfigControls }
        };
        return FormUtil.translateForm(fieldConfig, this.props.t);
    };
    handleSubmit = (e: React.MouseEvent<Button>) => {
        e.preventDefault();
        if (this.userForm.status === 'INVALID') {
            this.userForm.markAsSubmitted();
            return toastr.error(this.props.t('toastMessage:invalidFormSubmission'), '', constants.toastrError);
        }
        this.props.onSubmit(this.formValuesToItem(), this.props.installID);
    };

    setForm = (form: FormGroup | FormArray) => {
        this.userForm = form;
        this.userForm.meta = {
            loading: this.props.loading
        };
    };

    render() {
        const { t } = this.props;

        const formClassName = `clearfix beacon-form manage-form ${this.props.colorButton}`;

        return (
            <form className={formClassName}>
                <Col xs={12} className="form-buttons">
                    <FormGenerator onMount={this.setForm} fieldConfig={this.state.fieldConfig} />

                    <Button
                        bsStyle={this.props.colorButton}
                        type="button"
                        onClick={e => this.handleSubmit(e)}
                        style={{ margin: '24px 0 0 44px' }}
                        disabled={this.props.disabled || this.props.loading}
                    >
                        {t('Add')}
                    </Button>
                </Col>
            </form>
        );
    }
}
