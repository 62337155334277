/*
 * Search New Products Modal
 */
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import SearchNewProductsForm from './SearchNewProductsForm';
import { IinitialState } from '../../reducers';
import { Iproduct } from '../../models';
import { WithCommonModal } from '../common/WithCommonModal';
import {
    resetNewProducts,
    clearSelectedProductID,
    searchProducts,
    setProductSearchFormValues,
    toggleEditProductModal,
    updateProductSearchFormValues,
    bulkUpdateInstalls,
    addVisibleProduct,
    toggleSearchNewProductsModal,
    selectProductToAdd
} from '../../actions/manageInventoryActions';
import { selectIsLoading } from '../../reducers/commonReducers';

interface Iprops {
    colorButton: string;
    selectedItem: Iproduct;
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops & WithTranslation) => {
    let title = ownProps.t('searchNewProductModalTitle');

    if (state.manageInventory.enableBulkInstallBaseMode) {
        title = ownProps.t('searchNewProductModalTitleBulkChange', {
            count: state.manageInventory.selection.length
        });
    }

    return {
        className: 'user-edit',
        loading: selectIsLoading(state),
        show: state.manageInventory.showSearchNewProductsModal,
        productInfo: state.productInfo,
        tableFilters: state.manageInventory.tableFilters,
        formValues: state.manageInventory.productSearchFormValues,
        newProducts: state.manageInventory.newProducts,
        enableBulkInstallBaseMode: state.manageInventory.enableBulkInstallBaseMode,
        selection: state.manageInventory.selection,
        bulkInstallBaseCategory: state.manageInventory.bulkInstallBaseCategory,
        title,
        user: state.user
    };
};

export default withTranslation('manageInventory')(
    connect(mapStateToProps, {
        clearSelectedProductID,
        toggleEditProductModal,
        toggleModal: toggleSearchNewProductsModal,
        onHide: toggleEditProductModal,
        searchProducts,
        selectProductToAdd,
        resetNewProducts,
        setProductSearchFormValues,
        updateProductSearchFormValues,
        bulkUpdateInstalls,
        addVisibleProduct
    })(WithCommonModal(SearchNewProductsForm))
);
