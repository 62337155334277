/*
 * Manage Inventory Form
 * Edit inventory items
 */

import * as React from 'react';
import { TFunction } from 'i18next';

import { AbstractControl, FieldConfig, FormGenerator, FormGroup } from 'react-reactive-form';
import { Button, Col, ListGroup, Row, Well } from 'react-bootstrap';
import { Ioption, Iproduct, IproductInfo, Iuser } from '../../models';
import {
    resetNewProducts,
    searchProducts,
    setProductSearchFormValues,
    toggleEditProductModal,
    updateProductSearchFormValues,
    bulkUpdateInstalls,
    addVisibleProduct,
    selectProductToAdd
} from '../../actions/manageInventoryActions';
import { debounce, filter, isEmpty, map } from 'lodash';

import { FormUtil } from '../common/FormUtil';
import { IsearchProductFormValues } from '../../modelsForms';
import { constants } from '../../constants/constants';
import { toastr } from 'react-redux-toastr';

interface Iprops {
    toggleModal: () => void;
    toggleEditProductModal: typeof toggleEditProductModal;
    selectedItem?: Iproduct;
    loading: boolean;
    colorButton: string;
    t: TFunction;
    productInfo: IproductInfo;
    formValues: Partial<IsearchProductFormValues>;
    newProducts: { [key: string]: Iproduct };
    resetNewProducts: typeof resetNewProducts;
    setProductSearchFormValues: typeof setProductSearchFormValues;
    updateProductSearchFormValues: typeof updateProductSearchFormValues;
    clearSelectedProductID: () => void;
    searchProducts: typeof searchProducts;
    selectProductToAdd: typeof selectProductToAdd;
    enableBulkInstallBaseMode: boolean;
    bulkInstallBaseCategory: string;
    selection: string[];
    bulkUpdateInstalls: typeof bulkUpdateInstalls;
    addVisibleProduct: typeof addVisibleProduct;
    user: Iuser;
}

interface Istate {
    fieldConfig: FieldConfig;
}

class SearchNewProductsForm extends React.Component<Iprops, Istate> {
    private formGroup: FormGroup | any;
    private subscription: any;
    private updateFormValuesDebounced: (formValues: { [key: string]: any }) => void;
    private handleSubmitDebounced: () => void;

    constructor(props: Iprops) {
        super(props);
        this.updateFormValuesDebounced = debounce(this.props.updateProductSearchFormValues, 300);
        this.handleSubmitDebounced = debounce(this.handleSubmit, 400);
        this.state = {
            fieldConfig: this.buildFieldConfig()
        };
    }

    componentDidMount() {
        this.props.resetNewProducts();
        if (this.props.enableBulkInstallBaseMode) {
            this.setState(
                {
                    fieldConfig: this.buildFieldConfig(this.buildFormValuesFromSelected())
                },
                () => {
                    this.handleSubmit();
                }
            );
        }
    }

    componentWillUnmount() {
        this.props.setProductSearchFormValues({ isFinalProduct: true });
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    componentDidUpdate(prevProps: Iprops) {
        if (this.props.formValues.mainCategoryID !== prevProps.formValues.mainCategoryID) {
            this.setState({ fieldConfig: this.buildFieldConfig() });
        }
        if (this.props.bulkInstallBaseCategory !== prevProps.bulkInstallBaseCategory) {
            this.setState(
                {
                    fieldConfig: this.buildFieldConfig(this.buildFormValuesFromSelected())
                },
                () => {
                    this.handleSubmit();
                }
            );
        }
    }

    buildFormValuesFromSelected = (): Partial<IsearchProductFormValues> => {
        let categoryOption = undefined;
        if (this.props.bulkInstallBaseCategory.length) {
            const category = this.props.productInfo.mainCategories[this.props.bulkInstallBaseCategory];
            categoryOption = FormUtil.convertToSingleOption(category);
        }
        return {
            subcategoryID: undefined,
            mainCategoryID: categoryOption,
            search: undefined,
            brandID: undefined,
            isFinalProduct: true
        };
    };

    buildFieldConfig = (defaultFormValues: Partial<IsearchProductFormValues> = this.props.formValues) => {
        const disabled = false;
        const { subcategories, mainCategoryOptions, brandOptions, brands, standardOptions } = this.props.productInfo;
        const { mainCategoryID, subcategoryID, search, brandID, isFinalProduct, productStandard } = defaultFormValues;

        let filteredSubCategoryOptions: Ioption[] = [];
        let selectedSubCategory = null;
        let selectedBrand = null;
        let selectedProductStandard = null;

        if (mainCategoryID) {
            filteredSubCategoryOptions = FormUtil.convertToOptions(
                filter(subcategories, sub => sub.mainCategoryID === mainCategoryID.value)
            );
            if (subcategoryID) {
                const subcategory = subcategories[subcategoryID.value];
                if (subcategory.mainCategoryID === mainCategoryID.value) {
                    selectedSubCategory = subcategoryID;
                }
            }
        }
        if (brandID) {
            const brand = brands[brandID.value];
            selectedBrand = FormUtil.convertToSingleOption(brand);
        }

        if (productStandard && Array.isArray(productStandard)) {
            selectedProductStandard = standardOptions.filter(Obj =>
                productStandard.map(standard => standard.value).includes(Obj.value)
            );
        } else {
            selectedProductStandard = standardOptions.filter((value: Ioption) => {
                return this.props.user.userStandards.includes(value.value);
            });
        }

        const fieldConfigControls = {
            mainCategoryID: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    options: mainCategoryOptions,
                    label: 'common:mainCategory',
                    colWidth: 6,
                    placeholder: 'common:searchPlaceholder',
                    isMulti: false,
                    name: 'product-group'
                },
                formState: {
                    value: mainCategoryID,
                    disabled: this.props.enableBulkInstallBaseMode
                }
            },
            search: {
                render: FormUtil.TextInputWithoutValidation,
                meta: {
                    label: 'search',
                    colWidth: 6,
                    type: 'input',
                    placeholder: 'searchByName',
                    name: 'product-search'
                },
                formState: { value: search, disabled }
            },
            subcategoryID: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    options: filteredSubCategoryOptions,
                    label: 'common:subCategory',
                    colWidth: 6,
                    placeholder: 'common:searchPlaceholder',
                    isMulti: false,
                    name: 'subcategory',
                    isClearable: true
                },
                formState: {
                    value: selectedSubCategory,
                    disabled: false
                }
            },
            brandID: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    options: brandOptions,
                    label: 'common:manufacturer',
                    colWidth: 6,
                    placeholder: 'common:searchPlaceholder',
                    isMulti: false,
                    name: 'manufacturer',
                    isClearable: true
                },
                formState: {
                    value: selectedBrand,
                    disabled: false
                }
            },
            productStandard: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    options: standardOptions,
                    label: 'common:productStandard',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    isMulti: true,
                    name: 'productStandard',
                    isClearable: true
                },
                formState: {
                    value: selectedProductStandard,
                    disabled
                }
            },
            isFinalProduct: {
                render: FormUtil.Toggle,
                meta: {
                    label: 'productForm.isFinalProduct',
                    colWidth: 6,
                    style: {
                        marginTop: '8px'
                    }
                },
                formState: {
                    value: isFinalProduct === undefined ? true : isFinalProduct,
                    disabled
                }
            }
        };

        return FormUtil.translateForm(
            {
                controls: { ...fieldConfigControls }
            },
            this.props.t
        );
    };

    /*
     * (reusable)
     * subscribe to the formGroup changes
     */
    subscribeToChanges = () => {
        for (const key in this.formGroup.controls) {
            if (this.formGroup.controls.hasOwnProperty(key)) {
                this.subscription = this.formGroup.get(key).valueChanges.subscribe((value: any) => {
                    this.onValueChanges(value, key);
                });
            }
        }
    };

    /*
     * (reusable)
     * set the table filters to redux on each value change
     */
    onValueChanges = (value: any, key: string) => {
        switch (key) {
            case 'mainCategoryID':
                this.updateFormValuesDebounced({
                    [key]: value,
                    subcategoryID: null
                });
                this.handleSubmitDebounced();
                break;
            default:
                this.updateFormValuesDebounced({ [key]: value });
                this.handleSubmitDebounced();
                break;
        }
    };

    handleSubmit = () => {
        if (this.formGroup.status === 'INVALID') {
            this.formGroup.markAsSubmitted();
            toastr.error('Please check invalid inputs', '', constants.toastrError);
            return;
        }

        const {
            mainCategoryID,
            search,
            subcategoryID,
            brandID,
            origin,
            power,
            type,
            model,
            isFinalProduct,
            productStandard
        } = this.formGroup.value;
        const mainCategoryUUID = mainCategoryID ? mainCategoryID.value : null;
        const subcategoryUUID = subcategoryID ? subcategoryID.value : null;
        const brandUUID = brandID ? brandID.value : null;
        const originID = origin ? origin.value : null;
        const powerID = power ? power.value : null;
        const typeID = type ? type.value : null;
        const modelID = model ? model.value : null;
        const isFinaProduct = isFinalProduct ? isFinalProduct : isFinalProduct;

        let selectedStandards = [];
        if (productStandard && productStandard.length > 0) {
            selectedStandards = productStandard.map((standard: Ioption) => standard.value);
        }

        this.props.searchProducts(
            1,
            search,
            mainCategoryUUID,
            subcategoryUUID,
            brandUUID,
            originID,
            powerID,
            typeID,
            modelID,
            isFinaProduct,
            selectedStandards
        );
    };

    setForm = (form: AbstractControl) => {
        this.formGroup = form;
        this.formGroup.meta = {
            loading: this.props.loading
        };
        if (!this.subscription) {
            setTimeout(() => {
                this.subscribeToChanges();
            }, 300);
        }
    };

    handleProductSelect = (product: Iproduct) => {
        if (this.props.enableBulkInstallBaseMode) {
            const toastrConfirmOptions = {
                onOk: () => {
                    this.props.bulkUpdateInstalls({ productID: product.id });
                    this.props.addVisibleProduct(product);
                },
                onCancel: () => null,
                okText: this.props.t('buttonBulkChangeProduct'),
                cancelText: this.props.t('common:cancel')
            };
            toastr.confirm(
                this.props.t('toastMessage:confirmBulkChangeProduct', {
                    count: this.props.selection.length
                }),
                toastrConfirmOptions
            );
        } else {
            const newProduct = {
                ...product,
                subcategory: this.props.productInfo.subcategories[product.subcategoryID]
            };
            this.props.selectProductToAdd(newProduct);
        }
    };

    render() {
        const { t } = this.props;
        const formClassName = `clearfix beacon-form search-products-form ${this.props.colorButton}`;
        let searchActive = false;

        if (
            !this.props.loading &&
            this.formGroup &&
            this.formGroup.value &&
            (this.formGroup.value.search || this.formGroup.value.mainCategoryID)
        ) {
            searchActive = true;
        }

        const ProductListItem = ({
            product,
            productInfo,
            selectedItem,
            index
        }: {
            product: Iproduct;
            productInfo: IproductInfo;
            selectedItem?: Iproduct;
            index: string;
        }) => {
            const className =
                selectedItem && selectedItem.id === index
                    ? 'list-group-item new-product-item selected'
                    : 'list-group-item new-product-item';
            return (
                <li
                    className={className}
                    onClick={() => {
                        this.handleProductSelect(product);
                    }}
                >
                    <h4> {product.name} </h4>
                </li>
            );
        };

        return (
            <div>
                <p
                    style={{
                        marginLeft: '15px',
                        marginRight: '15px',
                        lineHeight: '1.5rem'
                    }}
                >
                    {t('searchNewProductInstructions')}
                </p>

                <form
                    onSubmit={(e: React.MouseEvent<HTMLFormElement>) => {
                        e.preventDefault();
                        this.handleSubmit();
                    }}
                    className={formClassName}
                    style={{ display: 'block' }}
                >
                    <Row>
                        <Col xs={12}>
                            <FormGenerator onMount={this.setForm} fieldConfig={this.state.fieldConfig} />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <ListGroup className="beacon-list-group">
                                {map(this.props.newProducts, (product, index) => (
                                    <ProductListItem
                                        product={product}
                                        productInfo={this.props.productInfo}
                                        key={product.id}
                                        selectedItem={this.props.selectedItem}
                                        index={index}
                                    />
                                ))}
                            </ListGroup>
                            {isEmpty(this.props.newProducts) && searchActive && (
                                <Col xs={12}>
                                    <Well className="text-center">{t('noProductsFound')}</Well>
                                </Col>
                            )}
                        </Col>
                    </Row>
                    <Col xs={12} className="form-buttons text-right">
                        <Button bsStyle="default" type="button" className="pull-left" onClick={this.props.toggleModal}>
                            {t('common:cancel')}
                        </Button>
                        {!this.props.enableBulkInstallBaseMode && (
                            <Button
                                bsStyle="link"
                                type="button"
                                disabled={this.props.loading}
                                className="right-side"
                                onClick={() => {
                                    this.props.clearSelectedProductID();
                                    this.props.toggleEditProductModal();
                                }}
                            >
                                {t('addNewProductButton')}
                            </Button>
                        )}
                        <button type="submit" style={{ display: 'none' }} />
                    </Col>
                </form>
            </div>
        );
    }
}
export default SearchNewProductsForm;
