/*
 * The HeaderMenu only displays if we have an authenticated user.
 * It is responsible for displaying the welcome message and the dropdown menu for logged in users
 * spinner is from : http://tobiasahlin.com/spinkit/
 */

import { toggleEditProfileModal, updateUserProfile } from '../../actions/userActions';

import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { IinitialState } from '../../reducers';
import { selectIsLoading } from '../../reducers/commonReducers';
import { FormUtil } from '../common/FormUtil';
import { WithCommonModal } from '../common/WithCommonModal';
import UserProfileForm from './UserProfileForm';

interface Iprops {
    colorButton: string;
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops & WithTranslation) => {
    const title = ownProps.t('editProfileModalTitle');
    return {
        className: 'user-edit',
        user: state.user,
        loading: selectIsLoading(state),
        show: state.showEditProfileModal,
        title,
        standardCodes: FormUtil.convertToOptions(state.productInfo.standardOptions)
    };
};

export default withTranslation('user')(
    connect(mapStateToProps, {
        toggleEditProfileModal,
        toggleModal: toggleEditProfileModal,
        onHide: toggleEditProfileModal,
        handleSubmit: updateUserProfile
    })(WithCommonModal(UserProfileForm))
);
