import { TFunction } from 'i18next';
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { updateWorkOrder, deleteWorkOrder } from '../../actions/workOrderActions';
import { IWorkOrder } from '../../models';
import { workOrderStatusEnum } from '../../models-enums';

interface Iprops {
    updateWorkOrder: typeof updateWorkOrder;
    deleteWorkOrder: typeof deleteWorkOrder;
    loading: boolean;
    colorButton?: string;
    t: TFunction;
    selectedWorkOrder: IWorkOrder;
}
export default class NotApplicableButtonRow extends Component<Iprops> {
    render() {
        return (
            <>
                {this.props.selectedWorkOrder.status !== workOrderStatusEnum.notApplicable && (
                    <Button
                        bsStyle="default"
                        className="pull-right"
                        onClick={e =>
                            this.props.updateWorkOrder({
                                ...this.props.selectedWorkOrder,
                                status: workOrderStatusEnum.notApplicable
                            })
                        }
                    >
                        {this.props.t('notApplicable')}
                    </Button>
                )}
                {this.props.selectedWorkOrder.status === workOrderStatusEnum.notApplicable && (
                    <Button
                        bsStyle="default"
                        className="pull-right"
                        onClick={e =>
                            this.props.updateWorkOrder({
                                ...this.props.selectedWorkOrder,
                                status: workOrderStatusEnum.reopened
                            })
                        }
                    >
                        {this.props.t('reopen')}
                    </Button>
                )}
                <Button
                    bsStyle="danger delete-button"
                    className="pull-right"
                    onClick={() => {
                        this.props.deleteWorkOrder(this.props.t);
                    }}
                >
                    {this.props.t('common:delete')}
                </Button>
            </>
        );
    }
}
