import { IinitialState } from '.';
import * as types from '../actions/actionTypes';

import { IsyncStatusReducer } from '../models';
import initialState from './initialState';

export default function SyncStatusReducer(
    state: IsyncStatusReducer = initialState.syncStatus,
    action: any
): IsyncStatusReducer {
    switch (action.type) {
        case types.GET_ALL_PRODUCTS_SUCCESS:
            return { ...state, allProductsUpdated: action.updateDate };
        case types.GET_PRODUCT_INFO_SUCCESS:
            return { ...state, productInfoUpdated: action.updateDate };
        case types.MANAGE_MEASUREMENT_POINT_LISTS_SUCCESS:
            return { ...state, measurementPointsUpdated: action.updateDate };
        case types.UPDATE_SYNC_STATUS_SUCCESS:
            return { ...state, lastSync: action.updateDate };
        case types.GET_FSE_SUCCESS:
            return { ...state, FSEUsersUpdated: action.updateDate };
        case types.GET_MEASUREMENT_POINT_FACILITY_RESULTS_SUCCESS:
            return {
                ...state,
                measurementPointResultsUpdated: action.updateDate
            };
        case types.GET_SIMPLE_MEASUREMENT_POINT_JOB_RESULTS_SUCCESS:
            return {
                ...state,
                simpleMeasurementPointResultsUpdated: action.updateDate
            };
        case types.INITIAL_SYNC_START: {
            return { ...state, isInitialSyncComplete: false };
        }
        case types.INITIAL_SYNC_END: {
            return { ...state, isInitialSyncComplete: true };
        }
        case types.GET_ALL_PARTS_SUCCESS: {
            return { ...state, allPartsUpdated: action.updateDate };
        }
        case types.SET_INITIAL_APP_SYNC_LIST: {
            return { ...state, initialAppSyncList: action.initialAppSyncList };
        }
        case types.CLEAR_INITIAL_APP_SYNC_LIST: {
            return { ...state, initialAppSyncList: [] };
        }
        case types.REMOVE_INITIAL_APP_SYNC_ITEM: {
            let newList = state.initialAppSyncList;
            newList = newList.filter(item => item.name !== action.name);

            return {
                ...state,
                initialAppSyncList: newList
            };
        }
        case types.UPDATE_INITIAL_APP_SYNC_ITEM_STATUS: {
            let newList = state.initialAppSyncList;
            newList = newList.map(item => {
                if (item.name === action.name) {
                    return { ...item, status: action.status };
                }
                return item;
            });

            return {
                ...state,
                initialAppSyncList: newList
            };
        }
        case types.SET_DOWNLOAD_SPEED: {
            if (action.downloadSpeed && action.downloadSpeed !== 'Infinity') {
                const speed = parseFloat(action.downloadSpeed);
                return { ...state, downloadSpeed: speed };
            }

            return { ...state, downloadSpeed: undefined };
        }
        case types.USER_LOGOUT_SUCCESS:
            return initialState.syncStatus;
        default:
            return state;
    }
}
