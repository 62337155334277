/*
 * UserForm
 * User signs up directly to the platform
 */

import * as React from 'react';

import { TFunction } from 'i18next';
import { forEach, mapValues } from 'lodash';
import { Row } from 'react-bootstrap';
import { AbstractControl, FieldConfig, FormGenerator, FormGroup } from 'react-reactive-form';
import SearchIcon from '../../images/search.svg';
import { FormUtil } from '../common/FormUtil';

interface Iprops extends React.Props<SearchTableForm> {
    fieldConfig: FieldConfig;
    handleSubmit: any;
    loading: boolean;
    showSearchButton?: boolean;
    colorButton: string;
    t: TFunction;
    onValueChanges?: any;
    subscribeValueChanges?: boolean;
}
export default class SearchTableForm extends React.Component<Iprops, {}> {
    public searchForm: FormGroup | any;
    public fieldConfig: FieldConfig;
    public fieldConfigMobile: FieldConfig = { controls: {} };
    private subscription: any;
    private showBtn: boolean;

    constructor(props: Iprops) {
        super(props);
        this.fieldConfig = FormUtil.translateForm(this.props.fieldConfig, props.t);

        // Feels a little hacky, but we want a FieldConfig with ONLY the search for mobile
        mapValues(this.props.fieldConfig.controls, (field: AbstractControl) => {
            if (field.meta.label === 'search') {
                this.fieldConfigMobile = FormUtil.translateForm({ controls: { search: field } }, props.t);
            }
        });

        this.showBtn = typeof this.props.showSearchButton === 'undefined' ? true : this.props.showSearchButton;
    }

    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        this.props.handleSubmit(this.searchForm.value);
    };

    setForm = (form: AbstractControl) => {
        this.searchForm = form;
        this.searchForm.meta = {
            loading: this.props.loading
        };

        if (this.props.subscribeValueChanges) {
            forEach(this.props.fieldConfig.controls, (input: any, key) => {
                if (key.indexOf('$field_') !== -1) {
                    // skip elements that are display only
                    return;
                }
                this.subscription = this.searchForm.get(key).valueChanges.subscribe((value: any) => {
                    this.props.onValueChanges(value, key);
                });
            });
        }
    };

    setFormMobile = (form: AbstractControl) => {
        this.searchForm = form;
        this.searchForm.meta = {
            loading: this.props.loading
        };

        if (this.props.subscribeValueChanges) {
            forEach(this.fieldConfigMobile.controls, (input: any, key) => {
                if (key.indexOf('$field_') !== -1) {
                    // skip elements that are display only
                    return;
                }
                this.subscription = this.searchForm.get(key).valueChanges.subscribe((value: any) => {
                    this.props.onValueChanges(value, key);
                });
            });
        }
    };

    render() {
        return (
            <>
                <Row className="search-table-form beacon-form">
                    <form onSubmit={this.handleSubmit}>
                        <FormGenerator onMount={this.setForm} fieldConfig={this.fieldConfig} />
                    </form>
                </Row>

                {Object.keys(this.fieldConfigMobile.controls).length > 0 && (
                    <Row className="search-table-form-mobile beacon-form">
                        <form onSubmit={this.handleSubmit}>
                            <img src={SearchIcon} alt="" className="search-table-form-icon" />
                            <FormGenerator onMount={this.setFormMobile} fieldConfig={this.fieldConfigMobile} />
                        </form>
                    </Row>
                )}
            </>
        );
    }
}
