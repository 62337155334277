import * as React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';

import { Ifacility, IinstallBase, Ipart, IsuggestedPartPopulated } from '../../models';
import { IinitialState } from '../../reducers';
import { selectSuggestedPartsForTable } from '../../reducers/partsReducer';
import { Banner } from '../common/Banner';
import { constants } from '../../constants/constants';
import { WithTranslation, withTranslation } from 'react-i18next';
import { initialFacility } from '../../reducers/initialState';
import { TableUtil } from '../common/TableUtil';
import ReactTable, { FinalState, RowInfo } from 'react-table';
import { TFunction } from 'i18next';
import { SearchAllPartsModalandContainer } from './SearchAllPartsModalandContainer';
import { addQuery } from '../common/OtherUtils';
import queryString from 'query-string';
import { LinkContainer } from 'react-router-bootstrap';
import { addPartToCart } from '../../actions/partsActions';
import { partTypeEnum } from '../../models-enums';
import { SearchAllPartsForm } from '../manageJob/SearchAllPartsForm';
import { selectIsLoading } from '../../reducers/commonReducers';

export enum suggestedPartsQueryParamsEnum {
    showSearchPartsModal = 'showSearchPartsModal'
}

const buildColumns = (t: TFunction) => {
    return TableUtil.translateHeaders(
        [
            {
                Header: 'part',
                id: 'part',
                accessor: ({ part }: IsuggestedPartPopulated) => `${part.number} - ${part.description}`,
                minWidth: 200
            },
            {
                Header: 'product',
                accessor: 'product.name',
                minWidth: 200
            },
            {
                Header: 'times used',
                accessor: 'productQuantity',
                width: 150
            },
            {
                Header: 'comments',
                accessor: 'adminComments',
                minWidth: 200
            },
            {
                Header: 'service time',
                accessor: 'part.installationTime',
                width: 150
            }
        ],
        t
    );
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Iprops extends RouteComponentProps<{ installID: string }> {
    // t: TFunction;
}

interface IdispatchProps {
    installBase: IinstallBase;
    facilityID: string;
    facility: Ifacility;
    productName: string;
    tableData: IsuggestedPartPopulated[];
    queryParams: typeof suggestedPartsQueryParamsEnum;
    addPartToCart: typeof addPartToCart;
    loading: boolean;
}
interface RowInfoSuggestedPart extends RowInfo {
    original: IsuggestedPartPopulated;
}
const SuggestedPartsLayoutC = (props: Iprops & IdispatchProps & WithTranslation) => {
    const { t, facility, installBase, productName, tableData, loading } = props;

    const colorButton = constants.colors[`blueButton`];

    const bannerTitle = t('bannerTitleMobile');

    const handleAdd = (rowInfo: RowInfoSuggestedPart) => {
        const { original } = rowInfo;
        props.addPartToCart(original.part, props.installBase.id);
    };

    const handleSearchAllAdd = (part: Ipart) => {
        props.addPartToCart(part, props.installBase.id);
    };

    const handleLaborAdd = (part: Ipart) => {
        props.addPartToCart(part);
    };

    /*
     * Handle user clicking on a location row
     * set the selected location to state and open the modal
     */
    const getTrProps = (state: FinalState, rowInfo: RowInfoSuggestedPart | undefined) => {
        if (rowInfo) {
            return {
                onClick: (e: React.MouseEvent<HTMLFormElement>) => {
                    handleAdd(rowInfo);
                }
            };
        } else {
            return {};
        }
    };

    return (
        <div className="suggested-parts">
            <Row>
                <Banner
                    title=""
                    img=""
                    color={constants.colors[`blue`]}
                    subtitle={productName}
                    subsubtitle={TableUtil.buildLocation(installBase, facility)}
                />
            </Row>
            <Row>
                <Col
                    style={{
                        flexGrow: 1,
                        fontSize: '2rem',
                        marginLeft: '48px',
                        padding: '24px 0'
                    }}
                >
                    {bannerTitle}
                </Col>
            </Row>
            <Row style={{ display: 'flex' }}>
                <Col xs={6} style={{ marginLeft: '15px' }}>
                    <SearchAllPartsForm
                        partType={partTypeEnum.labor}
                        colorButton={colorButton}
                        onSubmit={handleLaborAdd}
                        t={t}
                        loading={loading}
                    />
                </Col>
                <Col style={{ margin: '25px 38px 15px 30px' }}>
                    <Button
                        onClick={() => {
                            addQuery(suggestedPartsQueryParamsEnum.showSearchPartsModal, 'true', props.history);
                        }}
                        style={{ marginRight: '28px' }}
                    >
                        Search All Parts
                    </Button>
                    <LinkContainer to={`/devices/${props.installBase.id}`}>
                        <Button>Back</Button>
                    </LinkContainer>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <h4
                        style={{
                            flexGrow: 1,
                            fontSize: '1.4rem',
                            marginLeft: '30px',
                            padding: '10px 0'
                        }}
                    >
                        {t('suggestedPartsMobileSubTitle')}
                    </h4>
                    <ReactTable
                        data={tableData}
                        // onSortedChange={this.onSortedChanged}
                        columns={buildColumns(t)}
                        getTrProps={getTrProps}
                        defaultPageSize={tableData.length}
                        // onPageSizeChange={this.onPageSizeChange}
                        // showPageSizeOptions={true}
                        // pageSizeOptions={constants.tablePageSizeOptions}
                        className={`beacon-table -highlight blue`}
                        // previousText={t('common:previous')}
                        // nextText={t('common:next')}
                        // onPageChange={this.onPageChange}
                        // page={this.props.tableFilters.page}
                        sortable={false}
                        noDataText={t('common:noDataText')}
                        resizable={false}
                        showPagination={false}
                        // getTdProps={this.getTdProps}
                    />
                </Col>
            </Row>
            <SearchAllPartsModalandContainer
                history={props.history}
                queryParams={props.queryParams}
                colorButton={colorButton}
                onSubmit={handleSearchAllAdd}
                partType={partTypeEnum.default}
            />
        </div>
    );
};

const makeMapStateToProps = () => {
    return (state: IinitialState, ownProps: Iprops) => {
        const installBase = state.manageInventory.installBasesByID[ownProps.match.params.installID];
        const facility = state.facilities.facilitiesByID[installBase.facilityID] || initialFacility;
        const tableData = selectSuggestedPartsForTable(state, { installBase });
        const queryParams = queryString.parse(ownProps.location.search) as typeof suggestedPartsQueryParamsEnum;
        return {
            installBase,
            tableData,
            facility,
            productName: state.manageInventory.productsByID[installBase.productID].name,
            queryParams,
            loading: selectIsLoading(state)
        };
    };
};

export const SuggestedPartsLayout = withTranslation('nsPart')(
    connect(makeMapStateToProps, {
        addPartToCart
    })(SuggestedPartsLayoutC)
);
