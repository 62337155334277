/*
 * Preventative Maintenance Table
 */

import * as React from 'react';

import ReactTable, { FinalState, RowInfo, Column } from 'react-table';
import { IjobHour, Itile } from '../../models';
import { TFunction } from 'i18next';
import { toastr } from 'react-redux-toastr';
import moment from 'moment';

import { TableUtil } from '../common/TableUtil';
import { constants } from '../../constants/constants';
import { setSelectedJobHourID, deleteJobHour } from '../../actions/manageJobActions';

const columns = (t: TFunction) =>
    TableUtil.translateHeaders(
        [
            {
                Header: 'jobHoursForm.laborRateHeader',
                accessor: ({ number, description }: IjobHour) => {
                    return `${number} - ${description}`;
                },
                width: 260,
                id: 'laborRate'
            },
            {
                Header: 'jobHoursForm.engineerVanIdHeader',
                accessor: 'engineerVanID',
                width: 120
            },
            {
                Header: 'jobHoursForm.hoursHeader',
                accessor: 'quantity',
                width: 80
            },
            {
                Header: 'jobHoursForm.dateHeader',
                id: 'date',
                width: 100,
                accessor: ({ date }: IjobHour) => {
                    return date ? moment.utc(date).local(true).format(constants.momentDisplayFormat) : 'n/a';
                }
            }
        ],
        t
    );

interface Iprops {
    tableData: IjobHour[];
    t: TFunction;
    loading: boolean;
    currentTile: Itile;
    setSelectedJobHourID: typeof setSelectedJobHourID;
    selectedJobHourID: string;
    deleteJobHour: typeof deleteJobHour;
}
interface Istate {
    selectedRow: any;
    columns: any[];
}
interface RowInfoEdited extends RowInfo {
    original: IjobHour;
}

export class AddHoursTable extends React.PureComponent<Iprops, Istate> {
    static defaultProps = {
        currentTile: constants.getTileByURL(window.location.pathname)
    };
    constructor(props: Iprops) {
        super(props);
        this.state = {
            selectedRow: {},
            columns: columns(this.props.t)
        };
    }
    componentDidUpdate(prevProps: Iprops) {
        if (this.props.selectedJobHourID !== prevProps.selectedJobHourID && this.props.selectedJobHourID.length === 0) {
            this.setState({ selectedRow: {} });
        }
    }
    handleDelete = (jobHour: IjobHour) => {
        const toastrConfirmOptions = {
            onOk: () => {
                this.props.deleteJobHour(jobHour);
            },
            onCancel: () => null,
            okText: this.props.t('deleteHoursConfirmButton'),
            cancelText: this.props.t('common:cancel')
        };
        toastr.confirm(this.props.t('deleteHoursConfirmTitle'), toastrConfirmOptions);
    };
    /*
     * (reusable)
     * Handle user clicking on a product row
     * set the selected product to state and open the modal
     */
    getTrProps = (state: FinalState, rowInfo: RowInfoEdited | undefined) => {
        if (rowInfo) {
            return {
                style: {
                    background:
                        rowInfo.index === this.state.selectedRow
                            ? constants.colors[`${this.props.currentTile.color}Tr` as keyof typeof constants.colors]
                            : ''
                }
            };
        } else {
            return {};
        }
    };
    getTdProps = (
        fState: FinalState,
        rowInfo: RowInfoEdited | undefined,
        column: Column | undefined,
        instance: any
    ) => {
        if (rowInfo && column && column.id && column.id === 'delete') {
            return {
                onClick: () => {
                    this.handleDelete(rowInfo.original);
                }
            };
        } else {
            return {};
        }
    };

    render() {
        const { tableData, t } = this.props;

        const tableClassName = `beacon-table -highlight no-hover ${this.props.currentTile.color}`;
        return (
            <div style={{ marginTop: '20px' }}>
                <ReactTable
                    data={tableData}
                    columns={this.state.columns}
                    showPageSizeOptions={false}
                    showPagination={false}
                    className={tableClassName}
                    sortable={false}
                    multiSort={false}
                    noDataText={t('addHoursTableNoDataText')}
                    resizable={false}
                />
            </div>
        );
    }
}
