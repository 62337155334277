/*
 * Edit Job Modal and Redux Connector
 */

import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';

import { IinitialState } from '../../reducers';
import { WithCommonModal } from '../common/WithCommonModal';

import { selectIsLoading } from '../../reducers/commonReducers';
import { SearchAllPartsForm } from '../manageJob/SearchAllPartsForm';
import { Ihistory, Ipart } from '../../models';
import { removeQuery } from '../common/OtherUtils';
import { suggestedPartsQueryParamsEnum } from './SuggestedParts';
import { partTypeEnum } from '../../models-enums';

interface Iprops {
    colorButton: string;
    disabled?: boolean;
    secondModal?: boolean;
    history: Ihistory;
    queryParams: typeof suggestedPartsQueryParamsEnum;
    onSubmit: (part: Ipart) => void;
    shouldShowQuantity?: boolean;
    partType: partTypeEnum;
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops & WithTranslation) => {
    // define the closeModal function here in mapState so that we can pass it to both the main component and
    // to the onHide which is a WithCommonModal HOC prop
    // yes it is unusual to pass a function in mapState, but it is ok since it does not need dispatch
    const closeModal = () => {
        removeQuery(suggestedPartsQueryParamsEnum.showSearchPartsModal, ownProps.history);
    };

    const title = ownProps.t('nsPart:searchAllPartsModal.modalTitle');

    return {
        className: 'part-search',
        loading: selectIsLoading(state),
        title,
        online: state.offline.online,
        closeModal,
        onHide: closeModal,
        show: !!ownProps.queryParams.showSearchPartsModal
    };
};

export const SearchAllPartsModalandContainer = withTranslation('nsPart')(
    connect(mapStateToProps, {})(WithCommonModal(SearchAllPartsForm))
);
