/*
 * Button to handle adding a note to a measurment point
 */

import { Dictionary } from 'lodash';
import * as React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import {
    FieldConfig,
    FormArray,
    FormGenerator,
    Handler,
    InputType,
    ValidationErrors,
    FormGroup as fg
} from 'react-reactive-form';
import { connect, useSelector } from 'react-redux';
import NoteIcon from '../../images/note-icon.svg';
import { IdeficiencyCode, Ioption } from '../../models';
import { IinitialState } from '../../reducers';
import { getIsMobile } from '../../reducers/configReducer';
import { initialFacility } from '../../reducers/initialState';
import { selectDeficiencyCodesByStandardID } from '../../reducers/manageJobCommentsReducer';
import CommonMobileModal from '../common/CommonMobileModal';
import CommonModal from '../common/CommonModal';
import { FormUtil } from '../common/FormUtil';
import RichTextEditor from '../common/RichTextEditor';

interface Iprops {
    pristine: boolean;
    errors: ValidationErrors;
    submitted: boolean;
    meta: { [key: string]: any };
    handler: (inputType?: InputType, value?: string) => Handler;
}

interface IdispatchProps {
    codeOptions: Dictionary<IdeficiencyCode>;
}

const MPNoteButton = (props: Iprops & IdispatchProps) => {
    const [showModal, setShowModal] = React.useState<boolean>(false);
    const [overrideRTEText, setOverrideRTEText] = React.useState<boolean>(false);
    const buttonStyle = props.handler().value && props.handler().value.length ? 'warning' : 'default';
    const [textValue, setTextValue] = React.useState(props.handler().value);
    const [codeForm, setCodeForm] = React.useState<fg | FormArray>();
    const [controls] = React.useState<FieldConfig>({
        controls: {
            codeID: {
                render: FormUtil.Select,
                meta: {
                    label: 'Standard Text',
                    colWidth: 12,
                    isMulti: false,
                    name: 'code',
                    required: false,
                    options: FormUtil.convertToOptions(props.codeOptions)
                },
                formState: { value: undefined, disabled: false }
            }
        }
    });
    const [clearEditor, setClearEditor] = React.useState<boolean | undefined>(undefined);
    const onMount = (form: fg | FormArray) => {
        setCodeForm(form);
    };

    React.useEffect(() => {
        if (codeForm) {
            codeForm.get('codeID').valueChanges.subscribe((val: Ioption) => {
                const code = props.codeOptions[val.value].description;
                const newComment = `${props.handler().value}${code}`;

                setTextValue(newComment);
                // So dumb, these renders don't line up correctly, this whole thing needs to be re-written
                setTimeout(() => {
                    setOverrideRTEText(true);
                }, 50);

                setClearEditor(false);
            });
        }
    }, [codeForm]);

    React.useEffect(() => {
        if (clearEditor !== undefined) {
            setClearEditor(undefined);
        }
    }, [clearEditor]);

    const isMobile = useSelector(getIsMobile);
    const style = isMobile
        ? {
              marginTop: '20px',
              width: '100%',
              height: '36px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
          }
        : props.meta.center
        ? { marginLeft: '45px' }
        : { marginTop: '25px' };

    const Modal = isMobile ? CommonMobileModal : CommonModal;

    const NoteButton = () => (
        <Button
            type="button"
            bsStyle={buttonStyle}
            onClick={() => {
                setShowModal(true);
                setOverrideRTEText(true);
            }}
            className="pull-right"
            style={style}
        >
            {isMobile ? <img src={NoteIcon} /> : props.meta.t('notes')}
        </Button>
    );

    return (
        <>
            <Col xs={2} className="mp-row">
                <NoteButton />
            </Col>
            {showModal && (
                <Modal
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    className={isMobile ? '' : 'measurement-point-note-modal'}
                    title={props.meta.t(props.meta.label)}
                    container={isMobile && document.getElementById('two-pane-layout')}
                >
                    <form className="beacon-form">
                        {/* Something is sticking a 15px padding soon as you put a margin on, 5 + 15 = 20 yay */}
                        <Row style={{ marginLeft: '5px', marginRight: '5px' }}>
                            <FormGenerator
                                onUnmount={() =>
                                    codeForm &&
                                    codeForm.get('codeID').valueChanges.unsubscribe(() => {
                                        return;
                                    })
                                }
                                onMount={onMount}
                                fieldConfig={controls}
                            />
                        </Row>

                        <Row style={{ marginLeft: '20px', marginRight: '20px' }}>
                            <RichTextEditor
                                showNormalToolbar={false}
                                onChange={(e: any) => {
                                    let text = e;

                                    if (text === '<p></p>') {
                                        text = '';
                                    }

                                    setTextValue(text);
                                    if (!text) setClearEditor(true);
                                }}
                                initialContent={textValue}
                                readOnly={false}
                                clearEditor={clearEditor}
                                charLimit={1000}
                                overrideRTEText={overrideRTEText} // when the modal is opened, set the initial text
                                resetOverrideRTEText={() => setOverrideRTEText(false)} // reset the override text
                            />
                        </Row>
                        <Col xs={12} className="form-buttons text-right">
                            <Button
                                bsStyle="default"
                                type="button"
                                className="pull-left"
                                onClick={() => {
                                    setTextValue(props.handler().value);
                                    setShowModal(false);
                                }}
                            >
                                {props.meta.t('common:cancel')}
                            </Button>
                            <Button
                                bsStyle="default"
                                type="button"
                                className="pull-left"
                                style={{ marginLeft: '10px' }}
                                onClick={() => {
                                    setClearEditor(!clearEditor);
                                    setTextValue('');
                                }}
                            >
                                {props.meta.t('mobile:resetSelection')}
                            </Button>
                            {!props.meta.isHistoricalNote && (
                                <Button
                                    bsStyle="primary"
                                    type="button"
                                    onClick={() => {
                                        props.handler().onChange(textValue);
                                        setShowModal(false);
                                    }}
                                >
                                    {props.meta.t('common:save')}
                                </Button>
                            )}
                        </Col>
                    </form>
                </Modal>
            )}
        </>
    );
};

const mapStateToProps = (state: IinitialState, ownProps: Iprops) => {
    const facility = state.facilities.facilitiesByID[state.manageJob.selectedJob.facilityID] || initialFacility;

    return {
        codeOptions: selectDeficiencyCodesByStandardID(state.manageJobComment.deficiencyCodesByID, facility.standardID)
    };
};
export const MeasurementPointNoteButton = connect(mapStateToProps, {})(MPNoteButton);
