import { AxiosRequestConfig, AxiosResponse } from 'axios';
import API from '../constants/apiEndpoints';
import { beginAjaxCall, endAjaxCall } from './ajaxStatusActions';
import { msalFetch } from '../components/auth/Auth-Utils';
import { IcountryBE, IfacilityContact, ThunkResult } from '../models';
import * as types from './actionTypes';
import { appSyncItemNames, constants } from '../constants/constants';
import { isAPIRequestInQueue } from '../reducers/commonReducers';
import { AppSyncItemStatus } from '../models-enums';

export const getContactsByFacility = (facilityID: string, skipQueue: boolean = false): ThunkResult<any> => {
    return (dispatch, getState) => {
        // Don't recall why this was needed
        if (isAPIRequestInQueue(getState(), types.GET_CONTRACTS_SUCCESS)) {
            return Promise.resolve();
        }

        const url = API.contact.getByFacility;
        const axiosOptions: AxiosRequestConfig = {
            method: 'get',
            params: { id: facilityID },
            url
        };

        if (skipQueue) {
            dispatch({
                type: types.UPDATE_INITIAL_APP_SYNC_ITEM_STATUS,
                name: appSyncItemNames.FacilityContacts,
                status: AppSyncItemStatus.inProgress
            });

            return msalFetch(url, axiosOptions)
                .then((data: AxiosResponse<IfacilityContact[]>) => {
                    dispatch({ type: types.REMOVE_INITIAL_APP_SYNC_ITEM, name: appSyncItemNames.FacilityContacts });

                    dispatch({
                        type: types.SET_CONTACT_FOR_FACILITY,
                        payload: data,
                        id: facilityID
                    });
                })
                .catch((error: any) => {
                    constants.handleError(error, 'get contacts for facility');
                    console.error('[getContactsByFacility]:', error);

                    dispatch({
                        type: types.UPDATE_INITIAL_APP_SYNC_ITEM_STATUS,
                        name: appSyncItemNames.FacilityContacts,
                        status: AppSyncItemStatus.failed
                    });
                });
        } else {
            dispatch({
                type: types.GET_CONTRACTS_SUCCESS,
                meta: {
                    offline: {
                        effect: { axiosOptions, message: 'Get Contacts for Facility' },
                        commit: {
                            type: types.SET_CONTACT_FOR_FACILITY,
                            id: facilityID
                        }
                    }
                }
            });
        }
    };
};

export const setContactForFacility = (facilityId: string, contacts: IfacilityContact[]) => ({
    type: types.SET_CONTACT_FOR_FACILITY,
    id: facilityId,
    contacts
});
